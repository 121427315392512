export const tablesActionTypes = {
  /**
   * TABLE CONFIGS
   */
  LOAD_TABLES_REQUEST: 'LOAD_TABLES_REQUEST',
  LOAD_TABLES_SUCCESS: 'LOAD_TABLES_SUCCESS',
  LOAD_TABLES_FAILURE: 'LOAD_TABLES_FAILURE',

  CREATE_TABLE_REQUEST: 'CREATE_TABLE_REQUEST',
  CREATE_TABLE_SUCCESS: 'CREATE_TABLE_SUCCESS',
  CREATE_TABLE_FAILURE: 'CREATE_TABLE_FAILURE',

  UPDATE_TABLE_REQUEST: 'UPDATE_TABLE_REQUEST',
  UPDATE_TABLE_SUCCESS: 'UPDATE_TABLE_SUCCESS',
  UPDATE_TABLE_FAILURE: 'UPDATE_TABLE_FAILURE',

  DELETE_TABLE_REQUEST: 'DELETE_TABLE_REQUEST',
  DELETE_TABLE_SUCCESS: 'DELETE_TABLE_SUCCESS',
  DELETE_TABLE_FAILURE: 'DELETE_TABLE_FAILURE',

  // Folders

  LOAD_FOLDERS_REQUEST: 'LOAD_FOLDERS_REQUEST',
  LOAD_FOLDERS_SUCCESS: 'LOAD_FOLDERS_SUCCESS',
  LOAD_FOLDERS_FAILURE: 'LOAD_FOLDERS_FAILURE',

  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',

  DELETE_FOLDER_REQUEST: 'DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAILURE: 'DELETE_FOLDER_FAILURE',

  UPDATE_FOLDER_REQUEST: 'UPDATE_FOLDER_REQUEST',
  UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAILURE: 'UPDATE_FOLDER_FAILURE',

  COPY_FOLDER_REQUEST: 'COPY_FOLDER_REQUEST',
  COPY_FOLDER_SUCCESS: 'COPY_FOLDER_SUCCESS',
  COPY_FOLDER_FAILURE: 'COPY_FOLDER_FAILURE',

  /**
   * TABLE RESULTS
   */
  LOAD_TABLE_RESULT_REQUEST: 'LOAD_TABLE_RESULT_REQUEST',
  LOAD_TABLE_RESULT_SUCCESS: 'LOAD_TABLE_RESULT_SUCCESS',
  LOAD_TABLE_RESULT_FAILURE: 'LOAD_TABLE_RESULT_FAILURE',
  LOAD_TABLE_RESULT_CANCEL: 'LOAD_TABLE_RESULT_CANCEL'
};
