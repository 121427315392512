import { useCallback, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { isArray, uniqBy } from 'lodash';

import './SearchInput.scss';

/**
 * Renders a search input field to filter lists or tables
 * @param {array} allItems //an array of objects of all items that should be filtered
 * @param {function} setFilteredItems //function that sets the filtered items (most likely a useState setter function)
 * @param {array} filterValues //the values that should be filtered by, eg "lastName", in an array of strings
 * @param {string} placeholderText //the text that should be shown in the placeholder
 * @param {string} uniquenessKey //needed in an array of filterValues to filter duplicates by this key (eg id, 'iso3', ...). default: id
 * @param {string} inputId //needed in an array of filterValues to filter duplicates by this key (eg id, 'iso3', ...). default: id
 */
export const SearchInput = ({
  allItems,
  setFilteredItems,
  filterValues,
  placeholderText,
  uniquenessKey = 'id',
  inputId = 'basic-search-input'
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const handleSearchInput = useCallback(
    e => {
      setSearchInputValue(e.target.value.toLowerCase());

      if (e.target.value.length <= 0) {
        setFilteredItems([...allItems]);
        return;
      }

      // fallback for old version with just one string
      if (typeof filterValues === 'string') {
        setFilteredItems([
          ...allItems.filter(item => item[filterValues].toLowerCase().includes(e.target.value.toLowerCase()))
        ]);
      }

      let _items = [];
      if (isArray(filterValues)) {
        filterValues.forEach(val => {
          _items.push(...allItems.filter(item => item[val].toLowerCase().includes(e.target.value.toLowerCase())));
        });
        setFilteredItems(uniqBy(_items, uniquenessKey));
      }
    },
    [allItems, filterValues, setFilteredItems, uniquenessKey]
  );

  return (
    <div className='basic-search-input'>
      <Input
        onChange={handleSearchInput}
        value={searchInputValue}
        name='Search'
        type='text'
        placeholder={placeholderText}
        className='basic-search-input__input'
        id={inputId}
      />
      {searchInputValue.length > 0 && (
        <Button
          className='ml-1'
          color={'danger'}
          size={'sm'}
          outline
          onClick={() => {
            setSearchInputValue('');
            setFilteredItems([...allItems]);
          }}
        >
          <i className='far fa-times' />
        </Button>
      )}
    </div>
  );
};
