import React from 'react';
import { useSelector } from 'react-redux';

export const TablePathBreadcrumbs = ({ table }) => {
  const folders = useSelector(state => state.survey.tablesFolders);

  if (!table.id) return null;

  const tablePath = getNodePath(table, folders, []);
  tablePath.pop();
  tablePath.reverse();

  const renderTablePath = tablePath.map(id => {
    const folder = folders.list[id];

    return (
      <li key={id} className='breadcrumb-item'>
        <i className='fal fa-folder mr-1'></i>
        {folder.name}
      </li>
    );
  });

  return (
    <nav className={'small'} aria-label='breadcrumb'>
      <ol className='breadcrumb'>{renderTablePath}</ol>
    </nav>
  );
};

function getNodePath(node, folders, path = []) {
  for (let folder of Object.values(folders.list)) {
    const child = folder.children.find(child => child.id === node.id);
    if (child) {
      path.push(folder.id);
      getNodePath(folder, folders, path);
    }
  }

  return path;
}
