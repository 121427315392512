export const getQuestionElementsTranslationIds = element => {
  let ids = [];
  if (element) {
    // question: help, instruction, prefix, suffix, text
    Object.values(element.translations ?? {}).forEach(id => {
      ids.push(id);
    });
    // choices
    if (element.config?.choices?.order) {
      element.config.choices.order.forEach(choiceId => {
        const choiceTranslations = element.config.choices.list[choiceId].config.translations;
        Object.values(choiceTranslations).forEach(id => {
          ids.push(id);
        });
      });
    }
    // scales
    if (element.config?.scales?.order) {
      element.config.scales.order.forEach(scaleId => {
        const choiceTranslations = element.config.scales.list[scaleId].config.translations;
        Object.values(choiceTranslations).forEach(id => {
          ids.push(id);
        });
      });
    }
  }
  return ids;
};
