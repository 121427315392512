import React from 'react';

import { useRowOrder } from 'smv-helpers';
import { useDefaultMetric } from './helper';
import { DefaultQuestionMenu, SingleVectorChoiceMenu, MultipleVectorChoiceMenu } from './menus';
import { ResultRow, ResultRowHeader } from '.';

export const SystemVarResultRow = ({
  element,
  table,
  tableResult,
  row,
  questionHas,
  isCompactMatrix,
  matrixType,
  handler,
  hover
}) => {
  const { heads, config } = table;
  const { id, config: rowConfig } = row;
  const { showHiddenRows = true } = config;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row, showHiddenRows);
  const colSpan = matrixType === 'detail' ? 2 : 1;

  const defaultMetric = useDefaultMetric(table);

  const rowHeadLabel = element.label;
  const subelements = element ?? {};

  let questionMenu = (
    <DefaultQuestionMenu
      rowid={id}
      row={row}
      element={element}
      subelements={subelements}
      table={table}
      handler={handler}
      questionHas={questionHas}
    />
  );

  const _rows = [];
  rowOrder.forEach(order => {
    const metric = defaultMetric;

    const isHidden = order?.hidden ?? false;
    const isExcluded = order?.excluded ?? false;

    if (!showHiddenRows && (isHidden || isExcluded)) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;

    let choiceMenu = null;
    if (element.varType === 'single') {
      choiceMenu = (
        <SingleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    } else if (element.varType === 'multiple') {
      choiceMenu = (
        <MultipleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    }

    _rows.push(
      <ResultRow
        key={`result-row-${row.id}_${order.subelement}`}
        rowid={id}
        orderItem={order}
        subelement={subelements.list?.[order.subelement]}
        choiceid={order.subelement}
        config={config}
        heads={heads}
        tableResult={tableResult}
        handler={handler}
        metric={metric}
        isHidden={isHidden}
        isExcluded={isExcluded}
        sortationPinned={sortationPinned}
        isCompactMatrix={isCompactMatrix}
        menu={choiceMenu}
        hover={hover}
      >
        {_rows.length === 0 && (
          <ResultRowHeader
            rowid={id}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            colSpan={colSpan}
            handler={handler}
            questionHas={questionHas}
            menu={questionMenu}
          />
        )}
      </ResultRow>
    );
  });

  return _rows;
};
