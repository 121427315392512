import React from 'react';
import PropTypes from 'prop-types';

import {
  CustomText,
  SingleInputText,
  SingleInputNumber,
  MultipleInputText,
  MultipleInputNumber,
  SingleVector,
  MultipleVector,
  SingleMatrix,
  MultipleMatrix,
  CustomShelf
} from '../QuestionTypes/index';

export const QuestionTypeSelector = props => {
  const { question } = props;

  switch (question.config?.qtype) {
    case 'custom_text':
      return <CustomText {...props} />;

    case 'single_input_text':
      return <SingleInputText {...props} />;

    case 'single_input_number':
      return <SingleInputNumber {...props} />;

    case 'multiple_input_text':
      return <MultipleInputText {...props} />;

    case 'multiple_input_number':
      return <MultipleInputNumber {...props} />;

    case 'single_vector':
      return <SingleVector {...props} />;

    case 'multiple_vector':
      return <MultipleVector {...props} />;

    case 'single_matrix':
      return <SingleMatrix {...props} />;

    case 'multiple_matrix':
      return <MultipleMatrix {...props} />;

    case 'custom_shelf':
      return <CustomShelf {...props} />;

    default:
      return <>Question type {question.config?.qtype} not implemented.</>;
  }
};

QuestionTypeSelector.prototype = {
  question: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  writeable: PropTypes.bool.isRequired
};
