import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

import { translationsActions, questionnaireActions } from 'smv-redux';
import { EditableTextHover } from '../../../..';
import { ChoiceDisplayCondition } from '.';

import './ConfigChoiceItem.scss';

export const ConfigChoiceItem = ({
  choice,
  question,
  qtype,
  writeable
  // stringid,
  // sharedChoices,
  // fields
}) => {
  const survey = useSelector(state => state.survey);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';
  const expertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);

  const intl = useIntl();

  const questionnaireConflicts = useSelector(state => state.survey.questionnaire?.conflicts);
  const hasConflicts = questionnaireConflicts?.[question.id]?.choices?.includes(choice.id);

  const surveyid = survey.id;
  const choiceLabel = survey?.translations?.[choice?.config.translations.label]?.[locale];

  const [valueInput, setValueInput] = useState(choice?.value ?? null);

  useEffect(() => {
    if (choice?.value) {
      setValueInput(choice.value);
    }
  }, [choice.value]);

  const internalHandler = {
    /**
     * Updates a choice label
     */
    checkUpdateLabel: useCallback(
      value => {
        if (!writeable) return;
        if (value !== choiceLabel) {
          translationsActions.updateTranslation(surveyid, choice.config.translations.label, {
            [locale]: value
          });
        }
      },
      [choiceLabel, surveyid, locale, writeable, choice.config.translations.label]
    ),
    /**
     * Updates a choice config, e.g. the value, hide condition, randomisation, exclusive state
     */
    updateChoiceConfig: useCallback(
      (key, value) => {
        if (!writeable) return;

        if (key === 'value' && (!value || value <= 0)) return;

        const openEndDefaults = {
          // id: customAlphabet('1234567890abcdef', 24)(),
          input: 'text',
          maxInputLength: 255,
          fieldWidth: 100,
          fieldWidthUnit: '%'
        };

        const isOpenEnd = choice?.config?.openEnd ?? false;

        switch (key) {
          case 'value':
            if (choice.value !== value) {
              const callback = () => {
                setValueInput(choice.value);
              };
              questionnaireActions.updateChoice(surveyid, question.id, choice.id, { value }, callback);
            }
            break;
          case 'randomize':
            if (choice.config.randomize !== value) {
              questionnaireActions.updateChoice(surveyid, question.id, choice.id, { config: { randomize: value } });
            }
            break;
          case 'exclusive':
            if (choice.config.exclusive !== value) {
              questionnaireActions.updateChoice(surveyid, question.id, choice.id, { config: { exclusive: value } });
            }
            break;
          case 'openEnd':
            if (isOpenEnd) {
              questionnaireActions.deleteChoiceOpenEnd(surveyid, question.id, choice.id);
            } else {
              questionnaireActions.updateChoiceOpenEnd(surveyid, question.id, choice.id, openEndDefaults);
            }
            break;
          default:
            break;
        }
      },
      [question.id, choice, surveyid, writeable]
    ),

    /**
     * deletes choice
     */
    deleteChoice: useCallback(() => {
      if (!writeable) return;
      questionnaireActions.removeChoice(surveyid, question.id, choice.id);
    }, [surveyid, question.id, choice.id, writeable])
  };

  return (
    <div className={'choiceItem'}>
      {writeable && (
        <div className={'choiceItem__grab'}>
          <i id={`move_${choice.id}`} className={`fa-grip-vertical fal`}></i>
          <UncontrolledTooltip
            placement={'top'}
            target={`move_${choice.id}`}
            boundariesElement={`config-tab-${question.id}`}
          >
            Drag to move
          </UncontrolledTooltip>
        </div>
      )}

      <div className='choiceItem__label mr-2'>
        <EditableTextHover
          writeable={writeable}
          value={choiceLabel}
          replacing={true}
          onBlur={internalHandler.checkUpdateLabel}
          className='no-padding'
        />
      </div>

      {/* Value */}
      {expertMode && (
        <div className={`choiceItem__value mr-2 ${hasConflicts ? 'choiceItem__value--warning' : ''}`}>
          <Input
            disabled={!writeable}
            bsSize='sm'
            type='number'
            min={1}
            value={valueInput}
            onChange={e => setValueInput(parseInt(e.target.value))}
            onBlur={() => internalHandler.updateChoiceConfig('value', valueInput)}
            id={`value_${choice?.id}`}
            className={`choiceitem-input ${valueInput <= 0 ? 'choiceitem-input--invalid' : ''}`}
          ></Input>
          {valueInput <= 0 && (
            <small className='choiceitem-danger'>
              {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-container.value-must-be-greater-than-0' })}
            </small>
          )}
        </div>
      )}

      {/* Randomized */}
      <div className='choiceItem__pin'>
        <i
          id={`pin_${choice.id}`}
          className={`fa-${choice.config?.randomize ? 'random fas' : 'random fal'} ${writeable ? 'pointer' : ''}`}
          onClick={() => internalHandler.updateChoiceConfig('randomize', !choice.config?.randomize)}
        ></i>
        <UncontrolledTooltip
          placement='top'
          target={`pin_${choice.id}`}
          boundariesElement={`config-tab-${question.id}`}
        >
          {`${choice.config?.randomize ? 'Randomized' : 'Not randomized'}`}
        </UncontrolledTooltip>
      </div>

      {/* Open Response */}
      {['multiple_vector', 'single_vector', 'single_matrix'].includes(qtype) && (
        <div className='choiceItem__pin'>
          <i
            id={`open_response_${choice.id}`}
            className={classnames({
              'fa-comment-lines': true,
              'fas': choice.config?.openEnd,
              'fal': !choice.config?.openEnd,
              'pointer': writeable
            })}
            onClick={() => internalHandler.updateChoiceConfig('openEnd')}
          />
          <UncontrolledTooltip
            placement='top'
            target={`open_response_${choice.id}`}
            boundariesElement={`config-tab-${question.id}`}
          >
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-container.${choice.config?.openEnd ? 'open-end' : 'not-open-end'}`}
            />
          </UncontrolledTooltip>
        </div>
      )}

      {/* Exclusive */}
      {qtype === 'multiple_vector' && (
        <div
          onClick={() => internalHandler.updateChoiceConfig('exclusive', !choice?.config?.exclusive)}
          className='text-center'
        >
          <i
            id={`exclusive_${choice.id}`}
            className={classnames(
              'fa-exclamation-square',
              choice.config?.exclusive ? 'fas' : 'fal',
              writeable ? 'pointer' : ''
            )}
          ></i>
          <UncontrolledTooltip
            placement='top'
            target={`exclusive_${choice.id}`}
            boundariesElement={`config-tab-${question.id}`}
          >
            {`${choice.config?.exclusive ? 'Exclusive' : 'Not exclusive'}`}
          </UncontrolledTooltip>
        </div>
      )}

      {/* display / hide condition */}
      <div className='pointer text-center'>
        <ChoiceDisplayCondition
          question={question}
          choice={choice}
          writeable={writeable}
          containerId={`config-tab-${question.id}`}
        />
      </div>

      {/* delete */}
      {writeable && (
        <div className='choiceItem__trash'>
          <i id={`trash_${choice.id}`} className='far fa-trash-alt pointer' onClick={internalHandler.deleteChoice} />
          <UncontrolledTooltip
            placement='top'
            target={`trash_${choice.id}`}
            boundariesElement={`config-tab-${question.id}`}
          >
            <FormattedMessage id={'smoove.misc.delete'} />
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  );
};
