import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const ResultTableOpenEndedListingHead = ({ table }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire.list);
  const { heads, rows } = table;

  const rowElement = rows.list[rows.order[0]];
  const element = questionnaire[rowElement.sourceId];

  const headColumns = useMemo(
    () =>
      heads.order.map(headid => {
        const headElement = heads.list[headid];
        const element = questionnaire[headElement.elementid];
        return (
          <div key={`head-column-${headid}`} className={'result-table--cell'}>
            <div className={'result-table--head-cell'}>{element.title}</div>
          </div>
        );
      }),
    [questionnaire, heads]
  );

  return (
    <div className={'result-table--head'}>
      <div className={'result-table--row'}>
        <div className={'result-table--cell'}>&nbsp;</div>
        <div className={'result-table--cell'}>#</div>
        <div className={'result-table--cell'}>{element.title}</div>

        {/* additional columns */}
        {headColumns}
      </div>
    </div>
  );
};
