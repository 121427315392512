import React, { useCallback } from 'react';
import { DropdownItem } from 'reactstrap';
import produce from 'immer';
import { isArray } from 'lodash';
import classNames from 'classnames';

import { useControlledState } from 'smv-helpers';
import { SmvCustomDropdown } from '../../../../misc';
import {
  RowQuestionSettingsButton,
  RowQuestionSettingsModal,
  StaticRowOrHeadFilterButton,
  StaticRowOrHeadFilterModal
} from '../../modals';
import { ResultRowHeaderLoopSelect } from '..';

export const MultipleVectorQuestionMenu = ({ handler, rowid, row, element, subelements, table, questionHas }) => {
  const [SettingsButton, SettingsModal] = useControlledState(RowQuestionSettingsButton, RowQuestionSettingsModal);
  const [StaticFilterButton, StaticFilterModal] = useControlledState(
    StaticRowOrHeadFilterButton,
    StaticRowOrHeadFilterModal
  );

  const { config = {} } = row;
  const { countedValue = 'selected', includeMissings = false } = config;

  const setCountedValue = useCallback(
    type => {
      handler.setTableConfig(state =>
        produce(state, draft => {
          if (isArray(draft.rows.list[rowid].config)) draft.rows.list[rowid].config = {};
          draft.rows.list[rowid].config.countedValue = type;
        })
      );
    },
    [handler, rowid]
  );

  const toggleIncludeMissing = useCallback(() => {
    handler.setTableConfig(state =>
      produce(state, draft => {
        if (isArray(draft.rows.list[rowid].config)) draft.rows.list[rowid].config = {};
        draft.rows.list[rowid].config.includeMissings = !draft.rows.list[rowid].config.includeMissings;
      })
    );
  }, [handler, rowid]);

  return (
    <>
      <SmvCustomDropdown>
        {(isParentDropdownOpen, toggleParentDropdown) => (
          <>
            {questionHas.isLooped && (
              <>
                <ResultRowHeaderLoopSelect
                  rowid={rowid}
                  row={row}
                  handler={handler}
                  loopParents={questionHas.loopParents}
                  toggleParentDropdown={toggleParentDropdown}
                  isParentDropdownOpen={isParentDropdownOpen}
                />
                <DropdownItem divider />
              </>
            )}

            {/* COUNTED VALUES: selected|nonselected|missing */}
            <DropdownItem header>Counted Value</DropdownItem>
            <DropdownItem
              className={classNames({ active: countedValue === 'selected' })}
              onClick={() => setCountedValue('selected')}
            >
              <div className='smv-custom-dropdown__buttons'>Selected (1)</div>
            </DropdownItem>
            <DropdownItem
              className={classNames({ active: countedValue === 'nonselected' })}
              onClick={() => setCountedValue('nonselected')}
            >
              <div className='smv-custom-dropdown__buttons'>Not Selected (0)</div>
            </DropdownItem>
            <DropdownItem
              className={classNames({ active: countedValue === 'missing' })}
              onClick={() => setCountedValue('missing')}
            >
              <div className='smv-custom-dropdown__buttons'>Missing Values (-77)</div>
            </DropdownItem>

            {/* MISSINGS IN BASE true|false */}
            <DropdownItem divider />
            <DropdownItem header>Include Missings in Base</DropdownItem>
            <DropdownItem
              className={classNames({ active: countedValue === 'missing' || includeMissings === true })}
              onClick={toggleIncludeMissing}
            >
              <div className='smv-custom-dropdown__buttons'>Include Missings (-77)</div>
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem>
              <SettingsButton mode={'edit'} />
            </DropdownItem>
            <DropdownItem>
              <StaticFilterButton />
            </DropdownItem>
            <DropdownItem onClick={() => handler.removeElement(rowid, 'rows')}>
              <div className='smv-custom-dropdown__buttons'>
                <i className='fal fa-trash-alt mr-1' />
                <span>{`remove`}</span>
              </div>
            </DropdownItem>
          </>
        )}
      </SmvCustomDropdown>
      <SettingsModal
        rowid={rowid}
        row={row}
        element={element}
        subelements={subelements}
        table={table}
        handler={handler}
        questionHas={questionHas}
      />
      <StaticFilterModal rowOrHead={row} mode={'rows'} handler={handler} />
    </>
  );
};
