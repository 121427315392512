import { useMemo } from 'react';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';

import { questionnaireActions, translationsActions } from 'smv-redux/actions';

export const useSaveHandler = (question, surveyid) => {
  const translations = useSelector(state => state.survey?.translations ?? {});

  return useMemo(
    () => ({
      setInputCount: e => {
        const value = parseFloat(e?.target?.value) ?? 1;
        if (value !== question.config?.inputCount) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              inputCount: value
            }
          });
        }
      },
      setSuffix: (value, locale) => {
        const queSuffix = translations[question.translations.suffix]?.[locale];
        if (value !== queSuffix) {
          translationsActions.updateTranslation(surveyid, question.translations.suffix, {
            [locale]: value
          });
        }
      },
      setPrefix: (value, locale) => {
        const quePrefix = translations[question.translations.prefix]?.[locale];
        if (value !== quePrefix) {
          translationsActions.updateTranslation(surveyid, question.translations.prefix, {
            [locale]: value
          });
        }
      },
      setMinValue: e => {
        const value = e.target.value;

        if (value !== question.config?.minValue) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              minValue: value
            }
          });
        }
      },
      setMaxValue: e => {
        const value = e.target.value;

        if (value !== question.config?.maxValue) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              maxValue: value
            }
          });
        }
      },
      setMaxLength: e => {
        const value = e.target.value;

        if (value !== question.config?.maxInputLength) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              maxInputLength: value
            }
          });
        }
      },
      setLineCount: e => {
        const value = e.target.value;

        if (value !== question.config?.lines) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              lines: value
            }
          });
        }
      },
      setFieldWidth: e => {
        const { value } = e.target;
        if (value !== question.config?.fieldWidth && isNumber(parseInt(value))) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              fieldWidth: value
            }
          });
        }
      },
      setFieldWidthUnit: e => {
        const { value = 'em' } = e.target;
        if (value !== question.config?.fieldWidthUnit) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              fieldWidthUnit: value
            }
          });
        }
      },
      setFieldPercent: e => {
        const { value } = e.target;
        if (value !== question.config?.fieldPercent) {
          questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
            config: {
              fieldPercent: value
            }
          });
        }
      }
    }),
    [question, surveyid, translations]
  );
};
