export const bracketColorDefinitions = [
  {
    color: '#5C415D',
    textColor: 'white'
  },
  {
    color: '#188FA7',
    textColor: 'white'
  },
  {
    color: '#79B473',
    textColor: 'white'
  },
  {
    color: '#E94F37',
    textColor: 'white'
  },
  {
    color: '#393E41',
    textColor: 'white'
  },
  {
    color: '#D2FDFF',
    textColor: 'black'
  },
  {
    color: '#FFFC31',
    textColor: 'black'
  },
  {
    color: '#D58936',
    textColor: 'black'
  },
  {
    color: '#A44200',
    textColor: 'black'
  },
  {
    color: '#E56B6F',
    textColor: 'black'
  }
];
