import React from 'react';

import { ApplyToValues } from '.';

export const ApplyToTypeCalculation = ({ conditionalFormat, main, dimensionKey, handleApplyToSelect }) => {
  return (
    <ApplyToValues
      conditionalFormat={conditionalFormat}
      label={main.config.title}
      main={main}
      dimensionKey={dimensionKey}
      values={{ order: [main.id], list: { [main.id]: { id: main.id, label: main.config.title } } }}
      handleApplyToSelect={handleApplyToSelect}
    />
  );
};
