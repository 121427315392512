import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
// import { Store as notify } from 'react-notifications-component';
// import { useIntl } from 'react-intl';

import './DynamicSplitList.scss';
import produce from 'immer';
import { reportsActions } from 'src/smoove/redux/actions';
import { useSelector } from 'react-redux';

// const defaultNotifyProps = {
//   insert: 'bottom',
//   container: 'bottom-center',
//   animationIn: ['animate__animated', 'animate__fadeIn'],
//   animationOut: ['animate__animated', 'animate__fadeOut'],
//   dismiss: {
//     duration: 3000,
//     showIcon: true
//   }
// };

export const DynamicSplitsList = ({ internalSplitList, handler, report }) => {
  // const intl = useIntl();

  const surveyId = useSelector(state => state.survey.id);

  const splitList = useMemo(() => {
    return internalSplitList?.order?.map((splitid, idx) => (
      <DynamicSplitListElement key={splitid} split={internalSplitList.list[splitid]} handler={handler} index={idx} />
    ));
  }, [handler, internalSplitList?.order, internalSplitList.list]);

  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "#829ec9" : "#fafafa",
  });

  const reorderSplits = useCallback(
    result => {
      const { destination, source } = result;

      if (source.droppableId === 'splits-list-droppable') {
        if (destination.index === source.index) {
          return;
        }
        const newOrder = arrayMoveImmutable(internalSplitList.order, source.index, destination.index);

        handler.setInternalSplitList(state =>
          produce(state, draft => {
            draft.order = newOrder;
          })
        );

        reportsActions.updateReport(
          surveyId,
          report.id,
          produce(report, draft => {
            draft.splits.order = newOrder;
          })
        );
      }
    },
    [internalSplitList.order, surveyId, handler, report]
  );

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId === 'splits-list-droppable') {
      reorderSplits(result);
    }
  };

  return (
    <>
      <ModalHeader toggle={handler.close}>
        <FormattedMessage id={'smoove.page.common.dynamic-splits.label'} defaultMessage={'Splitting'} />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <h6>
              <FormattedMessage
                id={'smoove.page.common.dynamic-splits.available-splits'}
                defaultMessage={'Available Splits'}
              />
            </h6>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='splits-list-droppable'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className='droppable-default'
                  >
                    {splitList}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button onClick={handler.createSplit} color={'primary'} className='mt-2'>
              <FormattedMessage id={'smoove.page.common.dynamic-splits.add-split'} defaultMessage={'Add split'} />
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={handler.close}>
          <FormattedMessage id={'smoove.common.buttons.close'} defaultMessage={'close'} />
        </Button>
      </ModalFooter>
    </>
  );
};

const DynamicSplitListElement = ({ split, handler, index }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // margin: '4px',
    // background: isDragging ? "#8aa91b" : "#e5e5e5",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <Draggable key={split.id} draggableId={split.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className='draggable-default'
        >
          <div className='dynamic-split-list-element'>
            <i className='fal fa-grip-vertical ml-2'></i>
            <span className='dynamic-split-list-element__label mx-2'>{split?.label ?? 'unnamed split'}</span>
            <ButtonGroup size={'sm'}>
              <Button
                id={'edit-split-' + split.id}
                outline={true}
                color={'secondary'}
                onClick={() => handler.setSelectedSplit(split.id)}
              >
                <i className='fal fa-edit' />
              </Button>
              <UncontrolledTooltip target={'edit-split-' + split.id}>
                <FormattedMessage id={'smoove.page.common.dynamic-splits.edit-split'} defaultMessage={'Edit split'} />
              </UncontrolledTooltip>
              <Button
                id={'delete-split-' + split.id}
                outline={true}
                color={'danger'}
                onClick={() => handler.deleteSplit(split.id)}
              >
                <i className='fal fa-trash-alt' />
              </Button>
              <UncontrolledTooltip target={'delete-split-' + split.id}>
                <FormattedMessage
                  id={'smoove.page.common.dynamic-splits.delete-split'}
                  defaultMessage={'Delete split'}
                />
              </UncontrolledTooltip>
            </ButtonGroup>
          </div>
        </div>
      )}
    </Draggable>
  );
};
