import React from 'react';

import './CustomDragPreview.scss';

export const CustomDragPreview = props => {
  const item = props.monitorProps.item;

  return (
    <div className={'custom-drag-preview'}>
      <div className={'custom-drag-preview__label'}>{item.text}</div>
    </div>
  );
};
