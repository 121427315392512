export const sharedReportActionTypes = {
  LOAD_REPORT_REQUEST: 'LOAD_REPORT_REQUEST',
  LOAD_REPORT_SUCCESS: 'LOAD_REPORT_SUCCESS',
  LOAD_REPORT_FAILURE: 'LOAD_REPORT_FAILURE',

  /**
   * REPORT ELEMENT RESULTS
   */
  LOAD_REPORT_ELEMENT_RESULT_REQUEST: 'LOAD_REPORT_ELEMENT_RESULT_REQUEST',
  LOAD_REPORT_ELEMENT_RESULT_SUCCESS: 'LOAD_REPORT_ELEMENT_RESULT_SUCCESS',
  LOAD_REPORT_ELEMENT_RESULT_FAILURE: 'LOAD_REPORT_ELEMENT_RESULT_FAILURE',
  LOAD_REPORT_ELEMENT_RESULT_CANCEL: 'LOAD_REPORT_ELEMENT_RESULT_CANCEL'
};
