import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Stone } from 'smv-components';

import { userActions } from 'smv-redux';

export const Logout = () => {
  const history = useHistory();
  const intl = useIntl();

  const authentication = useSelector(state => state.authentication);

  useEffect(() => {
    const { loggedIn, loggedOut, loggingOut } = authentication;

    if (loggedIn && !loggingOut) userActions.logout();
    else if (loggedOut || loggingOut) {
      setTimeout(() => history.push('/login'), 1000);
    } else if (!loggedIn && !loggedOut && !loggingOut) {
      history.push('/login');
    }
  }, [authentication, history]);

  return (
    <>
      <Stone type={'D'} color={'green'} width={'400px'} position={['20%', null, null, 0]} translate={[-250, 0]} />
      <Stone type={'B'} color={'orange'} width={'400px'} position={['50%', 0]} translate={[90, 0]} />
      <Stone
        type={'A'}
        color={'blue'}
        width={'500px'}
        position={[null, null, 0, '50%', 'translateX(-50%)']}
        translate={[0, 300]}
      />

      <Helmet>
        <title>{intl.formatMessage({ id: 'core.page.logout.headline', defaultMessage: 'Logout' })} - smoove</title>
      </Helmet>

      <div className='base-content d-flex flex-fill container-fluid position-relative'>
        <div className={'container-fluid p-0'} style={{ maxWidth: 800 }}>
          <h2 className='text-uppercase text-smv-red fw-bold'>
            <FormattedMessage id={'core.page.logout.headline'} />
          </h2>
          <div className='mt-5'>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
            <FormattedMessage id={'core.page.logout.subline'} />
          </div>
        </div>
      </div>
    </>
  );
};
