import React, { useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { customAlphabet } from 'nanoid';
import { isArray } from 'lodash';
import produce from 'immer';

import { isLooped } from 'smv-helpers';
import { ResultTableOpenEndedListingHead, ResultTableOpenEndedListingBody, ResultTableOpenEndedListingFooter } from '.';

import './ResultTableOpenEndedListing.scss';

const whitelistHeadVars = ['single_vector'];

export const ResultTableOpenEndedListing = ({ table, tableResult, handler, isLoading }) => {
  const questions = useSelector(state => state.survey.questionnaire.list);
  const row = table.rows.list[table.rows.order[0]];
  const elementid = row.sourceId;
  const element = questions[elementid];

  const onDropHead = useCallback(
    item => {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          const headid = customAlphabet('1234567890abcdef', 24)();
          draftState.heads.order.push(headid);

          if (isArray(draftState.heads.list)) draftState.heads.list = {};
          draftState.heads.list[headid] = {
            id: headid,
            source: item?.type ?? 'question',
            elementid: item.id
          };
        })
      );
    },
    [handler]
  );

  const [{ isOverHead, canDropHead }, dropHead] = useDrop({
    accept: ['question', 'sysvar', 'calcvar'],
    drop: onDropHead,
    canDrop: item => {
      if (
        ['sysvar', 'calcvar'].includes(item.type) ||
        (item.type === 'question' && whitelistHeadVars.includes(item?.config?.qtype))
      )
        return true;
      else return false;
    },
    collect: monitor => ({
      isOverHead: monitor.isOver(),
      canDropHead: monitor.canDrop()
    })
  });

  const questionHas = useMemo(() => {
    const { isLoopedQuestion, loopParents } = isLooped(questions, element);
    const questionHas = {
      isLooped: isLoopedQuestion,
      loopParents: loopParents
    };

    return questionHas;
  }, [element, questions]);

  return (
    <>
      <div className={'result-table-container'}>
        {isLoading && (
          <div className='loading-overlay'>
            <span className={'fas fa-spinner fa-spin fa-3x'}></span>
          </div>
        )}
        <div className={'result-table-listing'}>
          <ResultTableOpenEndedListingHead
            table={table}
            row={row}
            questionHas={questionHas}
            element={element}
            handler={handler}
            headRef={dropHead}
            isOverHead={isOverHead}
            canDropHead={canDropHead}
          />
          <ResultTableOpenEndedListingBody table={table} tableResult={tableResult} />
        </div>
      </div>
      <ResultTableOpenEndedListingFooter table={table} tableResult={tableResult} handler={handler} />
    </>
  );
};
