import React, { useState, useMemo } from 'react';

import { ResultTableOpenEndedListing } from './table/table-listings/ResultTableOpenEndedListing';

export const TableChartListing = ({ chartConfig, table, tableResult, isLoading }) => {
  const row = table.rows.list[table.rows.order[0]];

  // todo: ignore the table limit & offset here when the whole table is returned from the backend (instead of the filtered one)
  const [limit, setLimit] = useState(row?.config?.limit ?? 25);
  const [offset, setOffset] = useState(row?.config?.offset ?? 0); //offset = page

  const handler = useMemo(() => {
    return {
      handleChangeLimit: e => {
        setLimit(e.target.value);
      },
      handleChangeOffset: e => {
        if (e === 0) {
          setOffset(0);
        } else {
          setOffset(e.target.value);
        }
      }
    };
  }, []);

  return (
    <ResultTableOpenEndedListing
      table={table}
      tableResult={tableResult}
      handler={handler}
      limit={limit}
      offset={offset}
      isLoading={isLoading}
    />
  );
};
