import React, { useCallback, useEffect, useMemo } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEqual, isArray } from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import produce from 'immer';

import { useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';

export const ManualSortation = ({
  handler,
  sortationKey = 'manualSortation',
  sortationConfig,
  subelements,
  row,
  table
}) => {
  const { isDetailedMatrix } = useMatrixType(table);

  const sortationMode = useMemo(() => {
    if (sortationKey === 'manualSortation') return 'sortationMode';
    if (sortationKey === 'manualHeadSortation') return 'headSortationMode';
  }, [sortationKey]);

  const isSortedManually = useMemo(() => sortationConfig?.[sortationMode] === 'manual', [
    sortationMode,
    sortationConfig
  ]);

  const _subelements = useMemo(() => {
    const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};

    if (!subelements) {
      return {
        list: {},
        order: []
      };
    } else {
      return {
        list: { ...(subelements?.list ?? {}), ...(isDetailedMatrix ? {} : calcFrequencyGroups) },
        order: [...(subelements?.order ?? []), ...Object.keys(isDetailedMatrix ? {} : calcFrequencyGroups)]
      };
    }
  }, [subelements, row?.config?.calcFrequencyGroups, isDetailedMatrix]);

  useEffect(() => {
    handler.setInternalConfig(state =>
      produce(state, draft => {
        if (!draft?.sortation || isArray(draft.sortation)) {
          draft.sortation = {
            [sortationKey]: {
              order: []
            }
          };
        } else if (!draft?.sortation?.[sortationKey] || isArray(draft.sortation[sortationKey])) {
          draft.sortation[sortationKey] = {
            order: []
          };
        }

        // set inital order/ check for updated original order
        if (draft.sortation[sortationMode] === 'manual') {
          if (_subelements.order.length === 0) draft.sortation[sortationKey].order = [];

          // arrays are unequal; remove deleted elements/ add new elements to the end
          if (
            draft.sortation[sortationKey].order.length !== _subelements.order.length ||
            !isEqual([...draft.sortation[sortationKey].order].sort(), [..._subelements.order].sort())
          ) {
            // removes deleted elements
            const _columnOrder =
              draft.sortation[sortationKey].order.filter(subid => _subelements.order.includes(subid)) ?? [];

            // adds new elements
            _subelements.order.forEach(subid => {
              if (!_columnOrder.includes(subid)) _columnOrder.push(subid);
            });

            draft.sortation[sortationKey].order = _columnOrder;
          }
        }
      })
    );
  }, [handler, sortationKey, _subelements, sortationMode]);

  const toggleManualSortation = useCallback(
    e => {
      const { checked } = e.target;
      handler.setInternalConfig(state =>
        produce(state, draft => {
          if (checked) draft.sortation[sortationMode] = 'manual';
          else if (!checked) draft.sortation[sortationMode] = null;
          // set initial order if it does not exist
          if (
            checked &&
            (draft.sortation[sortationKey].order.length !== _subelements.order.length ||
              !isEqual([...draft.sortation[sortationKey].order].sort(), [..._subelements.order].sort()))
          ) {
            // removes deleted elements
            const _columnOrder =
              draft.sortation[sortationKey].order.filter(subid => _subelements.order.includes(subid)) ?? [];

            // adds new elements
            _subelements.order.forEach(subid => {
              if (!_columnOrder.includes(subid)) _columnOrder.push(subid);
            });

            draft.sortation[sortationKey].order = _columnOrder;
          }
        })
      );
    },
    [handler, _subelements.order, sortationKey, sortationMode]
  );

  const handleResetPosition = useCallback(() => {
    handler.setInternalConfig(state =>
      produce(state, draft => {
        draft.sortation[sortationKey].order = _subelements.order;
      })
    );
  }, [handler, sortationKey, _subelements.order]);

  const handleReposition = useCallback(
    (fromIndex, toIndex) => {
      handler.setInternalConfig(state =>
        produce(state, draft => {
          draft.sortation[sortationKey].order = arrayMoveImmutable(
            draft.sortation[sortationKey].order,
            fromIndex,
            toIndex
          );
        })
      );
    },
    [handler, sortationKey]
  );

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    handleReposition(result.source.index, result.destination.index);
  };

  const isSortingEnabled = isSortedManually && sortationConfig?.[sortationKey]?.order?.length > 0 ? true : false;

  const elements = useMemo(() => {
    return sortationConfig?.[sortationKey]?.order?.length > 0
      ? sortationConfig[sortationKey].order
      : _subelements.order;
  }, [_subelements.order, sortationKey, sortationConfig]);

  return (
    <>
      <div className='mb-2'>
        <h6>Manual sorting</h6>
        <FormGroup switch>
          <Input
            type='switch'
            role='switch'
            id={`manual_head_sorting`}
            name='manualHeadSorting'
            checked={isSortedManually}
            onChange={toggleManualSortation}
          />
          <Label for={'manual_head_sorting'}>Sort manually</Label>
        </FormGroup>
      </div>
      {isSortedManually && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable-manual-sortation'>
            {(provided, snapshot) => (
              <div
                style={{ maxWidth: '450px' }}
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`droppable-default ${!isSortingEnabled ? 'droppable-default--disabled' : ''}`}
              >
                {elements.map((colId, idx) => {
                  return (
                    <OrderListElement
                      key={colId}
                      subelement={_subelements.list?.[colId]}
                      id={colId}
                      oldOrder={_subelements.order}
                      currentOrder={sortationConfig[sortationKey].order}
                      handler={handler}
                      handleReposition={handleReposition}
                      handleResetPosition={handleResetPosition}
                      index={idx}
                      isDragDisabled={!isSortingEnabled}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

const OrderListElement = ({ id, index, isDragDisabled, subelement }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // margin: '4px',
    // background: isDragging ? "#8aa91b" : "#e5e5e5",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const label = useSubelementPropertyTranslation(subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className={`draggable-default py-2`}
        >
          <i className='fal fa-grip-vertical ml-2'></i>
          <span className='ml-2 node__title'>{label}</span>
        </div>
      )}
    </Draggable>
  );
};
