import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, DropdownItem, Input, Row } from 'reactstrap';
import { customAlphabet } from 'nanoid';
import { ChromePicker } from 'react-color';
import { useIntl } from 'react-intl';
import produce from 'immer';
import { isFinite, isMatch } from 'lodash';

import { SmvCustomDropdown } from 'src/smoove/components/misc';
import { ReferenceDropdown, ApplyToDropdown } from '.';

const INITAL_FORMAT = {
  id: null,
  // type of the condition, `value` uses the cells own value, `difference`
  // the difference between the cells and the reference cells value
  conditionType: 'difference', // 'value' | 'difference',
  // optional, if conditionType === `difference`
  reference: {
    rowId: null,
    rowSubId: null,
    rowSubSubId: null,
    headId: null,
    headSubId: null
  },
  operator: 'GE',
  values: [0],
  applyTo: {
    rows: {},
    heads: {}
  },
  formats: {
    backgroundColor: ''
  }
};

export const ConditionalFormat = ({ setConditionalFormats, conditionalFormat = INITAL_FORMAT, tableConfig }) => {
  const intl = useIntl();

  const [_conditionalFormat, setConditionalFormat] = useState(conditionalFormat);
  const [canBeSaved, setCanBeSaved] = useState(false);
  const conditionalFormattingContainerRef = useRef(null);

  useEffect(() => {
    const hasColorSelected = _conditionalFormat.formats.backgroundColor.length > 0;
    const needsReference = _conditionalFormat.conditionType === 'difference';
    const hasReferenceSelected = !!_conditionalFormat.reference.rowId || !!_conditionalFormat.reference.headId;
    const hasValuesDefined =
      (_conditionalFormat.operator !== 'RANGE' && isFinite(_conditionalFormat.values?.[0])) ||
      (_conditionalFormat.operator === 'RANGE' &&
        isFinite(_conditionalFormat.values?.[0]) &&
        isFinite(_conditionalFormat.values?.[1]));

    if (hasColorSelected && (!needsReference || hasReferenceSelected) && hasValuesDefined) {
      setCanBeSaved(true);
    } else {
      setCanBeSaved(false);
    }
  }, [_conditionalFormat]);

  const handleDefaultChange = useCallback(e => {
    const { value, name } = e.target;
    setConditionalFormat(s =>
      produce(s, d => {
        d[name] = value;
      })
    );
  }, []);

  const handleValueChange = useCallback(e => {
    const { value, name } = e.target;
    const valueIndex = parseInt(e.target.dataset.valueindex);
    const _value = parseFloat(value);

    setConditionalFormat(s =>
      produce(s, d => {
        d[name][valueIndex] = _value;
      })
    );
  }, []);

  const handleColorChange = useCallback(color => {
    setConditionalFormat(s =>
      produce(s, d => {
        d.formats.backgroundColor = color.hex;
      })
    );
  }, []);

  const handleReferenceSelect = useCallback(reference => {
    setConditionalFormat(s =>
      produce(s, d => {
        // check if reference is already set
        if (isMatch(d.reference, reference)) {
          Object.keys(reference).forEach(key => (d.reference[key] = null));
        }
        // otherwise write new reference keys
        else {
          d.reference = { ...d.reference, ...reference };
        }
      })
    );
  }, []);

  const handleApplyToSelect = useCallback(update => {
    setConditionalFormat(s =>
      produce(s, d => {
        if (d.applyTo?.[update.dimensionKey] === undefined) d.applyTo = { rows: {}, heads: {}, ...d.applyTo };

        if (!update?.subId) {
          if (d.applyTo[update.dimensionKey]?.[update.mainId] === undefined) {
            d.applyTo[update.dimensionKey][update.mainId] = true;
          } else if (typeof d.applyTo[update.dimensionKey][update.mainId] === 'object') {
            d.applyTo[update.dimensionKey][update.mainId] = true;
          } else if (d.applyTo[update.dimensionKey][update.mainId] === true) {
            delete d.applyTo[update.dimensionKey][update.mainId];
          }
        } else {
          if (
            d.applyTo[update.dimensionKey]?.[update.mainId] === undefined ||
            typeof d.applyTo[update.dimensionKey][update.mainId] === 'boolean'
          ) {
            d.applyTo[update.dimensionKey][update.mainId] = { [update.subId]: true };
          } else if (typeof d.applyTo[update.dimensionKey][update.mainId] === 'object') {
            if (d.applyTo[update.dimensionKey][update.mainId]?.[update.subId] === undefined) {
              d.applyTo[update.dimensionKey][update.mainId][update.subId] = true;
            } else {
              delete d.applyTo[update.dimensionKey][update.mainId][update.subId];
            }
          }
        }
      })
    );
  }, []);

  const saveFormatHandler = () => {
    setConditionalFormats(s =>
      produce(s, d => {
        let formatId = _conditionalFormat.id;
        if (!formatId) {
          formatId = customAlphabet('1234567890abcdef', 24)();
          d.order.push(formatId);
        }

        d.list[formatId] = {
          ..._conditionalFormat,
          id: formatId
        };

        if (!_conditionalFormat.id) setConditionalFormat(INITAL_FORMAT);
      })
    );
  };

  const duplicateFormatHandler = () => {
    setConditionalFormats(s =>
      produce(s, d => {
        const formatId = customAlphabet('1234567890abcdef', 24)();
        d.order.push(formatId);
        d.list[formatId] = produce(_conditionalFormat, d => {
          d.id = formatId;
        });
      })
    );
  };

  const deleteFormatHandler = () => {
    setConditionalFormats(s =>
      produce(s, d => {
        d.order = d.order.filter(v => v !== _conditionalFormat.id);
        delete d.list[_conditionalFormat.id];
      })
    );
  };

  return (
    <Row className='w-100'>
      <Col className={'conditional-format__container'}>
        <div className='conditional-format__condition-container' ref={conditionalFormattingContainerRef}>
          <span className='mr-2'>
            {intl.formatMessage({
              id: 'smoove.page.tables.chart-config.conditional-formatting-table.intro-if',
              defaultMessage: 'If'
            })}
          </span>

          <Input
            id={'conditionType'}
            name={'conditionType'}
            type={'select'}
            value={_conditionalFormat.conditionType}
            onChange={handleDefaultChange}
            className='conditional-format__input'
          >
            <option value={'value'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.condition-type.value',
                defaultMessage: 'cell value'
              })}
            </option>
            <option value={'difference'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.condition-type.difference',
                defaultMessage: 'difference to reference value'
              })}
            </option>
          </Input>

          {_conditionalFormat.conditionType === 'difference' && (
            <ReferenceDropdown
              tableConfig={tableConfig}
              conditionalFormat={_conditionalFormat}
              handleReferenceSelect={handleReferenceSelect}
            />
          )}

          <Input
            id={'operator'}
            name={'operator'}
            type={'select'}
            value={_conditionalFormat.operator}
            onChange={handleDefaultChange}
            className='conditional-format__input'
          >
            <option value={'GE'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.greater-or-equal-to'
              })}
            </option>
            <option value={'LE'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.lower-or-equal-to'
              })}
            </option>
            <option value={'RANGE'}>
              {intl.formatMessage({ id: 'smoove.page.tables.chart-config.conditional-formatting-table.between' })}
            </option>
          </Input>

          {_conditionalFormat.operator !== 'RANGE' && (
            <Input
              id={'values'}
              name={'values'}
              data-valueindex={0}
              type={'number'}
              value={`${_conditionalFormat.values?.[0]}`}
              onChange={handleValueChange}
              className='conditional-format__value-input'
            />
          )}

          {_conditionalFormat.operator === 'RANGE' && (
            <>
              <Input
                id={'values-0'}
                name={'values'}
                data-valueindex={0}
                type={'number'}
                value={`${_conditionalFormat.values?.[0]}`}
                onChange={handleValueChange}
                className='conditional-format__value-input'
              />
              <span className='mr-2'>
                {intl.formatMessage({
                  id: 'smoove.page.tables.chart-config.conditional-formatting-table.values.range',
                  defaultMessage: ' and '
                })}
              </span>

              <Input
                id={'values-1'}
                name={'values'}
                data-valueindex={1}
                type={'number'}
                value={`${_conditionalFormat.values?.[1]}`}
                onChange={handleValueChange}
                className='conditional-format__value-input'
              />
            </>
          )}
          <span className='mr-1 fas fa-colon'></span>
          <ApplyToDropdown
            tableConfig={tableConfig}
            conditionalFormat={_conditionalFormat}
            handleApplyToSelect={handleApplyToSelect}
            container={conditionalFormattingContainerRef}
          />
          <div className='d-flex align-items-center'>
            <SmvCustomDropdown
              fixedMenu={true}
              container={conditionalFormattingContainerRef}
              faIconString={'far fa-fill-drip icon-smv-blue p-1'}
              className={'conditional-format__color-dropdown'}
            >
              <DropdownItem toggle={false}>
                {intl.formatMessage({
                  id: 'smoove.page.tables.chart-config.conditional-formatting-table.format-dropdown.header',
                  defaultMessage: 'Formats'
                })}
                <ChromePicker
                  color={_conditionalFormat.formats.backgroundColor}
                  disableAlpha={true}
                  onChange={handleColorChange}
                  onChangeComplete={handleColorChange}
                />
              </DropdownItem>
            </SmvCustomDropdown>

            {_conditionalFormat.formats.backgroundColor && (
              <span
                className={`mr-1 conditional-format__backgroundcolor ${
                  !_conditionalFormat.formats.backgroundColor ? 'conditional-format__backgroundcolor--empty' : ''
                }`}
                style={{
                  backgroundColor: _conditionalFormat?.formats?.backgroundColor ?? '#ffffff'
                }}
              />
            )}
          </div>
        </div>
        <div className={'conditional-format__buttons'}>
          <Button
            id={`add_conditionalFormat`}
            className={'ml-1'}
            color={canBeSaved ? 'primary' : 'grey'}
            disabled={!canBeSaved}
            onClick={saveFormatHandler}
            size={'sm'}
            outline
          >
            {!_conditionalFormat.id && <i className='fal fa-plus'></i>}
            {!!_conditionalFormat.id && <i className='fal fa-save'></i>}
          </Button>

          {!!_conditionalFormat.id && (
            <>
              <Button
                id={`duplicate_conditionalFormat`}
                className={'ml-1'}
                color={'secondary'}
                onClick={duplicateFormatHandler}
                size={'sm'}
                outline
              >
                <i className='fal fa-copy'></i>
              </Button>

              <Button
                id={`delete_conditionalFormat`}
                className={'ml-1'}
                color={'danger'}
                onClick={deleteFormatHandler}
                size={'sm'}
                outline
              >
                <i className='fal fa-trash-alt'></i>
              </Button>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};
