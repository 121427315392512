import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import produce from 'immer';

import { QueryBuilder } from 'smv-components';

import { stripHtml, getQueryBuilderSources, getQuestionnaireElementsOrder } from 'smv-helpers';
import { questionnaireActions } from 'smv-redux/actions';
import {
  getQueryBuilderEmptyState,
  getQueryBuilderWithoutElementsWithErrors,
  handleQuerybuilderAutosave
} from 'src/smoove/components/querybuilder/helper';

export const ChoiceDisplayCondition = ({ question, choice, writeable, className, containerId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const surveyid = useSelector(state => state.survey.id);
  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const translations = useSelector(state => state.survey?.translations);
  const label = translations?.[choice.config.translations.label]?.[locale] ?? '';
  const questions = useSelector(state => state.survey.questionnaire);
  const data = useSelector(state => state.survey?.data);

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const open = useCallback(() => setIsOpen(true), []);

  const sources = useMemo(() => {
    const rawSources = {
      question: { list: questions.list, order: getQuestionnaireElementsOrder(questions) },
      sysvar: systemVariables,
      calcvar: data.calculations
    };

    const _sources = getQueryBuilderSources(rawSources, question.id, 'above', false);

    return _sources;
  }, [questions, systemVariables, data.calculations, question.id]);

  return (
    <>
      <i
        onClick={open}
        id={`display_condition_${choice.id}_modal`}
        className={`${className} ${
          choice.config?.displayCondition && Object.keys(choice.config?.displayCondition?.elements)?.length > 1
            ? 'fas fa-eye-slash'
            : 'fal fa-eye-slash'
        }`}
      />
      <UncontrolledTooltip
        placement='top'
        target={`display_condition_${choice.id}_modal`}
        // container={containerId}
        boundariesElement={containerId}
      >
        <FormattedMessage id={'smoove.questionnaire.display-condition'} />
      </UncontrolledTooltip>

      <Modal unmountOnClose={true} isOpen={isOpen} centered={true} toggle={() => setIsOpen(false)} size='lg'>
        <ModalHeader toggle={() => setIsOpen(false)}>
          <div className='d-flex'>
            <div className='modal__title-group'>
              {intl.formatMessage({ id: 'smoove.questionnaire.display-condition' })}
              {`: ${stripHtml(label)}`}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginLeft: '20px' }}>
            <p>{intl.formatMessage({ id: 'smoove.questionnaire.hide-condition.info-text' })}</p>
          </div>

          <DisplayConditionQuery
            sources={sources}
            writeable={writeable}
            surveyid={surveyid}
            question={question}
            choice={choice}
          />
        </ModalBody>
        <ModalFooter>
          <Button color={'grey'} onClick={() => setIsOpen(false)}>
            {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const DisplayConditionQuery = ({ sources, writeable, question, choice, surveyid }) => {
  const [_displayCondition, setDisplayCondition] = useState(choice?.config?.displayCondition ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    if (!!choice?.config?.displayCondition && !!choice?.config?.displayCondition?.rootElementId) {
      if (!_displayCondition?.id) {
        setDisplayCondition(state =>
          produce(state, d => {
            d.id = choice?.config?.displayCondition.id;
          })
        );
      }
    } else {
      setDisplayCondition(getQueryBuilderEmptyState());
    }
  }, [choice?.config?.displayCondition, _displayCondition?.id]);

  const saveHandler = useCallback(() => {
    setIsSaving(true);

    const displayConditionId = choice?.config?.displayCondition?.id ? choice?.config?.displayCondition.id : null;

    questionnaireActions
      .updateDisplayConditionElement(
        surveyid,
        question.id,
        choice.id,
        displayConditionId,
        getQueryBuilderWithoutElementsWithErrors(_displayCondition)
      )
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyid, question.id, _displayCondition, choice?.config?.displayCondition?.id, choice?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    if (choice.config.displayCondition?.rootElementId) {
      questionnaireActions
        .removeDisplayConditionElement(surveyid, question.id, choice.id, choice.config.displayCondition?.rootElementId)
        .then(res => {
          setIsSaving(false);
          setWarning(null);
        });
    } else {
      setDisplayCondition(null);
      setIsSaving(false);
    }
  }, [choice.config.displayCondition?.rootElementId, choice.id, question.id, surveyid]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      _displayCondition,
      choice?.config?.displayCondition,
      sources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [_displayCondition, saveHandler, isSaving, choice?.config?.displayCondition, sources, deleteHandler]);

  return (
    <>
      <QueryBuilder
        readOnly={!writeable}
        name='display_condition'
        fields={sources}
        queryBuilder={_displayCondition}
        setQueryBuilder={setDisplayCondition}
        warning={warning}
        isSaving={isSaving}
      />
    </>
  );
};
