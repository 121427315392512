import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './ActiveShareWarning.scss';

export const ActiveShareWarning = ({ className = '', reportid }) => {
  const report = useSelector(state => state?.survey?.reports?.list?.[reportid]);

  if (
    !report ||
    !report?.accessLinks ||
    (typeof report.accessLinks === 'object' && Object.keys(report.accessLinks).length === 0)
  )
    return null;

  return (
    <div className={`active-share-warning ${className}`} id={`tt_active-share-warning`}>
      <i className='fal fa-share-alt fa-fw mr-1'></i>
      <span>
        <FormattedMessage id='smoove.page.survey.results.active-share.shared' defaultMessage='Shared' />
      </span>
      <UncontrolledTooltip target={`tt_active-share-warning`}>
        <FormattedMessage
          id={'smoove.page.survey.results.active-share.warning'}
          defaultMessage={
            'This report is shared. Any change will instantly be available for users accessing the report over a share link.'
          }
        />
      </UncontrolledTooltip>
    </div>
  );
};
