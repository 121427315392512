export const parseErrorAsMessageString = (error, defaultMessage) => {
  let message = defaultMessage ?? 'An error occured.';
  let description = '';

  if (error && error?.response?.data?.error?.message) {
    message = error?.response?.data?.error.message;
  }

  if (error && error?.response?.data?.error?.description) {
    description = error?.response?.data?.error.description;
  }

  return `${message} ${description}`;
};
