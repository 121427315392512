import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';
import produce from 'immer';

import { projectSettingsActionTypes } from '../constants';

const INITIAL_STATE = {
  // [projectId]: {
  //   [surveyId]: {
  //     selectedReportId
  //       [reportid]: {
  //            selectedPageId
  //        }
  //   }
  // }
};

const projectSettings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case projectSettingsActionTypes.SET_Q_LOCALE: {
      const { locale, projectId } = action.payload;
      return {
        ...state,
        [projectId]: { ...state?.[projectId], selectedLocale: locale }
      };
    }

    case projectSettingsActionTypes.TOGGLE_IS_SHORTLABELS_ACTIVE: {
      const { isShortlabelsActive, projectId } = action.payload;
      return {
        ...state,
        [projectId]: { ...state?.[projectId], isShortlabelsActive: isShortlabelsActive }
      };
    }

    case projectSettingsActionTypes.SET_QUESTIONNAIRE_ELEMENT_COLLAPSE: {
      const { surveyid, elementid, value } = action.payload;
      return produce(state, draft => {
        if (!draft?.[surveyid]) draft[surveyid] = {};
        if (!draft[surveyid]?.queCollapseMap) draft[surveyid].queCollapseMap = {};
        draft[surveyid].queCollapseMap[elementid] = value;
      });
    }

    case projectSettingsActionTypes.SET_SELECTED_PROJECT:
    case projectSettingsActionTypes.SET_SELECTED_REPORT:
    case projectSettingsActionTypes.SET_SELECTED_PAGE: {
      const { projectId, surveyId, reportId, pageId } = action.payload;
      return produce(state, draft => {
        if (!draft[projectId]) {
          draft[projectId] = {};
        }

        if (!draft[projectId][surveyId]) {
          draft[projectId][surveyId] = {};
        }

        if (!draft[projectId][surveyId][reportId]) {
          draft[projectId][surveyId][reportId] = {};
        }

        draft[projectId][surveyId].selectedReportId = reportId;
        if (pageId) {
          draft[projectId][surveyId][reportId].selectedPageId = pageId;
        }
      });
    }

    case projectSettingsActionTypes.SELECT_PROJECT_FAILURE: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'projectSettings',
  storage: local
};

export const projectSettingsReducer = persistReducer(persistConfig, projectSettings);
