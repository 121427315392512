import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useIntl } from 'react-intl';

import { WizardsList } from '.';

export const NewSurveyModal = ({ isOpen, toggle, project }) => {
  const intl = useIntl();
  const translationPrefix = 'smoove.page.project.general.settings';
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: `${translationPrefix}.add-survey` })}</ModalHeader>
      <ModalBody>
        <WizardsList project={project} toggle={toggle} />
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          {intl.formatMessage({ id: `smoove.common.buttons.cancel` })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
