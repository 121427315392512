import React from 'react';
import { QuestionText } from '../components';

export const SingleInputText = ({ question, writeable }) => {
  // const { config } = question;

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <textarea
        className={'form-control que-input-field que-input-field--multiline'}
        style={{ resize: 'none' }}
        rows={3}
        disabled={true}
        placeholder={'multiline text input...'}
      />
    </>
  );
};
