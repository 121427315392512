import React, { useEffect } from 'react';
import { onEscDown } from './helper/onEscDown';
import './SmvDrawer.scss';

export const SmvDrawer = ({ children, side = 'left', isOpen, toggle, onMouseDown }) => {
  useEffect(() => {
    const handleEscDown = onEscDown(toggle);
    if (isOpen) {
      window.addEventListener('keydown', handleEscDown);
    }
    return () => {
      window.removeEventListener('keydown', handleEscDown);
    };
  }, [isOpen, toggle]);

  return (
    <div onMouseDown={onMouseDown} tabIndex='-1'>
      <div className={`smv-drawer smv-drawer--${side} ${isOpen ? 'smv-drawer--open' : ''}`}>
        <div>
          <i className='fas fa-times smv-drawer__closebtn' onClick={() => toggle()}></i>
        </div>
        <div>{children}</div>
      </div>
      <div className={`smv-brackdrop ${isOpen ? 'smv-backdrop--open' : ''}`} onClick={() => toggle()} />
    </div>
  );
};
