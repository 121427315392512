import { useCallback, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { useSubelementPropertyTranslation } from 'smv-helpers';

import './MatrixCarouselPreview.scss';

export const MatrixCarouselPreview = ({ question }) => {
  const itemOrder = question?.config?.choices?.order ?? [];
  const scaleOrder = question?.config?.scales?.order ?? [];
  const list = useSelector(state => state.survey.questionnaire.list);
  const importedSource = question.config?.choices?.import?.source;
  const sourceQuestion = list?.[importedSource];
  const isMultiMatrix = question.config.qtype === 'multiple_matrix';

  const [activeSlide, setActiveSlide] = useState(0);

  const _importedChoices = useMemo(() => {
    if (!sourceQuestion) return [];
    if (sourceQuestion.config?.choices?.order && sourceQuestion.config.choices.order.length > 0) {
      return sourceQuestion.config.choices.order.map(choiceid => choiceid);
    }
  }, [sourceQuestion]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = useCallback(
    id => {
      let _selected = [...selectedItems];
      if (_selected.includes(id)) {
        _selected = _selected.filter(el => el !== id);
      } else {
        if (question.config?.qtype === 'single_matrix') {
          const choiceRegex = /([^__]+)/;
          const choiceId = id.match(choiceRegex)?.[0];
          selectedItems.forEach(item => {
            const match = item.match(choiceRegex);
            if (match.length > 0 && match[0] === choiceId) {
              _selected = _selected.filter(el => el !== item);
            }
          });
        }
        _selected.push(id);
      }
      setSelectedItems(_selected);
    },
    [selectedItems, question.config?.qtype]
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    arrows: false,
    afterChange: (current, next) => {
      setActiveSlide(current);
    }
  };
  const sliderRef = useRef();

  const sliderHander = useMemo(() => {
    return {
      gotoNext: () => {
        sliderRef.current.slickNext();
      },
      gotoPrev: () => {
        sliderRef.current.slickPrev();
      }
    };
  }, []);

  return (
    <div className='matrix-preview matrix-preview--carousel'>
      <Slider {...settings} className='dashboard-carousel' ref={sliderRef}>
        {_importedChoices?.map((choiceid, index) => {
          return (
            <div key={choiceid}>
              <MatrixChoice
                key={choiceid}
                choiceid={choiceid}
                question={question}
                importedSourceQuestion={sourceQuestion}
                selectedItems={selectedItems}
                handleSelectItem={handleSelectItem}
              />
            </div>
          );
        })}
        {itemOrder?.map((choiceid, index) => {
          return (
            <div key={choiceid}>
              <MatrixChoice
                key={choiceid}
                choiceid={choiceid}
                question={question}
                selectedItems={selectedItems}
                handleSelectItem={handleSelectItem}
              />
            </div>
          );
        })}
      </Slider>
      <ul className='preview-scale-container'>
        {scaleOrder?.map((scaleid, index) => {
          return (
            <ScaleLabel
              key={scaleid}
              scaleid={scaleid}
              choiceid={activeSlide}
              question={question}
              selectedItems={selectedItems}
              handleSelectItem={handleSelectItem}
              sliderHander={sliderHander}
            />
          );
        })}
      </ul>
      {isMultiMatrix && (
        <div className='text-right'>
          <Button color={'grey'} onClick={() => sliderHander.gotoNext()}>
            <FormattedMessage id={'smoove.common.buttons.continue'} defaultMessage={'Continue'} />
          </Button>
        </div>
      )}
    </div>
  );
};

const ScaleLabel = ({ scaleid, question, choiceid, selectedItems, handleSelectItem, sliderHander }) => {
  const choice = question.config?.scales?.list?.[scaleid];

  const scaleLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  let scaleOrder = question?.config?.scales?.order ?? [];
  const width = 100 / scaleOrder.length + 1;
  const qtype = question?.config?.qtype;
  const choiceScaleId = `${choiceid}__${scaleid}`;
  const isActive = selectedItems.includes(choiceScaleId);
  const isMultiMatrix = question.config.qtype === 'multiple_matrix';

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 768 ? true : false;

  const selectItem = () => {
    handleSelectItem(choiceScaleId);
    if (!isMultiMatrix) {
      sliderHander.gotoNext();
    }
  };

  return (
    <li className='preview-scale' style={{ width: `${width}%` }}>
      <div
        onClick={isMobile ? selectItem : () => {}}
        className='scale-text'
        dangerouslySetInnerHTML={{ __html: scaleLabel }}
      ></div>
      <div onClick={selectItem} className='previewitem__choice'>
        <span className={`choice ${isActive ? 'choice--active' : ''}`}>
          {qtype === 'single_matrix' && <i className={`circle`} />}
          {qtype === 'multiple_matrix' && <i className={`checkbox`} />}
        </span>
      </div>
    </li>
  );
};

const MatrixChoice = ({ choiceid, question, importedSourceQuestion }) => {
  let choice = question.config?.choices?.list?.[choiceid];

  if (importedSourceQuestion) {
    choice = importedSourceQuestion.config?.choices?.list?.[choiceid];
  }

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  if (!choice) {
    return null;
  }

  return (
    <div key={choiceid}>
      {/* <img src={item.src} alt={item.altText} /> */}
      <div className='carousel-item-preview' dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
    </div>
  );
};
