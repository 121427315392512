export const getSignificance = (cell, significanceGate, significanceDimension, significanceBenchmark) => {
  let isSignificantHigh = false,
    isSignificantLow = false;

  if (significanceDimension === 'heads') {
    const significances = cell?.significances ?? {};
    Object.keys(significances).forEach(headid => {
      Object.keys(significances[headid]).forEach(headsubid => {
        if (
          !!significanceBenchmark &&
          (headid !== significanceBenchmark?.headId || headsubid !== significanceBenchmark?.headSubId)
        ) {
          return;
        }
        const value = significances[headid][headsubid];

        if (value.val > significanceGate) isSignificantHigh = true;
        if (value.val < -significanceGate) isSignificantLow = true;
      });
    });
  } else if (significanceDimension === 'rows') {
    const rowSignificances = cell?.rowSignificances ?? {};
    Object.keys(rowSignificances).forEach(rowId => {
      Object.keys(rowSignificances[rowId]).forEach(rowSubId => {
        if (typeof rowSignificances[rowId][rowSubId] === 'object' && !!rowSignificances[rowId][rowSubId]?.val) {
          if (
            !!significanceBenchmark &&
            (rowId !== significanceBenchmark?.rowId || rowSubId !== significanceBenchmark?.rowSubId)
          ) {
            return;
          }
          const value = rowSignificances[rowId][rowSubId];

          if (value.val > significanceGate) isSignificantHigh = true;
          if (value.val < -significanceGate) isSignificantLow = true;
        } else if (typeof rowSignificances[rowId][rowSubId] === 'object' && !rowSignificances[rowId][rowSubId]?.val) {
          Object.keys(rowSignificances[rowId][rowSubId]).forEach(rowSubSubId => {
            if (
              !!significanceBenchmark &&
              (rowId !== significanceBenchmark?.rowId ||
                rowSubId !== significanceBenchmark?.rowSubId ||
                rowSubSubId !== significanceBenchmark?.rowSubSubId)
            ) {
              return;
            }
            const value = rowSignificances[rowId][rowSubId][rowSubSubId];

            if (value.val > significanceGate) isSignificantHigh = true;
            if (value.val < -significanceGate) isSignificantLow = true;
          });
        }
      });
    });
  }

  return { isSignificantHigh, isSignificantLow };
};
