import { translationsActions, questionnaireActions } from 'smv-redux';
import { parseImagesAndSetPlaceholders, mediaPlaceholderRegex } from 'smv-helpers';
import { isEqual } from 'lodash';

/**
 * Updates the question text translation for the specified locale and its media elements. Calls necessary redux actions and corresponding backend requests to update the translation and the media config object of the question.
 *
 * @param {string} value //new value from input field
 * @param {object} question // old question object
 * @param {object} surveyMedia //survey Media object from redux state
 * @param {string} questionText //question text including images, images will be parsed and replaced by placeholders
 * @param {string} surveyid
 * @param {string} locale
 */
export const saveQuestionText = (value, question, surveyMedia, questionText, surveyid, locale) => {
  const [newQuestionText, mediaConfigs] = parseImagesAndSetPlaceholders(value, question, surveyMedia);
  const oldImageIds = [...questionText.matchAll(mediaPlaceholderRegex)].map(el => el[2]);

  if (questionText !== newQuestionText) {
    translationsActions.updateTranslation(surveyid, question.translations.text, { [locale]: newQuestionText });
  }

  let _mediaConfig = {};
  const newMediaIds = [];
  Object.values(mediaConfigs).forEach(mediaConfig => {
    _mediaConfig[mediaConfig.id] = mediaConfig;
    newMediaIds.push(mediaConfig.id);
  });

  let imageIdsToRemove = oldImageIds.filter(x => !newMediaIds.includes(x));

  let updatedMediaConfig = { ...question.config.media };
  Object.keys(updatedMediaConfig).forEach(el => {
    if (imageIdsToRemove.includes(el)) delete updatedMediaConfig[el];
  });

  const _media = {
    ...updatedMediaConfig,
    ..._mediaConfig
  };

  const isMediaEqual = isEqual(_media, question.config.media);

  if (!isMediaEqual) {
    questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
      config: {
        media: _media
      }
    });
  }
};
