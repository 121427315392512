import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import { mediaPlaceholderRegex, stripHtml } from './';

/**
 * Returns the translated value of the elements property for the requested locale.
 *
 * If no locale is passed, the currently selected locale (`selectedLocale`) will be taken. If this is also not set,
 * the main locale will be taken as a fallback.
 * If the passed locale (selectedLocale as default) holds no translation,
 * the translation for the main locale will be returned.
 *
 * @param {object} element The questionnaire element, including its config
 * @param {string} property The requested property key - default: `text`
 * @param {string} locale the requested locale - if no locale is passed, the selected locale or main locale will be taken
 *
 * @returns {string} - The translated property value
 */
export const useElementPropertyTranslation = (element, options = {}) => {
  const { property = 'text', locale = null, useStripped = false, useFallback = false, useShortcodes = null } = options;

  const translation = useSelector(state => state.survey?.translations ?? {});
  const translationFallback = useSelector(state => state.survey?.translations?.[state.survey.locales.main] ?? {});
  const isShortlabelsActive = useSelector(
    state => state.projectSettings?.[state.survey.id]?.isShortlabelsActive ?? true
  );
  const selectedLocale = useSelector(
    state => locale ?? state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );

  let _useShortcodes = false;
  if (isBoolean(useShortcodes)) {
    _useShortcodes = useShortcodes;
  } else {
    _useShortcodes = isShortlabelsActive;
  }

  return useMemo(() => {
    let value = '';
    if (!element) return '';
    if (_useShortcodes && isShortlabelsActive && stripHtml(element?.config?.shortLabel ?? '').length > 0) {
      // todo: check if property_short even exist, like prefix/suffix. If yes, fix it
      // value = element.config[`${property}_short`];
      value = element.config.shortLabel;
    } else if (translation[element.translations?.[property]]?.[locale]?.length > 0) {
      value = translation[element.translations?.[property]]?.[locale];
    } else if (translation[element.translations?.[property]]?.[selectedLocale]?.length > 0) {
      value = translation[element.translations?.[property]]?.[selectedLocale];
    } else if (stripHtml(translation?.[element?.config?.[property]] ?? '').length > 0) {
      value = translation[element.config[property]];
    } else if (useFallback && stripHtml(translationFallback?.[element?.config?.[property]] ?? '').length > 0) {
      value = translationFallback[element.config[property]];
    }

    if (useStripped) {
      value = value.replaceAll(mediaPlaceholderRegex, '');
      value = stripHtml(value);
    }

    return value;
  }, [
    element,
    property,
    locale,
    selectedLocale,
    useFallback,
    _useShortcodes,
    useStripped,
    translation,
    translationFallback,
    isShortlabelsActive
  ]);
};
