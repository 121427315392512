import React, { useCallback, useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { settingsActions } from 'smv-redux';

import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { NewSurveyModal } from 'smv-components';
import { SearchInput } from '../misc/SearchInput';

import './SurveyListControls.scss';

// NOTE! status is now a string ('wizard'|'draft'|'running'|'completed')
// const types = [
//   { id: null, code: 'all' },
//   { id: 0, code: 'wizard' },
//   { id: 1, code: 'draft' },
//   { id: 2, code: 'running' },
//   { id: 3, code: 'completed' },
//   { id: 4, code: 'all' }
// ];

export const SurveyListControls = ({ filterTypes, sorting, liststyle, handler, surveys }) => {
  const intl = useIntl();
  const translationPrefix = 'smoove.page.projects';

  const project = useSelector(state => state.project);

  const [isNewSurveyModalOpen, setIsNewSurveyModalOpen] = useState(false);
  const toggleNewSurveyModal = useCallback(() => {
    setIsNewSurveyModalOpen(b => !b);
  }, []);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);
  // const typesWithCounts = useMemo(() => {
  //   return types.map(type => {
  //     let count = projects.length;
  //     if (type.code !== 'all') {
  //       count = projects.filter(project => project.status === type.id).length;
  //     }

  //     return {
  //       ...type,
  //       count
  //     };
  //   });
  // }, [projects]);
  // const typesWithCounts = [];

  const setListStyle = useCallback(style => {
    settingsActions.setProjectsListStyle(style);
  }, []);

  return (
    <div className='d-flex justify-content-between pl-0 mb-2 survey-list-controls'>
      <div
        className={'d-flex flex-row align-items-center flex-wrap justify-content-between'}
        style={{ flex: '1 1 auto' }}
      >
        <div className={'d-flex flex-row align-items-center flex-wrap mr-4'}>
          <div
            className={'pointer p-1' + (liststyle === 'rows' ? ' text-secondary' : ' text-smv')}
            onClick={() => setListStyle('rows')}
          >
            <i className='fal fa-bars' />
          </div>
          <div
            className={'pointer p-1' + (liststyle === 'tiles' ? ' text-secondary' : ' text-smv')}
            onClick={() => setListStyle('tiles')}
          >
            <i className='fal fa-grid' />
          </div>
        </div>
        <SearchInput
          allItems={surveys}
          filterValues={['title']}
          setFilteredItems={handler.setFilteredSurveys}
          placeholderText={intl.formatMessage({ id: `${translationPrefix}.search-survey` })}
        />

        <div>
          <SurveySortDropdown setSorting={handler.setSorting} sorting={sorting} />
        </div>

        {/* <div style={{minWidth: "250px"}}>
          <MultipleSelectDropdown values={filterTypes} options={typess.filter(el => el.label !== "all")} onChange={(e) => handler.handleFilterByType(e)} />
        </div> */}

        {/* {typesWithCounts.map(type => (
          <FilterButton
            key={type.code}
            filterType={filterType}
            handler={handler}
            thisFilterType={type.id}
            status={type.code}
            projectsCount={type.count}
          />
        ))} */}
      </div>
      {userCanEditProject && (
        <>
          <Button color={'primary'} size={'sm'} outline={false} onClick={toggleNewSurveyModal} className='ml-4'>
            {intl.formatMessage({ id: `smoove.page.project.general.settings.add-survey-btn` })}
          </Button>
          <NewSurveyModal isOpen={isNewSurveyModalOpen} toggle={toggleNewSurveyModal} project={project} />
        </>
      )}
    </div>
  );
};

// function FilterButton({ filterType, handler, projectsCount, thisFilterType, status }) {
//   const translationPrefix = 'smoove.page.projects';

//   return (
//     <div
//       className={
//         'menu-item text-uppercase pointer d-flex align-items-center' +
//         (filterType === thisFilterType ? ' text-smv-red' : ' text-smv')
//       }
//       onClick={() => handler.setFilterType(thisFilterType)}
//     >
//       <div className='mr-1'>
//         <FormattedMessage id={`${translationPrefix}.status.${status}`} />
//       </div>
//       <Badge color={'grey'} className={'rounded-pill'}>
//         {projectsCount}
//       </Badge>
//     </div>
//   );
// }

export const SurveySortDropdown = ({ setSorting, sorting }) => {
  const translationPrefix = 'smoove.page.projects.surveys.sorting';

  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  return (
    <Dropdown
      id='surveys_sort_by'
      isOpen={isSortDropdownOpen}
      size='sm'
      toggle={() => setIsSortDropdownOpen(state => !state)}
    >
      <DropdownToggle caret outline color='secondary'>
        <span>
          <FormattedMessage id={`${translationPrefix}.sort-by`} />
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setSorting('newestFirst')} active={sorting === 'newestFirst'}>
          <FormattedMessage id={`${translationPrefix}.newest-first`} />
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('oldestFirst')} active={sorting === 'oldestFirst'}>
          <FormattedMessage id={`${translationPrefix}.oldest-first`} />
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('alphAsc')} active={sorting === 'alphAsc'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.a-z`} />
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('alphDesc')} active={sorting === 'alphDesc'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.z-a`} />
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('statusCompletedDraft')} active={sorting === 'statusCompletedDraft'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.status-draft-first`} defaultMessage={'draft - completed'} />
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('statusDraftCompleted')} active={sorting === 'statusDraftCompleted'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.status-completed-first`} defaultMessage={'completed - draft'} />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
