import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { SurveyListControls, SurveyListElementRow, SurveyListElementTile } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { sortSurveys } from './helper/surveySorting';

import { SurveyListElementNew } from './SurveyListElementNew';

import './SurveyList.scss';

export const SurveyList = () => {
  const project = useSelector(state => state.project);
  const surveys = useSelector(state => state.project.surveys);
  const projects = useSelector(state => state.projects.list);
  const listStyle = useSelector(state => state.smooveSettings?.user?.projects?.listStyle ?? 'tiles');

  const isLoadingProject = project.loading ?? false;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);
  const history = useHistory();

  const [filteredSurveys, setFilteredSurveys] = useState(surveys);
  const [sorting, setSorting] = useState('oldestFirst'); //alphAsc, alphDesc, newestFirst, oldestFirst

  useEffect(() => {
    if (surveys) {
      setFilteredSurveys([...surveys]);
    }
  }, [surveys]);

  const handler = useMemo(() => {
    return {
      setSorting: setSorting,
      setFilteredSurveys: setFilteredSurveys
    };
  }, []);

  // todo: there is a bug filtering projects by state...
  const openSurvey = useCallback(
    (project, survey) => {
      const projectid = project.id;

      switch (survey.status) {
        case 'wizard':
          localStorage.setItem(`wizard-${survey.wizard?.type}`, JSON.stringify(survey.wizard));
          const wizardUrl = survey.wizard?.type.replace('-', '/');
          history.push(`/one/project/create/${wizardUrl}`);
          break;
        case 'draft':
        case 'testing':
          history.push(`/one/projects/${projectid}/surveys/${survey.id}/que`);
          break;
        case 'running':
          history.push(`/one/projects/${projectid}/surveys/${survey.id}/tables`);
          break;
        case 'completed':
          history.push(`/one/projects/${projectid}/surveys/${survey.id}/reports`);
          break;
        default:
          console.log(`handler for project status ${survey.status} not configured.`);
      }
    },
    [history]
  );

  const surveyList = useMemo(() => {
    const sortedSurveys = sortSurveys(filteredSurveys, sorting);
    if (isLoadingProject) {
      return (
        <div className='p-4'>
          <i className='fas fa-spinner fa-spin' />
        </div>
      );
    }

    let _surveys = [];

    sortedSurveys.forEach(survey => {
      if (!survey.id) return null;

      if (listStyle === 'tiles') {
        _surveys.push(
          <SurveyListElementTile key={survey.id} survey={survey} openSurvey={openSurvey} project={project} />
        );
      } else if (listStyle === 'rows') {
        _surveys.push(
          <SurveyListElementRow key={survey.id} survey={survey} openSurvey={openSurvey} project={project} />
        );
      }
    });

    if (userCanEditProject) {
      _surveys.push(<SurveyListElementNew key={'newsurvey'} listStyle={listStyle} project={project} />);
    }

    return _surveys;
  }, [project, openSurvey, listStyle, filteredSurveys, sorting, isLoadingProject, userCanEditProject]);

  if (!projects || projects.length <= 0) {
    if (isLoadingProject) {
      return (
        <div className='p-4'>
          <i className='fas fa-spinner fa-spin' />
        </div>
      );
    } else {
      return (
        <div className='mt-2'>
          <FormattedMessage id={`smoove.page.projects.no-projects-no-surveys`} />
        </div>
      );
    }
  }
  if (!project?.id && !isLoadingProject) {
    return <FormattedMessage id={`smoove.page.projects.please-select-project`} />;
  }

  return (
    <div className='survey-list'>
      <div className='d-flex align-items-center mb-2'>
        <h4 className='mb-0'>{project.title}</h4>
      </div>
      <SurveyListControls liststyle={listStyle} surveys={project.surveys} handler={handler} sorting={sorting} />

      <div className={`survey-list-container ${listStyle === 'tiles' ? 'survey-list-container--tiles' : ''}`}>
        {surveyList}
      </div>
    </div>
  );
};
