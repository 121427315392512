import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { isLooped } from 'smv-helpers';
import { BaseRow } from '..';
import {
  DefaultResultRow,
  OpenEndedDefaultResultRow,
  MatrixCompactResultRow,
  MatrixDetailResultRow,
  SystemVarResultRow,
  CalcVarResultRow,
  CalculationResultRow
} from '../result-rows';

export const ResultTableDefaultBody = ({
  handler,
  rowsRef,
  table,
  tableResult,
  isDraggingOver,
  canDropRow,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire?.list ?? {});
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const { heads, rows, config } = table;
  const isEmpty = useMemo(() => rows?.order?.length === 0, [rows]);

  const numberOfColumns = useMemo(() => {
    if (!isEmpty) return 0;
    if (!table?.heads?.order || table?.heads?.order?.length <= 0) return 1;
    const headItems = table?.heads?.order?.map(el => table?.heads?.list?.[el]?.sourceId);
    const columnLength = headItems.map(el => list?.[el]?.config?.choices?.order?.length);
    let colCount = 0;
    columnLength.forEach(el => (colCount += parseInt(el)));
    if (table.config.showTotal) colCount += 1;
    // add column for label and empty field
    colCount += 2;
    return colCount;
  }, [table.heads, table.config, list, isEmpty]);

  return (
    <tbody
      ref={rowsRef}
      className={classNames({
        'smv-result-table__body': true,
        'smv-result-table__body--is-over': canDropRow && isDraggingOver,
        'smv-result-table__body--can-drop': canDropRow && !isDraggingOver
      })}
    >
      {isEmpty && (
        <tr className={'result__row'}>
          <th scope='row' className={'result__empty'} colSpan={numberOfColumns}>
            <FormattedMessage id={'smoove.page.tables.table-body'} defaultMessage={'Table body'} />
          </th>
        </tr>
      )}
      {!isEmpty && config.showBase && (
        <BaseRow
          config={config}
          list={list}
          rows={rows}
          heads={heads}
          tableResult={tableResult}
          isCompactMatrix={isCompactMatrix}
          isDetailedMatrix={matrixType === 'detail'}
          matrixType={matrixType}
        />
      )}
      {!isEmpty && config.showBaseWeighted && (
        <BaseRow
          config={config}
          list={list}
          rows={rows}
          heads={heads}
          tableResult={tableResult}
          isCompactMatrix={isCompactMatrix}
          isDetailedMatrix={matrixType === 'detail'}
          matrixType={matrixType}
        />
      )}

      {rows.order.map(rowId => {
        const row = rows.list[rowId];
        const { sourceType, sourceId } = row;

        const questionHas = {
          isLooped: false
        };

        if (sourceType === 'question') {
          const element = list[sourceId];
          if (!element) return null;

          const { isLoopedQuestion, loopParents } = isLooped(list, element);
          questionHas.isLooped = isLoopedQuestion;
          questionHas.loopParents = loopParents;

          if (['single_input'].includes(element.config.qtype)) {
            return (
              <OpenEndedDefaultResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                row={row}
                tableResult={tableResult}
                questionHas={questionHas}
                handler={handler}
              />
            );
          } else if (matrixType === 'detail' && ['single_matrix', 'multiple_matrix'].includes(element.config.qtype)) {
            return (
              <MatrixDetailResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                row={row}
                matrixType={'detail'}
                tableResult={tableResult}
                questionHas={questionHas}
                handler={handler}
                hover={hover}
              />
            );
          } else if (isCompactMatrix && ['single_matrix', 'multiple_matrix'].includes(element.config.qtype)) {
            return (
              <MatrixCompactResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                row={row}
                matrixType={'compact'}
                tableResult={tableResult}
                questionHas={questionHas}
                handler={handler}
                hover={hover}
              />
            );
          } else {
            return (
              <DefaultResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                row={row}
                matrixType={matrixType}
                tableResult={tableResult}
                questionHas={questionHas}
                handler={handler}
                isCompactMatrix={isCompactMatrix}
                hover={hover}
              />
            );
          }
        } else if (sourceType === 'sysvar') {
          const element = systemVariables.list[sourceId];

          return (
            <SystemVarResultRow
              key={`result_row_${row.id}`}
              row={row}
              element={element}
              matrixType={matrixType}
              table={table}
              tableResult={tableResult}
              isCompactMatrix={isCompactMatrix}
              questionHas={questionHas}
              handler={handler}
              hover={hover}
            />
          );
        } else if (sourceType === 'calcvar') {
          const element = calcVars?.find(el => el.id === sourceId);

          return (
            <CalcVarResultRow
              key={`result_row_${row.id}`}
              row={row}
              element={element}
              matrixType={matrixType}
              table={table}
              tableResult={tableResult}
              questionHas={questionHas}
              handler={handler}
              hover={hover}
            />
          );
        } else if (sourceType === 'calculation') {
          return (
            <CalculationResultRow
              key={`result_row_${row.id}`}
              row={row}
              matrixType={matrixType}
              table={table}
              tableResult={tableResult}
              isCompactMatrix={isCompactMatrix}
              handler={handler}
              hover={hover}
            />
          );
        }

        return null;
      })}
    </tbody>
  );
};
