import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { tablesActions } from 'smv-redux';
import { useSelector } from 'react-redux';
import { TextInputRow } from 'smv-components';

export const EditTableModalButton = ({ toggle, mode }) => {
  const faIcon = mode === 'duplicate' ? 'copy' : 'edit';
  return (
    <div className={`smv-custom-dropdown__buttons`} onClick={toggle}>
      <i className={`fal fa-${faIcon} icon-smv-blue mr-1`} />
      <span>
        <FormattedMessage
          defaultMessage={`${mode} table`}
          id={`smoove.page.survey.tables.form.${mode}-table.dropdown-button`}
        />
      </span>
    </div>
  );
};

export const EditTableModal = ({ isOpen, toggle, tablesHandler, table, mode, parentId }) => {
  const [name, setName] = useState(`${table.name}${mode === 'duplicate' ? ' (copy)' : ''}`);

  const surveyId = useSelector(state => state.survey.id);

  const intl = useIntl();
  const translationPrefix = 'smoove.page.survey.tables';

  const saveHandler = useCallback(() => {
    if (mode === 'rename') {
      tablesActions.updateTable(surveyId, table.id, { name });
    }
    if (mode === 'duplicate') {
      const { id, surveyId, ...tableData } = table;

      tablesActions.createTable(surveyId, { ...tableData, name, parentId }).then(() => {
        tablesActions.loadFolders(surveyId);
      });
    }
    toggle();
  }, [toggle, name, table, mode, parentId, surveyId]);

  const saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      saveHandler();
    },
    [saveHandler]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
      <Form onSubmit={saveOnSubmit}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage
            defaultMessage={`${mode} table`}
            id={`smoove.page.survey.tables.form.${mode}-table.headline`}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>{intl.formatMessage({ id: `${translationPrefix}.form.save.text` })}</Col>
          </Row>
          <TextInputRow
            name={'name'}
            title={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.label` })}
            tooltip={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.desc` })}
            value={name}
            change={({ target }) => setName(target.value)}
            placeholder={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.placeholder` })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={saveHandler}>
            <FormattedMessage
              defaultMessage={`${mode} table`}
              id={`smoove.page.survey.tables.form.${mode}-table.button`}
            />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
