import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const MediaLibrarySortation = ({ setSorting, sorting }) => {
  const translationPrefix = 'smoove.common.media-library.sorting';
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  return (
    <Dropdown
      id='media_sort_by'
      isOpen={isSortDropdownOpen}
      size='sm'
      toggle={() => setIsSortDropdownOpen(state => !state)}
      // className='jumpto-dropdown'
    >
      <DropdownToggle caret outline color='secondary'>
        <span>Sort by</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setSorting('newestFirst')} active={sorting === 'newestFirst'}>
          <FormattedMessage id={`${translationPrefix}.newest-first`} />
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('oldestFirst')} active={sorting === 'oldestFirst'}>
          <FormattedMessage id={`${translationPrefix}.oldest-first`} />
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('alphAsc')} active={sorting === 'alphAsc'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.a-z`} />
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => setSorting('alphDesc')} active={sorting === 'alphDesc'}>
          <span>
            <FormattedMessage id={`${translationPrefix}.z-a`} />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
