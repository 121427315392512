import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip
} from 'reactstrap';
import axios from 'axios';
import _ from 'lodash';

import { translationsActions } from 'smv-redux';
import { getCountryIso2Code } from 'smv-helpers';
import { getQuestionnaireElementsOrder } from 'smv-helpers';

import { getQuestionElementsTranslationIds } from './helpers/getQuestionElementsTranslationIds';
import { TranslationElement } from './TranslationElement';

import './TranslationModal.scss';

export const TranslationButton = ({ toggle }) => {
  const locales = useSelector(state => state.survey.locales);
  const intl = useIntl();
  // todo: Add "locale" for shortlabels and always show this button, even when que is not writeable
  if (!locales || locales?.order?.length === 0 || !locales?.order) return null;

  return (
    <div onClick={toggle}>
      <i className={`text-smv-blue far fa-globe mr-2`} />
      <span>{intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.translations' })}</span>
    </div>
  );
};

export const TranslationModal = ({ isOpen, toggle, writeable }) => {
  const surveyid = useSelector(state => state.survey.id);
  const locales = useSelector(state => state.survey.locales);
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const elements = useSelector(state => state.survey.questionnaire.list);

  const intl = useIntl();

  const translationModalContainerRef = useRef(null);

  // Todo: This could be a helper function to get the correct order of the questionnaire in other occurences. No need to re-implement it everywhere
  const order = useMemo(() => {
    return getQuestionnaireElementsOrder(questionnaire);
  }, [questionnaire]);

  const [activeTab, setActiveTab] = useState('tab-translate');
  const [isSelectAOpen, setIsSelectAOpen] = useState(false);
  const [isSelectBOpen, setIsSelectBOpen] = useState(false);
  const [isSelectQuestionOpen, setIsSelectQuestionOpen] = useState(false);
  // const [selectedLocales, setSelectedLocales] = useState([]);

  // const updateSelectedLocales = locale => {
  //   if (selectedLocales.includes(locale)) {
  //     const _selected = selectedLocales.filter(el => el !== locale);
  //     setSelectedLocales(_selected);
  //   } else {
  //     setSelectedLocales([...selectedLocales, locale]);
  //   }
  // };

  const [localeA, setLocaleA] = useState(locales?.main ?? locales?.order?.[0]);
  const [localeB, setLocaleB] = useState(
    locales?.order?.[1] === locales?.main ? locales?.order?.[0] : locales?.order?.[1]
  );

  const languageA = useSelector(state => state.survey.locales?.list?.[localeA]);
  const languageB = useSelector(state => state.survey.locales?.list?.[localeB]);

  const [aiTranslationList, setAiTranslationList] = useState(null);
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);

  const numberOfAiTranslations = useMemo(() => {
    if (!aiTranslationList) return null;
    const _translatedObjects = Object.fromEntries(
      Object.entries(aiTranslationList).filter(([key, value]) => value.hasOwnProperty(localeB))
    );
    return Object.keys(_translatedObjects ?? {})?.length;
  }, [aiTranslationList, localeB]);

  const nodeOnClick = useCallback((e, id) => {
    e.stopPropagation();

    const element = document.querySelector('#_' + id);

    if (element) {
      const timeout = 20;
      const offset = 170;

      setTimeout(() => {
        const scrollEl = document.getElementById('sidebyside-translation-modal').parentElement;
        const bodyRect = scrollEl.scrollTop;
        const elementTop = element.getBoundingClientRect().top;
        const elementPosition = bodyRect - elementTop * -1;
        const offsetPosition = elementPosition - offset;

        scrollEl.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, timeout);

      const classes = ['smv-scroll-indicator'];

      element.classList.add(...classes);
      setTimeout(() => element.classList.remove(...classes), timeout + 1600);
    }
  }, []);

  // const createExportFile = () => {
  //   const _selectedLanguages = locales.order.filter(l => selectedLocales.includes(l));
  //   // todo: create actual export file,
  //   console.log(_selectedLanguages);
  // };

  // const importFile = file => {
  //   //todo: handle actual import, check if file is valid (mime-type)
  //   console.log(file);
  // };

  const ShortLabelsDropdownOption = () => {
    return (
      <>
        <i className={`far fa-flag mr-2`} />
        <span>Shortlabels</span>
      </>
    );
  };

  useEffect(() => {
    if (locales?.order?.length <= 1) {
      setLocaleB('shortlabels');
    }
  }, [locales?.order?.length]);

  useEffect(() => {
    setAiTranslationList(null);
  }, [localeA, localeB, isOpen]);

  const translationIds = useMemo(() => {
    if (!questionnaire?.list) return [];
    let ids = [];
    Object.values(questionnaire.list)?.forEach(el => {
      ids = [...ids, ...getQuestionElementsTranslationIds(el)];
    });
    return ids;
  }, [questionnaire?.list]);

  const getTranslationsForIds = useCallback(
    ids => {
      setIsLoadingTranslations(true);
      axios
        .patch(`/surveys/${surveyid}/ai/translations`, {
          sourceL: localeA,
          destL: localeB,
          translationIds: ids
        })
        .then(res => {
          setIsLoadingTranslations(false);
          setAiTranslationList(res.data.translations);
        })
        .catch(err => {
          console.log(err);
          setIsLoadingTranslations(false);
        });
    },
    [surveyid, localeA, localeB]
  );

  const handleUpdateAiTranslationList = useCallback(
    (id, action, translationText) => {
      let _aiTranslations = _.cloneDeep(aiTranslationList);
      if (!_aiTranslations) {
        _aiTranslations = {};
      }
      if (action === 'add') {
        if (!_aiTranslations[id]) {
          _aiTranslations[id] = {
            id: id
          };
        }
        _aiTranslations[id][localeB] = translationText;
      }
      if (action === 'remove') {
        delete _aiTranslations[id];
      }
      setAiTranslationList(_aiTranslations);
    },
    [aiTranslationList, localeB]
  );

  const getSingleTranslation = useCallback(
    translationId => {
      axios
        .patch(`/surveys/${surveyid}/ai/translations/${translationId}`, {
          sourceL: localeA,
          destL: localeB
        })
        .then(res => {
          handleUpdateAiTranslationList(translationId, 'add', res.data.translations[translationId][localeB]);
        });
    },
    [localeA, localeB, surveyid, handleUpdateAiTranslationList]
  );

  const handleSaveAllAiTranslations = useCallback(() => {
    let _data = [];
    Object.keys(aiTranslationList)?.forEach(id => {
      _data.push({
        id: id,
        [localeB]: aiTranslationList[id][localeB]
      });
    });
    setIsLoadingTranslations(true);
    translationsActions
      .updateMultipleTranslations(surveyid, _data)
      .then(res => {
        setIsLoadingTranslations(false);
        setAiTranslationList(null);
      })
      .catch(err => {
        setIsLoadingTranslations(false);
      });
  }, [aiTranslationList, surveyid, localeB]);

  return (
    <Modal
      className='sidebyside-translation-modal'
      id={'sidebyside-translation-modal'}
      isOpen={isOpen}
      toggle={toggle}
      container={'body'}
      size={'xl'}
    >
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.translations' })}
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem className='pointer'>
            <NavLink
              className={activeTab === 'tab-translate' ? 'active' : ''}
              onClick={() => setActiveTab('tab-translate')}
            >
              {intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.translate' })}
            </NavLink>
          </NavItem>
          {/* <NavItem className='pointer'>
            <NavLink className={activeTab === 'tab-export' ? 'active' : ''} onClick={() => setActiveTab('tab-export')}>
              Export
            </NavLink>
          </NavItem>
          <NavItem className='pointer'>
            <NavLink className={activeTab === 'tab-import' ? 'active' : ''} onClick={() => setActiveTab('tab-import')}>
              Import
            </NavLink>
          </NavItem> */}
        </Nav>
        <Container className='translation-content-container'>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='tab-translate'>
              <Row className='sidebyside-translation-modal__settings'>
                <Col sm={6} className='mb-2'>
                  <h6>{intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.settings' })}</h6>
                  <div className='d-flex justify-content-between'>
                    <Dropdown
                      id='questionselect'
                      isOpen={isSelectQuestionOpen}
                      size='sm'
                      toggle={() => setIsSelectQuestionOpen(state => !state)}
                      className='jumpto-dropdown'
                    >
                      <DropdownToggle caret outline color='secondary'>
                        <span>
                          {intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.jump-to-question' })}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {order?.map((id, idx) => (
                          <DropdownItem key={`${id}_${idx}`} onClick={e => nodeOnClick(e, `el-${id}`)}>
                            <span>
                              {elements[id]?.config?.varname ?? ''}
                              {elements[id]?.config?.varname ? ' - ' : ''}
                              {elements[id].title && elements[id]?.title?.length > 0 ? (
                                elements[id]?.title
                              ) : (
                                <i>untitled question</i>
                              )}
                            </span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
                {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
                  <Col sm={6}>
                    <h6>{intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.ai.ai-translations' })}</h6>
                    <div className='d-flex align-items-center'>
                      <button
                        className='ai-translation-button ai-translation-button--flags'
                        onClick={() => getTranslationsForIds(translationIds)}
                        id='ai-translate-all-button'
                      >
                        <i
                          className={`ml-2 flag-icon flag-icon-${getCountryIso2Code(
                            languageA?.iso3
                          ).toLowerCase()} mr-1`}
                        />
                        <i className={`mx-2 fal fa-${isLoadingTranslations ? 'spinner fa-spin' : 'arrow-right'}`} />
                        <i
                          className={`ml-2 flag-icon flag-icon-${getCountryIso2Code(
                            languageB?.iso3
                          ).toLowerCase()} mr-1`}
                        />
                      </button>
                    </div>
                    <UncontrolledTooltip placement='top' target={`ai-translate-all-button`} fade={true}>
                      {intl.formatMessage(
                        { id: 'smoove.questionnaire.translation.modal.ai.use-ai-to-translate-from-a-to-b' },
                        { locale1: languageA?.label, locale2: languageB?.label }
                      )}
                    </UncontrolledTooltip>
                  </Col>
                )}
                <Col sm={12}>
                  <h6>{intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.translations' })}</h6>
                </Col>
                <Col sm={6}>
                  <Dropdown
                    id='languageASelect'
                    isOpen={isSelectAOpen}
                    size='sm'
                    toggle={() => setIsSelectAOpen(state => !state)}
                  >
                    <DropdownToggle caret outline color='secondary'>
                      {localeA === 'shortlabels' && <ShortLabelsDropdownOption />}
                      {localeA !== 'shortlabels' && (
                        <>
                          <i
                            className={`flag-icon flag-icon-${getCountryIso2Code(languageA?.iso3).toLowerCase()} mr-2`}
                          />
                          <span>{languageA?.label}</span>
                        </>
                      )}
                      {languageA?.locale === locales.main && <i className='ml-1 fas fa-star'></i>}
                    </DropdownToggle>
                    <DropdownMenu>
                      {locales?.order?.map(locale => (
                        <DropdownItem key={`${locale}_a`} onClick={() => setLocaleA(locale)}>
                          <i
                            className={`flag-icon flag-icon-${getCountryIso2Code(
                              locales.list[locale]?.iso3
                            ).toLowerCase()} mr-2`}
                          />
                          <span>{locales?.list?.[locale]?.label}</span>
                          {locale === locales.main && <i className='ml-1 fas fa-star'></i>}
                        </DropdownItem>
                      ))}
                      <DropdownItem divider />
                      <DropdownItem key={`${'shortlabels'}`} onClick={() => setLocaleA('shortlabels')}>
                        <ShortLabelsDropdownOption />
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {localeA === 'shortlabels' && (
                    <div>
                      <small>
                        <FormattedMessage
                          defaultMessage={`Shortlabels infotext`}
                          id={`smoove.questionnaire.translation.shortlabels-info`}
                        />
                      </small>
                    </div>
                  )}
                </Col>
                <Col sm={6}>
                  <Dropdown
                    id='languageBSelect'
                    isOpen={isSelectBOpen}
                    size='sm'
                    toggle={() => setIsSelectBOpen(state => !state)}
                  >
                    <DropdownToggle caret outline color='secondary'>
                      {localeB === 'shortlabels' && <ShortLabelsDropdownOption />}
                      {localeB !== 'shortlabels' && (
                        <>
                          <i
                            className={`flag-icon flag-icon-${getCountryIso2Code(languageB?.iso3).toLowerCase()} mr-2`}
                          />
                          <span>{languageB?.label}</span>
                        </>
                      )}
                      {languageB?.locale === locales.main && <i className='ml-1 fas fa-star'></i>}
                    </DropdownToggle>
                    <DropdownMenu>
                      {locales?.order?.map(locale => (
                        <DropdownItem key={`${locale}_b`} onClick={() => setLocaleB(locale)}>
                          <i
                            className={`flag-icon flag-icon-${getCountryIso2Code(
                              locales.list[locale]?.iso3
                            ).toLowerCase()} mr-2`}
                          />
                          <span>{locales.list[locale].label}</span>
                          {locale === locales.main && <i className='ml-1 fas fa-star'></i>}
                        </DropdownItem>
                      ))}
                      <DropdownItem divider />
                      <DropdownItem key={`${'shortlabels'}`} onClick={() => setLocaleB('shortlabels')}>
                        <ShortLabelsDropdownOption />
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {localeB === 'shortlabels' && (
                    <div>
                      <small>
                        <FormattedMessage
                          defaultMessage={`Shortlabels infotext`}
                          id={`smoove.questionnaire.translation.shortlabels-info`}
                        />
                      </small>
                    </div>
                  )}
                </Col>
              </Row>
              {isOpen && (
                <div ref={translationModalContainerRef}>
                  {order?.map((id, idx) => (
                    <Table className='translation-table' key={`${id}_${idx}`} id={`_el-${id}`}>
                      <tbody>
                        <TranslationElement
                          elementid={id}
                          localeA={localeA}
                          localeB={localeB}
                          nodeOnClick={nodeOnClick}
                          writeable={writeable}
                          translationModalContainerRef={translationModalContainerRef}
                          aiTranslationList={aiTranslationList}
                          updateAiTranslationList={handleUpdateAiTranslationList}
                          getSingleTranslation={getSingleTranslation}
                          getTranslationsForIds={getTranslationsForIds}
                        />
                      </tbody>
                    </Table>
                  ))}
                </div>
              )}
            </TabPane>
            {/* <TabPane tabId='tab-export'>
              <Row className='py-2'>
                <Col>
                  <h6 className='mt-2'>Select Languages for excel export</h6>
                  <div>
                    {locales?.order?.map(locale => {
                      return (
                        <div className='d-flex align-items-center' key={`locale-${locale}`}>
                          <Input
                            className='pointer'
                            // disabled={}
                            id={`select-lang-to-export-${locale}`}
                            type='checkbox'
                            onChange={() => {
                              updateSelectedLocales(locale);
                            }}
                            checked={selectedLocales.includes(locale)}
                          />
                          <i
                            className={`pointer flag-icon flag-icon-${getCountryIso2Code(
                              locales.list[locale]?.iso3
                            ).toLowerCase()}`}
                            onClick={() => updateSelectedLocales(locale)}
                          />
                          <span className='pointer pl-2' onClick={() => updateSelectedLocales(locale)}>
                            {locales.list[locale].label} ({locale})
                          </span>
                          {locale === locales.main && <i className='ml-1 fas fa-star'></i>}
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    color={'primary'}
                    className='mt-2'
                    onClick={createExportFile}
                    disabled={selectedLocales.length <= 0}
                    outline={selectedLocales.length <= 0}
                  >
                    <i className='fal fa-file-excel mr-2'></i>
                    <span>Export Excel</span>
                  </Button>
                </Col>
              </Row>
            </TabPane> */}
            {/* <TabPane tabId='tab-import'>
              <Row className='py-2'>
                <Col md={12}>
                  <h6 className='mt-2'>Import Excel File</h6>
                  <small>Make sure to upload a valid xls/xlsx file</small>
                  <FileDropzone upload={importFile} />
                </Col>
              </Row>
            </TabPane> */}
          </TabContent>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={toggle}>
          <FormattedMessage defaultMessage={`Close`} id={`smoove.common.buttons.close`} />
        </Button>
      </ModalFooter>
      {numberOfAiTranslations > 1 && (
        <div className='ai-translation-floating-button'>
          <button
            className='ai-translation-button ai-translation-button--translate-all'
            onClick={handleSaveAllAiTranslations}
          >
            <i className={`mr-2 fal fa-${isLoadingTranslations ? 'spinner fa-spin' : 'wand-magic-sparkles'}`} />

            <span>
              {intl.formatMessage(
                { id: 'smoove.questionnaire.translation.modal.ai.apply' },
                { count: numberOfAiTranslations }
              )}
            </span>
          </button>
        </div>
      )}
    </Modal>
  );
};

export const TranslationButtonAndModal = ({ buttonClassName = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen(b => !b);
  }, []);

  const locales = useSelector(state => state.survey.locales);
  // todo: Add "locale" for shortlabels and always show this button, even when que is not writeable
  if (!locales || locales?.order?.length === 0 || !locales?.order) return null;

  return (
    <>
      <TranslationButton toggle={toggle} className={buttonClassName} />
      <TranslationModal toggle={toggle} isOpen={isOpen} />
    </>
  );
};

// const FileDropzone = props => {
//   const {
//     acceptedFiles,
//     // fileRejections,
//     getRootProps,
//     getInputProps
//   } = useDropzone({
//     // accept: 'image/jpeg,image/png',
//     multiple: false
//   });

//   const acceptedFileItems = acceptedFiles.map(file => (
//     <div key={file.path}>
//       {file.path} - {file.size} bytes
//     </div>
//   ));

//   return (
//     <div>
//       <div {...getRootProps({ className: 'dropzone' })} className='pointer'>
//         <input {...getInputProps()} />
//         <p className='border p-4 mb-0'>Drag 'n' drop some files here, or click to select files</p>
//       </div>
//       {acceptedFiles.length > 0 && (
//         <div>
//           <div>{acceptedFileItems}</div>
//           <Button color={'primary'} onClick={() => props.upload(acceptedFiles[0])}>
//             Upload
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// };
