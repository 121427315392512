import React, { useCallback } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import './MultiPurposeSlider.scss';

function Handle({
  handle: { id, value, percent },
  getHandleProps,
  showHandleCaption,
  showHandleTooltipOnHover,
  deletableHandles,
  removeHandle
}) {
  return (
    <div className='multipurposeslider__handle' style={{ left: `${percent}%` }} {...getHandleProps(id)}>
      {showHandleCaption && <div className='multipurposeslider__handle-caption'>{value}</div>}
      {showHandleTooltipOnHover ? (
        <div className='multipurposeslider__handle-tooltip'>
          <div className='multipurposeslider__handle-tooltiptext'>{value}</div>
        </div>
      ) : (
        ''
      )}
      {deletableHandles && (
        <div className='multipurposeslider__handle-delete' onClick={() => removeHandle(value)}>
          <span className='far fa-trash' />
        </div>
      )}
    </div>
  );
}

function Track({
  source,
  target,
  showPercentValues,
  trackCaptionValues,
  showTrackCaptionValues,
  index,
  onClickTrack,
  enableAddValues
}) {
  let percent = Math.round(target.percent - source.percent);
  let valueToAdd = Math.round((target.value + source.value) / 2);
  return (
    <div
      className={`multipurposeslider__track ${enableAddValues ? 'multipurposeslider__track--add-enabled' : ''}`}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
    >
      {enableAddValues && (
        <div
          className='multipurposeslider__plus-btn'
          onClick={enableAddValues ? () => onClickTrack(valueToAdd) : undefined}
        >
          +
        </div>
      )}
      {showPercentValues && (
        <div className='multipurposeslider__track-value multipurposeslider__track-value--top'>{percent}%</div>
      )}
      {showTrackCaptionValues ? (
        <div className='multipurposeslider__track-value multipurposeslider__track-value--bottom'>
          {trackCaptionValues ? trackCaptionValues[index] : ''}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

// helper to calculate the track captions as age groups
export const createTrackCaptionValuesAsRanges = (values, unit) => {
  if (values.length > 0) {
    const trackCaption = [];
    for (let i = 0; i < values.length - 1; i++) {
      if (i === 0) {
        trackCaption.push(`${values[i]} - ${values[i + 1]} ${unit ? unit : ''}`);
      } else {
        trackCaption.push(`${values[i] + 1} - ${values[i + 1]} ${unit ? unit : ''}`);
      }
    }
    return trackCaption;
  }
};

export const MultiPurposeSlider = ({
  values,
  domain = [0, 100],
  change,
  showHandleTooltipOnHover = false,
  showPercentValues = true,
  showTrackCaptionValues = true,
  trackCaptionvalues = [],
  showLeftTrack = true,
  showRightTrack = true,
  showHandleCaption = false,
  deletableHandles = false,
  enableAddValues = false,
  onUpdate = () => {}
}) => {
  const onAddHandle = useCallback(
    newValue => {
      change([...values, newValue].sort((a, b) => a - b));
    },
    [change, values]
  );

  const onRemoveHandle = useCallback(
    el => {
      if (values.length > 2) {
        change(values.filter(element => element !== el));
      }
    },
    [values, change]
  );

  return (
    <div className='multipurposeslider'>
      <Slider
        className='multipurposeslider__slider'
        domain={domain}
        step={1}
        mode={2}
        values={values}
        onChange={change}
        onUpdate={onUpdate}
      >
        <Rail>
          {({ getRailProps }) => (
            <div>
              <div className='multipurposeslider__rail' {...getRailProps()} />
            </div>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  showHandleCaption={showHandleCaption}
                  showHandleTooltipOnHover={showHandleTooltipOnHover}
                  deletableHandles={deletableHandles}
                  removeHandle={onRemoveHandle}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={showLeftTrack} right={showRightTrack}>
          {({ tracks }) => (
            <div className='slider-tracks'>
              {tracks.map(({ id, source, target, tracks }, idx) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  tracks={tracks}
                  showPercentValues={showPercentValues}
                  showTrackCaptionValues={showTrackCaptionValues}
                  trackCaptionValues={trackCaptionvalues}
                  index={idx}
                  clickToAddValues={true}
                  onClickTrack={onAddHandle}
                  enableAddValues={enableAddValues}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
};
