import React from 'react';
import { Route, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

import { EmptyNode } from '../Sidebar';
import { ElementSwitch, ElementConfigSwitch } from './';

export const Content = ({ writeable, surveyId }) => {
  // questionnaire, dispatch, collapseMap, setCollapseMap, settings,
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const { path } = useRouteMatch();

  return (
    <div className='d-flex flex-column' style={{ height: '100%' }}>
      {/* {writeable && root.length !== 0 && <EmptyNode style={{ height: '18px' }} indicator='first' />} */}

      {questionnaire.root?.map((id, index) => (
        <ElementSwitch
          key={id}
          index={index}
          elementid={id}
          surveyId={surveyId}
          writeable={writeable}
          parentsIds={[]}
          questionnaire={questionnaire}
        />
      ))}
      {writeable && <EmptyNode writeable={writeable} indicator='last' />}
      <Route path={`${path}/:elementid/:section?`}>
        <ElementConfigSwitch writeable={writeable} />
      </Route>
    </div>
  );
};

Content.propTypes = {
  // language: PropTypes.object.isRequired
};
