import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import produce from 'immer';

import { ShelfConfig } from '@mindline-analytics/shelftool';

import { surveyActions } from 'smv-redux/actions';
import { shelfBackgrounds } from '.';
import { Container } from 'reactstrap';
import { Row } from 'reactstrap';
import { Col } from 'reactstrap';

/**
 * @link http://gitea.mla.local/smoove/smoove-specification/src/branch/master/Questionnaire.md#custom-shelf
 */
export const ConfigCustomShelf = ({ question, handler, writeable }) => {
  const surveyid = useSelector(state => state.survey.id);
  const locales = useSelector(state => state.survey.locales.list);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const media = useSelector(state => state.survey.media);

  const countryCode = useMemo(() => locales[selectedLocale].iso3, [locales, selectedLocale]);

  const { boards = [], config = {} } = useMemo(() => question.config.shelf?.[countryCode] ?? {}, [
    question.config.shelf,
    countryCode
  ]);

  const shelfBoards = useMemo(
    () =>
      boards.map(board =>
        board
          .map(productId => {
            const mediaItem = media?.[productId] ?? false;
            if (mediaItem) {
              return { ...mediaItem, url: `${process.env.PUBLIC_URL}/${mediaItem.url}` };
            } else return false;
          })
          .filter(Boolean)
      ),
    [boards, media]
  );

  const shelfConfig = {
    ...config,
    background: question.config?.shelf?.config?.background ?? config?.background,
    backgrounds: shelfBackgrounds
  };

  const shelfConfigValidValues = useMemo(
    () => ({
      shelfResultType: ['none', 'single', 'multiple'],
      mode: ['buy', 'mark'],
      background: Object.keys(shelfBackgrounds)
    }),
    []
  );

  const internalHandler = {
    setShelfConfigValue: e => {
      const { value, name } = e.target;

      if (!Object.keys(shelfConfigValidValues).includes(name)) {
        console.log(`Invalid config key ${name}`);
        return;
      } else if (!shelfConfigValidValues[name].includes(value)) {
        console.log(`Invalid config value ${value} for key ${name}`);
        return;
      }

      // if (value !== question.config?.shelfResultType && includes(['none', 'single', 'multiple'], value)) {
      surveyActions.saveQuestionnaireElement(
        surveyid,
        produce(question, draft => {
          if (!!draft.config?.shelf?.config) draft.config.shelf.config = {};
          draft.config.shelf.config[name] = value;
        })
      );
      // }
    }
  };

  // backgrounds
  // shelfStyle

  // show details (zoom on click)
  // mode (mark/ buy)
  // shelfScale (int)
  // shelfTranslateY

  // submit_auto
  // submit_callback
  // submit_value

  return (
    <Container className='config-tab mt-2'>
      <Row className='mb-2'>
        <Col sm='12' md='9'>
          <ShelfConfig shelfConfig={shelfConfig} shelfBoards={shelfBoards} testcells={[]} />
        </Col>
        <Col sm='12' md='3'>
          <div className='config-group'>
            <h6>Result Type</h6>
            <Input
              disabled={!writeable}
              type={'select'}
              bsSize={'sm'}
              name={'shelfResultType'}
              defaultValue={question.config?.shelf?.config?.shelfResultType ?? 'single'}
              onChange={internalHandler.setShelfConfigValue}
            >
              <option value={'none'}>None</option>
              <option value={'single'}>Single Product</option>
              <option value={'multiple'}>Multiple Products</option>
            </Input>
          </div>

          {question.config?.shelf?.config?.shelfResultType !== 'none' && (
            <div className='config-group'>
              <h6>Selection Mode</h6>
              <Input
                disabled={!writeable}
                type={'select'}
                bsSize={'sm'}
                name={'mode'}
                defaultValue={question.config?.shelf?.config?.mode ?? 'buy'}
                onChange={internalHandler.setShelfConfigValue}
              >
                <option value={'buy'}>buy/ shopping basket</option>
                <option value={'mark'}>highlight/ mark</option>
              </Input>
            </div>
          )}

          <div className='config-group'>
            <h6>Background</h6>
            <Input
              disabled={!writeable}
              type={'select'}
              bsSize={'sm'}
              name={'background'}
              defaultValue={question.config?.shelf?.config?.background ?? Object.keys(shelfBackgrounds)[0]}
              onChange={internalHandler.setShelfConfigValue}
            >
              {Object.keys(shelfBackgrounds).map(key => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Input>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
