import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import produce from 'immer';

import { useRowOrder, stripHtml, useMatrixType } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

import {
  ResultTableChartQuestionSelectionDetails,
  ResultTableChartResultRow,
  ResultTableChartResultRowHeader
} from '.';

export const ResultTableChartMatrixCompactResultRow = ({
  element,
  table,
  chartConfig,
  tableResult,
  row,
  questionHas,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { matrixType } = useMatrixType(table);

  const { rows, config } = table;
  const { showHiddenRows = true } = config;
  const { id, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row, showHiddenRows);

  const defaultRows = useMemo(
    () =>
      (table?.rows?.order ?? []).filter(rowid =>
        ['question', 'sysvar', 'calcvar'].includes(table.rows.list[rowid].source)
      ),
    [table?.rows]
  );

  const defaultMetric = useDefaultMetric(table);

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};
  let subelements = element?.config?.scales ?? {};

  let heads = produce(table.rows, draft => {
    const defaultRows = { list: {}, order: [] };

    (rows?.order ?? []).forEach(rowid => {
      if (['question', 'sysvar', 'calcvar'].includes(rows.list[rowid].sourceType)) {
        defaultRows.order.push(rowid);
        defaultRows.list[rowid] = rows.list[rowid];
      }
    });

    if (table.heads.order.length > 0) {
      table.heads.order.forEach(headId => {
        const head = table.heads.list[headId];
        if (head.sourceType === 'calculation') {
          defaultRows.order.push(headId);
          defaultRows.list[headId] = head;
        }
      });
    }

    return defaultRows;
  });

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const rowHeadLabel = stripHtml(element.title);

  const significancesShow = chartConfig?.significancesShow ?? false;

  let headMappingTable = {};
  if (defaultRows.length > 1) {
    const valueIdMap = {};
    defaultRows.forEach((rowid, rowidx) => {
      const { elementid: sourceElementid, config } = table.rows.list[rowid];
      const sourceElement = list?.[sourceElementid];
      const _choices = getMergedChoices(sourceElement, sourceElement.config?.choices, list);

      _choices.order.forEach(choiceid => {
        const { value } = _choices.list[choiceid];

        if (rowidx === 0) {
          headMappingTable[choiceid] = {
            id: choiceid,
            rowid: rowid,
            ids: [choiceid],
            idmap: { [rowid]: choiceid },
            value: value,
            hidden: config?.hidden?.[choiceid] === true
          };
          valueIdMap[value] = choiceid;
        } else {
          const mainid = valueIdMap[value];
          headMappingTable[mainid].ids.push(choiceid);
          headMappingTable[mainid].idmap[rowid] = choiceid;
        }
      });
    });
  }

  const _rows = [];
  rowOrder.forEach(order => {
    let metric = defaultMetric;
    if (order.value === 'mean') metric = 'smvrslt_mean';

    const isHiddenScale = order?.hidden ?? false;
    const isExcludedScale = order?.excluded;

    if (isHiddenScale || isExcludedScale) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;

    let subelement = {};
    if (calcFrequencyGroups?.[order?.subelement]) {
      subelement = calcFrequencyGroups[order.subelement];
    } else if (subelements.list?.[order?.subelement]) {
      subelement = subelements.list[order.subelement];
    }

    _rows.push(
      <ResultTableChartResultRow
        key={`result-row-compact-${order.rowid}_${order.subelement}`}
        rowid={id}
        orderItem={order}
        subelement={subelement}
        choiceid={order.subelement}
        list={list}
        config={config}
        heads={heads}
        tableResult={tableResult}
        conditionalFormats={chartConfig.conditionalFormats}
        metric={metric}
        sortationPinned={sortationPinned}
        isCompactMatrix={true}
        isDetailedMatrix={false}
        matrixType={matrixType}
        headMappingTable={headMappingTable}
        significancesShow={significancesShow}
        hover={hover}
      >
        {_rows.length === 0 && chartConfig.showQuestionTitleColumn && (
          <ResultTableChartResultRowHeader
            rowid={id}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            questionHas={questionHas}
            questionSelectionDetails={
              <ResultTableChartQuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
            }
          />
        )}
      </ResultTableChartResultRow>
    );
  });

  return _rows;
};

ResultTableChartMatrixCompactResultRow.propTypes = {
  element: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  tableResult: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  questionHas: PropTypes.object.isRequired
};
