import React, { useCallback } from 'react';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useDrop } from 'react-dnd';
import produce from 'immer';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getTableElement } from '../helper';

import './ResultTableEmpty.scss';

export const ResultTableEmpty = ({ handler }) => {
  const weights = useSelector(state => state.survey.data?.weights);

  let standardWeight = null;
  (weights ?? []).forEach(weight => {
    if (weight.isDefault) standardWeight = weight.id;
  });

  const onDropRow = useCallback(
    item => {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          const element = getTableElement(item);
          draftState.rows.order.push(element.id);
          draftState.rows.list[element.id] = element;
          if (standardWeight) {
            draftState.config.weightResult = true;
            draftState.config.weightVariable = standardWeight;
          }
        })
      );
    },
    [handler, standardWeight]
  );

  const [{ isDraggingOver, canDrop }, dropRow] = useDrop({
    accept: ['question', 'sysvar', 'calcvar'],
    drop: onDropRow,
    collect: monitor => ({
      isDraggingOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={dropRow} className={`result-table-empty--container`}>
      <Card
        className={classNames({
          'result-table-empty--card': true,
          'smv-result-table__body--is-over': isDraggingOver && canDrop,
          'smv-result-table__body--can-drop': canDrop
        })}
      >
        <CardBody className={'d-flex flex-column justify-content-center align-items-center'}>
          <h1 className={'text-center w-75'}>
            <i className={'fal fa-table mr-2'} />
          </h1>
          <h2 className={'display-4 text-center w-75'}>
            <FormattedMessage
              id={'smoove.page.project.tables.empty.text'}
              defaultMessage={'Drop a frist question item to start analyzing the data!'}
            />
          </h2>
        </CardBody>
      </Card>
    </div>
  );
};
