import 'smv-assets/css/bootstrap_with_overwrites.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'typeface-roboto';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

import 'smv-assets/css/smoove-shell.scss';

import React from 'react';
import { render, createPortal } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNotifications } from 'react-notifications-component';
import { TranslationProvider, VersionCheck } from 'smv-components';
import { ApplicationRouter } from './ApplicationRouter';
import { store } from 'smv-redux';
import packageJson from '../package.json';

// Caution: The import order matters, do not mess with it as it might break the UI

const version = packageJson.version;
global.REACT_APP_VERSION = version;

const wizards = [];
if (process.env.REACT_APP_WZ_INDIVIDUAL === 'true') {
  wizards.push({
    path: '/one/project/create/individual/:step?',
    content: () => import(/* webpackChunkName: "wizard-individual" */ 'wizards-shared/src/individual')
  });
}

if (process.env.REACT_APP_WZ_CONCEPT === 'true') {
  wizards.push({
    path: '/one/project/create/concept/:step?',
    content: () => import(/* webpackChunkName: "wizard-concept" */ 'wizards-shared/src/concept/module')
  });
}
if (process.env.REACT_APP_WZ_DESIGN === 'true') {
  wizards.push({
    path: '/one/project/create/design/:step?',
    content: () => import(/* webpackChunkName: "wizard-design" */ 'wizards-shared/src/CreateProjectWizardDesign')
  });
}

wizards.push({
  path: '/one/project/create/impact/:type?/:step?',
  content: () => import(/* webpackChunkName: "wizard-impact" */ 'wizards-beiersdorf/src/impact')
});

wizards.push({
  path: '/one/project/create/bdf/crowner/:step?',
  content: () => import(/* webpackChunkName: "wizard-bdf-crowner" */ 'wizards-beiersdorf/src/crowner')
});

if (process.env.REACT_APP_WZ_BURGER_KING === 'true') {
  wizards.push({
    path: '/one/project/create/burgerking-taste/:step?',
    content: () => import(/* webpackChunkName: "burger-king" */ 'wizards-burger-king/src')
  });
}
if (process.env.REACT_APP_WZ_SCHWAN_COSMETICS === 'true') {
  wizards.push({
    path: '/one/project/create/schwan-concept/:step?',
    content: () => import(/* webpackChunkName: "wizard-schwan-concept" */ 'wizards-schwan-cosmetics/src')
  });
}

export function Smoove() {
  const notifications = createPortal(<ReactNotifications className='top-0' />, document.body);
  return (
    <Provider store={store}>
      <TranslationProvider>
        {notifications}
        <VersionCheck>
          <PersistGate persistor={store.persistor} loading={<div>...rehydrating</div>}>
            <ApplicationRouter
              modules={[
                {
                  path: '/one',
                  content: () => import(/* webpackChunkName: "smoove" */ './smoove/'),
                  wizards
                }
              ]}
            />
          </PersistGate>
        </VersionCheck>
      </TranslationProvider>
    </Provider>
  );
}

document.getElementById('root') && render(<Smoove />, document.getElementById('root'));
