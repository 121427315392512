import React from 'react';

export const ActiveFilterGroup = ({ label, children }) => {
  return (
    <div className='active-filter-list__group'>
      <span className={'active-filter-list__group--label'}>{label}</span>
      {children}
    </div>
  );
};
