import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ReferenceValue } from './ReferenceValue';
import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ReferenceValuesDetailedMatrix = ({
  conditionalFormat,
  dimensionKey,
  main,
  matrixType = null,
  value,
  scales = null,
  handleReferenceSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  const isSelected = useMemo(() => {
    if (conditionalFormat.reference.rowId === main.id && conditionalFormat.reference.rowSubId === value.id) return true;
    return false;
  }, [main.id, value.id, conditionalFormat?.reference]);

  return (
    <Dropdown
      tag={'div'}
      direction={'end'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'dropdown-item--active': isSelected
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'smv-custom-dropdown__dropdown-toggle--flex': true
        })}
      >
        <span>{label}</span>
        <i className={'fa-thin fa-chevron-right'} />
      </DropdownToggle>

      <DropdownMenu
        end
        className={classNames({
          'smv-custom-dropdown__dropdown-menu': true,
          'smv-custom-dropdown__dropdown-menu--nested': isOpen
        })}
      >
        {scales.order?.map(scaleId => {
          const scale = scales.list[scaleId];

          const _element = {
            rowId: main.id,
            rowSubId: value.id,
            rowSubSubId: scale.id
          };

          const isSelected =
            conditionalFormat.reference.rowId === main.id &&
            conditionalFormat.reference.rowSubId === value.id &&
            conditionalFormat.reference.rowSubSubId === scale.id;

          return (
            <ReferenceValue
              key={`formula-builder-fixed-element-item-${main.id}-${value.id}-${scale.id}`}
              element={_element}
              value={scale}
              isSelected={isSelected}
              handleReferenceSelect={handleReferenceSelect}
            />
          );
        })}

        {Object.values(main.config?.calcFrequencyGroups ?? {}).map(calcGroup => {
          const _element = {
            rowId: main.id,
            rowSubId: value.id,
            rowSubSubId: calcGroup.id
          };

          let isSelected = false;
          if (
            conditionalFormat.reference.rowId === main.id &&
            conditionalFormat.reference.rowSubId === value.id &&
            conditionalFormat.reference.rowSubSubId === calcGroup.id
          ) {
            isSelected = true;
          }

          return (
            <ReferenceValue
              key={`formula-builder-fixed-element-item-${main.id}-${value.id}-${calcGroup.id}`}
              element={_element}
              value={calcGroup}
              isSelected={isSelected}
              handleReferenceSelect={handleReferenceSelect}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
