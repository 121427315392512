import React, { useEffect } from 'react';
import { generatePath, Link, useParams, useRouteMatch } from 'react-router-dom';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { SmvCustomDropdown } from 'src/smoove/components/misc';
import { useControlledState } from 'smv-helpers';
import {
  EditFolderButton,
  EditFolderModal,
  DeleteFolderButton,
  DeleteFolderModal,
  DeleteTableButton,
  DeleteTableModal,
  EditTableModalButton,
  EditTableModal,
  CopyFolderButton,
  WeightTablesButton,
  WeightTablesModal
} from 'src/smoove/components/project-results/modals';
import { getChartTypeIcon } from '../../project-reports/helper/getChartTypeIcon';

import './CustomNode.scss';

export const CustomNode = ({
  node,
  handler,
  tablesHandler,
  isOpen,
  onToggle,
  isDropTarget,
  readonly,
  tables,
  folders,
  containerRef,
  isTableConfigInitialState,
  clearTable
}) => {
  const isFolder = node?.type === 'folder';

  const dragOverProps = useDragOver(node.id, isOpen, onToggle);

  const handleToggle = e => {
    e.stopPropagation();
    onToggle(node.id);
  };

  return (
    <div
      className={classnames(`custom-node`, {
        'custom-node--is-drop-target': isDropTarget,
        'custom-node--is-open': isOpen,
        'custom-node--readonly': readonly
      })}
      {...dragOverProps}
    >
      {node.droppable && <i onClick={handleToggle} className={`far fa-chevron-${isOpen ? 'down' : 'right'}`} />}
      {isFolder ? (
        <Folder
          folder={folders.list[node.id]}
          handler={handler}
          readonly={readonly}
          toggle={handleToggle}
          isOpen={isOpen}
          node={node}
          isTableConfigInitialState={isTableConfigInitialState}
          clearTable={clearTable}
        />
      ) : (
        <Table
          table={tables.list[node.id]}
          handler={handler}
          tablesHandler={tablesHandler}
          readonly={readonly}
          containerRef={containerRef}
          node={node}
        />
      )}
    </div>
  );
};

const Folder = ({ folder, handler, readonly, toggle, isOpen, node, isTableConfigInitialState, clearTable }) => {
  const intl = useIntl();

  const [EditButton, EditModal] = useControlledState(EditFolderButton, EditFolderModal, false);
  const [AddButton, AddModal] = useControlledState(EditFolderButton, EditFolderModal, false);
  const [DeleteButton, DeleteModal] = useControlledState(DeleteFolderButton, DeleteFolderModal, false);
  const [WeightButton, WeightModal] = useControlledState(WeightTablesButton, WeightTablesModal, false);

  const weights = useSelector(state => state.survey?.data?.weights ?? []);
  const hasWeights = weights.length > 0;

  if (!folder) return null;

  return (
    <div className='table-folder'>
      <i className={`far fa-folder${isOpen ? '-open' : ''} px-1`} onClick={toggle} />
      <div className='table-folder__label' onClick={toggle} title={folder.name}>
        {folder.name}
      </div>
      {!readonly && (
        <div className='ml-auto'>
          <SmvCustomDropdown>
            <DropdownItem>
              <AddButton mode={'add'} />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <EditButton mode={'edit'} />
            </DropdownItem>
            <DropdownItem>
              <CopyFolderButton handler={handler} folder={folder} />
            </DropdownItem>
            <DropdownItem divider />
            {hasWeights && (
              <>
                <DropdownItem>
                  <WeightButton
                    buttonText={intl.formatMessage({
                      id: 'smoove.page.tables.weight-tables.apply-weight-to-tables-in-folder'
                    })}
                  />
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            <DropdownItem>
              <DeleteButton />
            </DropdownItem>
          </SmvCustomDropdown>
          <AddModal mode={'add'} folder={folder} handler={handler} parentId={node.id} />
          <EditModal mode={'edit'} folder={folder} handler={handler} parentId={node.parent} />
          <DeleteModal deleteHandler={() => handler.deleteFolder(folder.id)} title={`'${folder?.name}'`} />
          {hasWeights && (
            <WeightModal
              folder={folder}
              isTableConfigInitialState={isTableConfigInitialState}
              clearTable={clearTable}
            />
          )}
        </div>
      )}
    </div>
  );
};

const Table = ({ table, handler, tablesHandler, readonly, containerRef, node }) => {
  const { path } = useRouteMatch();
  const routeParams = useParams();

  const [DeleteButton, DeleteModal] = useControlledState(DeleteTableButton, DeleteTableModal, false);
  const [RenameButton, RenameModal] = useControlledState(EditTableModalButton, EditTableModal, false);
  const [DuplicateButton, DuplicateModal] = useControlledState(EditTableModalButton, EditTableModal, false);

  useEffect(() => {
    // neede only for reports page
    if (readonly && containerRef) {
      const handler = e => {
        e.dataTransfer.setData('text/plain', '');
        e.dataTransfer.setData('sourceType', 'table');
        e.dataTransfer.setData('sourceId', table.id);
      };
      containerRef.current.addEventListener('dragstart', handler);
    }
  }, [table, readonly, containerRef]);

  const label = table?.name || `unnamed table ${table?.id}`;
  const isActive = routeParams?.tableid === table?.id;
  const isStaticFiltersActive = !!table?.filters && !!table.filters?.rootElementId;

  const iconAndLabel = (
    <>
      <i className={`${getChartTypeIcon(table?.chart?.chartType)} table-item__chart-icon ${isActive ? '' : ''} `}></i>
      <div className='table-item__label' title={label}>
        {label}
      </div>
    </>
  );

  return (
    <div className={`table-item ${isActive ? 'table-item--active' : ''}`}>
      {readonly && iconAndLabel}
      {!readonly && (
        <Link
          style={{ color: 'unset' }}
          to={generatePath(path, { ...routeParams, tableid: table.id })}
          unselectable='on'
          className={`table-item__link`}
          title={label}
        >
          {iconAndLabel}
        </Link>
      )}

      {isStaticFiltersActive && (
        <span className='ml-1'>
          <i className='fal fa-filter'></i>
        </span>
      )}

      <div className='ml-auto' style={{ width: '20px' }}>
        {!readonly && (
          <>
            <SmvCustomDropdown>
              <DropdownItem>
                <RenameButton mode={'rename'} />
              </DropdownItem>
              <DropdownItem>
                <DuplicateButton mode={'duplicate'} />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <DeleteButton />
              </DropdownItem>
            </SmvCustomDropdown>
            <DeleteModal deleteHandler={() => handler.deleteTable(table.id)} title={`'${label}'`} />
            <RenameModal table={table} tablesHandler={tablesHandler} mode={'rename'} parentId={node.parent} />
            <DuplicateModal table={table} tablesHandler={tablesHandler} mode={'duplicate'} parentId={node.parent} />
          </>
        )}
      </div>
    </div>
  );
};
