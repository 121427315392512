import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip, Button } from 'reactstrap';
import { Store as notify } from 'react-notifications-component';
import { useIntl } from 'react-intl';
import { questionnaireActions } from '../../../redux/actions';
import { defaultNotifyProps } from 'smv-constants';

export const ConfirmDelete = ({ element }) => {
  const intl = useIntl();
  const surveyid = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire.list);

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);

  const toggle = useCallback(() => setIsOpen(b => !b), []);

  useEffect(() => {
    return () => {
      setIsDeleteButtonDisabled(false);
    };
  }, []);

  return (
    <>
      <i
        id={`btn_delete_${element.id}`}
        className='far fa-trash-alt icon-smv-blue p-1'
        onClick={() => setIsOpen(true)}
      />

      <UncontrolledTooltip target={`btn_delete_${element.id}`} delay={{ show: 500, hide: 200 }}>
        <FormattedMessage
          id={`smoove.common.buttons.delete-${element.type}`}
          defaultMessage={`delete ${element.type} element`}
        />
      </UncontrolledTooltip>

      <Modal isOpen={isOpen} centered={true} autoFocus={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id={'smoove.questionnaire.modal.delete-header'} defaultMessage={'Confirm Deletion'} />
        </ModalHeader>

        <ModalBody>
          <FormattedMessage
            id={'smoove.questionnaire.modal.delete-body'}
            defaultMessage={'Do you want to delete <b>"{title}"</b>?'}
            values={{
              b: text => <b>{text}</b>,
              title: `${element?.config?.varname ? `${element?.config?.varname} - ` : ''}${element.title}`
            }}
          />

          {element?.importedBy?.length > 0 && (
            <div>
              <div className='my-2'>
                <span className='mr-1 text-warning'>
                  {intl.formatMessage({ id: 'smoove.questionnaire.modal.warning' })}
                </span>
                <span>{intl.formatMessage({ id: 'smoove.questionnaire.modal.import-warning-text' })}</span>
              </div>
              {element.importedBy.map(queId => {
                const question = questionnaire[queId];
                return (
                  <div key={queId}>
                    {question?.config?.varname}: {question?.title}
                  </div>
                );
              })}
            </div>
          )}

          {!!element?.children?.length && (
            <p className={'text-warning mt-2'}>
              <b>
                <FormattedMessage id={'smoove.questionnaire.modal.delete-warning'} defaultMessage={'Warning'} />
              </b>
              <FormattedMessage
                id={`smoove.questionnaire.modal.delete-warning-body`}
                defaultMessage={`All subsidiary elements will also be deleted irreversibly!`}
              />
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color={'secondary'} outline={true} onClick={() => setIsOpen(false)}>
            <FormattedMessage id={'smoove.common.buttons.cancel'} defaultMessage={'Cancel'} />
          </Button>
          <Button
            color={'danger'}
            autoFocus={true}
            disabled={isDeleteButtonDisabled}
            onClick={() => {
              setIsDeleteButtonDisabled(true);
              questionnaireActions.removeQuestionnaireElement(surveyid, element.id).then(res => {
                if (res.isAxiosError) {
                  notify.addNotification({
                    title: intl.formatMessage({
                      id: 'smoove.questionnaire.elements.remove-element-notification-error',
                      defaultMessage: 'An error occurred.'
                    }),

                    type: 'danger',
                    ...defaultNotifyProps
                  });
                } else {
                  notify.addNotification({
                    title: intl.formatMessage({
                      id: 'smoove.questionnaire.elements.remove-element-notification-success',
                      defaultMessage: 'Item Successflly removed.'
                    }),

                    type: 'success',
                    ...defaultNotifyProps
                  });
                  setIsDeleteButtonDisabled(false);
                }
              });
            }}
          >
            <FormattedMessage id={'smoove.common.buttons.delete'} defaultMessage={'Delete'} />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
