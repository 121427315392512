import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import { DashboardElementMenu } from '..';

export const DashboardElementInvalid = ({ handler, element, readonly }) => {
  return (
    <Card className={`h-100 w-100 smv-dashboard-element`}>
      <CardHeader>
        <CardTitle tag='h5'>
          <FormattedMessage
            id={'smoove.page.project.reports.errors.invalid-element.headline'}
            defaultMessage={'Oops, something is wrong here...'}
          />
        </CardTitle>
        {!readonly && <DashboardElementMenu handler={handler} element={element} isInvalid={true} />}
      </CardHeader>
      <CardBody className={'d-flex justify-content-center align-items-center'}>
        <h2 className='display-4 text-center w-75'>
          <i className='fal fa-exclamation-triangle mr-2'></i>
          <FormattedMessage
            id={'smoove.page.project.reports.errors.invalid-element.message'}
            defaultMessage={'This item could not be loaded - may be the table was deleted.'}
          />
        </h2>
      </CardBody>
    </Card>
  );
};
