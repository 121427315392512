import React from 'react';
import './iconprovider.scss';
import Config from './svg/config.svg';
import ConfigColour from './svg/config_colour.svg';
import FilterItemColour from './svg/filter_item_colour.svg';
import FilterItem from './svg/filter_item.svg';
import InputFieldColour from './svg/input_field_colour.svg';
import InputField from './svg/input_field.svg';
import JumpColour from './svg/jump_colour_green.svg';
import Jump from './svg/jump.svg';
import MultipleChoiceColour from './svg/multiple_choice_colour.svg';
import MultipleChoice from './svg/multiple_choice.svg';
import MultipleMatrixColour from './svg/multiple_matrix_colour.svg';
import MultipleMatrix from './svg/multiple_matrix.svg';
import ScreenoutColour from './svg/screenout_colour.svg';
import Screenout from './svg/screenout.svg';
import SingleChoiceColour from './svg/single_choice_colour.svg';
import SingleChoice from './svg/single_choice.svg';
import SingleMatrixColour from './svg/single_matrix_colour.svg';
import SingleMatrix from './svg/single_matrix.svg';

export const IconProvider = ({ icon, filled = false, clickable = false }) => {
  let renderedIcon;
  switch (icon) {
    case 'config':
      renderedIcon = filled ? <img src={ConfigColour} alt='' /> : <img src={Config} alt='' />;
      break;
    case 'filter':
    case 'filter_item':
      renderedIcon = filled ? <img src={FilterItemColour} alt='' /> : <img src={FilterItem} alt='' />;
      break;
    case 'single_input_text':
    case 'multiple_input_text':
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-input-text`} />;
      break;
    case 'single_input_number':
    case 'multiple_input_number':
      renderedIcon = (
        <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-input-numeric`} />
      );
      break;
    case 'single_input':
    case 'multiple_input':
      renderedIcon = filled ? <img src={InputFieldColour} alt='' /> : <img src={InputField} alt='' />;
      break;
    case 'custom_text':
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-text`} />;
      break;
    case 'jump':
      renderedIcon = filled ? <img src={JumpColour} alt='' /> : <img src={Jump} alt='' />;
      break;
    case 'multiple_vector':
      renderedIcon = filled ? <img src={MultipleChoiceColour} alt='' /> : <img src={MultipleChoice} alt='' />;
      break;
    case 'multiple_matrix':
      renderedIcon = filled ? <img src={MultipleMatrixColour} alt='' /> : <img src={MultipleMatrix} alt='' />;
      break;
    case 'screenout':
      renderedIcon = filled ? <img src={ScreenoutColour} alt='' /> : <img src={Screenout} alt='' />;
      break;
    case 'custom_shelf':
      renderedIcon = <i className={`iconprovider__fa fad ${filled ? 'iconprovider__fa--filled' : ''} fa-abacus`} />;
      break;
    case 'single_vector':
      renderedIcon = filled ? <img src={SingleChoiceColour} alt='' /> : <img src={SingleChoice} alt='' />;
      break;
    case 'single_matrix':
      renderedIcon = filled ? <img src={SingleMatrixColour} alt='' /> : <img src={SingleMatrix} alt='' />;
      break;
    case 'ranking_matrix':
      renderedIcon = (
        <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-ranking-star`} />
      );
      break;
    case 'container':
      renderedIcon = (
        <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-object-group`} />
      );
      break;
    case 'loop':
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-repeat`} />;
      break;
    case 'randomize':
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-random`} />;
      break;
    case 'page':
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-file`} />;
      break;
    case 'imported':
      renderedIcon = (
        <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-arrow-turn-down-right`} />
      );
      break;
    default:
      renderedIcon = <i className={`iconprovider__fa fal ${filled ? 'iconprovider__fa--filled' : ''} fa-${icon}`} />;
  }

  return <span className={`iconprovider ${clickable ? 'iconprovider--clickable' : ''}`}>{renderedIcon}</span>;
};
