import React from 'react';
import { useSelector } from 'react-redux';
import { InlineEdit } from 'smv-components';
import { questionnaireActions } from '../../../redux/actions';

export const ElementTitle = ({ element, className, writeable }) => {
  const { id, title, type } = element;
  const surveyid = useSelector(state => state.survey.id);

  if (type === 'question') {
    if (!writeable) {
      return (
        <div className='d-flex ml-1'>
          {element.config?.varname && <span className='question-title__varname mr-1'>{element.config.varname} -</span>}
          <div>{element.title}</div>
        </div>
      );
    }

    return (
      <div className='question-title ml-1'>
        {element.config?.varname && (
          <div className='question-title__varname'>
            <span>{element.config.varname}</span>
            <span className='mx-1'>-</span>
          </div>
        )}
        <InlineEdit
          className='question-title__title'
          defaultValue={element.title}
          placeholder={'enter title...'}
          writeable={writeable}
          saveFn={newTitle => {
            if (writeable && title !== newTitle) {
              questionnaireActions.updateQuestionnaireElement(surveyid, element.id, {
                title: newTitle
              });
            }
          }}
        />
      </div>
    );
  }

  // container or page
  if (!writeable) {
    return (
      <span className='que-element-title text-uppercase mr-auto ml-1' id={'_' + id}>
        <div>{title}</div>
      </span>
    );
  }

  return (
    <span className='que-element-title text-uppercase mr-auto ml-1' id={'_' + id}>
      <InlineEdit
        className={className}
        defaultValue={title}
        placeholder={'enter title...'}
        writeable={writeable}
        saveFn={newTitle => {
          if (writeable && title !== newTitle) {
            questionnaireActions.updateQuestionnaireElement(surveyid, element.id, {
              parentId: element.parentId ?? null,
              title: newTitle
            });
          }
        }}
      />
    </span>
  );
};
