import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { parsePlaceholdersAndSetHtml, useElementPropertyTranslation } from 'smv-helpers';

import { VectorItemsPreview } from './VectorItemsPreview';
import { MatrixItemsScalePreview } from './MatrixItemsScalePreview';
import { SingleInputPreview } from './SingleInputPreview';
import { MultipleInputPreview } from './MultipleInputPreview';
import { MatrixCarouselPreview } from './MatrixCarouselPreview';
import { Shelf } from './ShelfPreview';
import { RankingMatrixPreview } from './RankingMatrixPreview';

export const QuestionPreview = ({ question }) => {
  const surveyMedia = useSelector(state => state.survey.media);

  const questionText = useElementPropertyTranslation(question, {
    useFallback: true,
    useShortcodes: false
  });

  const questionInstruction = useElementPropertyTranslation(question, {
    property: 'instruction',
    useFallback: true,
    useShortcodes: false
  });

  const renderedQuestionText = useMemo(() => parsePlaceholdersAndSetHtml(questionText, question, surveyMedia), [
    questionText,
    surveyMedia,
    question
  ]);

  const isVector = ['single_vector', 'multiple_vector'].includes(question.config.qtype);
  const isMatrix = ['single_matrix', 'multiple_matrix'].includes(question.config.qtype);
  const isSingleInputText = question.config.qtype === 'single_input_text';
  const isSingleInputNumber = question.config.qtype === 'single_input_number';
  const isMultipleInputText = question.config.qtype === 'multiple_input_text';
  const isMultipleInputNumber = question.config.qtype === 'multiple_input_number';
  const isShelfQuestion = question.config.qtype === 'custom_shelf';
  const displayMatrixAsCarousel = question.config?.displayAsCarousel ?? false;
  const isRankingMatrix = question.config.displayType === 'ranking';

  if (isRankingMatrix)
    return (
      <div className='question-preview'>
        <div className='question-preview__que-text' dangerouslySetInnerHTML={{ __html: renderedQuestionText }}></div>
        <hr />
        <p className='question-preview__que-instruction' dangerouslySetInnerHTML={{ __html: questionInstruction }}></p>
        <RankingMatrixPreview question={question} />
      </div>
    );

  return (
    <div className='question-preview'>
      <div className='question-preview__que-text' dangerouslySetInnerHTML={{ __html: renderedQuestionText }}></div>
      <hr />
      <p className='question-preview__que-instruction' dangerouslySetInnerHTML={{ __html: questionInstruction }}></p>
      {isVector && <VectorItemsPreview question={question} />}
      {isMatrix && !displayMatrixAsCarousel && <MatrixItemsScalePreview question={question} />}
      {isMatrix && displayMatrixAsCarousel && <MatrixCarouselPreview question={question} />}
      {(isSingleInputText || isSingleInputNumber) && <SingleInputPreview question={question} />}
      {(isMultipleInputText || isMultipleInputNumber) && <MultipleInputPreview question={question} />}
      {isShelfQuestion && <Shelf question={question} />}
      {/* shelf */}
    </div>
  );
};
