import React from 'react';
import PropTypes from 'prop-types';
import { QuestionText, VectorChoices } from '../components';

export const MultipleVector = ({ question, writeable }) => {
  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <VectorChoices question={question} writeable={writeable} />
    </>
  );
};

MultipleVector.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    config: PropTypes.shape({
      qtype: PropTypes.string.isRequired,
      choices: PropTypes.object
    }).isRequired
  }).isRequired,
  writeable: PropTypes.bool.isRequired
};
