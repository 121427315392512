import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { IntlContext, useIntlContext } from '../translation';

export const TranslationProvider = ({ children }) => {
  const intlContextValue = useIntlContext();

  const locale = useSelector(state => state.appSettings?.locale ?? intlContextValue.defaultLocale);

  return (
    <IntlContext.Provider value={intlContextValue}>
      <IntlProvider
        locale={locale.replace('_', '-')}
        defaultLocale={intlContextValue.defaultLocale}
        messages={intlContextValue.messages[locale]}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
