import Axios from 'axios';
import { Store as notify } from 'react-notifications-component';
// import { customAlphabet } from 'nanoid'

import 'smv-redux/axiosConfig';
import { intl, store } from 'smv-redux';
import { dataPrepActionTypes } from '../constants';
import { defaultNotifyProps } from 'smv-constants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

const addDataPrep = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_REQUEST,
    payload: { surveyId }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_FAILURE,
        payload: {
          error
        }
      });
    });
};

const getDataPrepBySurveyId = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/data-preps`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_SUCCESS,
        payload: {
          surveyId,
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addWeight = async (surveyId, mode, label) => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_REQUEST,
    payload: surveyId
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/weights`, { mode: mode, label: label })
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateWeight = async (surveyId, weightId, data) => {
  delete data.isLoading;
  delete data.result;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/weights/${weightId}`, data)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteWeight = async (surveyId, weightId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_REQUEST,
    payload: { surveyId, weightId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/weights/${weightId}`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addWeightDefinition = async (surveyId, weightId, data = {}) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/weights/${weightId}/definitions`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateWeightDefinition = async (surveyId, weightId, definitionId, data) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/weights/${weightId}/definitions/${definitionId}`, data)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });
      Promise.resolve(res);
      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
      Promise.reject(error);
      return error;
    });
};

const deleteWeightDefinition = async (surveyId, weightId, definitionId, data = {}) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_REQUEST,
    payload: { ...data }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/weights/${weightId}/definitions/${definitionId}`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculation = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_REQUEST,
    payload: surveyId
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateCalculation = async (surveyId, calculationId, data) => {
  console.log(data);
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/calculations/${calculationId}`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteCalculation = async (surveyId, calculationId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_REQUEST,
    payload: { surveyId, calculationId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/calculations/${calculationId}`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculationValue = async (surveyId, calculationId, data) => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateCalculationValue = async (surveyId, calculationId, valueId, data) => {
  console.log(data);
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteCalculationValue = async (surveyId, calculationId, valueId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { surveyId, calculationId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculationValueConditionElement = async (surveyId, calculationId, valueId, data) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions`, data)
    .then(res => {
      const condition = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          calculationId,
          valueId,
          condition
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
        payload: {
          surveyId,
          calculationId,
          valueId,
          error
        }
      });

      return error;
    });
};

const removeCalculationValueConditionElement = async (surveyId, calculationId, valueId, condtionElementId) => {
  store.dispatch({
    type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: condtionElementId
  });

  return Axios.delete(
    `/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions/${condtionElementId}`
  )
    .then(res => {
      const condition = res.data;

      store.dispatch({
        type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          calculationId,
          valueId,
          condition
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
        payload: {
          error
        }
      });

      return error;
    });
};

const updateCalculationValueConditionElement = async (surveyId, calculationId, valueId, condtionElementId, data) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions/${condtionElementId}`,
      {
        ...data,
        id: undefined
      }
    )
      .then(res => {
        const condition = res.data;
        store.dispatch({
          type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            calculationId,
            valueId,
            condition
          }
        });

        resolve(res);
      })
      .catch(error => {
        store.dispatch({
          type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
          payload: {
            error
          }
        });

        reject(error);
      });
  });
};

const uploadWeightCsvFile = (surveyId, weightId, csvFile, callbackFn = () => {}) => {
  store.dispatch({
    type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_REQUEST,
    payload: { surveyId, weightId }
  });

  const fd = new FormData();
  fd.append('file', csvFile);
  fd.append('name', csvFile.name);
  Axios.post(`/surveys/${surveyId}/data-preps/weights/${weightId}/csv`, fd)
    .then(response => {
      const data = response.data;
      const dataPrep = data.data;
      store.dispatch({
        type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_SUCCESS,
        payload: {
          dataPrep
        }
      });
      callbackFn(false);

      notify.addNotification({
        title: intl.formatMessage({
          id: 'smoove.page.survey.data.weights.upload-csv',
          defaultMessage: `Successfully uploaded`
        }),

        type: 'success',
        ...defaultNotifyProps
      });

      return { dataPrep };
    })
    .catch(err => {
      console.log(err);
      callbackFn(err);
      store.dispatch({
        type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_FAILURE,
        payload: {
          err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const deleteWeightCsvFile = (surveyId, weightId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_WEIGHT_CSV_REQUEST,
    payload: { surveyId, weightId }
  });

  Axios.delete(`/surveys/${surveyId}/data-preps/weights/${weightId}/csv`)
    .then(response => {
      const dataPrep = response.data;
      store.dispatch({
        type: dataPrepActionTypes.DELETE_WEIGHT_CSV_SUCCESS,
        payload: {
          dataPrep
        }
      });

      notify.addNotification({
        title: intl.formatMessage({
          id: 'smoove.page.survey.data.weights.delete-csv',
          defaultMessage: `Successfully deleted`
        }),

        type: 'success',
        ...defaultNotifyProps
      });

      return { dataPrep };
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_WEIGHT_CSV_FAILURE,
        payload: {
          err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

export const dataPrepActions = {
  addCalculation,
  updateCalculation,
  deleteCalculation,

  addCalculationValue,
  updateCalculationValue,
  deleteCalculationValue,

  addDataPrep,
  getDataPrepBySurveyId,

  addCalculationValueConditionElement,
  updateCalculationValueConditionElement,
  removeCalculationValueConditionElement,

  addWeight,
  updateWeight,
  deleteWeight,

  addWeightDefinition,
  updateWeightDefinition,
  deleteWeightDefinition,

  uploadWeightCsvFile,
  deleteWeightCsvFile
};
