import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormGroup, Table, UncontrolledTooltip } from 'reactstrap';
import { RowLabel } from './RowLabel';
import { getCountryIso2Code } from 'smv-helpers';

// @Todo: Primary language/ locale must be set to define, in which language the questionnaire will be created/ displayed by default
export const CountrySelectRow = props => {
  const {
    title,
    name,
    value,
    countries,
    mainLanguage,
    setMainLanguage,
    handleUpdateCountry,
    add = () => console.log('Info: no add handler specified.'),
    remove = () => console.log('Info: no remove handler specified.'),
    placeholder = null,
    tooltip = null
  } = props;

  const unselectedCountries = Object.keys(countries)
    .filter(key => !Object.keys(value).includes(key))
    .reduce((res, key) => ({ ...res, [key]: countries[key] }), {});

  let countriesSelectedForSampling = [];
  Object.keys(value).forEach(el => {
    if (!value[el].reportingOnly) countriesSelectedForSampling.push(el);
  });

  return (
    <FormGroup row>
      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>
        {/* Seleted Countries */}
        <Table>
          <thead>
            <tr>
              <th>Country</th>
              <th>{Object.values(value).length > 0 && 'main language'}</th>
              <th>
                {Object.values(value).length > 0 && (
                  <>
                    <span>reporting only</span>
                    <i className='ml-2 fal fa-circle-info' id={`tt_reporting_only`} />
                    <UncontrolledTooltip target={`tt_reporting_only`}>
                      When activated, this locale will not be used as a sampling language but only inside smoove for
                      reporting. This can be useful if you want to run a survey in non-english countries but report in
                      english - then you would add UK/USA as a country and activate "reporting only" for this country.
                    </UncontrolledTooltip>
                  </>
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(value).map(country => (
              <tr key={country.locale}>
                <td>
                  <div className='d-flex flex-row no-gutters align-items-center'>
                    <span
                      className={`flag-icon flag-icon-squared flag-icon-${getCountryIso2Code(
                        country.iso3
                      )?.toLowerCase()} mr-2`}
                    />
                    <div>{country.label}</div>
                  </div>
                </td>
                <td>
                  <span>
                    {/**
                     * @Todo: Add main language logic for multi language countries!
                     */}
                    {country.locale === mainLanguage && <i className='fas fa-globe text-smv-green' />}
                    {country.locale !== mainLanguage && (
                      <i
                        className='fal fa-globe text-smv-light pointer'
                        onClick={() => setMainLanguage(country.locale)}
                      />
                    )}
                  </span>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <FormGroup switch>
                      <Input
                        type='switch'
                        role='switch'
                        id={`toggleIsReportingOnly`}
                        name='toggleIsReportingOnly'
                        checked={country.reportingOnly ?? false}
                        disabled={!country.reportingOnly && countriesSelectedForSampling.length <= 1}
                        onChange={e =>
                          handleUpdateCountry({
                            ...country,
                            reportingOnly: !country.reportingOnly
                          })
                        }
                      />
                    </FormGroup>
                  </div>
                </td>
                <td>
                  <i
                    className='fal fa-trash-alt text-danger pointer text-smv p-1'
                    onClick={() => remove(country.locale)}
                  />
                </td>
              </tr>
            ))}
            {Object.keys(unselectedCountries).length > 0 && (
              <tr>
                <td colSpan={4} style={{ borderBottom: 'none' }}>
                  <Input type='select' name={name} id='pc_countries' className={'question'} value={0} onChange={add}>
                    <option value={0} disabled={true} defaultValue={0}>
                      {placeholder}
                    </option>
                    {Object.keys(unselectedCountries).map(countryId => (
                      <option key={countryId} value={countryId}>
                        {unselectedCountries[countryId].label}
                      </option>
                    ))}
                  </Input>
                  <label htmlFor='name'>
                    <span />
                  </label>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </FormGroup>
  );
};

CountrySelectRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  countries: PropTypes.object.isRequired,
  add: PropTypes.func,
  remove: PropTypes.func,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string
};
