import React from 'react';
import { useSelector } from 'react-redux';

import { FormulaBuilderTableElement } from '.';

export const FormulaBuilderTableElementSystemVariable = ({ calculationMode, main, handleAddReference }) => {
  const systemVariable = useSelector(state => state.survey?.systemVariables?.list?.[main.sourceId] ?? null);
  return (
    <div key={`${main.id}`}>
      <h6>{systemVariable.label}</h6>
      {systemVariable.order.map((valueId, idx) => {
        const value = systemVariable.list[valueId];
        const element = {
          sourceId: main.sourceId,
          sourceType: main.sourceType,
          metric: 'smvrslt_perc',

          // cell identifiers
          rowId: calculationMode === 'rows' ? main.id : null,
          rowSubId: calculationMode === 'rows' ? valueId : null,
          rowSubSubId: null,
          headId: calculationMode === 'heads' ? main.id : null,
          headSubId: calculationMode === 'heads' ? valueId : null
        };

        return (
          <FormulaBuilderTableElement
            key={`${main.id}_${valueId}`}
            element={element}
            value={value}
            handleAddReference={handleAddReference}
          />
        );
      })}
    </div>
  );
};
