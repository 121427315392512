export const chartSettings = {
  default: {
    bar: {
      export: {
        pptx: true,
        xlsx: true,
        image: true
      },
      axis: true,
      chartOrientation: true,
      chartSubtype: true,
      colorSchema: true,
      tableTranspose: true,
      chartDomainAuto: true,
      axisYreversed: true,
      axisYwidth: true,
      dimensionsReversed: true,
      showGrid: true,
      valuesShow: true,
      showTooltip: true,
      showLegend: true,
      significancesShow: true,
      tooltipShow: true,
      legendShow: true,
      legendSaveHiddenSeries: true,
      legendIconSize: true
    },
    line: {
      export: {
        pptx: true,
        xlsx: true,
        image: true
      },
      axis: true,
      chartOrientation: true,
      chartSubtype: true,
      colorSchema: true,
      tableTranspose: true,
      chartDomainAuto: true,
      axisYreversed: true,
      axisYwidth: true,
      dimensionsReversed: true,
      showGrid: true,
      valuesShow: true,
      showTooltip: true,
      showLegend: true,
      significancesShow: true,
      tooltipShow: true,
      legendShow: true,
      legendSaveHiddenSeries: true,
      legendIconSize: true
    },
    area: {
      export: {
        pptx: true,
        xlsx: true,
        image: true
      },
      axis: true,
      chartOrientation: true,
      chartSubtype: true,
      colorSchema: true,
      tableTranspose: true,
      chartDomainAuto: true,
      dimensionsReversed: true,
      showGrid: true,
      valuesShow: true,
      significancesShow: true,
      showTooltip: true,
      showLegend: true,
      legendSaveHiddenSeries: true,
      legendIconSize: true
    },
    scatter: {
      export: {
        pptx: true,
        xlsx: true,
        image: true
      },
      axis: true,
      colorSchema: true,
      tableTranspose: false, // is automatically controlled by the dimension settings
      chartDomainXAuto: true,
      chartDomainYAuto: true,
      dimensionsReversed: true,
      showGrid: true,
      valuesShow: true,
      significancesShow: false,
      showTooltip: true,
      showLegend: true,
      legendSaveHiddenSeries: true,
      legendIconSize: true
    },
    pie: {
      export: {
        pptx: true,
        xlsx: true,
        image: true
      },
      colorSchema: true,
      axis: true,
      innerRadius: true,
      valuesShow: true,
      significancesShow: true,
      showTooltip: true,
      showLegend: true,
      legendIconSize: true
    },
    table: {
      export: {
        pptx: true,
        xlsx: true
      },
      showBase: true,
      tableTranspose: true,
      showQuestionTitleColumn: true,
      showDetailedMatrixChoiceTitleColumn: true,
      significancesShow: true,
      conditionalFormattingTable: true
    }
  },
  listing: {
    table: {
      export: {
        xlsx: true
      }
    },
    wordcloud: {
      export: {
        pptx: false,
        // pdf: true,
        xlsx: true,
        image: true
      },
      colorSchema: true,
      wordcloudFontSizes: true
    }
  }
};

export const chartTypes = {
  bar: {
    label: `smoove.page.tables.chart-config.chart-types.bar`
  },
  line: {
    label: `smoove.page.tables.chart-config.chart-types.line`
  },
  area: {
    label: `smoove.page.tables.chart-config.chart-types.area`
  },
  scatter: {
    label: `smoove.page.tables.chart-config.chart-types.scatter`
  },
  pie: {
    label: `smoove.page.tables.chart-config.chart-types.pie`
  },
  table: {
    label: `smoove.page.tables.chart-config.chart-types.table`
  },
  wordcloud: {
    label: `smoove.page.tables.chart-config.chart-types.wordcloud`
  }
};

export const tableTypeChartTypes = Object.fromEntries(
  Object.keys(chartSettings).map(tableType => {
    return [tableType, Object.keys(chartSettings[tableType])];
  })
);
