import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const translationPrefix = 'smoove.page.gtpr';

const GeneralDataProtectionRegulation = props => {
  const intl = useIntl();

  return (
    <div className='smv-imprint base-content container-fluid'>
      <Helmet>
        <title>{intl.formatMessage({ id: `${translationPrefix}.headline` })}</title>
      </Helmet>
      <h1 className='d-block text-uppercase text-smv fw-bold'>
        <FormattedMessage id={`${translationPrefix}.headline`} />
      </h1>
      <div className='row'>
        <div className='col-md-12'>
          <h2>
            <FormattedMessage id={`${translationPrefix}.01-h2-common`} />
          </h2>
          <h3>
            <FormattedMessage id={`${translationPrefix}.01-h3-responsible`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.01-h3-responsible-p1`} />
            <br />
            <br />
            <b>mindline analytics GmbH</b>
            <br />
            <FormattedMessage id={`${translationPrefix}.01-cto`} />: Stephan Winkelmann, Jeremy Kirsten
            <br />
            Nordostpark 45
            <br />
            90411 Nürnberg
            <br />
            <br />
            <FormattedMessage id={`${translationPrefix}.01-phone`} />: +49 (0)40 808 109 -0
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.01-h3-dsb`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.01-h3-dsb-p1`} />
            <a href='mailto:dsb@mindline-analytics.de'>dsb@mindline-analytics.de</a>
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.01-h3-links`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.01-h3-links-p1`} />
          </p>
          <h2>
            <FormattedMessage id={`${translationPrefix}.02-h2-data`} />
          </h2>
          <h3>
            <FormattedMessage id={`${translationPrefix}.02-h3-usage`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-usage-p1`} />
          </p>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-usage-p2`} />
          </p>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-usage-p3`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.02-h3-ssl`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-ssl-p1`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.02-h3-localstorage`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-localstorage-p1`} />
          </p>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-localstorage-p2`} />{' '}
          </p>
          {/* Dabei werden folgende Informationen lokale Daten hinterlegt:  XXXX XXX */}
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-localstorage-p3`} />
          </p>
          <p>
            <FormattedMessage id={`${translationPrefix}.02-h3-localstorage-p4`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.021-data-processing`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.021-data-processing-text`} />
          </p>
          <p>{/* sentry.io Unsere Website nutzt ein Tool für automatisiertes Bugreporting */}</p>
          <h2>
            <FormattedMessage id={`${translationPrefix}.03-h2-special`} />
          </h2>
          <h3>
            <FormattedMessage id={`${translationPrefix}.03-h3-contactform`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.03-h3-contactform-p1`} />
          </p>
          <h2>
            <FormattedMessage id={`${translationPrefix}.04-h2-yourrights`} />
          </h2>
          <h3>
            <FormattedMessage id={`${translationPrefix}.04-h3-retraction`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.04-h3-retraction-p1`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.04-h3-data-portability`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.04-h3-data-portability-p1`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.04-h3-right-delete`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.04-h3-right-delete-p1`} />
          </p>
          <h3>
            <FormattedMessage id={`${translationPrefix}.04-h3-right-complaint`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.04-h3-right-complaint-p1`} />
            <a href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'>
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            </a>
          </p>
          <h2>
            <FormattedMessage id={`${translationPrefix}.05-h2-changes`} />
          </h2>
          <h3>
            <FormattedMessage id={`${translationPrefix}.05-h3-declaration`} />
          </h3>
          <p>
            <FormattedMessage id={`${translationPrefix}.05-h3-declaration-p1`} />
          </p>
        </div>
      </div>
    </div>
  );
};

GeneralDataProtectionRegulation.propTypes = {};

export { GeneralDataProtectionRegulation };
