import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Input } from 'reactstrap';
import { Store as notify } from 'react-notifications-component';

import { defaultNotifyProps } from 'smv-constants';
import { isValidEmail } from 'smv-helpers';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

export const PasswordResetPage = () => {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();

  const isLoggedIn = useSelector(state => state.authentication.loggedIn ?? false);
  const token = params.resetToken ?? null;

  const [isRecoveryLinkSent, setIsRecoveryLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailInputValue, setEmailInputValue] = useState('');

  const handleEmailInputChange = useCallback(e => {
    setEmailInputValue(e.target.value);
    isValidEmail(e.target.value) ? setIsEmailValid(true) : setIsEmailValid(false);
  }, []);

  const handleSubmitResetPassword = useCallback(
    event => {
      event.preventDefault();
      if (!isValidEmail(emailInputValue)) {
        setIsEmailValid(false);
      } else {
        setIsLoading(true);
        axios
          .post('/passwordResetRequest', { email: emailInputValue })
          .then(res => {
            setIsRecoveryLinkSent(true);
            setIsLoading(false);
            notify.addNotification({
              title: intl.formatMessage({ id: 'core.page.reset-password.click-on-the-link-in-the-email-text' }),
              type: 'success',
              dismiss: {
                duration: 3000,
                showIcon: true
              },
              ...defaultNotifyProps
            });
          })
          .catch(err => {
            console.log(err);
            notify.addNotification({
              title: parseErrorAsMessageString(err),
              type: 'danger',
              ...defaultNotifyProps,
              dismiss: {
                duration: 4000,
                showIcon: true
              }
            });
            setIsLoading(false);
          });
      }
    },
    [emailInputValue, intl]
  );

  if (isLoggedIn) {
    history.push('/one/projects');
  }

  if (token) return <NewPassword />;

  return (
    <div className='container-fluid base-content flex-fill'>
      <div className='my-4'>
        <h2>{intl.formatMessage({ id: 'core.page.reset-password.recover-password' })}</h2>
      </div>
      {!isRecoveryLinkSent && (
        <Form onSubmit={handleSubmitResetPassword}>
          <p>{intl.formatMessage({ id: 'core.page.reset-password.reset-your-password-text' })}</p>
          <div className='fw-bold mb-1'>{intl.formatMessage({ id: 'core.page.reset-password.your-email' })}</div>
          <div style={{ maxWidth: '350px' }}>
            <Input
              value={emailInputValue}
              onChange={handleEmailInputChange}
              type={'email'}
              autoFocus={true}
              placeholder={intl.formatMessage({ id: 'core.page.reset-password.enter-your-email-address' })}
            />
          </div>
          <div style={{ maxWidth: '350px' }}>
            {!isEmailValid && (
              <div>{intl.formatMessage({ id: 'core.page.reset-password.please-enter-a-valid-email-address' })}</div>
            )}
            <Button
              onClick={handleSubmitResetPassword}
              className='mt-4'
              disabled={!isEmailValid || emailInputValue.length <= 0}
              type={'submit'}
            >
              {intl.formatMessage({ id: 'core.page.reset-password.get-recovery-link' })}
            </Button>
          </div>
        </Form>
      )}

      {isLoading && <span className='fa fa-spinner fa-spin'></span>}

      {isRecoveryLinkSent && (
        <div>
          <p>{intl.formatMessage({ id: 'core.page.reset-password.click-on-the-link-in-the-email-text' })}</p>
          <Link to={'/login'}>{intl.formatMessage({ id: 'core.page.reset-password.back-to-login' })}</Link>
        </div>
      )}
    </div>
  );
};

const NewPassword = () => {
  const params = useParams();
  const token = params.resetToken ?? null;
  const intl = useIntl();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordSetSuccess, setIsPasswordSetSuccess] = useState(false);

  const [passwordInputValue, setPasswordInputValue] = useState('');
  const [password2InputValue, setPassword2InputValue] = useState('');

  const handlePasswordInputChange = useCallback(e => {
    setPasswordInputValue(e.target.value);
  }, []);

  const handlePassword2InputChange = useCallback(e => {
    setPassword2InputValue(e.target.value);
  }, []);

  const passwordsAreEqual = useMemo(() => {
    return passwordInputValue === password2InputValue;
  }, [passwordInputValue, password2InputValue]);

  const handleSetNewPassword = useCallback(
    e => {
      e.preventDefault();
      if (passwordInputValue.length > 0 && passwordsAreEqual) {
        setIsLoading(true);
        axios
          .post(`/passwordResetRequest/${token}`, { password: passwordInputValue })
          .then(res => {
            setIsLoading(false);
            setIsPasswordSetSuccess(true);
            notify.addNotification({
              title: intl.formatMessage({ id: 'core.page.reset-password.your-new-password-has-been-set' }),
              type: 'success',
              ...defaultNotifyProps,
              dismiss: {
                duration: 2000,
                showIcon: true
              }
            });
          })
          .catch(err => {
            console.log(err);
            setIsLoading(false);
            notify.addNotification({
              title: parseErrorAsMessageString(err),
              type: 'danger',
              ...defaultNotifyProps,
              dismiss: {
                duration: 4000,
                showIcon: true
              }
            });
          });
      }
    },
    [passwordInputValue, token, passwordsAreEqual, intl]
  );

  return (
    <div className='container-fluid base-content flex-fill'>
      <div className='my-4'>
        <h2>{intl.formatMessage({ id: 'core.page.reset-password.recover-password' })}</h2>
      </div>
      {!isPasswordSetSuccess && (
        <Form onSubmit={handleSetNewPassword}>
          <p>{intl.formatMessage({ id: 'core.page.reset-password.please-enter-your-new-password' })}</p>
          <div style={{ maxWidth: '350px' }}>
            <Input
              value={passwordInputValue}
              onChange={handlePasswordInputChange}
              type={'password'}
              placeholder={intl.formatMessage({ id: 'core.page.reset-password.enter-new-password' })}
              autoFocus
            />
            <Input
              className='mt-2'
              value={password2InputValue}
              onChange={handlePassword2InputChange}
              type={'password'}
              placeholder={intl.formatMessage({ id: 'core.page.register.repeat-password' })}
            />
          </div>
          <div style={{ maxWidth: '350px' }}>
            {!passwordsAreEqual && (
              <div>{intl.formatMessage({ id: 'core.page.reset-password.the-entered-passwords-are-not-equal' })}</div>
            )}
            <Button
              onClick={handleSetNewPassword}
              className='mt-4'
              disabled={!passwordsAreEqual || passwordInputValue.length <= 0}
              type={'submit'}
            >
              {intl.formatMessage({ id: 'core.page.reset-password.set-new-password' })}
            </Button>
          </div>
          {isLoading && <span className='fa fa-spinner fa-spin'></span>}
        </Form>
      )}
      {isPasswordSetSuccess && (
        <>
          <p>{intl.formatMessage({ id: 'core.page.reset-password.your-password-has-been-reset-successfully' })}</p>
          <Button onClick={() => history.push('/login')} className='mt-4' type={'submit'}>
            {intl.formatMessage({ id: 'core.menu.item.login' })}
          </Button>
        </>
      )}
    </div>
  );
};
