import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const DeletePageButton = ({ toggle }) => {
  return (
    <div className={`smv-custom-dropdown__buttons`} onClick={toggle}>
      <i className='fal fa-trash-alt icon-smv-blue mr-1' />
      <span>
        <FormattedMessage defaultMessage={`Delete Page`} id={`smoove.page.reports.modals.delete-page.button-open`} />
      </span>
    </div>
  );
};

export const DeletePageModal = ({ isOpen, toggle, reportsHandler, page }) => {
  const { name, id } = page;

  const deleteHandler = () => {
    reportsHandler.deletePage(id);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={'smoove.page.reports.modals.delete-page.headline'} defaultMessage={'Delete Page'} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id={'smoove.page.reports.modals.delete-page.body'}
          defaultMessage={"Are you sure you want to delete report '{name}'?"}
          values={{ title: name }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.cancel'} defaultMessage={'Cancel'} />
        </Button>
        <Button color={'danger'} onClick={deleteHandler}>
          <FormattedMessage id={'smoove.common.buttons.delete'} defaultMessage={'Delete'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const DeletePageButtonAndModal = ({ reportsHandler, page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <>
      <DeletePageButton toggle={toggle} />
      <DeletePageModal toggle={toggle} isOpen={isOpen} reportsHandler={reportsHandler} page={page} />
    </>
  );
};
