import classNames from 'classnames';
import React, { useCallback } from 'react';
import { DropdownItem } from 'reactstrap';
import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ReferenceValue = ({ element, value, isSelected = false, handleReferenceSelect }) => {
  const _handleReferenceSelect = useCallback(() => handleReferenceSelect(element), [element, handleReferenceSelect]);

  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  return (
    <DropdownItem
      onClick={_handleReferenceSelect}
      className={classNames({
        'dropdown-item--active': isSelected
      })}
    >
      <div className={'smv-custom-dropdown__buttons'}>
        {isSelected && <i className='fa-regular fa-lock'></i>}
        {!isSelected && <i className='fa-regular'></i>}
        <span>{label}</span>
      </div>
    </DropdownItem>
  );
};
