import { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { reorderArray } from 'smv-helpers';
import { getMediaElementType } from './helpers/getMediaElementType';

export const MediaLibrarySelectedFilesList = ({
  selectedFiles,
  handleClickFile,
  handleEditImageProperties,
  setSelectedFiles,
  isOpen
}) => {
  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorderArray(selectedFiles, result.source.index, result.destination.index);

      setSelectedFiles(items);
    },
    [selectedFiles, setSelectedFiles]
  );

  // fixes a bug with react-beautiful-dnd throwing an error when the modal closes
  if (!isOpen) return <div></div>;

  return (
    <>
      <h5 className='mt-2'>
        <FormattedMessage id={'smoove.common.media-library.selected-images'} defaultMessage={'Selected files'} />
      </h5>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppableMediaLibrary' direction='horizontal'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className='media-library-upload-stage'>
              {selectedFiles.map((file, index) => {
                return (
                  <Draggable key={file?.name + '_' + index} draggableId={file?.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='media-library-upload-stage__item'
                        key={`${file?.name}_${index}`}
                      >
                        {getMediaElementType(file) === 'image' && (
                          // todo: remove date, the backend should provide a last edited parameter in the media url to reload media, if file has changed
                          <img
                            src={file?.local?.thumbnailUrl + '?' + new Date().getTime()}
                            alt={file?.originalName}
                            title={file?.originalName}
                          />
                        )}
                        {getMediaElementType(file) === 'audio' && (
                          <div className='av-media d-flex flex-column align-items-center justify-content-center'>
                            <i className={`far fa-2x fa-music `} />
                            <div className='av-media-name' title={file.originalName}>
                              {file.originalName}
                            </div>
                          </div>
                        )}
                        {getMediaElementType(file) === 'video' && (
                          <div className='av-media d-flex flex-column align-items-center justify-content-center'>
                            <i className={`far fa-2x fa-file-video `} />
                            <div className='av-media-name' title={file.originalName}>
                              {file.originalName}
                            </div>
                          </div>
                        )}
                        <Button
                          className='image-edit-btn'
                          size={'sm'}
                          color={'secondary'}
                          onClick={() => handleEditImageProperties(file)}
                        >
                          <i className='far fa-cog' />
                        </Button>
                        <Button
                          className='image-remove-btn'
                          size={'sm'}
                          color={'danger'}
                          onClick={() => {
                            handleClickFile(file);
                          }}
                        >
                          <i className='far fa-minus' />
                        </Button>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
