import React, { useMemo } from 'react';

import { ResultTableChartResultRow, ResultTableChartResultRowHeader } from '.';

export const ResultTableChartCalculationResultRow = ({
  table,
  chartConfig,
  tableResult,
  row,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  const { rows, config } = table;
  let heads = table.heads;

  let colSpan = matrixType === 'detail' && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) ? 2 : 1;
  const isHidden = useMemo(() => row.config?.hidden?.[row.id] === true, [row]);

  const calculationTitle = row?.config?.title ?? 'Calculation';

  const significancesShow = chartConfig?.significancesShow ?? false;

  if (isCompactMatrix) {
    const defaultRows = { list: {}, order: [] };

    (rows?.order ?? []).forEach(rowid => {
      if (['question', 'sysvar', 'calcvar'].includes(rows.list[rowid].sourceType)) {
        if (matrixType !== 'compact-merged' || defaultRows.order.length === 0) {
          defaultRows.order.push(rowid);
          defaultRows.list[rowid] = rows.list[rowid];
        }
      }
    });

    if (heads.order.length > 0) {
      heads.order.forEach(headId => {
        const head = heads.list[headId];
        if (head.sourceType === 'calculation') {
          defaultRows.order.push(headId);
          defaultRows.list[headId] = head;
        }
      });
    }

    heads = defaultRows;
  }

  if (isHidden) return null;

  return [
    <ResultTableChartResultRow
      key={`result-row-${row.id}_calculation`}
      rowid={row.id}
      row={row}
      orderItem={{ label: calculationTitle }}
      subelement={{}}
      choiceid={row.id}
      scaleid={null}
      heads={heads}
      config={config}
      tableResult={tableResult}
      conditionalFormats={chartConfig.conditionalFormats}
      isCompactMatrix={isCompactMatrix}
      matrixType={matrixType}
      headMappingTable={{}}
      metric={'smvrslt_calculation'}
      significancesShow={significancesShow}
      rowHeadColSpan={colSpan > 1 && !chartConfig.showQuestionTitleColumn ? 2 : 1}
      hover={hover}
    >
      {chartConfig.showQuestionTitleColumn && (
        <ResultTableChartResultRowHeader rowid={row.id} colSpan={colSpan} label={'Calculation'} />
      )}
    </ResultTableChartResultRow>
  ];
};
