import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import produce from 'immer';

import { ConditionalFormat } from './ConditionalFormat';

import './CondifionalFormatModal.scss';

export const ConditionalFormattingTableButton = ({ toggle }) => {
  const intl = useIntl();

  return (
    <Button id={`table-filter-modal-button`} onClick={toggle} size={'sm'} color={'secondary'} outline={true}>
      {intl.formatMessage({ id: `smoove.page.tables.chart-config.conditional-formatting-table.button` })}
    </Button>
  );
};

export const ConditionalFormattingTableModal = ({ isOpen, toggle, row, tableConfig, chartConfig, handler }) => {
  const intl = useIntl();
  const [conditionalFormats, setConditionalFormats] = useState(
    chartConfig?.conditionalFormats ?? { order: [], list: {} }
  );
  // const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const saveHandler = useCallback(() => {
    handler.setTableConfig(s =>
      produce(s, d => {
        d.chart.conditionalFormats = conditionalFormats;
      })
    );
    toggle();
  }, [handler, conditionalFormats, toggle]);

  const onDragEnd = useCallback(result => {
    const { destination, source } = result;
    if (!destination || !source || destination.index === source.index) return;
    setConditionalFormats(s =>
      produce(s, d => {
        d.order = arrayMoveImmutable(d.order, source.index, destination.index);
      })
    );
  }, []);

  return (
    <Modal className='formula-builder-modal' isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({
          id: 'smoove.page.tables.chart-config.conditional-formatting-table.title',
          defaultMessage: 'Conditional Formats'
        })}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.add-conditional-format'
              })}
            </Label>
          </Col>
        </Row>
        <ConditionalFormat setConditionalFormats={setConditionalFormats} tableConfig={tableConfig} />

        <Row className={'mt-3'}>
          <Col>
            <Label>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.conditional-formatting-table.configured-conditional-formats'
              })}
            </Label>
          </Col>
        </Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='conditional-format-droppable'>
            {provided => (
              <div
                className={`droppable-default droppable-default--filters-selected`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {conditionalFormats.order.map((formatId, index) => {
                  return (
                    <Draggable key={`conditionalFormat_${formatId}`} draggableId={formatId} index={index}>
                      {provided => (
                        <div
                          className={`conditional-format__drag-container draggable-default`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <i className='fal fa-grip-vertical mr-2'></i>

                          <ConditionalFormat
                            setConditionalFormats={setConditionalFormats}
                            conditionalFormat={conditionalFormats.list[formatId]}
                            tableConfig={tableConfig}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        {/* <Button color={isSaveButtonDisabled ? 'grey' : 'primary'} disabled={isSaveButtonDisabled} onClick={saveHandler}> */}
        <Button color={'primary'} onClick={saveHandler}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.save`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ConditionalFormattingTableButtonAndModal = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <>
      <ConditionalFormattingTableButton toggle={toggle} />
      {isOpen && <ConditionalFormattingTableModal toggle={toggle} isOpen={isOpen} {...props} />}
    </>
  );
};
