import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import { Cell } from '.';
import { getHighestHeadBase } from './result-rows/helper';

export const BaseRow = ({
  heads,
  rows,
  config,
  tableResult,
  metric = 'smvrslt_base',
  isCompactMatrix = false,
  isDetailedMatrix = false,
  matrixType = null
}) => {
  const { showTotal = true, showHiddenColumns = true } = config;

  const spareSpan = matrixType === 'detail' ? 2 : 1;

  const getBaseValue = (headid, headchoiceid, metric) => {
    if (!tableResult || !tableResult?.values) return '-';
    const bases = [];

    tableResult?.order?.forEach(tableRow => {
      const { rowid, subelement, subsubelement } = tableRow;

      let elementbase = tableResult.values?.[rowid]?.[subelement] ?? {};
      if (subsubelement) elementbase = tableResult.values?.[rowid]?.[subelement][subsubelement] ?? {};

      if (!!elementbase?.[headid]?.[headchoiceid]?.[metric])
        bases.push(parseInt(elementbase[headid][headchoiceid][metric], 10));
    });

    if (bases.length <= 0) return '0';

    const min = Math.min(...bases);
    const max = Math.max(...bases);

    if (min !== max) return `${min}-${max}`;
    return max;
  };

  let headCols = heads;
  /**
   * Set headcols from rows, if compact matrix should be displayed,
   * to provide the matrixs choices as head columns
   */
  if (isCompactMatrix) {
    headCols = produce(rows, draft => {
      const [firstRowId, ...otherRows] = draft.order;
      draft.order = [firstRowId];
      otherRows.forEach(_rowId => delete draft.list[_rowId]);

      if (heads.order.length > 0) {
        heads.order.forEach(headId => {
          const head = heads.list[headId];
          if (head.sourceType === 'calculation') {
            draft.order.push(headId);
            draft.list[headId] = head;
          }
        });
      }
    });
  }

  return (
    <tr className={'base-row result__row small font-italic'}>
      {/* Row Label */}
      {spareSpan > 0 && <th colSpan={spareSpan} scope='row'></th>}
      <th colSpan={1} scope='row'>
        Base
      </th>

      {/* Total Column */}
      {showTotal && tableResult && !isCompactMatrix && (
        <Cell
          key={`baserow_${metric}`}
          value={getBaseValue('__total__', '__total__', metric)}
          config={{}}
          idHidden={false}
        />
      )}

      {/* Other Columns */}
      {headCols.order.length > 0 &&
        headCols.order.map(headid => {
          const { sourceType } = headCols.list[headid];
          const headConfig = headCols.list[headid].config ?? {
            sortation: {},
            excluded: {},
            hidden: {}
          };

          return tableResult?.headOrder?.[headid]?.map(orderItem => {
            /** @todo: refactor hidden heads to use headOrder */

            let isHiddenColumn = headCols.list[headid]?.config?.hidden?.[orderItem.headSubId] ?? false;
            if (!isHiddenColumn && (headConfig?.autoHideLowBases ?? false)) {
              const autoHideLowBasesHurdle = headConfig?.autoHideLowBasesHurdle ?? 0;
              const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
                isDetailedMatrix: isDetailedMatrix,
                excludeHidden: true
              });
              if (highestBase <= autoHideLowBasesHurdle) isHiddenColumn = true;
            }

            if (!showHiddenColumns && isHiddenColumn) return null;

            return (
              <Cell
                key={`baserow_${metric}_${orderItem.headSubId}`}
                value={sourceType === 'calculation' ? '-' : getBaseValue(headid, orderItem.headSubId, metric)}
                config={{}}
                isHidden={isHiddenColumn}
              />
            );
          });
        })}
    </tr>
  );
};

BaseRow.propTypes = {
  heads: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  tableResult: PropTypes.object,
  metric: PropTypes.string,
  matrixType: PropTypes.string,
  isCompactMatrix: PropTypes.bool.isRequired
};
