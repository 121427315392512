import { produce } from 'immer';
import { isArray } from 'lodash';

import { dataPrepActionTypes } from '../constants';

const INITIAL_STATE = {
  calculations: [],
  weights: [],
  isLoading: false
};

export const dataPrepReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * @foreign actions
     * LOAD_SURVEY_SUCCESS
     * LOAD_REPORT_SUCCESS
     */
    // case surveyActionTypes.LOAD_SURVEY_SUCCESS:

    /**
     * SAVE_DATAPREP_ELEMENT_X
     */
    case dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_REQUEST:
      return produce(state, draftState => {
        // eslint-disable-next-line
        const { type, elementid } = payload;
        // draftState.loading = true;
      });
    case dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { type, elementid, element, calculationid } = payload;
        // draftState[type].loading = false;
        if (type === 'calculationValue') {
          if (isArray(draftState.calculations[calculationid].values.list))
            draftState.calculations[calculationid].values.list = {};
          draftState.calculations[calculationid].values.list[elementid] = element;
          if (!draftState.calculations[calculationid].values.order.includes(elementid)) {
            draftState.calculations[calculationid].values.order.push(elementid);
          }
        } else {
          if (isArray(draftState[type])) draftState[type] = {};
          draftState[type][elementid] = element;
        }

        // update lastinsertid, if new calculation/ weight
        if (type !== 'calculationValue' && !state[type]?.[elementid]) {
          draftState._lastinsertid = elementid;
        } else {
          draftState._lastinsertid = null;
        }
      });
    case dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_FAILURE:
      return produce(state, draftState => {
        // eslint-disable-next-line
        const { type, elementid, error } = payload;
        // draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    /**
     * DELETE_DATAPREP_ELEMENT_X
     */
    case dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_REQUEST:
      return produce(state, draftState => {
        // eslint-disable-next-line
        const { type, elementid } = payload;
        // draftState.loading = true;
      });
    case dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { type, elementid, calculationid } = payload;

        if (type === 'calculationValue') {
          delete draftState['calculations'][calculationid]['values']['list'][elementid];
          draftState['calculations'][calculationid]['values']['order'] = draftState['calculations'][calculationid][
            'values'
          ]['order'].filter(id => id !== elementid);
        } else {
          delete draftState[type][elementid];
        }
        if (elementid === state._lastinsertid) {
          draftState._lastinsertid = null;
        }
      });
    case dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_FAILURE:
      return produce(state, draftState => {
        // eslint-disable-next-line
        const { type, elementid, error } = payload;
        // draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    case dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_SUCCESS:
      const { dataPrep } = payload;
      // If dataprep has no weights or calculations, the backend returns nothing. In this case we need to set it to an empty object to make the page work.
      !dataPrep ? (state = {}) : (state = dataPrep);
      return state;

    // data-prep
    case dataPrepActionTypes.ADD_DATA_PREP_SUCCESS:
    case dataPrepActionTypes.UPDATE_DATA_PREP_SUCCESS:
      state = payload.dataPrep;
      return state;
    case dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_SUCCESS:
    case dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS:
    case dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_SUCCESS:
      return produce(state, draftState => {
        draftState.weights = payload.dataPrep.weights;
      });
    case dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_SUCCESS:
    case dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_SUCCESS:
    case dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_SUCCESS:
    case dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_SUCCESS:
    case dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_SUCCESS:
    case dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_SUCCESS:
      return produce(state, draftState => {
        draftState.calculations = payload.dataPrep.calculations;
      });

    // data-prep failure
    case dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_FAILURE:
    case dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE:
    case dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_FAILURE:
    case dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_FAILURE:
    case dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_FAILURE:
    case dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_FAILURE:
    case dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_FAILURE:
    case dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_FAILURE:
    case dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_FAILURE:
      console.log(payload.error);
      return produce(state, draftState => {});

    case dataPrepActionTypes.UPLOAD_WEIGHT_CSV_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true;
      });
    case dataPrepActionTypes.UPLOAD_WEIGHT_CSV_SUCCESS:
      state = {
        ...payload.dataPrep,
        isLoading: false
      };
      return state;
    case dataPrepActionTypes.UPLOAD_WEIGHT_CSV_FAILURE:
      console.log(payload.error);
      return produce(state, draftState => {
        draftState.isLoading = false;
      });

    case dataPrepActionTypes.DELETE_WEIGHT_CSV_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true;
      });
    case dataPrepActionTypes.DELETE_WEIGHT_CSV_SUCCESS:
      state = {
        ...payload.dataPrep,
        isLoading: false
      };
      return state;
    case dataPrepActionTypes.DELETE_WEIGHT_CSV_FAILURE:
      console.log(payload.error);
      return produce(state, draftState => {
        draftState.isLoading = false;
      });

    case dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS:
    case dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS:
    case dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS:
      const { calculationId, valueId, condition } = payload;
      return produce(state, draftState => {
        const calculation = draftState.calculations.find(calc => calc.id === calculationId);
        if (calculation) {
          const value = calculation.values.find(value => value.id === valueId);
          if (value) {
            value.condition = condition;
          }
        }
        return draftState;
      });

    default:
      return state;
  }
};
