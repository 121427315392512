import axios from 'axios';
import { store } from 'smv-redux';

// import { userConstants } from 'smv-redux';
import { settingsActionTypes } from '../constants';
import { settingsConstants } from '../constants';

/**
 * Initially loads user information and client/ user settings from backend
 */
const initSettings = () => {
  store.dispatch({
    type: settingsActionTypes.SMOOVE_SETTINGS_INIT_REQUEST
  });

  /**
   * todo: remove this fake data and get the correct data from backend when ready
   * fake SMOOVE_SETTINGS_INIT_SUCCESS payload
   * get /me endpoint user information and dispatch to USERS_DETAILS_SUCCESS
   */

  let userSettings = {
    expertMode: true
  };

  // let test = {
  //   jsonrpc: '2.0',
  //   result: {
  //     user: { username: 'jeremy.kirsten', firstname: 'Jeremy', lastname: 'Kirsten', email: 'j.kirsten@datrics.de' }
  //   }
  // };

  let clientSettings = {
    wizards: {
      list: {
        __forceObject: true
        // 'bdf-impact': {
        //   type: 'impact',
        //   title: { stringid: 'smoove.common.project.types.impact.label', default: 'Impact' },
        //   description: { stringid: 'smoove.common.project.types.impact.description', default: 'Impact' }
        // }
      },
      // order: ['bdf-impact']
      order: []
    }
  };

  store.dispatch({
    type: settingsActionTypes.SMOOVE_SETTINGS_INIT_SUCCESS,
    payload: { user: {}, userSettings, clientSettings }
  });

  /* store.dispatch({
        type: settingsActionTypes.SMOOVE_SETTINGS_INIT_FAILURE,
        payload: { error: err }
      }); */
};

/**
 * Sets the smoove global expert mode
 * @param {boolean} expertMode
 */
const setExpertMode = expertMode => {
  store.dispatch({
    type: settingsActionTypes.SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS,
    payload: { expertMode }
  });
};

/**
 * Sets list style of projects list to `tiles` or `rows`
 *
 * @param {string} style
 */
const setProjectsListStyle = style => {
  store.dispatch({
    type: settingsActionTypes.SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS,
    payload: { style }
  });
};

/**
 * Sets the application locale of the frontend
 * @param {string} locale Locale with unterscore, e.g. en_US
 */
const setAppLocale = locale => {
  store.dispatch({
    type: settingsConstants.APP_SETTINGS_SET_LOCALE_SUCCESS,
    payload: { locale }
  });
};

/**
 * Fetches meta.json from the backend to load code base version and branch.
 */
const checkAppVersion = () => {
  store.dispatch({
    type: settingsConstants.APP_SETTINGS_CHECK_VERSION_REQUEST,
    payload: {}
  });

  axios
    .get(`/meta.json?${Date.now()}`, { baseURL: process.env?.PUBLIC_URL ?? '' })
    .then(({ data }) => {
      const { version, branch } = data;

      store.dispatch({
        type: settingsConstants.APP_SETTINGS_CHECK_VERSION_SUCCESS,
        payload: { version, branch }
      });
    })
    .catch(err => {
      store.dispatch({
        type: settingsConstants.APP_SETTINGS_CHECK_VERSION_FAILURE,
        payload: { error: err }
      });
    });
};

/* Get users exports.
 *
 */
const getUserExports = () => {
  store.dispatch({
    type: settingsActionTypes.USERS_EXPORTS_REQUEST
  });

  return axios
    .get(`/users/me/exports`)
    .then(res => {
      const data = res.data;
      store.dispatch({
        type: settingsActionTypes.USERS_EXPORTS_SUCECSS,
        payload: {
          data: data
        }
      });
      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: settingsActionTypes.USERS_EXPORTS_FAILURE
      });
    });
};

const getSharedExports = (reportId, token) => {
  store.dispatch({
    type: settingsActionTypes.SHARED_EXPORTS_REQUEST
  });

  return axios
    .get(`/shares/reports/${reportId}/exports/${token}`)
    .then(res => {
      const data = res.data;
      store.dispatch({
        type: settingsActionTypes.SHARED_EXPORTS_SUCECSS,
        payload: {
          data: data
        }
      });

      return res;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: settingsActionTypes.SHARED_EXPORTS_FAILURE
      });
    });
};

const toggleUserExportsWindow = () => {
  store.dispatch({
    type: settingsActionTypes.TOGGLE_USERS_EXPORTS_WINDOW_REQUEST
  });
};

const toggleSharedReportExportsWindow = () => {
  store.dispatch({
    type: settingsActionTypes.TOGGLE_SHARED_EXPORTS_WINDOW_REQUEST
  });
};

export const settingsActions = {
  initSettings,
  setExpertMode,
  setProjectsListStyle,
  getUserExports,
  getSharedExports,
  toggleSharedReportExportsWindow,
  toggleUserExportsWindow,

  checkAppVersion,
  setAppLocale
};
