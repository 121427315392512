import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createIntl } from 'react-intl';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';

import { coreReducers } from 'smv-redux';

import de_DE from 'smv-redux/redux-translations/de_DE.json';
import en_US from 'smv-redux/redux-translations/en_US.json';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const createRootReducer = asyncReducers =>
  combineReducers({
    ...coreReducers,
    ...asyncReducers
  });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(createRootReducer(), composeEnhancers(applyMiddleware(...[thunkMiddleware])));

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};
store.persistor = persistStore(store);

store.injectReducers = reducers => {
  reducers.forEach(({ name, fn }) => {
    store.asyncReducers[name] = fn;
  });

  // Flush Persists the pending state to avoid the loss of state as
  // replaceReducer causes a rehydrate
  store.persistor.flush();
  store.replaceReducer(createRootReducer(store.asyncReducers));
  store.persistor.persist();
};

// translations for redux action reducers
const messages = {
  'de-DE': de_DE,
  'en-US': en_US
};

function selectLocale(state) {
  const locale = state.appSettings?.locale ?? 'en-US';
  return locale.replace('_', '-');
}

export let intl;

let currentLocale;
function handleLocaleChange() {
  let previousLocale = currentLocale;
  currentLocale = selectLocale(store.getState());

  if (previousLocale !== currentLocale) {
    intl = createIntl({
      locale: currentLocale,
      messages: messages[currentLocale]
    });
  }
}

store.subscribe(handleLocaleChange);
