import React from 'react';
import produce from 'immer';
import { ResultTableChartRowTransposed, ResultTableChartRowTransposedTotal } from '.';

export const ResultTableChartBodyTransposed = ({
  table,
  tableResult,
  chartConfig,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  let { heads, config } = table;
  if (isCompactMatrix) {
    heads = produce(heads, d => {
      if (isCompactMatrix) {
        table.rows.order.forEach(rowid => {
          if (table.rows.list[rowid].sourceType === 'question') {
            d.order = [rowid, ...d.order];
            d.list[rowid] = table.rows.list[rowid];
          }
        });
      }
    });
  }
  const { showTotal = true } = config;

  return (
    <tbody className={`smv-result-table__body`}>
      {showTotal && !isCompactMatrix && (
        <ResultTableChartRowTransposedTotal
          table={table}
          tableResult={tableResult}
          chartConfig={chartConfig}
          hover={hover}
        />
      )}

      {heads.order.map(headId => {
        const head = heads.list[headId];

        return (
          <ResultTableChartRowTransposed
            key={head.id}
            head={head}
            table={table}
            tableResult={tableResult}
            matrixType={matrixType}
            isCompactMatrix={isCompactMatrix}
            chartConfig={chartConfig}
            hover={hover}
          />
        );
      })}
    </tbody>
  );
};
