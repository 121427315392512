import { ButtonGroup, Col, Input, FormGroup, Row, Button, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export const MediaImageConfig = ({ internalConfig, internalHandler, element }) => {
  const showAsSlideshow = internalConfig?.mediaConfig?.showAsSlideshow ?? false;
  const verticalAlign = internalConfig?.mediaConfig?.verticalAlign ?? 'center';
  const horizontalAlign = internalConfig?.mediaConfig?.horizontalAlign ?? 'center';
  const layout = internalConfig?.mediaConfig?.layout ?? 'row';

  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading>
        <FormattedMessage
          id={'smoove.page.reports.dashboard-element-config.media-settings.headline'}
          defaultMessage={'Media Settings'}
        />
      </ListGroupItemHeading>

      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.reports.dashboard-element-config.media-settings.slideshow.toggle'}
            defaultMessage={'Slideshow'}
          />
        </Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`showAsSlideshow`}
              name={'mediaConfig.showAsSlideshow'}
              checked={showAsSlideshow}
              onChange={internalHandler.toggleCheckboxValue}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* Layout: row / column / grid (tba) */}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.reports.dashboard-element-config.media-settings.layout'}
            defaultMessage={'Layout'}
          />
        </Col>
        <Col md={7}>
          <ButtonGroup size='sm'>
            <Button
              id={'tt_layout_row'}
              name={'mediaConfig.layout'}
              value={'row'}
              disabled={showAsSlideshow}
              onClick={internalHandler.setConfigStringValue}
              active={layout === 'row'}
              outline={layout !== 'row'}
            >
              <i
                className={classNames({
                  'fas': layout === 'row',
                  'fal': layout !== 'row',
                  'fa-columns-3': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_layout_row'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.direction-row`} />
              </UncontrolledTooltip> */}
            </Button>
            <Button
              id={'tt_layout_column'}
              name={'mediaConfig.layout'}
              value={'column'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={layout === 'column'}
              outline={layout !== 'column'}
            >
              <i
                className={classNames({
                  'fas': layout === 'column',
                  'fal': layout !== 'column',
                  'fa-columns-3 fa-rotate-90': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_layout_column'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.direction-column`} />
              </UncontrolledTooltip> */}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {/* Horizontal Align */}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.reports.dashboard-element-config.media-settings.horizontal-align'}
            defaultMessage={'Horizontal Alignment'}
          />
        </Col>
        <Col md={7}>
          <ButtonGroup size='sm'>
            <Button
              id={'tt_horizontalAlign_left'}
              name={'mediaConfig.horizontalAlign'}
              value={'left'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={horizontalAlign === 'left'}
              outline={horizontalAlign !== 'left'}
            >
              <i
                className={classNames({
                  'fas': horizontalAlign === 'left',
                  'fal': horizontalAlign !== 'left',
                  'fa-objects-align-left': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_horizontalAlign_left'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-left`} />
              </UncontrolledTooltip> */}
            </Button>
            <Button
              id={'tt_horizontalAlign_center'}
              name={'mediaConfig.horizontalAlign'}
              value={'center'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={horizontalAlign === 'center'}
              outline={horizontalAlign !== 'center'}
            >
              <i
                className={classNames({
                  'fas': horizontalAlign === 'center',
                  'fal': horizontalAlign !== 'center',
                  'fa-objects-align-center-horizontal': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_horizontalAlign_center'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-center`} />
              </UncontrolledTooltip> */}
            </Button>
            <Button
              id={'tt_horizontalAlign_right'}
              name={'mediaConfig.horizontalAlign'}
              value={'right'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={horizontalAlign === 'right'}
              outline={horizontalAlign !== 'right'}
            >
              <i
                className={classNames({
                  'fas': horizontalAlign === 'right',
                  'fal': horizontalAlign !== 'right',
                  'fa-objects-align-right': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_horizontalAlign_right'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-right`} />
              </UncontrolledTooltip> */}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {/* Vertical Align */}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.reports.dashboard-element-config.media-settings.vertical-align'}
            defaultMessage={'vertical Alignment'}
          />
        </Col>
        <Col md={7}>
          <ButtonGroup size='sm'>
            <Button
              id={'tt_verticalAlign_top'}
              name={'mediaConfig.verticalAlign'}
              value={'top'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={verticalAlign === 'top'}
              outline={verticalAlign !== 'top'}
            >
              <i
                className={classNames({
                  'fas': verticalAlign === 'top',
                  'fal': verticalAlign !== 'top',
                  'fa-objects-align-top': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_verticalAlign_top'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-top`} />
              </UncontrolledTooltip> */}
            </Button>
            <Button
              id={'tt_verticalAlign_center'}
              name={'mediaConfig.verticalAlign'}
              value={'center'}
              disabled={showAsSlideshow}
              active={verticalAlign === 'center'}
              outline={verticalAlign !== 'center'}
              onClick={internalHandler.setConfigStringValue}
              className='pe-none'
            >
              <i
                className={classNames({
                  'fas': verticalAlign === 'center',
                  'fal': verticalAlign !== 'center',
                  'fa-objects-align-center-vertical': true
                  // "pe-none": true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_verticalAlign_center'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-center`} />
              </UncontrolledTooltip> */}
            </Button>
            <Button
              id={'tt_verticalAlign_bottom'}
              name={'mediaConfig.verticalAlign'}
              value={'bottom'}
              onClick={internalHandler.setConfigStringValue}
              disabled={showAsSlideshow}
              active={verticalAlign === 'bottom'}
              outline={verticalAlign !== 'bottom'}
            >
              <i
                className={classNames({
                  'fas': verticalAlign === 'bottom',
                  'fal': verticalAlign !== 'bottom',
                  'fa-objects-align-bottom': true,
                  'pe-none': true
                })}
              />
              {/* <UncontrolledTooltip target={'tt_verticalAlign_bottom'} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`smoove.page.reports.media-items.align-bottom`} />
              </UncontrolledTooltip> */}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
