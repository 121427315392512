import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

import { ChartRouter } from '../dashboard';
import { ExportFileButtonAndModal } from '../misc';
import { chartSettings } from './config';

import './ResultVisual.scss';

export const ResultVisual = ({ table, tableResult, resultTableType, isLoading, handler }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(true);

  /**
   * The dashboard element requires a chart configuration, the table configuration and the table results.
   *
   * Within the `Table` section, all three parts are passed as props, no data fetching is nessecary.
   * The chart configuration
   */

  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const exportOptions = useMemo(() => chartSettings?.[resultTableType]?.[table?.chart?.chartType]?.export ?? {}, [
    resultTableType,
    table?.chart
  ]);

  const isExportable = useMemo(() => Object.keys(exportOptions).length > 0, [exportOptions]);

  return (
    <div className={`result-visual p-4 border mt-4`}>
      <div className={'header-container'}>
        <span className={'pointer'} onClick={toggle}>
          <i
            id={`toggle_result_visual`}
            className={`pointer fas fa-fw fa-chevron-right rotate ${isOpen ? 'rotate-90' : ''}`}
          />
          <UncontrolledTooltip className='tooltip-test' placement='top' target={`toggle_result_visual`} fade={false}>
            {isOpen
              ? intl.formatMessage({ id: 'smoove.common.buttons.hide-chart' })
              : intl.formatMessage({ id: 'smoove.common.buttons.show-chart' })}
          </UncontrolledTooltip>
          <b className='ml-1'>
            {isOpen
              ? intl.formatMessage({ id: 'smoove.common.buttons.hide-chart' })
              : intl.formatMessage({ id: 'smoove.common.buttons.show-chart' })}
          </b>
        </span>
        <span>
          {/**
           * @todo: currently the backend expects a saved table config and does not
           * accept a temporary config. To enable pptx exports for unsaved tables,
           * the backend must be extendet to optionally receive the table config as
           * a payload parameter.
           */}
          {isOpen && isExportable && (
            <ExportFileButtonAndModal
              buttonClassName={'mt-2'}
              type={'chart'}
              exportHandler={handler.export}
              exportOptions={exportOptions}
              iconOnly={true}
              renderAsButton={true}
            />
          )}
        </span>
      </div>
      <Collapse isOpen={isOpen} className=''>
        {isOpen && (
          <>
            {isLoading && (
              <div className={'loading-overlay'}>
                <span className={'fas fa-spinner fa-spin fa-3x'} />
              </div>
            )}
            <div
              className={classNames({
                'chart-container': true,
                'chart-container--loading': isLoading
              })}
            >
              <ChartRouter
                elementid={'tmp-tables-result-visual'}
                chartConfig={table.chart}
                table={table}
                tableResult={tableResult}
                resultTableType={resultTableType}
                handler={handler}
              />
            </div>
          </>
        )}
      </Collapse>
    </div>
  );
};
