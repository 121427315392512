import { useMemo } from 'react';
import classNames from 'classnames';
import { operatorElementIcons } from '../config';

export const FormulaBuilderOperatorElement = ({ element, color, handleRemoveElement }) => {
  const isBracket = useMemo(() => ['(', ')'].includes(element.value), [element.value]);

  return (
    <div
      className={classNames({
        'formula-builder__element': true,
        'formula-builder__element--bracket': isBracket,
        'formula-builder__element--math-operator': !isBracket
      })}
      style={{
        color: isBracket ? color.textColor : '',
        backgroundColor: isBracket ? color.color : '',
        borderColor: color.color
      }}
    >
      <span className='formula-builder__element-label'>{operatorElementIcons[element.value]}</span>
      <div className='formula-builder__element-delete-btn' onClick={() => handleRemoveElement(element.id)}>
        <i className='far fa-trash-alt fa-fw' />
      </div>
    </div>
  );
};
