import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { projectSettingsActions } from 'smv-redux/actions';

import { StructureElementNode } from '.';
import { EmptyNode } from '..';

import './sidebar-structure.scss';

export const Structure = ({ writeable }) => {
  const surveyId = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const queCollapseMap = useSelector(state => state.projectSettings?.[state.survey.id]?.queCollapseMap ?? {});

  const nodeOnClick = useCallback(
    (e, nodeInfo) => {
      e.stopPropagation();
      let waitForUncollapse = false;

      // uncollapse desired element if collapsed
      if ((queCollapseMap?.[nodeInfo.id] ?? true) === false) {
        waitForUncollapse = true;
        projectSettingsActions.setCollapse(surveyId, nodeInfo.id, true);
      }

      // uncollapse parent elements if collapsed
      (nodeInfo?.path ?? []).forEach(parentid => {
        if ((queCollapseMap?.[parentid] ?? true) === false) {
          waitForUncollapse = true;
          projectSettingsActions.setCollapse(surveyId, parentid, true);
        }
      });

      const element = document.querySelector('#_' + nodeInfo.id);
      if (element) {
        const timeout = waitForUncollapse ? 500 : 0;

        // scroll to selected element after delay to count in collapsing animation time
        setTimeout(() => {
          const offset = 200;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }, timeout);

        const classes = ['smv-scroll-indicator'];

        element.classList.add(...classes);
        setTimeout(() => element.classList.remove(...classes), timeout + 250);
      }
    },
    [queCollapseMap, surveyId]
  );

  return (
    <div className='questionaire-sidebar__column-left'>
      <div className='questionaire-sidebar__structure'>
        {questionnaire?.root?.map((elementId, index) => (
          <StructureElementNode
            key={elementId}
            index={index}
            surveyId={surveyId}
            elementId={elementId}
            nodeOnClick={nodeOnClick}
            writeable={writeable}
            questionnaire={questionnaire}
            parentsIds={[]}
          />
        ))}
      </div>
      {writeable && <EmptyNode writeable={writeable} indicator='last' style={{ flexGrow: '1' }} />}
    </div>
  );
};
