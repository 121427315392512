import React from 'react';
import { DropdownItem } from 'reactstrap';

import { useControlledState } from 'src/smoove/helpers';
import { SmvCustomDropdown } from '../../../../misc';
import {
  RowQuestionSettingsButton,
  RowQuestionSettingsModal,
  StaticRowOrHeadFilterButton,
  StaticRowOrHeadFilterModal
} from '../../modals';
import { ResultRowHeaderLoopSelect } from '..';

export const DefaultQuestionMenu = ({ handler, subelements, rowid, row, element, table, questionHas }) => {
  const [SettingsButton, SettingsModal] = useControlledState(RowQuestionSettingsButton, RowQuestionSettingsModal);
  const [StaticFilterButton, StaticFilterModal] = useControlledState(
    StaticRowOrHeadFilterButton,
    StaticRowOrHeadFilterModal
  );

  return (
    <>
      <SmvCustomDropdown>
        {(isParentDropdownOpen, toggleParentDropdown) => (
          <>
            {questionHas.isLooped && (
              <>
                <ResultRowHeaderLoopSelect
                  rowid={rowid}
                  row={row}
                  handler={handler}
                  loopParents={questionHas.loopParents}
                  toggleParentDropdown={toggleParentDropdown}
                  isParentDropdownOpen={isParentDropdownOpen}
                />
                <DropdownItem divider />
              </>
            )}
            <DropdownItem>
              <SettingsButton mode={'edit'} />
            </DropdownItem>
            <DropdownItem>
              <StaticFilterButton />
            </DropdownItem>
            <DropdownItem onClick={() => handler.removeElement(rowid, 'rows')}>
              <div className='smv-custom-dropdown__buttons'>
                <i className='fal fa-trash-alt mr-1' />
                <span>{`remove`}</span>
              </div>
            </DropdownItem>
          </>
        )}
      </SmvCustomDropdown>
      <SettingsModal
        rowid={rowid}
        row={row}
        subelements={subelements}
        element={element}
        table={table}
        handler={handler}
        questionHas={questionHas}
      />
      <StaticFilterModal rowOrHead={row} mode={'rows'} handler={handler} />
    </>
  );
};
