import React from 'react';
import { useDrag } from 'react-dnd';
import { IconProvider } from 'smv-components';

export const SystemVariableItem = ({ systemVariable }) => {
  /* eslint-disable-next-line */
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'sysvar',
    item: {
      id: systemVariable.id,
      type: 'sysvar',
      config: systemVariable
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  });

  let iconType = 'single_vector';
  if (systemVariable.varType === 'multiple') iconType = 'multiple_vector';

  const label = systemVariable?.label ?? systemVariable?.varname ?? 'unnamed element';

  return (
    <div className={`list-group-item-text question-item`} ref={drag}>
      <IconProvider icon={iconType} />
      <span className='ml-2'>{label}</span>
    </div>
  );
};
