import { useSelector } from 'react-redux';

import { useSubelementPropertyTranslation } from 'smv-helpers';

import { FormulaBuilderReferenceElement } from '.';

export const FormulaBuilderReferenceSystemVariableElement = ({
  calculationId,
  calculationMode,
  element,
  tableConfig,
  renderParentLabels,
  handleSaveElement,
  handleRemoveElement,
  dropdownContainerRef
}) => {
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const parent = systemVariables.list[element.value.sourceId];
  const value = calculationMode === 'rows' ? parent.list[element.value.rowSubId] : parent.list[element.value.headSubId];

  let label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  if (renderParentLabels) {
    label = (parent?.label ?? '') + ' - ' + label;
  }

  return (
    <FormulaBuilderReferenceElement
      calculationId={calculationId}
      calculationMode={calculationMode}
      tableConfig={tableConfig}
      element={element}
      label={label}
      handleSaveElement={handleSaveElement}
      handleRemoveElement={handleRemoveElement}
      options={{ metricSelect: true, allowFixation: true }}
      dropdownContainerRef={dropdownContainerRef}
    />
  );
};
