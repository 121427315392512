import React from 'react';
import { Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CreateProjectWidget } from 'smv-components';

export const WizardsList = ({ project, toggle = () => {} }) => {
  const wizards = useSelector(state => state.smooveSettings?.client?.wizards ?? { list: {}, order: [] });

  const intl = useIntl();

  return (
    <>
      <Row>
        {process.env.REACT_APP_WZ_CONCEPT === 'true' && (
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 pb-4'>
            <CreateProjectWidget
              title={intl.formatMessage({
                id: 'smoove.common.project.types.concept.label'
              })}
              description={intl.formatMessage({
                id: 'smoove.common.project.types.concept.description'
              })}
              type={'concept'}
            />
          </div>
        )}
        {wizards?.order?.map(wizardid => {
          const wizard = wizards?.list?.[wizardid];
          if (!wizard) return null;
          return (
            <div key={`wizard-card-${wizardid}`} className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 pb-4'>
              <CreateProjectWidget
                title={intl.formatMessage({
                  id: wizard?.title?.stringid,
                  defaultMessage: wizard?.title?.default
                })}
                description={intl.formatMessage({
                  id: wizard?.description?.stringid,
                  defaultMessage: wizard?.description?.default
                })}
                type={wizard?.type}
              />
            </div>
          );
        })}
        {process.env.REACT_APP_WZ_INDIVIDUAL === 'true' && (
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 pb-4'>
            <CreateProjectWidget
              title={intl.formatMessage({
                id: 'smoove.common.project.types.individual.label'
              })}
              description={intl.formatMessage({
                id: 'smoove.common.project.types.individual.description'
              })}
              type={'individual'}
            />
          </div>
        )}
      </Row>
    </>
  );
};
