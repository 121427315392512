import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { Stone } from 'smv-components';

import { LoginForm } from '../components/Login/LoginForm';

import './Login.scss';

export const Login = ({ history }) => {
  const intl = useIntl();
  const user = useSelector(state => state.authentication);

  return (
    <>
      <Stone type={'D'} color={'blue'} width={'400px'} position={['20%', null, null, 0]} translate={[-290, 0]} />
      <Stone type={'B'} color={'orange'} width={'400px'} position={['30%', 0]} translate={[180, 0]} />
      <Stone
        type={'A'}
        color={'green'}
        width={'500px'}
        position={[null, null, 0, '50%', 'translateX(-50%)']}
        translate={[0, 300]}
      />

      <Helmet>
        <title>{intl.formatMessage({ id: 'core.page.login.headline', defaultMessage: 'Login' })} - smoove</title>
      </Helmet>

      <div className='base-content d-flex flex-fill container-fluid position-relative login-page'>
        <div className={'container-fluid p-0 login-page__container'} style={{ maxWidth: 800 }}>
          {/* Headline */}
          <h2 className='text-uppercase text-smv-red fw-bold'>
            <FormattedMessage id={'core.page.login.headline'} defaultMessage={'Login'} />
          </h2>

          {user.error && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.generic'} />
            </div>
          )}
          {user.accountexpired && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.accountexpired'} />
            </div>
          )}
          {user.sessionexpired && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.sessionexpired'} />
            </div>
          )}

          <LoginForm user={user} history={history} />
        </div>
      </div>
    </>
  );
};
