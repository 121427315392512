export const getQuestionnaireElementTypeSpecificIconString = element => {
  if (element.type !== 'question') return element.type;
  if (!element || !element?.config) return '';

  const { qtype, displayType } = element.config;

  if (qtype === 'single_matrix' && displayType === 'ranking') {
    return 'ranking_matrix';
  }

  return qtype;
};
