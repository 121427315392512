import { useCallback, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, ModalHeader, Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import produce from 'immer';
import { useIntl } from 'react-intl';

import { permissionEnum, useUserHasPermission } from 'smv-helpers';

export const WeightSettingsModal = ({ toggle, isOpen, weight, weightHandler }) => {
  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const intl = useIntl();

  const setWeightConfigProperty = useCallback(
    (property, value) => {
      if (weight) {
        weightHandler.updateWeight(
          weight.id,
          produce(weight, draft => {
            draft.config[property] = value;
          })
        );
      }
    },
    [weight, weightHandler]
  );

  // todo: validate inputs for min/max values
  const [maxWeightIterations, setMaxWeightIterations] = useState(0);
  const [targetWeightAccuracy, setTargetWeightAccuracy] = useState(0);
  const [maxWeightValue, setMaxWeightValue] = useState(0);
  const [minWeightValue, setMinWeightValue] = useState(0);

  const [isMaxWeightValueActive, setIsMaxWeightValueActive] = useState(false);
  const [isMinWeightValueActive, setIsMinWeightValueActive] = useState(false);

  useEffect(() => {
    if (weight && weight.config) {
      setMaxWeightIterations(weight.config.maxWeightIterations);
      setTargetWeightAccuracy(weight.config.targetWeightAccuracy);
      setMaxWeightValue(weight.config.maxWeightValue ?? '');
      setMinWeightValue(weight.config.minWeightValue ?? '');
      if (weight.config.maxWeightValue) {
        setIsMaxWeightValueActive(true);
      }
      if (weight.config.minWeightValue) {
        setIsMinWeightValueActive(true);
      }
    }
  }, [weight]);

  const handleSetMaxWeightValueActive = useCallback(
    checked => {
      setIsMaxWeightValueActive(checked);
      if (checked) {
        setWeightConfigProperty('maxWeightValue', 1);
      } else {
        setWeightConfigProperty('maxWeightValue', null);
      }
    },
    [setWeightConfigProperty]
  );

  const handleSetMinWeightValueActive = useCallback(
    checked => {
      setIsMinWeightValueActive(checked);
      if (checked) {
        setWeightConfigProperty('minWeightValue', 0);
      } else {
        setWeightConfigProperty('minWeightValue', null);
      }
    },
    [setWeightConfigProperty]
  );

  const handleSaveMaxWeightValue = useCallback(
    e => {
      let _val = parseFloat(e.target.value);
      if (_val < 1) {
        _val = 1;
      }
      setWeightConfigProperty('maxWeightValue', _val.toFixed(2));
    },
    [setWeightConfigProperty]
  );

  const handleSaveMinWeightValue = useCallback(
    e => {
      let _val = parseFloat(e.target.value);
      if (_val < 0) {
        _val = 0;
      }
      if (_val > 1) {
        _val = 1;
      }
      setWeightConfigProperty('minWeightValue', _val.toFixed(2));
    },
    [setWeightConfigProperty]
  );

  const handleSaveMaxIterations = useCallback(
    value => {
      let _val = value;
      if (value < 2) {
        _val = 2;
      }
      if (value > 1000) {
        _val = 1000;
      }
      setWeightConfigProperty('maxWeightIterations', _val);
    },
    [setWeightConfigProperty]
  );

  return (
    <Modal toggle={toggle} isOpen={isOpen} size={'md'}>
      <ModalHeader>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-settings' })}</ModalHeader>
      <ModalBody>
        <Row className='mt-2'>
          <Col md={8}>
            <h6 className='mb-0'>{intl.formatMessage({ id: 'smoove.page.data.weight.max-number-of-iterations' })}</h6>
          </Col>
          <Col md={4}>
            <Input
              id={`input_maxWeightIterations`}
              placeholder='maxWeightIterations'
              bsSize='sm'
              type='number'
              min={2}
              max={1000}
              step={1}
              disabled={!userCanEditProject}
              value={maxWeightIterations}
              onChange={e => setMaxWeightIterations(e.target.value)}
              onBlur={e => handleSaveMaxIterations(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <small>{intl.formatMessage({ id: 'smoove.page.data.weight.max-iterations-text' })}</small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={8}>
            <h6 className='mb-0'>{intl.formatMessage({ id: 'smoove.page.data.weight.accuracy' })}</h6>
          </Col>
          <Col md={4}>
            <Input
              id={`input_targetWeightAccuracy`}
              placeholder='targetWeightAccuracy'
              bsSize='sm'
              type='number'
              min={0.0001}
              max={1}
              step={0.0001}
              disabled={!userCanEditProject}
              value={targetWeightAccuracy}
              onChange={e => setTargetWeightAccuracy(e.target.value)}
              onBlur={e => setWeightConfigProperty('targetWeightAccuracy', e.target.value)}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <small>{intl.formatMessage({ id: 'smoove.page.data.weight.accuracy-text' })}</small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={8}>
            <div className='d-flex align-items-center'>
              <h6 className='mb-0'>{intl.formatMessage({ id: 'smoove.page.data.weight.max-weight-value' })}</h6>
              <FormGroup
                switch
                style={{
                  display: ' flex',
                  alignItems: 'center',
                  pddingBottom: '4px',
                  marginLeft: '8px',
                  marginBottom: '4px'
                }}
              >
                <Input
                  type='switch'
                  role='switch'
                  id={`toggleIsMaxWeightValueActive_${weight.id}`}
                  name={`toggleIsMaxWeightValueActive__${weight.id}`}
                  className='pointer'
                  checked={isMaxWeightValueActive}
                  disabled={!userCanEditProject}
                  onChange={e => handleSetMaxWeightValueActive(e.target.checked)}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md={4}>
            <Input
              id={`input_maxWeightValue`}
              placeholder='no limit'
              bsSize='sm'
              type='number'
              min={1}
              // max={1}
              // step={1}
              disabled={!userCanEditProject || !isMaxWeightValueActive}
              value={maxWeightValue}
              onChange={e => setMaxWeightValue(e.target.value)}
              onBlur={handleSaveMaxWeightValue}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <small>{intl.formatMessage({ id: 'smoove.page.data.weight.max-weight-value-tex' })}</small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={8}>
            <div className='d-flex align-items-center'>
              <h6 className='mb-0'>{intl.formatMessage({ id: 'smoove.page.data.weight.min-weight-value' })}</h6>
              <FormGroup
                switch
                style={{
                  display: ' flex',
                  alignItems: 'center',
                  pddingBottom: '4px',
                  marginLeft: '8px',
                  marginBottom: '4px'
                }}
              >
                <Input
                  type='switch'
                  role='switch'
                  id={`toggleIsMinWeightValueActive_${weight.id}`}
                  name={`toggleIsMinWeightValueActive_${weight.id}`}
                  className='pointer'
                  checked={isMinWeightValueActive}
                  disabled={!userCanEditProject}
                  onChange={e => handleSetMinWeightValueActive(e.target.checked)}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md={4}>
            <Input
              id={`input_minWeightValue`}
              placeholder='0'
              bsSize='sm'
              type='number'
              min={0}
              max={1}
              step={0.01}
              disabled={!userCanEditProject || !isMinWeightValueActive}
              value={minWeightValue}
              onChange={e => setMinWeightValue(e.target.value)}
              onBlur={handleSaveMinWeightValue}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <small>{intl.formatMessage({ id: 'smoove.page.data.weight.min-weight-value-tex' })}</small>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
