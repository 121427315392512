import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tree } from '@minoru/react-dnd-treeview';
import { useRouteMatch } from 'react-router-dom';

import { tablesActions } from 'smv-redux/actions';

import { CustomNode, CustomDragPreview, Placeholder } from '.';

import './TableTree.scss';

export const TableTree = ({ handler, tablesHandler, readonly = false, isTableConfigInitialState, clearTable }) => {
  const surveyId = useSelector(state => state.survey.id);
  const tables = useSelector(state => state.survey.tables);
  const folders = useSelector(state => state.survey.tablesFolders);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyId]?.selectedLocale ?? state.survey.locales.main
  );
  const { params } = useRouteMatch();

  const treeData = getTreeData(folders, tables);

  const initialOpenParents = useMemo(() => {
    if (params.tableid) {
      const tableNode = treeData.find(node => node.id === params.tableid);
      if (!tableNode) return [];
      else return getRecursiveParents([], tableNode, treeData);
    }
  }, [params.tableid, treeData]);

  const handleDrop = useCallback(
    (newTree, { dragSourceId, dropTargetId, dragSource, dropTarget, destinationIndex, relativeIndex }) => {
      if (dragSource.type === 'folder') {
        tablesActions
          .updateFolder(surveyId, dragSourceId, { parentId: dropTargetId, position: relativeIndex })
          .then(() => {
            tablesActions.loadFolders(surveyId);
          });
      } else if (dragSource.type === 'table') {
        tablesActions
          .updateTable(surveyId, dragSourceId, { parentId: dropTargetId, position: relativeIndex })
          .then(() => {
            tablesActions.loadFolders(surveyId).then(() => {
              if (params.tableid) {
                tablesActions.loadTableResults(surveyId, tables.list[params.tableid], selectedLocale);
              }
            });
          });
      }
    },
    [params.tableid, surveyId, selectedLocale, tables]
  );

  if (treeData.length === 0) return null;

  return (
    <Tree
      tree={treeData}
      initialOpen={initialOpenParents}
      rootId={folders.rootFolderId}
      render={(node, { isOpen, onToggle, isDropTarget, isDragSource, containerRef }) => {
        return (
          <CustomNode
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            isDropTarget={isDropTarget}
            isDragSource={isDragSource}
            handler={handler}
            tablesHandler={tablesHandler}
            readonly={readonly}
            tables={tables}
            folders={folders}
            containerRef={containerRef}
            isTableConfigInitialState={isTableConfigInitialState}
            clearTable={clearTable}
          />
        );
      }}
      dragPreviewRender={monitorProps => <CustomDragPreview monitorProps={monitorProps} />}
      onDrop={handleDrop}
      classes={{
        container: 'table-tree',
        draggingSource: 'table-tree--dragging-source',
        placeholder: 'table-tree--placeholder-container',
        dropTarget: 'table-tree--drop-target'
      }}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
        if (readonly) return false;

        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      canDrag={() => {
        if (readonly) return false;
        return true;
      }}
      dropTargetOffset={5}
      placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
    />
  );
};

function getTreeData(folders, tables) {
  const tree = [];
  const rootFolder = folders.list[folders.rootFolderId];

  if (!rootFolder) {
    return [];
  }

  tree.push({
    id: rootFolder.id,
    parent: null,
    text: rootFolder.name,
    type: 'folder',
    droppable: true
  });

  function traverseFolder(folder) {
    folder.children.forEach(child => {
      if (child.type === 'folder') {
        const currentFolder = folders.list[child.id];
        if (currentFolder) {
          tree.push({
            id: child.id,
            parent: folder.id,
            text: currentFolder.name ?? 'no name',
            type: 'folder',
            droppable: true
          });

          traverseFolder(currentFolder);
        }
      } else if (child.type === 'table') {
        const table = tables.list[child.id];
        if (table) {
          tree.push({
            id: child.id,
            parent: folder.id,
            text: table.name ?? 'no name',
            type: 'table',
            droppable: false
          });
        }
      }
    });
  }

  traverseFolder(rootFolder);
  return tree;
}

const getRecursiveParents = (path, element, list) => {
  if (!!element?.parent && element.parent !== 'root') {
    const parentNode = list.find(node => node.id === element.parent);
    path.push(element.parent);
    return getRecursiveParents(path, parentNode, list);
  } else {
    return path;
  }
};
