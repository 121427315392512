export const translationActionTypes = {
  GET_ALL_TRANSLATIONS_REQUEST: 'GET_ALL_TRANSLATIONS_REQUEST',
  GET_ALL_TRANSLATIONS_SUCCESS: 'GET_ALL_TRANSLATIONS_SUCCESS',
  GET_ALL_TRANSLATIONS_FAILURE: 'GET_ALL_TRANSLATIONS_FAILURE',

  UPDATE_TRANSLATION_REQUEST: 'UPDATE_TRANSLATION_REQUEST',
  UPDATE_TRANSLATION_SUCCESS: 'UPDATE_TRANSLATION_SUCCESS',
  UPDATE_TRANSLATION_FAILURE: 'UPDATE_TRANSLATION_FAILURE',

  UPDATEMULTIPLE_TRANSLATION_REQUEST: 'UPDATEMULTIPLE_TRANSLATION_REQUEST',
  UPDATEMULTIPLE_TRANSLATION_SUCCESS: 'UPDATEMULTIPLE_TRANSLATION_SUCCESS',
  UPDATEMULTIPLE_TRANSLATION_FAILURE: 'UPDATEMULTIPLE_TRANSLATION_FAILURE'
};
