import { useCallback, useState } from 'react';
import { ConfirmAction } from 'smv-components';
import { surveyActions } from 'smv-redux';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { getSurveyStatusBadge } from 'src/smoove/helpers/getSurveyStatus';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { ReadOnlyAccessIconAndTooltip } from '../misc/ReadOnlyAccessIconAndTooltip';
import { DuplicateSurveyModal } from './SurveyListElementTile';

export const SurveyListElementRow = ({ openSurvey, survey, project }) => {
  const history = useHistory();
  const intl = useIntl();
  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
  const toggleDuplicateModal = useCallback(() => setIsOpenDuplicateModal(prev => !prev), []);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);

  const translationPrefix = 'smoove.page.projects';

  const openSurveySettings = useCallback(() => {
    surveyActions.getSurvey(survey?.id);
    history.push(`/one/projects/${project?.id}/surveys/${survey?.id}/survey`);
  }, [history, survey?.id, project?.id]);

  const handleOpenSurvey = useCallback(
    e => {
      e.stopPropagation();
      openSurvey(project, survey);
    },
    [project, survey, openSurvey]
  );

  return (
    <div key={survey.id} className={'survey-list-element-row'}>
      <div className='survey-list-element-row__left' onClick={handleOpenSurvey}>
        <div className='survey-list-element__title d-flex align-items-center'>
          {!userCanEditProject && (
            <span className='mr-2' style={{ fontSize: '0.75em' }}>
              <ReadOnlyAccessIconAndTooltip />
            </span>
          )}
          {survey.title}
        </div>
        <div>
          {getSurveyStatusBadge(survey.status)}
          <small className='ml-2'>
            <FormattedMessage id={`${translationPrefix}.surveys.created-on`} />:{' '}
            <FormattedDate day='2-digit' year='numeric' month='long' value={survey.createdAt} />
          </small>
        </div>
      </div>
      <div className='survey-list-element-row__right'>
        <div>
          {/* survey settings */}
          <i className='fal fa-cog p-1' onClick={openSurveySettings} id={`tt_to_surveysettings_${survey.id}`} />
          <UncontrolledTooltip target={`tt_to_surveysettings_${survey.id}`} delay={{ hide: 100 }} autohide={false}>
            <FormattedMessage id={`${translationPrefix}.survey-settings`} defaultMessage={`Survey settings`} />
          </UncontrolledTooltip>
          {/* duplicate survey */}
          {userCanEditProject && (
            <>
              <i
                className='fal fa-copy p-1'
                onClick={() => {
                  setIsOpenDuplicateModal(true);
                }}
                id={`tt_to_surveyduplicate_${survey.id}`}
              />
              <UncontrolledTooltip target={`tt_to_surveyduplicate_${survey.id}`} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage
                  id='smoove.page.projects.surveys.modal.button-open.duplicate'
                  defaultMessage='Duplicate Survey'
                />
              </UncontrolledTooltip>
            </>
          )}
          {/* delete survey */}
          {userCanEditProject && (
            <>
              <ConfirmAction
                size={'lg'}
                title={intl.formatMessage({ id: `${translationPrefix}.surveys.delete-survey` })}
                body={
                  <div>
                    {intl.formatMessage({ id: `${translationPrefix}.surveys.delete-survey-confirmation-text` })}
                  </div>
                }
                btnText={'Delete'}
                callback={() => surveyActions.removeSurvey(project.id, survey.id)}
              >
                {confirm => (
                  <i
                    className='fal fa-trash-alt pointer text-danger p-1'
                    id={`tt_delete_survey_${survey.id}`}
                    onClick={confirm}
                  />
                )}
              </ConfirmAction>
              <UncontrolledTooltip target={`tt_delete_survey_${survey.id}`} delay={{ hide: 100 }} autohide={false}>
                <FormattedMessage id={`${translationPrefix}.surveys.delete-survey`} />
              </UncontrolledTooltip>
            </>
          )}
        </div>
      </div>

      {/* <span className={'badge rounded-pill ' + surveyStatusPillClass[survey.status]}>
          <FormattedMessage id={surveyStatus[survey.status]} />
        </span> */}
      {userCanEditProject && (
        <DuplicateSurveyModal
          isOpen={isOpenDuplicateModal}
          toggle={toggleDuplicateModal}
          project={project}
          survey={survey}
        />
      )}
    </div>
  );
};

//   <td onClick={() => openSurvey(project, survey)} className='pointer'>
//     {survey.title}
//   </td>
//   <td onClick={() => openSurvey(project, survey)} className='pointer'>
//     {getSurveyStatusBadge(survey.status)}
//     {/* <span className={'badge rounded-pill ' + surveyStatusPillClass[survey.status]}>
//     <FormattedMessage id={surveyStatus[survey.status]} />
//   </span> */}
//   </td>
//   <td onClick={() => openSurvey(project, survey)} className='pointer'>
//     {survey.description}
//   </td>
//   <td>
//     <ConfirmAction
//       size={'lg'}
//       title={'Delete survey'}
//       body={<div>{'Are you sure you want to delete this survey?'}</div>}
//       btnText={'Delete'}
//       callback={() => surveyActions.removeSurvey(project.id, survey.id)}
//     >
//       {confirm => <i className='far fa-trash-alt pointer text-smv p-1' onClick={confirm} />}
//     </ConfirmAction>
//   </td>
// </tr >´
