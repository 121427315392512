import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ConfirmAction } from 'smv-components';
import { stripHtml } from 'smv-helpers';

import { questionnaireActions } from 'src/smoove/redux/actions';
import { ChoiceDisplayCondition } from '../../config-modals/QuestionConfigModal/configtab/choices';

import './ChoiceConfigContainer.scss';

export const ChoiceConfigContainer = ({ question, choice, qtype, writeable }) => {
  const config = choice.config;

  const surveyid = useSelector(state => state.survey.id);
  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const choiceLabel = useSelector(
    state => state.survey?.translations?.[choice.config.translations.label]?.[locale] ?? ''
  );

  const updateChoiceConfig = useCallback(
    (key, value) => {
      if (!writeable) return;

      let updatedChoice = {};

      switch (key) {
        case 'value':
          if (choice.value !== value) {
            updatedChoice = { value: Math.abs(value) };
          }
          break;
        case 'randomize':
          if (choice.config.randomize !== value) {
            updatedChoice = { config: { randomize: value } };
          }
          break;
        case 'exclusive':
          if (choice.config.exclusive !== value) {
            updatedChoice = { config: { exclusive: value } };
          }
          break;
        default:
          break;
      }

      questionnaireActions.updateChoice(surveyid, question.id, choice.id, updatedChoice);
    },
    [question.id, choice, surveyid, writeable]
  );

  const deleteChoice = useCallback(() => {
    if (!writeable) return;
    questionnaireActions.removeChoice(surveyid, question.id, choice.id);
  }, [surveyid, question.id, choice.id, writeable]);

  const handleUpdateChoiceOpenEnd = useCallback(() => {
    const openEndDefaults = {
      input: 'text',
      maxInputLength: 255,
      fieldWidth: 100,
      fieldWidthUnit: '%'
    };
    const isOpenEnd = choice?.config?.openEnd ?? false;
    if (isOpenEnd) {
      questionnaireActions.deleteChoiceOpenEnd(surveyid, question.id, choice.id);
    } else {
      questionnaireActions.updateChoiceOpenEnd(surveyid, question.id, choice.id, openEndDefaults);
    }
  }, [choice, surveyid, question.id]);

  return (
    <div className='choice-config-container' id={`choice_${choice.id}_container`}>
      {/* random */}
      <>
        <i
          className={`${writeable ? 'pointer' : ''} ${config?.randomize ? 'fas' : 'fal'}  fa-fw fa-random ml-1`}
          onClick={() => updateChoiceConfig('randomize', config?.randomize ? false : true)}
          id={`random_${choice.id}`}
        ></i>
        <UncontrolledTooltip
          placement='top'
          target={`random_${choice.id}`}
          container={`choice_${choice.id}_container`}
          boundariesElement={`choice_${choice.id}_container`}
        >
          <FormattedMessage
            id={`smoove.questionnaire.modal.config-container.${config?.randomize ? 'randomized' : 'not-randomized'}`}
          />
        </UncontrolledTooltip>
      </>
      {/* open responses */}
      {['multiple_vector', 'single_vector', 'single_matrix'].includes(qtype) && (
        <>
          <i
            className={classNames({
              'fa-comment-lines ml-1': true,
              'fas': choice.config?.openEnd,
              'fal': !choice.config?.openEnd,
              'pointer': writeable
            })}
            id={`openend_${choice.id}`}
            onClick={handleUpdateChoiceOpenEnd}
          ></i>
          <UncontrolledTooltip
            placement='top'
            target={`openend_${choice.id}`}
            container={`choice_${choice.id}_container`}
            boundariesElement={`choice_${choice.id}_container`}
          >
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-container.${config.openEnd ? 'open-end' : 'not-open-end'}`}
            />
          </UncontrolledTooltip>
        </>
      )}
      {/* exclusive */}
      {qtype === 'multiple_vector' && (
        <>
          <i
            className={`${writeable ? 'pointer' : ''} ${
              config.exclusive ? 'fas' : 'fal'
            } fa-fw fa-exclamation-square ml-1`}
            onClick={() => updateChoiceConfig('exclusive', !choice?.config?.exclusive)}
            id={`exclusive_${choice.id}`}
          ></i>
          <UncontrolledTooltip
            placement='top'
            target={`exclusive_${choice.id}`}
            container={`choice_${choice.id}_container`}
            boundariesElement={`choice_${choice.id}_container`}
          >
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-container.${config.exclusive ? 'exclusive' : 'not-exclusive'}`}
            />
          </UncontrolledTooltip>
        </>
      )}
      {/* hide condition */}
      <ChoiceDisplayCondition
        className={'pointer fa-fw ml-1'}
        question={question}
        choice={choice}
        writeable={writeable}
        containerId={`choice_${choice.id}_container`}
      />
      {/* delete */}
      {writeable && (
        <div className='d-flex align-items-center'>
          <ConfirmAction
            title={
              <FormattedMessage
                id={'smoove.questionnaire.modal.config-container.delete-item-confirm-head'}
                defaultMessage={'Delete this element?'}
                values={{ element: <span>"{stripHtml(choiceLabel)}"</span> }}
              />
            }
            size={'md'}
            body={
              <div>
                <FormattedMessage
                  id={'smoove.questionnaire.modal.config-container.delete-item-confirm-text'}
                  defaultMessage={'Are you sure you want to delete this element?'}
                  values={{ element: <b>"{stripHtml(choiceLabel)}"</b> }}
                />
              </div>
            }
            callback={deleteChoice}
          >
            {confirm => (
              <>
                <i
                  id={`trash_${choice.id}`}
                  className='far fa-fw fa-trash-alt pointer ml-1 icon-smv-font-light'
                  onClick={confirm}
                />
                <UncontrolledTooltip
                  placement='top'
                  target={`trash_${choice.id}`}
                  container={`choice_${choice.id}_container`}
                  boundariesElement={`choice_${choice.id}_container`}
                >
                  <FormattedMessage id={'smoove.misc.delete'} />
                </UncontrolledTooltip>
              </>
            )}
          </ConfirmAction>
        </div>
      )}
    </div>
  );
};
