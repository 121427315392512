import React from 'react';
import { useSelector } from 'react-redux';

import { ApplyToValues } from '.';

export const ApplyToTypeSystemVariable = ({ conditionalFormat, main, dimensionKey, handleApplyToSelect }) => {
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const { sourceId } = main;

  const systemVariable = systemVariables.list[sourceId];
  if (!systemVariable) return null;

  const label = systemVariable.label;

  return (
    <ApplyToValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      values={{ order: systemVariable.order, list: systemVariable.list }}
      handleApplyToSelect={handleApplyToSelect}
    />
  );
};
