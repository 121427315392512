import React from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { generatePath, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { IconProvider } from 'smv-components';

import { ElementSwitch, ElementTitle, ElementCollapseIcon } from '.';
import { ConfirmDelete } from './ConfirmDelete';
import './Container.scss';

export const Container = ({ container, isCollapsed, handler, writeable, questionnaire, parentsIds, surveyId }) => {
  const { path } = useRouteMatch();
  const routeParams = useParams();

  const level = (container?.path?.length ?? 0) + 1;
  const smooveLevelClass = level > 0 ? ` smoove-level-${level}` : '';

  const containerHas = {
    // screenout: Boolean(Object.keys(container.config?.screenout ?? {}).length),
    filter: Boolean((container.config?.filter ?? {})?.rootElementId),
    // jump: Boolean(Object.keys(container.config?.jump ?? {}).length),
    randomize: container.config?.randomize ?? false,
    loop: Boolean(container.config?.loop?.source ?? false)
  };

  const containerConflicts = questionnaire?.conflicts?.[container?.id];
  let conflictDefaultMessage = 'Container has conflicts';
  let conflictMessageId = 'smoove.questionnaire.container.conflicts';

  if (containerConflicts) {
    switch (true) {
      case 'loops' in containerConflicts: {
        conflictDefaultMessage = 'Container should be placed below the question used in loop';
        conflictMessageId = 'smoove.questionnaire.container.conflicts.loop';
        break;
      }
      case 'filter' in containerConflicts: {
        conflictDefaultMessage = 'Container should be placed below the question used in filter';
        conflictMessageId = 'smoove.questionnaire.container.conflicts.filter';
        break;
      }
      default: {
        conflictDefaultMessage = 'Container has conflicts';
        conflictMessageId = 'smoove.questionnaire.container.conflicts';
      }
    }
  }

  return (
    <div id={'_' + container.id} className={'smoove-container-container' + smooveLevelClass}>
      <div className={`smoove-container-container__container ${smooveLevelClass}`}>
        <ElementCollapseIcon toggleCollapse={handler.toggleCollapse} isCollapsed={isCollapsed} />
        <ElementTitle element={container} writeable={writeable} className='container__title' />
        <div className='container-icons'>
          {containerHas.randomize && (
            <span id={`icon_randomize_${container.id}`} className={'ml-2'}>
              <Link to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: container.id })}>
                <IconProvider icon='randomize' filled={true} clickable={false} />
                <UncontrolledTooltip
                  target={`icon_randomize_${container.id}`}
                  delay={{ show: 500, hide: 200 }}
                  autohide={false}
                >
                  <FormattedMessage id={`smoove.questionnaire.randomize`} defaultMessage={'Randomized Order'} />
                </UncontrolledTooltip>
              </Link>
            </span>
          )}
          {containerHas.loop && (
            <span id={`icon_loop_${container.id}`} className={'ml-2'}>
              <Link to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: container.id })}>
                <IconProvider icon='loop' filled={true} clickable={false} />
                <UncontrolledTooltip
                  target={`icon_loop_${container.id}`}
                  delay={{ show: 500, hide: 200 }}
                  autohide={false}
                >
                  <FormattedMessage id={`smoove.questionnaire.loop`} defaultMessage={'Loop'} />
                </UncontrolledTooltip>
              </Link>
            </span>
          )}
          {containerHas.filter && (
            <span id={`icon_filter_${container.id}`} className={'ml-2'}>
              <Link
                to={generatePath(`${path}/:elementid/:section?`, {
                  ...routeParams,
                  elementid: container.id,
                  section: 'filter'
                })}
              >
                <IconProvider icon='filter' filled={true} clickable={false} />
                <UncontrolledTooltip
                  target={`icon_filter_${container.id}`}
                  delay={{ show: 500, hide: 200 }}
                  autohide={false}
                >
                  <FormattedMessage id={`smoove.questionnaire.filter`} defaultMessage={'Filter'} />
                </UncontrolledTooltip>
              </Link>
            </span>
          )}
        </div>

        {/* conflict icon */}
        {containerConflicts && (
          <>
            <Link
              to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: container.id })}
              id={`btn_conflict_${container.id}`}
            >
              <i className='far fa-warning p-1 text-warning' />
              <span className='text-warning mr-2'>Conflict</span>
            </Link>
            <UncontrolledTooltip target={`btn_conflict_${container.id}`} delay={{ show: 500, hide: 200 }}>
              <FormattedMessage id={conflictMessageId} defaultMessage={conflictDefaultMessage} />
            </UncontrolledTooltip>
          </>
        )}

        {/* config icons */}
        <div className='d-flex'>
          <Link to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: container.id })}>
            <i id={`btn_edit_${container.id}`} className='far fa-edit icon-smv-blue p-1' />
          </Link>
          <UncontrolledTooltip target={`btn_edit_${container.id}`} delay={{ show: 500, hide: 200 }}>
            <FormattedMessage
              id={`smoove.common.buttons.edit-container`}
              defaultMessage={'configure container element'}
            />
          </UncontrolledTooltip>

          {/* copy icon */}
          {writeable && (
            <>
              <i
                id={`btn_copy_${container.id}`}
                className='far fa-clone icon-smv-blue p-1'
                onClick={handler.duplicateQuestionnaireElement}
              />
              <UncontrolledTooltip target={`btn_copy_${container.id}`} delay={{ show: 500, hide: 200 }}>
                <FormattedMessage id={`smoove.common.buttons.copy-container`} defaultMessage={'copy container'} />
              </UncontrolledTooltip>
            </>
          )}

          {writeable && <ConfirmDelete element={container} />}
        </div>
      </div>

      <Collapse isOpen={isCollapsed} timeout={600}>
        <div className={`smoove-container-container__collapse level-${level}`}>
          {container?.children?.map((id, index) => (
            <ElementSwitch
              key={id}
              index={index}
              elementid={id}
              surveyId={surveyId}
              writeable={writeable}
              questionnaire={questionnaire}
              parentsIds={[...parentsIds, container.parentId]}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

Container.propTypes = {
  container: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  handler: PropTypes.object.isRequired
};
