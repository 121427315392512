import { useSelector } from 'react-redux';

import { useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import { getMergedChoices } from '../../helper';

import { FormulaBuilderReferenceElement } from '.';

export const FormulaBuilderReferenceQuestionElement = ({
  calculationId,
  calculationMode,
  element,
  tableConfig,
  renderParentLabels,
  handleSaveElement,
  handleRemoveElement,
  dropdownContainerRef
}) => {
  const questionVariables = useSelector(s => s.survey.questionnaire.list);
  const questionVariable = questionVariables[element.value.sourceId];
  const choices = getMergedChoices(questionVariable, questionVariable?.config?.choices, questionVariables);
  const scales = questionVariable?.config?.scales ?? { order: [], list: {} };

  const { isCompactMatrix, isDetailedMatrix } = useMatrixType(tableConfig);

  /** CHOICE LABEL */
  const choiceItem = choices.list[calculationMode === 'rows' ? element.value.rowSubId : element.value.headSubId];
  const choiceLabel = useSubelementPropertyTranslation(
    choiceItem,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  /** SCALE LABEL */
  let scaleItem = null;
  if (isCompactMatrix) {
    scaleItem = scales.list[calculationMode === 'rows' ? element.value.rowSubId : element.value.headSubId];
  } else if (isDetailedMatrix) {
    scaleItem = scales.list[element.value.rowSubSubId];
  }
  const scaleLabel = useSubelementPropertyTranslation(
    scaleItem,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  const labelParts = [];
  if (renderParentLabels) labelParts.push(questionVariable?.title ?? '');
  if (choiceLabel) labelParts.push(choiceLabel);
  if (scaleLabel) labelParts.push(scaleLabel);
  const label = labelParts.join(' - ');

  return (
    <FormulaBuilderReferenceElement
      calculationId={calculationId}
      calculationMode={calculationMode}
      tableConfig={tableConfig}
      element={element}
      label={label}
      handleSaveElement={handleSaveElement}
      handleRemoveElement={handleRemoveElement}
      options={{ metricSelect: true, allowFixation: true }}
      dropdownContainerRef={dropdownContainerRef}
    />
  );
};
