import { projectsReducer } from './projectsReducer';
import { projectReducerPersisted } from './projectReducer';
import { surveyReducer } from './surveyReducer';
import { projectSettingsReducer } from './projectSettingsReducer';
import local from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { clientsReducerPersisted } from './clientsReducer';

import { dataPrepReducer } from './dataPrepReducer';
import { tablesReducer, tablesFoldersReducer } from './tablesReducer';
import { chartLayersReducer } from './chartLayersReducer';
import { reportsReducer } from './reportsReducer';

import { settingsReducer } from './settingsReducer';

import { settings } from './settingsAppReducer';
import { routes } from './routesReducer';
import { authentication } from './authenticationReducer';
import { alert } from './alertReducer';
import { profile } from './profileReducer';

export { settingsReducerUser } from './settingsReducer_user';
export { settingsReducerClient } from './settingsReducer_client';

export { dataPrepReducer, tablesReducer, reportsReducer, tablesFoldersReducer };

export { project_sharedReportReducer } from './project_sharedReportReducer';

const persistConfig = {
  key: 'survey',
  storage: local,
  blacklist: ['error', 'loading', 'fetching', 'reports', 'tables', 'chartLayers', 'media', 'questionnaire']
};
/**
 * Combines the general survey state reducer with sub-reducers for
 * single parts, e.g. data, tables, reports, etc.
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {function} combined reducer
 */
const combinedSurveyReducer = (state, action) => {
  const survey = surveyReducer(state, action);
  return {
    ...survey,
    tables: tablesReducer(survey.tables, action),
    tablesFolders: tablesFoldersReducer(survey.tablesFolders, action),
    chartLayers: chartLayersReducer(survey.chartLayers, action),
    reports: reportsReducer(survey.reports, action),
    data: dataPrepReducer(survey.data, action)
  };
};

const surveyReducerPersisted = persistReducer(persistConfig, combinedSurveyReducer);

export const reducers = [
  { name: 'projects', fn: projectsReducer },
  { name: 'clients', fn: clientsReducerPersisted },
  { name: 'project', fn: projectReducerPersisted },
  { name: 'survey', fn: surveyReducerPersisted },
  { name: 'projectSettings', fn: projectSettingsReducer },
  { name: 'smooveSettings', fn: settingsReducer }
];

// import { registration } from './registration.reducer';
// import { users } from './users.reducer';

export const coreReducers = {
  appSettings: settings,
  routes,
  authentication,
  profile,
  alert
};
