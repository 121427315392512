import { FormulaBuilderOperatorElement, FormulaBuilderReferenceElementSwitch, FormulaBuilderValueElement } from '.';

export const FormulaBuilderCalculationElementSwitch = ({
  calculationId,
  calculationMode,
  tableConfig,
  element,
  handleSaveElement,
  handleRemoveElement,
  color = { color: 'inherit', textColor: 'inherit' },
  renderParentLabels = true,
  dropdownContainerRef
}) => {
  switch (element.type) {
    case 'operator':
      return (
        <FormulaBuilderOperatorElement
          element={element}
          color={color}
          handleSaveElement={handleSaveElement}
          handleRemoveElement={handleRemoveElement}
        />
      );
    case 'value':
      return (
        <FormulaBuilderValueElement
          element={element}
          handleSaveElement={handleSaveElement}
          handleRemoveElement={handleRemoveElement}
        />
      );
    case 'reference':
      return (
        <FormulaBuilderReferenceElementSwitch
          calculationId={calculationId}
          calculationMode={calculationMode}
          tableConfig={tableConfig}
          element={element}
          handleSaveElement={handleSaveElement}
          handleRemoveElement={handleRemoveElement}
          renderParentLabels={renderParentLabels}
          dropdownContainerRef={dropdownContainerRef}
        />
      );

    default:
      return null;
  }
};
