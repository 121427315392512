import React from 'react';
import PropTypes from 'prop-types';

import { QuestionText, VectorChoices, MatrixScales } from '../components';

export const SingleMatrix = ({ question, writeable }) => {
  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <VectorChoices question={question} writeable={writeable} />
      {question.config.displayType === 'matrix' && <MatrixScales question={question} writeable={writeable} />}
    </>
  );
};

SingleMatrix.propTypes = {
  question: PropTypes.shape({
    config: PropTypes.shape({
      qtype: PropTypes.string.isRequired,
      choices: PropTypes.shape({
        list: PropTypes.object,
        order: PropTypes.array
      }),
      scale: PropTypes.shape({
        list: PropTypes.object,
        order: PropTypes.array
      })
    }).isRequired
  }).isRequired,
  writeable: PropTypes.bool.isRequired
};
