import React from 'react';
import { useSelector } from 'react-redux';

import { getMergedChoices } from 'src/smoove/components/misc/helper';

import { ReferenceValues } from '.';

export const ReferenceTypeQuestion = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleReferenceSelect
}) => {
  const questionVariables = useSelector(s => s.survey.questionnaire.list);
  const { sourceId } = main;

  const questionElement = questionVariables?.[sourceId] ?? {};
  if (!questionElement) return null;

  const label = questionElement.title;

  let values = getMergedChoices(questionElement, questionElement?.config?.choices, questionVariables);
  let scales = null;
  if (dimensionKey === 'rows' && matrixType === 'compact') {
    values = questionElement?.config?.scales;
  } else if (dimensionKey === 'rows' && matrixType === 'detail') {
    scales = questionElement?.config?.scales;
  }

  return (
    <ReferenceValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      matrixType={matrixType}
      values={values}
      scales={scales}
      handleReferenceSelect={handleReferenceSelect}
    />
  );
};
