import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';

import { ResultErrorWarning } from '../../misc';
import { TablePathBreadcrumbs } from '.';

export const ResultTableHeading = ({ table, isLoading = false, isError = false, isConfigChanged = false }) => {
  const intl = useIntl();
  const tableName = table.name;
  const isStaticFiltersActive = !!table?.filters && !!table.filters?.rootElementId;

  return (
    <>
      {isError && <ResultErrorWarning />}
      <h5 className={`tables-page__headline ${isConfigChanged ? 'tables-page__headline--unsaved-changes' : ''}`}>
        {tableName !== '' ? (
          tableName
        ) : (
          <i>
            <FormattedMessage id={'smoove.page.tables.new-table'} defaultMessage={'New Table'} />
          </i>
        )}
        {isConfigChanged && <span className='tables-page__headline-unsaved-changes-indicator'>*</span>}
        {isLoading && <span className={'fas fa-spinner fa-spin ml-2'}></span>}
        {isStaticFiltersActive && (
          <span className='ml-1'>
            <i className='far fa-filter static-filter-warning' id={'static-filter-warning'}></i>
            <UncontrolledTooltip target={'static-filter-warning'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.table-config.static-filters.this-table-is-filtered-by-static-filters'
              })}
            </UncontrolledTooltip>
          </span>
        )}
      </h5>
      <TablePathBreadcrumbs table={table} />
    </>
  );
};
