import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { translationsActions } from 'smv-redux';

export const ConfirmCopyTranslationButton = ({ toggle }) => {
  const intl = useIntl();

  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i className={'fal fa-clone pointer icon-smv-blue mr-1'} />
      <span>{intl.formatMessage({ id: `smoove.questionnaire.translation.modal.copy-subelement.button-label` })}</span>
    </div>
  );
};

export const ConfirmCopyTranslationModal = ({
  isOpen,
  toggle,
  locale1,
  locale2,
  subelementType = 'choices',
  question
}) => {
  const translations = useSelector(state => state.survey.translations);
  const surveyid = useSelector(state => state.survey.id);

  const intl = useIntl();

  const handleCopyChoicesTranslation = useCallback(() => {
    question?.config?.[subelementType]?.order?.forEach(subelementId => {
      const subelement = question.config?.[subelementType]?.list?.[subelementId];
      const subelementTranslationId = subelement.config.translations?.label;
      const value = translations?.[subelementTranslationId]?.[locale1];

      translationsActions.updateTranslation(surveyid, subelementTranslationId, {
        [locale2]: value
      });
    });
  }, [surveyid, translations, question?.config, subelementType, locale1, locale2]);

  const callback = useCallback(() => {
    handleCopyChoicesTranslation();
    toggle();
  }, [handleCopyChoicesTranslation, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={'body'} size={'md'}>
      <ModalHeader>
        {intl.formatMessage({ id: `smoove.questionnaire.translation.modal.copy-subelement.modal-title` })}
      </ModalHeader>
      <ModalBody>
        <div className='text-center my-2' style={{ fontSize: '1.5rem' }}>
          <b>{locale1}</b>
          <i className='far fa-arrow-right mx-2'></i>
          <b>{locale2}</b>
        </div>
        <div>
          {intl.formatMessage(
            { id: `smoove.questionnaire.translation.modal.copy-subelement.modal-text` },
            { locale1: locale1, locale2: locale2 }
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          {intl.formatMessage({ id: `smoove.common.buttons.cancel` })}
        </Button>
        <Button color={'primary'} onClick={callback}>
          {intl.formatMessage({ id: 'smoove.questionnaire.translation.modal.copy-subelement.modal-confirm' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
