import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { SmvCustomDropdown } from '../../../../misc';
import { DropdownItemColorPicker } from './components/DropdownItemColorPicker';

export const SingleVectorChoiceMenu = ({
  handler,
  table,
  rowid,
  choiceid,
  scaleid,
  isHidden,
  isExcluded,
  sortationPinned = null,
  subelements
}) => {
  const intl = useIntl();

  /** @todo: the hide/exclude properties should only consider the configured state, not hidden by low base/ frequency */
  return (
    <SmvCustomDropdown>
      {/**
       * VISABILITY
       */}
      <DropdownItem header>
        <FormattedMessage id={'smoove.page.survey.tables.options.visibility.label'} defaultMessage={'Visibility'} />
      </DropdownItem>
      <DropdownItem
        className={classNames({ active: !isHidden && !isExcluded })}
        disabled={!isHidden && !isExcluded}
        onClick={() => handler.toggleSubelementVisibility('rows', null, rowid, choiceid, scaleid)}
      >
        <div className='smv-custom-dropdown__buttons'>
          <i className={`fal fa-eye mr-1`} />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.show-subelement.label'}
              defaultMessage={'show element'}
            />
          </span>
        </div>
      </DropdownItem>
      <DropdownItem
        className={classNames({ active: isHidden })}
        disabled={isHidden}
        onClick={() => handler.toggleSubelementVisibility('rows', 'hidden', rowid, choiceid, scaleid)}
      >
        <div className={'smv-custom-dropdown__buttons'}>
          <i className={`fal fa-eye-slash mr-1`} />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.hide-subelement.label'}
              defaultMessage={'hide element'}
            />
          </span>
        </div>
      </DropdownItem>
      <DropdownItem onClick={() => handler.toggleShowAll('rows', rowid, choiceid, scaleid, subelements)}>
        <div className='smv-custom-dropdown__buttons'>
          <i className={`fal fa-eye mr-1`} />
          <span>
            {intl.formatMessage({ id: 'smoove.page.survey.tables.options.show-all.label', defaultMessage: 'show all' })}
          </span>
        </div>
      </DropdownItem>
      <DropdownItem onClick={() => handler.toggleHideAllOthers(rowid, choiceid, scaleid, subelements)}>
        <div className='smv-custom-dropdown__buttons'>
          <i className={`fal fa-eye-slash mr-1`} />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.hide-other-subelements.label'}
              defaultMessage={'hide all others'}
            />
          </span>
        </div>
      </DropdownItem>
      <DropdownItem
        className={classNames({ active: isExcluded })}
        disabled={isExcluded}
        onClick={() => handler.toggleSubelementVisibility('rows', 'excluded', rowid, choiceid, scaleid)}
      >
        <div className='smv-custom-dropdown__buttons'>
          <i className={`fal fa-empty-set mr-1`} />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.exclude-subelement.label'}
              defaultMessage={'exclude element'}
            />
          </span>
        </div>
      </DropdownItem>

      {/**
       * COLOR SELECTION
       */}
      <DropdownItem header>
        <FormattedMessage id={'smoove.page.survey.tables.options.color.label'} defaultMessage={'Color Selection'} />
      </DropdownItem>
      <DropdownItemColorPicker
        handler={handler}
        chartConfig={table.chart}
        rowId={rowid}
        rowSubId={choiceid}
        rowSubSubId={scaleid}
        // headId={}
        // headSubId={}
      />

      {/**
       * SORTATION
       */}
      <DropdownItem header>
        <FormattedMessage id={'smoove.page.survey.tables.options.sortation.label'} defaultMessage={'Sortation'} />
      </DropdownItem>
      <DropdownItem
        className={classNames({ active: sortationPinned === 'top' })}
        onClick={() => handler.sortChangeElementPinned('top', rowid, choiceid, scaleid)}
      >
        <div className='smv-custom-dropdown__buttons'>
          <i className='fal fa-arrow-to-top mr-1' />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.sortation.pin-to-top'}
              defaultMessage={'pin to top'}
            />
          </span>
        </div>
      </DropdownItem>
      <DropdownItem
        className={classNames({ active: sortationPinned === 'bot' })}
        onClick={() => handler.sortChangeElementPinned('bot', rowid, choiceid, scaleid)}
      >
        <div className='smv-custom-dropdown__buttons'>
          <i className='fal fa-arrow-to-bottom mr-1' />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.sortation.pin-to-bottom'}
              defaultMessage={'pin to bottom'}
            />
          </span>
        </div>
      </DropdownItem>
    </SmvCustomDropdown>
  );
};
