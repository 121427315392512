import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ResultTableChartQuestionSelectionDetails = ({ row, questionHas, element }) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  let questionSelectionDetails = [];
  if (questionHas.isLooped) {
    const loopSelections = questionHas.loopParents.map(loopParent => {
      return (
        <LoopSelectionInfo
          key={`loopIteration_${row.id}_${loopParent.config.loop.source}`}
          loopSourceElement={list[loopParent.config.loop.source]}
          selectedSubelements={row.config?.loop?.[loopParent.id]?.included ?? {}}
        />
      );
    });

    questionSelectionDetails.push(
      <div key={'loopIteration'}>
        <b>
          <FormattedMessage
            id={'smoove.page.survey.tables.options.loop-iteration.label'}
            defaultMessage={'Loop Iteration'}
          />
          :
        </b>{' '}
        {loopSelections}
      </div>
    );
  }

  if (
    element.config.qtype === 'multiple_vector' ||
    element.config.qtype === 'multiple_matrix' ||
    (element.config.qtype === 'custom_shelf' &&
      (element.config?.shelfResultType === 'multiple' || element.config?.shelf?.config?.shelfResultType === 'multiple'))
  ) {
    const { countedValue = 'selected', includeMissings = false } = row.config;

    if (countedValue === 'selected') {
      questionSelectionDetails.push(
        <div key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.selected'}
            defaultMessage={'selected values'}
          />
        </div>
      );
    } else if (countedValue === 'nonselected') {
      questionSelectionDetails.push(
        <div key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.nonselected'}
            defaultMessage={`<u>non</u> selected values`}
          />
        </div>
      );
    } else if (countedValue === 'missing') {
      questionSelectionDetails.push(
        <div key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.missing'}
            defaultMessage={`missing values`}
          />
        </div>
      );
    }

    if (includeMissings || countedValue === 'missing') {
      questionSelectionDetails.push(
        <div key={'includeMissings'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.calculation-base.label'}
              defaultMessage={'Base'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.calculation-base.missings-included'}
            defaultMessage={`missing values included`}
          />
        </div>
      );
    }
  }

  if (questionSelectionDetails.length > 0) {
    return <div className={`smv-result-table__result-question-selection-details`}>{questionSelectionDetails}</div>;
  }

  return null;
};

const LoopSelectionInfo = ({ loopSourceElement, selectedSubelements }) => {
  const selectedSubelementLabels = Object.keys(selectedSubelements)
    .map(choiceId => {
      if (!selectedSubelements[choiceId]) return null;
      const choice = loopSourceElement.config.choices.list[choiceId];
      return <LoopSelectionSubelements key={choice.id} subelement={choice} />;
    })
    .filter(Boolean);
  const selectedSubelementLabelsList = [];
  selectedSubelementLabels.forEach((e, i) => {
    if (i + 1 < selectedSubelementLabels.length) selectedSubelementLabelsList.push(e, ', ');
    else selectedSubelementLabelsList.push(e);
  });

  return (
    <div className={'smv-result-table__result-question-selection-details--loop-iteration'}>
      <span className={'smv-result-table__result-question-selection-details--loop-iteration-source'}>
        {loopSourceElement.title}:{' '}
      </span>
      <span className={'smv-result-table__result-question-selection-details--loop-iteration-selected'}>
        {selectedSubelementLabels.length === 0 ? (
          <FormattedMessage
            id={'smoove.page.project.tables.options.loop-iteration.all-iterations'}
            defaultMessage={'all'}
          />
        ) : (
          <span>{selectedSubelementLabelsList}</span>
        )}
      </span>
    </div>
  );
};

const LoopSelectionSubelements = ({ subelement }) => {
  const label = useSubelementPropertyTranslation(subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return <span>{label}</span>;
};
