import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './SyntheticDataWarning.scss';

export const SyntheticDataWarning = ({ className = '' }) => {
  const surveyStatus = useSelector(state => state.survey.status);

  if (['running', 'completed'].includes(surveyStatus) || !surveyStatus) return null;

  return (
    <div className={`synthetic-data-warning ${className}`} id={`tt_synth-data-warning`}>
      <i className='fal fa-exclamation-triangle fa-fw mr-1'></i>
      <span>
        <FormattedMessage id={'smoove.page.survey.results.dummy-data.dummy-data'} defaultMessage={'Synthetic Data'} />
      </span>
      <UncontrolledTooltip target={`tt_synth-data-warning`}>
        <FormattedMessage
          id={'smoove.page.survey.results.dummy-data.warning'}
          defaultMessage={'The survey has not been started - synthetic data is displayed!'}
        />
      </UncontrolledTooltip>
    </div>
  );
};
