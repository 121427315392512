import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Jodit } from 'jodit';
import { customAlphabet } from 'nanoid';

import { MediaLibraryModal } from './MediaLibrary/MediaLibraryButtonAndModal';
import { getMediaElementType } from './MediaLibrary/helpers/getMediaElementType';
import audiofile from '../../../assets/img/medialibrary/audiofile.png';
import videofile from '../../../assets/img/medialibrary/videofile.png';

import './EditableTextJodit.scss';

const preparePaste = jodit => {
  jodit.e.on(
    'paste',
    e => {
      jodit.e.stopPropagation('paste');

      const dataTransfer = Jodit.modules.Helpers.getDataTransfer(e);
      let pastedContent = '';
      let isHtml = false;

      if (dataTransfer.getData(Jodit.constants.TEXT_HTML)) {
        isHtml = true;
        pastedContent = dataTransfer.getData(Jodit.constants.TEXT_HTML);
      } else pastedContent = dataTransfer.getData(Jodit.constants.TEXT_PLAIN);

      if (isHtml && Jodit.modules.Helpers.isHtmlFromWord(pastedContent)) {
        // replace font through span tags
        pastedContent = pastedContent.replace(/<(\/)?(font)([\w\s="']*)[^>]*>/g, '<$1span$3>');
        // apply css styles to elements
        pastedContent = Jodit.modules.Helpers.applyStyles(pastedContent);
      }

      if (isHtml) {
        pastedContent = pastedContent
          // add linebreaks for table rows
          .replace(/<(\/tr)>/g, '<br/>')
          // remove several html tags
          .replace(/<(\/)?(html|body|colgroup|col|o:p|table|thead|tbody|tr|th|td)[^>]*>/g, '')
          // remove all class attributes
          .replace(/\s*class="[^"]*"/g, '')
          // remove html comments
          .replace(/<!--[^>]*>/g, '')
          // replace all html-headline tags with span
          .replace(/<(\/?)(h[1-6])>/g, '<$1span>')
          // remove all image tags, that does not contain a smv-media-id attribute
          .replace(/<img(?![^>]*\bsmv-media-id\b)[^>]*>/g, '');

        // remove style attribute, but keep styles for italic/ bold/ underline
        pastedContent = pastedContent.replace(/\s*style="([^"]*)"/g, function (match, styleContent) {
          const fontStyleMatch = styleContent.match(/font-style:\s*italic;/);
          const fontWeightMatch = styleContent.match(/font-weight:\s*700;/);
          const textDecorationMatch = styleContent.match(/text-decoration:\s*underline;/);

          if (fontStyleMatch || fontWeightMatch || textDecorationMatch) {
            const styles = [fontStyleMatch?.[0], fontWeightMatch?.[0], textDecorationMatch?.[0]].filter(String);

            return ` style="${styles.join(' ')}"`;
          } else {
            return '';
          }
        });
      }

      if (!isHtml) {
        // add <br/> instead of newline
        pastedContent = Jodit.modules.Helpers.nl2br(pastedContent);
      }

      jodit.s.insertHTML(pastedContent);

      return false;
    },
    { top: true }
  );
};

Jodit.plugins.add('preparePaste', preparePaste);

export const EditableTextJodit = ({
  value = '',
  onBlur,
  writeable,
  isMediaLibraryOpen = false,
  toggleMediaLibrary,
  isLoading = false,
  placeholder
}) => {
  const editorRef = useRef(null);

  const [content, setContent] = useState(value);
  const [tmpEditor, setTmpEditor] = useState(null); //tmp editor is used to paste an image from the media library into the correct position

  useEffect(() => {
    setContent(value);
  }, [value]);

  // need to pass the editor and set it to tmpEditor to be able to paste an image at the correct position
  const toggleMediaLibraryAndSetTmpEditor = useCallback(
    editor => {
      toggleMediaLibrary();
      setTmpEditor(editor);
    },
    [toggleMediaLibrary]
  );

  const buttons = useMemo(() => {
    return [
      'bold',
      'italic',
      'underline',
      'eraser',
      // 'image',
      {
        name: 'Media Library',
        // iconURL: 'https://www.fillmurray.com/460/300.jpg',
        tooltip: 'Open Media library',
        exec: editor => toggleMediaLibraryAndSetTmpEditor(editor)
        // icon: '<i class="far fa-image" ></i>'
      }
      // {
      // 	name: 'insert image',
      // 	tooltip: 'Insert current Date',
      // 	exec: (editor, url) => {

      //     editor.s.insertHTML(<p><img src={url} width="200px" alt="" /></p>);
      // 		// editor.s.insertHTML(new Date().toDateString());
      //     // selectFileCb(handleInsertImage)
      // 	}
      // }
      // {
      //   // iconURL: 'images/dummy.png',
      //   name: "test",
      //   tooltip: 'insert Dummy Image',
      //   exec: function (editor) {
      //     console.log(editor);
      //     editor.dummy.insertDummyImage("https://www.fillmurray.com/460/500.jpg");
      //   }
      // }
    ];
  }, [toggleMediaLibraryAndSetTmpEditor]);

  const buttonsWithoutMediaLibrary = useMemo(() => {
    return ['bold', 'italic', 'underline', 'eraser'];
  }, []);

  // https://xdsoft.net/jodit/docs/classes/config.Config.html
  const config = useMemo(() => {
    return {
      readonly: !writeable, // all options from https://xdsoft.net/jodit/doc/,
      // disabled: isLoading,
      placeholder: placeholder ?? 'Start typing...',
      buttons: toggleMediaLibrary ? buttons : buttonsWithoutMediaLibrary,
      buttonsMD: toggleMediaLibrary ? buttons : buttonsWithoutMediaLibrary,
      buttonsSM: toggleMediaLibrary ? buttons : buttonsWithoutMediaLibrary,
      buttonsXS: toggleMediaLibrary ? buttons : buttonsWithoutMediaLibrary,
      // spellcheck: true,
      // language: "en", //
      allowTabNavigation: true,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      showBrowserColorPicker: false,
      // nl2brInPlainText: true, //Inserts HTML line breaks before all newlines in a string
      defaultActionOnPaste: 'insert_as_html', //"insert_as_html" | "insert_as_text" | "insert_clear_html" | "insert_only_text"
      defaultActionOnPasteFromWord: 'insert_as_html',
      // cleanHTML: {   //https://xdsoft.net/jodit/docs/classes/config.Config.html#cleanHTML
      //   cleanOnPaste: true,
      //   // allowTags: 'p,br,div,b,strong,span,em,i,u,img,audio,video' //removes all elements that are not allowed (it will actually remove the tags and their content, not just the tags alone)
      // },
      processPasteFromWord: true,
      processPasteHTML: true,
      statusbar: false,
      hidePoweredByJodit: true,
      image: {
        openOnDblClick: true,
        editSrc: false,
        useImageEditor: false,
        showPreview: true,
        selectImageAfterClose: true,
        editBorderRadius: false,
        editLink: false,
        editMargins: false,
        editSize: true,
        editClass: false,
        editId: false,
        editAlt: false,
        editTitle: false
      }
      // toolbarSticky: true,
      // toolbarStickyOffset : 110
      // enableDragAndDropFileToEditor: true,
      // events: {
      //   afterInit: editor => {
      //     // console.log(editor);
      //   },
      //   // afterInit: function (editor) {
      //   //   editor.dummy = new Jodit.modules.Dummy(editor);
      //   // }
      //   select: s => {
      //     // console.log(s);
      //   },
      //   keydown: e => {
      //     // console.log(e);
      //   },
      //   s: s => {
      //     // console.log(s);
      //   }
      //   // getIcon: function (name, control, clearName) {
      //   //   var code = name;
      //   //   console.log(name);
      //   //   console.log(control);
      //   //   console.log(clearName);

      //   //   // not all font awesome icons is matched to Jodit icons
      //   //   switch (clearName) {
      //   //     case 'medialibrary':
      //   //       code = 'image';
      //   //       break;
      //   //   }

      //   //   return (
      //   //     '<i class="far fa-' +
      //   //     code +
      //   //     '"></i>'
      //   //   );
      //   // }
      // }
      // events: {
      //   afterInit: (instance) => {
      //     console.log(instance);
      //     testjodit = instance;
      //   }
      // }
    };
  }, [buttons, buttonsWithoutMediaLibrary, toggleMediaLibrary, writeable, placeholder]);
  // const config = {buttons: ['bold', 'italic', 'underline']};

  // const handleBlur = useCallback((newContent) => {
  //   onBlur(newContent)
  // }, [onBlur])

  const handleChange = useCallback(newContent => {
    setContent(newContent);
  }, []);

  const selectFileCb = useCallback(
    files => {
      const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;
      files.forEach(file => {
        const mediaid = customAlphabet('1234567890abcdef', 24)();
        const url = `${baseUrl}/${file.url}`;

        const fileType = getMediaElementType(file);
        let htmlElement = null;
        if (fileType === 'image') {
          htmlElement = document.createElement('img');
          htmlElement.setAttribute('src', url);
          htmlElement.setAttribute('width', '200px');
        } else if (fileType === 'audio') {
          htmlElement = document.createElement('img');
          htmlElement.setAttribute('src', audiofile);
          htmlElement.setAttribute('width', '120px');
          htmlElement.setAttribute('title', file.originalName);
          // htmlElement.controls = 'controls';
          // htmlElement.setAttribute('class', 'jodit-audio-html-element');
        } else if (fileType === 'video') {
          htmlElement = document.createElement('img');
          htmlElement.setAttribute('src', videofile);
          htmlElement.setAttribute('width', '360px');
          htmlElement.setAttribute('title', file.originalName);
          // htmlElement.controls = 'controls';
          // htmlElement.setAttribute('class', 'jodit-video-html-element');
        }
        // htmlElement.setAttribute('src', url);
        htmlElement.setAttribute('smv-media-id', mediaid);
        htmlElement.setAttribute('smv-media-source-id', file.id);
        tmpEditor.s.insertHTML(htmlElement);
      });
      setTmpEditor(null);
    },
    [tmpEditor]
  );

  return (
    <div className={`editabletext-jodit ${isLoading ? 'editabletext-jodit--loading' : ''}`}>
      <JoditEditor
        ref={editorRef}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={newContent => onBlur(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => handleChange(newContent)}
      />
      <MediaLibraryModal
        toggle={toggleMediaLibrary}
        isOpen={isMediaLibraryOpen}
        writeable={writeable}
        selectFileCb={selectFileCb}
      />
    </div>
  );
};

// buttons: ButtonsOption = [
//   {
//     group: 'font-style',
//     buttons: []
//   },
//   {
//     group: 'list',
//     buttons: []
//   },
//   {
//     group: 'font',
//     buttons: []
//   },
//   '---',
//   {
//     group: 'script',
//     buttons: []
//   },
//   {
//     group: 'media',
//     buttons: []
//   },
//   '\n',
//   {
//     group: 'state',
//     buttons: []
//   },
//   {
//     group: 'clipboard',
//     buttons: []
//   },
//   {
//     group: 'insert',
//     buttons: []
//   },
//   {
//     group: 'indent',
//     buttons: []
//   },
//   {
//     group: 'color',
//     buttons: []
//   },
//   {
//     group: 'form',
//     buttons: []
//   },
//   '---',
//   {
//     group: 'history',
//     buttons: []
//   },
//   {
//     group: 'search',
//     buttons: []
//   },
//   {
//     group: 'source',
//     buttons: []
//   },
//   {
//     group: 'other',
//     buttons: []
//   },
//   {
//     group: 'info',
//     buttons: []
//   }
// ];

// buttonsMD: ButtonsOption = [
//   'bold',
//   'italic',
//   '|',
//   'ul',
//   'ol',
//   'eraser',
//   '|',
//   'font',
//   'fontsize',
//   '---',
//   'image',
//   'table',
//   '|',
//   'link',
//   '\n',
//   'brush',
//   'paragraph',
//   'align',
//   '|',
//   'hr',
//   'copyformat',
//   'fullsize',
//   '---',
//   'undo',
//   'redo',
//   '|',
//   'dots'
// ];
