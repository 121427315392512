import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isNull } from 'lodash';

import { stripHtml } from '.';

/**
 * Returns the properties which are needed to get a translation.
 *
 * The hook returns the translations,
 * translations for the default (main) local, which are used as fallback,
 * the isShortlabelsActive flag and an intl instance.
 *
 * @returns {object} {translation, translationFallback, isShortlabelsActive, intl}
 */
export const useTranslationHooks = () => {
  const surveyid = useSelector(state => state.survey.id);
  const translations = useSelector(state => state.survey?.translations ?? {});
  const isShortlabelsActive = useSelector(state => state.projectSettings?.[surveyid]?.isShortlabelsActive ?? true);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyid]?.selectedLocale ?? state.survey.locales.main
  );

  const mainLocale = useSelector(state => state.survey.locales.main);
  const intl = useIntl();

  return useMemo(
    () => ({
      translations,
      isShortlabelsActive,
      selectedLocale,
      mainLocale,
      intl
    }),
    [translations, isShortlabelsActive, intl, selectedLocale, mainLocale]
  );
};

/**
 * Returns the translation for the given subelement/ property.
 *
 * @param {object} subelement
 * @param {object} orderItem
 * @param {boolean} showCodeValue Display code value in brackets as labels suffix. Defaults to false.
 * @param {string} property
 * @param {string} locale
 *
 * @returns translated label as string
 */
export const useSubelementPropertyTranslation = (subelement, orderItem = {}, options = {}) => {
  const { locale = null } = options;
  const translationsProps = useTranslationHooks(locale);

  return getSubelementPropertyTranslation(subelement, orderItem, options, translationsProps);
};

/**
 * Returns the translation for the given subelement/ property.
 *
 * @param {object} subelement
 * @param {object} orderItem
 * @param {boolean} showCodeValue Display code value in brackets as labels suffix. Defaults to false.
 * @param {object} translationProps Object returned by `useTranslationHooks`
 * @param {string} property
 *
 * @returns translated label as string
 */
export const getSubelementPropertyTranslation = (
  subelement,
  orderItem = {},
  options = {},
  { translations, intl, isShortlabelsActive = false, selectedLocale = null, mainLocale = null }
) => {
  const {
    property = 'label',
    locale = null,
    showCodeValue = false,
    useShortcodes = false,
    useStripped = false,
    useFallback = false
  } = options;

  let label = orderItem?.key ?? null;

  // show shortlabel if shortlabels are active and it exists
  if (useShortcodes && isShortlabelsActive && stripHtml(subelement?.config?.shortLabel ?? '').length > 0) {
    // todo: Check if property can be someting else as label and if yes, fix it
    // label = subelement?.[`${property}_short`];
    label = subelement?.config?.shortLabel;
  }

  // check if translation in passed locale exists
  else if (stripHtml(translations?.[subelement?.config?.translations?.[`${property}`]]?.[locale] ?? '').length > 0) {
    label = translations?.[subelement?.config?.translations?.[`${property}`]]?.[locale];
  }

  // check if translation in selectedLocale exists
  else if (
    stripHtml(translations?.[subelement?.config?.translations?.[`${property}`]]?.[selectedLocale] ?? '').length > 0
  ) {
    label = translations?.[subelement?.config?.translations?.[`${property}`]]?.[selectedLocale];
  }

  // if a translation is missing, use main language label
  else if (
    useFallback &&
    mainLocale &&
    stripHtml(translations?.[subelement?.config?.translations?.[`${property}`]]?.[mainLocale] ?? '').length > 0
  ) {
    label = translations?.[subelement?.config?.translations?.[`${property}`]]?.[mainLocale];
  } else if (!!orderItem?.label && intl.messages?.[orderItem.label]) {
    label = intl.messages[orderItem.label];
  } else if (!!orderItem?.label) {
    label = orderItem.label;
  }
  // check for non translated label on subelement,
  // e.g. for calc- and sysvar values
  else if (!!subelement?.label && !subelement.label.startsWith('choice.') && !subelement.label.startsWith('scale.')) {
    label = subelement.label;
  } else {
    //   return `not labeled (${orderItem.key})`;
  }

  if (isNull(label)) return null;

  if (useStripped) label = stripHtml(label);

  if (showCodeValue && (!!orderItem?.value || !!subelement?.value)) {
    return `${label} (${orderItem?.value ?? subelement?.value})`;
  } else {
    return label;
  }
};
