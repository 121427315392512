import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Row, Col, Input, FormGroup, UncontrolledTooltip, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { customAlphabet } from 'nanoid';
import { useIntl } from 'react-intl';
import produce from 'immer';

import { TextInputRow, RowLabel } from 'smv-components';
import { useSubelementPropertyTranslation } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';

import './FrequencyGroups.scss';

export const FrequencyGroups = ({ row, element, internalRowConfig, setInternalRowConfig }) => {
  const [isCreateNewGroupCollapseOpen, setIsCreateNewGroupCollapseOpen] = useState(false);
  const [collapseMap, setCollapseMap] = useState([]);

  const internalHandler = {
    closeGroupConfig: groupid => {
      setIsCreateNewGroupCollapseOpen(false);
      const _collapseMap = collapseMap.filter(id => id !== groupid);
      setCollapseMap(_collapseMap);
    },
    saveGroupConfig: calcGroup => {
      setInternalRowConfig(state =>
        produce(state, draft => {
          if (!draft?.calcFrequencyGroups || isArray(draft.calcFrequencyGroups)) draft.calcFrequencyGroups = {};
          draft.calcFrequencyGroups[calcGroup.id] = calcGroup;
        })
      );
      setIsCreateNewGroupCollapseOpen(false);
      const _collapseMap = collapseMap.filter(id => id !== calcGroup.id);
      setCollapseMap(_collapseMap);
    },
    deleteGroupConfig: calcGroupId => {
      setInternalRowConfig(state =>
        produce(state, draft => {
          delete draft.calcFrequencyGroups[calcGroupId];
        })
      );
      setIsCreateNewGroupCollapseOpen(false);
      const _collapseMap = collapseMap.filter(id => id !== calcGroupId);
      setCollapseMap(_collapseMap);
    }
  };

  const handleSetCollapseMap = groupid => {
    if (collapseMap.includes(groupid)) {
      const _collapseMap = collapseMap.filter(id => id !== groupid);
      setCollapseMap(_collapseMap);
    } else {
      setCollapseMap([...collapseMap, groupid]);
    }
  };

  return (
    <Row className={`smv-row-question-settings__boxes`}>
      <Col md={'8'} lg={'6'}>
        <div className={`mb-2`}>
          <h6>Boxes/ Overcodes</h6>
        </div>
        <div className={`smv-row-question-settings__boxes-list`}>
          {Object.values(internalRowConfig?.calcFrequencyGroups ?? {})?.map(group => (
            <React.Fragment key={group.id}>
              <div key={group.id} className={`smv-row-question-settings__boxes-list-item`}>
                <div>
                  <i
                    className={`pr-2 pointer fa-fw fal fa-chevron-${collapseMap.includes(group.id) ? 'down' : 'right'}`}
                    onClick={() => handleSetCollapseMap(group.id)}
                  />
                  <span className='fw-bold pointer' onClick={() => handleSetCollapseMap(group.id)}>
                    {group.label}
                  </span>
                </div>
                <span>
                  <i className='fal fa-edit pointer mr-2' onClick={() => handleSetCollapseMap(group.id)}></i>
                  <i
                    className='fal fa-trash-alt pointer mr-2'
                    onClick={() => internalHandler.deleteGroupConfig(group.id)}
                  ></i>
                </span>
              </div>
              <Collapse isOpen={collapseMap.includes(group.id)}>
                <FrequencyGroupConfig
                  row={row}
                  element={element}
                  selectedGroup={group.id}
                  calcFrequencyGroups={internalRowConfig?.calcFrequencyGroups ?? {}}
                  handler={internalHandler}
                />
              </Collapse>
            </React.Fragment>
          ))}
        </div>
        {/* </Collapse> */}
        {!isCreateNewGroupCollapseOpen && (
          <Button
            id={`add_calcGroup`}
            color='primary'
            outline
            className='btn-sm mr-1 mt-2'
            onClick={() => setIsCreateNewGroupCollapseOpen(true)}
          >
            <i className='fal fa-plus mr-2' />
            <span>New overcode</span>
          </Button>
        )}
        {isCreateNewGroupCollapseOpen && <div className='pl-2 mt-4'>New overcode:</div>}
        <Collapse isOpen={isCreateNewGroupCollapseOpen}>
          <FrequencyGroupConfig
            row={row}
            element={element}
            selectedGroup={'new'}
            calcFrequencyGroups={internalRowConfig?.calcFrequencyGroups ?? {}}
            handler={internalHandler}
          />
        </Collapse>
      </Col>
    </Row>
  );
};

export const FrequencyGroupConfig = ({ handler, row, element, selectedGroup, calcFrequencyGroups }) => {
  const intl = useIntl();
  const questions = useSelector(state => state.survey.questionnaire.list);

  const [internalGroupConfig, setInternalGroupConfig] = useState({});
  const { sourceType } = row;

  useEffect(() => {
    setInternalGroupConfig({
      id: customAlphabet('1234567890abcdef', 24)(),
      label: '',
      subElements: [],
      calculationMode: 'netcount',
      ...(calcFrequencyGroups?.[selectedGroup] ?? {})
    });
  }, [selectedGroup, calcFrequencyGroups]);

  const updateLabel = useCallback(({ target }) => {
    const { value } = target;
    setInternalGroupConfig(state => ({ ...state, label: value }));
  }, []);

  const togglesubElementInGroup = useCallback(choiceid => {
    setInternalGroupConfig(state =>
      produce(state, draft => {
        if (draft.subElements.includes(choiceid)) draft.subElements = draft.subElements.filter(_id => _id !== choiceid);
        else draft.subElements.push(choiceid);
      })
    );
  }, []);

  const handleCalculationModeChange = useCallback(e => {
    const { value } = e.target;
    setInternalGroupConfig(state =>
      produce(state, draft => {
        if (value !== 'average' && !!draft?.includeHurdle) delete draft.includeHurdle;
        draft.calculationMode = value;
      })
    );
  }, []);

  const handleToggleIncludeHurdle = useCallback(() => {
    setInternalGroupConfig(state =>
      produce(state, draft => {
        if (!draft?.includeHurdle) {
          console.log('set');
          draft.includeHurdle = {
            type: 'value',
            hurdle: 0
          };
        } else {
          console.log('delete');
          delete draft.includeHurdle;
        }
      })
    );
  }, []);

  const handleIncludeHurdleValueChange = useCallback(e => {
    const _value = parseFloat(e.target.value);
    if (isFinite(_value)) {
      setInternalGroupConfig(state =>
        produce(state, draft => {
          draft.includeHurdle.hurdle = e.target.value;
        })
      );
    }
  }, []);

  const elementValues = useMemo(() => {
    if (sourceType === 'question') {
      if (['single_matrix', 'multiple_matrix'].includes(element.config.qtype)) {
        return element.config.scales;
      } else {
        return getMergedChoices(element, element.config?.choices, questions);
      }
    } else if (sourceType === 'sysvar') {
      return { order: element.order, list: element.list };
    } else if (sourceType === 'calcvar') {
      return {
        order: element.values.map(el => el.id),
        list: Object.fromEntries(element?.values?.map(value => [value.id, value]))
      };
    }
  }, [sourceType, element, questions]);

  const handleInvertElementSelection = useCallback(() => {
    setInternalGroupConfig(state =>
      produce(state, draft => {
        elementValues.order.forEach(subId => {
          if (draft.subElements.includes(subId)) draft.subElements = draft.subElements.filter(_id => _id !== subId);
          else draft.subElements.push(subId);
        });
      })
    );
  }, [elementValues]);

  return (
    <div>
      <TextInputRow
        name={'label'}
        title={'Label'}
        // title={intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.label` })}
        // tooltip={intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.desc` })}
        value={internalGroupConfig?.label ?? ''}
        change={updateLabel}
        // placeholder={intl.formatMessage({
        //   id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.placeholder`
        // })}
      />
      <Row>
        <RowLabel
          name={'calculationMode'}
          title={intl.formatMessage({
            id: 'smoove.page.survey.tables.modals.question-settings.calculation-mode.label',
            defaultMessage: 'Calculation mode'
          })}
          sm={6}
        />

        <Col sm={6}>
          <Input
            type='select'
            id={`calculationMode`}
            name={`calculationMode`}
            value={internalGroupConfig?.calculationMode ?? 'netcount'}
            onChange={handleCalculationModeChange}
          >
            <option value={'netcount'}>
              {intl.formatMessage({
                id: 'smoove.page.survey.tables.modals.question-settings.calculation-mode.netcount.label',
                defaultMessage: 'net count'
              })}
            </option>
            <option value={'average'}>
              {intl.formatMessage({
                id: 'smoove.page.survey.tables.modals.question-settings.calculation-mode.average.label',
                defaultMessage: 'average (of aggregated values)'
              })}
            </option>
          </Input>
        </Col>
      </Row>

      {internalGroupConfig.calculationMode === 'average' && (
        <>
          <Row>
            <Col>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`${row.id}_${internalGroupConfig.id}_includeHurdle`}
                  checked={!!internalGroupConfig?.includeHurdle}
                  disabled={internalGroupConfig.calculationMode !== 'average'}
                  onChange={handleToggleIncludeHurdle}
                />
                <Label for={`${row.id}_${internalGroupConfig.id}_includeHurdle`}>
                  {intl.formatMessage({
                    id: 'smoove.page.survey.tables.modals.question-settings.calculation.include-hurdle.toggle.label',
                    defaultMessage: 'restrict values'
                  })}
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <Input
                type='number'
                disabled={!internalGroupConfig?.includeHurdle}
                value={internalGroupConfig?.includeHurdle?.hurdle ?? 0}
                onChange={handleIncludeHurdleValueChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <span>
                {intl.formatMessage({
                  id: 'smoove.page.survey.tables.modals.question-settings.calculation.include-hurdle.hint',
                  defaultMessage:
                    'Only values greater than the specified hurdle are included in the average calculation. Please use with caution!'
                })}
              </span>
            </Col>
          </Row>
        </>
      )}

      <Row className={'frequency-group-elements-header'}>
        <RowLabel name={'includedElements'} title={'includedElements'} />
        <span>
          <i
            id={`invert-element-selection-${selectedGroup}`}
            className={'fa-regular fa-shuffle icon-smv-blue'}
            onClick={handleInvertElementSelection}
          />
          <UncontrolledTooltip target={`invert-element-selection-${selectedGroup}`}>
            {intl.formatMessage({
              id: 'smoove.page.survey.tables.modals.question-settings.calculation-mode.invert-selected.label',
              defaultMessage: 'invert selection'
            })}
          </UncontrolledTooltip>
        </span>
      </Row>

      <Row>
        <Col>
          {elementValues?.order?.map(valueid => {
            // disable, if choice is already used in another group
            const disabled =
              Object.values(calcFrequencyGroups)
                .map(group => group.id !== internalGroupConfig.id && group.subElements.includes(valueid))
                .filter(Boolean).length > 0;
            return (
              <FrequencyGroupElement
                key={valueid}
                row={row}
                internalGroupConfig={internalGroupConfig}
                subElementid={valueid}
                subElement={elementValues.list[valueid]}
                togglesubElementInGroup={togglesubElementInGroup}
                disabled={disabled}
              />
            );
          })}
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col className={'smv-row-question-settings__boxes-group-controls mt-2'}>
          <Button
            color='primary'
            className={'btn-sm mr-1'}
            onClick={() => handler.saveGroupConfig(internalGroupConfig)}
          >
            Save
          </Button>
          <Button color='grey' className={'btn-sm'} onClick={() => handler.closeGroupConfig(internalGroupConfig.id)}>
            Dismiss
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const FrequencyGroupElement = ({
  row,
  subElementid,
  subElement,
  internalGroupConfig,
  togglesubElementInGroup,
  disabled
}) => {
  const label = useSubelementPropertyTranslation(subElement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <div className={`smv-row-question-settings__boxes-group-item`}>
      <span>{label}</span>
      <span>
        <FormGroup switch>
          <Input
            type='switch'
            role='switch'
            id={`${row.id}_${internalGroupConfig.id}_${subElementid}`}
            name={`${row.id}_${internalGroupConfig.id}_${subElementid}`}
            checked={internalGroupConfig?.subElements?.includes(subElementid) ?? false}
            disabled={disabled}
            onChange={() => togglesubElementInGroup(subElementid)}
          />
        </FormGroup>
      </span>
    </div>
  );
};
