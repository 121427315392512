import React from 'react';
import classNames from 'classnames';

import { getCellValue, useIsHovered, useSignificanceFlags, useSignificanceFlagsHover } from 'smv-helpers';
import { significanceGates } from '../config';

export const Cell = ({
  cell,
  value = null,
  metric,
  config,
  isHidden = false,
  isExcluded = false,
  cellIdentifier,
  hover = null
}) => {
  const significanceDimension = config?.valueSignificancesDimension ?? 'heads';
  const significanceBenchmark = config?.valueSignificancesBenchmark ?? null;
  const significanceLevel = parseFloat(config.valueSignificancesLevel);
  const significanceGate = significanceGates[1]?.[significanceLevel];

  const { isHovered, isHoveredCell } = useIsHovered(hover?.hoveredCell, cellIdentifier, significanceDimension);

  const { isSignificantHigh, isSignificantLow } = useSignificanceFlags(
    cell,
    significanceDimension,
    significanceGate,
    significanceBenchmark
  );
  const { isSignificantHighHover, isSignificantLowHover } = useSignificanceFlagsHover(
    hover?.hoveredCell,
    cellIdentifier,
    cell,
    significanceDimension,
    significanceGate,
    significanceBenchmark
  );

  return (
    <td
      className={classNames({
        'result__cell': true,
        'result__cell--hidden': isHidden,
        'result__cell--excluded': isExcluded,
        'result__cell--hovered': isHovered,
        'result__cell--hovered-cell': isHoveredCell,
        'sig__high': isSignificantHighHover || isSignificantHigh,
        'sig__low': isSignificantLowHover || isSignificantLow,
        'sig__mutually': isSignificantHigh && isSignificantLow && !isSignificantHighHover && !isSignificantLowHover,
        'sig__high--to-hovered': isSignificantHighHover,
        'sig__low--to-hovered': isSignificantLowHover,
        'sig__not-hovered':
          !significanceBenchmark &&
          !!hover?.hoveredCell &&
          (!isHovered || (isHovered && !isHoveredCell && !isSignificantHighHover && !isSignificantLowHover))
      })}
      onMouseOver={hover?.setHoveredCell ? () => hover.setHoveredCell(cellIdentifier) : null}
      onMouseOut={hover?.setHoveredCell ? () => hover.setHoveredCell(null) : null}
    >
      {!isExcluded && (value ?? getCellValue(cell, metric, config))}
      {isExcluded && `(n=${getCellValue(cell, 'smvrslt_abs', config)})`}
    </td>
  );
};
