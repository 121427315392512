export const questionnaireActionTypes = {
  LOAD_QUESTIONNAIRE_REQUEST: 'LOAD_QUESTIONNAIRE_REQUEST',
  LOAD_QUESTIONNAIRE_SUCCESS: 'LOAD_QUESTIONNAIRE_SUCCESS',
  LOAD_QUESTIONNAIRE_FAILURE: 'LOAD_QUESTIONNAIRE_FAILURE',

  EDIT_TEXT_REQUEST: 'EDIT_TEXT_REQUEST',
  EDIT_TEXT_SUCCESS: 'EDIT_TEXT_SUCCESS',
  EDIT_TEXT_FAILURE: 'EDIT_TEXT_FAILURE',

  ADD_QUESTIONNAIRE_ELEMENT_REQUEST: 'ADD_QUESTIONNAIRE_ELEMENT_REQUEST',
  ADD_QUESTIONNAIRE_ELEMENT_SUCCESS: 'ADD_QUESTIONNAIRE_ELEMENT_SUCCESS',
  ADD_QUESTIONNAIRE_ELEMENT_FAILURE: 'ADD_QUESTIONNAIRE_ELEMENT_FAILURE',

  REMOVE_QUESTIONNAIRE_ELEMENT_REQUEST: 'REMOVE_QUESTIONNAIRE_ELEMENT_REQUEST',
  REMOVE_QUESTIONNAIRE_ELEMENT_SUCCESS: 'REMOVE_QUESTIONNAIRE_ELEMENT_SUCCESS',
  REMOVE_QUESTIONNAIRE_ELEMENT_FAILURE: 'REMOVE_QUESTIONNAIRE_ELEMENT_FAILURE',

  UPDATE_QUESTIONNAIRE_ELEMENT_REQUEST: 'UPDATE_QUESTIONNAIRE_ELEMENT_REQUEST',
  UPDATE_QUESTIONNAIRE_ELEMENT_SUCCESS: 'UPDATE_QUESTIONNAIRE_ELEMENT_SUCCESS',
  UPDATE_QUESTIONNAIRE_ELEMENT_FAILURE: 'UPDATE_QUESTIONNAIRE_ELEMENT_FAILURE',

  COPY_QUESTIONNAIRE_ELEMENT_REQUEST: 'COPY_QUESTIONNAIRE_ELEMENT_REQUEST',
  COPY_QUESTIONNAIRE_ELEMENT_SUCCESS: 'COPY_QUESTIONNAIRE_ELEMENT_SUCCESS',
  COPY_QUESTIONNAIRE_ELEMENT_FAILURE: 'COPY_QUESTIONNAIRE_ELEMENT_FAILURE',

  ADD_CHOICE_REQUEST: 'ADD_CHOICE_REQUEST',
  ADD_CHOICE_SUCCESS: 'ADD_CHOICE_SUCCESS',
  ADD_CHOICE_FAILURE: 'ADD_CHOICE_FAILURE',

  REMOVE_CHOICE_REQUEST: 'REMOVE_CHOICE_REQUEST',
  REMOVE_CHOICE_SUCCESS: 'REMOVE_CHOICE_SUCCESS',
  REMOVE_CHOICE_FAILURE: 'REMOVE_CHOICE_FAILURE',

  UPDATE_CHOICE_REQUEST: 'UPDATE_CHOICE_REQUEST',
  UPDATE_CHOICE_SUCCESS: 'UPDATE_CHOICE_SUCCESS',
  UPDATE_CHOICE_FAILURE: 'UPDATE_CHOICE_FAILURE',

  ADD_CHOICES_IMPORT_REQUEST: 'ADD_CHOICES_IMPORT_REQUEST',
  ADD_CHOICES_IMPORT_SUCCESS: 'ADD_CHOICES_IMPORT_SUCCESS',
  ADD_CHOICES_IMPORT_FAILURE: 'ADD_CHOICES_IMPORT_FAILURE',

  REMOVE_CHOICES_IMPORT_REQUEST: 'REMOVE_CHOICES_IMPORT_REQUEST',
  REMOVE_CHOICES_IMPORT_SUCCESS: 'REMOVE_CHOICES_IMPORT_SUCCESS',
  REMOVE_CHOICES_IMPORT_FAILURE: 'REMOVE_CHOICES_IMPORT_FAILURE',

  UPDATE_CHOICES_IMPORT_REQUEST: 'UPDATE_CHOICES_IMPORT_REQUEST',
  UPDATE_CHOICES_IMPORT_SUCCESS: 'UPDATE_CHOICES_IMPORT_SUCCESS',
  UPDATE_CHOICES_IMPORT_FAILURE: 'UPDATE_CHOICES_IMPORT_FAILURE',

  ADD_SCALE_REQUEST: 'ADD_SCALE_REQUEST',
  ADD_SCALE_SUCCESS: 'ADD_SCALE_SUCCESS',
  ADD_SCALE_FAILURE: 'ADD_SCALE_FAILURE',

  REMOVE_SCALE_REQUEST: 'REMOVE_SCALE_REQUEST',
  REMOVE_SCALE_SUCCESS: 'REMOVE_SCALE_SUCCESS',
  REMOVE_SCALE_FAILURE: 'REMOVE_SCALE_FAILURE',

  UPDATE_SCALE_REQUEST: 'UPDATE_SCALE_REQUEST',
  UPDATE_SCALE_SUCCESS: 'UPDATE_SCALE_SUCCESS',
  UPDATE_SCALE_FAILURE: 'UPDATE_SCALE_FAILURE',

  ADD_SCREENOUT_ELEMENT_REQUEST: 'ADD_SCREENOUT_ELEMENT_REQUEST',
  ADD_SCREENOUT_ELEMENT_SUCCESS: 'ADD_SCREENOUT_ELEMENT_SUCCESS',
  ADD_SCREENOUT_ELEMENT_FAILURE: 'ADD_SCREENOUT_ELEMENT_FAILURE',

  REMOVE_SCREENOUT_ELEMENT_REQUEST: 'REMOVE_SCREENOUT_ELEMENT_REQUEST',
  REMOVE_SCREENOUT_ELEMENT_SUCCESS: 'REMOVE_SCREENOUT_ELEMENT_SUCCESS',
  REMOVE_SCREENOUT_ELEMENT_FAILURE: 'REMOVE_SCREENOUT_ELEMENT_FAILURE',

  UPDATE_SCREENOUT_ELEMENT_REQUEST: 'UPDATE_SCREENOUT_ELEMENT_REQUEST',
  UPDATE_SCREENOUT_ELEMENT_SUCCESS: 'UPDATE_SCREENOUT_ELEMENT_SUCCESS',
  UPDATE_SCREENOUT_ELEMENT_FAILURE: 'UPDATE_SCREENOUT_ELEMENT_FAILURE',

  ADD_FILTER_ELEMENT_REQUEST: 'ADD_FILTER_ELEMENT_REQUEST',
  ADD_FILTER_ELEMENT_SUCCESS: 'ADD_FILTER_ELEMENT_SUCCESS',
  ADD_FILTER_ELEMENT_FAILURE: 'ADD_FILTER_ELEMENT_FAILURE',

  REMOVE_FILTER_ELEMENT_REQUEST: 'REMOVE_FILTER_ELEMENT_REQUEST',
  REMOVE_FILTER_ELEMENT_SUCCESS: 'REMOVE_FILTER_ELEMENT_SUCCESS',
  REMOVE_FILTER_ELEMENT_FAILURE: 'REMOVE_FILTER_ELEMENT_FAILURE',

  UPDATE_FILTER_ELEMENT_REQUEST: 'UPDATE_FILTER_ELEMENT_REQUEST',
  UPDATE_FILTER_ELEMENT_SUCCESS: 'UPDATE_FILTER_ELEMENT_SUCCESS',
  UPDATE_FILTER_ELEMENT_FAILURE: 'UPDATE_FILTER_ELEMENT_FAILURE',

  ADD_JUMP_ELEMENT_REQUEST: 'ADD_JUMP_ELEMENT_REQUEST',
  ADD_JUMP_ELEMENT_SUCCESS: 'ADD_JUMP_ELEMENT_SUCCESS',
  ADD_JUMP_ELEMENT_FAILURE: 'ADD_JUMP_ELEMENT_FAILURE',

  REMOVE_JUMP_ELEMENT_REQUEST: 'REMOVE_JUMP_ELEMENT_REQUEST',
  REMOVE_JUMP_ELEMENT_SUCCESS: 'REMOVE_JUMP_ELEMENT_SUCCESS',
  REMOVE_JUMP_ELEMENT_FAILURE: 'REMOVE_JUMP_ELEMENT_FAILURE',

  UPDATE_JUMP_ELEMENT_REQUEST: 'UPDATE_JUMP_ELEMENT_REQUEST',
  UPDATE_JUMP_ELEMENT_SUCCESS: 'UPDATE_JUMP_ELEMENT_SUCCESS',
  UPDATE_JUMP_ELEMENT_FAILURE: 'UPDATE_JUMP_ELEMENT_FAILURE',

  ADD_DISPLAY_CONDITION_ELEMENT_REQUEST: 'ADD_DISPLAY_CONDITION_ELEMENT_REQUEST',
  ADD_DISPLAY_CONDITION_ELEMENT_SUCCESS: 'ADD_DISPLAY_CONDITION_ELEMENT_SUCCESS',
  ADD_DISPLAY_CONDITION_ELEMENT_FAILURE: 'ADD_DISPLAY_CONDITION_ELEMENT_FAILURE',

  REMOVE_DISPLAY_CONDITION_ELEMENT_REQUEST: 'REMOVE_DISPLAY_CONDITION_ELEMENT_REQUEST',
  REMOVE_DISPLAY_CONDITION_ELEMENT_SUCCESS: 'REMOVE_DISPLAY_CONDITION_ELEMENT_SUCCESS',
  REMOVE_DISPLAY_CONDITION_ELEMENT_FAILURE: 'REMOVE_DISPLAY_CONDITION_ELEMENT_FAILURE',

  UPDATE_DISPLAY_CONDITION_ELEMENT_REQUEST: 'UPDATE_DISPLAY_CONDITION_ELEMENT_REQUEST',
  UPDATE_DISPLAY_CONDITION_ELEMENT_SUCCESS: 'UPDATE_DISPLAY_CONDITION_ELEMENT_SUCCESS',
  UPDATE_DISPLAY_CONDITION_ELEMENT_FAILURE: 'UPDATE_DISPLAY_CONDITION_ELEMENT_FAILURE'
};
