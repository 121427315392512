import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { Store as notify } from 'react-notifications-component';

import { defaultNotifyProps } from 'smv-constants';
import { TextInputRow } from 'smv-components';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

export const RegisterForm = ({ user, handleShowLoginForm }) => {
  const intl = useIntl();
  const params = useParams();
  const history = useHistory();

  const invitationToken = params?.invitationToken;

  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordRepeat: ''
  });

  /**
   * Check form state, if all required fields are filled
   */
  const canSubmit = useMemo(() => {
    if (
      state.email.length === 0 ||
      state.password.length === 0 ||
      state.passwordRepeat.length === 0 ||
      state.firstName.length === 0 ||
      state.lastName.length === 0
    )
      return false;
    else if (state.password !== state.passwordRepeat) return false;
    return true;
  }, [state]);

  /**
   * Generic form field handler
   */
  const handleChange = useCallback(e => {
    let { name, value, type } = e.target;
    if (type === 'checkbox') value = e.target.checked;
    setState(s => ({ ...s, [name]: value }));
  }, []);

  /**
   * Handle form submit, either login or change password event
   */
  const handleSubmit = e => {
    e.preventDefault();

    if (!canSubmit) return false;

    const data = {
      email: state.email,
      password: state.password,
      firstName: state.firstName,
      lastName: state.lastName,
      invitationToken: invitationToken
    };

    axios
      .post(`/users/invitedUsers`, { ...data })
      .then(res => {
        notify.addNotification({
          title: intl.formatMessage({
            id: 'core.page.user-invitation.sucessfully-added-your-acccount-to-the-client-redirecting'
          }),
          type: 'success',
          ...defaultNotifyProps
        });
        history.push('/login');
      })
      .catch(err => {
        console.log(err);
        notify.addNotification({
          title: parseErrorAsMessageString(
            err,
            intl.formatMessage({ id: 'core.page.user-invitation.error-accepting-invitation' })
          ),
          type: 'danger',
          ...defaultNotifyProps,
          dismiss: {
            duration: 4000,
            showIcon: true
          }
        });
      });
  };

  return (
    <form onSubmit={handleSubmit} className='login-form'>
      <TextInputRow
        name={'email'}
        title={intl.formatMessage({ id: 'core.page.login.email.label', defaultMessage: 'Email' })}
        value={state.email}
        placeholder={intl.formatMessage({
          id: 'core.page.login.email.placeholder',
          defaultMessage: 'Enter email'
        })}
        change={handleChange}
        required
        autoFocus
        type={'email'}
      />
      <TextInputRow
        name={'firstName'}
        title={intl.formatMessage({ id: 'core.page.profile.user.firstname' })}
        value={state.firstName}
        placeholder={intl.formatMessage({ id: 'core.page.profile.user.firstname' })}
        change={handleChange}
        required
      />
      <TextInputRow
        name={'lastName'}
        title={intl.formatMessage({ id: 'core.page.profile.user.lastname' })}
        value={state.lastName}
        placeholder={intl.formatMessage({ id: 'core.page.profile.user.lastname' })}
        change={handleChange}
        required
      />
      <TextInputRow
        name={'password'}
        title={intl.formatMessage({ id: 'core.page.register.enter-password' })}
        value={state.password}
        placeholder={intl.formatMessage({ id: 'core.page.register.enter-password' })}
        change={handleChange}
        required
        type={'password'}
      />
      <TextInputRow
        name={'passwordRepeat'}
        title={intl.formatMessage({ id: 'core.page.register.repeat-password' })}
        value={state.passwordRepeat}
        placeholder={intl.formatMessage({ id: 'core.page.register.repeat-password' })}
        change={handleChange}
        required
        type={'password'}
      />

      <Button color={'primary'} className='mt-4' type='submit' style={{ minWidth: 250 }} disabled={!canSubmit}>
        {user.loggingIn && (
          <>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
            {intl.formatMessage({ id: 'core.page.login.button.loading', defaultMessage: 'loading...' })}
          </>
        )}
        {!user.loggingIn && !user.pwexpired && (
          <div>{intl.formatMessage({ id: 'core.page.register.button.submit' })}</div>
        )}
      </Button>
      <p className='my-2'>
        <span>{intl.formatMessage({ id: 'core.page.user-invitation.already-have-an-account' })}</span>
        <span className='ml-1 text-secondary pointer fw-bold' onClick={handleShowLoginForm}>
          {intl.formatMessage({ id: 'core.page.user-invitation.login-with-existing-account' })}
        </span>
      </p>
    </form>
  );
};
