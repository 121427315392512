import produce from 'immer';
import { settingsActionTypes } from '../constants';

const INITIAL_STATE = {
  expertMode: true,
  projects: {
    listStyle: 'tiles'
  }
};

export const settingsReducerUser = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case settingsActionTypes.SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS:
      return produce(state, draft => {
        const { style } = payload;
        draft.projects.listStyle = style;
      });

    case settingsActionTypes.SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS:
      return produce(state, draft => {
        const { expertMode } = payload;
        draft.expertMode = expertMode;
      });

    default:
      return state;
  }
};
