import { useMemo } from 'react';

/**
 * Checks wether the surveys questionnaire is editable.
 *
 * @param {*} surveyStatus The current survey status, 0 = wizard, 1 = draft, 2 = running, 3 = completed
 * @param {*} canEditLaunchedSurvey Flag if the user can edit an already launched survey
 *
 * @returns true | false
 */
export const useIsQueWriteable = (surveyStatus, canEditLaunchedSurvey = false) =>
  useMemo(() => {
    if (canEditLaunchedSurvey) {
      return true;
    } else if (['testing', 'running', 'completed'].includes(surveyStatus)) {
      return false;
    } else return true;
  }, [surveyStatus, canEditLaunchedSurvey]);
