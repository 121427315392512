import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Row } from 'reactstrap';

import { FormattedMessage, useIntl } from 'react-intl';

import { useElementPropertyTranslation } from 'smv-helpers';

import { EditableTextHover } from '../../..';
import { useSaveHandler } from './inputs';

/**
 * @link http://gitea.mla.local/smoove/smoove-specification/src/branch/master/Questionnaire.md#user-content-single-input
 */

export const ConfigSingleInput = ({ question, handler, writeable }) => {
  const surveyid = useSelector(state => state.survey?.id);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[state.survey?.id]?.selectedLocale ?? state.survey.locales.main
  );

  const intl = useIntl();

  const prefixText = useElementPropertyTranslation(question, {
    property: 'prefix',
    useFallback: !writeable,
    useShortcodes: false,
    locale: selectedLocale
  });

  const suffixText = useElementPropertyTranslation(question, {
    property: 'suffix',
    useFallback: !writeable,
    useShortcodes: false,
    locale: selectedLocale
  });

  const [internalState, setInternalState] = useState({
    suffix: question.config?.suffix ?? '',
    minValue: question.config?.minValue ?? null,
    maxValue: question.config?.maxValue ?? null,
    maxInputLength: question.config?.maxInputLength ?? null,
    lines: question.config?.lines ?? null,
    fieldWidth: question.config?.fieldWidth ?? null,
    fieldWidthUnit: question.config?.fieldWidthUnit
  });

  const saveHandler = useSaveHandler(question, surveyid);

  return (
    <>
      {question.config.qtype === 'single_input_number' && (
        <Row>
          <div className='config-group col-4'>
            <h6>
              <FormattedMessage id={`smoove.questionnaire.modal.config-tab.minimum`} defaultMessage={'Minimum'} />
            </h6>
            <Input
              disabled={!writeable}
              id={`config_minCount_id_${question.id}`}
              bsSize='sm'
              type='number'
              placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-min-value` })}
              value={internalState?.minValue ?? ''}
              onChange={e => setInternalState({ ...internalState, minValue: e.target.value })}
              onBlur={saveHandler.setMinValue}
            />
          </div>

          <div className='config-group col-4'>
            <h6>
              <FormattedMessage id={`smoove.questionnaire.modal.config-tab.maximum`} defaultMessage={'Maximum'} />
            </h6>
            <Input
              disabled={!writeable}
              id={`config_maxCount_id_${question.id}`}
              bsSize='sm'
              type='number'
              placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-max-value` })}
              value={internalState?.maxValue ?? ''}
              onChange={e => setInternalState({ ...internalState, maxValue: e.target.value })}
              onBlur={saveHandler.setMaxValue}
            />
          </div>

          <div className='config-group col-4'>
            <h6>
              <FormattedMessage
                id={`smoove.questionnaire.modal.config-tab.max-input-length`}
                defaultMessage={'Max. input length (number of characters)'}
              />
            </h6>
            <Input
              disabled={!writeable}
              id={`config_maxinputlength_id_${question.id}`}
              bsSize='sm'
              type='number'
              placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-max-input-length` })}
              value={internalState.maxInputLength ?? ''}
              onChange={e => setInternalState({ ...internalState, maxInputLength: e.target.value })}
              onBlur={saveHandler.setMaxLength}
              min={1}
              max={255}
            />
          </div>
        </Row>
      )}

      <div className='config-group'>
        <h6>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.prefix`} defaultMessage={'Prefix'} />
        </h6>
        <small>Text that will be rendered in front of the input.</small>
        <EditableTextHover
          className='border'
          writeable={writeable}
          value={prefixText ?? ''}
          replacing={true}
          // onChange={e => setInternalState({ ...internalState, prefix: e.target.value })}
          onBlur={e => saveHandler.setPrefix(e, selectedLocale)}
        />
      </div>
      <div className='config-group'>
        <h6>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.suffix`} defaultMessage={'Suffix'} />
        </h6>
        <small>Text that will be rendered after the input.</small>
        <EditableTextHover
          className='border'
          writeable={writeable}
          value={suffixText ?? ''}
          replacing={true}
          // onChange={e => setInternalState({ ...internalState, suffix: e.target.value })}
          onBlur={e => saveHandler.setSuffix(e, selectedLocale)}
        />
      </div>

      {question.config.qtype === 'single_input_text' && (
        <Row>
          <div className='config-group col-6'>
            <h6>
              <FormattedMessage id={`smoove.questionnaire.modal.config-tab.lines`} defaultMessage={'Lines'} />
            </h6>
            <Input
              disabled={!writeable}
              id={`config_lines_id_${question.config.varname}`}
              placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-max-number-lines` })}
              bsSize='sm'
              type='number'
              value={internalState.lines ?? ''}
              onChange={e => setInternalState({ ...internalState, lines: e.target.value })}
              onBlur={saveHandler.setLineCount}
              min={1}
              max={1000}
            />
          </div>
        </Row>
      )}

      <Row>
        <div className='config-group col-6'>
          <h6>
            <FormattedMessage id={`smoove.questionnaire.modal.config-tab.field-width`} defaultMessage={'Field width'} />
          </h6>
          <Input
            disabled={!writeable}
            id={`config_fieldwidth`}
            placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-field-width` })}
            bsSize='sm'
            type='text'
            value={internalState.fieldWidth ?? ''}
            onChange={e => setInternalState({ ...internalState, fieldWidth: e.target.value })}
            onBlur={saveHandler.setFieldWidth}
          />
        </div>

        {/* Field Width Unit */}
        <div className='config-group col-6'>
          <h6>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-tab.field-width-unit`}
              defaultMessage={'Field width Unit'}
            />
          </h6>
          <Input
            disabled={!writeable}
            id={`config_fieldwidth}`}
            bsSize='sm'
            type='select'
            value={internalState.fieldWidthUnit}
            onChange={e => setInternalState({ ...internalState, fieldWidthUnit: e.target.value })}
            onBlur={saveHandler.setFieldWidthUnit}
          >
            <option>em</option>
            <option>%</option>
          </Input>
        </div>
      </Row>
    </>
  );
};
