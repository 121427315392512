import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

export const ElementCollapseIcon = ({ isCollapsed, toggleCollapse }) => {
  return (
    <i
      className={classNames({
        'fas fa-chevron-right pointer p-1 rotate': true,
        'rotate-90': isCollapsed
      })}
      onClick={toggleCollapse}
    />
  );
};

ElementCollapseIcon.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired
};
