import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { IconProvider } from 'smv-components';

import { useSubelementPropertyTranslation } from 'smv-helpers';

/**
 *
 * @param {string|Component} questionSelectionDetails Additional information for the current selection, e.g. which
 *                                                    values are counted for mutliple vector questions
 *                                                    or which loop cycle is selected for looped questions.
 * @returns
 */
export const ResultTableChartResultRowHeader = ({
  rowid,
  subelement,
  orderItem,
  label,
  rowSpan = null,
  colSpan = null,
  questionHas = {},
  showCodeValuesRow = false,
  questionSelectionDetails = null
}) => {
  const _label = useSubelementPropertyTranslation(subelement, orderItem, {
    showCodeValue: showCodeValuesRow,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <th rowSpan={rowSpan} colSpan={colSpan} className={'row__header'}>
      <div className={'row__header_label'}>
        <span className={`row__header_label--label`}>{label || _label}</span>

        {questionHas.isLooped && (
          <span id={`icon_loop_${rowid}`} className={'ml-2'}>
            <IconProvider icon='loop' filled={false} clickable={false} />
            <UncontrolledTooltip target={`icon_loop_${rowid}`} delay={{ show: 500, hide: 200 }} autohide={false}>
              <FormattedMessage id={`smoove.questionnaire.is-looped`} defaultMessage={'Repeated in loop'} />
            </UncontrolledTooltip>
          </span>
        )}
      </div>
      {questionSelectionDetails}
    </th>
  );
};
