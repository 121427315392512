export const sortAlphAsc = media => {
  return [...media].sort((a, b) => a.originalName.toLowerCase().localeCompare(b.originalName.toLowerCase()));
};

export const sortAlphDesc = media => {
  return [...media].sort((a, b) => b.originalName.toLowerCase().localeCompare(a.originalName.toLowerCase()));
};

export const sortByDateNewestFirst = media => {
  return [...media].sort((a, b) => new Date(b.createdAt ?? Date.now()) - new Date(a.createdAt ?? Date.now()));
};

export const sortByDateOldestFirst = media => {
  return [...media].sort((a, b) => new Date(a.createdAt ?? Date.now()) - new Date(b.createdAt ?? Date.now()));
};

export const sortMedia = (media, sorting) => {
  switch (sorting) {
    case 'alphAsc':
      media = sortAlphAsc(media);
      break;
    case 'alphDesc':
      media = sortAlphDesc(media);
      break;
    case 'newestFirst':
      media = sortByDateNewestFirst(media);
      break;
    case 'oldestFirst':
      media = sortByDateOldestFirst(media);
      break;
    default:
      break;
  }
  return media;
};
