import React from 'react';

import { ApplyToValues } from '.';

export const ApplyToTypeTotal = ({ conditionalFormat, handleApplyToSelect }) => {
  return (
    <ApplyToValues
      conditionalFormat={conditionalFormat}
      label={'Total'}
      main={{ id: '__total__' }}
      dimensionKey={'heads'}
      values={{ order: ['__total__'], list: { __total__: { id: '__total__', label: 'Total' } } }}
      handleApplyToSelect={handleApplyToSelect}
    />
  );
};
