import React, { useCallback, useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';
import { isArray } from 'lodash';
import produce from 'immer';

export const ResultTableOpenEndedListingFooter = ({ table, tableResult, handler }) => {
  const row = table.rows.list[table.rows.order[0]];

  const handleConfigChange = useCallback(
    e => {
      const { name, value } = e.target;
      if (!['limit', 'offset'].includes(name)) return;
      handler.setTableConfig(state =>
        produce(state, draft => {
          if (!draft.rows.list[row.id]?.config || isArray(draft.rows.list[row.id].config))
            draft.rows.list[row.id].config = {};
          draft.rows.list[row.id].config[name] = value;
        })
      );
    },
    [handler, row.id]
  );

  const limit = row.config?.limit ?? 25;
  const offset = row.config?.offest ?? 0;
  const totalCount = tableResult?.values?.[row.id]?.totalCount ?? 0;

  useEffect(() => {
    if (totalCount > 0 && offset >= Math.ceil(totalCount / limit)) {
      handler.setTableConfig(state =>
        produce(state, draft => {
          draft.rows.list[row.id].config.offset = 0;
        })
      );
    }
  }, [handler, totalCount, offset, limit, row.id]);

  const pageOptions = useMemo(() => {
    if (totalCount <= limit) return null;
    const pages = Math.ceil(totalCount / limit);

    let pageOptions = [];
    for (let page = 0; page < pages; page++) {
      pageOptions.push(
        <option key={`result-table-listing-page-${page + 1}`} value={page}>
          {page + 1}
        </option>
      );
    }

    return pageOptions;
  }, [limit, totalCount]);

  return (
    <div className={'result-table-listing-footer'}>
      <div className={'result-table-listing-footer--limit'}>
        <div className='mr-2'>Limit</div>
        <Input
          type={'select'}
          label={'Number of results'}
          bsSize={'sm'}
          id={`limit`}
          name={`limit`}
          value={row.config?.limit ?? 25}
          onChange={handleConfigChange}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Input>
      </div>
      <div className={'result-table-listing-footer--pagination'}>
        <div className='mx-2'>Page</div>
        <Input
          type={'select'}
          label={'Page'}
          bsSize={'sm'}
          id={`offset`}
          name={`offset`}
          value={row.config?.offset ?? 0}
          onChange={handleConfigChange}
        >
          {pageOptions}
        </Input>
      </div>
    </div>
  );
};
