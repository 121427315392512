import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { PageHeadline } from 'smv-components';
import { useIsUserSmooveAdmin } from 'smv-helpers';

import './Administration.scss';

export const Administration = () => {
  const translationPrefix = 'smoove.page.administration';
  const intl = useIntl();

  const isUserSmooveAdmin = useIsUserSmooveAdmin();

  if (!isUserSmooveAdmin)
    return (
      <div className="container-fluid base-content flex-fill administration-page edit-client-page'">
        You do not have access to this page.
      </div>
    );

  return (
    <div className='container-fluid base-content flex-fill administration-page'>
      <PageHeadline headline={`${translationPrefix}.headline`} />
      <Helmet>
        <title>{intl.formatMessage({ id: `${translationPrefix}.headline`, defaultMessage: 'Administration' })}</title>
      </Helmet>
      <nav className={'small'} aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link to={'/one/admin'}>{intl.formatMessage({ id: `${translationPrefix}.administration` })}</Link>
          </li>
        </ol>
      </nav>
      <Row>
        <Col md='12'>
          <h5 className='text-uppercase fw-bold'>
            <FormattedMessage id={`smoove.page.administration.clients.organizations`} />
          </h5>
          <Link to={`admin/clients`}>
            <i className='fal fa-arrow-right mr-2' />
            <span>{intl.formatMessage({ id: `${translationPrefix}.clients.to-organization-administration` })}</span>
          </Link>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col md='12'>
          <h5 className='text-uppercase fw-bold'>
            <FormattedMessage id={`smoove.page.administration.users.users`} />
          </h5>
          <Link to={`admin/users`}>
            <i className='fal fa-arrow-right mr-2' />
            <span>{intl.formatMessage({ id: `${translationPrefix}.users.to-user-administration` })}</span>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
