// https://github.com/d3/d3-scale-chromatic#interpolateViridis
import * as d3Color from 'd3-scale-chromatic';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import { rgb } from 'd3-color';

import { getContrastColor } from '.';

export const getSchemeColor = ({ colorScales, colorSchema, value }) => {
  let color;

  if (colorScales.order.includes(colorSchema)) {
    let range = [],
      domain = [];

    const length = colorScales.list[colorSchema].colors.order.length;
    const steps = 1 / (length - 1);

    colorScales.list[colorSchema].colors.order.forEach((colorid, i) => {
      range.push(colorScales.list[colorSchema].colors.list[colorid].color);
      domain.push(steps * i);
    });

    const scale = scaleLinear().domain(domain).range(range).interpolate(interpolateRgb);
    color = scale(value);
  } else if (colorSchema === 'rdylgr') color = d3Color.interpolateRdYlGn(value);
  else if (colorSchema === 'rdylbu') color = d3Color.interpolateRdYlBu(value);
  else if (colorSchema === 'spectral') color = d3Color.interpolateSpectral(value);
  else if (colorSchema === 'viridis') color = d3Color.interpolateViridis(value);
  else if (colorSchema === 'blues') {
    color = d3Color.interpolateBlues(value + 0.05);
  } else if (colorSchema === 'rainbow') color = d3Color.interpolateRainbow(value);
  else if (colorSchema === 'sinebow') color = d3Color.interpolateSinebow(value);
  else if (colorSchema === 'turbo') color = d3Color.interpolateTurbo(value);
  else if (colorSchema === 'plasma') color = d3Color.interpolatePlasma(value);
  else color = d3Color.interpolateViridis(value);

  const contrastColor = getContrastColor(rgb(color));

  return { color, contrastColor };
};
