import React from 'react';

import { ReportsListItem } from './';

export const CustomNode = ({ node, handler, activeFilters }) => {
  return (
    <div>
      <ReportsListItem key={node.id} reportid={node.id} handler={handler} activeFilters={activeFilters} />
    </div>
  );
};
