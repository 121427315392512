// import { useEffect } from 'react';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import { useSelector, shallowEqual } from 'react-redux';

// todo: re-implement when going live!
export const SentryReporting = ({ children }) => {
  // const user = useSelector(state => state.authentication?.user ?? null, shallowEqual);
  // const branch = useSelector(state => state.appSettings.branch, shallowEqual);
  // const locale = useSelector(state => state.appSettings.locale, shallowEqual);

  // useEffect(() => {
  //   if (
  //     !branch ||
  //     process.env.NODE_ENV !== 'production' ||
  //     !process.env?.REACT_APP_SENTRY_DSN ||
  //     process.env.REACT_APP_SENTRY_DSN === ''
  //   )
  //     return;

  //   Sentry.init({
  //     dsn: process.env.REACT_APP_SENTRY_DSN,
  //     release: `smoove@${branch}-v${global.REACT_APP_VERSION}`,
  //     initialScope: {
  //       tags: { frontend_locale: locale }
  //     },
  //     ignoreErrors: ['ResizeObserver loop limit exceeded'],
  //     beforeSend(event) {
  //       if (event.exception) {
  //         Sentry.showReportDialog({
  //           eventId: event.event_id,
  //           lang: locale.substring(0, 2)
  //         });
  //       }

  //       return event;
  //     },
  //     integrations: [new Integrations.BrowserTracing()],

  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for performance monitoring.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0
  //   });
  // }, [branch, locale]);

  // useEffect(() => {
  //   Sentry.configureScope(scope => {
  //     if (!user) {
  //       scope.setUser(null);
  //     } else if (user) {
  //       scope.setUser({ email: user.email, username: user.username });
  //     }
  //   });
  // }, [user, locale]);

  // useEffect(() => {
  //   Sentry.configureScope(scope => {
  //     scope.setTag('frontend_locale', locale);
  //   });
  // }, [locale]);

  return children;
};
