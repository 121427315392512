import React from 'react';
import { useDrag } from 'react-dnd';
import { IconProvider } from 'smv-components';
import { getQuestionnaireElementTypeSpecificIconString } from 'smv-helpers';

export const MatrixQuestionItem = ({ question }) => {
  /* eslint-disable-next-line */
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'question',
    item: question,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  });

  const varnameString = question?.config?.varname ? `${question.config.varname} - ` : '';

  return (
    <div className={`list-group-item-text question-item`} ref={drag}>
      <IconProvider icon={getQuestionnaireElementTypeSpecificIconString(question)} />
      <span className='ml-2'>
        {varnameString}
        {question.title}
      </span>
    </div>
  );
};
