import { useCallback, useState } from 'react';
import produce from 'immer';
import classNames from 'classnames';

export const FormulaBuilderValueElement = ({ element, handleSaveElement, handleRemoveElement }) => {
  const [inputValue, setInputValue] = useState(element.value);

  const handleInputChange = useCallback(e => setInputValue(parseFloat(e.target.value)), []);

  const handleBlur = useCallback(
    e => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        handleSaveElement(
          produce(element, draft => {
            draft.value = value;
          })
        );
      }
    },
    [element, handleSaveElement]
  );

  const inputWidth = `${inputValue}`.length * 9 + 22 > 48 ? `${inputValue}`.length * 9 + 22 : 48;

  return (
    <div
      className={classNames({
        'formula-builder__element': true,
        'formula-builder__element--value': true
      })}
    >
      <input
        type='number'
        style={{ width: inputWidth }}
        value={inputValue}
        placeholder={''}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <div className='formula-builder__element-delete-btn' onClick={() => handleRemoveElement(element.id)}>
        <i className='far fa-trash-alt fa-fw' />
      </div>
    </div>
  );
};
