import produce from 'immer';
import { useIntl } from 'react-intl';
import { getSubelementPropertyTranslation, useGetSourceElement, useTranslationHooks } from 'smv-helpers';

export const useSignificanceBenchmarkLabel = () => {
  const intl = useIntl();
  const translationProps = useTranslationHooks();
  const getSourceElement = useGetSourceElement();

  return ({ tableConfig, valueSignificancesDimension, valueSignificancesBenchmark, isCompactMatrix }) => {
    let label;
    if (!valueSignificancesBenchmark) {
      label = intl.formatMessage({
        id: `smoove.page.tables.table-footer.significances.benchmark.multisided`,
        defaultMessage: 'multisided'
      });
    } else if (valueSignificancesDimension === 'heads' && valueSignificancesBenchmark?.headId === '__total__') {
      label = intl.formatMessage({ id: `smoove.page.tables.total` });
    } else {
      if (valueSignificancesDimension === 'heads') {
        let heads = tableConfig.heads;
        if (isCompactMatrix) {
          heads = produce(heads, d => {
            if (isCompactMatrix) {
              tableConfig.rows.order.forEach(rowid => {
                if (tableConfig.rows.list[rowid].sourceType === 'question') {
                  d.order = [rowid, ...d.order];
                  d.list[rowid] = tableConfig.rows.list[rowid];
                }
              });
            }
          });
        }

        const head = heads.list[valueSignificancesBenchmark.headId];
        const { elementValues } = getSourceElement(head);
        label = getSubelementPropertyTranslation(
          elementValues.list[valueSignificancesBenchmark?.headSubId],
          null,
          {
            useShortcodes: true,
            useStripped: true,
            useFallback: true
          },
          translationProps
        );
      } else if (valueSignificancesDimension === 'rows') {
        const row = tableConfig.rows.list[valueSignificancesBenchmark.rowId];
        const { elementValues, elementScales } = getSourceElement(row);
        const labelParts = [];
        labelParts[0] = getSubelementPropertyTranslation(
          isCompactMatrix
            ? elementScales.list[valueSignificancesBenchmark?.rowSubId]
            : elementValues.list[valueSignificancesBenchmark?.rowSubId],
          null,
          {
            useShortcodes: true,
            useStripped: true,
            useFallback: true
          },
          translationProps
        );
        labelParts[1] = getSubelementPropertyTranslation(
          elementScales.list[valueSignificancesBenchmark?.rowSubSubId],
          null,
          {
            useShortcodes: true,
            useStripped: true,
            useFallback: true
          },
          translationProps
        );

        label = labelParts.filter(Boolean).join(' | ');
      }
    }

    return label;
  };
};
