import { produce } from 'immer';

import { projectActionTypes } from '../constants/projectConstants';
import { project_sharedReportReducer } from './';
import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';

const INITIAL_STATE = {
  id: 'initial',
  loading: false,
  title: '',
  createdAt: '',
  status: 0,
  surveys: []
};

const projectReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * CREATE_PROJECT_X
     */
    case projectActionTypes.CREATE_PROJECT_REQUEST:
      return { ...state, fetching: true };
    case projectActionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        ...payload,
        fetching: false
      };
    case projectActionTypes.CREATE_PROJECT_FAILURE:
      return { ...state, fetching: false };

    /**
     * SAVE_PROJECT_X
     */
    // case projectActionTypes.SAVE_PROJECT_REQUEST:
    //   return { ...state, fetching: true };
    // case projectActionTypes.SAVE_PROJECT_SUCCESS:
    //   return { ...state, fetching: false };
    // case projectActionTypes.SAVE_PROJECT_FAILURE:
    //   return { ...state, fetching: false };

    case projectActionTypes.SET_SHARED_LIST:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          lists: {
            ...state.questionnaire.lists,
            [payload.type]: {
              ...state.questionnaire.lists[payload.type],
              [payload.list.config.share]: payload.list
            }
          }
        }
      };

    /**
     * EDIT_TEXT
     */
    case projectActionTypes.EDIT_TEXT: {
      const { locale, id, content } = payload;

      return produce(state, draftState => {
        // fix php json_encode issue (php always returns an array for empty 'objects'
        // => "json_encode(array())" returns "[]" as long a no associative keys are set
        if (draftState.questionnaire.translations.length === 0) {
          draftState.questionnaire.translations = {};
        }

        draftState.questionnaire.config.languages.forEach(lan => {
          // create translation object path for locale if not exist
          if (!draftState.questionnaire.translations?.[lan.locale]) {
            draftState.questionnaire.translations[lan.locale] = {};
          }

          // create translation-id for locale if not exist
          if (!draftState.questionnaire.translations[lan.locale]?.[id]) {
            draftState.questionnaire.translations[lan.locale][id] = '';
          }
        });

        draftState.questionnaire.translations[locale][id] = content;
      });
    }

    /**
     * UPDATE_QUESTION
     */
    case projectActionTypes.UPDATE_QUESTION:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          elements: {
            ...state.questionnaire.elements,
            list: {
              ...state.questionnaire.elements.list,
              [payload.question.id]: {
                ...payload.question,
                config: { ...payload.question.config }
              }
            }
          }
        }
      };

    case projectActionTypes.SURVEY_LIST_REQUEST:
      return { ...state, loading: true };

    case projectActionTypes.SURVEY_LIST_SUCCESS:
      return produce(state, draft => {
        const { surveys } = payload;
        draft.surveys = surveys;
        draft.loading = false;
      });

    case projectActionTypes.SURVEY_LIST_FAILURE:
      return { ...state, loading: false };

    case projectActionTypes.SWITCH_PROJECT:
      return produce(state, draft => {
        const { project } = payload;
        draft.title = project.title;
        draft.status = project.status;
        draft.description = project.description;
        draft.id = project.id;
        draft.surveys = project.surveys;
      });

    case projectActionTypes.INIT_RESET_PROJECT:
      return produce(state, draft => {
        draft.title = null;
        draft.status = null;
        draft.description = null;
        draft.id = null;
        draft.fetching = false;
        draft.surveys = [];
        draft.reports = [];
        draft.tables = [];
        draft.createdAt = null;
      });

    case projectActionTypes.PATCH_PROJECT_REQUEST:
      return { ...state, fetching: true };

    case projectActionTypes.PATCH_PROJECT_SUCCESS:
      return produce(state, draft => {
        const { project } = payload;
        draft.title = project.title;
        draft.status = project.status;
        draft.description = project.description;
        draft.fetching = false;
      });

    case projectActionTypes.PATCH_PROJECT_FAILURE:
      return { ...state, fetching: false };

    default:
      break;
  }

  state = project_sharedReportReducer(state, action);

  return state;
};

const persistConfig = {
  key: 'project',
  storage: local
};

export const projectReducerPersisted = persistReducer(persistConfig, projectReducer);
