import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

// import { Stone } from 'smv-components';

import documentation from '../assets/img/help/documentation.jpg';
import newproject from '../assets/img/startpage/newproject.jpg';
import projectsimg from '../assets/img/startpage/projects.jpg';

// import { projectsActions } from '../redux/actions';
import { PageHeadline } from '../components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

export const StartPage = ({ history }) => {
  // const wizards = useSelector(state => state.smooveSettings?.client?.wizards ?? { list: {}, order: [] });

  const clients = useSelector(state => state.clients);
  const user = useSelector(state => state.authentication.user);

  const clientId = clients.selected;

  useEffect(() => {
    if (!clientId) {
      history.push(`/one/clients`);
    }
    // todo: only get all projects if we will list all projects on this page. currently disabled below
    // else {
    //   projectsActions.getAllProjects(clientId);
    // }
  }, [clientId, history]);

  const intl = useIntl();

  const canUserCreateNewProject = useUserHasPermission(permissionEnum.PROJECT_CREATE);

  const handleClickCreateNewProject = useCallback(() => {
    if (canUserCreateNewProject) history.push(`/one/newproject`);
  }, [history, canUserCreateNewProject]);

  return (
    <>
      {/* <Stone type={'A'} color={'red'} width={'500px'} position={[null, 0, 0]} translate={[110, 250]} />
      <Stone type={'B'} color={'green'} width={'400px'} position={[500, null, null, 0]} translate={[-100, 0]} /> */}
      <Helmet>
        <title>{intl.formatMessage({ id: 'core.menu.item.dashboard' })}</title>
      </Helmet>
      <div className='base-content d-flex flex-fill container-fluid'>
        <div className={'container-fluid p-0'}>
          <PageHeadline headline={'smoove.page.start.headline'} values={{ name: user?.firstName ?? '' }} />
          {/* <Row className='mt-4'>
            <Col>
              <h2>
                <FormattedMessage id={'smoove.page.start.headline-h2'} defaultMessage={'News'} />
              </h2>
              <FormattedMessage
                id={'smoove.page.start.headline-h2-text'}
                defaultMessage={'Latest updates and information on smoove can be found on our'}
              />
              <a
                href='https://mindline-analytics.atlassian.net/wiki/spaces/SMVCS/blog'
                target='_blank'
                rel='noreferrer'
              >
                Blog
              </a>
              .
            </Col>
          </Row> */}
          <Row className='mt-4'>
            <Col md={{ size: 4, offset: 0 }} className='mb-4'>
              {/* <h2>News</h2> */}
              <Card
                className='pointer'
                onClick={() => {
                  if (clientId) {
                    history.push(`/one/projects`);
                  } else {
                    history.push('/one/clients');
                  }
                }}
              >
                {/* todo: create custom images and replace unsplash ones? */}
                <img alt='Projects' src={projectsimg} />
                <CardBody>
                  <CardTitle tag='h5'>
                    <FormattedMessage id={'smoove.page.start.cards.my-project'} defaultMessage={'Projects'} />
                  </CardTitle>
                  <CardText>
                    <FormattedMessage
                      id={'smoove.page.start.cards.my-project-text'}
                      defaultMessage={'A list of all projects.'}
                    />
                  </CardText>
                  <Button color={'secondary'}>
                    <FormattedMessage
                      id={'smoove.page.start.cards.my-project'}
                      defaultMessage={'Alle Projekte anzeigen'}
                    />
                  </Button>
                </CardBody>
              </Card>
            </Col>
            {canUserCreateNewProject && (
              <Col md={4} className='mb-4'>
                <Card className='pointer' onClick={handleClickCreateNewProject}>
                  {/* todo: create custom images and replace unsplash ones? */}
                  <img alt='New Project' src={newproject} />
                  <CardBody>
                    <CardTitle tag='h5'>
                      <FormattedMessage id={'smoove.page.start.cards.new-project'} defaultMessage={'New Project'} />
                    </CardTitle>
                    <CardText>
                      <FormattedMessage
                        id={'smoove.page.start.cards.new-project-text'}
                        defaultMessage={'Create a new project.'}
                      />
                    </CardText>
                    <Button color={'primary'}>
                      <FormattedMessage
                        id={'smoove.page.start.cards.new-project-button'}
                        defaultMessage={'Create new project'}
                      />
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col md={4} className='mb-4'>
              <Card>
                <img alt='Sample' src={documentation} />
                <CardBody>
                  <CardTitle tag='h5'>
                    <FormattedMessage id={'smoove.page.start.cards.documentation'} defaultMessage={'Documentation'} />
                  </CardTitle>
                  <CardText>
                    <FormattedMessage
                      id={'smoove.page.start.cards.documentation-text'}
                      defaultMessage={'Read our documentation.'}
                    />
                  </CardText>
                  <Button
                    href={'https://mindline-analytics.atlassian.net/wiki/spaces/SMVCS/overview'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FormattedMessage
                      id={'smoove.page.start.cards.documentation-button'}
                      defaultMessage={'Go to documentation'}
                    />
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* </Container> */}
          {/* {projects?.length > 0 && (
            <Row className='mt-4'>
              <Col>
                <h2>
                  <FormattedMessage id={'smoove.page.start.latest-projects'} defaultMessage={'Your latest projects'} />
                </h2>
                <Table striped style={{ background: 'white' }}>
                  <thead>
                    <tr>
                      <td>
                        <b>
                          <FormattedMessage id={'smoove.page.start.table.project-name'} defaultMessage={'Name'} />
                        </b>
                      </td>
                      <td>
                        <b>
                          <FormattedMessage
                            id={'smoove.page.start.table.project-description'}
                            defaultMessage={'Description'}
                          />
                        </b>
                      </td>
                      <td>
                        <b>
                          <FormattedMessage
                            id={'smoove.page.start.table.project-created-at'}
                            defaultMessage={'Created at'}
                          />
                        </b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {projects
                      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .slice(0, 5)
                      .map(project => {
                        return (
                          <tr key={project.id} className='pointer'>
                            <td onClick={() => openProject(project)}>{project.title}</td>
                            <td onClick={() => openProject(project)}>{project.description}</td>
                            <td onClick={() => openProject(project)}>
                              <FormattedDate day='2-digit' year='numeric' month='long' value={project.createdAt} /> -{' '}
                              <FormattedTime value={project.createdAt} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Link to={'/one/projects'}>
                  <FormattedMessage id={'smoove.page.start.show-all-projects'} defaultMessage={'show all projects'} />
                </Link>
              </Col>
            </Row>
          )} */}

          <div className='row pt-5'>
            {/* <div className='col-lg-4 col-sm-6 col-12 pb-4'>
              <CreateProjectWidget
                title={intl.formatMessage({
                  id: 'smoove.common.project.types.ideascreening.label'
                })}
                description={intl.formatMessage({
                  id: 'smoove.common.project.types.ideascreening.description'
                })}
                type={'ideascreening'}
                disabled={true}
              />
            </div> */}

            {/* {process.env.REACT_APP_WZ_CONCEPT === 'true' && (
              <div className='col-lg-4 col-sm-6 col-12 pb-4'>
                <CreateProjectWidget
                  title={intl.formatMessage({
                    id: 'smoove.common.project.types.concept.label'
                  })}
                  description={intl.formatMessage({
                    id: 'smoove.common.project.types.concept.description'
                  })}
                  type={'concept'}
                />
              </div>
            )} */}

            {/* {wizards?.order?.map(wizardid => {
              const wizard = wizards?.list?.[wizardid];
              if (!wizard) return null;
              return (
                <div key={`wizard-card-${wizardid}`} className='col-lg-4 col-sm-6 col-12 pb-4'>
                  <CreateProjectWidget
                    title={intl.formatMessage({
                      id: wizard?.title?.stringid,
                      defaultMessage: wizard?.title?.default
                    })}
                    description={intl.formatMessage({
                      id: wizard?.description?.stringid,
                      defaultMessage: wizard?.description?.default
                    })}
                    type={wizard?.type}
                  />
                </div>
              );
            })} */}

            {/* <div className='col-lg-4 col-sm-6 col-12 pb-4'>
              <CreateProjectWidget
                title={intl.formatMessage({
                  id: 'smoove.common.project.types.conjoint.label'
                })}
                description={intl.formatMessage({
                  id: 'smoove.common.project.types.conjoint.description'
                })}
                type={'conjoint'}
                disabled={true}
              />
            </div> */}

            {/* {process.env.REACT_APP_WZ_INDIVIDUAL === 'true' && (
              <div className='col-lg-4 col-sm-6 col-12 pb-4'>
                <CreateProjectWidget
                  title={intl.formatMessage({
                    id: 'smoove.common.project.types.individual.label'
                  })}
                  description={intl.formatMessage({
                    id: 'smoove.common.project.types.individual.description'
                  })}
                  type={'individual'}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
