import Axios from 'axios';
import { Store as notify } from 'react-notifications-component';

import { defaultNotifyProps } from 'smv-constants';
import { projectsActions, store } from 'smv-redux';
import 'smv-redux/axiosConfig';

import { projectActionTypes } from '../constants/projectConstants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

const editText = (locale, id, content, options) => dispatch => {
  dispatch({
    type: projectActionTypes.EDIT_TEXT,
    payload: { locale, id, content, options }
  });
};

// --- old? ---
// const setSharedList = (list, type) => dispatch => {
//   dispatch({
//     type: projectActionTypes.SET_SHARED_LIST,
//     payload: { list, type }
//   });
// };

// const saveQuestion = question => dispatch => {
//   dispatch({
//     type: projectActionTypes.UPDATE_QUESTION,
//     payload: { question }
//   });
// };
// -------

/**
 * Loads all surveys of a project and updates the redux store
 *
 * @param {string} projectId
 *
 */
const getAllSurveys = projectId => {
  store.dispatch({
    type: projectActionTypes.SURVEY_LIST_REQUEST
  });

  Axios.get('/projects/' + projectId + '/surveys')
    .then(res => {
      const surveys = res?.data ?? false;

      if (!!surveys) {
        store.dispatch({
          type: projectActionTypes.SURVEY_LIST_SUCCESS,
          payload: { surveys: surveys }
        });
      } else {
        store.dispatch({
          type: projectActionTypes.SURVEY_LIST_FAILURE
        });
      }
    })
    .catch(err => {
      store.dispatch({
        type: projectActionTypes.SURVEY_LIST_FAILURE
      });
    });
};

// todo: should this, instead of switchint the project state in redux, get the project from the backend first in case of changes?
/**
 * switches to a specific project and fetches all of its surveys
 *
 * @param {object} project //the project that should be switched to
 *
 */
const switchProject = project => {
  store.dispatch({
    type: projectActionTypes.SWITCH_PROJECT,
    payload: {
      project
    }
  });
};

/**
 * Resets the redux project-state to an empty project
 *
 */
const resetProject = () => {
  store.dispatch({
    type: projectActionTypes.INIT_RESET_PROJECT
  });
};

/**
 * Updates a project with the information provided in the config
 *
 * @param {string} projectid //the project that should be updated
 * @param {object} config //the project that should be switched to
 *
 */
const patchProject = (projectid, config) => {
  store.dispatch({
    type: projectActionTypes.PATCH_PROJECT_REQUEST,
    payload: { projectid }
  });

  Axios.patch(`/projects/${projectid}`, config)
    .then(res => {
      const project = res.data;

      store.dispatch({
        type: projectActionTypes.PATCH_PROJECT_SUCCESS,
        payload: {
          projectid,
          project
        }
      });

      return project;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: projectActionTypes.PATCH_PROJECT_FAILURE,
        payload: {
          projectid,
          error: err
        }
      });
    });
};

/**
 * Updates a project with the information provided in the config
 *
 * @param {string} clientid //the project that should be updated
 * @param {object} project //all information necessary to create a new project
 * @param {function} callbackFn //function that is called on success (passed the newly created project) or if failed (passed null, error)
 *
 */
const createNewProject = (clientId, project, callbackFn = () => {}) => {
  store.dispatch({
    type: projectActionTypes.CREATE_PROJECT_REQUEST,
    payload: null
  });

  Axios.post('/clients/' + clientId + '/projects', project)
    .then(res => {
      const project = res?.data ?? false;

      store.dispatch({
        type: projectActionTypes.CREATE_PROJECT_SUCCESS,
        payload: project
      });

      callbackFn(project);
      // projectsActions.getAllProjects(clientId);
    })
    .catch(err => {
      console.log(err);
      callbackFn(null, err);
      store.dispatch({
        type: projectActionTypes.CREATE_PROJECT_FAILURE
      });
    });
};

/**
 * Duplicates a survey in a project
 *
 * @param {string} projectId
 */
export const duplicate = async projectId => {
  store.dispatch({
    type: projectActionTypes.DUPLICATE_PROJECT_REQUEST,
    payload: { projectId }
  });

  return Axios.request({ method: 'copy', url: `/projects/${projectId}`, timeout: 10 * 60 * 1000 })
    .then(res => {
      const project = res.data;

      store.dispatch({
        type: projectActionTypes.DUPLICATE_PROJECT_SUCCESS,
        payload: {
          projectId,
          project
        }
      });

      notify.addNotification({
        title: 'Project successfully duplicated!',
        type: 'success',
        ...defaultNotifyProps
      });

      return res;
    })
    .catch(error => {
      store.dispatch({
        type: projectActionTypes.DUPLICATE_PROJECT_FAILURE,
        payload: {
          projectId,
          error
        }
      });
      notify.addNotification({
        title: 'An error occured during duplication, please try again or contact the support.',
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * Imports a project to smoove
 *
 * @param {string} clientid the project that should be updated
 * @param {FormData} formData the file and name that should be uploaded as FormData
 *
 */
const importProject = (clientId, formData) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/clients/${clientId}/projects/importedProjects`, formData, { timeout: 5 * 60 * 1000 })
      .then(res => {
        notify.addNotification({
          title: 'Project imported successfully. ',
          type: 'success',
          ...defaultNotifyProps
        });
        projectsActions.getAllProjects(clientId);
        resolve(res);
      })
      .catch(error => {
        notify.addNotification({
          title: parseErrorAsMessageString(
            error,
            'Something went wrong. If a project was created due to the import, please delete it and try importing again.'
          ),
          type: 'danger',
          ...defaultNotifyProps,
          dismiss: {
            duration: 5000,
            showIcon: true
          }
        });
        reject(error);
      });
  });
};

export const projectActions = {
  createNewProject,
  // setSharedList,
  editText,
  // saveQuestion,
  getAllSurveys,
  switchProject,
  resetProject,
  patchProject,
  duplicate,
  importProject
};
