import React, { useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button, ButtonGroup, Input, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { pick } from 'lodash';
import produce from 'immer';

import { useGetSourceElement } from 'smv-helpers';
import { ErrorBoundary } from 'src/smoove/components/misc';

import './FiltersListSelected.scss';

export const FiltersListSelected = ({ filterOrder, filterList, handler }) => {
  return (
    <div className='selected-filter-list'>
      {/* todo: _id was missing in the response from backend. will be re-implemented, then I think building this custom filter-object can be removed and it can be: filter = filterlist[filterid] again  */}
      {filterOrder.map((filterid, index) => {
        const filter = {
          ...filterList[filterid]
        };

        return (
          <Draggable key={`${filterid}_${index}`} draggableId={filterid} index={index}>
            {provided => (
              <div
                className={`draggable-default `}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style
                }}
              >
                <ErrorBoundary fallback={<SelectedListElementInvalid filter={filter} handler={handler} />}>
                  <SelectedListElement filter={filter} handler={handler} />
                </ErrorBoundary>
              </div>
            )}
          </Draggable>
        );
      })}
    </div>
  );
};

const SelectedListElement = React.memo(({ filter, handler }) => {
  const { elementTitle } = useGetSourceElement()(pick(filter, ['id', 'sourceType', 'sourceId']));

  const intl = useIntl();
  const placeholder =
    elementTitle ??
    intl.formatMessage({ id: 'smoove.page.reports.modals.filters.unnamed-element', defaultMessage: 'unnamed element' });

  const [fade, setFade] = useState(false);
  const [filterLabel, setFilterLabel] = useState(filter?.label ?? '');

  const changeFilterLabel = useCallback(e => {
    const { value } = e.target;
    setFilterLabel(value);
  }, []);

  const saveFilterLabel = useCallback(() => {
    if (!filterLabel || filter.label === filterLabel) return;

    const _filter = produce(filter, draft => {
      draft.label = filterLabel;
    });

    handler.updateFilter(_filter);
  }, [handler, filterLabel, filter]);

  return (
    <div className={classNames('node', { 'animate__animated animate__fadeOut': fade })}>
      <i className='fal fa-grip-vertical' />

      <span className='node__title'>
        <Input
          type='text'
          id={`splitLabel`}
          name='label'
          value={filterLabel}
          placeholder={placeholder}
          onChange={changeFilterLabel}
          onBlur={saveFilterLabel}
        />
      </span>

      <ButtonGroup size={'sm'}>
        {/** EDIT FILTER */}
        <Button
          id={'edit-filter-' + filter.id}
          outline={true}
          color={'secondary'}
          onClick={() => handler.setSelectedFilter(filter.id)}
        >
          <i className='fal fa-edit' />
        </Button>
        <UncontrolledTooltip target={'edit-filter-' + filter.id}>
          <FormattedMessage id={'smoove.page.common.filters.edit-filters'} defaultMessage={'Edit filter'} />
        </UncontrolledTooltip>

        {/** DELETE FILTER */}
        <Button
          id={'delete-filter-' + filter.id}
          outline={true}
          color={'danger'}
          onClick={() => {
            setFade(true);
            handler.removeFilter(filter.id);
          }}
        >
          <i className='fal fa-trash-alt' />
        </Button>
        <UncontrolledTooltip target={'delete-filter-' + filter.id}>
          {intl.formatMessage({ id: 'smoove.page.project.results.delete-filter' })}
        </UncontrolledTooltip>
      </ButtonGroup>
    </div>
  );
});

export const SelectedListElementInvalid = React.memo(({ filter, handler }) => {
  const intl = useIntl();

  const [fade, setFade] = useState(false);

  return (
    <div className={classNames('node', { 'animate__animated animate__fadeOut': fade })}>
      <i className='fal fa-grip-vertical' />

      <span className='node__title'>
        {intl.formatMessage({ id: 'smoove.page.reports.modals.filters.invalid-element' })}
      </span>

      <ButtonGroup size={'sm'}>
        {/** DELETE FILTER */}
        <Button
          id={'delete-filter-' + filter.id}
          outline={true}
          color={'danger'}
          onClick={() => {
            setFade(true);
            handler.removeFilter(filter.id);
          }}
        >
          <i className='fal fa-trash-alt' />
        </Button>
        <UncontrolledTooltip target={'delete-filter-' + filter.id}>
          {intl.formatMessage({ id: 'smoove.page.project.results.delete-filter' })}
        </UncontrolledTooltip>
      </ButtonGroup>
    </div>
  );
});
