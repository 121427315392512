import produce from 'immer';
import { settingsActionTypes } from '../constants';

const INITIAL_STATE = {};

export const settingsReducerClient = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case settingsActionTypes.SMOOVE_SETTINGS_INIT_SUCCESS:
      return produce(state, draft => {
        const { wizards } = payload.clientSettings;
        draft.wizards = wizards ?? { list: {}, order: [] };
      });

    // case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
    //   return produce(state, draft => {
    //     // todo: is this obsolete after refactoring using colorscales directly from client instead of clientSettings?
    //     const { colorScales } = payload.clientSettings;
    //     draft.colorScales = colorScales;
    //   });

    default:
      return state;
  }
};
