/**
 * Checks if the currently logged in user has the permission for the passed client or project permissionName. Returns boolean
 *
 * @param {object} user the user the role should be found for
 * @param {object} client the client the role is defined in
 * @param {object} projectId id of the project the role belongs to
 *
 * @returns {object} the role-object of the selected projectRole which is defined in the client projectRoles
 */

export const getUserProjectRole = (user, client, projectId) => {
  if (!user || !client || !projectId) return null;

  const clientProjectRoles = client?.roles?.projectRoles;
  const userProjectRoleId = user.clientRoles?.[client.id]?.projectRoles?.[projectId]?.role;

  if (!clientProjectRoles) return null;
  return clientProjectRoles.find(el => el.id === userProjectRoleId);
};
