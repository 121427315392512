import html2canvas from 'html2canvas';
import { downloadUrl } from '../redux/actions/helper';

export const exportDomElementAsImage = async element => {
  const name = 'smoove_export';
  const format = 'png';
  const quality = 1;

  const options = {
    scale: 5,
    logging: false
  };

  // recharts renders legend in separate svg elements without defs block.
  // find defs block with gradients in provided element and attach it to all other svgs
  element = applyDefsToLegendSvgs(element);

  /**
   * @link https://html2canvas.hertzen.com/configuration
   */
  await html2canvas(element, options)
    .then(canvas => {
      const file = canvas.toDataURL(`image/${format}`, format, quality);
      downloadUrl(file, name);
    })
    .catch(e => {
      console.log(e);
    });
};

const applyDefsToLegendSvgs = element => {
  // find the first defs element that contains linearGradient as a child
  let defs = null;
  let defsSVG = null;

  const svgElements = element.querySelectorAll('svg');

  for (let svg of svgElements) {
    const defsElements = svg.querySelectorAll('defs');

    for (let defsTmp of defsElements) {
      if (defsTmp.querySelector('linearGradient')) {
        defs = defsTmp;
        defsSVG = svg;
        break;
      }
    }
    if (defs) break;
  }

  // attach found defs element to all other svg elements
  if (defs) {
    svgElements.forEach(svg => {
      if (svg !== defsSVG) {
        const clonedDef = defs.cloneNode(true);
        svg.appendChild(clonedDef);
      }
    });
  }

  return element;
};
