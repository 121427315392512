import { isEqual, omit } from 'lodash';

/**
 * Searches for a fixed color value for the given cellIdentifier.
 *
 * Returns a color with the highest cellIdentifier match or false, if no color found.
 *
 * @param {*} chartConfig
 * @param {{
 *  rowId?: string
 *  rowSubId?: string
 *  rowSubSubId?: string
 *  headId?: string
 *  headSubId?: string
 * }} cellIdentifier
 *
 * @returns {
 *  hsl?: { h: number, s: number, l: number, a: number }
 *  hex: string
 *  rgb: { r: number, g: number, b: number, a: number }
 *  hsv: { h: number, s: number, v: number, a: number }
 *  oldHue: number
 *  source: string
 * }
 */
export const getFixedColor = (chartConfig, cellIdentifier) => {
  const fixedColorTypes = {
    cellColor: null,
    rowColor: null,
    headColor: null
  };

  chartConfig.colorsFixed.forEach(_c => {
    const { color, ..._cellIdentifier } = _c;

    if (isEqual(_cellIdentifier, cellIdentifier)) {
      fixedColorTypes.cellColor = color;
    } else if (
      _cellIdentifier.rowId &&
      isEqual(omit(_cellIdentifier, ['headId', 'headSubId']), omit(cellIdentifier, ['headId', 'headSubId']))
    ) {
      fixedColorTypes.rowColor = color;
    } else if (
      _cellIdentifier.headId &&
      isEqual(
        omit(_cellIdentifier, ['rowId', 'rowSubId', 'rowSubSubId']),
        omit(cellIdentifier, ['rowId', 'rowSubId', 'rowSubSubId'])
      )
    ) {
      fixedColorTypes.headColor = color;
    }
  });

  if (fixedColorTypes.cellColor) return fixedColorTypes.cellColor;
  else if (fixedColorTypes.rowColor) return fixedColorTypes.rowColor;
  else if (fixedColorTypes.headColor) return fixedColorTypes.headColor;
  else return false;
};
