import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Col, Row, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ConfirmAction } from 'smv-components';
import { surveyActions } from 'smv-redux';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

import './SurveyStatus.scss';

const DUMMYDATA_CASES_DEFAULT = 100;
const DUMMYDATA_CASES_MAX = 1000000;

export const SurveyStatus = ({ handler }) => {
  const survey = useSelector(state => state.survey ?? {});
  const projectId = useSelector(state => state.project.id ?? {});
  const intl = useIntl();

  const questbackEntryLink = useMemo(() => {
    return survey?.questback?.entryURL ?? '';
  }, [survey?.questback?.entryURL]);

  const questbackTestingLink = useMemo(() => {
    return survey?.questback?.testingURL ?? '';
  }, [survey?.questback?.testingURL]);

  const questionnaire = useSelector(state => state.survey.questionnaire ?? {});
  const conflicts = questionnaire.conflicts ?? false;

  const questionIdsWithConflicts = [];
  if (!!conflicts) {
    Object.keys(conflicts).map(el => questionIdsWithConflicts.push(el));
  }

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);
  const isUserProjectAdmin = useUserHasPermission(permissionEnum.PROJECT_ADMIN);

  const statuscode = useMemo(() => survey?.status ?? 'draft', [survey?.status]);

  // // surveyStatus is used only in setSurveyStatus dropdown!
  const [surveyStatus, setSurveyStatus] = useState(statuscode);
  const handleSurveyStatusChange = useCallback(value => setSurveyStatus(value), []);

  // eslint-disable-next-line
  const [isPaused, setIsPaused] = useState(false);
  const [dummyDataCases, setDummyDataCases] = useState(DUMMYDATA_CASES_DEFAULT);
  const [statusTimelineClasses, setStatusTimelineClasses] = useState();

  const handleDummyDataCases = useCallback(e => {
    const cases = parseInt(e.target.value);
    if (isNaN(cases) || cases <= 0 || cases > DUMMYDATA_CASES_MAX) setDummyDataCases(DUMMYDATA_CASES_DEFAULT);
    else setDummyDataCases(cases);
  }, []);

  const handleGenerateDummyData = useCallback(() => {
    setDummyDataCases(cases => {
      if (cases > 0 && cases <= DUMMYDATA_CASES_MAX) {
        handler.generateDummyData(cases);
        return DUMMYDATA_CASES_DEFAULT;
      }

      return cases;
    });
  }, [handler]);

  const handleSaveSurveyStatus = useCallback(
    surveyStatus => {
      surveyActions.updateSurvey(projectId, survey.id, { status: surveyStatus });
    },
    [survey.id, projectId]
  );

  const handleResetToDraftMode = useCallback(() => {
    surveyActions.updateSurvey(projectId, survey.id, {
      status: 'draft',
      questback: {
        id: null
      }
    });
  }, [survey.id, projectId]);

  useEffect(() => {
    setStatusTimelineClasses(
      classNames('status-timeline', {
        'status-timeline--wizard': statuscode === 'wizard',
        'status-timeline--draft': statuscode === 'draft',
        'status-timeline--testing': statuscode === 'testing',
        'status-timeline--running': statuscode === 'running' && !isPaused,
        'status-timeline--paused': statuscode === 'running' && isPaused,
        'status-timeline--complete': statuscode === 'completed'
      })
    );
  }, [isPaused, statuscode]);

  const isButtonDisabled = survey?.loading ?? false;
  const isLaunchButtonDisabled = (survey?.loading || !!conflicts) ?? false;

  const isGenerateRandomDataButtonDisabled =
    (survey?.loading || dummyDataCases <= 0 || dummyDataCases > DUMMYDATA_CASES_MAX) ?? false;

  return (
    <div className='smv-survey-status'>
      <div className={statusTimelineClasses}>
        <div
          className={`timeline-bubble ${
            ['wizard', 'draft', 'testing', 'running', 'completed'].includes(statuscode) ? 'timeline-bubble--active' : ''
          }`}
        >
          <span className='timeline-bubble__label'>
            {intl.formatMessage({ id: 'smoove.page.projects.status.wizard' })}
          </span>
        </div>
        <div
          className={`timeline__line ${
            ['draft', 'testing', 'running', 'completed'].includes(statuscode) ? 'timeline__line--active' : ''
          }`}
        ></div>
        <div
          className={`timeline-bubble ${
            ['draft', 'testing', 'running', 'completed'].includes(statuscode) ? 'timeline-bubble--active' : ''
          }`}
        >
          <span className='timeline-bubble__label'>
            {intl.formatMessage({ id: 'smoove.page.projects.status.draft' })}
          </span>
        </div>
        <div
          className={`timeline__line ${
            ['testing', 'running', 'completed'].includes(statuscode) ? 'timeline__line--active' : ''
          }`}
        ></div>
        <div
          className={`timeline-bubble ${
            ['testing', 'running', 'completed'].includes(statuscode) ? 'timeline-bubble--active' : ''
          }`}
        >
          <span className='timeline-bubble__label'>
            {intl.formatMessage({ id: 'smoove.page.projects.status.testing' })}
          </span>
        </div>
        <div
          className={`timeline__line ${['running', 'completed'].includes(statuscode) ? 'timeline__line--active' : ''}`}
        ></div>
        <div
          className={`timeline-bubble ${
            ['running', 'completed'].includes(statuscode) ? 'timeline-bubble--active' : ''
          }`}
        >
          <span className='timeline-bubble__label'>
            {intl.formatMessage({ id: 'smoove.page.projects.status.running' })}
            <br />
            {isPaused && intl.formatMessage({ id: 'smoove.page.projects.status.paused' })}
          </span>
        </div>
        <div className={`timeline__line ${statuscode === 'completed' ? 'timeline__line--active' : ''}`}></div>
        <div className={`timeline-bubble ${statuscode === 'completed' ? 'timeline-bubble--active' : ''}`}>
          <span className='timeline-bubble__label'>
            {intl.formatMessage({ id: 'smoove.page.projects.status.completed' })}
          </span>
        </div>
      </div>
      {userCanEditProject && (
        <div className='status-actions'>
          {statuscode === 'draft' && (
            <Button color='primary' className='mr-2' onClick={handler.startTesting} disabled={isLaunchButtonDisabled}>
              <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.to-testing' })}</span>
              <i className='fal fa-arrow-right ml-2' />
            </Button>
          )}
          {statuscode === 'testing' && (
            <>
              <Button
                color='secondary'
                className='mr-2'
                onClick={handleResetToDraftMode}
                disabled={isLaunchButtonDisabled}
              >
                <i className='fal fa-arrow-left mr-2' />
                <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.back-to-draft-mode' })}</span>
              </Button>
              <Button color='primary' className='mr-2' onClick={handler.launchSurvey} disabled={isLaunchButtonDisabled}>
                <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.approve-and-launch' })}</span>
                <i className='fal fa-arrow-right ml-2' />
              </Button>
            </>
          )}
          {statuscode === 'running' && (
            <>
              <Button
                color='secondary'
                className='mr-2'
                onClick={() => handleSaveSurveyStatus('testing')}
                disabled={isLaunchButtonDisabled}
              >
                <i className='fal fa-arrow-left mr-2' />
                <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.back-to-test-mode' })}</span>
              </Button>
              <Button
                color='primary'
                className='mr-2'
                onClick={() => handleSaveSurveyStatus('completed')}
                disabled={isLaunchButtonDisabled}
              >
                <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.finish-survey' })}</span>
                <i className='fal fa-arrow-right ml-2' />
              </Button>
            </>
          )}
          {statuscode === 'completed' && (
            <>
              <Button
                color='secondary'
                className='mr-2'
                onClick={() => handleSaveSurveyStatus('running')}
                disabled={isLaunchButtonDisabled}
              >
                <i className='fal fa-arrow-left mr-2' />
                <span>{intl.formatMessage({ id: 'smoove.page.survey.general.button.back-to-running' })}</span>
              </Button>
            </>
          )}
          {/* isSmooveAdmin? */}
          {isUserProjectAdmin && ['draft', 'testing', 'running', 'completed'].includes(statuscode) && (
            <ConfirmAction
              title={intl.formatMessage({
                id: `smoove.page.project.general.survey-status.confirm.title`,
                defaultMessage: 'Change survey status'
              })}
              size={'md'}
              body={
                <>
                  <Row>
                    <Col>
                      {intl.formatMessage({
                        id: `smoove.page.project.general.survey-status.confirm.text`,
                        defaultMessage: ''
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type={'select'}
                        // label={intl.formatMessage({ id: `smoove.page.tables.table-config.value-type` })}
                        id={`surveyStatus`}
                        name='surveyStatus'
                        value={surveyStatus}
                        onChange={e => handleSurveyStatusChange(e.target.value)}
                      >
                        <option value={'draft'}>
                          {intl.formatMessage({ id: 'smoove.page.projects.status.draft' })}
                        </option>
                        <option value={'testing'}>
                          {intl.formatMessage({ id: 'smoove.page.projects.status.testing' })}
                        </option>
                        <option value={'running'}>
                          {intl.formatMessage({ id: 'smoove.page.projects.status.running' })}
                        </option>
                        <option value={'completed'}>
                          {intl.formatMessage({ id: 'smoove.page.projects.status.completed' })}
                        </option>
                      </Input>
                    </Col>
                  </Row>
                </>
              }
              callback={() => handleSaveSurveyStatus(surveyStatus)}
              btnColor={'secondary'}
              btnText={intl.formatMessage({
                id: `smoove.page.project.general.survey-status.confirm.action`,
                defaultMessage: 'save'
              })}
            >
              {openModal => (
                <Button color={'secondary'} outline className={'mr-2'} onClick={openModal} disabled={isButtonDisabled}>
                  {intl.formatMessage({
                    id: 'smoove.page.project.general.survey-status.confirm.button',
                    defaultMessage: 'Change survey status'
                  })}
                </Button>
              )}
            </ConfirmAction>
          )}
          {survey.loading && <i className='fas fa-spinner fa-spin' />}
        </div>
      )}
      {isUserProjectAdmin && ['draft', 'testing', 'running'].includes(statuscode) && (
        <div className='mt-2'>
          <ConfirmAction
            title={intl.formatMessage({ id: `smoove.page.project.general.dummy-data.confirm.title` })}
            size={'md'}
            body={
              <>
                <Row>
                  <Col>{intl.formatMessage({ id: `smoove.page.project.general.dummy-data.confirm.text` })}</Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type={'number'}
                      value={dummyDataCases}
                      onChange={handleDummyDataCases}
                      min={1}
                      max={DUMMYDATA_CASES_MAX}
                    />
                  </Col>
                </Row>
              </>
            }
            callback={handleGenerateDummyData}
            btnColor={'secondary'}
            btnText={intl.formatMessage({ id: `smoove.page.project.general.dummy-data.confirm.button` })}
            disabled={isGenerateRandomDataButtonDisabled}
          >
            {openModal => (
              <Button color={'secondary'} className={'mr-2'} onClick={openModal} disabled={isButtonDisabled}>
                {intl.formatMessage({
                  id: 'smoove.page.project.general.dummy-data.button',
                  defaultMessage: 'Generate random data'
                })}
              </Button>
            )}
          </ConfirmAction>
        </div>
      )}

      {['testing', 'running'].includes(statuscode) && questbackTestingLink && (
        <div className='mt-4 mb-2'>
          <span className='mr-2'>{intl.formatMessage({ id: 'smoove.page.survey.general.test-link' })}</span>
          <TestlinkSelector url={questbackTestingLink ?? ''} testing={true} />
        </div>
      )}
      {['running'].includes(statuscode) && questbackEntryLink && (
        <div className='mt-2 mb-2'>
          <span className='mr-2'>{intl.formatMessage({ id: 'smoove.page.survey.general.live-link' })}</span>
          <TestlinkSelector url={questbackEntryLink ?? ''} />
        </div>
      )}
      {!!conflicts && (
        <div className='my-2 text-warning'>
          {intl.formatMessage({ id: 'smoove.page.project.general.survey-status.survey-cant-be-launced-warning' })}
        </div>
      )}
      {!!conflicts && (
        <ul>
          {questionIdsWithConflicts.map(id => {
            return (
              <li key={id}>
                <Link to={`/one/projects/${projectId}/surveys/${survey.id}/que/${id}/config`}>
                  {questionnaire.list[id]?.config?.varname} - {questionnaire.list[id]?.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const TestlinkSelector = ({ url, testing = false }) => {
  const intl = useIntl();

  const locales = useSelector(state => state.survey.locales);

  const [isLocaleDropdownOpen, setIsLlcaleDropdownOpen] = useState(false);
  const [localeForLink, setLocaleForLink] = useState(locales.list?.[locales?.main] ?? null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setLocaleForLink(locales.list?.[locales?.main]);
  }, [locales]);

  const handleSetLocaleForLink = useCallback(locale => {
    setLocaleForLink(locale);
  }, []);

  const urlWithParameters = url
    ? `${url + (url.indexOf('?') === -1 ? '?' : '&')}a=${localeForLink?.locale ?? ''}${testing ? '&b=9999' : ''}`
    : '';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlWithParameters);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 1200);
  };

  const openInNewTab = () => {
    window.open(urlWithParameters, '_blank');
  };

  return (
    <div className='url-input'>
      <InputGroup>
        {localeForLink && (
          <Dropdown
            id='questionselect'
            isOpen={isLocaleDropdownOpen}
            size='sm'
            toggle={() => setIsLlcaleDropdownOpen(state => !state)}
            className='jumpto-dropdown'
          >
            <DropdownToggle caret outline color='secondary'>
              <span className={`flag-icon flag-icon-${localeForLink.iso2.toLowerCase()} mr-2`}></span>
              <span>{localeForLink.label}</span>
              {locales.main === localeForLink.locale && <i className='fas fa-star ml-2 text-secondary' />}
            </DropdownToggle>
            <DropdownMenu>
              {(locales?.order ?? [])?.map((id, idx) => {
                const _locale = locales.list[id];
                if (!_locale || _locale?.reportingOnly) return null;
                return (
                  <DropdownItem key={`${id}_${idx}`} onClick={() => handleSetLocaleForLink(_locale)}>
                    <span>
                      <i className={`flag-icon flag-icon-${_locale.iso2.toLowerCase()} mr-2`}></i>
                      <span>{_locale.label}</span>
                      {locales.main === _locale.locale && <i className='fas fa-star ml-2 text-secondary' />}
                    </span>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        )}
        <Input
          type='text'
          className='pointer'
          value={urlWithParameters}
          readOnly
          onClick={copyToClipboard}
          title={urlWithParameters}
        />
        <Button color='primary' onClick={copyToClipboard}>
          <i className='fal fa-copy'></i>
        </Button>
        <Button color='secondary' onClick={openInNewTab}>
          <i className='fal fa-up-right-from-square'></i>
        </Button>
      </InputGroup>
      {showNotification && (
        <div className='copied-notification'>
          {intl.formatMessage({ id: 'smoove.page.survey.general.link-copied' })}
        </div>
      )}
    </div>
  );
};
