import { getMediaElementType } from '../components/questionnaire/Content/MediaLibrary/helpers/getMediaElementType';

/**
 * Converts an html string with img|video|audio-Tags to a string with img-placeholders.
 *
 * @param {string} questionText
 *
 * @returns {string, object} Returns a text-string with placeholders like this [[image||ab12bf]] for img|video|audio-Tags and a config-object containing detailed media information to save to the question element
 */
export const parseImagesAndSetPlaceholders = (questionText, question, media) => {
  const whitelistAttributes = ['width', 'height', 'style'];

  let mediaConfigs = {};
  const mediaRegex = /<(img|audio|video)[\w\s"=\-.:;,+/]+>/g;

  const newQuestionText = questionText.replaceAll(mediaRegex, match => {
    const img = getImageElement(match);
    const queMediaId = img.getAttribute('smv-media-id');
    const sourceId = img.getAttribute('smv-media-source-id');
    const surveyMediaItem = media?.[sourceId];
    const type = getMediaElementType(surveyMediaItem);

    if (!queMediaId) return '';
    else {
      mediaConfigs[queMediaId] = { config: {} };
      for (const attr of img.attributes) {
        if (attr.name === 'smv-media-id') {
          mediaConfigs[queMediaId]['id'] = attr.value;
        } else if (attr.name === 'smv-media-source-id') {
          mediaConfigs[queMediaId]['source'] = attr.value;
        } else if (attr.name === 'lightbox') {
          mediaConfigs[queMediaId]['lightbox'] = attr.value;
        } else if (whitelistAttributes.includes(attr.name)) {
          mediaConfigs[queMediaId]['config'][attr.name] = attr.value;
        }
      }
      return `[[${type}|${queMediaId}]]`;
    }
  });

  return [newQuestionText, mediaConfigs];
};

const getImageElement = html => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();

  return template.content.firstChild;
};
