export const defaultNotifyProps = {
  insert: 'bottom',
  container: 'bottom-center',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 3000,
    showIcon: true
  }
};
