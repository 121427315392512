export const getHeadBaseValue = (tableResult, headid, headchoiceid, metric) => {
  if (!tableResult || !tableResult?.values) return '-';

  const bases = [];

  tableResult?.order?.forEach(tableRow => {
    const { rowid, subelement, subsubelement } = tableRow;

    let elementbase = tableResult.values?.[rowid]?.[subelement] ?? {};
    if (subsubelement) elementbase = tableResult.values?.[rowid]?.[subelement][subsubelement] ?? {};

    if (!!elementbase?.[headid]?.[headchoiceid]?.[metric])
      bases.push(parseInt(elementbase[headid][headchoiceid][metric], 10));
  });

  if (bases.length <= 0) return '0';

  const min = Math.min(...bases);
  const max = Math.max(...bases);

  if (min !== max) return `${min}-${max}`;

  return max;
};
