import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import { Link, generatePath, useRouteMatch } from 'react-router-dom';

import { EditableTextHover } from '../../../..';
import { getPreviousQuestions, useSubelementPropertyTranslation } from 'smv-helpers';
import { questionnaireActions } from '../../../../../../../redux/actions';

import './SharedChoicesConfig.scss';

export const SharedChoicesConfig = ({ question, writeable }) => {
  // we could use this instead of prop-drilling the sharedChoices through the components - they go from Content.js down to the query builder. refactor? tbd...
  const surveyid = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);

  const intl = useIntl();

  const internalHandler = {
    setSource: source => {
      if (source === undefined || source === '') {
        questionnaireActions.removeChoicesImport(surveyid, question.id);
      } else {
        questionnaireActions.updateChoicesImport(surveyid, question.id, source ? { source } : {});
      }
    }
  };

  const sourceQuestions = useMemo(() => getPreviousQuestions(questionnaire, question.id), [questionnaire, question.id]);

  return (
    <div>
      {/* Source Question */}
      <span>
        <Input
          disabled={!writeable}
          type='select'
          name={'sharedChoicesSource'}
          value={question.config?.choices?.import?.source || ''}
          onChange={e => internalHandler.setSource(e.target.value)}
        >
          <option value={''}>
            {intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.select-source-question` })}
          </option>
          {sourceQuestions.map((source, idx) => (
            <option key={`sharedChoiceSource_${source.id}_${idx}`} value={source.id}>
              {source.title}
            </option>
          ))}
        </Input>
      </span>

      <SharedChoiceList question={question} writeable={writeable} />
    </div>
  );
};

const SharedChoiceList = ({ question, writeable }) => {
  const list = useSelector(state => state.survey.questionnaire.list);
  const isExpertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);

  const { path } = useRouteMatch();

  if (!question.config?.choices?.import?.source) return null;

  const sourceQuestion = list[question.config?.choices?.import?.source];
  const sourcePath = `${path.split('/que/')[0]}/que/${sourceQuestion.id}?ref=${question.id}`;

  return (
    <div className='shared-choices-list'>
      <div className='shared-choices-list__head mt-2'>
        <div>Name</div>
        {isExpertMode && <div>Value</div>}
      </div>
      <div className='shared-choices-list__choices-container'>
        {sourceQuestion?.config?.choices?.order?.map(choiceid => {
          const sharedChoice = sourceQuestion.config.choices.list[choiceid];
          return <SharedChoice key={choiceid} questionId={question.id} choice={sharedChoice} sourcePath={sourcePath} />;
        })}
      </div>
      <div className='text-right'>
        <Link to={generatePath(sourcePath)} className='pointer'>
          <small>Edit source question</small>
        </Link>
      </div>
    </div>
  );
};

export const SharedChoice = ({ sourcePath, questionId, choice, writeable }) => {
  const isExpertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useShortcodes: false,
    useStripped: !writeable,
    useFallback: !writeable
  });

  const questionnaireConflicts = useSelector(state => state.survey.questionnaire?.conflicts);
  const hasConflicts = questionnaireConflicts?.[questionId]?.importChoices?.includes(choice.id);

  let valueClasses = classNames('shared-choice__value', {
    'shared-choice__value--warning': hasConflicts
  });

  return (
    <div className='d-flex align-items-center shared-choice'>
      <i className='fal fa-fw fa-arrow-turn-down-right mr-1'></i>
      <EditableTextHover value={choiceLabel} writeable={false} className='no-padding' />
      {isExpertMode && hasConflicts && (
        <Link to={generatePath(sourcePath)} className='pointer'>
          <div className={valueClasses}>{choice.value}</div>
        </Link>
      )}
      {!hasConflicts && <div className={valueClasses}>{choice.value}</div>}
    </div>
  );
};
