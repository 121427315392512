import { useMemo } from 'react';

export const isLooped = (questionnaireList, element) => {
  const loopParents = [];

  const checkLoopRecursive = (questionnaireList, element) => {
    const parentId = element?.parentId;
    const parent = questionnaireList?.[parentId];
    if (!parent) return;

    const loopSourceId = parent?.config?.loop?.source;

    if (loopSourceId && !!questionnaireList?.[loopSourceId]) {
      loopParents.push(parent);
    }

    if (parent.parentId) checkLoopRecursive(questionnaireList, parent);
  };

  checkLoopRecursive(questionnaireList, element);

  return { isLoopedQuestion: loopParents.length > 0, loopParents };
};

export const useIsLooped = (questionnaireList, element) =>
  useMemo(() => isLooped(questionnaireList, element), [questionnaireList, element]);
