import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Collapse, FormGroup, Input, Table, UncontrolledTooltip } from 'reactstrap';
import { uniqBy } from 'lodash';

import { ConfirmAction } from 'smv-components';
import { permissionEnum, reorderArray, useUserHasPermission } from 'smv-helpers';
import { projectSettingsActions, surveyActions } from 'smv-redux';
import { SearchInput } from '../misc/SearchInput';

import './SurveyLocalesList.scss';

export const SurveyLocalesList = () => {
  const survey = useSelector(state => state.survey);
  const client = useSelector(state => state.clients.list[state.clients.selected]);
  const projectid = useSelector(state => state.survey.projectid);
  const surveyid = useSelector(state => state.survey.id);

  // const intl = useIntl();

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const [filteredLocales, setFilteredLocales] = useState([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const unselectedLanguages = useMemo(() => {
    return Object.values(client?.locales ?? [])?.filter(el => {
      return !survey.locales?.order?.includes(el.locale);
    });
  }, [survey.locales?.order, client?.locales]);

  useEffect(() => {
    const _items = [];
    const searchInputValue = document.getElementById('locales-search-input')?.value ?? '';
    ['locale', 'iso3', 'language', 'country'].forEach(val => {
      _items.push(
        ...unselectedLanguages.filter(item => item[val].toLowerCase().includes(searchInputValue.toLowerCase()))
      );
    });
    setFilteredLocales(uniqBy(_items, 'locale'));
  }, [unselectedLanguages]);

  const patchSurvey = useCallback(
    element => {
      surveyActions.updateSurvey(projectid, surveyid, element);
    },
    [projectid, surveyid]
  );

  const [itemOrder, setItemOrder] = useState(survey.locales.order ?? []);
  useEffect(() => {
    if (survey?.locales?.order) {
      setItemOrder(survey.locales.order);
    }
  }, [survey?.locales?.order]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const newOrder = reorderArray(itemOrder, result.source.index, result.destination.index);

    setItemOrder(newOrder);

    patchSurvey({
      locales: {
        ...survey.locales,
        order: newOrder
      }
    });
  };

  const handleAddLocale = useCallback(
    locale => {
      if (!userCanEditProject) return;

      const _locales = {
        ...survey.locales,
        list: {
          ...(survey.locales.list ?? {}),
          [locale.locale]: locale
        },
        order: [...(survey.locales.order ?? []), locale.locale],
        main: survey.locales.main ?? locale.locale
      };

      surveyActions.updateSurvey(projectid, surveyid, { locales: _locales });
      projectSettingsActions.setQLocale(surveyid, _locales.main);
    },
    [survey.locales, surveyid, userCanEditProject, projectid]
  );

  return (
    <>
      <Table className='survey-country-table'>
        <thead>
          <tr>
            <th style={{ width: '5%' }}></th>
            <th style={{ width: '40%' }}>Country</th>
            <th style={{ width: '10%' }}>Main Language</th>
            <th style={{ width: '10%' }}>Reporting Only</th>
            {survey.includeSampling && <th style={{ width: '15%' }}>Completes</th>}
            {survey.includeSampling && <th style={{ width: '15%' }}>Incidence</th>}
            <th style={{ width: '5%' }}></th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'survey-locales-droppable'}>
            {(provided, snapshot) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
                // className={'droppable-default'}
              >
                {(itemOrder ?? []).map((locale, index) => {
                  if (!locale) return null;
                  return (
                    <CountryRow
                      country={survey?.locales?.list[locale]}
                      key={locale}
                      index={index}
                      patchSurvey={patchSurvey}
                    />
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      {unselectedLanguages.length > 0 && userCanEditProject && (
        <>
          <div
            className='d-flex align-items-center mb-2 pointer text-secondary'
            onClick={() => setIsCollapseOpen(b => !b)}
          >
            <i className={`fal fa-chevron-${isCollapseOpen ? 'down' : 'right'} fa-fw ml-auto`} />
            <span className='ml-2'>Add language</span>
          </div>
          <Collapse isOpen={isCollapseOpen}>
            <div className='locales-list__unselected'>
              <div className='locales-list__unselected-search'>
                <SearchInput
                  allItems={unselectedLanguages}
                  filterValues={['locale', 'iso3', 'language', 'country']}
                  setFilteredItems={setFilteredLocales}
                  placeholderText={'search'}
                  inputId={'locales-search-input'}
                />
              </div>
              <div className='locales-list__unselected-list'>
                {filteredLocales.map(locale => (
                  <div
                    key={locale?.locale}
                    onClick={() => {
                      handleAddLocale(locale);
                    }}
                    className='locales-list__unselected-locale'
                  >
                    <i className={`fal fa-plus locales-list__add-locale-btn mr-2`} />
                    <i className={`flag-icon flag-icon-${locale.iso2.toLowerCase()} mr-2`} />
                    <span>
                      {locale?.label} ({locale.locale})
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

const CountryRow = ({ country, index, patchSurvey }) => {
  const intl = useIntl();

  const survey = useSelector(state => state.survey);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[survey.id]?.selectedLocale ?? state.survey.locales?.main ?? null
  );

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const [isMainLanguage, setIsMainLanguage] = useState(country?.locale === survey.locales.main);
  const [isReportingOnly, setIsReportingOnly] = useState(country?.reportingOnly);
  const [completes, setCompletes] = useState(country?.completes);
  const [incidence, setIncidence] = useState(country?.incidence);

  useEffect(() => {
    setIsReportingOnly(country?.reportingOnly);
  }, [country?.reportingOnly]);

  useEffect(() => {
    setIsMainLanguage(country?.locale === survey.locales.main);
  }, [survey.locales.main, country?.locale]);

  const handleUpdateSamplingGeneric = useCallback(
    (locale, element, value) => {
      const _locales = produce(survey.locales, draftState => {
        draftState.list[locale][element] = value;
      });
      patchSurvey({ locales: _locales });
    },
    [survey.locales, patchSurvey]
  );

  const handleSetMainLanguage = useCallback(
    locale => {
      patchSurvey({
        locales: {
          ...survey.locales,
          main: locale
        }
      });
    },
    [survey.locales, patchSurvey]
  );

  const removeLanguage = useCallback(
    locale => {
      if (!userCanEditProject) return;
      let _locales = { ...survey.locales };

      if (_locales.order.includes(locale)) {
        delete _locales.list[locale];
        _locales.order = _locales.order.filter(el => el !== locale);
      }
      if (_locales.main === locale) {
        _locales.main = _locales.order[0];
      }
      patchSurvey({ locales: _locales });

      let _selectedLocale = selectedLocale;
      if (locale === selectedLocale) {
        _selectedLocale = _locales.order[0] ?? null;
      }
      projectSettingsActions.setQLocale(survey.id, _selectedLocale);
    },
    [patchSurvey, selectedLocale, survey.locales, survey.id, userCanEditProject]
  );

  if (!survey?.locales) return null;

  const countriesToReport = [];
  Object.keys(survey.locales?.list ?? {}).forEach(el => {
    if (!survey.locales.list[el].reportingOnly) countriesToReport.push(el);
  });

  if (!country) return null;

  return (
    <Draggable draggableId={country?.locale} index={index}>
      {(provided, snapshot) => (
        <tr
          key={country.locale}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className='survey-country-row'
        >
          <td>
            <span className='fal fa-grip-vertical' style={{ cursor: 'grab' }}></span>
          </td>
          <td>
            <span className={`flag-icon flag-icon-${country.iso2.toLowerCase()} mr-2`} />
            <span>
              {country.label} ({country.locale})
            </span>
          </td>
          <td>
            <div className='pointer' onClick={() => handleSetMainLanguage(country.locale)}>
              <span className={`fa${isMainLanguage ? 's' : 'l'} fa-star text-secondary`}></span>
            </div>
          </td>
          <td>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`toggleIsReportingOnly`}
                name='toggleIsReportingOnly'
                className='pointer'
                checked={isReportingOnly}
                disabled={!country.reportingOnly && countriesToReport.length <= 1}
                onChange={() => handleUpdateSamplingGeneric(country.locale, 'reportingOnly', !isReportingOnly)}
              />
            </FormGroup>
          </td>
          {survey.includeSampling && (
            <td>
              {!isReportingOnly && (
                <Input
                  id={`completes_input_value_${country.locale}`}
                  bsSize='sm'
                  type='number'
                  disabled={!survey.includeSampling}
                  value={completes}
                  onChange={e => setCompletes(e.target.value)}
                  onBlur={e => handleUpdateSamplingGeneric(country.locale, 'completes', parseInt(e.target.value))}
                  min={0}
                  style={{ width: '100%' }}
                  className='mr-1'
                />
              )}
              {isReportingOnly && <div>-</div>}
            </td>
          )}
          {survey.includeSampling && (
            <td>
              {!isReportingOnly && (
                <div className='d-flex align-items-center'>
                  <Input
                    id={`incidence_input_value_${country.locale}`}
                    bsSize='sm'
                    type='number'
                    disabled={!survey.includeSampling}
                    value={incidence}
                    onChange={e => setIncidence(e.target.value)}
                    onBlur={e => handleUpdateSamplingGeneric(country.locale, 'incidence', parseInt(e.target.value))}
                    min={0}
                    max={100}
                    style={{ width: '100%' }}
                    className='mr-1'
                  />
                  <span>%</span>
                </div>
              )}
              {isReportingOnly && <div>-</div>}
            </td>
          )}
          <td>
            <ConfirmAction
              title={intl.formatMessage({ id: `smoove.page.survey.general.survey-languages.remove` })}
              size={'md'}
              body={
                <div>
                  {intl.formatMessage({ id: `smoove.page.survey.general.survey-languages.remove-confirm-text` })}
                </div>
              }
              callback={() => removeLanguage(country.locale)}
            >
              {confirm => (
                <div className='locales-list__remove' onClick={confirm}>
                  <i id={`tt_${country.locale}-delete`} className='far fa-trash-alt text-danger pointer'></i>
                  <UncontrolledTooltip target={`tt_${country.locale}-delete`} delay={{ hide: 500 }} autohide={false}>
                    {intl.formatMessage({ id: 'smoove.page.survey.general.survey-languages.remove' })}
                  </UncontrolledTooltip>
                </div>
              )}
            </ConfirmAction>
          </td>
        </tr>
      )}
    </Draggable>
  );
};
