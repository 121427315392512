import React from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { questionnaireActions } from 'smv-redux/actions';
import { createNewQuestionnaireItem } from 'smv-helpers';

import './EmptyNode.scss';
// import { defaultNotifyProps } from 'smv-constants';
// import { Store as notify } from 'react-notifications-component';

export const EmptyNode = ({ style = {}, writeable }) => {
  const surveyid = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const isLoading = useSelector(state => state.survey.loading ?? false);

  const translationPrefix = 'smoove.questionnaire';

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['question', 'page', 'container'],
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: item => {
      if (!!item?.id) return false;
      return true;
    },
    drop: item => {
      // Only new elements, get type/ qtype from item
      const content = createNewQuestionnaireItem(item, questionnaire);
      // append element to root
      questionnaireActions.addQuestionnaireElement(surveyid, content);
    }
  });

  const isQueEmpty = !('root' in questionnaire) || questionnaire?.root?.length === 0;

  return (
    <div
      ref={drop}
      className={`empty-node ${isQueEmpty ? 'empty-node--empty-que' : ''} ${
        isOver && canDrop ? 'empty-node--isOver' : ''
      }`}
      style={{ ...style }}
    >
      {writeable && isQueEmpty && !isLoading && (
        <div className='p-2 text-center d-flex flex-column'>
          <FormattedMessage
            id={`${translationPrefix}.empty-state.empty-que-hint`}
            default={'Add a first question or group element from the right sidebar by drag & dropping it here.'}
          />
          <i className='fal fa-plus fa-2x mt-2' />
        </div>
      )}
      {writeable && (
        <div className='p-2 text-center plus-container'>
          {isOver && !isQueEmpty && <i className='fal fa-plus fa-2x mt-2' />}
        </div>
      )}
      {isLoading && (
        <div className='text-center'>
          <i className='fas fa-spinner fa-2x fa-spin' />
        </div>
      )}
    </div>
  );
};
