import React from 'react';
import { Col, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { StaticTableFilterButtonAndModal } from './StaticTableFilter';

export const StaticTableFilterSettings = ({ tableConfig, handler }) => {
  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.table-config.static-filters.title'}
              defaultMessage={'Static Table Filter'}
            />
          </Col>
          <Col md={7}>
            <StaticTableFilterButtonAndModal tableConfig={tableConfig} handler={handler} />
          </Col>
        </Row>
      </ListGroupItemHeading>
    </ListGroupItem>
  );
};
