import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import { useSelector } from 'react-redux';
import produce from 'immer';

import { View } from './View';

import './ChartLayersModal.scss';
import { chartLayersActions } from 'smv-redux';
import { isEqual } from 'lodash';

export const ChartLayersButtonAndModal = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <>
      <ChartLayersButton toggle={toggle} />
      {isOpen && <ChartLayersModal toggle={toggle} isOpen={isOpen} {...props} />}
    </>
  );
};

export const ChartLayersButton = ({ toggle }) => {
  const intl = useIntl();

  return (
    <Button id={`table-filter-modal-button`} onClick={toggle} size={'sm'} color={'secondary'} outline={true}>
      {intl.formatMessage({ id: `smoove.page.tables.chart-config.layers.button` })}
    </Button>
  );
};

export const ChartLayersModal = ({ isOpen, toggle, tableConfig, handler }) => {
  const surveyId = useSelector(s => s.survey.id);
  const intl = useIntl();

  const INITIAL_LAYER = {
    id: null,
    label: '',
    views: {}
  };
  const [_layer, setLayer] = useState(INITIAL_LAYER);
  const [views, setViews] = useState({ order: [], list: {} });

  useEffect(() => {
    // check if chart layer is defined
    if (tableConfig.chart?.chartLayer) {
      // if so load the chart layer
      chartLayersActions.loadChartLayers(surveyId).then(layers => {
        // get its views
        let layer = layers?.list?.[tableConfig.chart?.chartLayer];
        setLayer(layer);

        if (layer?.views) {
          setViews(layer?.views);
        }
      });
    }
  }, [surveyId, tableConfig.chart.chartLayer]);

  const saveHandler = useCallback(() => {
    if (views.order.length < 2) return;
    const updatedOrder = [...views.order];

    const saveChartLayerPromise = !_layer.id
      ? chartLayersActions.createChartLayer(surveyId, { label: `new layer` }).then(res => res.layer)
      : Promise.resolve(_layer);

    const crudViewsPromise = saveChartLayerPromise.then(layer => {
      const viewsPromises = [];
      // views to be created
      views.order.forEach((viewId, index) => {
        if (!layer.views.order.includes(viewId)) {
          const { id, ..._view } = views.list[viewId];
          viewsPromises.push(
            chartLayersActions.createChartLayerView(surveyId, layer.id, _view).then(res => {
              updatedOrder[index] = res.view.id;
              return Promise.resolve(res);
            })
          );
        }
      });

      // views to be updated
      views.order.forEach(viewId => {
        if (layer.views.order.includes(viewId) && !isEqual(layer.views.list[viewId], views.list[viewId])) {
          const { id, ..._view } = views.list[viewId];
          viewsPromises.push(chartLayersActions.updateChartLayerView(surveyId, layer.id, viewId, _view));
        }
      });

      // views to be deleted
      layer.views.order.forEach(viewId => {
        if (!views.order.includes(viewId)) {
          viewsPromises.push(chartLayersActions.deleteChartLayerView(surveyId, layer.id, viewId));
        }
      });

      return Promise.all(viewsPromises).then(resAll => {
        return chartLayersActions.loadChartLayers(surveyId).then(res => res.list[layer.id]);
      });
    });

    crudViewsPromise.then(updatedLayer => {
      chartLayersActions.updateChartLayerViewsOrder(surveyId, updatedLayer.id, { order: updatedOrder }).then(() => {
        if (!tableConfig.chart.chartLayer || tableConfig.chart.chartLayer !== updatedLayer.id) {
          handler.setTableConfig(s =>
            produce(s, d => {
              d.chart.chartLayer = updatedLayer.id;
            })
          );
        }
        toggle();
      });
    });
  }, [handler, views, toggle, surveyId, tableConfig.chart.chartLayer, _layer]);

  const deleteLayerHandler = () => {
    handler.setTableConfig(s =>
      produce(s, d => {
        delete d.chart.chartLayer;
      })
    );

    toggle();
  };

  const onDragEnd = useCallback(result => {
    const { destination, source } = result;
    if (!destination || !source || destination.index === source.index) return;
    setViews(s =>
      produce(s, d => {
        d.order = arrayMoveImmutable(d.order, source.index, destination.index);
      })
    );
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({
          id: 'smoove.page.tables.chart-config.layers.title',
          defaultMessage: 'Layers'
        })}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.layers.add-view'
              })}
            </Label>
          </Col>
        </Row>
        <View setViews={setViews} tableConfig={tableConfig} />
        <Row className={'mt-3'}>
          <Col>
            <Label>
              {intl.formatMessage({
                id: 'smoove.page.tables.chart-config.layers.configured-views'
              })}
            </Label>
          </Col>
        </Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='view-droppable'>
            {provided => (
              <div
                className={`droppable-default droppable-default--filters-selected`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {views.order.map((viewId, index) => {
                  return (
                    <Draggable key={`view_${viewId}`} draggableId={viewId} index={index}>
                      {provided => (
                        <div
                          className={`view__drag-container draggable-default`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <i className='fal fa-grip-vertical mr-2'></i>

                          <View setViews={setViews} view={views.list[viewId]} tableConfig={tableConfig} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <Button color={'danger'} onClick={deleteLayerHandler}>
          <FormattedMessage defaultMessage={`Delete Layer`} id={`smoove.page.tables.chart-config.layers.delete`} />
        </Button>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        <Button color={'primary'} onClick={saveHandler}>
          <FormattedMessage defaultMessage={`Save`} id={`smoove.common.buttons.save`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
