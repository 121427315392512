import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';

import { ColorScale } from '.';
import { chartSettings, chartTypes, tableTypeChartTypes } from '../config';

import '../ResultConfig.scss';

export const GeneralChartConfig = ({ handler, chartConfig, resultTableType, matrixType }) => {
  const intl = useIntl();

  const chartTypeOptions = [];
  Object.keys(chartTypes).forEach(type => {
    if (tableTypeChartTypes?.[resultTableType]?.includes(type)) {
      const chartTypeOption = (
        <option key={type} value={type}>
          {intl.formatMessage({ id: chartTypes[type].label })}
        </option>
      );

      chartTypeOptions.push(chartTypeOption);
    }
  });

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.chart-config.chart-settings'} defaultMessage={'Chart Settings'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-type'} defaultMessage={'Chart type'} />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.chart-type` })}
            id={`chartType`}
            name='chartType'
            value={chartConfig?.chartType ?? 'bar'}
            onChange={handler.setChartType}
          >
            {chartTypeOptions.map(el => el)}
          </Input>
        </Col>
      </Row>

      {['bar', 'line', 'area', 'scatter'].includes(chartConfig?.chartType) && chartTypeSettings?.chartOrientation && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage
                id={'smoove.page.tables.chart-config.chart-orientation'}
                defaultMessage={'Chart orientation'}
              />
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.chart-orientation` })}
                id={`chartOrientation`}
                name='chartOrientation'
                value={chartConfig?.chartOrientation ?? 'horizontal'}
                onChange={handler.setChartOrientation}
              >
                <option value={'horizontal'}>
                  {intl.formatMessage({ id: `smoove.page.tables.chart-config.horizontal` })}
                </option>
                <option value={'vertical'}>
                  {intl.formatMessage({ id: `smoove.page.tables.chart-config.vertical` })}
                </option>
              </Input>
            </Col>
          </Row>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage id={'smoove.page.tables.chart-config.chart-subtype'} defaultMessage={'Chart subtype'} />
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.bar-type` })}
                id={`chartSubtype`}
                name='chartSubtype'
                value={chartConfig?.chartSubtype ?? 'grouped'}
                onChange={handler.setChartSubtype}
              >
                <option value='' disabled>
                  {intl.formatMessage({ id: `smoove.page.tables.chart-config.choose-chart-subtype` })}
                </option>
                <option value={'stacked'}>
                  {intl.formatMessage({ id: `smoove.page.tables.chart-config.stacked` })}
                </option>
                <option value={chartConfig?.chartType === 'bar' ? 'grouped' : 'independent'}>
                  {chartConfig?.chartType === 'bar' ? 'Grouped' : 'Independent'}
                </option>
              </Input>
            </Col>
          </Row>
        </>
      )}

      {chartConfig.chartType !== 'table' && chartTypeSettings?.colorSchema && (
        <>
          <ColorScale onChange={handler.setChartColorSchema} colorSchema={chartConfig?.colorSchema} />
          <Row className='mt-1 align-items-center'>
            <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.reverse-colors` })}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`colorSchemaReverse`}
                  name='colorSchemaReverse'
                  checked={chartConfig?.colorSchemaReverse ?? false}
                  onChange={handler.setCheckboxValue}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}

      {chartTypeSettings?.tableTranspose && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.transpose` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`tableTranspose`}
                name='tableTranspose'
                checked={chartConfig?.tableTranspose ?? false}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {chartTypeSettings?.showBase && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.show-base` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                // label={intl.formatMessage({ id: `smoove.page.tables.chart-config.show-base` })}
                id={`showBase`}
                name='showBase'
                checked={chartConfig.showBase ?? true}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {chartTypeSettings?.showQuestionTitleColumn && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.show-question-title-column` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                // label={intl.formatMessage({ id: `smoove.page.tables.chart-config.show-question-title-column` })}
                id={`showQuestionTitleColumn`}
                name='showQuestionTitleColumn'
                checked={chartConfig.showQuestionTitleColumn ?? true}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {chartTypeSettings?.showDetailedMatrixChoiceTitleColumn && matrixType === 'detail' && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            {intl.formatMessage({ id: `smoove.page.tables.chart-config.show-detailed-matrix-choice-title-column` })}
          </Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                // label={intl.formatMessage({ id: `smoove.page.tables.chart-config.show-question-title-column` })}
                id={`showDetailedMatrixChoiceTitleColumn`}
                name='showDetailedMatrixChoiceTitleColumn'
                checked={chartConfig.showDetailedMatrixChoiceTitleColumn ?? true}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};
