import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Input, Label } from 'reactstrap';
import { customAlphabet } from 'nanoid';
import produce from 'immer';

import { reorderArray } from 'smv-helpers';

import { FormulaBuilderToolbar } from '.';
import { FormulaBuilderCalculationElementSwitch } from './FormulaBuilderCalculationElements';

import './FormulaBuilder.scss';
import { bracketColorDefinitions } from './config';

export const FormulaBuilder = ({ tableConfig, calculationMode, calculation, setCalculation }) => {
  const [elementColors, setElementColors] = useState([]);
  const [calculationTitle, setCalculationTitle] = useState(calculation?.title ?? '');
  const dropdownContainerRef = useRef(null);
  const renderParentLabels = useMemo(
    () =>
      (tableConfig?.rows?.order ?? [])?.filter(rowid =>
        ['question', 'sysvar', 'calcvar'].includes(tableConfig.rows.list[rowid].sourceType)
      ).length > 1,
    [tableConfig]
  );

  const handleSetCalculationTitle = useCallback(e => setCalculationTitle(e?.target?.value ?? ''), []);

  const handleSaveCalculationTitle = useCallback(
    e => {
      const { value } = e?.target ?? {};
      setCalculation(s =>
        produce(s, d => {
          d.title = value;
        })
      );
    },
    [setCalculation]
  );

  const handleAddOperator = useCallback(
    operator => {
      if (!operator) return null;

      const element = {
        id: customAlphabet('1234567890abcdef', 24)(),
        type: 'operator',
        value: operator
      };

      setCalculation(s =>
        produce(s, d => {
          d.order.push(element.id);
          d.list[element.id] = element;
        })
      );
    },
    [setCalculation]
  );

  const handleAddValue = useCallback(() => {
    const element = {
      id: customAlphabet('1234567890abcdef', 24)(),
      type: 'value',
      value: 0
    };

    setCalculation(s =>
      produce(s, d => {
        d.order.push(element.id);
        d.list[element.id] = element;
      })
    );
  }, [setCalculation]);

  const handleAddReference = useCallback(
    referencedValue => {
      const _element = {
        id: customAlphabet('1234567890abcdef', 24)(),
        type: 'reference',
        value: referencedValue
      };

      setCalculation(s =>
        produce(s, d => {
          d.order.push(_element.id);
          d.list[_element.id] = _element;
        })
      );
    },
    [setCalculation]
  );

  const handleSaveElement = useCallback(
    element => {
      setCalculation(s =>
        produce(s, d => {
          d.list[element.id] = element;
        })
      );
    },
    [setCalculation]
  );

  const handleRemoveElement = useCallback(
    elementid => {
      setCalculation(s =>
        produce(s, d => {
          delete d.list[elementid];
          d.order = d.order.filter(_elementid => _elementid !== elementid);
        })
      );
    },
    [setCalculation]
  );

  const onDragEnd = useCallback(
    result => {
      if (!result.source || !result.destination) return null;

      setCalculation(s =>
        produce(s, d => {
          d.order = reorderArray(d.order, result.source.index, result.destination.index);
        })
      );
    },
    [setCalculation]
  );

  useEffect(() => {
    const _elementColors = [];
    let bracketCounter = 0;

    calculation.order.forEach(elementid => {
      const element = calculation.list[elementid];
      if (element.type === 'operator' && element.value === '(') {
        _elementColors.push(bracketColorDefinitions[bracketCounter]);
        bracketCounter = bracketCounter + 1;
      } else if (element.type === 'operator' && element.value === ')') {
        bracketCounter--;
        _elementColors.push(bracketColorDefinitions[bracketCounter]);
      } else {
        _elementColors.push(bracketColorDefinitions[bracketCounter]);
      }
    });

    // todo: validation, check for error, if error: set all colors to red?

    setElementColors(_elementColors);
  }, [calculation]);

  if (!tableConfig || tableConfig.rows.order.length <= 0)
    return <div>Table is empty. To create a calculation, add elements to the table.</div>;

  // todo: group highlighting (on hover?) --> Define groups!
  // todo: validation
  // todo: fix dnd error that pops up when pressing save in the modal

  return (
    <>
      <div className='mb-4'>
        <Label for={'formula-name-input'}>Formula Name</Label>
        <Input
          id={'formula-name-input'}
          name={'title'}
          placeholder={'Enter name'}
          value={calculationTitle}
          onChange={handleSetCalculationTitle}
          onBlur={handleSaveCalculationTitle}
        />
      </div>

      <div>Formula Builder</div>

      <div className={`formula-builder`} ref={dropdownContainerRef}>
        {calculation.order.length <= 0 && <div>No elements. Add elements from operators or list below.</div>}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className='formula-builder__selected-stage'>
                {calculation.order.map((elementid, elementIndex) => {
                  const element = calculation.list[elementid];

                  return (
                    <Draggable key={`${elementid}_${elementIndex}`} draggableId={elementid} index={elementIndex}>
                      {(provided, snapshot) => (
                        <div
                          className='formula-builder__draggable-element'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={`${elementid}_${elementIndex}`}
                        >
                          <FormulaBuilderCalculationElementSwitch
                            calculationId={calculation.id}
                            calculationMode={calculationMode}
                            tableConfig={tableConfig}
                            element={element}
                            renderParentLabels={renderParentLabels}
                            color={elementColors[elementIndex]}
                            handleSaveElement={handleSaveElement}
                            handleRemoveElement={handleRemoveElement}
                            dropdownContainerRef={dropdownContainerRef}
                          />
                          {/* <FormulaBuilderElement
                            calculationMode={calculationMode}
                            tableConfig={tableConfig}
                            element={element}
                            renderParentLabels={renderParentLabels}
                            color={elementColors[elementIndex]}
                            handleSaveElement={handleSaveElement}
                            handleRemoveElement={handleRemoveElement}
                          /> */}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <FormulaBuilderToolbar
        calculationMode={calculationMode}
        calculation={calculation}
        tableConfig={tableConfig}
        handleAddValue={handleAddValue}
        handleAddOperator={handleAddOperator}
        handleAddReference={handleAddReference}
        dropdownContainerRef={dropdownContainerRef}
      />
    </>
  );
};
