import { isObject, reduce } from 'lodash';

export function flattenKeys(obj, parentKey = '') {
  return reduce(
    obj,
    (result, value, key) => {
      const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
      if (isObject(value)) {
        const flattened = flattenKeys(value, prefixedKey);
        return { ...result, ...flattened };
      }
      return { ...result, [prefixedKey]: value };
    },
    {}
  );
}
