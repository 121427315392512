export const validateWeightDefinition = (definition, sources) => {
  const source = sources[definition.sourceId];
  const subgroupSource = sources[definition.subgroupSourceId];

  if (!source) return false;
  if (definition.sourceType === 'question') {
    if (
      definition.sourceId &&
      ['single_matrix', 'multiple_matrix', 'multiple_vector'].includes(source.config.qtype) &&
      !definition.choiceId
    ) {
      return false;
    }
    if (
      definition.sourceId &&
      definition.choiceId &&
      ['multiple_matrix'].includes(source.config.qtype) &&
      !definition.scaleId
    ) {
      return false;
    }
  }
  if (definition.sourceType === 'calcvar') {
    if (source.multiple && !definition.choiceId) return false;
  }
  if (definition.inSubgroups) {
    if (!definition.subgroupSourceId || !subgroupSource) return false;
    if (definition.subgroupSourceType === 'question') {
      if (
        definition.subgroupSourceId &&
        ['single_matrix', 'multiple_vector'].includes(subgroupSource.config.qtype) &&
        !definition.subgroupChoiceId
      ) {
        return false;
      }
    }
    if (definition.subgroupSourceType === 'calcvar') {
      if (subgroupSource.multiple & !definition.subgroupChoiceId) return false;
    }
    return true;
  } else {
    return true;
  }
};
