import React, { useCallback, useEffect, useState } from 'react';
import {
  Input,
  FormGroup,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { projectSettingsActions } from 'smv-redux/actions';
import { TranslationButton, TranslationModal } from '../questionnaire/TranslationModal';

import './LanguageSelectDropdown.scss';
import { getCountryIso2Code } from 'src/smoove/helpers/getCountriesIsoCode/getCountryIso2Code';

export const LanguageSelectDropdown = ({
  id = '',
  className = '',
  showToggleShortlabelsItem = false,
  showTranslationButton = true,
  writeable
}) => {
  const surveyid = useSelector(state => state.survey?.id);
  const locales = useSelector(state => state.survey.locales ?? {});
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyid]?.selectedLocale ?? state.survey.locales.main ?? null
  );
  const mainLocale = useSelector(state => state.survey.locales.main);
  const language = useSelector(state => state.survey.locales?.list?.[selectedLocale] ?? '');
  const isShortlabelsActive = useSelector(state => state.projectSettings?.[surveyid]?.isShortlabelsActive ?? true);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(b => !b), []);

  const [isTranslationmodalOpen, setIsTranslationmodalOpen] = useState(false);
  const toggleTranslationmodal = useCallback(() => setIsTranslationmodalOpen(b => !b), []);

  const intl = useIntl();

  useEffect(() => setIsOpen(false), [selectedLocale]);

  const setActiveLanguage = locale => {
    if (locale !== selectedLocale) {
      projectSettingsActions.setQLocale(surveyid, locale);
    }
  };

  const toggleShortlabels = () => {
    projectSettingsActions.toggleIsShortlabelsActive(surveyid, !isShortlabelsActive);
  };

  if (!locales) return null;

  return (
    <div className={`language-select-dropdown ${className}`}>
      {!isOpen && (
        <UncontrolledTooltip
          target={`tt_languageSelect_${id}`}
          delay={{ show: 1000 }}
          autohide={true}
          placement={'left'}
        >
          {intl.formatMessage({
            id: `smoove.common.language-select.tooltip-1`,
            defaultMessage: 'Here you can change the language version of the questionnaire.'
          })}
          <br />
          {intl.formatMessage({
            id: `smoove.common.language-select.tooltip-2`,
            defaultMessage: 'This does not affect the results, only the texts and labels used.'
          })}
        </UncontrolledTooltip>
      )}

      <Dropdown isOpen={isOpen} size='sm' toggle={toggleIsOpen} className='d-flex justify-content-end'>
        <DropdownToggle id={`tt_languageSelect_${id}`} caret outline color={'secondary'}>
          <span className={`flag-icon flag-icon-${getCountryIso2Code(language?.iso3).toLowerCase()} mr-2`} />
          <span className='locale-label'>
            <span>
              {language?.label} {isShortlabelsActive && showToggleShortlabelsItem ? '(short)' : ''}{' '}
            </span>
            {language.locale === mainLocale && <i className='fas fa-star' />}
          </span>
        </DropdownToggle>
        <DropdownMenu end>
          {locales?.order?.map(locale => (
            <DropdownItem key={locale} onClick={() => setActiveLanguage(locale)}>
              <span
                className={`flag-icon flag-icon-${getCountryIso2Code(locales.list[locale]?.iso3).toLowerCase()} mr-2`}
              />
              <span>{locales.list?.[locale]?.label}</span>
              {locale === mainLocale && <i className='fas fa-star ml-2 text-secondary' />}
            </DropdownItem>
          ))}
          {showToggleShortlabelsItem && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => toggleShortlabels()}>
                {/* <i className={`far fa-flag mr-2`} /> */}
                <FormGroup switch>
                  <Input
                    type='switch'
                    role='switch'
                    id={`toggleShortlabelsSwitch`}
                    name='toggleShortlabelsSwitch'
                    checked={isShortlabelsActive}
                    readOnly
                    // onChange={setChartDomainAuto}
                  />
                  <Label className='mb-0' for={'toggleShortlabelsSwitch'}>
                    Shortlabels
                  </Label>
                </FormGroup>
              </DropdownItem>
            </>
          )}
          {showTranslationButton && (
            <>
              <DropdownItem divider />
              <DropdownItem>
                {/* <i className={`far fa-globe mr-2`} />
                <span>Translation</span> */}
                <TranslationButton toggle={toggleTranslationmodal} />
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
      {showTranslationButton && (
        <TranslationModal isOpen={isTranslationmodalOpen} toggle={toggleTranslationmodal} writeable={writeable} />
      )}
    </div>
  );
};
