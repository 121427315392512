import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { useElementPropertyTranslation } from 'smv-helpers';
import { useSignificanceBenchmarkLabel } from 'src/smoove/components/dashboard/charts/helper';

export const ResultTableDefaultFooter = ({ table, isCompactMatrix }) => {
  const intl = useIntl();
  const questionnaire = useSelector(state => state.survey?.questionnaire);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const sysVars = useSelector(state => state.survey?.systemVariables ?? []);

  const { rows } = table;
  const {
    valueSignificances,
    valueSignificancesLevel = 0.95,
    valueSignificancesDimension = 'heads',
    valueSignificancesBenchmark = null
  } = table?.config ?? {};

  const questionText = useMemo(() => {
    if (!rows) return null;

    return Object.values(rows.list).map((row, idx) => {
      if (row.sourceType === 'calcvar') {
        const element = calcVars?.find(el => el.id === row.sourceId);
        return (
          <div key={`${row.sourceId}_${idx}`} className='question_text'>
            {rows.order.length > 1 && <b className='mr-1'>{idx + 1})</b>}
            {element?.label && <b>{element.label}</b>}
          </div>
        );
      } else if (row.sourceType === 'sysvar') {
        const element = sysVars?.[row.sourceId];

        return (
          <div key={`${row.sourceId}_${idx}`} className='question_text'>
            {rows.order.length > 1 && <b className='mr-1'>{idx + 1})</b>}
            {element?.label && <b>{element.label}</b>}
          </div>
        );
      } else if (row.sourceType === 'calculation') {
        return (
          <div key={`${row.sourceId}_${idx}`} className='question_text'>
            {rows.order.length > 1 && <b className='mr-1'>{idx + 1})</b>}
            {row?.config?.title && <b>Calculation: {row?.config?.title}</b>}
          </div>
        );
      } else {
        return (
          <QuestionTextFooter
            key={`${row.sourceId}_${idx}`}
            rowElement={row}
            questionIndex={idx}
            questionElement={questionnaire?.list[row.sourceId]}
            numberedQuestions={rows.order.length > 0}
          />
        );
      }
    });
  }, [rows, questionnaire.list, calcVars, sysVars]);

  const getSignificanceBenchmarkLabel = useSignificanceBenchmarkLabel();
  const benchmarkLabel = getSignificanceBenchmarkLabel({
    tableConfig: table,
    valueSignificancesDimension,
    valueSignificancesBenchmark,
    isCompactMatrix
  });

  return (
    <div>
      {rows?.order?.length > 0 && (
        <Card className={'my-3'}>
          <CardBody>
            <div className='mb-1'>
              {rows.order.length > 1
                ? intl.formatMessage({
                    id: 'smoove.page.tables.table-footer.question-texts',
                    defaultMessage: 'Question Texts'
                  })
                : intl.formatMessage({
                    id: 'smoove.page.tables.table-footer.question-text',
                    defaultMessage: 'Question Text'
                  })}
              :
            </div>
            {questionText}
            {valueSignificances && (
              <div className={'mt-1 d-inline-flex align-items-center'}>
                {intl.formatMessage(
                  {
                    id: 'smoove.page.tables.table-footer.significances.text',
                    defaultMessage: 'Significances in {dimension} {benchmark}shown at a level of {value}%:'
                  },
                  {
                    value: valueSignificancesLevel * 100,
                    dimension: intl.formatMessage({
                      id: `smoove.page.tables.table-config.significance-dimension-${valueSignificancesDimension}`
                    }),
                    benchmark: !valueSignificancesBenchmark ? `(${benchmarkLabel}) ` : `(vs ${benchmarkLabel}) `
                  }
                )}

                <div className={'sig__icon sig__icon--high'}></div>
                {intl.formatMessage({
                  id: 'smoove.page.tables.table-footer.significances.high',
                  defaultMessage: 'higher'
                })}
                <div className={'sig__icon sig__icon--low'}></div>
                {intl.formatMessage({
                  id: 'smoove.page.tables.table-footer.significances.low',
                  defaultMessage: 'lower'
                })}
                {!valueSignificancesBenchmark && (
                  <>
                    <div className={'sig__icon sig__icon--mutually'}></div>
                    {intl.formatMessage({
                      id: 'smoove.page.tables.table-footer.significances.mutually',
                      defaultMessage: 'both (higher & lower)'
                    })}
                  </>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </div>
  );
};

const QuestionTextFooter = ({ rowElement, questionIndex, questionElement, numberedQuestions = false }) => {
  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main ?? 'en-US'
  );

  const questionText = useElementPropertyTranslation(questionElement, {
    locale,
    useStripped: true,
    useFallback: true
  });

  const varnameString = questionElement?.config?.varname ? `${questionElement.config.varname} - ` : '';
  return (
    <div key={`${rowElement.sourceId}_${questionIndex}`} className='question_text'>
      {numberedQuestions && <b className='mr-1'>{questionIndex + 1})</b>}
      <span>
        <b>
          {varnameString}
          {questionElement?.title}:
        </b>{' '}
        {questionText}
      </span>
    </div>
  );
};
