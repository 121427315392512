import { useCallback, useMemo } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import produce from 'immer';

import { getRowOrder, useGetSourceElement, useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import { significanceGates } from '../config';

export const SignificanceSettings = ({ handler, tableConfig, tableResult }) => {
  const intl = useIntl();

  const getSourceElement = useGetSourceElement();
  const { isCompactMatrix } = useMatrixType(tableConfig);

  const valueSignificancesBenchmarkKey = useMemo(() => {
    if (tableConfig?.config?.valueSignificancesBenchmark === null) return 'multisided';
    else if (
      isEqual(tableConfig?.config?.valueSignificancesBenchmark ?? {}, { headId: '__total__', headSubId: '__total__' })
    )
      return '__total__';
    else if (!!tableConfig?.config?.valueSignificancesBenchmark) {
      return Object.values(tableConfig?.config?.valueSignificancesBenchmark ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [tableConfig?.config?.valueSignificancesBenchmark]);

  const benchmarkSources = useMemo(() => {
    const _benchmarkSources = {};
    if (tableConfig.config.valueSignificancesDimension === 'heads') {
      let heads = tableConfig.heads;
      if (isCompactMatrix) {
        heads = produce(heads, d => {
          if (isCompactMatrix) {
            tableConfig.rows.order.forEach(rowid => {
              if (tableConfig.rows.list[rowid].sourceType === 'question') {
                d.order = [rowid, ...d.order];
                d.list[rowid] = tableConfig.rows.list[rowid];
              }
            });
          }
        });
      }

      for (const headId of heads.order) {
        const head = heads.list[headId];
        const { elementValues } = getSourceElement(head);
        for (const orderItem of tableResult?.headOrder?.[headId] ?? []) {
          const subelement = elementValues.list[orderItem.headSubId];
          _benchmarkSources[`${headId}~${orderItem.headSubId}`] = {
            subelement,
            cellIdentifier: { headId, headSubId: orderItem.headSubId }
          };
        }
      }
    } else if (tableConfig.config.valueSignificancesDimension === 'rows') {
      for (const rowId of tableConfig.rows.order) {
        const row = tableConfig.rows.list[rowId];
        const { element, elementValues, elementScales } = getSourceElement(row);
        const { rowOrder } = getRowOrder(tableResult?.order ?? [], row);
        for (const orderItem of rowOrder) {
          if (orderItem?.subsubelement && tableResult?.tableStruct === 'matrix_detail') {
            const subelement = elementValues.list[orderItem.subelement];
            const subsubelement = elementScales.list[orderItem.subsubelement];
            _benchmarkSources[`${rowId}~${orderItem.subelement}~${orderItem.subsubelement}`] = {
              subelement,
              subsubelement,
              cellIdentifier: { rowId, rowSubId: orderItem.subelement, rowSubSubId: orderItem.subsubelement }
            };
          } else if (
            element.type === 'question' &&
            ['single_matrix', 'multiple_matrix'].includes(element.config.qtype) &&
            tableResult?.tableStruct === 'matrix_compact_single'
          ) {
            const subelement = elementScales.list[orderItem.subelement];
            _benchmarkSources[`${rowId}~${orderItem.subelement}~${null}`] = {
              subelement,
              cellIdentifier: { rowId, rowSubId: orderItem.subelement, rowSubSubId: null }
            };
          } else {
            const subelement = elementValues.list[orderItem.subelement];
            _benchmarkSources[`${rowId}~${orderItem.subelement}~${null}`] = {
              subelement,
              cellIdentifier: { rowId, rowSubId: orderItem.subelement, rowSubSubId: null }
            };
          }
        }
      }
    }
    return _benchmarkSources;
  }, [getSourceElement, tableConfig, tableResult, isCompactMatrix]);

  const significanceLevelOptions = useMemo(
    () =>
      Object.keys(significanceGates[1])
        .reverse()
        .map(significanceLevelValue => (
          <option key={`significance-level-${significanceLevelValue}`} value={significanceLevelValue}>
            {significanceLevelValue * 100}%
          </option>
        )),
    []
  );

  const toggleValueSignificances = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valueSignificances = !draftState.config.valueSignificances;
          draftState.chart.significancesShow = draftState.config.valueSignificances;
        })
      ),
    [handler]
  );

  const setValueSignificancesLevel = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valueSignificancesLevel = parseFloat(value);
        })
      );
    },
    [handler]
  );

  const setValueSignificancesDimension = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valueSignificancesDimension = value;
          draftState.config.valueSignificancesBenchmark = null;
        })
      );
    },
    [handler]
  );

  const setValueSignificancesBenchmark = useCallback(
    e => {
      const { value } = e.target;

      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (value === 'multisided') draftState.config.valueSignificancesBenchmark = null;
          else if (value === '__total__')
            draftState.config.valueSignificancesBenchmark = { headId: '__total__', headSubId: '__total__' };
          else draftState.config.valueSignificancesBenchmark = benchmarkSources[value].cellIdentifier;
        })
      );
    },
    [handler, benchmarkSources]
  );

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.table-config.significances'} defaultMessage={'Significances'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.enable-significances` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`valueSignificances`}
              name='valueSignificances'
              checked={tableConfig.config.valueSignificances}
              onChange={toggleValueSignificances}
            />
          </FormGroup>
        </Col>
      </Row>
      {tableConfig.config.valueSignificances && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage
                id={'smoove.page.tables.table-config.significance-level'}
                defaultMessage={'Significance Level'}
              />
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.table-config.significance-level` })}
                id={`valueSignificancesLevel`}
                name='valueSignificancesLevel'
                value={parseFloat(tableConfig.config.valueSignificancesLevel)}
                onChange={setValueSignificancesLevel}
              >
                <option value='' disabled>
                  {intl.formatMessage({
                    id: 'smoove.page.tables.table-config.choose-gate',
                    defaultMessage: 'Choose gate'
                  })}
                </option>
                {significanceLevelOptions}
              </Input>
            </Col>
          </Row>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.significance-dimension` })}</Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.table-config.significance-dimension` })}
                id={`valueSignificancesDimension`}
                name='valueSignificancesDimension'
                value={tableConfig?.config?.valueSignificancesDimension ?? 'heads'}
                onChange={setValueSignificancesDimension}
              >
                <option value='' disabled>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.significance-dimension-choose` })}
                </option>
                <option value='heads'>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.significance-dimension-heads` })}
                </option>
                <option value='rows'>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.significance-dimension-rows` })}
                </option>
              </Input>
            </Col>
          </Row>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage
                id={'smoove.page.tables.table-config.significance-benchmark'}
                defaultMessage={'Benchmark'}
              />
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.table-config.significance-benchmark` })}
                id={`valueSignificancesBenchmark`}
                name='valueSignificancesBenchmark'
                value={valueSignificancesBenchmarkKey}
                onChange={setValueSignificancesBenchmark}
              >
                <option value='' disabled>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.choose-significance-benchmark` })}
                </option>
                <option value='multisided'>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.significance-benchmark-multisided` })}
                </option>
                {tableConfig.config.valueSignificancesDimension === 'heads' && !isCompactMatrix && (
                  <option value='__total__'>{intl.formatMessage({ id: `smoove.page.tables.total` })}</option>
                )}
                {Object.keys(benchmarkSources).map(benchmarkSourceKey => {
                  return (
                    <ValueSignificancesBenchmarkOptions
                      key={benchmarkSourceKey}
                      benchmarkSourceKey={benchmarkSourceKey}
                      benchmarkSource={benchmarkSources[benchmarkSourceKey]}
                    />
                  );
                })}
              </Input>
            </Col>
          </Row>
        </>
      )}
    </ListGroupItem>
  );
};

const ValueSignificancesBenchmarkOptions = ({ benchmarkSourceKey, benchmarkSource }) => {
  const labels = [];
  labels[0] = useSubelementPropertyTranslation(benchmarkSource.subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  labels[1] = useSubelementPropertyTranslation(benchmarkSource.subsubelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return <option value={benchmarkSourceKey}>{labels.filter(Boolean).join(' | ')}</option>;
};
