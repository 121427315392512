import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TextInputRow } from 'smv-components';
import classNames from 'classnames';

import './EditFolder.scss';

export const EditFolderButton = ({ toggle, mode, type = null }) => {
  const intl = useIntl();

  if (type === 'icon-only' && mode === 'add')
    return (
      <div className='smv-custom-dropdown__buttons' onClick={toggle}>
        <i className={'fal fa-folder-plus icon-smv-blue'}></i>
      </div>
    );

  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i
        className={classNames({
          'fal fa-folder-plus': mode === 'add',
          'fal fa-edit': mode === 'edit',
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>{intl.formatMessage({ id: `smoove.page.project.tables.form.${mode}-folder.button` })}</span>
    </div>
  );
};

export const EditFolderModal = ({ isOpen, toggle, handler, folder, mode, parentId }) => {
  const initial_folder = mode === 'edit' ? folder : { name: '', parentId };
  const [internalFolder, setInternalFolder] = useState(initial_folder);
  const intl = useIntl();

  const updateName = useCallback(({ target }) => {
    const { value } = target;
    setInternalFolder(state => ({ ...state, name: value }));
  }, []);

  const saveHandler = useCallback(() => {
    handler.saveFolder({ id: internalFolder.id, name: internalFolder.name, parentId });
    toggle();
  }, [internalFolder, parentId, handler, toggle]);

  const saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      saveHandler();
    },
    [saveHandler]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={saveOnSubmit}>
        <ModalHeader toggle={toggle}>
          {intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.headline` })}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>{intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.text` })}</Col>
          </Row>
          <TextInputRow
            name={'name'}
            title={intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.label` })}
            tooltip={intl.formatMessage({ id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.desc` })}
            value={internalFolder.name}
            change={updateName}
            placeholder={intl.formatMessage({
              id: `smoove.page.survey.tables.form.${mode}-folder.fields.name.placeholder`
            })}
            autoFocus={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={saveHandler}>
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
