import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { smooveRoles } from './roleConstants';

/**
 * Returns true if the currently logged in user is a smoove admin
 */
export const useIsUserSmooveAdmin = () => {
  const user = useSelector(state => state.authentication.user);
  return useMemo(() => {
    if (!user) return false;
    return user.smooveRole === smooveRoles.SMOOVE_ADMIN;
  }, [user]);
};
