import React from 'react';

import { useElementPropertyTranslation } from 'smv-helpers';

export const MultipleInputPreview = ({ question }) => {
  const suffixText = useElementPropertyTranslation(question, {
    property: 'suffix',
    useFallback: true,
    useShortcodes: false
  });

  const prefixText = useElementPropertyTranslation(question, {
    property: 'prefix',
    useFallback: true,
    useShortcodes: false
  });

  const pRegex = /(<[p]>)?(<\/p>)?/g;

  const inputs = [];
  for (let i = 0; i < (question.config.inputCount ?? 1); i++) {
    inputs.push(
      <div className='question-preview__multi-input' key={`$input_${question.id}__${i}`}>
        <span className='mr-4'>#{i + 1}</span>
        <span dangerouslySetInnerHTML={{ __html: prefixText.replaceAll(pRegex, '') }}></span>
        <input
          className='mx-1'
          style={{ width: question.config.fieldWidth ?? '100%' }}
          type={question.config?.input ?? 'text'}
          min={question.config?.min ?? null}
          max={question.config?.max ?? null}
        ></input>
        <span dangerouslySetInnerHTML={{ __html: suffixText.replaceAll(pRegex, '') }}></span>
      </div>
    );
  }
  return <div className='question-preview__multi-input-container'>{inputs}</div>;
};
