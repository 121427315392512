import React, { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { permissionEnum, useIsQueWriteable, useUserHasPermission } from 'smv-helpers';

import { Sidebar, Content, EditLaunchedSurveyToggle } from '../components/questionnaire';
import { LanguageSelectDropdown } from '../components/project-results';
import { MediaLibraryButtonAndModal } from '../components/questionnaire/Content/MediaLibrary/MediaLibraryButtonAndModal';

import './Questionnaire.scss';
import { PageHeadline } from '../components/PageHeadline';
import { questionnaireActions, translationsActions } from '../redux/actions';
// import { BillingInfo } from '../components/BillingSystem/BillingInfo';

export const Questionnaire = ({ match }) => {
  const survey = useSelector(state => state.survey);
  const surveyid = survey.id;

  const project = useSelector(state => state.project);

  const intl = useIntl();
  const translationPrefix = 'smoove.questionnaire';

  const [isEditLaunchedSurveyEnabled, setIsEditLaunchedSurveyEnabled] = useState(false);
  const isWriteable = useIsQueWriteable(survey.status, isEditLaunchedSurveyEnabled);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);

  const inputFile = useRef(null);

  const handleClickUploadFileButton = () => {
    inputFile.current.click();
  };

  const handleFileUploadDialog = e => {
    const selectedFile = e.target.files[0];

    translationsActions.importTranslations(surveyid, selectedFile);

    e.target.value = null;
  };

  const questionnaireHandler = useMemo(() => {
    return {
      toggleIsEditLaunchedSurvey: () => {
        setIsEditLaunchedSurveyEnabled(b => !b);
      },
      save: () => {
        console.log('projectsActions.save(survey)');
        // projectsActions.save(config);
      },
      launch: () => {
        console.log('projectsActions.exportQuestback(config.id)');
        // projectsActions.exportQuestback(config.id);
      },
      exportElement: surveyid => {
        questionnaireActions.exportElement(surveyid);
      }
    };
  }, []);

  /**
   * Page/ container controls:
   *   - configuration icon (filter/ loop/
   *   - clone?
   *   - variable prefix (set variable prefix for all questions inside this container?
   *   - variable index (option to reset the variable index, that it starts with 1 again for this
   *     container/ only if the prefix is unique to previous containers/ questions?
   *   -
   *
   * Possible Features:
   *
   * Structure View:
   *   - collapse/ un-collapse all (pages)
   *
   * Multicountry Studies:
   *   - Option to disable a question for the current country vs to disable it in all other countries
   *
   */
  if (!survey.id)
    return (
      <div className='smv-questionnaire container-fluid base-content flex-fill mb-3'>
        <div className='mt-4'>{survey.loading && <i className='fal fa-spinner fa-spin' />}</div>
      </div>
    );

  if (!survey.locales?.order || survey.locales?.order?.length <= 0)
    return (
      <div className='smv-questionnaire container-fluid base-content flex-fill mb-3'>
        <div className='mt-4'>
          <FormattedMessage id={`${translationPrefix}.empty-state.no-locales-text`} default={'No locales specified'} />
          <Link className='ml-1' to={`/one/projects/${survey.projectid}/${survey.id}/survey`}>
            <FormattedMessage id={`${translationPrefix}.empty-state.general-page-link`} default={'To general page'} />
          </Link>
        </div>
      </div>
    );

  return (
    /**
     * The DnDProvider is also used in the `Tables` component. When switching routes, the following error occurs:
     * `Cannot have two HTML5 backends at the same time`
     *
     * This should have been fixed in react-dnd 11.1.3 but the error still occurs.
     * The `HTML5Backend` seems to be not unmounted correctly. To fix this temporarily, the `DndProvider`
     * was moved to the next parent component (`Project`) and disabled in both child components (`Tables`, `Questionnaire`).
     * <DndProvider backend={HTML5Backend}>
     */
    <div className='smv-questionnaire container-fluid base-content flex-fill mb-3'>
      <Helmet>
        <title>
          {survey.title ?? ''} - {intl.formatMessage({ id: `core.menu.surveysubmenu.item.questionnaire` })}
        </title>
      </Helmet>
      <Row className='smv-questoinnaire__head'>
        <Col md='9'>
          <PageHeadline fallback={survey.title} />
        </Col>
        <Col md='3' className='d-flex justify-content-end mb-2'>
          {/* <BillingInfo /> */}
          <MediaLibraryButtonAndModal writeable={isWriteable && userCanEditProject} isSelectionDisabled={true} />
          <div className='smv-dashboard-element-menu__item' id={`export-questionnaire-${surveyid}`}>
            <UncontrolledTooltip target={`export-questionnaire-${surveyid}`} delay={{ hide: 0 }} autohide={false}>
              Export Questionnaire
            </UncontrolledTooltip>
            <Button
              color={'secondary'}
              size={'sm'}
              outline={true}
              className='mr-2'
              style={{ marginTop: '1px', height: '30px' }}
              onClick={() => questionnaireHandler.exportElement(surveyid)}
            >
              <i className='fas fa-file-export'></i>
            </Button>
          </div>
          <div className='smv-dashboard-element-menu__item' id={`import-translations-${surveyid}`}>
            <UncontrolledTooltip target={`import-translations-${surveyid}`} delay={{ hide: 0 }} autohide={false}>
              Import Translations
            </UncontrolledTooltip>
            <Button
              color={'secondary'}
              size={'sm'}
              outline={true}
              className='mr-2'
              style={{ marginTop: '1px', height: '30px' }}
              onClick={handleClickUploadFileButton}
            >
              <i className='fas fa-file-import'></i>
            </Button>
            <input
              type='file'
              accept='.xlsx'
              id='file'
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleFileUploadDialog}
            />
          </div>
          <EditLaunchedSurveyToggle
            questionnaireHandler={questionnaireHandler}
            isEditLaunchedSurveyEnabled={isEditLaunchedSurveyEnabled}
          />
          {survey?.locales?.order?.length > 0 && (
            <LanguageSelectDropdown writeable={isWriteable && userCanEditProject} />
          )}
        </Col>
      </Row>
      <Row>
        <Col className='questionnaire__content'>
          <Content writeable={isWriteable && userCanEditProject} surveyId={survey.id} />
        </Col>
        <Sidebar writeable={isWriteable && userCanEditProject} />
      </Row>
    </div>
  );
};
