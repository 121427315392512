import React from 'react';

import { useElementPropertyTranslation } from 'smv-helpers';

export const SingleInputPreview = ({ question }) => {
  const suffixText = useElementPropertyTranslation(question, {
    property: 'suffix',
    useFallback: true,
    useShortcodes: false
  });

  const prefixText = useElementPropertyTranslation(question, {
    property: 'prefix',
    useFallback: true,
    useShortcodes: false
  });

  const pRegex = /(<[p]>)?(<\/p>)?/g;

  const inputHtml = (
    <>
      {question.config.input === 'number' ? (
        <input className='mx-1' type='number' style={{ width: question.config.fieldWidth ?? '100%' }}></input>
      ) : question.config.lines > 1 ? (
        <textarea
          className='mx-1'
          type='text'
          rows={question.config.lines ?? 5}
          style={{ width: question.config.fieldWidth ?? '100%', resize: 'none' }}
        />
      ) : (
        <input type='text' style={{ width: question.config.fieldWidth ?? '100%' }} />
      )}
    </>
  );

  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: prefixText.replaceAll(pRegex, '') }}></span>
      {inputHtml}
      <span dangerouslySetInnerHTML={{ __html: suffixText.replaceAll(pRegex, '') }}></span>
    </div>
  );
};
