import React, { useMemo } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

import { chartSettings } from '../config';

export const ChartTooltipConfig = ({ chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.showTooltip !== true) return null;

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        {intl.formatMessage({ id: `smoove.page.tables.chart-config.tooltips` })}
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.show-tooltip` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`showTooltip`}
              name='showTooltip'
              checked={chartConfig?.showTooltip ?? true}
              onChange={handler.setCheckboxValue}
            />
          </FormGroup>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
