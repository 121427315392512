import React, { useMemo } from 'react';
import { customAlphabet } from 'nanoid';
import PropTypes from 'prop-types';
import produce from 'immer';
import { useSelector } from 'react-redux';

import { GroupRules } from '.';
import { getQueryBuilderEmptyState, isQueryBuilderAnEmptyGroup } from './helper';

import './QueryBuilder.scss';

export function QueryBuilder({
  name,
  fields,
  queryBuilder,
  setQueryBuilder,
  readOnly = false,
  warning = null,
  isSaving = false
}) {
  const translations = useSelector(state => state.survey.translations);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';

  const internalHandler = useMemo(() => {
    return {
      addGroup: group =>
        setQueryBuilder(s =>
          produce(s, d => {
            const elementId = customAlphabet('1234567890abcdef', 24)();

            d.elements[group.parent].children.push(elementId);
            delete group.parent;

            d.elements[elementId] = {
              ...group,
              id: elementId,
              type: 'group',
              condition: 'or',
              not: false,
              children: []
            };
          })
        ),
      updateGroup: group =>
        setQueryBuilder(s =>
          produce(s, d => {
            d.elements[group.id] = group;
          })
        ),
      removeGroup: group =>
        setQueryBuilder(s =>
          produce(s, d => {
            const parentGroup = Object.values(s.elements).find(
              element => element.type === 'group' && element.children.includes(group.id)
            );
            if (parentGroup) {
              d.elements[parentGroup.id].children = d.elements[parentGroup?.id]?.children.filter(
                _id => _id !== group?.id
              );

              delete d.elements[group?.id];

              const deleteChildrenReq = group => {
                group.children.forEach(elementid => {
                  delete d.elements[elementid];
                  if (s.elements[elementid].children) {
                    deleteChildrenReq(s.elements[elementid]);
                  }
                });
              };

              deleteChildrenReq(group);
            }
          })
        ),
      addRule: rule =>
        setQueryBuilder(s =>
          produce(s, d => {
            const elementId = customAlphabet('1234567890abcdef', 24)();
            rule.id = elementId;

            d.elements[rule.parent].children.push(elementId);
            delete rule.parent;

            d.elements[elementId] = rule;
          })
        ),
      updateRule: rule => {
        // reset rule completely if the source was changed
        if (queryBuilder.elements?.[rule.id].sourceId !== rule.sourceId) {
          const _emptyRuleWithSource = {
            id: rule.id,
            sourceId: rule.sourceId,
            type: 'rule'
          };
          setQueryBuilder(s =>
            produce(s, d => {
              d.elements[rule.id] = _emptyRuleWithSource;
            })
          );
        } else {
          setQueryBuilder(s =>
            produce(s, d => {
              d.elements[rule.id] = rule;
            })
          );
        }
      },
      resetToInitialState: () => {
        setQueryBuilder(getQueryBuilderEmptyState());
      },
      removeRule: rule => {
        const _state = produce(queryBuilder, d => {
          const parentGroup = Object.values(queryBuilder.elements).find(
            element => element.type === 'group' && element.children.includes(rule.id)
          );
          d.elements[parentGroup.id].children = d.elements[parentGroup.id].children.filter(_id => _id !== rule.id);
          delete d.elements[rule.id];
        });
        if (isQueryBuilderAnEmptyGroup(_state)) {
          setQueryBuilder(getQueryBuilderEmptyState());
        } else {
          setQueryBuilder(_state);
        }
      }
    };
  }, [setQueryBuilder, queryBuilder]);

  return (
    <>
      <div className='querybuilder'>
        <GroupRules
          readOnly={readOnly}
          name={name}
          queryBuilder={queryBuilder}
          fields={fields}
          group={queryBuilder?.elements?.[queryBuilder?.rootElementId]}
          handler={internalHandler}
          translations={translations}
          locale={locale}
        />
      </div>
      {/* {warning && !isQueryBuilderEmptyState(queryBuilder) && (
        <div className='text-warning'>
          <span className='mr-2'>{intl.formatMessage({ id: 'smoove.common.querybuilder.unsaved-changes' })}</span>
          <span>{intl.formatMessage({ id: warning })}</span>
        </div>
      )} */}
      {isSaving && <div className='fal fa-spinner fa-spin'></div>}
      {/* todo: reset button to reset unsaved changes? */}
    </>
  );
}

QueryBuilder.propTypes = {
  queryBuilder: PropTypes.shape({
    rootElementId: PropTypes.string,
    elements: PropTypes.object.isRequired
  })
};
