import React from 'react';
import './SmvSidebarToggleButtonMobile.scss';

export const SmvSidebarToggleButtonMobile = ({ toggleFn, isOpen, label }) => {
  return (
    <div
      className='smv-sidebar-toggle-btn-mobile'
      onClick={() => {
        toggleFn(!isOpen);
      }}
    >
      <span>{label}</span>
      <i className={`ml-2 fas fa-chevron-double-${isOpen ? 'up' : 'down'}`}></i>
    </div>
  );
};
