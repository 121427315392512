import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Col, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const TableSortation = ({ handler, tableConfig }) => {
  const intl = useIntl();

  const onDragEndReorderRows = useCallback(
    result => {
      const { destination, source } = result;
      if (!destination || !source || destination.index === source.index) {
        return;
      }
      handler.reorderDimension('rows', source, destination);
    },
    [handler]
  );

  const onDragEndReorderHeads = useCallback(
    result => {
      const { destination, source } = result;
      if (!destination || !source || destination.index === source.index) {
        return;
      }
      handler.reorderDimension('heads', source, destination);
    },
    [handler]
  );

  return (
    <>
      {tableConfig?.rows?.order?.length > 1 && (
        <ListGroupItem>
          <ListGroupItemHeading>
            <Row className='mt-1 align-items-center'>
              <Col md={12}>{intl.formatMessage({ id: 'smoove.page.tables.table-config.row-order' })}</Col>
            </Row>
          </ListGroupItemHeading>
          <DragDropContext onDragEnd={onDragEndReorderRows}>
            <Droppable droppableId='row-sortation-droppable'>
              {provided => (
                <div
                  className={`droppable-default droppable-default--filters-selected`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tableConfig.rows.order.map((elementId, index) => {
                    return (
                      <TableSortElement
                        key={`${elementId}_${index}`}
                        tableElement={tableConfig.rows.list[elementId]}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ListGroupItem>
      )}
      {tableConfig?.heads?.order?.length > 1 && (
        <ListGroupItem>
          <ListGroupItemHeading>
            <Row className='mt-1 align-items-center'>
              <Col md={12}>{intl.formatMessage({ id: 'smoove.page.tables.table-config.head-order' })}</Col>
            </Row>
          </ListGroupItemHeading>
          <DragDropContext onDragEnd={onDragEndReorderHeads}>
            <Droppable droppableId='head-sortation-droppable'>
              {provided => (
                <div
                  className={`droppable-default droppable-default--filters-selected`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tableConfig.heads.order.map((elementId, index) => {
                    return (
                      <TableSortElement
                        key={`${elementId}_${index}`}
                        tableElement={tableConfig.heads.list[elementId]}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ListGroupItem>
      )}
    </>
  );
};

const TableSortElement = ({ tableElement, index }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const calcVars = useSelector(state => state.survey.data.calculations);
  const sysVars = useSelector(state => state.survey.systemVariables);
  // todo: make a universal helper/comopnent/hook that gets the element from the different sources
  let elementTitle = '-';
  if (tableElement.sourceType === 'question') {
    elementTitle = questionnaire.list[tableElement.sourceId]?.title;
  }
  if (tableElement.sourceType === 'calcvar') {
    elementTitle = (calcVars ?? []).find(el => el.id === tableElement.sourceId)?.label;
  }
  if (tableElement.sourceType === 'sysvar') {
    elementTitle = (sysVars.list ?? {})[tableElement.sourceId]?.label;
  }
  if (tableElement.sourceType === 'calculation') {
    elementTitle = tableElement.config.title;
  }

  return (
    <Draggable draggableId={tableElement.id} index={index}>
      {provided => (
        <div
          className={`draggable-default `}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style
          }}
        >
          {index + 1}
          {': '}
          {elementTitle}
        </div>
      )}
    </Draggable>
  );
};
