export const clientsActionTypes = {
  CLIENTS_LIST_REQUEST: 'CLIENTS_LIST_REQUEST',
  CLIENTS_LIST_SUCCESS: 'CLIENTS_LIST_SUCCESS',
  CLIENTS_LIST_FAILURE: 'CLIENTS_LIST_FAILURE',

  SWITCH_CLIENT: 'SWITCH_CLIENT',

  CREATE_NEW_CLIENT_REQUEST: 'CREATE_NEW_CLIENT_REQUEST',
  CREATE_NEW_CLIENT_SUCCESS: 'CREATE_NEW_CLIENT_SUCCESS',
  CREATE_NEW_CLIENT_FAILURE: 'CREATE_NEW_CLIENT_FAILURE',

  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAILURE: 'DELETE_CLIENT_FAILURE',

  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILURE: 'UPDATE_CLIENT_FAILURE',

  UPDATE_COLORSCALE_REQUEST: 'UPDATE_COLORSCALE_REQUEST',
  UPDATE_COLORSCALE_SUCCESS: 'UPDATE_COLORSCALE_SUCCESS',
  UPDATE_COLORSCALE_FAILURE: 'UPDATE_COLORSCALE_FAILURE',

  DELETE_COLORSCALE_REQUEST: 'DELETE_COLORSCALE_REQUEST',
  DELETE_COLORSCALE_SUCCESS: 'DELETE_COLORSCALE_SUCCESS',
  DELETE_COLORSCALE_FAILURE: 'DELETE_COLORSCALE_FAILURE'
};
