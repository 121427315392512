import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { isArray, isEqual } from 'lodash';

import { SplitElementItems } from './SplitElementItems';

export const DynamicSplitConfigSettings = ({ splitelement, handler }) => {
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const elements = useSelector(state => state.survey.questionnaire.list ?? {});

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const internalHandler = {
    setSplitElementLabel: e => {
      const { value } = e.target;
      if (value.length > 0) {
        handler.saveSplitElement({
          ...splitelement,
          label: value
        });
      } else {
        const _splitElement = {
          ...splitelement
        };
        delete _splitElement.label;
        handler.saveSplitElement(_splitElement);
      }
    },
    setSplitElementHiddenItems: item => {
      const config = splitelement.config ?? {};
      if (!config?.hidden || isArray(config.hidden)) config.hidden = {};

      if (config.hidden?.[item] ?? false) {
        delete config.hidden[item];
      } else {
        config.hidden[item] = true;
      }

      const _splitelement = {
        ...splitelement,
        config: config
      };

      handler.saveSplitElement(_splitelement);
    },
    setSplitElementItemOrder: order => {
      const config = {
        sortation: {
          headSortationMode: null,
          manualHeadSortation: {
            order: []
          }
        },
        ...splitelement?.config
      };

      if (isEqual(splitelement.config?.sortation?.manualHeadSortation?.order, order)) {
        config.sortation.manualHeadSortation.order = [];
      } else {
        config.sortation.headSortationMode = 'manual';
        config.sortation.manualHeadSortation.order = order;
      }

      const _splitelement = {
        ...splitelement,
        config: config
      };

      handler.saveSplitElement(_splitelement);
    }
  };

  if (!splitelement) return <span>-</span>;

  let label, sourceElement;
  if (splitelement.sourceType === 'question') {
    sourceElement = elements[splitelement.sourceId];
    label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.title;
  } else if (splitelement.sourceType === 'sysvar') {
    sourceElement = systemVariables.list[splitelement.sourceId];
    label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
  } else if (splitelement.sourceType === 'calcvar') {
    sourceElement = calcVars.find(el => el.id === splitelement.sourceId);
    label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
  }

  return (
    <Col md={6}>
      <div className={'dynamic-split-config-settings'}>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <h6>
            <FormattedMessage
              id={'smoove.page.common.dynamic-splits.element-settings'}
              defaultMessage={'Element Settings'}
            />
            <span>: {label ?? 'unnamed element'}</span>
          </h6>
          <Button size={'sm'} color={'grey'} onClick={() => handler.setOpenedSplitElement(null)}>
            <i className='fal fa-times'></i>
          </Button>
        </div>
        <Row>
          <Label for='chartSubtitel' className='fw-bold' md={5}>
            <FormattedMessage id={'smoove.page.common.dynamic-splits.split-label'} defaultMessage={'Split label'} />
          </Label>
          <Col md={7}>
            <Input
              type='text'
              id={`splitLabel`}
              name='label'
              value={splitelement?.label ?? ''}
              placeholder='Enter split label'
              onChange={internalHandler.setSplitElementLabel}
            />
          </Col>
        </Row>
        <h6>
          <FormattedMessage
            id={'smoove.page.common.dynamic-splits.split-element-items'}
            defaultMessage={'Split Element Items'}
          />
        </h6>
        <div className='d-flex flex-column'>
          <SplitElementItems splitelement={splitelement} handler={internalHandler} />
        </div>
      </div>
    </Col>
  );
};
