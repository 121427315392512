import React from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';

export const Metrics = ({ rowid, metrics, config, handler }) => {
  return (
    <>
      <Row className={'mt-0 align-items-center'}>
        <Col>
          <h6>General Settings</h6>
        </Col>
      </Row>
      {metrics.map(metric => (
        <Row key={`${metric.prop}_${rowid}`} className='mt-1 align-items-center'>
          <Col>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`${rowid}_${metric.prop}`}
                name={metric.prop}
                checked={config?.[metric.prop] ?? metric.default}
                onChange={handler.setCheckboxValue}
              />
              <Label for={`${rowid}_${metric.prop}`}>{metric.label}</Label>
            </FormGroup>
          </Col>
        </Row>
      ))}
    </>
  );
};
