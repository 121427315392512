import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Input, FormGroup, InputGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';

import { chartSettings } from '../config';

export const ChartAxisConfig = ({ chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.axis !== true) return null;

  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.chart-config.axis-settings'} defaultMessage={'Axis settings'} />
      </ListGroupItemHeading>

      {chartTypeSettings?.innerRadius && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage id={'smoove.page.tables.chart-config.inner-radius'} defaultMessage={'Inner Radius'} />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              step={5}
              label={intl.formatMessage({ id: `smoove.page.tables.chart-config.inner-radius` })}
              id={`innerRadius`}
              name='innerRadius'
              value={chartConfig?.innerRadius ?? 0}
              onChange={handler.setSelectValue}
            >
              <option value={0}>0 (default)</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={70}>70</option>
              <option value={80}>80</option>
            </Input>
          </Col>
        </Row>
      )}

      {chartTypeSettings?.chartDomainAuto && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage id={'smoove.page.tables.chart-config.axis.auto-range'} defaultMessage={'Auto Range'} />
            </Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`chartDomainAuto`}
                  name='chartDomainAuto'
                  checked={chartConfig?.chartDomainAuto ?? true}
                  onChange={handler.setChartDomainAuto}
                />
              </FormGroup>
            </Col>
          </Row>

          {chartConfig?.chartDomainAuto === false && (
            <Row className='mt-1 align-items-center'>
              <Col md={5}>
                <FormattedMessage
                  id={'smoove.page.tables.chart-config.axis.numeric-axis-maximum'}
                  defaultMessage={'Numeric axis maximum'}
                />
              </Col>
              <Col md={7}>
                <Input
                  type='number'
                  step={5}
                  label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.numeric-axis-maximum` })}
                  id={`chartDomainMax`}
                  name='chartDomainMax'
                  value={chartConfig?.chartDomainMax ?? ''}
                  placeholder={'auto'}
                  onChange={handler.setChartDomainRange}
                  disabled={chartConfig?.chartDomainAuto ?? true}
                ></Input>
              </Col>
            </Row>
          )}
        </>
      )}

      {chartTypeSettings?.chartDomainXAuto && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage
                id={'smoove.page.tables.chart-config.axis.x-auto-range'}
                defaultMessage={'x-axis auto range'}
              />
            </Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`chartDomainXAuto`}
                  name='chartDomainXAuto'
                  checked={chartConfig?.chartDomainXAuto ?? true}
                  onChange={handler.setChartDomainAuto}
                />
              </FormGroup>
            </Col>
          </Row>
          {chartConfig?.chartDomainXAuto === false && (
            <Row className='mt-1 align-items-center'>
              <Col md={5}>
                <FormattedMessage id={'smoove.page.tables.chart-config.axis.x-range'} defaultMessage={'x-axis range'} />
              </Col>
              <Col md={7}>
                <InputGroup className='d-flex flex-nowrap'>
                  <Input
                    type='number'
                    step={5}
                    label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.x-range-min` })}
                    id={`chartDomainXMin`}
                    className='mr-1'
                    name='chartDomainXMin'
                    value={(chartConfig?.chartDomainXAuto ?? true) === true ? '' : chartConfig?.chartDomainXMin ?? ''}
                    placeholder={chartConfig?.chartDomainXAuto ?? true ? '0' : 'min'}
                    onChange={handler.setChartDomainRange}
                    disabled={chartConfig?.chartDomainXAuto ?? true}
                  />
                  <Input
                    type='number'
                    step={5}
                    label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.x-range-max` })}
                    id={`chartDomainXMax`}
                    name='chartDomainXMax'
                    value={(chartConfig?.chartDomainXAuto ?? true) === true ? '' : chartConfig?.chartDomainXMax ?? ''}
                    placeholder={'max'}
                    onChange={handler.setChartDomainRange}
                    disabled={chartConfig?.chartDomainXAuto ?? true}
                  />
                </InputGroup>
              </Col>
            </Row>
          )}
        </>
      )}

      {chartTypeSettings?.chartDomainYAuto && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              <FormattedMessage
                id={'smoove.page.tables.chart-config.axis.y-auto-range'}
                defaultMessage={'y-axis auto range'}
              />
            </Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`chartDomainYAuto`}
                  name='chartDomainYAuto'
                  checked={chartConfig?.chartDomainYAuto ?? true}
                  onChange={handler.setChartDomainAuto}
                />
              </FormGroup>
            </Col>
          </Row>
          {chartConfig?.chartDomainYAuto === false && (
            <Row className='mt-1 align-items-center'>
              <Col md={5}>
                <FormattedMessage id={'smoove.page.tables.chart-config.axis.y-range'} defaultMessage={'y-axis range'} />
              </Col>
              <Col md={7}>
                <InputGroup className='d-flex flex-nowrap'>
                  <Input
                    type='number'
                    step={5}
                    label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.y-range-min` })}
                    id={`chartDomainYMin`}
                    className='mr-1'
                    name='chartDomainYMin'
                    value={(chartConfig?.chartDomainYAuto ?? true) === true ? '' : chartConfig?.chartDomainYMin ?? ''}
                    placeholder={chartConfig?.chartDomainYAuto ?? true ? '0' : 'min'}
                    onChange={handler.setChartDomainRange}
                    disabled={chartConfig?.chartDomainYAuto ?? true}
                  />
                  <Input
                    type='number'
                    step={5}
                    label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.y-range-max` })}
                    id={`chartDomainYMax`}
                    name='chartDomainYMax'
                    value={(chartConfig?.chartDomainYAuto ?? true) === true ? '' : chartConfig?.chartDomainYMax ?? ''}
                    placeholder={'max'}
                    onChange={handler.setChartDomainRange}
                    disabled={chartConfig?.chartDomainYAuto ?? true}
                  />
                </InputGroup>
              </Col>
            </Row>
          )}
        </>
      )}

      {chartTypeSettings?.axisYreversed &&
        ((chartConfig?.chartType === 'bar' && chartConfig?.chartOrientation === 'horizontal') ||
          (chartConfig?.chartType === 'line' && chartConfig?.chartOrientation === 'vertical')) && (
          <Row className='mt-1 align-items-center'>
            <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.y-axis-reversed` })}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`axisYreversed`}
                  name='axisYreversed'
                  checked={chartConfig?.axisYreversed ?? false}
                  onChange={handler.setCheckboxValue}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

      {chartTypeSettings?.axisYwidth &&
        ((chartConfig?.chartType === 'bar' && chartConfig?.chartOrientation === 'horizontal') ||
          (chartConfig?.chartType === 'line' && chartConfig?.chartOrientation === 'vertical')) && (
          <Row className='mt-1 align-items-center'>
            <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.y-axis-width` })}</Col>
            <Col md={7}>
              <Input
                type='number'
                step={5}
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.y-axis-width` })}
                id={`axisYwidth`}
                name='axisYwidth'
                value={chartConfig?.axisYwidth ?? 60}
                onChange={handler.setChartAxisYwidth}
                className='custom-number'
              />
            </Col>
          </Row>
        )}

      {chartTypeSettings?.dimensionsReversed && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.axis.reversed-dimensions` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`dimensionsReversed`}
                name='dimensionsReversed'
                checked={chartConfig?.dimensionsReversed ?? false}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {chartTypeSettings?.showGrid && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.show-grid` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`showGrid`}
                name='showGrid'
                checked={chartConfig?.showGrid ?? false}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};
