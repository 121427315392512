import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, ListGroup, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import produce from 'immer';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import { reportsActions, projectSettingsActions } from '../redux/actions';
import { useIsQueWriteable, exportDomElementAsImage, useUserHasPermission, permissionEnum } from 'smv-helpers';

import { LanguageSelectDropdown, TablesList } from '../components/project-results';
import {
  Report,
  ReportsListContainer,
  ConfigBarTop,
  Filters,
  gridBreakpoints,
  DynamicSplits
} from '../components/project-reports';
import { SmvSidebarToggleButton, SmvDrawer, SyntheticDataWarning, ActiveShareWarning } from '../components/misc';
import { useSidebarToggle } from '../components/misc/helper';
import { MediaItemsList } from '../components/project-reports/MediaItemsList/MediaItemsList';

import './Reports.scss';
import { StaticFiltersWarning } from '../components/misc/StaticFiltersWarning';
import { AddReportButton } from '../components/project-reports/ReportsList/AddReportButton';
import { PageHeadline } from '../components/PageHeadline';
import { ReportContentList } from '../components/project-reports/ReportContentList/ReportContentList';

export const Reports = ({ history, match }) => {
  const { params } = match;
  const { projectid, surveyid, pageid } = params;

  const survey = useSelector(state => state.survey);
  const projectSettings = useSelector(state => state?.projectSettings);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const surveyStatus = survey?.status;
  const tables = survey.tables;
  const reports = survey.reports;
  const selectedReportId = projectSettings?.[projectid]?.[surveyid]?.selectedReportId;
  const reportid = params.reportid;
  const report = reports?.list?.[selectedReportId ?? reportid];

  if (!report && reports?.order?.length > 0) {
    projectSettingsActions.setSelectedPage(projectid, surveyid, reports.order[0]);
  }

  const intl = useIntl();

  const reportRef = useRef();
  const reportsPageRef = useRef();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [leftSideBarSize, toggleLeftSidebar] = useSidebarToggle();

  const [activeSplits, setActiveSplits] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const [previewSize, setPreviewSize] = useState('lg');
  const [enabledScreenSizes, setEnabledScreenSizes] = useState([]);
  const isWriteable = useIsQueWriteable(surveyStatus, false) && userCanEditProject;
  const isLoading = survey.loading ?? reports.loading ?? false;

  const isShortlabelsActive = useSelector(state => state.projectSettings?.[surveyid]?.isShortlabelsActive ?? true);

  const switchPreviewSize = useCallback(size => {
    if (!reportRef) return;

    const pageTabsContainerWidth = Math.floor(reportRef?.current?.offsetWidth) + 'px';

    const animationOptions = {
      duration: 150,
      easing: 'ease',
      fill: 'forwards'
    };

    let reactGridLayout = reportRef?.current?.querySelector('.react-grid-layout');
    let animate, maxWidth;

    if (!reactGridLayout) {
      setPreviewSize(size);
    } else {
      if (size === 'sm') {
        // reactGridLayout.style.maxWidth = gridBreakpoints.sm;
        maxWidth = `${gridBreakpoints.sm + 2}px`;
      } else if (size === 'md') {
        // reactGridLayout.style.maxWidth = gridBreakpoints.md;
        maxWidth = `${gridBreakpoints.md + 2}px`;
      } else if (size === 'lg') {
        // reactGridLayout.style.maxWidth = '100%';
        maxWidth = '100%';
      }
      animate = reportRef.current.animate(
        // + 2 for borders
        [{ pageTabsContainerWidth }, { maxWidth }],
        animationOptions
      );
      animate.onfinish = function () {
        setPreviewSize(size);
      };
    }
  }, []);

  const calculateAndSetEnabledScreenSizes = useCallback(() => {
    const maxPageWidth = reportsPageRef?.current?.offsetWidth;
    if (maxPageWidth >= gridBreakpoints.lg) {
      setEnabledScreenSizes(['sm', 'md', 'lg']);
    } else if (maxPageWidth >= gridBreakpoints.md && maxPageWidth < gridBreakpoints.lg) {
      setEnabledScreenSizes(['sm', 'md']);
    } else if (maxPageWidth < gridBreakpoints.md) {
      setEnabledScreenSizes(['sm']);
    }
  }, []);

  // set initial previewSize
  useEffect(() => {
    const maxPageWidth = reportsPageRef?.current?.offsetWidth;
    if (maxPageWidth >= gridBreakpoints.lg) {
      setPreviewSize('lg');
    } else if (maxPageWidth >= gridBreakpoints.md && maxPageWidth < gridBreakpoints.lg) {
      setPreviewSize('md');
    } else if (maxPageWidth < gridBreakpoints.md) {
      setPreviewSize('sm');
    }
  }, []);

  useEffect(() => {
    calculateAndSetEnabledScreenSizes();
  }, [reportsPageRef?.current?.offsetWidth, leftSideBarSize.open, calculateAndSetEnabledScreenSizes]);

  const toggleLeftDrawer = useCallback(() => {
    setIsDrawerOpen(isDrawerOpen => !isDrawerOpen);
  }, []);

  /**
   * Reset active filters and splits when leaving reports page
   */
  useEffect(() => {
    return () => {
      setActiveFilters({});
      setActiveSplits({});
    };
  }, []);

  const handler = useMemo(() => {
    return {
      setActiveSplitElement: (splitid, splitelementid) => {
        setActiveSplits(state =>
          produce(state, draft => {
            if (draft?.[splitid]?.[splitelementid] === true) delete draft[splitid];
            else draft[splitid] = { [splitelementid]: true };
          })
        );
      },
      resetSplit: splitid => {
        setActiveSplits(state =>
          produce(state, draft => {
            delete draft[splitid];
          })
        );
      },
      /**
       * Sets the selected valueid in filter or resets to default, if no valueid is passed in
       *
       * @param {object} filter
       * @param {string|undefined} valueid
       */
      setActiveFilter: (filter, valueid) => {
        const isSingleSelect = filter?.isSingleSelect ?? false;
        const isMandatory = filter?.isMandatory ?? false;
        const defaultSelected = filter?.defaultSelected ?? false;

        setActiveFilters(state =>
          produce(state, draft => {
            if (!draft?.[filter.id]) draft[filter.id] = {};

            if (valueid) {
              /** single select */
              if (isSingleSelect) {
                /** if mandatory single select and current value selected, skip change */
                if (isMandatory && draft[filter.id]?.[valueid] === true) {
                  return draft;
                } else if (!isMandatory && draft[filter.id]?.[valueid] === true) {
                  /** if not mandatory single select and current value selected, delete valueid */
                  delete draft[filter.id][valueid];
                } else {
                  /** default set single value */
                  draft[filter.id] = { [valueid]: true };
                }
              } else {
                /** not single select */
                /** if mandatory multiple select and only current value selected, skip change */
                if (isMandatory && draft[filter.id]?.[valueid] === true && Object.keys(draft[filter.id]).length === 1) {
                  return draft;
                } else if (
                  /** if not mandatory multiple select or multiple values and current value selected, delete valueid */
                  draft[filter.id]?.[valueid] === true &&
                  (!isMandatory || Object.keys(draft[filter.id]).length > 1)
                ) {
                  delete draft[filter.id][valueid];
                } else {
                  /** not mandatory */
                  draft[filter.id][valueid] = true;
                }
              }
            }

            /** reset to default if filter empty or no valueid as argument (= reset) */
            if (!valueid || Object.keys(draft[filter.id]).length === 0) {
              if ((isMandatory && defaultSelected) || (!valueid && defaultSelected)) {
                draft[filter.id] = { [defaultSelected]: true };
              } else {
                delete draft[filter.id];
              }
            }
          })
        );
      },
      addReport: report => {
        return reportsActions.addReport(surveyid, report).then(report => {
          projectSettingsActions.setSelectedReport(projectid, surveyid, report.id);
          return report;
        });
      },
      deleteReport: reportid => {
        const currentIndex = reports.order.findIndex(id => id === reportid);
        const newId = currentIndex > 0 ? reports.order[currentIndex - 1] : reports.order[0];

        reportsActions.deleteReport(surveyid, reportid).then(res => {
          if (res === true) {
            if (selectedReportId === reportid && reports.order.filter(el => el === reportid).length > 0) {
              projectSettingsActions.setSelectedReport(projectid, surveyid, newId);
              const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${newId}`;
              history.push(pathname);
            }
            reportsActions.loadReports(surveyid);
          }
        });
      },
      duplicateReport: reportid => {
        reportsActions.duplicateReport(surveyid, reportid).then(newReport => {
          reportsActions.loadReports(surveyid).then(() => {
            const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${newReport.id}`;
            history.push(pathname);
          });
        });
      },

      addPage: async page => {
        const pageId = page.id;

        const updatedReport = produce(report, draftReport => {
          if (!report.pages.list) {
            draftReport.pages.list = {};
            draftReport.pages.order = [];
          }
          draftReport.pages.list[page.id] = page;
          draftReport.pages.order.push(page.id);
        });

        return reportsActions.updateReport(surveyid, reportid, updatedReport).then(report => {
          projectSettingsActions.setSelectedPage(projectid, surveyid, reportid, pageId);
          const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}/pages/${pageId}`;
          history.push(pathname);

          return report;
        });
      },
      updatePage: page => {
        return reportsActions.updateReport(
          surveyid,
          reportid,
          produce(report, draftReport => {
            draftReport.pages.list[page.id] = page;
          })
        );
      },
      deletePage: async pageid => {
        return reportsActions
          .updateReport(
            surveyid,
            reportid,
            produce(report, draftReport => {
              delete draftReport.pages.list[pageid];
              draftReport.pages.order = draftReport.pages.order.filter(id => id !== pageid);
            })
          )
          .then(() => {
            if (report.pages.order.length > 0) {
              projectSettingsActions.setSelectedPage(projectid, surveyid, reportid, report.pages.order[0]);
              const pathname = `/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}/pages/${report.pages.order[0]}`;
              history.push(pathname);
            }
          });
      },

      exportElement: (reportid, pageid, elementid, exportFileType, exportLoopFilters = null) => {
        if (['pptx', 'xlsx'].includes(exportFileType)) {
          reportsActions.exportElement(
            projectid,
            surveyid,
            reportid,
            pageid,
            elementid,
            exportFileType,
            activeFilters,
            activeSplits,
            isShortlabelsActive,
            exportLoopFilters
          );
        } else if (exportFileType === 'image') {
          const domElementId = `dashboard-element-${elementid}`;
          const element = document.getElementById(domElementId);
          const cardBody = element.querySelectorAll('div.card-body')?.[0] ?? false;
          if (cardBody) exportDomElementAsImage(cardBody);
        } else {
          console.log(`Export type ${exportFileType} not implemented!`);
        }
      },
      exportPage: (reportid, pageid, exportFileType, exportLoopFilters = null) => {
        reportsActions.exportElement(
          projectid,
          surveyid,
          reportid,
          pageid,
          null,
          exportFileType,
          activeFilters,
          activeSplits,
          isShortlabelsActive,
          exportLoopFilters
        );
      },
      exportReport: (reportid, exportFileType, exportLoopFilters = null) => {
        reportsActions.exportElement(
          projectid,
          surveyid,
          reportid,
          null,
          null,
          exportFileType,
          activeFilters,
          activeSplits,
          isShortlabelsActive,
          exportLoopFilters
        );
      },
      saveShare: (reportid, content) => {
        reportsActions.saveSharedReport(projectid, surveyid, reportid, content);
      },
      deleteShare: (reportid, shareid) => {
        reportsActions.deleteSharedReport(surveyid, reportid, shareid);
      },
      resetActiveFiltersAndSplits: () => {
        setActiveFilters({});
        setActiveSplits({});
      }
    };
  }, [
    activeFilters,
    activeSplits,
    history,
    projectid,
    report,
    reportid,
    reports.order,
    selectedReportId,
    surveyid,
    isShortlabelsActive
  ]);

  return (
    <div className='container-fluid base-content flex-fill mw-100 reports-page'>
      <Helmet>
        <title>
          {survey?.title || ''} - {intl.formatMessage({ id: `core.menu.surveysubmenu.item.reports` })}
        </title>
      </Helmet>

      {report && (
        <ReportsHeadContainer
          report={report}
          previewSize={previewSize}
          switchPreviewSize={switchPreviewSize}
          enabledScreenSizes={enabledScreenSizes}
          isWriteable={isWriteable}
          toggleLeftDrawer={toggleLeftDrawer}
        />
      )}
      <SmvDrawer side='left' isOpen={isDrawerOpen} toggle={toggleLeftDrawer}>
        <h4>Settings</h4>
        <hr />
        <small>Questionnaire language</small>
        {report && (
          <LanguageSelectDropdown
            className={`d-sm-flex d-md-none d-lg-none d-lg-none ml-2`}
            showToggleShortlabelsItem={true}
            writeable={isWriteable}
          />
        )}
        <hr />
        <ReportsListContainer reports={reports} handler={handler} />
        <hr />
        {report && (
          <DynamicSplits report={report} handler={handler} activeSplits={activeSplits} readonly={!userCanEditProject} />
        )}
        {report && (
          <Filters report={report} handler={handler} activeFilters={activeFilters} readonly={!userCanEditProject} />
        )}
      </SmvDrawer>
      <Row className='flex-nowrap'>
        <Col
          md={leftSideBarSize.md}
          lg={leftSideBarSize.lg}
          className={classNames('reports-sidebar-left', 'd-none', 'd-md-block')}
        >
          <SmvSidebarToggleButton position='left' toggleFn={toggleLeftSidebar} isOpen={leftSideBarSize.open} />
          <ListGroup className='list-group--first-lvl'>
            <ReportsListContainer reports={reports} handler={handler} />
            {userCanEditProject && (
              <TablesList surveyid={surveyid} tables={tables} draggable={true} sortable={false} readonly={true} />
            )}
            {userCanEditProject && <MediaItemsList handler={handler} />}

            {report && (
              <>
                <ReportContentList pageid={pageid} readonly={!userCanEditProject} />
                <DynamicSplits
                  report={report}
                  handler={handler}
                  activeSplits={activeSplits}
                  readonly={!userCanEditProject}
                />
                <Filters
                  report={report}
                  handler={handler}
                  activeFilters={activeFilters}
                  readonly={!userCanEditProject}
                />
              </>
            )}
          </ListGroup>
        </Col>
        <div
          className={`reports-page__report-container col-md-${12 - leftSideBarSize.md} col-lg-${
            12 - leftSideBarSize.lg
          }`}
          ref={reportsPageRef}
        >
          {isLoading && (
            <span>
              <i className='fas fa-spinner fa-spin ml-3'></i>
            </span>
          )}
          {!report && !isLoading && userCanEditProject && <AddReportButton saveHandler={handler.addReport} />}
          {report &&
            !isLoading &&
            [`${match.path}`, `${match.path}/pages/:pageid`].map(path => {
              return (
                <Route
                  key={path}
                  path={path}
                  exact={true}
                  render={props => (
                    <ReportRoute
                      {...props}
                      handler={handler}
                      report={report}
                      projectSettings={projectSettings}
                      previewSize={previewSize}
                      setPreviewSize={setPreviewSize}
                      toggleLeftDrawer={toggleLeftDrawer}
                      activeSplits={activeSplits}
                      activeFilters={activeFilters}
                      reportRef={reportRef}
                    />
                  )}
                />
              );
            })}
        </div>
      </Row>
    </div>
  );
};

const ReportsHeadline = ({ report }) => {
  const intl = useIntl();

  return (
    <div className='d-flex align-items-center reports-head-container__headline'>
      <PageHeadline
        fallback={`${intl.formatMessage({ id: `core.menu.surveysubmenu.item.reports` })} - ${report?.name || ''}`}
      />
      <SyntheticDataWarning className='ml-4 mr-2' />
      <StaticFiltersWarning reportid={report?.id} className={'mr-2'} />
      <ActiveShareWarning reportid={report?.id} />
    </div>
  );
};

const ReportsHeadContainer = ({
  report,
  previewSize,
  switchPreviewSize,
  enabledScreenSizes,
  isWriteable,
  toggleLeftDrawer
}) => {
  return (
    <div className='reports-head-container'>
      <ReportsHeadline report={report} />
      <div className={`d-none d-sm-none d-md-flex d-lg-flex d-lg-flex ml-4`}>
        <ConfigBarTop
          isAdmin={true}
          previewSize={previewSize}
          setPreviewSize={switchPreviewSize}
          enabledScreenSizes={enabledScreenSizes}
        />
      </div>
      <Button
        className={`d-md-none d-lg-none d-xl-none ml-4`}
        onClick={() => toggleLeftDrawer()}
        size={'sm'}
        color={'secondary'}
      >
        <i className='fal fa-cog'></i>
        <span className='ml-1'>Settings</span>
      </Button>
      <LanguageSelectDropdown
        className={`d-none d-sm-none d-md-flex d-lg-flex d-lg-flex ml-2`}
        showToggleShortlabelsItem={true}
        writeable={isWriteable}
      />
    </div>
  );
};

const ReportRoute = ({
  history,
  match,
  handler,
  report,
  projectSettings,
  reportRef,
  previewSize,
  setPreviewSize,
  toggleLeftDrawer,
  activeFilters,
  activeSplits
}) => {
  const { params } = match;
  const { projectid, surveyid, reportid, pageid } = params;

  const selectedReportId = projectSettings?.[projectid]?.[surveyid]?.selectedReportId;
  const selectedPageId = projectSettings?.[projectid]?.[surveyid]?.[reportid]?.selectedPageId ?? pageid;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  useEffect(() => {
    if (!reportid && selectedReportId) {
      history.push(`/one/projects/${projectid}/surveys/${surveyid}/reports/${selectedReportId}`);
    }
  }, [projectid, surveyid, reportid, selectedReportId, history]);

  return (
    <Report
      projectid={projectid}
      surveyId={surveyid}
      report={report}
      selectedPageId={selectedPageId}
      previewSize={previewSize}
      setPreviewSize={setPreviewSize}
      reportsHandler={handler}
      toggleLeftDrawer={toggleLeftDrawer}
      activeSplits={activeSplits}
      activeFilters={activeFilters}
      reportRef={reportRef}
      readonly={!userCanEditProject}
    />
  );
};
