import React from 'react';
import { useDrag } from 'react-dnd';
import { useIntl } from 'react-intl';

import { IconProviderWithName } from 'smv-components';

export const QuestionnaireElementDragIcon = ({ type = 'question', qtype = null, writeable }) => {
  const intl = useIntl();

  const [, drag] = useDrag({
    type: type,
    item: {
      type: type,
      qtype: qtype
    },
    canDrag: () => {
      return writeable;
    }
  });

  return (
    <div ref={drag} key={qtype} className={'w-100'}>
      <IconProviderWithName
        icon={qtype ?? type}
        filled={false}
        clickable={writeable}
        name={intl.formatMessage({
          id: `smoove.questionnaire.question.${(qtype ?? type).split('_').join('-')}`
        })}
        dragable={writeable}
      />
    </div>
  );
};
