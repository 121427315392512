import { useCallback, useState } from 'react';

export const useSidebarToggle = () => {
  const [config, setConfig] = useState({ open: false, md: 3, lg: 2 });

  const toggle = useCallback(
    () =>
      setConfig(s => {
        if (s.open) return { open: false, md: 3, lg: 2 };
        else return { open: true, md: 4, lg: 3 };
      }),
    []
  );

  return [config, toggle];
};
