import React from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import helpcenter from '../assets/img/help/helpcenter.jpg';
import documentation from '../assets/img/help/documentation.jpg';

import './Help.scss';

const translationPrefix = 'smoove.page.help';

const Help = props => {
  const intl = useIntl();

  return (
    <div className='container-fluid base-content flex-fill help-page'>
      <Helmet>
        <title>{intl.formatMessage({ id: `${translationPrefix}.headline` })}</title>
      </Helmet>
      <Row>
        <Col>
          <h2 className='d-block text-uppercase text-smv fw-bold'>
            <FormattedMessage id={`${translationPrefix}.headline`} defaultMessage={'Help'} />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 6 }} lg={{ size: 4 }}>
          <Card>
            <a
              href={'https://mindline-analytics.atlassian.net/servicedesk/customer/portals'}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='img-fluid' src={helpcenter} alt='smoove help center' />
            </a>
            <CardBody>
              <CardTitle tag='h5'>
                <FormattedMessage id={`${translationPrefix}.helpcenter.headline`} defaultMessage={'Help Center'} />
              </CardTitle>
              <CardText>
                <FormattedMessage id={`${translationPrefix}.helpcenter.text`} />
              </CardText>
              <Button
                href={'https://mindline-analytics.atlassian.net/servicedesk/customer/portals'}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FormattedMessage
                  id={`${translationPrefix}.helpcenter.btn-text`}
                  defaultMessage={'Go to Help Center'}
                />
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={{ size: 6 }} lg={{ size: 4 }}>
          <Card>
            <a
              href={'https://mindline-analytics.atlassian.net/wiki/spaces/SMVCS/overview'}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='img-fluid' src={documentation} alt='smoove knowledge base' />
            </a>
            <CardBody>
              <CardTitle tag='h5'>
                <FormattedMessage id={`${translationPrefix}.documentation.headline`} defaultMessage={'Documentation'} />
              </CardTitle>
              <CardText>
                <FormattedMessage id={`${translationPrefix}.documentation.text`} />
              </CardText>
              <Button
                href={'https://mindline-analytics.atlassian.net/wiki/spaces/SMVCS/overview'}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FormattedMessage
                  id={`${translationPrefix}.documentation.btn-text`}
                  defaultMessage={'Go to documentation'}
                />
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Help.propTypes = {};

export { Help };
