import axios from 'axios';
import { projectActions, projectsActions, store, surveyActions } from 'smv-redux';
import { clientsActionTypes } from './../constants/clientsContants';

/**
 * Async function that fetches all clients that are available to the user from the backend and updates the redux store accordingly
 *
 * @return returns all clients
 *
 */
const getAllClients = async () => {
  store.dispatch({
    type: clientsActionTypes.CLIENTS_LIST_REQUEST
  });
  try {
    const result = await axios.get('/users/me/clients');

    const clients = {};

    result.data.forEach(client => {
      clients[client.id] = client;
    });

    store.dispatch({
      type: clientsActionTypes.CLIENTS_LIST_SUCCESS,
      payload: { clients }
    });
    return clients;
  } catch (error) {
    store.dispatch({
      type: clientsActionTypes.CLIENTS_LIST_FAILURE,
      payload: { error }
    });
    throw error;
  }
};

/**
 * Creates a new client
 *
 * @param {object} data //data needed to create a new client. EG: {title: "string"}
 *
 */
const createNewClient = (data, callbackFn = () => {}) => {
  store.dispatch({
    type: clientsActionTypes.CREATE_NEW_CLIENT_REQUEST,
    payload: {}
  });

  axios
    .post(`/clients`, data)
    .then(result => {
      store.dispatch({
        type: clientsActionTypes.CREATE_NEW_CLIENT_SUCCESS,
        payload: { clients: result.data }
      });
      // todo: this works but is an extra request. is there an easier way to update the state (in the reducer?)
      clientsActions.getAllClients();
      callbackFn();
    })
    .catch(error => {
      store.dispatch({
        type: clientsActionTypes.CREATE_NEW_CLIENT_FAILURE,
        payload: {}
      });
    });
};

/**
 * Deletes a client by ID and updates reduxState accordingly
 *
 * @param {string} clientId //ID of the client that will be deleted
 *
 */
const deleteClient = async clientId => {
  store.dispatch({
    type: clientsActionTypes.DELETE_CLIENT_REQUEST,
    payload: {}
  });

  return axios
    .delete(`/clients/${clientId}`)
    .then(result => {
      store.dispatch({
        type: clientsActionTypes.DELETE_CLIENT_SUCCESS,
        payload: { clients: result.data }
      });
      // todo: this works but is an extra request. is there an easier way to update the state (in the reducer?)
      clientsActions.getAllClients();
    })
    .catch(error => {
      store.dispatch({
        type: clientsActionTypes.DELETE_CLIENT_FAILURE,
        payload: {}
      });
    });
};

/**
 * Switches to another client and updates the redux projects state
 *
 * @param {string} clientId //ID of the client that should be switched to
 *
 */
const switchClient = clientId => {
  store.dispatch({
    type: clientsActionTypes.SWITCH_CLIENT,
    payload: { clientId }
  });
  projectActions.resetProject();
  surveyActions.resetSurvey();
  projectsActions.resetProjectsList();
};

const updateClient = (clientId, data) => {
  store.dispatch({
    type: clientsActionTypes.UPDATE_CLIENT_REQUEST,
    payload: {}
  });

  axios
    .patch(`/clients/${clientId}`, data)
    .then(res => {
      store.dispatch({
        type: clientsActionTypes.UPDATE_CLIENT_SUCCESS,
        payload: { client: res.data }
      });
      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: clientsActionTypes.UPDATE_CLIENT_FAILURE,
        payload: {}
      });
      return error;
    });
};

/**
 * updates a custom color scale
 * @param {string} clientId
 * @param {string} colorScaleId
 * @param {object} colorScaleData
 */
const updateColorScale = (clientId, colorScaleId, colorScaleData) => {
  store.dispatch({
    type: clientsActionTypes.UPDATE_COLORSCALE_REQUEST,
    payload: { colorScaleData }
  });

  axios
    .patch(`/clients/${clientId}/colorscales/${colorScaleId}`, colorScaleData)
    .then(res => {
      store.dispatch({
        type: clientsActionTypes.UPDATE_COLORSCALE_SUCCESS,
        payload: { colorScale: res.data, clientId: clientId }
      });
      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: clientsActionTypes.UPDATE_COLORSCALE_FAILURE,
        payload: {}
      });
      return error;
    });
};

/**
 * Deletes a custom color scale
 * @param {string} clientId
 * @param {string} colorScaleId
 */
const deleteColorScale = (clientId, colorScaleId) => {
  store.dispatch({
    type: clientsActionTypes.DELETE_COLORSCALE_REQUEST
  });

  axios
    .delete(`/clients/${clientId}/colorscales/${colorScaleId}`)
    .then(result => {
      store.dispatch({
        type: clientsActionTypes.DELETE_COLORSCALE_SUCCESS,
        payload: { colorScaleId: colorScaleId, clientId: clientId }
      });
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: clientsActionTypes.DELETE_COLORSCALE_FAILURE,
        payload: { error: error }
      });
    });
};

export const clientsActions = {
  getAllClients,
  createNewClient,
  deleteClient,
  switchClient,
  updateClient,
  updateColorScale,
  deleteColorScale
};
