import React, { useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useIntl } from 'react-intl';

export const DuplicateReportButton = ({ toggle, className = '' }) => {
  const intl = useIntl();
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i className='fal fa-copy icon-smv-blue mr-1' />
      <span>
        {intl.formatMessage({
          id: 'smoove.page.reports.modals.duplicate-report.button-open',
          defaultMessage: 'Duplicate Report'
        })}
      </span>
    </div>
  );
};

export const DuplicateReportModal = ({ isOpen, toggle, reportid, duplicateHandler, title = '' }) => {
  const intl = useIntl();

  const _duplicateHandler = useCallback(() => {
    toggle();
    duplicateHandler(reportid);
  }, [duplicateHandler, reportid, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({
          id: 'smoove.page.reports.modals.duplicate-report.headline',
          defaultMessage: 'Duplicate Report'
        })}
      </ModalHeader>
      <ModalBody>
        {intl.formatMessage(
          {
            id: 'smoove.page.reports.modals.duplicate-report.body',
            defaultMessage: 'Are you sure you want to duplicate report {title}?'
          },
          { title: title }
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          {intl.formatMessage({ id: 'smoove.common.buttons.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button color={'primary'} onClick={_duplicateHandler}>
          {intl.formatMessage({ id: 'smoove.common.buttons.save', defaultMessage: 'Save' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
