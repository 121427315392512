import produce from 'immer';
import { clientsActionTypes } from './../constants/clientsContants';
import local from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { sharedReportActionTypes } from '../constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  selected: null,
  list: {}
};

const clientsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case clientsActionTypes.CLIENTS_LIST_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.CLIENTS_LIST_SUCCESS:
      return produce(state, draft => {
        const { clients } = payload;
        draft.loading = false;
        draft.error = false;
        draft.list = clients;
      });

    case clientsActionTypes.CLIENTS_LIST_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case clientsActionTypes.CREATE_NEW_CLIENT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.CREATE_NEW_CLIENT_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
      });

    case clientsActionTypes.CREATE_NEW_CLIENT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case clientsActionTypes.DELETE_CLIENT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.DELETE_CLIENT_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
      });

    case clientsActionTypes.DELETE_CLIENT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case clientsActionTypes.UPDATE_CLIENT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.UPDATE_CLIENT_SUCCESS:
      return produce(state, draft => {
        const { client } = payload;
        draft.loading = false;
        draft.error = false;
        draft.list[client.id] = client;
      });

    case clientsActionTypes.UPDATE_CLIENT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case clientsActionTypes.DELETE_COLORSCALE_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.DELETE_COLORSCALE_SUCCESS:
      return produce(state, draft => {
        const { clientId, colorScaleId } = payload;
        draft.loading = false;
        draft.error = false;
        delete draft.list[clientId].colorScales.list[colorScaleId];
        draft.list[clientId].colorScales.order = draft.list[clientId].colorScales.order.filter(
          id => id !== colorScaleId
        );
      });

    case clientsActionTypes.DELETE_COLORSCALE_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case clientsActionTypes.UPDATE_COLORSCALE_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case clientsActionTypes.UPDATE_COLORSCALE_SUCCESS:
      return produce(state, draft => {
        const { colorScale, clientId } = payload;
        draft.loading = false;
        draft.error = false;
        draft.list[clientId].colorScales.list[colorScale.id] = colorScale;
      });

    case clientsActionTypes.UPDATE_COLORSCALE_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
      return produce(state, draftState => {
        draftState.selected = payload.client.id;
        draftState.list[payload.client.id] = payload.client;
      });

    case clientsActionTypes.SWITCH_CLIENT:
      return produce(state, draft => {
        draft.selected = payload.clientId;
      });

    default:
      return state;
  }
};

const persistConfig = {
  key: 'clients',
  storage: local
};

export const clientsReducerPersisted = persistReducer(persistConfig, clientsReducer);
