import { settingsActionTypes } from '../constants';

const INITIAL_STATE = {
  loadingExports: false,
  isUserExportsWindowOpen: false,
  isSharedReportExportsWindowOpen: false
};

export const settingsReducerGlobalState = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line
  const { type, payload } = action;
  switch (type) {
    case settingsActionTypes.USERS_EXPORTS_REQUEST:
      return {
        ...state,
        loadingExports: true
        // isUserExportsWindowOpen: true
      };
    case settingsActionTypes.SHARED_EXPORTS_REQUEST:
      return {
        ...state,
        loadingExports: true
        // isUserExportsWindowOpen: true
      };
    case settingsActionTypes.USERS_EXPORTS_SUCECSS:
    case settingsActionTypes.USERS_EXPORTS_FAILURE:
    case settingsActionTypes.SHARED_EXPORTS_SUCECSS:
    case settingsActionTypes.SHARED_EXPORTS_FAILURE:
      return { ...state, loadingExports: false };
    case settingsActionTypes.TOGGLE_USERS_EXPORTS_WINDOW_REQUEST:
      return {
        ...state,
        isUserExportsWindowOpen: !state.isUserExportsWindowOpen
      };
    case settingsActionTypes.TOGGLE_SHARED_EXPORTS_WINDOW_REQUEST:
      return {
        ...state,
        isSharedReportExportsWindowOpen: !state.isSharedReportExportsWindowOpen
      };

    default:
      return state;
  }
};
