import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getQueryBuilderEmptyState, handleQuerybuilderAutosave } from '../../../querybuilder/helper';
import { QueryBuilder } from 'smv-components';
import { getQueryBuilderSources, getQuestionnaireElementsOrder, useTranslationHooks } from 'smv-helpers';
import { dataPrepActions } from 'smv-redux';

export const WeightDefinitionFilterModal = ({ isOpen, toggle, weightId, definition }) => {
  const surveyId = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const calculations = useSelector(state => state.survey?.data?.calculations ?? []);

  const intl = useIntl();

  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);
  const translationProps = useTranslationHooks();

  const sources = useMemo(() => {
    // const WHITELIST_QUESTION_TYPES = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix'];

    const queOrder = getQuestionnaireElementsOrder(questionnaire);
    const _questions = { list: {}, order: [] };
    (queOrder ?? []).forEach(elementid => {
      const element = questionnaire.list[elementid];
      // const { isLoopedQuestion } = isLooped(questionnaire.list, element);

      // if (element.type === 'question' && !isLoopedQuestion && WHITELIST_QUESTION_TYPES.includes(element.config.qtype)) {
      _questions.order.push(elementid);
      _questions.list[elementid] = element;
      // }
    });
    const rawSources = {
      question: _questions,
      sysvar: systemVariables,
      calcvar: {
        list: Object.fromEntries(calculations?.map(value => [value.id, value])),
        order: calculations?.map(el => el.id)
      }
    };

    const _sources = getQueryBuilderSources(rawSources, null, 'above', true, true, translationProps);

    return _sources;
  }, [questionnaire, translationProps, systemVariables, calculations]);

  const [staticTableFilters, setStaticTableFilters] = useState(definition.staticTableFilters ?? null);

  useEffect(() => {
    if (!!definition?.staticTableFilters && !!definition?.staticTableFilters?.rootElementId) {
      if (!staticTableFilters?.id) {
        setStaticTableFilters(state =>
          produce(state, d => {
            d.id = definition?.staticTableFilters?.id;
          })
        );
      }
    } else {
      setStaticTableFilters(getQueryBuilderEmptyState());
    }
  }, [definition?.staticTableFilters, definition?.staticTableFilters?.rootElementId, staticTableFilters?.id]);

  const saveHandler = useCallback(
    value => {
      if (definition) {
        setIsSaving(true);
        dataPrepActions
          .updateWeightDefinition(
            surveyId,
            weightId,
            definition.id,
            produce(definition, draft => {
              draft.staticTableFilters = staticTableFilters;
            })
          )
          .then(res => {
            setIsSaving(false);
          })
          .catch(err => {
            setIsSaving(false);
          });
      }
    },
    [surveyId, weightId, definition, staticTableFilters]
  );

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    dataPrepActions
      .updateWeightDefinition(surveyId, weightId, definition.id, { staticTableFilters: null })
      .then(res => {
        setIsSaving(false);
      })
      .catch(err => {
        setIsSaving(false);
      });
  }, [definition?.id, weightId, surveyId]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      staticTableFilters,
      definition?.staticTableFilters,
      sources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [staticTableFilters, saveHandler, isSaving, definition?.staticTableFilters, sources, deleteHandler]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'smoove.page.common.filters.label' })}</ModalHeader>
      <ModalBody>
        <QueryBuilder
          name='filters'
          fields={sources}
          queryBuilder={staticTableFilters}
          readOnly={false}
          setQueryBuilder={setStaticTableFilters}
        />
        <div>{warning ?? warning}</div>
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.close'} defaultMessage={'close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
