export const profileConstants = {
  // EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  // EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  // EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

  SAVE_USER_REQUEST: 'SAVE_USER_REQUEST',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_FAILURE: 'SAVE_USER_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE'
};
