import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';

import wizardLogoConcept from '../assets/img/wizards/wizard-concept.png';
import wizardLogoImpact from '../assets/img/wizards/wizard-impact.png';
import wizardLogoConjoint from '../assets/img/wizards/wizard-conjoint.png';
import wizardLogoIdeaScreening from '../assets/img/wizards/wizard-ideascreening.png';
import wizardLogoIndividual from '../assets/img/wizards/wizard-individual.png';
import wizardLogoCrowner from '../assets/img/wizards/wizard-crowner.png';
import './CreateProjectWidget.scss';

const icons = {
  'individual': wizardLogoIndividual,
  'conjoint': wizardLogoConjoint,
  'concept': wizardLogoConcept,
  'impact': wizardLogoImpact,
  'ideascreening': wizardLogoIdeaScreening,
  'bdf/crowner': wizardLogoCrowner,
  'default': <i className='fal fa-check-circle'></i>
  // 'concept': <i className='fal fa-thumbs-up'></i>,
  // 'impact': <i className='fal fa-rocket'></i>,
  // 'schwan-concept': <i className='fal fa-thumbs-up'></i>
};

const CreateProjectWidget = ({ title, description, type, wizardIcon = null, disabled = false }) => {
  const history = useHistory();
  const [loadStorageModalOpen, setLoadStorageModalOpen] = useState(false);

  const icon = icons?.[type] ?? icons.default;

  const handler = {
    openWizard: (type, load = null) => {
      const storageKey = `wizard-${type.replace('/', '-')}`;
      const storageItem = localStorage.getItem(storageKey);

      if (storageItem && isNull(load)) {
        // Ask if the stored state should be loaded or if a new wizard should be started
        setLoadStorageModalOpen(true);
        return;
      } else if (storageItem && load === false) {
        // clear storage and continue
        localStorage.removeItem(storageKey);
      }

      history.push(`/one/project/create/${type}`);
    },
    toggleLoadStorageModal: useCallback(() => {
      setLoadStorageModalOpen(b => !b);
    }, [])
  };

  return (
    <>
      <div className={'widget-container card box-shadow text-smv project-widget'}>
        {!!wizardIcon && wizardIcon}
        {!wizardIcon && (
          <div className='p-2'>
            <div
              className={'card-img-top card-img-top--wizard-image text-smv text-centerp-sm-4'}
              style={{ fontSize: '10em', backgroundImage: `url(${icon})` }}
            />
          </div>
        )}
        <div className='card-body text-center'>
          <h4 className='card-title text-uppercase pb-2 text-break'>{title}</h4>

          <div className='card-text d-flex justify-content-center'>
            <p className='text-center px-2' style={{ maxWidth: '230px' }}>
              {description}
            </p>
          </div>

          <Button
            onClick={() => handler.openWizard(type)}
            color={disabled ? 'grey' : 'primary'}
            outline={disabled}
            disabled={disabled}
          >
            <FormattedMessage id={'smoove.common.buttons.create-now'} />
          </Button>
        </div>
      </div>
      <LoadWizardFromStorageModal isOpen={loadStorageModalOpen} type={type} title={title} handler={handler} />
    </>
  );
};

const LoadWizardFromStorageModal = ({ isOpen, type, title, handler }) => {
  return (
    <Modal isOpen={isOpen} toggle={handler.toggleLoadStorageModal}>
      <ModalHeader toggle={handler.toggleLoadStorageModal}>
        <FormattedMessage id={'smoove.page.project.create.load-wizard.headline'} defaultMessage={'Continue editing'} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id={'smoove.page.project.create.load-wizard.message'}
          defaultMessage={
            'An unsaved state of a {title} wizard was found. Would you like to continue editing or start a new wizard process?'
          }
          values={{ title }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handler.openWizard(type, true)}>
          <FormattedMessage
            id={'smoove.page.project.create.load-wizard.continue-wizard'}
            defaultMessage={'Continue wizard'}
          />
        </Button>
        <Button onClick={() => handler.openWizard(type, false)}>
          <FormattedMessage
            id={'smoove.page.project.create.load-wizard.start-new-wizard'}
            defaultMessage={'Start new wizard'}
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreateProjectWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  wizardIcon: PropTypes.element
};

export default CreateProjectWidget;
