// Array to map between iso2 and iso3 codes
export const countries = [
  {
    iso2: 'AF',
    iso3: 'AFG'
  },
  {
    iso2: 'AL',
    iso3: 'ALB'
  },
  {
    iso2: 'DZ',
    iso3: 'DZA'
  },
  {
    iso2: 'AS',
    iso3: 'ASM'
  },
  {
    iso2: 'AD',
    iso3: 'AND'
  },
  {
    iso2: 'AO',
    iso3: 'AGO'
  },
  {
    iso2: 'AI',
    iso3: 'AIA'
  },
  {
    iso2: 'AQ',
    iso3: 'ATA'
  },
  {
    iso2: 'AG',
    iso3: 'ATG'
  },
  {
    iso2: 'AR',
    iso3: 'ARG'
  },
  {
    iso2: 'AM',
    iso3: 'ARM'
  },
  {
    iso2: 'AW',
    iso3: 'ABW'
  },
  {
    iso2: 'AU',
    iso3: 'AUS'
  },
  {
    iso2: 'AT',
    iso3: 'AUT'
  },
  {
    iso2: 'AZ',
    iso3: 'AZE'
  },
  {
    iso2: 'BS',
    iso3: 'BHS'
  },
  {
    iso2: 'BH',
    iso3: 'BHR'
  },
  {
    iso2: 'BD',
    iso3: 'BGD'
  },
  {
    iso2: 'BB',
    iso3: 'BRB'
  },
  {
    iso2: 'BY',
    iso3: 'BLR'
  },
  {
    iso2: 'BE',
    iso3: 'BEL'
  },
  {
    iso2: 'BZ',
    iso3: 'BLZ'
  },
  {
    iso2: 'BJ',
    iso3: 'BEN'
  },
  {
    iso2: 'BM',
    iso3: 'BMU'
  },
  {
    iso2: 'BT',
    iso3: 'BTN'
  },
  {
    iso2: 'BO',
    iso3: 'BOL'
  },
  {
    iso2: 'BO',
    iso3: 'BOL'
  },
  {
    iso2: 'BA',
    iso3: 'BIH'
  },
  {
    iso2: 'BW',
    iso3: 'BWA'
  },
  {
    iso2: 'BV',
    iso3: 'BVT'
  },
  {
    iso2: 'BR',
    iso3: 'BRA'
  },
  {
    iso2: 'IO',
    iso3: 'IOT'
  },
  {
    iso2: 'BN',
    iso3: 'BRN'
  },
  {
    iso2: 'BN',
    iso3: 'BRN'
  },
  {
    iso2: 'BG',
    iso3: 'BGR'
  },
  {
    iso2: 'BF',
    iso3: 'BFA'
  },
  {
    iso2: 'BI',
    iso3: 'BDI'
  },
  {
    iso2: 'KH',
    iso3: 'KHM'
  },
  {
    iso2: 'CM',
    iso3: 'CMR'
  },
  {
    iso2: 'CA',
    iso3: 'CAN'
  },
  {
    iso2: 'CV',
    iso3: 'CPV'
  },
  {
    iso2: 'KY',
    iso3: 'CYM'
  },
  {
    iso2: 'CF',
    iso3: 'CAF'
  },
  {
    iso2: 'TD',
    iso3: 'TCD'
  },
  {
    iso2: 'CL',
    iso3: 'CHL'
  },
  {
    iso2: 'CN',
    iso3: 'CHN'
  },
  {
    iso2: 'CX',
    iso3: 'CXR'
  },
  {
    iso2: 'CC',
    iso3: 'CCK'
  },
  {
    iso2: 'CO',
    iso3: 'COL'
  },
  {
    iso2: 'KM',
    iso3: 'COM'
  },
  {
    iso2: 'CG',
    iso3: 'COG'
  },
  {
    iso2: 'CD',
    iso3: 'COD'
  },
  {
    iso2: 'CK',
    iso3: 'COK'
  },
  {
    iso2: 'CR',
    iso3: 'CRI'
  },
  {
    iso2: 'CI',
    iso3: 'CIV'
  },
  {
    iso2: 'CI',
    iso3: 'CIV'
  },
  {
    iso2: 'HR',
    iso3: 'HRV'
  },
  {
    iso2: 'CU',
    iso3: 'CUB'
  },
  {
    iso2: 'CY',
    iso3: 'CYP'
  },
  {
    iso2: 'CZ',
    iso3: 'CZE'
  },
  {
    iso2: 'DK',
    iso3: 'DNK'
  },
  {
    iso2: 'DJ',
    iso3: 'DJI'
  },
  {
    iso2: 'DM',
    iso3: 'DMA'
  },
  {
    iso2: 'DO',
    iso3: 'DOM'
  },
  {
    iso2: 'EC',
    iso3: 'ECU'
  },
  {
    iso2: 'EG',
    iso3: 'EGY'
  },
  {
    iso2: 'SV',
    iso3: 'SLV'
  },
  {
    iso2: 'GQ',
    iso3: 'GNQ'
  },
  {
    iso2: 'ER',
    iso3: 'ERI'
  },
  {
    iso2: 'EE',
    iso3: 'EST'
  },
  {
    iso2: 'ET',
    iso3: 'ETH'
  },
  {
    iso2: 'FK',
    iso3: 'FLK'
  },
  {
    iso2: 'FO',
    iso3: 'FRO'
  },
  {
    iso2: 'FJ',
    iso3: 'FJI'
  },
  {
    iso2: 'FI',
    iso3: 'FIN'
  },
  {
    iso2: 'FR',
    iso3: 'FRA'
  },
  {
    iso2: 'GF',
    iso3: 'GUF'
  },
  {
    iso2: 'PF',
    iso3: 'PYF'
  },
  {
    iso2: 'TF',
    iso3: 'ATF'
  },
  {
    iso2: 'GA',
    iso3: 'GAB'
  },
  {
    iso2: 'GM',
    iso3: 'GMB'
  },
  {
    iso2: 'GE',
    iso3: 'GEO'
  },
  {
    iso2: 'DE',
    iso3: 'DEU'
  },
  {
    iso2: 'GH',
    iso3: 'GHA'
  },
  {
    iso2: 'GI',
    iso3: 'GIB'
  },
  {
    iso2: 'GR',
    iso3: 'GRC'
  },
  {
    iso2: 'GL',
    iso3: 'GRL'
  },
  {
    iso2: 'GD',
    iso3: 'GRD'
  },
  {
    iso2: 'GP',
    iso3: 'GLP'
  },
  {
    iso2: 'GU',
    iso3: 'GUM'
  },
  {
    iso2: 'GT',
    iso3: 'GTM'
  },
  {
    iso2: 'GG',
    iso3: 'GGY'
  },
  {
    iso2: 'GN',
    iso3: 'GIN'
  },
  {
    iso2: 'GW',
    iso3: 'GNB'
  },
  {
    iso2: 'GY',
    iso3: 'GUY'
  },
  {
    iso2: 'HT',
    iso3: 'HTI'
  },
  {
    iso2: 'HM',
    iso3: 'HMD'
  },
  {
    iso2: 'VA',
    iso3: 'VAT'
  },
  {
    iso2: 'HN',
    iso3: 'HND'
  },
  {
    iso2: 'HK',
    iso3: 'HKG'
  },
  {
    iso2: 'HU',
    iso3: 'HUN'
  },
  {
    iso2: 'IS',
    iso3: 'ISL'
  },
  {
    iso2: 'IN',
    iso3: 'IND'
  },
  {
    iso2: 'ID',
    iso3: 'IDN'
  },
  {
    iso2: 'IR',
    iso3: 'IRN'
  },
  {
    iso2: 'IQ',
    iso3: 'IRQ'
  },
  {
    iso2: 'IE',
    iso3: 'IRL'
  },
  {
    iso2: 'IM',
    iso3: 'IMN'
  },
  {
    iso2: 'IL',
    iso3: 'ISR'
  },
  {
    iso2: 'IT',
    iso3: 'ITA'
  },
  {
    iso2: 'JM',
    iso3: 'JAM'
  },
  {
    iso2: 'JP',
    iso3: 'JPN'
  },
  {
    iso2: 'JE',
    iso3: 'JEY'
  },
  {
    iso2: 'JO',
    iso3: 'JOR'
  },
  {
    iso2: 'KZ',
    iso3: 'KAZ'
  },
  {
    iso2: 'KE',
    iso3: 'KEN'
  },
  {
    iso2: 'KI',
    iso3: 'KIR'
  },
  {
    iso2: 'KP',
    iso3: 'PRK'
  },
  {
    iso2: 'KR',
    iso3: 'KOR'
  },
  {
    iso2: 'KR',
    iso3: 'KOR'
  },
  {
    iso2: 'KW',
    iso3: 'KWT'
  },
  {
    iso2: 'KG',
    iso3: 'KGZ'
  },
  {
    iso2: 'LA',
    iso3: 'LAO'
  },
  {
    iso2: 'LV',
    iso3: 'LVA'
  },
  {
    iso2: 'LB',
    iso3: 'LBN'
  },
  {
    iso2: 'LS',
    iso3: 'LSO'
  },
  {
    iso2: 'LR',
    iso3: 'LBR'
  },
  {
    iso2: 'LY',
    iso3: 'LBY'
  },
  {
    iso2: 'LY',
    iso3: 'LBY'
  },
  {
    iso2: 'LI',
    iso3: 'LIE'
  },
  {
    iso2: 'LT',
    iso3: 'LTU'
  },
  {
    iso2: 'LU',
    iso3: 'LUX'
  },
  {
    iso2: 'MO',
    iso3: 'MAC'
  },
  {
    iso2: 'MK',
    iso3: 'MKD'
  },
  {
    iso2: 'MG',
    iso3: 'MDG'
  },
  {
    iso2: 'MW',
    iso3: 'MWI'
  },
  {
    iso2: 'MY',
    iso3: 'MYS'
  },
  {
    iso2: 'MV',
    iso3: 'MDV'
  },
  {
    iso2: 'ML',
    iso3: 'MLI'
  },
  {
    iso2: 'MT',
    iso3: 'MLT'
  },
  {
    iso2: 'MH',
    iso3: 'MHL'
  },
  {
    iso2: 'MQ',
    iso3: 'MTQ'
  },
  {
    iso2: 'MR',
    iso3: 'MRT'
  },
  {
    iso2: 'MU',
    iso3: 'MUS'
  },
  {
    iso2: 'YT',
    iso3: 'MYT'
  },
  {
    iso2: 'MX',
    iso3: 'MEX'
  },
  {
    iso2: 'FM',
    iso3: 'FSM'
  },
  {
    iso2: 'MD',
    iso3: 'MDA'
  },
  {
    iso2: 'MC',
    iso3: 'MCO'
  },
  {
    iso2: 'MN',
    iso3: 'MNG'
  },
  {
    iso2: 'ME',
    iso3: 'MNE'
  },
  {
    iso2: 'MS',
    iso3: 'MSR'
  },
  {
    iso2: 'MA',
    iso3: 'MAR'
  },
  {
    iso2: 'MZ',
    iso3: 'MOZ'
  },
  {
    iso2: 'MM',
    iso3: 'MMR'
  },
  {
    iso2: 'MM',
    iso3: 'MMR'
  },
  {
    iso2: 'NA',
    iso3: 'NAM'
  },
  {
    iso2: 'NR',
    iso3: 'NRU'
  },
  {
    iso2: 'NP',
    iso3: 'NPL'
  },
  {
    iso2: 'NL',
    iso3: 'NLD'
  },
  {
    iso2: 'AN',
    iso3: 'ANT'
  },
  {
    iso2: 'NC',
    iso3: 'NCL'
  },
  {
    iso2: 'NZ',
    iso3: 'NZL'
  },
  {
    iso2: 'NI',
    iso3: 'NIC'
  },
  {
    iso2: 'NE',
    iso3: 'NER'
  },
  {
    iso2: 'NG',
    iso3: 'NGA'
  },
  {
    iso2: 'NU',
    iso3: 'NIU'
  },
  {
    iso2: 'NF',
    iso3: 'NFK'
  },
  {
    iso2: 'MP',
    iso3: 'MNP'
  },
  {
    iso2: 'NO',
    iso3: 'NOR'
  },
  {
    iso2: 'OM',
    iso3: 'OMN'
  },
  {
    iso2: 'PK',
    iso3: 'PAK'
  },
  {
    iso2: 'PW',
    iso3: 'PLW'
  },
  {
    iso2: 'PS',
    iso3: 'PSE'
  },
  {
    iso2: 'PA',
    iso3: 'PAN'
  },
  {
    iso2: 'PG',
    iso3: 'PNG'
  },
  {
    iso2: 'PY',
    iso3: 'PRY'
  },
  {
    iso2: 'PE',
    iso3: 'PER'
  },
  {
    iso2: 'PH',
    iso3: 'PHL'
  },
  {
    iso2: 'PN',
    iso3: 'PCN'
  },
  {
    iso2: 'PL',
    iso3: 'POL'
  },
  {
    iso2: 'PT',
    iso3: 'PRT'
  },
  {
    iso2: 'PR',
    iso3: 'PRI'
  },
  {
    iso2: 'QA',
    iso3: 'QAT'
  },
  {
    iso2: 'RE',
    iso3: 'REU'
  },
  {
    iso2: 'RO',
    iso3: 'ROU'
  },
  {
    iso2: 'RU',
    iso3: 'RUS'
  },
  {
    iso2: 'RU',
    iso3: 'RUS'
  },
  {
    iso2: 'RW',
    iso3: 'RWA'
  },
  {
    iso2: 'SH',
    iso3: 'SHN'
  },
  {
    iso2: 'KN',
    iso3: 'KNA'
  },
  {
    iso2: 'LC',
    iso3: 'LCA'
  },
  {
    iso2: 'PM',
    iso3: 'SPM'
  },
  {
    iso2: 'VC',
    iso3: 'VCT'
  },
  {
    iso2: 'VC',
    iso3: 'VCT'
  },
  {
    iso2: 'VC',
    iso3: 'VCT'
  },
  {
    iso2: 'WS',
    iso3: 'WSM'
  },
  {
    iso2: 'SM',
    iso3: 'SMR'
  },
  {
    iso2: 'ST',
    iso3: 'STP'
  },
  {
    iso2: 'SA',
    iso3: 'SAU'
  },
  {
    iso2: 'SN',
    iso3: 'SEN'
  },
  {
    iso2: 'RS',
    iso3: 'SRB'
  },
  {
    iso2: 'SC',
    iso3: 'SYC'
  },
  {
    iso2: 'SL',
    iso3: 'SLE'
  },
  {
    iso2: 'SG',
    iso3: 'SGP'
  },
  {
    iso2: 'SK',
    iso3: 'SVK'
  },
  {
    iso2: 'SI',
    iso3: 'SVN'
  },
  {
    iso2: 'SB',
    iso3: 'SLB'
  },
  {
    iso2: 'SO',
    iso3: 'SOM'
  },
  {
    iso2: 'ZA',
    iso3: 'ZAF'
  },
  {
    iso2: 'GS',
    iso3: 'SGS'
  },
  {
    iso2: 'SS',
    iso3: 'SSD'
  },
  {
    iso2: 'ES',
    iso3: 'ESP'
  },
  {
    iso2: 'LK',
    iso3: 'LKA'
  },
  {
    iso2: 'SD',
    iso3: 'SDN'
  },
  {
    iso2: 'SR',
    iso3: 'SUR'
  },
  {
    iso2: 'SJ',
    iso3: 'SJM'
  },
  {
    iso2: 'SZ',
    iso3: 'SWZ'
  },
  {
    iso2: 'SE',
    iso3: 'SWE'
  },
  {
    iso2: 'CH',
    iso3: 'CHE'
  },
  {
    iso2: 'SY',
    iso3: 'SYR'
  },
  {
    iso2: 'TW',
    iso3: 'TWN'
  },
  {
    iso2: 'TW',
    iso3: 'TWN'
  },
  {
    iso2: 'TJ',
    iso3: 'TJK'
  },
  {
    iso2: 'TZ',
    iso3: 'TZA'
  },
  {
    iso2: 'TH',
    iso3: 'THA'
  },
  {
    iso2: 'TL',
    iso3: 'TLS'
  },
  {
    iso2: 'TG',
    iso3: 'TGO'
  },
  {
    iso2: 'TK',
    iso3: 'TKL'
  },
  {
    iso2: 'TO',
    iso3: 'TON'
  },
  {
    iso2: 'TT',
    iso3: 'TTO'
  },
  {
    iso2: 'TN',
    iso3: 'TUN'
  },
  {
    iso2: 'TR',
    iso3: 'TUR'
  },
  {
    iso2: 'TM',
    iso3: 'TKM'
  },
  {
    iso2: 'TC',
    iso3: 'TCA'
  },
  {
    iso2: 'TV',
    iso3: 'TUV'
  },
  {
    iso2: 'UG',
    iso3: 'UGA'
  },
  {
    iso2: 'UA',
    iso3: 'UKR'
  },
  {
    iso2: 'AE',
    iso3: 'ARE'
  },
  {
    iso2: 'GB',
    iso3: 'GBR'
  },
  {
    iso2: 'US',
    iso3: 'USA'
  },
  {
    iso2: 'UM',
    iso3: 'UMI'
  },
  {
    iso2: 'UY',
    iso3: 'URY'
  },
  {
    iso2: 'UZ',
    iso3: 'UZB'
  },
  {
    iso2: 'VU',
    iso3: 'VUT'
  },
  {
    iso2: 'VE',
    iso3: 'VEN'
  },
  {
    iso2: 'VE',
    iso3: 'VEN'
  },
  {
    iso2: 'VN',
    iso3: 'VNM'
  },
  {
    iso2: 'VN',
    iso3: 'VNM'
  },
  {
    iso2: 'VG',
    iso3: 'VGB'
  },
  {
    iso2: 'VI',
    iso3: 'VIR'
  },
  {
    iso2: 'WF',
    iso3: 'WLF'
  },
  {
    iso2: 'EH',
    iso3: 'ESH'
  },
  {
    iso2: 'YE',
    iso3: 'YEM'
  },
  {
    iso2: 'ZM',
    iso3: 'ZMB'
  },
  {
    iso2: 'ZW',
    iso3: 'ZWE'
  }
];
