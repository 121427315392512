import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, DropdownItem, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { customAlphabet } from 'nanoid';
import { ChromePicker } from 'react-color';
import produce from 'immer';
import { isEmpty } from 'lodash';

import { SmvCustomDropdown } from 'src/smoove/components/misc';

import { getQuestionnaireElementsOrder, isLooped, useTranslationHooks, getQueryBuilderSources } from 'smv-helpers';

import { QueryBuilder } from 'smv-components/querybuilder';
import {
  getQueryBuilderEmptyState,
  getQuerybuilderErrors,
  isQueryBuilderAnEmptyGroup,
  isQueryBuilderEmptyState
} from 'smv-components/querybuilder/helper';

const INITIAL_VIEW = {
  id: null,
  label: '',
  viewFilter: {},
  color: '',
  hidden: false
};

export const View = ({ setViews, view = INITIAL_VIEW, tableConfig }) => {
  const questions = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations ?? []);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const [_filter, setFilter] = useState(!isEmpty(view.viewFilter) ? view.viewFilter : getQueryBuilderEmptyState());

  const [_view, setView] = useState(view);
  const [_viewLabel, setViewLabel] = useState(_view?.label ?? '');
  const [canBeSaved, setCanBeSaved] = useState(false);

  const viewContainerRef = useRef(null);

  const translationProps = useTranslationHooks();

  const sources = useMemo(() => {
    const WHITELIST_QUESTION_TYPES = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix'];

    const queOrder = getQuestionnaireElementsOrder(questions);
    const _questions = { list: {}, order: [] };
    (queOrder ?? []).forEach(elementid => {
      const element = questions.list[elementid];
      const { isLoopedQuestion } = isLooped(questions.list, element);

      if (element.type === 'question' && !isLoopedQuestion && WHITELIST_QUESTION_TYPES.includes(element.config.qtype)) {
        _questions.order.push(elementid);
        _questions.list[elementid] = element;
      }
    });
    const rawSources = {
      question: _questions,
      sysvar: systemVariables,
      calcvar: {
        list: Object.fromEntries(calculations?.map(value => [value.id, value])),
        order: calculations?.map(el => el.id)
      }
    };

    const _sources = getQueryBuilderSources(rawSources, null, 'above', true, true, translationProps);

    return _sources;
  }, [questions, systemVariables, calculations, translationProps]);

  useEffect(() => {
    if (_view.id) {
      setViews(s =>
        produce(s, d => {
          d.list[_view.id] = {
            ..._view
          };
        })
      );
    }
  }, [_view, setViews]);

  useEffect(() => {
    if (_filter) {
      const queryBuilderErrors = getQuerybuilderErrors(_filter, sources);

      if (isQueryBuilderAnEmptyGroup(_filter)) {
        setFilter(getQueryBuilderEmptyState());
      }

      if (queryBuilderErrors.length === 0 && !isQueryBuilderEmptyState(_filter)) {
        setView(s =>
          produce(s, d => {
            d.viewFilter = _filter;
          })
        );

        const hasColorSelected = _view.color.length > 0;
        const hasViewLabel = _view.label.length > 0;

        if (hasViewLabel && hasColorSelected) {
          setCanBeSaved(true);
        } else {
          setCanBeSaved(false);
        }
      }
    }
  }, [_filter, _view, sources]);

  const handleSetViewLabel = useCallback(e => setViewLabel(e?.target?.value ?? ''), []);

  const handleSaveViewLabel = useCallback(
    e => {
      const { value } = e?.target ?? {};
      setView(s =>
        produce(s, d => {
          d.label = value;
        })
      );
    },
    [setView]
  );

  const handleColorChange = useCallback(color => {
    setView(s =>
      produce(s, d => {
        d.color = color.hex;
      })
    );
  }, []);

  const saveViewHandler = () => {
    setViews(s =>
      produce(s, d => {
        let viewId = _view.id;
        if (!viewId) {
          viewId = customAlphabet('1234567890abcdef', 24)();
          d.order.push(viewId);
        }

        d.list[viewId] = {
          ..._view,
          id: viewId
        };

        if (!_view.id) setView(INITIAL_VIEW);
      })
    );
  };

  const duplicateViewHandler = () => {
    setViews(s =>
      produce(s, d => {
        const viewId = customAlphabet('1234567890abcdef', 24)();
        d.order.push(viewId);
        d.list[viewId] = produce(_view, d => {
          d.id = viewId;
        });
      })
    );
  };

  const deleteViewHandler = () => {
    setViews(s =>
      produce(s, d => {
        d.order = d.order.filter(v => v !== _view.id);
        delete d.list[_view.id];
      })
    );
  };

  const setViewToHiddenHandler = () => {
    setView(s =>
      produce(s, d => {
        d.hidden = !d.hidden;
      })
    );
  };

  return (
    <>
      <Row className='w-100'>
        <Col className={'view__container'}>
          {/* <Label className='view__label' for={'view-label-input'}>view label</Label> */}
          <Input
            className='view__label_input'
            id={'view-label-input'}
            name={'label'}
            placeholder={'Enter label'}
            value={_viewLabel}
            onChange={handleSetViewLabel}
            onBlur={handleSaveViewLabel}
          />
          <div className='view__color-container' ref={viewContainerRef}>
            <span className='ml-2 mr-1 fas fa-colon'></span>
            <div className='d-flex align-items-center'>
              <SmvCustomDropdown
                fixedMenu={true}
                container={viewContainerRef}
                faIconString={'far fa-fill-drip icon-smv-blue p-1'}
                className={'view__color-dropdown'}
              >
                <DropdownItem toggle={false}>
                  <ChromePicker
                    color={_view.color}
                    disableAlpha={true}
                    onChange={handleColorChange}
                    onChangeComplete={handleColorChange}
                  />
                </DropdownItem>
              </SmvCustomDropdown>

              {_view.color && (
                <span
                  className={`mr-1 view__backgroundcolor ${!_view.color ? 'view__backgroundcolor--empty' : ''}`}
                  style={{
                    backgroundColor: _view?.color ?? '#ffffff'
                  }}
                />
              )}
            </div>
          </div>
          <div className={'view__buttons'}>
            {!_view.id && (
              <>
                <UncontrolledTooltip target={`add_view`} delay={{ hide: 0 }} autohide={false}>
                  Add View
                </UncontrolledTooltip>
                <Button
                  id={`add_view`}
                  className={'ml-1'}
                  color={canBeSaved ? 'primary' : 'grey'}
                  disabled={!canBeSaved}
                  onClick={saveViewHandler}
                  size={'sm'}
                  outline
                >
                  <i className='fal fa-plus'></i>
                </Button>
              </>
            )}
            {!!_view.id && (
              <>
                <UncontrolledTooltip target={`hide_view`} delay={{ hide: 0 }} autohide={false}>
                  Hide View
                </UncontrolledTooltip>
                <Button
                  id={`hide_view`} //
                  className={'ml-1'}
                  color={`secondary ${_view.hidden ? 'active' : ''}`}
                  onClick={setViewToHiddenHandler}
                  size={'sm'}
                  outline
                >
                  <i className='fal fa-eye-slash'></i>
                </Button>
                <UncontrolledTooltip target={`duplicate_view`} delay={{ hide: 0 }} autohide={false}>
                  Duplicate View
                </UncontrolledTooltip>
                <Button
                  id={`duplicate_view`}
                  className={'ml-1'}
                  color={'secondary'}
                  onClick={duplicateViewHandler}
                  size={'sm'}
                  outline
                >
                  <i className='fal fa-copy'></i>
                </Button>
                <UncontrolledTooltip target={`delete_view`} delay={{ hide: 0 }} autohide={false}>
                  Delete View
                </UncontrolledTooltip>
                <Button
                  id={`delete_view`}
                  className={'ml-1'}
                  color={'danger'}
                  onClick={deleteViewHandler}
                  size={'sm'}
                  outline
                >
                  <i className='fal fa-trash-alt'></i>
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row className='w-100'>
        <QueryBuilder
          name='value'
          fields={sources}
          queryBuilder={_filter}
          readOnly={false}
          setQueryBuilder={setFilter}
        />
      </Row>
    </>
  );
};
