import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { ExportFileButtonAndModal } from '../misc';

import './DashboardElementMenu.scss';

export const DashboardElementMenu = ({
  handler,
  element,
  exportHandler = null,
  exportOptions = null,
  toggleMediaLibrary = null,
  toggleEditTextModal = null,
  isInvalid = false,
  reportId = null,
  activeFilters = null
}) => {
  const elementId = element.id;
  if (isInvalid)
    return (
      <div className={`smv-dashboard-element-menu`}>
        <div className='smv-dashboard-element-menu__item' id={`delete-${elementId}`}>
          <UncontrolledTooltip target={`delete-${elementId}`} delay={{ hide: 0 }} autohide={false}>
            Delete
          </UncontrolledTooltip>
          <i className='far fa-fw fa-trash-alt' onClick={() => handler.deleteElement(elementId)}></i>
        </div>
      </div>
    );

  return (
    <div className={`smv-dashboard-element-menu`}>
      <div className='smv-dashboard-element-menu__item' id={`edit-${elementId}`}>
        <UncontrolledTooltip target={`edit-${elementId}`} delay={{ hide: 0 }} autohide={false}>
          Edit
        </UncontrolledTooltip>
        <i className='far fa-fw fa-edit' onClick={() => handler.openConfigDrawer(elementId)}></i>
      </div>
      {toggleMediaLibrary && (
        <div className='smv-dashboard-element-menu__item' id={`media-${elementId}`}>
          <UncontrolledTooltip target={`media-${elementId}`} delay={{ hide: 0 }} autohide={false}>
            Open media library
          </UncontrolledTooltip>
          <i className='far fa-fw fa-photo-film' onClick={toggleMediaLibrary}></i>
        </div>
      )}
      {toggleEditTextModal && (
        <div className='smv-dashboard-element-menu__item' id={`edit-text-${elementId}`}>
          <UncontrolledTooltip target={`edit-text-${elementId}`} delay={{ hide: 0 }} autohide={false}>
            Edit Text & Color
          </UncontrolledTooltip>
          <i className='far fa-fw fa-text' onClick={toggleEditTextModal}></i>
        </div>
      )}
      <div className='smv-dashboard-element-menu__item' id={`copy-${elementId}`}>
        <UncontrolledTooltip target={`copy-${elementId}`} delay={{ hide: 0 }} autohide={false}>
          Duplicate
        </UncontrolledTooltip>
        <i className='far fa-fw fa-copy' onClick={() => handler.copyElement(elementId)}></i>
      </div>
      {exportHandler && (
        <div className='smv-dashboard-element-menu__item' id={`export-${elementId}`}>
          <UncontrolledTooltip target={`export-${elementId}`} delay={{ hide: 0 }} autohide={false}>
            Export
          </UncontrolledTooltip>
          <ExportFileButtonAndModal
            type={'element'}
            iconOnly={true}
            exportHandler={exportHandler}
            exportOptions={exportOptions}
            reportId={reportId}
            activeFilters={activeFilters}
          />
        </div>
      )}
      <div className='smv-dashboard-element-menu__item' id={`delete-${elementId}`}>
        <UncontrolledTooltip target={`delete-${elementId}`} delay={{ hide: 0 }} autohide={false}>
          Delete
        </UncontrolledTooltip>
        <i className='far fa-fw fa-trash-alt' onClick={() => handler.deleteElement(elementId)}></i>
      </div>
    </div>
  );
};
