import { isEqual, pick } from 'lodash';
import { useMemo } from 'react';

export const pickHeadKeys = ['headId', 'headSubId'];
export const pickRowKeys = ['rowdId', 'rowSubId', 'rowSubSubId'];

export const useIsHovered = (hoveredCell, cellIdentifier, significanceDimension) =>
  useMemo(() => {
    let isHovered = false,
      isHoveredColumn = false,
      isHoveredRow = false,
      isHoveredCell = false;
    if (cellIdentifier && hoveredCell) {
      if (isEqual(pick(hoveredCell, pickHeadKeys), pick(cellIdentifier, pickHeadKeys))) isHoveredColumn = true;
      if (isEqual(pick(hoveredCell, pickRowKeys), pick(cellIdentifier, pickRowKeys))) isHoveredRow = true;
      if (isEqual(hoveredCell, cellIdentifier)) isHoveredCell = true;
      isHovered =
        (significanceDimension === 'heads' && isHoveredRow) || (significanceDimension === 'rows' && isHoveredColumn);
    }

    return { isHovered, isHoveredColumn, isHoveredRow, isHoveredCell };
  }, [hoveredCell, cellIdentifier, significanceDimension]);

export const useSignificanceFlagsHover = (
  hoveredCell,
  cellIdentifier,
  cell,
  significanceDimension,
  significanceGate,
  significanceBenchmark
) => {
  return useMemo(() => {
    let isSignificantHighHover = false,
      isSignificantLowHover = false;

    /**
     * if significance has a benchmark, do not use hover effects
     */
    if (!!significanceBenchmark) return { isSignificantHighHover, isSignificantLowHover };

    if (significanceDimension === 'heads') {
      const significances = cell?.significances ?? null;

      if (significances && hoveredCell && isEqual(pick(hoveredCell, pickRowKeys), pick(cellIdentifier, pickRowKeys))) {
        const chiValue = significances?.[hoveredCell?.headId]?.[hoveredCell?.headSubId];
        if (chiValue) {
          if (chiValue.val > significanceGate) isSignificantHighHover = true;
          if (chiValue.val < -significanceGate) isSignificantLowHover = true;
        }
      }
    } else if (significanceDimension === 'rows') {
      const rowSignificances = cell?.rowSignificances ?? null;

      if (
        rowSignificances &&
        hoveredCell &&
        isEqual(pick(hoveredCell, pickHeadKeys), pick(cellIdentifier, pickHeadKeys))
      ) {
        const chiValue =
          rowSignificances?.[hoveredCell?.rowId]?.[hoveredCell?.rowSubId]?.[hoveredCell?.rowSubSubId] ??
          rowSignificances?.[hoveredCell?.rowId]?.[hoveredCell?.rowSubId];
        if (chiValue) {
          if (chiValue.val > significanceGate) isSignificantHighHover = true;
          if (chiValue.val < -significanceGate) isSignificantLowHover = true;
        }
      }
    }

    return { isSignificantHighHover, isSignificantLowHover };
  }, [hoveredCell, cellIdentifier, cell, significanceDimension, significanceGate, significanceBenchmark]);
};

export const useSignificanceFlags = (cell, significanceDimension, significanceGate, significanceBenchmark) => {
  return useMemo(() => {
    let isSignificantHigh = false,
      isSignificantLow = false;

    if (significanceDimension === 'heads') {
      const significances = cell?.significances ?? {};
      Object.keys(significances).forEach(headid => {
        Object.keys(significances[headid]).forEach(headsubid => {
          if (
            !!significanceBenchmark &&
            (headid !== significanceBenchmark?.headId || headsubid !== significanceBenchmark?.headSubId)
          ) {
            return;
          }
          const value = significances[headid][headsubid];

          if (value.val > significanceGate) isSignificantHigh = true;
          if (value.val < -significanceGate) isSignificantLow = true;
        });
      });
    } else if (significanceDimension === 'rows') {
      const rowSignificances = cell?.rowSignificances ?? {};
      Object.keys(rowSignificances).forEach(rowId => {
        Object.keys(rowSignificances[rowId]).forEach(rowSubId => {
          if (typeof rowSignificances[rowId][rowSubId] === 'object' && !!rowSignificances[rowId][rowSubId]?.val) {
            if (
              !!significanceBenchmark &&
              (rowId !== significanceBenchmark?.rowId || rowSubId !== significanceBenchmark?.rowSubId)
            ) {
              return;
            }
            const value = rowSignificances[rowId][rowSubId];

            if (value.val > significanceGate) isSignificantHigh = true;
            if (value.val < -significanceGate) isSignificantLow = true;
          } else if (typeof rowSignificances[rowId][rowSubId] === 'object' && !rowSignificances[rowId][rowSubId]?.val) {
            Object.keys(rowSignificances[rowId][rowSubId]).forEach(rowSubSubId => {
              if (
                !!significanceBenchmark &&
                (rowId !== significanceBenchmark?.rowId ||
                  rowSubId !== significanceBenchmark?.rowSubId ||
                  rowSubSubId !== significanceBenchmark?.rowSubSubId)
              ) {
                return;
              }
              const value = rowSignificances[rowId][rowSubId][rowSubSubId];

              if (value.val > significanceGate) isSignificantHigh = true;
              if (value.val < -significanceGate) isSignificantLow = true;
            });
          }
        });
      });
    }

    return { isSignificantHigh, isSignificantLow };
  }, [cell, significanceDimension, significanceGate, significanceBenchmark]);
};
