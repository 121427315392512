import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Store as notify } from 'react-notifications-component';
import { projectSettingsActions, questionnaireActions } from '../../../redux/actions';

import { Question, Page, Container } from '.';
import { defaultNotifyProps } from 'smv-constants';
import { useIntl } from 'react-intl';

export const ElementSwitch = ({ index, elementid, writeable, questionnaire, parentsIds, surveyId }) => {
  const intl = useIntl();
  const _isCollapsed = useSelector(
    state => state.projectSettings?.[state.survey.id]?.queCollapseMap?.[elementid] ?? true
  );

  // todo: can this be refactored in a better way so element is not rerendered after dragging?
  const element = questionnaire.list[elementid];

  const [isCollapsed, setIsCollapsed] = useState(_isCollapsed);
  useEffect(() => {
    setIsCollapsed(_isCollapsed);
  }, [_isCollapsed]);

  const handler = useMemo(() => {
    return {
      toggleCollapse: () => {
        setIsCollapsed(b => {
          const newValue = !b;
          projectSettingsActions.setCollapse(surveyId, elementid, newValue);
          return newValue;
        });
      },
      duplicateQuestionnaireElement: () => {
        questionnaireActions.copyQuestionnaireElement(surveyId, elementid);
      }
    };
  }, [elementid, surveyId]);

  // Drop
  // eslint-disable-next-line
  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: ['question', 'page', 'container'],
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      isOverCurrent: !!monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      dropItem: monitor.getItem()
    }),
    canDrop: (item, monitor) => {
      if (item.id) return false;
      if (item.type === 'page') {
        if (element.type === 'page') return false;

        // check if parent already contains a page
        for (const id of parentsIds) {
          const parentElement = questionnaire.list[id];
          if (parentElement?.type === 'page') {
            return false;
          }
        }
      }

      return true;
    },
    drop: (item, monitor) => {
      if (monitor.didDrop() || !monitor.canDrop()) return;
      let title = item.type;
      if (item.type === 'container') title = 'group';

      const content = {
        title: `New ${title}`,
        type: item.type,
        config: {},
        parentId: element.parentId ?? null,
        position: index + 1
      };

      // Set qtype for questions
      if (item.type === 'question') content.config.qtype = item.qtype;

      // add element to parent/ root on specified position (above another item)
      questionnaireActions.addQuestionnaireElement(surveyId, content).then(res => {
        if (res.isAxiosError) {
          notify.addNotification({
            title: intl.formatMessage({
              id: 'smoove.questionnaire.elements.add-element-notification-error',
              defaultMessage: 'An error occurred.'
            }),

            type: 'danger',
            ...defaultNotifyProps
          });
        } else {
          notify.addNotification({
            title: intl.formatMessage({
              id: 'smoove.questionnaire.elements.add-element-notification-success',
              defaultMessage: 'Item Successflly removed.'
            }),

            type: 'success',
            ...defaultNotifyProps
          });
        }
      });
    }
  });

  if (element.type === 'container')
    return (
      <div ref={drop} style={{ ...(isOverCurrent && canDrop ? { outline: '1px dashed black' } : {}) }}>
        <Container
          key={element.id}
          container={element}
          isCollapsed={isCollapsed}
          handler={handler}
          writeable={writeable}
          questionnaire={questionnaire}
          parentsIds={[...parentsIds]}
          surveyId={surveyId}
        />
      </div>
    );

  if (element.type === 'page')
    return (
      <div ref={drop} style={{ ...(isOverCurrent && canDrop ? { outline: '1px dashed black' } : {}) }}>
        <Page
          key={element.id}
          page={element}
          isCollapsed={isCollapsed}
          handler={handler}
          writeable={writeable}
          questionnaire={questionnaire}
          parentsIds={[...parentsIds]}
          surveyId={surveyId}
        />
      </div>
    );

  if (element.type === 'question')
    return (
      <div ref={drop} style={{ ...(isOverCurrent && canDrop ? { outline: '1px dashed black' } : {}) }}>
        <Question
          key={element.id}
          question={element}
          isCollapsed={isCollapsed}
          handler={handler}
          writeable={writeable}
          questionnaire={questionnaire}
          parentsIds={[...parentsIds]}
        />
      </div>
    );

  return <div>element type unknown</div>;
};
