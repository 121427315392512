import React, { useCallback, useState, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Col, Row } from 'reactstrap';

import { isArray } from 'lodash';
import produce from 'immer';
import classNames from 'classnames';

import { BaseFilter } from '../setting-components';
import { HeadSortationSettings } from '.';

export const HeadQuestionSettingsButton = ({ toggle }) => {
  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i
        className={classNames({
          'fal fa-cog': true,
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>{`settings`}</span>
    </div>
  );
};

export const HeadQuestionSettingsModal = props => {
  const { isOpen, toggle, headConfig = {}, headid, handler, isCompactMatrix, tableConfig } = props;
  // for compact matrix tables, the headConfig actually contains the rowConfig
  const [internalHeadConfig, setInternalHeadConfig] = useState(isArray(headConfig) ? {} : headConfig);

  const saveHandler = useCallback(
    internalHeadConfig => {
      handler.setTableConfig(state =>
        produce(state, draft => {
          if (isCompactMatrix) draft.rows.list[headid].config = internalHeadConfig;
          else draft.heads.list[headid].config = internalHeadConfig;
        })
      );
      toggle();
    },
    [handler, toggle, headid, isCompactMatrix]
  );

  const internalHandler = useMemo(() => {
    return {
      setInternalConfig: setInternalHeadConfig,
      setCheckboxValue: e => {
        const { name, checked } = e.target;
        setInternalHeadConfig(state =>
          produce(state, draftState => {
            draftState[name] = checked;
          })
        );
      },
      setNumericValue: e => {
        const { name, value } = e.target;
        const _value = parseFloat(value);
        setInternalHeadConfig(state =>
          produce(state, draftState => {
            draftState[name] = _value;
          })
        );
      }
    };
  }, []);

  const saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      saveHandler(internalHeadConfig);
    },
    [saveHandler, internalHeadConfig]
  );

  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'} centered>
      <Form onSubmit={saveOnSubmit}>
        <ModalHeader toggle={toggle}>Head Settings</ModalHeader>
        <ModalBody>
          <Row className='mb-3'>
            {!isCompactMatrix && (
              <Col>
                <BaseFilter rowid={headid} handler={internalHandler} config={internalHeadConfig} />
              </Col>
            )}
          </Row>
          <Row className='mb-3'>
            <Col>
              <HeadSortationSettings
                tableConfig={tableConfig}
                internalHandler={internalHandler}
                internalHeadConfig={internalHeadConfig}
                {...props}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => saveHandler(internalHeadConfig)}>
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
