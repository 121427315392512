import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { getMergedChoices } from './helper';
import { stripHtml } from 'smv-helpers';

import './StaticFiltersWarning.scss';

export const StaticFiltersWarning = ({ reportid, className }) => {
  const staticFilters = useSelector(state => state.survey.reports?.list?.[reportid]?.config?.staticFilters ?? null);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const questionnaire = useSelector(state => state.survey?.questionnaire ?? { list: {}, order: [] });
  const translations = useSelector(state => state.survey?.translations ?? {});
  const mainLocale = useSelector(state => state.survey?.locales?.main ?? {});

  const filterLabels = useMemo(() => {
    if (!staticFilters || !systemVariables) return [];
    let labels = [];
    staticFilters?.order?.forEach(filterid => {
      const sourceId = staticFilters.list[filterid].sourceId;

      if (staticFilters.list[filterid]?.sourceType === 'sysvar') {
        const filterValues = Object.keys(staticFilters.list[filterid].sourceSubElements);
        const selectedSysvars = Object.values(systemVariables.list?.[sourceId]?.list ?? {});
        selectedSysvars.forEach(sysvar => {
          if (filterValues.includes(sysvar.id) || filterValues.includes(sysvar.locale)) {
            labels.push(sysvar.label);
          }
        });
      }

      if (staticFilters.list[filterid]?.sourceType === 'question') {
        const filterValues = Object.keys(staticFilters.list[filterid].sourceSubElements);
        const selectedQuestion = questionnaire.list?.[sourceId];
        if (!selectedQuestion) return;
        let sourceElement = questionnaire?.list?.[sourceId];
        if (!sourceElement) return;
        const sourceElementValues = getMergedChoices(sourceElement, sourceElement.config?.choices, selectedQuestion);
        if (!sourceElementValues) return;
        filterValues.forEach(el => {
          const element = sourceElementValues.list[el];
          if (!element) return;
          const labelId = element.config.translations.label;
          const translation = translations[labelId]?.[mainLocale];
          labels.push(stripHtml(translation));
        });
      }
    });
    return labels;
  }, [staticFilters, systemVariables, questionnaire, translations, mainLocale]);

  if (!staticFilters || staticFilters?.order.length <= 0) return null;

  return (
    <div className={`static-filters-warning ${className}`} id={`tt_static-filters-warning`}>
      <i className='fal fa-filter fa-fw mr-1'></i>
      <FormattedMessage
        id={'smoove.page.project.results.filter-warning.static-filter'}
        defaultMessage={'Static filter'}
      />
      <UncontrolledTooltip target={`tt_static-filters-warning`}>
        <span className='mr-1'>
          <FormattedMessage
            id={'smoove.page.project.results.filter-warning.filter-warning'}
            defaultMessage={'The Report is filtered for the following values:'}
          />
        </span>
        {filterLabels.map((label, idx) => {
          return <span key={`${idx}_${label}`}>{`${label}${idx < filterLabels.length - 1 ? ', ' : ''}`}</span>;
        })}
      </UncontrolledTooltip>
    </div>
  );
};
