import Axios from 'axios';

import 'smv-redux/axiosConfig';
import { store } from 'smv-redux';

import { chartLayersActionTypes } from '../constants';

const loadChartLayers = async surveyId => {
  store.dispatch({
    type: chartLayersActionTypes.LOAD_CHARTLAYERS_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/tables/layers`)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.LOAD_CHARTLAYERS_SUCCESS,
        payload: {
          surveyId,
          chartLayers: { list: res.data.list, order: res.data.order }
        }
      });

      return res.data;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: chartLayersActionTypes.LOAD_CHARTLAYERS_FAILURE,
        payload: err
      });
    });
};

const createChartLayer = async (surveyId, chartLayer) => {
  store.dispatch({
    type: chartLayersActionTypes.CREATE_CHARTLAYER_REQUEST,
    payload: chartLayer
  });

  return Axios.post(`/surveys/${surveyId}/tables/layers`, chartLayer)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.CREATE_CHARTLAYER_SUCCESS,
        payload: {
          layers: res.data
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.CREATE_CHARTLAYER_FAILURE,
        payload: err
      });
    });
};

const updateChartLayerViewsOrder = async (surveyId, layerId, order) => {
  store.dispatch({
    type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_REQUEST,
    payload: order
  });

  return Axios.patch(`/surveys/${surveyId}/tables/layers/${layerId}/order`, order)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_SUCCESS,
        payload: {
          // returns only the updated layer
          layer: res.data
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_FAILURE,
        payload: err
      });
    });
};

const deleteChartLayer = async (surveyId, chartLayerId) => {
  store.dispatch({
    type: chartLayersActionTypes.DELETE_CHARTLAYER_REQUEST,
    payload: { chartLayerId }
  });

  return Axios.delete(`/surveys/${surveyId}/tables/layers/${chartLayerId}`)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.DELETE_CHARTLAYER_SUCCESS
      });
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.DELETE_CHARTLAYER_FAILURE,
        payload: err
      });
    });
};

const createChartLayerView = async (surveyId, chartLayerId, chartLayerView) => {
  store.dispatch({
    type: chartLayersActionTypes.CREATE_CHARTLAYERVIEW_REQUEST,
    payload: chartLayerView
  });

  return Axios.post(`/surveys/${surveyId}/tables/layers/${chartLayerId}/views`, chartLayerView)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.CREATE_CHARTLAYERVIEW_SUCCESS,
        payload: {
          layers: res.data
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.CREATE_CHARTLAYERVIEW_FAILURE,
        payload: err
      });
    });
};

const updateChartLayerView = async (surveyId, chartLayerId, viewId, chartLayerView) => {
  store.dispatch({
    type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEW_REQUEST,
    payload: chartLayerView
  });

  return Axios.patch(`/surveys/${surveyId}/tables/layers/${chartLayerId}/views/${viewId}`, chartLayerView)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEW_SUCCESS,
        payload: {
          layers: res.data
        }
      });
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.UPDATE_CHARTLAYERVIEW_FAILURE,
        payload: err
      });
    });
};

const deleteChartLayerView = async (surveyId, chartLayerId, chartLayerViewId) => {
  store.dispatch({
    type: chartLayersActionTypes.DELETE_CHARTLAYERVIEW_REQUEST,
    payload: { chartLayerViewId }
  });

  return Axios.delete(`/surveys/${surveyId}/tables/layers/${chartLayerId}/views/${chartLayerViewId}`)
    .then(res => {
      store.dispatch({
        type: chartLayersActionTypes.DELETE_CHARTLAYERVIEW_SUCCESS
      });
    })
    .catch(err => {
      store.dispatch({
        type: chartLayersActionTypes.DELETE_CHARTLAYERVIEW_FAILURE,
        payload: err
      });
    });
};

export const chartLayersActions = {
  // Layers
  loadChartLayers,

  // Layer
  createChartLayer,
  updateChartLayerViewsOrder,
  deleteChartLayer,

  // Layer Views
  createChartLayerView,
  updateChartLayerView,
  deleteChartLayerView
};
