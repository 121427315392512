import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Store as notify } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';

import { defaultNotifyProps } from 'smv-constants';

import { LoginForm } from 'src/smoove/components/Login/LoginForm';
import { RegisterForm } from 'src/smoove/components/Login/RegisterForm';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

export const UserInvitation = () => {
  const params = useParams();
  const intl = useIntl();
  const history = useHistory();

  const user = useSelector(state => state.authentication);
  const isLoggedIn = useSelector(state => state.authentication.loggedIn ?? false);
  const [showRegisterForm, setShowRegisterForm] = useState(true);

  const invitationToken = params?.invitationToken;

  const handlePatchInvite = useCallback(() => {
    return axios
      .patch(`/users/me/inviteRequest/${invitationToken}`)
      .then(res => {
        notify.addNotification({
          title: intl.formatMessage({
            id: 'core.page.user-invitation.sucessfully-added-your-acccount-to-the-client-redirecting'
          }),
          type: 'success',
          ...defaultNotifyProps
        });
        history.push(`/one/clients`);
      })
      .catch(err => {
        notify.addNotification({
          title: parseErrorAsMessageString(
            err,
            intl.formatMessage({ id: 'core.page.user-invitation.error-accepting-invitation' }) + err?.message
          ),
          type: 'danger',
          ...defaultNotifyProps,
          dismiss: {
            duration: 4000,
            showIcon: true
          }
        });
        console.log(err);
      });
  }, [invitationToken, intl, history]);

  useEffect(() => {
    if (isLoggedIn && invitationToken) {
      handlePatchInvite();
    }
  }, [isLoggedIn, invitationToken, handlePatchInvite]);

  const handleShowLoginForm = useCallback(() => {
    setShowRegisterForm(false);
  }, []);

  if (!isLoggedIn)
    return (
      <>
        <div className='container-fluid base-content flex-fill'>
          <div className='my-4'>
            {!showRegisterForm && (
              <>
                <h2 className='text-uppercase text-smv-red fw-bold'>
                  <FormattedMessage id={'core.page.login.headline'} defaultMessage={'Login'} />
                </h2>
                <div className='my-2'>
                  <span>
                    {intl.formatMessage({
                      id: 'core.page.user-invitation.you-have-been-invided-to-a-new-organization'
                    })}
                  </span>
                  <span className='ml-1 text-secondary pointer fw-bold' onClick={() => setShowRegisterForm(b => !b)}>
                    {intl.formatMessage({ id: 'core.page.user-invitation.create-a-new-account' })}
                  </span>
                </div>
                <LoginForm user={user} />
              </>
            )}
          </div>
          {showRegisterForm && (
            <>
              <h2 className='text-uppercase text-smv-red fw-bold'>
                {intl.formatMessage({ id: 'core.page.user-invitation.register' })}
              </h2>
              <div className='my-2'>
                {intl.formatMessage({ id: 'core.page.user-invitation.create-a-new-account-make-sure' })}
              </div>
              <RegisterForm user={user} handleShowLoginForm={handleShowLoginForm} />
            </>
          )}
        </div>
      </>
    );

  return (
    <div className='container-fluid base-content flex-fill'>
      <div className='mt-2'>
        <Link to={`/one/clients`}>{intl.formatMessage({ id: 'core.page.user-invitation.to-projects-page' })}</Link>
      </div>
    </div>
  );
};
