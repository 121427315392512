import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { RowLabel } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { surveyActions } from 'smv-redux';

export const SurveyReferenceSettings = ({ survey }) => {
  const intl = useIntl();
  const projectId = useSelector(state => state.project.id ?? {});

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);
  const isUserProjectAdmin = useUserHasPermission(permissionEnum.PROJECT_ADMIN);

  const [clientReference, setClientReference] = useState(survey?.references?.clientReference ?? '');
  const [distributorReference, setDistributorReference] = useState(survey?.references?.distributorReference ?? '');
  const [internalReference, setInternalReference] = useState(survey?.references?.internalReference ?? '');
  const handleSave = useCallback(
    e => {
      const { name, value } = e.target;
      if ((!survey?.references?.[name] && value.length > 0) || (survey?.references?.[name] ?? '') !== value) {
        surveyActions.updateSurvey(projectId, survey.id, {
          references: {
            ...survey.references,
            [name]: value
          }
        });
      }
    },
    [survey, projectId]
  );

  return (
    <Row className='my-4'>
      <RowLabel
        name={'project-reference-settings'}
        title={intl.formatMessage({
          id: 'smoove.page.project.general.reference-settings.label',
          defaultMessage: 'Reference Settings'
        })}
        tooltip={intl.formatMessage({
          id: 'smoove.page.project.general.reference-settings.tooltip',
          defaultMessage: ''
        })}
      />
      <Col md={7}>
        <FormGroup>
          <Label for='client-project-reference'>
            {intl.formatMessage({
              id: 'smoove.page.project.general.reference-settings.client-project-reference.label'
            })}
          </Label>
          <Input
            id='client-project-reference'
            name={'clientReference'}
            tooltip={intl.formatMessage({
              id: 'smoove.page.project.general.reference-settings.client-project-reference.desc'
            })}
            placeholder={intl.formatMessage({
              id: 'smoove.page.project.general.reference-settings.client-project-reference.placeholder'
            })}
            type={'text'}
            value={clientReference}
            onChange={e => setClientReference(e.target.value)}
            onBlur={handleSave}
            disabled={!userCanEditProject}
          />
        </FormGroup>
        {isUserProjectAdmin && (
          <>
            <FormGroup>
              <Label for='distributor-project-reference'>
                {intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.distributor-project-reference.label'
                })}
              </Label>
              <Input
                id='distributor-project-reference'
                name={'distributorReference'}
                tooltip={intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.distributor-project-reference.desc'
                })}
                placeholder={intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.distributor-project-reference.placeholder'
                })}
                type={'text'}
                value={distributorReference}
                onChange={e => setDistributorReference(e.target.value)}
                onBlur={handleSave}
                disabled={!userCanEditProject}
              />
            </FormGroup>
            <FormGroup>
              <Label for='internal-project-reference'>
                {intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.internal-project-reference.label'
                })}
              </Label>
              <Input
                id='internal-project-reference'
                name={'internalReference'}
                tooltip={intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.internal-project-reference.desc'
                })}
                placeholder={intl.formatMessage({
                  id: 'smoove.page.project.general.reference-settings.internal-project-reference.placeholder'
                })}
                type={'text'}
                value={internalReference}
                onChange={e => setInternalReference(e.target.value)}
                onBlur={handleSave}
                disabled={!userCanEditProject}
              />
            </FormGroup>
          </>
        )}
      </Col>
    </Row>
  );
};
