import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams, useRouteMatch, generatePath, useHistory, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalBody, ModalFooter, Button, Input, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import produce from 'immer';

import { QueryBuilder, InlineEdit } from 'smv-components';

import { getQueryBuilderSources, getQuestionnaireElementsOrder } from 'smv-helpers';
import { ConfigTab } from './configtab';
import { ConfigCustomShelf } from '.';
import { questionnaireActions } from 'smv-redux/actions';

import {
  getQueryBuilderEmptyState,
  getQueryBuilderWithoutElementsWithErrors,
  handleQuerybuilderAutosave
} from 'src/smoove/components/querybuilder/helper';

import '../ConfigModal.scss';

const defaultTabList = [
  ['config', <i className='fal fa-cog ml-2' />],
  ['screenout', <i className='fal fa-door-open ml-2' />],
  ['filter', <i className='fal fa-filter ml-2' />]
  // ['jump', <i className='fal fa-share ml-2' />]
];

export const QuestionConfigModal = ({ question, writeable }) => {
  const { path } = useRouteMatch();
  const params = useParams();
  const history = useHistory();

  const surveyid = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const sharedChoices = {};

  // @Todo: the varprefix can consist of all parent containers/ pages prefixes,
  // not only the prefix of the direct parent. So the whole path has to be checked.
  // const parentId = question.path[question.path.length - 1];
  // const parentElement = elements.list[parentId];
  // const varprefix = parentElement?.config.varprefix + parentElement?.children.indexOf(question.id);

  const { screenoutSources, filterSources, jumpSources, jumpTargets } = useMemo(() => {
    const rawSources = {
      question: { list: questionnaire.list, order: getQuestionnaireElementsOrder(questionnaire) },
      sysvar: systemVariables
    };

    const screenoutSources = getQueryBuilderSources(rawSources, question.id);
    const filterSources = getQueryBuilderSources(rawSources, question.id, 'above', false);
    const jumpSources = screenoutSources;
    const jumpTargets = getQueryBuilderSources({ question: rawSources.question }, question.id, 'below', false);

    return { screenoutSources, filterSources, jumpSources, jumpTargets };
  }, [questionnaire, systemVariables, question.id]);

  const qtype = question?.config?.qtype;

  const tabList = useMemo(
    () =>
      produce(defaultTabList, draft => {
        if (qtype === 'custom_shelf') {
          draft.splice(1, 0, ['shelf', <i className='fal fa-abacus ml-2' />]);
        }
      }),
    [qtype]
  );

  const internalHandler = {
    checkUpdateQBUpdate: () => {},
    setSection: section => {
      if (internalHandler.checkUpdateQBUpdate() === false) return;
      history.push(generatePath(path, { ...params, section: section }));
    },
    close: () => {
      if (internalHandler.checkUpdateQBUpdate() === false) return;
      history.push(generatePath(path.replace('/:elementid/:section?', ''), params));
    }
  };

  return (
    <>
      <ModalHeader toggle={internalHandler.close}>
        <div className='d-flex'>
          <div className='modal__title-group'>
            <div className='modal__title-info'>
              <FormattedMessage id={'smoove.questionnaire.modal.settings-of'} defaultMessage={'Settings of'} />
              {':'}
            </div>
            <div className='mx-1 modal__title-info-varname'>{`${question.config?.varname} - `}</div>
            <InlineEdit
              disabled={!writeable}
              className='modal__title'
              defaultValue={question.title}
              saveFn={newTitle => {
                if (question.title !== newTitle) {
                  questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
                    title: newTitle
                  });
                }
              }}
            />
          </div>
        </div>
      </ModalHeader>

      {/* Tabs Navigation  */}
      <div className='d-flex mt-4 overflow-auto pb-2'>
        {tabList.map(([tabText, tabIcon]) => (
          <div
            key={tabText}
            className={classnames('tab text-smv px-2 mx-4', {
              'tab--active': tabText === params.section || (tabText === 'config' && !params.section)
            })}
            onClick={() => internalHandler.setSection(tabText)}
          >
            <div className='d-flex align-items-center justify-content-center'>
              <div>
                <FormattedMessage id={`smoove.questionnaire.modal.tab-${tabText}`} defaultMessage={tabText} />
              </div>
              <div>{tabIcon}</div>
            </div>
          </div>
        ))}
      </div>

      <ModalBody style={{ minHeight: '450px' }}>
        {/* Tab Contents */}

        <Route path={generatePath(path, { ...params, section: undefined })} exact={true}>
          <ConfigTab question={question} sharedChoices={sharedChoices} writeable={writeable} />
        </Route>

        <Route path={generatePath(path, { ...params, section: 'config' })} exact={true}>
          <ConfigTab question={question} sharedChoices={sharedChoices} writeable={writeable} />
        </Route>

        {qtype === 'custom_shelf' && (
          <Route path={generatePath(path, { ...params, section: 'shelf' })} exact={true}>
            <ConfigCustomShelf question={question} writeable={writeable} />
          </Route>
        )}

        <Route path={generatePath(path, { ...params, section: 'screenout' })} exact={true}>
          <Screenout writeable={writeable} sources={screenoutSources} question={question} surveyid={surveyid} />
        </Route>

        <Route path={generatePath(path, { ...params, section: 'filter' })} exact={true}>
          <Filter writeable={writeable} sources={filterSources} question={question} surveyid={surveyid} />
        </Route>

        <Route path={generatePath(path, { ...params, section: 'jump' })} exact={true}>
          <Jump
            writeable={writeable}
            sources={jumpSources}
            question={question}
            surveyid={surveyid}
            jumpTargets={jumpTargets}
          />
        </Route>
      </ModalBody>

      <ModalFooter>
        {/* <div className='mr-auto d-flex'>
          <div className='mr-2'>
            <FormattedMessage id={`smoove.common.project.expert-mode`} defaultMessage={'Expert Mode'} />
          </div>
          <SmvToggleExpertModeSwitch />
        </div> */}

        <Button color={'grey'} onClick={internalHandler.close}>
          <FormattedMessage id={`smoove.common.buttons.close`} defaultMessage={'Close'} />
        </Button>
      </ModalFooter>
    </>
  );
};

QuestionConfigModal.propTypes = {
  question: PropTypes.object.isRequired
};

const Screenout = ({ writeable, sources, question, surveyid }) => {
  const [_screenout, setScreenout] = useState(question.config.screenout ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    if (!!question.config.screenout && !!question.config.screenout?.rootElementId) {
      if (!_screenout?.id) {
        setScreenout(state =>
          produce(state, d => {
            d.id = question.config.screenout.id;
          })
        );
      }
    } else {
      setScreenout(getQueryBuilderEmptyState());
    }
  }, [question.config.screenout, _screenout?.id]);

  const saveHandler = useCallback(() => {
    setIsSaving(true);

    const screenoutId = question.config?.screenout?.id ? question.config?.screenout.id : null;

    questionnaireActions
      .updateScreenoutElement(surveyid, question.id, screenoutId, getQueryBuilderWithoutElementsWithErrors(_screenout))
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyid, question.id, _screenout, question.config?.screenout?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    if (question.config.screenout?.rootElementId) {
      questionnaireActions
        .removeScreenoutElement(surveyid, question.id, question.config.screenout?.rootElementId)
        .then(res => {
          setIsSaving(false);
          setWarning(null);
        });
    } else {
      setScreenout(null);
      setIsSaving(false);
    }
  }, [question.config.screenout?.rootElementId, question.id, surveyid]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      _screenout,
      question.config.screenout,
      sources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [_screenout, saveHandler, isSaving, question.config.screenout, sources, deleteHandler]);

  return (
    <>
      <QueryBuilder
        readOnly={!writeable}
        name='screenout'
        fields={sources}
        queryBuilder={_screenout}
        setQueryBuilder={setScreenout}
        warning={warning}
        isSaving={isSaving}
      />
    </>
  );
};

const Filter = ({ writeable, sources, question, surveyid }) => {
  const [_filter, setFilter] = useState(question.config.filter ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    if (!!question.config.filter && !!question.config.filter?.rootElementId) {
      if (!_filter?.id) {
        setFilter(state =>
          produce(state, d => {
            d.id = question.config.filter.id;
          })
        );
      }
    } else {
      setFilter(getQueryBuilderEmptyState());
    }
  }, [question.config.filter, _filter?.id]);

  const saveHandler = useCallback(() => {
    setIsSaving(true);

    const filterId = question.config?.filter?.id ? question.config?.filter?.id : null;

    questionnaireActions
      .updateFilterElement(surveyid, question.id, filterId, getQueryBuilderWithoutElementsWithErrors(_filter))
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyid, question.id, _filter, question.config?.filter?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    if (question.config.filter?.rootElementId) {
      questionnaireActions
        .removeFilterElement(surveyid, question.id, question.config.filter?.rootElementId)
        .then(res => {
          setIsSaving(false);
          setWarning(null);
        });
    } else {
      setFilter(null);
      setIsSaving(false);
    }
  }, [question.config.filter?.rootElementId, question.id, surveyid]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      _filter,
      question.config.filter,
      sources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [_filter, saveHandler, isSaving, question.config.filter, sources, deleteHandler]);

  return (
    <>
      <QueryBuilder
        readOnly={!writeable}
        name='filter'
        fields={sources}
        queryBuilder={_filter}
        setQueryBuilder={setFilter}
        warning={warning}
        isSaving={isSaving}
      />
    </>
  );
};

const Jump = ({ writeable, sources, question, surveyid, jumpTargets }) => {
  const intl = useIntl();
  const [_jump, setJump] = useState(question.config.jump ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    if (!!question.config.jump && !!question.config.jump?.rootElementId) {
      if (!_jump?.id) {
        setJump(state =>
          produce(state, d => {
            d.id = question.config.jump.id;
          })
        );
      }
    } else {
      setJump(getQueryBuilderEmptyState());
    }
  }, [question.config.jump, _jump?.id]);

  const saveHandler = useCallback(() => {
    setIsSaving(true);

    const jumpId = question.config?.jump?.id ? question.config?.jump.id : null;

    questionnaireActions
      .updateJumpElement(surveyid, question.id, jumpId, getQueryBuilderWithoutElementsWithErrors(_jump))
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyid, question.id, _jump, question.config?.jump?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    if (question.config.jump?.rootElementId) {
      questionnaireActions.removeJumpElement(surveyid, question.id, question.config.jump?.rootElementId).then(res => {
        setIsSaving(false);
        setWarning(null);
      });
    } else {
      setJump(null);
      setIsSaving(false);
    }
  }, [question.config.jump?.rootElementId, question.id, surveyid]);

  useEffect(() => {
    handleQuerybuilderAutosave(_jump, question.config.jump, sources, isSaving, deleteHandler, setWarning, saveHandler);
  }, [_jump, saveHandler, isSaving, question.config.jump, sources, deleteHandler]);

  return (
    <>
      <QueryBuilder
        readOnly={!writeable}
        name='jump'
        fields={sources}
        queryBuilder={_jump}
        setQueryBuilder={setJump}
        warning={warning}
        isSaving={isSaving}
      />
      <div className='col-12 pr-0 mt-4'>
        <div className='fw-bold'>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.jump-to`} defaultMessage={'Jump to:'} />
        </div>
        <Input
          disabled={!writeable}
          type='select'
          name='jump-input'
          defaultValue={question.config.jump?.target || ''}
          onChange={({ target }) => {
            questionnaireActions.updateJumpElement(surveyid, question.id, question.config.jump.id, {
              target: target.value
            });
          }}
        >
          <option value=''>{intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.please-choose` })}</option>
          {jumpTargets.map(field => (
            <option key={field.id} value={field.id}>
              {field.label}
            </option>
          ))}
        </Input>
      </div>
    </>
  );
};
