import React, { useMemo } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

import { chartSettings } from '../config';

export const ChartLegendConfig = ({ chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.showLegend !== true) return null;

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        {intl.formatMessage({ id: `smoove.page.tables.chart-config.legend` })}
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.show-legend` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`showLegend`}
              name='showLegend'
              checked={chartConfig?.showLegend ?? true}
              onChange={handler.setCheckboxValue}
            />
          </FormGroup>
        </Col>
      </Row>

      {chartTypeSettings?.legendSaveHiddenSeries && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.legend.save-hidden-series` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`legendSaveHiddenSeries`}
                name='legendSaveHiddenSeries'
                checked={chartConfig?.legendSaveHiddenSeries ?? false}
                onChange={handler.setCheckboxValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      {/* {chartSettings?.legendIconType && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>Icon Type</Col>
          <Col md={7}>
            <Input
              type='select'
              label={'Icon Type'}
              id={`legendIconType`}
              name='legendIconType'
              value={chartConfig?.legendIconType ?? 'square'}
              onChange={setSelectValue}
            >
              <option value={'square'}>Square (default)</option>
              <option value={'line'}>Line</option>
              <option value={'plainline'}>Plainline</option>
              <option value={'rect'}>Rect</option>
              <option value={'circle'}>Circle</option>
              <option value={'cross'}>Cross</option>
              <option value={'diamond'}>Diamond</option>
              <option value={'star'}>Star</option>
              <option value={'triangle'}>Triangle</option>
              <option value={'wye'}>Wye</option>
            </Input>
          </Col>
        </Row>
      )} */}

      {chartSettings?.legendIconSize && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.icon-size` })}</Col>
          <Col md={7}>
            <Input
              type='select'
              step={5}
              label={intl.formatMessage({ id: `smoove.page.tables.chart-config.icon-size` })}
              id={`legendIconSize`}
              name='legendIconSize'
              value={chartConfig?.legendIconSize ?? 14}
              onChange={handler.setSelectValue}
            >
              <option value={8}>8</option>
              <option value={10}>10</option>
              <option value={12}>12</option>
              <option value={14}>14 (default)</option>
              <option value={16}>16</option>
              <option value={18}>18</option>
              <option value={20}>20</option>
            </Input>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};
