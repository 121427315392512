import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CardFooter, UncontrolledTooltip } from 'reactstrap';

import { useElementPropertyTranslation, useMatrixType } from 'smv-helpers';
import { useChartBases } from './charts/helper/useChartBases';
import { getMergedChoices } from '../misc/helper';
import { useSignificanceBenchmarkLabel } from './charts/helper';

import './DashboardElementFooter.scss';

export const DashboardElementFooter = ({
  table,
  resultTableType,
  onMouseEnterSignificance,
  onMouseLeaveSignificance
}) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const isStaticFiltersActive = useMemo(() => {
    return !!table?.filters && !!table.filters?.rootElementId;
  }, [table?.filters]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setIsOpen(b => !b);
  }, []);
  const [isEllipsis, setIsEllipsis] = useState(true);

  const handleIsEllipsis = useCallback(
    node => {
      if (!node) return false;
      if (table?.rows?.order?.length > 1) {
        setIsEllipsis(true);
      } else {
        setIsEllipsis(node.offsetWidth < node.scrollWidth);
      }
    },
    [table?.rows?.order?.length]
  );

  const { isCompactMatrix } = useMatrixType(table);

  let bases = useChartBases({ table, tableResult: table.result, isCompactMatrix });

  const significancesShow = table?.chart?.significancesShow ?? false;
  const { valueSignificancesLevel = 0.95, valueSignificancesDimension = 'heads', valueSignificancesBenchmark = null } =
    table?.config ?? {};

  const footerText = useMemo(() => {
    return (table?.rows?.order ?? []).map((rowid, rowidx) => {
      const el = table?.rows?.list?.[rowid];

      if (el.sourceType === 'question') {
        const element = questionnaire?.list[el.sourceId];
        if (!element) return null;

        const _choices = getMergedChoices(element, element.config?.choices, questionnaire?.list);

        const calcFrequencyGroups = el.config.calcFrequencyGroups ?? null;

        const _hiddenOrExcludedChoices = [];

        Object.keys(el?.config?.hidden ?? {})?.forEach(v => {
          if (el.config.hidden[v]) _hiddenOrExcludedChoices.push(v);
        });
        Object.keys(el?.config?.excluded ?? {})?.forEach(v => {
          if (el.config.excluded[v]) _hiddenOrExcludedChoices.push(v);
        });

        if (
          _choices?.order?.length > 0 &&
          _choices.order.every(id => _hiddenOrExcludedChoices.includes(id)) &&
          !calcFrequencyGroups
        )
          return null;

        return (
          <QuestionTextFooter
            key={`${el.sourceId}_${rowidx}`}
            forwardRef={rowidx === 0 ? handleIsEllipsis : null}
            rowElement={el}
            questionElement={element}
            questionIndex={rowidx}
            bases={bases}
            isStaticFiltersActive={isStaticFiltersActive}
            chartType={table.chart.chartType}
          />
        );
      } else if (el.sourceType === 'sysvar') {
        const element = systemVariables?.list?.[el.sourceId];
        if (!element) return null;

        return (
          <div key={element?.id} className='footer-text'>
            <span
              className='flex-grow-1 footer__question-text'
              title={element?.label ? element.label : ''}
              ref={rowidx === 0 ? handleIsEllipsis : null}
            >
              {element?.label && <span>{element.label}</span>}
            </span>
            <span className='footer__bases' title={`n=${bases}`}>{`n=${bases}`}</span>
          </div>
        );
      } else if (el.sourceType === 'calcvar') {
        const element = calcVars?.find(v => v.id === el.sourceId);
        if (!element) return null;

        return (
          <div key={element.id} className='footer-text'>
            <span
              className='flex-grow-1 footer__question-text'
              title={element?.label ? element.label : ''}
              ref={rowidx === 0 ? handleIsEllipsis : null}
            >
              {element?.label && <span>{element.label}</span>}
            </span>
            <span className='footer__bases' title={`n=${bases}`}>{`n=${bases}`}</span>
          </div>
        );
      } else if (el.sourceType === 'calculation') {
        return (
          <div key={el.id} className='footer-text'>
            <span
              className='flex-grow-1 footer__question-text'
              title={el.config?.title ?? ''}
              ref={rowidx === 0 ? handleIsEllipsis : null}
            >
              Calculation: {el.config?.title ?? ''}
            </span>
            {/* <span className='footer__bases' title={`n=${bases}`}>{`n=${bases}`}</span> */}
          </div>
        );
      } else {
        return <div key={el.id} className='footer-text'></div>;
      }
    });
  }, [
    table?.rows,
    table?.chart?.chartType,
    questionnaire.list,
    bases,
    systemVariables,
    calcVars,
    handleIsEllipsis,
    isStaticFiltersActive
  ]);

  return (
    <CardFooter
      className={`smv-dashboard-element__footer `}
      onMouseLeave={() => setTimeout(() => setIsOpen(false), 1000)}
    >
      <div className={`dashboard-footer-text-container ${isOpen ? 'dashboard-footer-text-container--open' : ''}`}>
        {isEllipsis && (
          <div className='dashboard-footer-toggle-btn' onClick={toggleOpen}>
            <i className={`far fa-chevron-down ${!isOpen ? 'fa-chevron-down--rotate' : ''}`} />
          </div>
        )}
        <div className={`dashboard-footer-text ${isEllipsis ? 'pl-3' : ''}`} onClick={toggleOpen}>
          {footerText}
          {significancesShow && (
            <ValueSignificancesTextFooter
              chartType={table?.chart?.chartType}
              tableConfig={table}
              valueSignificancesLevel={valueSignificancesLevel}
              valueSignificancesDimension={valueSignificancesDimension}
              valueSignificancesBenchmark={valueSignificancesBenchmark}
              onMouseEnterSignificance={onMouseEnterSignificance}
              onMouseLeaveSignificance={onMouseLeaveSignificance}
              isCompactMatrix={isCompactMatrix}
              isEllipsis={isEllipsis}
            />
          )}
        </div>
      </div>
    </CardFooter>
  );
};

const QuestionTextFooter = ({
  forwardRef,
  rowElement,
  questionIndex,
  questionElement,
  bases = null,
  isStaticFiltersActive,
  chartType
}) => {
  const intl = useIntl();

  const questionText = useElementPropertyTranslation(questionElement, {
    useStripped: true,
    useFallback: true
  });

  let questionSelectionDetails = [];
  if (
    questionElement.config.qtype === 'multiple_vector' ||
    questionElement.config.qtype === 'multiple_matrix' ||
    (questionElement.config.qtype === 'custom_shelf' &&
      (questionElement.config?.shelfResultType === 'multiple' ||
        questionElement.config?.shelf?.config?.shelfResultType === 'multiple'))
  ) {
    const { countedValue = 'selected', includeMissings = false } = rowElement.config ?? {};

    if (countedValue === 'selected') {
      questionSelectionDetails.push(
        <span key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.selected'}
            defaultMessage={'selected values'}
          />
        </span>
      );
    } else if (countedValue === 'nonselected') {
      questionSelectionDetails.push(
        <span key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.nonselected'}
            defaultMessage={`<u>non</u> selected values`}
          />
        </span>
      );
    } else if (countedValue === 'missing') {
      questionSelectionDetails.push(
        <span key={'countedValue'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.counted-values.label'}
              defaultMessage={'Counting'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.counted-values.missing'}
            defaultMessage={`missing values`}
          />
        </span>
      );
    }

    if (includeMissings || countedValue === 'missing') {
      questionSelectionDetails.push(
        <span key={'includeMissings'}>
          <b>
            <FormattedMessage
              id={'smoove.page.project.tables.options.calculation-base.label'}
              defaultMessage={'Base'}
            />
            :
          </b>{' '}
          <FormattedMessage
            id={'smoove.page.project.tables.options.calculation-base.missings-included'}
            defaultMessage={`missing values included`}
          />
        </span>
      );
    }
  }

  return (
    <div className='footer-text'>
      <span
        className='footer__question-text'
        ref={forwardRef}
        title={(questionElement?.config?.varname ? questionElement.config.varname + ': ' : '') + questionText}
      >
        {questionElement?.config?.varname && questionElement.config.varname + ': '}
        {questionText}
        {questionSelectionDetails.length > 0 && <span className='ml-1'>({questionSelectionDetails})</span>}
      </span>
      <span>
        {chartType !== 'scatter' && bases && <span className='footer__bases' title={`n=${bases}`}>{`n=${bases}`}</span>}
        {isStaticFiltersActive && (
          <span className='ml-1'>
            <i className='far fa-filter static-filter-warning' id={'static-filter-warning'}></i>
            <UncontrolledTooltip target={'static-filter-warning'}>
              {intl.formatMessage({
                id: 'smoove.page.tables.table-config.static-filters.this-table-is-filtered-by-static-filters'
              })}
            </UncontrolledTooltip>
          </span>
        )}
      </span>
    </div>
  );
};

const ValueSignificancesTextFooter = ({
  chartType,
  tableConfig,
  valueSignificancesLevel,
  valueSignificancesDimension,
  valueSignificancesBenchmark,
  onMouseEnterSignificance,
  onMouseLeaveSignificance,
  isCompactMatrix
}) => {
  const intl = useIntl();

  const getSignificanceBenchmarkLabel = useSignificanceBenchmarkLabel();
  const benchmarkLabel = getSignificanceBenchmarkLabel({
    tableConfig,
    valueSignificancesDimension,
    valueSignificancesBenchmark,
    isCompactMatrix
  });

  const sigIconClass = chartType === 'table' ? 'sig__icon__table' : 'sig__icon__chart';

  return (
    <div className={`footer__significances`}>
      <div className={'mt-1 d-inline-flex align-items-center'}>
        {intl.formatMessage(
          {
            id: 'smoove.page.tables.table-footer.significances.text',
            defaultMessage: 'Significances {benchmark}shown at a level of {value}%:'
          },
          {
            value: valueSignificancesLevel * 100,
            dimension: intl.formatMessage({
              id: `smoove.page.tables.table-config.significance-dimension-${valueSignificancesDimension}`
            }),
            benchmark: !valueSignificancesBenchmark ? `(${benchmarkLabel}) ` : `(vs ${benchmarkLabel}) `
          }
        )}

        <div
          className={'sig__container'}
          onMouseEnter={onMouseEnterSignificance}
          onMouseLeave={onMouseLeaveSignificance}
          data-significancetype={'high'}
        >
          <span className={`sig__icon ${sigIconClass}--high ${chartType === 'line' ? 'sig__icon__circle' : ''}`} />
          {intl.formatMessage({
            id: 'smoove.page.tables.table-footer.significances.high',
            defaultMessage: 'higher'
          })}
        </div>

        <div
          className={'sig__container'}
          onMouseEnter={onMouseEnterSignificance}
          onMouseLeave={onMouseLeaveSignificance}
          data-significancetype={'low'}
        >
          <span className={`sig__icon ${sigIconClass}--low ${chartType === 'line' ? 'sig__icon__circle' : ''}`} />
          {intl.formatMessage({
            id: 'smoove.page.tables.table-footer.significances.low',
            defaultMessage: 'lower'
          })}
        </div>

        {!valueSignificancesBenchmark && (
          <>
            <div
              className={'sig__container'}
              onMouseEnter={onMouseEnterSignificance}
              onMouseLeave={onMouseLeaveSignificance}
              data-significancetype={'both'}
            >
              <span
                className={`sig__icon ${sigIconClass}--mutually ${chartType === 'line' ? 'sig__icon__circle' : ''}`}
              />
              {intl.formatMessage({
                id: 'smoove.page.tables.table-footer.significances.mutually',
                defaultMessage: 'both (higher & lower)'
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
