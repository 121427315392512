import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DropdownItem } from 'reactstrap';
// import produce from 'immer';

import { translationsActions } from 'smv-redux/actions';
import { questionnaireActions } from 'smv-redux';

import { useControlledState } from 'smv-helpers';

import { EditableTextHover } from '../Content';
import { ConfirmCopyTranslationButton, ConfirmCopyTranslationModal } from './ConfirmCopyTranslation';
import { PasteMultipleTranslationsButton, PasteMultipleTranslationsModal } from './PasteMultipleTranslations';
import { SmvCustomDropdown } from '../../misc';
import { AiTranslationButton, AiTranslationResult } from './AiTranslation';

export const TranslateScales = ({
  question,
  localeA,
  localeB,
  writeable,
  translationModalContainerRef,
  aiTranslationList,
  updateAiTranslationList,
  getSingleTranslation
}) => {
  const [CopyTranslationButton1, CopyTranslationModal1] = useControlledState(
    ConfirmCopyTranslationButton,
    ConfirmCopyTranslationModal,
    false
  );
  const [CopyTranslationButton2, CopyTranslationModal2] = useControlledState(
    ConfirmCopyTranslationButton,
    ConfirmCopyTranslationModal,
    false
  );

  const [PasteMultipleTranslationsButton1, PasteMultipleTranslationsModal1] = useControlledState(
    PasteMultipleTranslationsButton,
    PasteMultipleTranslationsModal,
    false
  );
  const [PasteMultipleTranslationsButton2, PasteMultipleTranslationsModal2] = useControlledState(
    PasteMultipleTranslationsButton,
    PasteMultipleTranslationsModal,
    false
  );
  return (
    <>
      <tr>
        <td
          colSpan={2}
          className='translation-question-title'
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <h6 className='mt-2 ml-1' style={{ fontWeight: '300' }}>
            Scale
          </h6>
          {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
            <>
              <SmvCustomDropdown fixedMenu={false} container={translationModalContainerRef}>
                <DropdownItem>
                  <CopyTranslationButton1 />
                </DropdownItem>
                <DropdownItem>
                  <PasteMultipleTranslationsButton1 />
                </DropdownItem>
              </SmvCustomDropdown>
              <CopyTranslationModal1
                locale1={localeB}
                locale2={localeA}
                question={question}
                subelementType={'scales'}
              />
              <PasteMultipleTranslationsModal1 locale={localeA} question={question} subelementType={'scales'} />
            </>
          )}
        </td>
        <td className={'translation-question-title'} style={{ textAlign: 'right' }}>
          {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
            <>
              <SmvCustomDropdown fixedMenu={false} container={translationModalContainerRef}>
                <DropdownItem>
                  <CopyTranslationButton2 />
                </DropdownItem>
                <DropdownItem>
                  <PasteMultipleTranslationsButton2 />
                </DropdownItem>
              </SmvCustomDropdown>
              <CopyTranslationModal2
                locale1={localeA}
                locale2={localeB}
                question={question}
                subelementType={'scales'}
              />
              <PasteMultipleTranslationsModal2 locale={localeB} question={question} subelementType={'scales'} />
            </>
          )}
        </td>
      </tr>

      {question.config?.scales?.order?.map(scaleid => {
        const scale = question.config.scales.list[scaleid];

        return (
          <tr key={scaleid}>
            <td>
              <Scale question={question} scale={scale} locale={localeA} writeable={writeable} />
            </td>
            <td>
              <Scale
                question={question}
                scale={scale}
                locale={localeB}
                writeable={writeable}
                getSingleTranslation={
                  localeA !== 'shortlabels' && localeB !== 'shortlabels' ? getSingleTranslation : null
                }
                aiTranslationList={aiTranslationList}
                updateAiTranslationList={updateAiTranslationList}
              />
            </td>
          </tr>
        );
      })}
    </>
  );
};

const Scale = ({
  question,
  scale,
  locale,
  writeable,
  getSingleTranslation,
  aiTranslationList,
  updateAiTranslationList
}) => {
  const surveyid = useSelector(state => state.survey.id);
  const scaleLabel = useSelector(
    state => state.survey?.translations?.[scale.config.translations.label]?.[locale] ?? ''
  );
  const mainLanguage = useSelector(state => state.survey.locales.main);

  const intl = useIntl();

  const isMainLanguage = useMemo(() => mainLanguage === locale, [mainLanguage, locale]);

  const placeholderText = useMemo(() => {
    if (writeable) return intl.formatMessage({ id: 'smoove.questionnaire.translation.question.scale.placeholder' });
    if (isMainLanguage)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.missing-tanslation.placeholder' });
    return intl.formatMessage({
      id: 'smoove.questionnaire.translation.missing-tanslation-with-fallback.placeholder'
    });
  }, [intl, writeable, isMainLanguage]);

  const updateScale = value => {
    if (value !== scaleLabel)
      translationsActions.updateTranslation(surveyid, scale.config.translations.label, {
        [locale]: value
      });
  };

  const updateShortlabel = useCallback(
    value => {
      questionnaireActions.updateScale(surveyid, question.id, scale.id, {
        config: { shortLabel: value }
      });

      // const _scale = produce(scale, draftScale => {
      //   draftScale.label_short = value;
      // });
      // const path = [surveyid, question.id, scale.id];
      // surveyActions.saveSubElement(path, 'scale', _scale);
    },
    [surveyid, question.id, scale.id]
  );

  if (locale === 'shortlabels') {
    return (
      <EditableTextHover
        className={'help-text text-font mb-2'}
        value={scale.config?.shortLabel ?? null}
        placeholder={placeholderText}
        replacing={true}
        onBlur={updateShortlabel}
        writeable={true}
      />
    );
  }

  return (
    <>
      <div id={`_${scale.id}_${locale}`} className='d-flex'>
        <div className='flex-grow-1'>
          <EditableTextHover
            className={'no-padding'}
            value={scaleLabel}
            placeholder={placeholderText}
            replacing={true}
            onBlur={updateScale}
            writeable={writeable}
          />
        </div>
        {getSingleTranslation && (
          <AiTranslationButton
            getTranslations={() => getSingleTranslation(scale.config.translations.label)}
            whiteBackground={true}
          />
        )}
      </div>
      <div>
        {aiTranslationList?.[scale.config.translations.label]?.[locale] && (
          <AiTranslationResult
            aiTranslation={aiTranslationList[scale.config.translations.label][locale]}
            saveHandler={updateScale}
            updateAiTranslationList={updateAiTranslationList}
            translationId={scale.config.translations.label}
          />
        )}
      </div>
    </>
  );
};
