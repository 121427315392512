import { useEffect, useState } from 'react';

export const useCanDrop = (check, initial = false, eventType = 'dragstart') => {
  const [canDrop, setCanDrop] = useState(initial);

  useEffect(() => {
    const handleDragStart = e => setCanDrop(check(e));
    window.addEventListener(eventType, handleDragStart);

    return () => {
      window.removeEventListener(eventType, handleDragStart);
    };
  }, [check, eventType]);

  return { canDrop };
};
