import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  UncontrolledTooltip
} from 'reactstrap';
import { isArray, isEqual } from 'lodash';
import { customAlphabet } from 'nanoid';

import { MultipleSelectDropdown, TextInputRow } from 'smv-components';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { reportsActions } from 'smv-redux';

export const AddReportButton = ({ report, saveHandler, size }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(i => !i);
  };

  if (size === 'icon') {
    return (
      <div className='smv-custom-dropdown__buttons' onClick={toggle}>
        <i id='add-report-btn' className={'fal fa-plus-square icon-smv-blue'}></i>
        <UncontrolledTooltip target={`add-report-btn`}>
          <FormattedMessage
            id={`smoove.page.reports.modals.edit-report.add-new-report`}
            defaultMessage={'Add new report'}
          />
        </UncontrolledTooltip>
        <AddReportModal isOpen={isOpen} toggle={toggle} saveHandler={saveHandler}></AddReportModal>
      </div>
    );
  }

  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <Button color={'primary'}>
        <i id='add-report-btn' className={'fal fa-plus mr-2'}></i>
        <FormattedMessage
          id={`smoove.page.reports.modals.edit-report.add-new-report`}
          defaultMessage={'Add new report'}
        />
      </Button>
      <UncontrolledTooltip target={`add-report-btn`}>
        <FormattedMessage
          id={`smoove.page.reports.modals.edit-report.add-new-report`}
          defaultMessage={'Add new report'}
        />
      </UncontrolledTooltip>
      <AddReportModal isOpen={isOpen} toggle={toggle} report={report} saveHandler={saveHandler}></AddReportModal>
    </div>
  );
};

export const AddReportModal = ({ isOpen, toggle, saveHandler }) => {
  const pageId = customAlphabet('1234567890abcdef', 24)();
  const [internalReport, setInternalReport] = useState({
    name: '',
    pages: { list: { [pageId]: { id: pageId, name: 'new page' } }, order: [pageId] }
  });

  const history = useHistory();
  const { path, params } = useRouteMatch();

  const intl = useIntl();

  const updateName = useCallback(({ target }) => {
    const { value } = target;
    setInternalReport(state => ({ ...state, name: value }));
  }, []);

  const _saveHandler = useCallback(async () => {
    if (internalReport.name) {
      await saveHandler(internalReport).then(report => {
        toggle();
        history.push(
          generatePath(path, {
            projectid: params.projectid,
            surveyid: params.surveyid,
            reportid: report.id
          })
        );
      });

      reportsActions.loadReports(params.surveyid);
    }
  }, [history, path, params, internalReport, saveHandler, toggle]);

  const _saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      _saveHandler();
    },
    [_saveHandler]
  );

  // eslint-disable-next-line
  const filterHandler = useMemo(
    () => ({
      save: filter => {
        if (!filter.elementid || filter?.values?.length <= 0) return;
        setInternalReport(state =>
          produce(state, draft => {
            const filterid = filter?.id ?? customAlphabet('1234567890abcdef', 24)();

            if (!draft?.config) draft.config = {};
            if (!draft?.config?.staticFilters || isArray(draft.config.staticFilters.list))
              draft.config.staticFilters = { list: {}, order: [] };

            if (!draft.config.staticFilters.list[filterid]) {
              draft.config.staticFilters.order.push(filterid);
            }
            draft.config.staticFilters.list[filterid] = { ...filter, id: filterid };
          })
        );
      },
      remove: filterid => {
        setInternalReport(state =>
          produce(state, draft => {
            delete draft.config.staticFilters.list[filterid];
            draft.config.staticFilters.order = draft.config.staticFilters.order.filter(
              _filterid => filterid !== _filterid
            );
          })
        );
      }
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={false} size={'lg'}>
      <Form onSubmit={_saveOnSubmit}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage
            id={`smoove.page.reports.modals.edit-report.headline-${'add'}`}
            defaultMessage={'Edit report'}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormattedMessage
                id={`smoove.page.reports.modals.edit-report.form.name.instruction`}
                defaultMessage={'Enter report name'}
              />
              :
            </Col>
          </Row>
          <TextInputRow
            name={'name'}
            title={intl.formatMessage({
              id: `smoove.page.reports.modals.edit-report.form.name.title`,
              defaultMessage: 'Name'
            })}
            // tooltip={intl.formatMessage({
            //   id: `smoove.page.reports.modals.edit-report.form.name.tooltip`,
            //   defaultMessage: ''
            // })}
            value={internalReport.name}
            change={updateName}
            placeholder={intl.formatMessage({
              id: 'smoove.page.reports.modals.edit-report.form.name.placeholder',
              defaultMessage: 'enter name...'
            })}
            autoFocus={true}
          />
          {/* <StaticReportFilters handler={filterHandler} report={internalReport} /> */}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={_saveHandler} disabled={!internalReport.name}>
            {intl.formatMessage({ id: `smoove.common.buttons.save` })}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
// eslint-disable-next-line
const StaticReportFilters = ({ handler, report }) => {
  return (
    <>
      <Row>
        <Col>
          <h6 className={'text-smv text-uppercase fw-bold'}>
            <FormattedMessage
              id={`smoove.page.reports.modals.edit-report.form.static-filters.headline`}
              defaultMessage={'Static Report Filters'}
            />
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          {(report?.config?.staticFilters?.order ?? []).map(filterid => (
            <StaticReportFilter key={filterid} handler={handler} filter={report.config.staticFilters.list[filterid]} />
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <StaticReportFilter handler={handler} />
        </Col>
      </Row>
    </>
  );
};

const DEFAULT_FILTER = { id: null, elementid: null, values: {}, type: 'sysvar' };

const StaticReportFilter = ({ handler, filter = null }) => {
  const [internalFilter, setInternalFilter] = useState(filter ?? DEFAULT_FILTER);
  useEffect(() => {
    if (!!filter) {
      setInternalFilter(state => {
        if (isEqual(state, filter)) return state;
        else return filter;
      });
    }
  }, [filter]);

  // const questionnaire = useSelector(state => state.survey.questionnaire);
  // const calculations = useSelector(state => state.survey?.data?.calculations ?? {});

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const removeFilter = useCallback(() => {
    handler.remove(filter.id);
  }, [handler, filter]);

  const changeElement = e => {
    const elementid = e.target.value;
    setInternalFilter(state =>
      produce(state, draft => {
        if (draft?.elementid !== elementid) {
          draft.elementid = elementid;
          draft.values = {};
          // draft.type = 'sysvar';
        }
      })
    );
  };

  const changeValues = values => {
    setInternalFilter(state => {
      if (isEqual(values, Object.keys(state.values))) return state;
      return produce(state, draft => {
        draft.values = {};
        // draft.type = 'sysvar';
        values.forEach(valueid => (draft.values[valueid] = true));
      });
    });
  };

  useEffect(() => {
    if (
      !!internalFilter?.elementid &&
      Object.keys(internalFilter?.values ?? {}).length > 0 &&
      !isEqual(filter, internalFilter)
    ) {
      handler.save(internalFilter);
      if (!internalFilter.id) setInternalFilter(DEFAULT_FILTER);
    }
  }, [handler, filter, internalFilter]);

  const _values = useMemo(() => {
    return Object.keys(internalFilter?.values ?? {});
  }, [internalFilter?.values]);

  const _options = useMemo(() => {
    return Object.values(systemVariables.list?.[internalFilter?.elementid]?.list ?? {});
  }, [systemVariables, internalFilter.elementid]);

  return (
    <Row className='mb-2'>
      <Col md={6}>
        <Input
          type='select'
          label={'globalFilterSelect'}
          id={`globalFilterSelect`}
          name='globalFilterSelect'
          value={internalFilter?.elementid ?? ''}
          onChange={changeElement}
        >
          <option value={''}>Select Filter Element</option>
          {systemVariables.order.map(systemVariableId => (
            <option key={systemVariableId} value={systemVariableId}>
              {systemVariables.list[systemVariableId].label}
            </option>
          ))}
        </Input>
      </Col>
      <Col md={5}>
        {_options.length > 0 && <MultipleSelectDropdown options={_options} values={_values} onChange={changeValues} />}
      </Col>
      <Col md={1} className='d-flex align-items-center justify-content-start'>
        {!!internalFilter.id && (
          <div className={`smv-custom-dropdown__buttons`} onClick={removeFilter}>
            <i className='fal fa-trash-alt icon-smv-blue mr-1' />
            {/* <span>
              <FormattedMessage
                defaultMessage={``}
                id={`smoove.page.reports.modals.edit-report.form.static-filters.delete-filter`}
              />
            </span> */}
          </div>
        )}
      </Col>
    </Row>
  );
};
