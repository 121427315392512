import { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { userActions } from 'smv-redux';

import { Email, Password, RememberMe } from '.';

export const LoginForm = ({ user }) => {
  const intl = useIntl();

  const history = useHistory();

  const [state, setState] = useState({
    email: '',
    password: '',
    newPassword1: '',
    newPassword2: '',
    remember: user?.remember ?? false
  });

  /**
   * Check form state, if all required fields are filled
   */
  const canSubmit = useMemo(() => {
    if (state.email.length === 0 || state.password.length === 0) return false;
    else if (user.pwexpired && (state.newPassword1.length <= 0 || state.newPassword1 !== state.newPassword2))
      return false;
    return true;
  }, [user, state]);

  /**
   * Generic form field handler
   */
  const handleChange = useCallback(e => {
    let { name, value, type } = e.target;
    if (type === 'checkbox') value = e.target.checked;
    setState(s => ({ ...s, [name]: value }));
  }, []);

  /**
   * Handle form submit, either login or change password event
   */
  const handleSubmit = e => {
    e.preventDefault();

    if (!canSubmit) return false;

    if (!user.pwexpired) {
      // The success is handled through ApplicationRouter,
      // which checks for the updated state and redirects
      // to a new location
      userActions
        .login({
          username: state.email,
          password: state.password,
          remember: state.remember
        })
        .then(isLoggedIn => {
          if (isLoggedIn) {
            history.push('/one');
          }
        });
    } else {
      userActions.resetPassword({
        username: state.email,
        pw: state.password,
        pw1: state.newPassword1,
        pw2: state.newPassword2,
        remember: state.remember
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className='login-form'>
      <Email state={state} onChange={handleChange} />

      <Password state={state} onChange={handleChange} />

      {user.pwexpired && (
        <>
          <div className='row alert alert-warning mt-5 mb-3'>
            <FormattedMessage
              id={'core.page.login.error.password-expired'}
              defaultMessage={'Your password has expired, please set a new password.'}
            />
          </div>
          <Password name={'newPassword1'} state={state} invalid={user.newPasswordInvalid} onChange={handleChange} />
          <Password name={'newPassword2'} state={state} invalid={user.newPasswordInvalid} onChange={handleChange} />
        </>
      )}

      <div className='mt-2 mb-4'>
        <span className='pointer' onClick={() => history.push('/resetPassword')}>
          {intl.formatMessage({ id: 'core.page.reset-password.forgot-password' })}
        </span>
      </div>
      <RememberMe show={true} state={state} onChange={handleChange} />

      <Button color={'primary'} className='mt-4' type='submit' style={{ minWidth: 250 }} disabled={!canSubmit}>
        {user.loggingIn && (
          <>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
            {intl.formatMessage({ id: 'core.page.login.button.loading', defaultMessage: 'loading...' })}
          </>
        )}
        {!user.loggingIn && !user.pwexpired && (
          <FormattedMessage id={'core.page.login.button.submit'} defaultMessage={'Sign in'} />
        )}
        {!user.loggingIn && user.pwexpired && (
          <FormattedMessage id={'core.page.login.button.save'} defaultMessage={'Save'} />
        )}
      </Button>
    </form>
  );
};
