import React from 'react';
import { useSelector } from 'react-redux';

import { ApplyToValues } from '.';

export const ApplyToTypeCalculationVariable = ({ conditionalFormat, main, dimensionKey, handleApplyToSelect }) => {
  const calculationVariables = useSelector(state => state.survey?.data?.calculations ?? {});

  const { sourceId } = main;

  const calculationVariable = calculationVariables.find(v => v.id === sourceId);
  if (!calculationVariable) return null;

  const label = calculationVariable.label;
  const values = {
    list: Object.fromEntries(calculationVariable.values.map(value => [value.id, value])),
    order: calculationVariable.values.map(value => value.id)
  };

  return (
    <ApplyToValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      values={values}
      handleApplyToSelect={handleApplyToSelect}
    />
  );
};
