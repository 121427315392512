import { useMemo } from 'react';
import { getUserClientRole, getUserProjectRole } from 'smv-helpers';
import { useSelector } from 'react-redux';
import { useIsUserSmooveAdmin } from './useIsUserSmooveAdmin';

/**
 * Checks if the currently logged in user has the permission for the passed client or project permissionName. Returns boolean
 *
 * @param {string} permissionName an existing client or project permission from the permissionEnum
 * @param {object} projectId projectId the permission should be checked for. If no projectId is passed, the currently selected projectId of the redux state is used.
 */
export const useUserHasPermission = (permissionName, projectId = null) => {
  const user = useSelector(state => state.authentication.user);
  const client = useSelector(state => state.clients?.list?.[state.clients?.selected]);
  const currentProjectId = useSelector(state => state.project?.id);

  if (!projectId) projectId = currentProjectId;

  const isUserSmooveAdmin = useIsUserSmooveAdmin();

  return useMemo(() => {
    if (!user || !client) return false;

    if (isUserSmooveAdmin) return true;

    const userClientRole = getUserClientRole(user, client);
    const userProjectRole = getUserProjectRole(user, client, projectId);

    let allUserPermissions = [];
    if (!userClientRole && !userProjectRole) return false;

    if (userClientRole?.permissions) allUserPermissions = [...userClientRole.permissions];

    if (userProjectRole?.permissions) allUserPermissions = [...allUserPermissions, ...userProjectRole.permissions];

    if (allUserPermissions.includes(permissionName)) return true;

    return false;
  }, [user, client, permissionName, projectId, isUserSmooveAdmin]);
};
