import { useCallback } from 'react';

import './AiTranslationResult.scss';

export const AiTranslationResult = ({ aiTranslation, saveHandler, updateAiTranslationList, translationId }) => {
  const handleUseAiTranslation = useCallback(() => {
    saveHandler(aiTranslation);
    updateAiTranslationList(translationId, 'remove', null);
  }, [saveHandler, aiTranslation, updateAiTranslationList, translationId]);

  const handleDeclineTranslation = useCallback(() => {
    updateAiTranslationList(translationId, 'remove', null);
  }, [translationId, updateAiTranslationList]);

  return (
    <div className='ai-translation-result'>
      <div className='flex-grow-1'>
        <span className='ai-translation-result__text' dangerouslySetInnerHTML={{ __html: aiTranslation }}></span>
      </div>
      <span>
        <i className='fal fa-fw fa-xmark text-danger pointer' onClick={handleDeclineTranslation} />
      </span>
      <span>
        <i className='fal fa-fw fa-check text-primary pointer' onClick={handleUseAiTranslation} />
      </span>
    </div>
  );
};
