import { produce } from 'immer';
import { sharedReportActionTypes, tablesActionTypes } from '../constants';

const TABLES_INITIAL_STATE = {
  loading: false,
  list: {},
  order: []
};

export const tablesReducer = (state = TABLES_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * LOAD_REPORT_SUCCESS (shared reports)
     */
    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { tables } = payload;
        draftState.loading = false;
        if (!draftState.list) draftState.list = {};
        if (!draftState.order) draftState.oder = [];
        draftState.list = tables.list;
        draftState.order = tables.order;
      });

    /**
     * LOAD_TABLES_X
     */
    case tablesActionTypes.LOAD_TABLES_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
      });
    case tablesActionTypes.LOAD_TABLES_SUCCESS:
      return produce(TABLES_INITIAL_STATE, draftState => {
        payload.tables.forEach(table => {
          const tableid = table.id;
          draftState.order.push(tableid);
          draftState.list[tableid] = table;
        });
      });
    case tablesActionTypes.LOAD_TABLES_FAILURE:
      return produce(state, draftState => {
        draftState.loading = false;
        draftState.error = payload;
      });

    case tablesActionTypes.CREATE_TABLE_SUCCESS:
      const { table } = payload;
      return produce(state, draftState => {
        draftState.list[table.id] = table;
        draftState.order.push(table.id);
      });

    /**
     * UPDATE_TABLE_X
     */
    case tablesActionTypes.UPDATE_TABLE_REQUEST: {
      return state;
    }
    case tablesActionTypes.UPDATE_TABLE_SUCCESS: {
      const { table } = payload;

      return produce(state, draftState => {
        draftState.list[table.id] = table;
      });
    }
    case tablesActionTypes.UPDATE_TABLE_FAILURE:
      return state;

    /**
     * DELETE_TABLE_X
     */
    case tablesActionTypes.DELETE_TABLE_REQUEST:
      return state;
    case tablesActionTypes.DELETE_TABLE_SUCCESS:
      return produce(state, draftState => {
        const { tableId } = payload;

        draftState.order = draftState.order.filter(id => id !== tableId);
        delete draftState.list[tableId];
      });
    case tablesActionTypes.DELETE_TABLE_FAILURE:
      return produce(state, draftState => {
        const { tableId, error } = payload;
        draftState.list[tableId].loading = false;
        draftState.list[tableId].error = error;
      });

    /**
     * LOAD_TABLE_RESULT_X
     */
    case tablesActionTypes.LOAD_TABLE_RESULT_REQUEST: {
      const { tableid = '__tmp', table } = payload;

      return produce(state, draftState => {
        if (draftState.list[tableid]) {
          draftState.list[tableid].loading = true;
          draftState.list[tableid].error = null;

          // reset results, if table changes from detailed to compact matrix
          if (draftState.list[tableid]?.result?.tableStruct === 'matrix_detail' && table.heads.order.length === 0) {
            draftState.list[tableid].result = null;
          }
        } else {
          draftState.list[tableid] = { id: tableid, loading: true, error: null };
        }
      });
    }
    case tablesActionTypes.LOAD_TABLE_RESULT_SUCCESS: {
      const { tableid = '__tmp' } = payload;
      return produce(state, draftState => {
        if (draftState.list[tableid]) {
          draftState.list[tableid].loading = false;
          draftState.list[tableid].error = null;
          draftState.list[tableid].result = payload.result;
        }
      });
    }
    case tablesActionTypes.LOAD_TABLE_RESULT_FAILURE: {
      return produce(state, draftState => {
        const { tableid = '__tmp', error } = payload;
        if (draftState.list[tableid]) {
          draftState.list[tableid].loading = false;
          draftState.list[tableid].error = error;
        }
      });
    }

    default:
      return state;
  }
};

const initialFoldersState = {
  order: [],
  list: {},
  rootFolderId: null
};

export const tablesFoldersReducer = (state = initialFoldersState, action) => {
  const { type, payload } = action;

  switch (type) {
    case tablesActionTypes.LOAD_FOLDERS_SUCCESS:
      state = initialFoldersState;

      const { folders } = payload;
      const rootFolder = folders.find(folder => folder.name === 'root');
      if (!rootFolder) return state;

      return produce(state, draftState => {
        draftState.rootFolderId = rootFolder.id;
        folders.forEach(folder => {
          draftState.order.push(folder.id);
          draftState.list[folder.id] = folder;
        });
      });

    case tablesActionTypes.UPDATE_FOLDER_SUCCESS:
      const { folder } = payload;

      return produce(state, draftState => {
        draftState.list[folder.id] = folder;
      });

    default:
      return state;
  }
};
