import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import produce from 'immer';
import PropTypes from 'prop-types';

import { getSortingIconString } from 'smv-helpers';
import { getMergedChoices } from '../../../misc/helper';
import { getHighestHeadBase } from '../result-rows/helper';

import { ResultTableDefaultHeadHeader, ResultTableDefaultHeadColumnHeader } from '.';

export const ResultTableDefaultHead = ({
  headRef,
  isDraggingOver,
  canDropHead,
  table,
  tableResult,
  handler,
  isCompactMatrix,
  matrixType
}) => {
  const intl = useIntl();
  const { heads, rows, config } = table;

  const list = useSelector(state => state.survey.questionnaire.list);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const isDetailedMatrix = matrixType === 'detail';

  const { showTotal = true, showHiddenColumns = true } = config;

  // increase colspan for first column to 2, if more than 1 element in rows,
  // and row group headings will be displayed
  const spareSpan = matrixType === 'detail' ? 2 : 1;
  const isEmpty = useMemo(() => heads.length === 0, [heads]);

  const thead = {
    header: [],
    columnheads: []
  };

  if (!isEmpty) {
    thead.header.push(<th key={'header__spare-1'} colSpan={spareSpan + 1} scope='col'></th>);
    thead.columnheads.push(<th key={'columnhead__spare-1'} colSpan={spareSpan}></th>);
    thead.columnheads.push(
      <th key={'columnhead__spare-2'}>
        <span className='pointer' onClick={() => handler.sortTable('__value__', '__value__')}>
          <span>#</span>
          {table.config.sortation?.headElemId === '__value__' && (
            <i className={`fal fa-${getSortingIconString(table.config?.sortation?.direction)} ml-1`}></i>
          )}
        </span>
        {' / '}
        <span className='pointer' onClick={() => handler.sortTable('__label__', '__label__')}>
          <span>{intl.formatMessage({ id: 'smoove.common.label', defaultMessage: 'Label' })}</span>
          {table.config.sortation?.headElemId === '__label__' && (
            <i className={`fal fa-${getSortingIconString(table.config?.sortation?.direction)} ml-1`}></i>
          )}
        </span>
      </th>
    );
  }

  if (isEmpty) {
    thead.header.push(
      <th key={'header__empty'} scope='col' className={'header__empty'}>
        {intl.formatMessage({ id: 'smoove.page.tables.table-head', defaultMessage: 'Table Head' })}
      </th>
    );
  } else if (showTotal && !isCompactMatrix) {
    thead.header.push(
      <th key={'header__total'} scope={'col'} className={'head__header'}>
        <div className={'head__header_label'}>
          <span className={`head__header_label--label`}>{/* Total */}</span>
        </div>
      </th>
    );
    thead.columnheads.push(
      <th key={'columnhead__total'} scope='col' className={'head__column pointer'}>
        <span className={'head__column_content'} onClick={() => handler.sortTable('__total__', '__total__')}>
          {intl.formatMessage({ id: 'smoove.page.tables.total', defaultMessage: 'Total' })}
          {table.config.sortation?.headElemId === '__total__' && (
            <i className={`fal fa-${getSortingIconString(table.config?.sortation?.direction)} ml-1`}></i>
          )}
        </span>
      </th>
    );
  }

  if (isCompactMatrix) {
    const rowId = rows.order[0];
    const { sourceId } = rows.list[rows.order[0]];
    const rowConfig = rows.list[rows.order[0]].config ?? {
      sortation: {},
      hidden: {},
      excluded: {}
    };
    const { hidden = {}, excluded = {} } = rowConfig;

    const element = list[sourceId];
    const mergedChoices = getMergedChoices(element, element.config.choices, list);

    const _columns = [];
    tableResult?.headOrder?.[rowId]?.forEach(orderItem => {
      /** @todo: refactor hidden heads to use headOrder */
      let isHidden = hidden?.[orderItem.headSubId] === true || excluded?.[orderItem.headSubId] === true;
      if (!isHidden && (rowConfig?.autoHideLowBases ?? false)) {
        const autoHideLowBasesHurdle = rowConfig?.autoHideLowBasesHurdle ?? 0;
        const highestBase = getHighestHeadBase(tableResult, rowId, orderItem.headSubId, { excludeHidden: true });
        if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
      }

      if (!showHiddenColumns && isHidden) return null;

      _columns.push(
        <ResultTableDefaultHeadColumnHeader
          key={`columnhead__${element.id}_${orderItem.headSubId}`}
          headid={rowId}
          column={mergedChoices.list[orderItem.headSubId]}
          toggleHidden={() => handler.toggleSubelementVisibility('rows', 'hidden', rowId, orderItem.headSubId)}
          sortTable={() => handler.sortTable(rowId, orderItem.headSubId)}
          isHidden={isHidden}
          config={config}
        />
      );
    });

    thead.columnheads.push(..._columns);
    thead.header.push(
      <ResultTableDefaultHeadHeader
        key={`header__${element.id}`}
        element={element}
        tableConfig={table}
        colSpan={_columns.length}
        columns={mergedChoices}
        handler={handler}
        showDeleteButton={false}
        headid={rowId}
        isCompactMatrix={true}
        headConfig={rowConfig}
      />
    );

    heads.order.forEach(headId => {
      const _head = heads.list[headId];
      if (_head.sourceType !== 'calculation') return;

      const { hidden = {}, excluded = {} } = _head.config;
      /** @todo: refactor hidden heads to use headOrder */
      const isHidden = hidden?.[headId] === true || excluded?.[headId] === true;

      if (!showHiddenColumns && isHidden) return null;

      const element = {
        id: _head.id,
        type: 'calculation',
        title: 'Calculation'
      };

      const _column = (
        <ResultTableDefaultHeadColumnHeader
          key={`columnhead__${headId}_${headId}`}
          headid={headId}
          column={{
            id: _head.id,
            label: _head.config.title,
            value: _head.id
          }}
          toggleHidden={() => handler.toggleSubelementVisibility('heads', 'hidden', headId, headId)}
          sortTable={() => handler.sortTable(headId, headId)}
          isHidden={isHidden}
          config={config}
        />
      );

      thead.columnheads.push(_column);
      thead.header.push(
        <ResultTableDefaultHeadHeader
          key={`header__${element.id}`}
          element={element}
          colSpan={1}
          columns={[_column]}
          handler={handler}
          head={_head}
          headid={headId}
          headConfig={_head.config}
          tableConfig={table}
        />
      );
    });
  } else {
    heads.order.forEach(headid => {
      const _head = heads.list[headid];
      const { hidden = {}, excluded = {} } = _head.config;

      let element;
      let columns = [];

      if (_head.sourceType === 'question') {
        element = list[_head.sourceId];
        const mergedChoices = getMergedChoices(element, element.config.choices, list);
        columns = mergedChoices;
      } else if (_head.sourceType === 'sysvar') {
        element = systemVariables.list[_head.sourceId];
        columns = { order: element.order, list: element.list };
      } else if (_head.sourceType === 'calcvar') {
        const calculationVariable = calcVars?.find(calculation => calculation.id === _head.sourceId);
        element = produce(calculationVariable, draft => {
          draft.type = 'calcvar';
        });
        columns = {
          list: Object.fromEntries(element.values.map(value => [value.id, value])),
          order: element.values.map(value => value.id)
        };
      } else if (_head.sourceType === 'calculation') {
        element = {
          id: _head.id,
          type: 'calculation',
          title: 'Calculation'
        };
        columns = {
          list: {
            [_head.id]: {
              id: _head.id,
              label: _head.config.title,
              value: _head.id
            }
          },
          order: [_head.id]
        };
      } else {
        return null;
      }

      const _columns = [];
      tableResult?.headOrder?.[headid]?.forEach(orderItem => {
        /** @todo: refactor hidden heads to use headOrder */
        let isHidden = hidden?.[orderItem.headSubId] === true || excluded?.[orderItem.headSubId] === true;
        if (!isHidden && ((_head.autoHideLowBases ?? false) || (_head.config?.autoHideLowBases ?? false))) {
          const autoHideLowBasesHurdle = _head.autoHideLowBasesHurdle ?? _head.config?.autoHideLowBasesHurdle ?? 0;
          const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
            isDetailedMatrix: isDetailedMatrix,
            excludeHidden: true
          });
          if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
        }

        if (!showHiddenColumns && isHidden) return null;

        _columns.push(
          <ResultTableDefaultHeadColumnHeader
            key={`columnhead__${element.id}_${orderItem.headSubId}`}
            headid={headid}
            column={columns.list[orderItem.headSubId]}
            toggleHidden={() => handler.toggleSubelementVisibility('heads', 'hidden', headid, orderItem.headSubId)}
            sortTable={() => handler.sortTable(headid, orderItem.headSubId)}
            isHidden={isHidden}
            config={config}
          />
        );
      });

      if (showHiddenColumns || _columns.length > 0) {
        thead.columnheads.push(..._columns);
        thead.header.push(
          <ResultTableDefaultHeadHeader
            key={`header__${element.id}`}
            element={element}
            colSpan={_columns.length}
            columns={columns}
            handler={handler}
            head={_head}
            headid={headid}
            headConfig={_head.config}
            tableConfig={table}
          />
        );
      }
    });
  }

  return (
    <thead
      ref={headRef}
      className={classNames({
        'smv-result-table__head': true,
        'smv-result-table__head--is-over': isDraggingOver && canDropHead,
        'smv-result-table__head--can-drop': canDropHead
      })}
    >
      <tr className={'head__row'}>{thead.header}</tr>
      <tr className={'head__row'}>{thead.columnheads}</tr>
    </thead>
  );
};

ResultTableDefaultHead.propTypes = {
  table: PropTypes.object.isRequired,
  isCompactMatrix: PropTypes.bool.isRequired
};
