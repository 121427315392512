import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { settingsActions } from '../redux';
import { HeaderLogo, useScrollOffset } from '.';

import './Header.scss';
import 'flag-icon-css/css/flag-icon.min.css';

export const HeaderPublic = () => {
  const locale = useSelector(state => state.appSettings.locale);
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const shouldShrink = useScrollOffset(30);

  const location = useLocation();

  return (
    <div
      className={classNames('smv-header container-fluid fixed-top justify-content-between', {
        'box-shadow': shouldShrink,
        'smv-header--shrinked': shouldShrink
      })}
    >
      <Navbar light expand='md' className='p-0 h-100'>
        <NavbarBrand tag={Link} to={'/'}>
          <HeaderLogo type={'full'} />
        </NavbarBrand>
      </Navbar>

      <div className='navbar-nav d-flex flex-row'>
        <span
          className={`nav-item nav-item--flag pointer ${locale === 'de_DE' ? 'active' : ''}`}
          onClick={() => settingsActions.setAppLocale('de_DE')}
        >
          <i className={`flag-icon flag-icon-de`} style={{ fontSize: '1.5rem' }} />
        </span>

        <span
          className={`nav-item nav-item--flag pointer ${locale === 'en_US' ? 'active' : ''}`}
          onClick={() => settingsActions.setAppLocale('en_US')}
        >
          <i className={`flag-icon flag-icon-us`} style={{ fontSize: '1.5rem' }} />
        </span>
        {loggedIn ? (
          <Link to={'/logout'} exact='true' draggable={false}>
            <div className='btn btn-smv-blue-outline ml-2'>
              <FormattedMessage id={'core.menu.item.logout'} defaultMessage={'Logout'} />
            </div>
          </Link>
        ) : (
          location.pathname !== '/login' && (
            <Link to={'/login'} exact='true' draggable={false}>
              <div className='btn btn-smv-blue-outline ml-2'>
                <FormattedMessage id={'core.menu.item.login'} defaultMessage={'Login'} />
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  );
};
