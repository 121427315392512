/**
 * Returns desired result value
 *
 * @param {object} cell Cell results | undefined
 * @param {string} metrix
 */
export function getResultValue(props) {
  const cell = props.cell;
  const metric = props.metric ?? 'smvrslt_perc';
  const fallback = props?.fallback !== undefined ? props.fallback : '-';

  if (!cell) return fallback;

  let value = cell?.[metric];

  if (!value) return fallback;

  if (metric === 'smvrslt_perc') value *= 100;

  return value;
}
