import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import produce from 'immer';

import { ShelfStatic } from '@mindline-analytics/shelftool';

import { QuestionText } from '../../components';
import { shelfBackgrounds } from '.';

export const CustomShelf = ({ question, writeable }) => {
  const locales = useSelector(state => state.survey.locales.list);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const media = useSelector(state => state.survey.media);

  const countryCode = useMemo(() => locales[selectedLocale].iso3, [locales, selectedLocale]);

  const { boards = [], config = {} } = useMemo(() => question.config.shelf?.[countryCode] ?? {}, [
    question.config.shelf,
    countryCode
  ]);

  const shelfBoards = useMemo(
    () =>
      boards.map(board =>
        board
          .map(productId => {
            const mediaItem = media?.[productId] ?? false;
            if (mediaItem) {
              return produce(mediaItem, draft => {
                draft.url = `${process.env.PUBLIC_URL}/${mediaItem.url}`;
              });
            } else return false;
          })
          .filter(Boolean)
      ),
    [boards, media]
  );

  const shelfConfig = {
    ...config,
    background: question.config?.background ?? config?.background,
    backgrounds: shelfBackgrounds
  };

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <ErrorBoundary FallbackComponent={() => <div>ShelfTool is not available</div>}>
        {null}
        <ShelfStatic shelfConfig={shelfConfig} shelfBoards={shelfBoards} testcells={[]} />
      </ErrorBoundary>
    </>
  );
};
