import { useEffect, useState } from 'react';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { customAlphabet } from 'nanoid';
import produce from 'immer';
import { useSelector } from 'react-redux';

import { reportsActions } from 'smv-redux/actions';
import { DynamicSplitsList, DynamicSplitConfig } from '.';

// import './EditFilters.scss';

const INITAL_SPLIT_STATE = {
  id: null,
  label: null,
  // config: {},
  elements: {
    list: {},
    order: []
  }
};

export const EditDynamicSplitsButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i id={'edit-dynamic-splits-button'} className={'fal fa-edit icon-smv-blue mr-1'} />
      <UncontrolledTooltip target={'edit-dynamic-splits-button'} delay={{ hide: 500 }} autohide={false}>
        <FormattedMessage id={'smoove.page.common.dynamic-splits.edit-splitting'} defaultMessage={'Edit Splitting'} />
      </UncontrolledTooltip>
    </div>
  );
};

export const EditDynamicSplitsModal = ({ isOpen, toggle, report }) => {
  const [selectedSplit, setSelectedSplit] = useState(null);
  const [internalSplitList, setInternalSplitList] = useState(report?.splits ?? { list: {}, order: [] });
  const surveyid = useSelector(state => state.survey.id);

  useEffect(() => {
    setInternalSplitList(state =>
      produce(state, draft => {
        draft.order = report?.splits?.order;
      })
    );
  }, [report?.splits?.order]);

  const handler = {
    close: () => {
      setSelectedSplit(null);
      toggle();
    },
    setSelectedSplit,
    setInternalSplitList,
    createSplit: () => {
      const splitid = customAlphabet('1234567890abcdef', 24)();
      const split = {
        ...INITAL_SPLIT_STATE,
        id: splitid
      };
      if (internalSplitList.list) {
        setInternalSplitList(state =>
          produce(state, draft => {
            draft.order.push(splitid);
            draft.list[splitid] = split;
          })
        );
      } else {
        const _splits = {
          list: {
            [splitid]: split
          },
          order: [splitid]
        };
        setInternalSplitList(_splits);
      }
      setSelectedSplit(splitid);
    },
    saveSplit: split => {
      reportsActions.saveSplit(surveyid, report.id, split);
    },
    deleteSplit: splitid => {
      reportsActions.deleteSplit(surveyid, report.id, splitid);
    }
  };

  return (
    <Modal className={'edit-filter-modal'} isOpen={isOpen} toggle={handler.close} size={'xl'}>
      {!selectedSplit && <DynamicSplitsList handler={handler} internalSplitList={internalSplitList} report={report} />}
      {!!selectedSplit && (
        <DynamicSplitConfig
          handler={handler}
          report={report}
          selectedSplit={selectedSplit}
          internalSplitList={internalSplitList}
        />
      )}
    </Modal>
  );
};

export const EditDynamicSplitsButtonAndModal = ({ report, buttonClassName = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <EditDynamicSplitsButton toggle={toggle} className={buttonClassName} />
      {isOpen && <EditDynamicSplitsModal toggle={toggle} isOpen={isOpen} report={report} />}
    </>
  );
};
