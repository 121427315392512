import React from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router';
import { ConfirmAction } from 'smv-components';

import { SaveTableModalButton } from '.';

export const TableButtons = ({ handler, table, resetTable, clearTable, isConfigChanged, isEmpty }) => {
  const intl = useIntl();
  const translationPrefix = 'smoove.page.project.tables';

  const history = useHistory();
  const routeParams = useParams();
  const { path } = useRouteMatch();

  if (!table) return null;

  return (
    <div className='tables-save-buttons'>
      <div className='mt-1'>
        <SaveTableModalButton handler={handler} isEmpty={isEmpty} table={table} />
      </div>
      <div className={'my-2'}>
        <Button block outline color={'secondary'} onClick={resetTable} disabled={!table.id || !isConfigChanged}>
          <i className='mr-1 fal fa-undo'></i>
          {intl.formatMessage({ id: `${translationPrefix}.config.undo-changes` })}
        </Button>
      </div>
      <div className={'my-2'}>
        <ConfirmAction
          title={intl.formatMessage({ id: `${translationPrefix}.config.unsaved-changes` })}
          size={'md'}
          body={<div>{intl.formatMessage({ id: `${translationPrefix}.config.unsaved-changes-confirm-text` })}</div>}
          callback={clearTable}
          btnColor={'secondary'}
          btnText={intl.formatMessage({ id: `${translationPrefix}.config.unsaved-changes-confirm-btn` })}
        >
          {confirm => (
            <Button block outline color='secondary' onClick={confirm} className={`btn btn-outline`} disabled={isEmpty}>
              <i className='mr-1 fal fa-eraser'></i>
              {intl.formatMessage({ id: `${translationPrefix}.config.reset-table` })}
            </Button>
          )}
        </ConfirmAction>
      </div>
      <div className='my-2'>
        <ConfirmAction
          title={intl.formatMessage({ id: `${translationPrefix}.config.delete-table` })}
          size={'md'}
          body={<div>{intl.formatMessage({ id: `${translationPrefix}.config.delete-table-confirm-text` })}</div>}
          callback={() => {
            handler.deleteTable(table.id);
            history.push(generatePath(path, { ...routeParams, tableid: undefined }));
          }}
        >
          {confirm => (
            <Button block outline color='danger' onClick={confirm} disabled={!table.id || table.id === '__tmp'}>
              <i className='mr-1 fal fa-trash-alt'></i>
              {intl.formatMessage({ id: `${translationPrefix}.config.delete-table` })}
            </Button>
          )}
        </ConfirmAction>
      </div>
    </div>
  );
};
