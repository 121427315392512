import React, { useEffect, useMemo, useState } from 'react';
import { Col, Input, FormGroup, Table, UncontrolledTooltip } from 'reactstrap';
import { RowLabel } from './RowLabel';
import { getCountryIso2Code } from 'smv-helpers';
import { SearchInput } from '../misc/SearchInput';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import produce from 'immer';

export const WizardCountrySelection = ({ wizardState, setWizardState }) => {
  const client = useSelector(state => state.clients.list?.[state.clients.selected ?? null] ?? null);
  const availableLocales = useMemo(() => client.locales ?? [], [client.locales]);
  const intl = useIntl();

  const addCountry = locale => {
    setWizardState(state =>
      produce(state, draftState => {
        draftState.countries.order = [...state.countries.order, locale.locale];
        draftState.countries.list = {
          ...state.countries.list,
          [locale.locale]: locale
        };
        if (!state.main_language) {
          draftState.main_language = locale.locale;
        }
      })
    );
  };

  const removeLocale = locale => {
    setWizardState(state =>
      produce(state, draftState => {
        let mainLang = state.main_language;
        const { [locale.locale]: deletedCountry, ...remainingCountries } = state?.countries.list ?? {};

        if (locale.locale === state.main_language) {
          mainLang = Object.keys(remainingCountries)[0] ?? null;
        }
        return (draftState = {
          ...state,
          countries: {
            list: remainingCountries,
            order: state.countries.order.filter(el => el !== locale.locale)
          },
          main_language: mainLang
        });
      })
    );
  };

  const setMainLanguage = locale => {
    setWizardState(state =>
      produce(state, draftState => {
        draftState.main_language = locale;
      })
    );
  };

  const handleUpdateCountry = country => {
    setWizardState(state =>
      produce(state, draftState => {
        draftState.countries.list[country.locale] = country;
      })
    );
  };

  const mainLanguage = wizardState?.main_language;

  const unselectedCountries = useMemo(() => {
    return (availableLocales ?? [])?.filter(el => {
      return !wizardState?.countries?.order?.includes(el.locale);
    });
  }, [wizardState?.countries?.order, availableLocales]);

  const [filteredLocales, setFilteredLocales] = useState([]);

  let countriesSelectedForSampling = [];
  Object.keys(wizardState?.countries ?? {}).forEach(el => {
    if (!wizardState?.countries?.list?.[el]?.reportingOnly) countriesSelectedForSampling.push(el);
  });

  useEffect(() => {
    const _items = [];
    const searchInputValue = document.getElementById('locales-search-input-wizard')?.value ?? '';
    ['locale', 'iso3', 'language', 'country'].forEach(val => {
      _items.push(
        ...Object.values(unselectedCountries)?.filter(item =>
          item[val].toLowerCase().includes(searchInputValue.toLowerCase())
        )
      );
    });
    setFilteredLocales(uniqBy(_items, 'locale'));
  }, [unselectedCountries]);

  return (
    <FormGroup row>
      <RowLabel
        name={'countries'}
        title={intl.formatMessage({ id: 'smoove.wizards.common.countries.label' })}
        tooltip={intl.formatMessage({ id: 'smoove.wizards.common.countries.desc' })}
      />

      <Col lg={8} xl={9}>
        {Object.keys(unselectedCountries)?.length > 0 && (
          <>
            <h6 className='mt-2'>Select countries:</h6>
            <div className='locales-list__unselected mb-4'>
              <div className='locales-list__unselected-search'>
                <SearchInput
                  allItems={unselectedCountries}
                  filterValues={['locale', 'iso3', 'language', 'country']}
                  setFilteredItems={setFilteredLocales}
                  placeholderText={'search'}
                  inputId={'locales-search-input-wizard'}
                />
              </div>
              <div className='locales-list__unselected-list'>
                {filteredLocales.map(locale => (
                  <div
                    key={locale?.locale}
                    onClick={() => addCountry(locale)}
                    className='locales-list__unselected-locale'
                  >
                    <i className={`fal fa-plus locales-list__add-locale-btn mr-2`} />
                    <i className={`flag-icon flag-icon-${locale.iso2.toLowerCase()} mr-2`} />
                    <span>
                      {locale?.label} ({locale.locale})
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {/* Seleted Countries */}
        {(wizardState?.countries?.order ?? [])?.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Country</th>
                <th>{(wizardState?.countries?.order ?? [])?.length > 0 && 'main language'}</th>
                <th>
                  {(wizardState?.countries?.order ?? [])?.length > 0 && (
                    <>
                      <span>reporting only</span>
                      <i className='ml-2 fal fa-circle-info' id={`tt_reporting_only`} />
                      <UncontrolledTooltip target={`tt_reporting_only`}>
                        When activated, this locale will not be used as a sampling language but only inside smoove for
                        reporting. This can be useful if you want to run a survey in non-english countries but report in
                        english - then you would add UK/USA as a country and activate "reporting only" for this country.
                      </UncontrolledTooltip>
                    </>
                  )}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(wizardState?.countries?.order ?? [])?.map(locale => {
                const country = wizardState?.countries?.list?.[locale];

                return (
                  <tr key={country.locale}>
                    <td>
                      <div className='d-flex flex-row no-gutters align-items-center'>
                        <span
                          className={`flag-icon flag-icon-squared flag-icon-${getCountryIso2Code(
                            country.iso3
                          )?.toLowerCase()} mr-2`}
                        />
                        <div>{country.label}</div>
                      </div>
                    </td>
                    <td>
                      <span>
                        {country.locale === mainLanguage && <i className='fas fa-star text-secondary' />}
                        {country.locale !== mainLanguage && (
                          <i
                            className='fal fa-star text-secondary pointer'
                            onClick={() => setMainLanguage(country.locale)}
                          />
                        )}
                      </span>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <FormGroup switch>
                          <Input
                            type='switch'
                            role='switch'
                            id={`toggleIsReportingOnly`}
                            name='toggleIsReportingOnly'
                            checked={country.reportingOnly ?? false}
                            disabled={!country.reportingOnly && countriesSelectedForSampling.length <= 1}
                            onChange={e =>
                              handleUpdateCountry({
                                ...country,
                                reportingOnly: !country.reportingOnly
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                    </td>
                    <td>
                      <i
                        className='fal fa-trash-alt text-danger pointer text-smv p-1'
                        onClick={() => removeLocale(country)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Col>
    </FormGroup>
  );
};
