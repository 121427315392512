import classNames from 'classnames';

import { FormulaBuilderReferenceElement } from '.';
import { useMemo } from 'react';

export const FormulaBuilderReferenceCalculationValueElement = ({ calculationMode, element, tableConfig, ...rest }) => {
  const label = useMemo(() => {
    if (calculationMode === 'rows') {
      return tableConfig[calculationMode].list[element.value.rowId].config.title;
    } else if (calculationMode === 'heads') {
      return tableConfig[calculationMode].list[element.value.headId].config.title;
    } else {
      return 'unnamed element';
    }
  }, [tableConfig, calculationMode, element.value]);

  const classes = classNames({
    'formula-builder__element': true
  });

  return (
    <FormulaBuilderReferenceElement
      calculationMode={calculationMode}
      tableConfig={tableConfig}
      element={element}
      label={label}
      classes={classes}
      options={{ metricSelect: false, allowFixation: true }}
      {...rest}
    />
  );
};
