import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import produce from 'immer';
import PropTypes from 'prop-types';

import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { ResultTableChartHeadHeader, ResultTableChartHeadColumnHeader } from '.';
import { getHighestHeadBase } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const ResultTableChartHead = ({
  headRef,
  table,
  tableResult,
  chartConfig,
  isEmpty,
  isCompactMatrix,
  matrixType
}) => {
  const { rows, config } = table;
  const head = table.heads;
  const list = useSelector(state => state.survey.questionnaire?.list ?? {});
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const isDetailedMatrix = matrixType === 'detail';

  const intl = useIntl();

  const { showTotal = true } = config;

  // increase colspan for first column to 2, if more than 1 element in rows,
  // and row group headings will be displayed
  let colSpan = matrixType === 'detail' && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) ? 2 : 1;
  if (!chartConfig.showQuestionTitleColumn) colSpan = colSpan - 1;

  const thead = {
    header: [],
    columnheads: []
  };

  if (!isEmpty) {
    thead.header.push(<th key={'header__spare-1'} colSpan={colSpan + 1} scope='col'></th>);
    colSpan > 0 && thead.columnheads.push(<th key={'columnhead__spare-1'} colSpan={colSpan}></th>);
    thead.columnheads.push(
      <th key={'columnhead__spare-2'}>
        <span></span>
      </th>
    );
  }

  if (isEmpty) {
    thead.header.push(
      <th key={'header__empty'} scope='col' className={'header__empty'}>
        {intl.formatMessage({ id: 'smoove.page.tables.table-head', defaultMessage: 'Table Head' })}
      </th>
    );
  } else if (showTotal && !isCompactMatrix) {
    thead.header.push(
      <th key={'header__total'} scope='col'>
        {/* Total */}
      </th>
    );
    thead.columnheads.push(
      <ResultTableChartHeadColumnHeader
        key={'columnhead__total'}
        column={{ label: intl.formatMessage({ id: 'smoove.page.tables.total', defaultMessage: 'Total' }) }}
        config={{}}
      />
    );
  }

  if (isCompactMatrix) {
    const rowId = rows.order[0];
    const { sourceId, config: rowConfig } = rows.list[rowId];
    const { hidden = {}, excluded = {} } = rowConfig;

    const element = list[sourceId];
    const mergedChoices = getMergedChoices(element, element.config.choices, list);

    const _columns = [];
    tableResult?.headOrder?.[rowId].forEach(orderItem => {
      /** @todo: refactor hidden heads to use headOrder */
      let isHidden = hidden?.[orderItem.headSubId] === true || excluded?.[orderItem.headSubId] === true;
      if (!isHidden && ((rowConfig.autoHideLowBases ?? false) || (rowConfig.config?.autoHideLowBases ?? false))) {
        const autoHideLowBasesHurdle =
          rowConfig.autoHideLowBasesHurdle ?? rowConfig.config?.autoHideLowBasesHurdle ?? 0;
        const highestBase = getHighestHeadBase(tableResult, rowId, orderItem.headSubId, {
          isDetailedMatrix: isDetailedMatrix,
          excludeHidden: true
        });
        if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
      }

      if (isHidden) return null;

      _columns.push(
        <ResultTableChartHeadColumnHeader
          key={`columnhead__${element.id}_${orderItem.headSubId}`}
          column={mergedChoices.list[orderItem.headSubId]}
          config={config}
        />
      );
    });

    thead.columnheads.push(..._columns);
    thead.header.push(
      <ResultTableChartHeadHeader key={`header__${element.id}`} element={element} colSpan={_columns.length} />
    );

    head.order.forEach(headId => {
      const _head = head.list[headId];
      if (_head.sourceType !== 'calculation') return;

      const { hidden = {}, excluded = {} } = _head.config;
      const isHidden = hidden?.[headId] === true || excluded?.[headId] === true;

      if (isHidden) return null;

      const element = {
        id: _head.id,
        type: 'calculation',
        title: 'Calculation'
      };

      const _column = (
        <ResultTableChartHeadColumnHeader
          key={`columnhead__${headId}_${headId}`}
          column={{
            id: _head.id,
            label: _head.config.title,
            value: _head.id
          }}
          config={config}
        />
      );

      thead.columnheads.push(_column);
      thead.header.push(<ResultTableChartHeadHeader key={`header__${element.id}`} element={element} colSpan={1} />);
    });
  } else {
    head.order.forEach(headid => {
      const _head = head.list[headid];
      const { hidden = {}, excluded = {} } = _head.config;

      let element;
      let columns = [];

      if (_head.sourceType === 'question') {
        element = list[_head.sourceId];
        const mergedChoices = getMergedChoices(element, element.config.choices, list);
        columns = mergedChoices;
      } else if (_head.sourceType === 'sysvar') {
        element = systemVariables.list[_head.sourceId];
        columns = { list: element.list, order: element.order };
      } else if (_head.sourceType === 'calcvar') {
        const calculationVariable = calcVars?.find(calculation => calculation.id === _head.sourceId);
        element = produce(calculationVariable, draft => {
          draft.type = 'calcvar';
        });
        columns = {
          list: Object.fromEntries(element.values.map(value => [value.id, value])),
          order: element.values.map(value => value.id)
        };
      } else if (_head.sourceType === 'calculation') {
        element = {
          id: headid,
          type: 'calculation',
          title: 'Calculation'
        };
        columns = {
          list: {
            [headid]: {
              id: headid,
              label: _head.config.title,
              value: headid
            }
          },
          order: [headid]
        };
      }

      const _columns = [];
      tableResult?.headOrder?.[headid]?.forEach(orderItem => {
        /** @todo: refactor hidden heads to use headOrder */
        let isHidden = hidden?.[orderItem.headSubId] === true || excluded?.[orderItem.headSubId] === true;

        if (!isHidden && (_head?.config?.autoHideLowBases ?? false)) {
          const autoHideLowBasesHurdle = _head?.config?.autoHideLowBasesHurdle ?? 0;
          const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
            isDetailedMatrix: isDetailedMatrix,
            excludeHidden: true
          });
          if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
        }

        if (isHidden) return null;

        _columns.push(
          <ResultTableChartHeadColumnHeader
            key={`columnhead__${element.id}_${orderItem.headSubId}`}
            column={columns.list[orderItem.headSubId]}
            config={config}
          />
        );
      });

      if (_columns.length > 0) {
        thead.columnheads.push(..._columns);
        thead.header.push(
          <ResultTableChartHeadHeader key={`header__${element.id}`} element={element} colSpan={_columns.length} />
        );
      }
    });
  }

  return (
    <thead ref={headRef} className={'smv-result-table__head'}>
      {head?.order?.length > 1 && <tr className={'head__row'}>{thead.header}</tr>}
      <tr className={'head__row'}>{thead.columnheads}</tr>
    </thead>
  );
};

ResultTableChartHead.propTypes = {
  table: PropTypes.object.isRequired
};
