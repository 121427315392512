import React from 'react';
import { useDrag } from 'react-dnd';
import { IconProvider } from 'smv-components';

export const CalculationItem = ({ calculation }) => {
  /* eslint-disable-next-line */
  const [{ opacity, isDragging }, drag] = useDrag({
    type: 'calcvar',
    item: {
      id: calculation.id,
      type: 'calcvar',
      config: calculation
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div className={`list-group-item-text question-item`} ref={drag}>
      <IconProvider icon={'layer-group'} />
      <span className='ml-2'>{calculation?.label ?? 'unnamed element'}</span>
    </div>
  );
};
