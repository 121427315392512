import React from 'react';

import {
  ReferenceTypeCalculation,
  ReferenceTypeCalculationVariable,
  ReferenceTypeQuestion,
  ReferenceTypeSystemVariable
} from '.';

export const ReferenceTypeSwitch = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleReferenceSelect
}) => {
  const { sourceType } = main;
  if (sourceType === 'question') {
    return (
      <ReferenceTypeQuestion
        conditionalFormat={conditionalFormat}
        main={main}
        matrixType={matrixType}
        dimensionKey={dimensionKey}
        handleReferenceSelect={handleReferenceSelect}
      />
    );
  } else if (sourceType === 'sysvar') {
    return (
      <ReferenceTypeSystemVariable
        conditionalFormat={conditionalFormat}
        main={main}
        matrixType={matrixType}
        dimensionKey={dimensionKey}
        handleReferenceSelect={handleReferenceSelect}
      />
    );
  } else if (sourceType === 'calcvar') {
    return (
      <ReferenceTypeCalculationVariable
        conditionalFormat={conditionalFormat}
        main={main}
        matrixType={matrixType}
        dimensionKey={dimensionKey}
        handleReferenceSelect={handleReferenceSelect}
      />
    );
  } else if (sourceType === 'calculation') {
    return (
      <ReferenceTypeCalculation
        conditionalFormat={conditionalFormat}
        main={main}
        matrixType={matrixType}
        dimensionKey={dimensionKey}
        handleReferenceSelect={handleReferenceSelect}
      />
    );
  }
  return null;
};
