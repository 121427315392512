import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { pick } from 'lodash';
import produce from 'immer';

import { reorderArray, useGetSourceElement, useSubelementPropertyTranslation } from 'smv-helpers';

export const FilterConfig = ({ handler, filter, element }) => {
  const intl = useIntl();
  const { elementTitle, elementValues } = useGetSourceElement()(pick(filter, ['id', 'sourceType', 'sourceId']));

  const [filterLabel, setFilterLabel] = useState(filter?.label ?? '');
  const filterLabelPlaceholder =
    elementTitle ??
    intl.formatMessage({ id: 'smoove.page.reports.modals.filters.unnamed-element', defaultMessage: 'unnamed element' });

  const handleChangeFilterLabel = useCallback(e => {
    const { value } = e.target;
    setFilterLabel(value);
  }, []);

  const handleSaveFilterLabel = useCallback(
    e => {
      if (filter.label === filterLabel || (filter.label === null && filterLabel.length <= 0)) return;

      const _filter = produce(filter, draft => {
        if (filterLabel.length > 0) draft.label = filterLabel;
        else draft.label = null;
      });

      handler.updateFilter(_filter);
    },
    [handler, filter, filterLabel]
  );

  const handleSwitchChange = useCallback(
    e => {
      const { name, checked } = e.target;
      handler.updateFilter(
        produce(filter, draft => {
          draft[name] = checked;
        })
      );
    },
    [handler, filter]
  );

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <h6>
          <FormattedMessage id={'smoove.page.common.filters.edit-filters'} defaultMessage={'Edit Filter'} />
        </h6>
        <Button size={'sm'} color={'grey'} onClick={() => handler.setSelectedFilter(null)}>
          <i className='fal fa-times'></i>
        </Button>
      </div>

      <Row>
        <Label for='filterLabel' className='font-weight-bold' md={5}>
          {intl.formatMessage({ id: 'smoove.page.project.results.filter-label' })}
        </Label>
        <Col md={7}>
          <Input
            type={'text'}
            id={`filterLabel`}
            name={'label'}
            value={filterLabel}
            placeholder={filterLabelPlaceholder}
            onChange={handleChangeFilterLabel}
            onBlur={handleSaveFilterLabel}
          />
        </Col>
      </Row>

      <Row>
        <Label for={'filterConfig'} className={'font-weight-bold'} md={5}>
          {intl.formatMessage({ id: 'smoove.page.project.results.filter-config' })}
        </Label>
        <Col md={7}>
          {/* isSingleSelect boolean */}
          <FormGroup switch>
            <Input
              type={'switch'}
              id={`isSingleSelect`}
              name={'isSingleSelect'}
              checked={filter?.isSingleSelect ?? false}
              onChange={handleSwitchChange}
            />
            <Label check>Single Select</Label>
          </FormGroup>

          {/* isMandatory boolean*/}
          <FormGroup switch>
            <Input
              type={'switch'}
              id={`isMandatory`}
              name={'isMandatory'}
              disabled={!filter?.defaultSelected}
              checked={filter?.isMandatory ?? false}
              onChange={handleSwitchChange}
            />
            <Label check>Mandatory</Label>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Label for={'filterElements'} className={'font-weight-bold'} md={5}>
          {intl.formatMessage({ id: 'smoove.page.common.filters.filter-elements' })}
        </Label>
        <Col md={7}>
          <FilterElementItems handler={handler} filter={filter} elementValues={elementValues} />
        </Col>
      </Row>
    </>
  );
};

const FilterElementItems = ({ handler, filter, elementValues }) => {
  const _itemOrder = useMemo(() => {
    const _order = filter?.order ?? [];
    _order.filter(valueid => elementValues.order.includes(valueid));
    elementValues.order.forEach(valueid => {
      if (!_order.includes(valueid)) {
        _order.push(valueid);
      }
    });

    return _order;
  }, [filter?.order, elementValues]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const newOrder = reorderArray(_itemOrder, result.source.index, result.destination.index);
    handler.updateFilter(
      produce(filter, draft => {
        draft.order = newOrder;
      })
    );
  };

  const items = _itemOrder.map((valueid, idx) => {
    return (
      <FilterElementItem
        key={valueid}
        index={idx}
        item={elementValues.list[valueid]}
        handler={handler}
        filter={filter}
      />
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'filter-element-item-list-droppable'}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            // style={getListStyle(snapshot.isDraggingOver)}
            className={'droppable-default'}
          >
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const FilterElementItem = ({ handler, filter, item, index }) => {
  const isHidden = filter?.hidden?.[item.id] ?? false;
  const isDefaultSelected = filter?.defaultSelected === item.id;

  const label = useSubelementPropertyTranslation(item, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const toggleHiddenElement = useCallback(() => {
    handler.updateFilter(
      produce(filter, draft => {
        if (!draft?.hidden) draft.hidden = {};
        if (draft?.hidden[item.id]) {
          delete draft.hidden[item.id];
        } else {
          draft.hidden[item.id] = true;
          if (draft?.defaultSelected === item.id) {
            delete draft.defaultSelected;
            if (draft?.isMandatory) {
              draft.isMandatory = false;
            }
          }
        }
      })
    );
  }, [handler, filter, item.id]);

  const setDefaultSelectedElement = useCallback(() => {
    handler.updateFilter(
      produce(filter, draft => {
        if (draft?.defaultSelected === item.id) {
          delete draft.defaultSelected;
          if (draft?.isMandatory) {
            draft.isMandatory = false;
          }
        } else {
          draft.defaultSelected = item.id;
        }
      })
    );
  }, [handler, filter, item.id]);

  return (
    <Draggable draggableId={item?.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className={`draggable-default split-item ${isHidden ? 'split-item--hidden' : ''}`}
        >
          <span>
            <i className='fal fa-grip-vertical mx-2'></i>
            {label}
          </span>
          <span>
            <span onClick={setDefaultSelectedElement}>
              <i className={`pointer mr-2 fa-badge-check ${isDefaultSelected ? 'fa-solid' : 'fa-light'}`} />
            </span>
            <span onClick={toggleHiddenElement}>
              <i className={`pointer mr-2 fal ${isHidden ? 'fa-eye-slash' : 'fa-eye'}`} />
            </span>
          </span>
          {item.content}
        </div>
      )}
    </Draggable>
  );
};
