import { useCallback } from 'react';
import { DropdownItem } from 'reactstrap';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const FormulaBuilderReferenceElementFixedReferenceDropdownItem = ({
  element,
  value,
  isActive = false,
  showIcon = true,
  handleFixedElementSelect
}) => {
  const _handleFixedElementSelect = useCallback(() => handleFixedElementSelect(element), [
    element,
    handleFixedElementSelect
  ]);

  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  return (
    <DropdownItem
      onClick={_handleFixedElementSelect}
      className={classNames({
        'dropdown-item--active': isActive
      })}
    >
      <div className={'smv-custom-dropdown__buttons'}>
        {isActive && <i className='fa-regular fa-lock'></i>}
        {!isActive && <i className='fa-regular'></i>}
        <span>{label}</span>
      </div>
    </DropdownItem>
  );
};
