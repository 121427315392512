import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useIntl } from 'react-intl';

export const ConfirmAction = ({
  callback,
  body,
  title,
  children,
  size = null,
  btnColor = 'danger',
  btnText = null,
  disabled = false
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const intl = useIntl();

  const handler = {
    show: () => setIsOpened(true),
    hide: () => setIsOpened(false),
    confirm: () => {
      callback();
      handler.hide();
    }
  };

  return (
    <>
      {children(handler.show)}
      <Modal isOpen={isOpened} centered={true} toggle={handler.hide} size={size} autoFocus={false}>
        <ModalHeader toggle={handler.hide}>{title}</ModalHeader>
        <ModalBody children={body} />
        <ModalFooter>
          <Button color={'secondary'} outline={true} onClick={handler.hide}>
            {intl.formatMessage({ id: `smoove.common.buttons.cancel` })}
          </Button>
          <Button color={btnColor} onClick={handler.confirm} autoFocus={true} disabled={disabled}>
            {btnText ?? intl.formatMessage({ id: `smoove.common.buttons.delete` })}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
