export const dataPrepActionTypes = {
  SAVE_DATAPREP_ELEMENT_REQUEST: 'SAVE_DATAPREP_ELEMENT_REQUEST',
  SAVE_DATAPREP_ELEMENT_SUCCESS: 'SAVE_DATAPREP_ELEMENT_SUCCESS',
  SAVE_DATAPREP_ELEMENT_FAILURE: 'SAVE_DATAPREP_ELEMENT_FAILURE',

  DELETE_DATAPREP_ELEMENT_REQUEST: 'DELETE_DATAPREP_ELEMENT_REQUEST',
  DELETE_DATAPREP_ELEMENT_SUCCESS: 'DELETE_DATAPREP_ELEMENT_SUCCESS',
  DELETE_DATAPREP_ELEMENT_FAILURE: 'DELETE_DATAPREP_ELEMENT_FAILURE',

  LOAD_DATAPREP_WEIGHTING_FACTORS_REQUEST: 'LOAD_DATAPREP_WEIGHTING_FACTORS_REQUEST',
  LOAD_DATAPREP_WEIGHTING_FACTORS_SUCCESS: 'LOAD_DATAPREP_WEIGHTING_FACTORS_SUCCESS',
  LOAD_DATAPREP_WEIGHTING_FACTORS_FAILURE: 'LOAD_DATAPREP_WEIGHTING_FACTORS_FAILURE',

  DATA_APPLY_CALCULATIONS_REQUEST: 'DATA_APPLY_CALCULATIONS_REQUEST',
  DATA_APPLY_CALCULATIONS_SUCCESS: 'DATA_APPLY_CALCULATIONS_SUCCESS',
  DATA_APPLY_CALCULATIONS_FAILURE: 'DATA_APPLY_CALCULATIONS_FAILURE',

  ADD_DATA_PREP_REQUEST: 'ADD_DATA_PREP_REQUEST',
  ADD_DATA_PREP_SUCCESS: 'ADD_DATA_PREP_SUCCESS',
  ADD_DATA_PREP_FAILURE: 'ADD_DATA_PREP_FAILURE',

  UPDATE_DATA_PREP_REQUEST: 'UPDATE_DATA_PREP_REQUEST',
  UPDATE_DATA_PREP_SUCCESS: 'UPDATE_DATA_PREP_SUCCESS',
  UPDATE_DATA_PREP_FAILURE: 'UPDATE_DATA_PREP_FAILURE',

  DELETE_DATA_PREP_REQUEST: 'DELETE_DATA_PREP_REQUEST',
  DELETE_DATA_PREP_SUCCESS: 'DELETE_DATA_PREP_SUCCESS',
  DELETE_DATA_PREP_FAILURE: 'DELETE_DATA_PREP_FAILURE',

  GET_DATA_PREP_BY_SURVEY_ID_REQUEST: 'GET_DATA_PREP_BY_SURVEY_ID_REQUEST',
  GET_DATA_PREP_BY_SURVEY_ID_SUCCESS: 'GET_DATA_PREP_BY_SURVEY_ID_SUCCESS',
  GET_DATA_PREP_BY_SURVEY_ID_FAILURE: 'GET_DATA_PREP_BY_SURVEY_ID_FAILURE',

  ADD_DATA_PREP_WEIGHT_REQUEST: 'ADD_DATA_PREP_WEIGHT_REQUEST',
  ADD_DATA_PREP_WEIGHT_SUCCESS: 'ADD_DATA_PREP_WEIGHT_SUCCESS',
  ADD_DATA_PREP_WEIGHT_FAILURE: 'ADD_DATA_PREP_WEIGHT_FAILURE',

  UPDATE_DATA_PREP_WEIGHT_REQUEST: 'UPDATE_DATA_PREP_WEIGHT_REQUEST',
  UPDATE_DATA_PREP_WEIGHT_SUCCESS: 'UPDATE_DATA_PREP_WEIGHT_SUCCESS',
  UPDATE_DATA_PREP_WEIGHT_FAILURE: 'UPDATE_DATA_PREP_WEIGHT_FAILURE',

  DELETE_DATA_PREP_WEIGHT_REQUEST: 'DELETE_DATA_PREP_WEIGHT_REQUEST',
  DELETE_DATA_PREP_WEIGHT_SUCCESS: 'DELETE_DATA_PREP_WEIGHT_SUCCESS',
  DELETE_DATA_PREP_WEIGHT_FAILURE: 'DELETE_DATA_PREP_WEIGHT_FAILURE',

  ADD_DATA_PREP_CALCULATION_REQUEST: 'ADD_DATA_PREP_CALCULATION_REQUEST',
  ADD_DATA_PREP_CALCULATION_SUCCESS: 'ADD_DATA_PREP_CALCULATION_SUCCESS',
  ADD_DATA_PREP_CALCULATION_FAILURE: 'ADD_DATA_PREP_CALCULATION_FAILURE',

  UPDATE_DATA_PREP_CALCULATION_REQUEST: 'UPDATE_DATA_PREP_CALCULATION_REQUEST',
  UPDATE_DATA_PREP_CALCULATION_SUCCESS: 'UPDATE_DATA_PREP_CALCULATION_SUCCESS',
  UPDATE_DATA_PREP_CALCULATION_FAILURE: 'UPDATE_DATA_PREP_CALCULATION_FAILURE',

  DELETE_DATA_PREP_CALCULATION_REQUEST: 'DELETE_DATA_PREP_CALCULATION_REQUEST',
  DELETE_DATA_PREP_CALCULATION_SUCCESS: 'DELETE_DATA_PREP_CALCULATION_SUCCESS',
  DELETE_DATA_PREP_CALCULATION_FAILURE: 'DELETE_DATA_PREP_CALCULATION_FAILURE',

  ADD_DATA_PREP_CALCULATION_VALUE_REQUEST: 'ADD_DATA_PREP_CALCULATION_VALUE_REQUEST',
  ADD_DATA_PREP_CALCULATION_VALUE_SUCCESS: 'ADD_DATA_PREP_CALCULATION_VALUE_SUCCESS',
  ADD_DATA_PREP_CALCULATION_VALUE_FAILURE: 'ADD_DATA_PREP_CALCULATION_VALUE_FAILURE',

  UPDATE_DATA_PREP_CALCULATION_VALUE_REQUEST: 'UPDATE_DATA_PREP_CALCULATION_VALUE_REQUEST',
  UPDATE_DATA_PREP_CALCULATION_VALUE_SUCCESS: 'UPDATE_DATA_PREP_CALCULATION_VALUE_SUCCESS',
  UPDATE_DATA_PREP_CALCULATION_VALUE_FAILURE: 'UPDATE_DATA_PREP_CALCULATION_VALUE_FAILURE',

  DELETE_DATA_PREP_CALCULATION_VALUE_REQUEST: 'DELETE_DATA_PREP_CALCULATION_VALUE_REQUEST',
  DELETE_DATA_PREP_CALCULATION_VALUE_SUCCESS: 'DELETE_DATA_PREP_CALCULATION_VALUE_SUCCESS',
  DELETE_DATA_PREP_CALCULATION_VALUE_FAILURE: 'DELETE_DATA_PREP_CALCULATION_VALUE_FAILURE',

  ADD_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST: 'ADD_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST',
  ADD_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS: 'ADD_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS',
  ADD_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE: 'ADD_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE',

  REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST: 'REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST',
  REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS: 'REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS',
  REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE: 'REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE',

  UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST: 'UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST',
  UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS: 'UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS',
  UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE: 'UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE',

  UPLOAD_WEIGHT_CSV_REQUEST: 'UPLOAD_WEIGHT_CSV_REQUEST',
  UPLOAD_WEIGHT_CSV_SUCCESS: 'UPLOAD_WEIGHT_CSV_SUCCESS',
  UPLOAD_WEIGHT_CSV_FAILURE: 'UPLOAD_WEIGHT_CSV_FAILURE',

  DELETE_WEIGHT_CSV_REQUEST: 'DELETE_WEIGHT_CSV_REQUEST',
  DELETE_WEIGHT_CSV_SUCCESS: 'DELETE_WEIGHT_CSV_SUCCESS',
  DELETE_WEIGHT_CSV_FAILURE: 'DELETE_WEIGHT_CSV_FAILURE'
};
