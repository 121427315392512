import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
// import DatePicker from 'react-datepicker';
// import { isEqual } from 'lodash';
import produce from 'immer';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { TextInputRow, RowLabel } from 'smv-components';
import { surveyActions } from 'smv-redux';
import { MediaLibraryModal } from 'src/smoove/components/questionnaire/Content/MediaLibrary/MediaLibraryButtonAndModal';

import {
  SurveyStatus,
  QuestbackSettings,
  DataActions,
  SurveyReferenceSettings
} from '../../components/survey-settings';
import { PageHeadline } from '../../components';

import '../../assets/css/react-datepicker-importer.scss';
import './SurveySettings.scss';
import { permissionEnum, useUserHasPermission, useIsUserSmooveAdmin } from 'smv-helpers';
import { BillingDetailedSurveyCosts } from 'src/smoove/components/BillingSystem/BillingDetailedSurveyCosts';
import { SurveyLocalesList } from 'src/smoove/components/survey-settings/SurveyLocalesList';

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const SurveySettings = () => {
  const intl = useIntl();
  const projectid = useSelector(state => state.survey.projectid);
  const project = useSelector(state => state.project);
  const survey = useSelector(state => state.survey ?? {});
  const user = useSelector(state => state.authentication.user);
  const clients = useSelector(state => state.clients?.list);
  const surveyClientId = useSelector(state => state.clients?.selected);
  const billingClientId = useSelector(state => state.survey?.billing?.clientId);

  const [billingClient, setBillingClient] = useState(clients[billingClientId ?? surveyClientId]);

  const params = useParams();
  const { surveyid } = params;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const isUserProjectAdmin = useUserHasPermission(permissionEnum.PROJECT_ADMIN);
  const isUserClientAdmin = useUserHasPermission(permissionEnum.CLIENT_ADMIN);
  const isUserSmooveAdmin = useIsUserSmooveAdmin();

  const userAdminClients = useMemo(() => {
    let uAdminClients = [];

    if (isUserSmooveAdmin) {
      uAdminClients = clients && typeof clients === 'object' ? Object.values(clients) : [];
    } else if (isUserClientAdmin) {
      uAdminClients = Object.entries(user.clientRoles).reduce((list, [clientId, roles]) => {
        const roleId = roles.clientRole?.role;
        const client = clients[clientId];
        const userClientRole = clients[clientId]?.roles?.clientRoles?.find(el => el.id === roleId);
        if (userClientRole && userClientRole.permissions?.includes(permissionEnum.CLIENT_ADMIN)) {
          list.push(client);
        }
        return list;
      }, []);
    }

    return uAdminClients;
  }, [clients, user?.clientRoles, isUserSmooveAdmin, isUserClientAdmin]);

  const userIsAdminForBillingClient = useMemo(() => {
    return billingClient && userAdminClients.find(cl => cl.id === billingClient.id) ? true : false;
  }, [userAdminClients, billingClient]);

  const media = useSelector(state => state.survey.media);

  const _media = useMemo(() => {
    if (!media) {
      console.log('no media!');
      return [];
    }
    return produce(Object.values(media), draft => {
      draft.forEach((media, index) => {
        draft[index].local = {
          url: `${baseUrl}/${media.url}`,
          thumbnailUrl: `${baseUrl}/${media.thumbnailUrl}`
        };
        draft[index].loading = media.loading;
      });
    });
  }, [media]);

  // useEffect(() => {
  //   surveyActions.getAvailableLanguages();
  // }, []);

  // const [state, setState] = useState({
  //   ...config
  // });

  // const [deselectableRadioCb, setDeselectableRadioCb] = useState(false);
  // const [includeBackButtonsCb, setIncludeBackButtonsCb] = useState(false);
  // const [customPanelInput, setCustomPanelInput] = useState('');
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);

  const toggleMediaLibrary = useCallback(() => {
    setIsMediaLibraryOpen(b => !b);
  }, []);

  // const calendarRef = useRef();
  const patchSurvey = useCallback(
    element => {
      surveyActions.updateSurvey(projectid, surveyid, element);
    },
    [projectid, surveyid]
  );

  const internalHandler = useMemo(
    () => ({
      launchSurvey: () => {
        if (!userCanEditProject) return;
        surveyActions.launchSurvey(projectid, surveyid);
      },
      startTesting: () => {
        if (!userCanEditProject) return;
        surveyActions.testSurvey(projectid, surveyid);
      },
      generateDummyData: (cases = 100) => {
        if (!userCanEditProject) return;
        surveyActions.generateDummyData(surveyid, cases);
      },
      changeStatus: e => {
        if (!userCanEditProject) return;
        // const { value } = e.target;
        // if (['wizard', 'draft', 'running', 'completed'].includes(value)) {
        //   setState(state => ({ ...state, status: value }));
        // }
      }
      // setSelectedPanel: id => {
      //   setState(state =>
      //     produce(state, draftState => {
      //       if (!draftState?.panel) draftState.panel = { type: 'custom' };
      //       draftState.panel.type = id;
      //     })
      //   );
      // }
    }),
    [surveyid, userCanEditProject, projectid]
  );

  const updateBillingClient = useCallback(
    clientId => {
      if (clientId && clients[clientId] && billingClient !== clients[clientId]) {
        setBillingClient(clients[clientId]);
        patchSurvey({ billing: { clientId: clientId } });
      }
    },
    [clients, billingClient, patchSurvey]
  );

  useEffect(() => {
    const clientId = billingClientId ?? surveyClientId;
    if (!billingClient) {
      setBillingClient(clients[clientId] ?? null);
    } else if (billingClient !== clients[clientId]) {
      setBillingClient(clients[clientId]);
    }
  }, [clients, billingClientId, surveyClientId, billingClient]);

  const [surveyTitle, setSurveyTitle] = useState(survey.title ?? '');
  const updateSurveyTitle = useCallback(() => {
    if (surveyTitle !== survey.title) {
      patchSurvey({ title: surveyTitle });
    }
  }, [surveyTitle, patchSurvey, survey.title]);

  const [surveyDescription, setSurveyDescription] = useState(survey.description ?? '');
  const updateSurveyDescription = useCallback(() => {
    if (surveyDescription !== survey.description) {
      patchSurvey({ description: surveyDescription });
    }
  }, [surveyDescription, patchSurvey, survey.description]);

  const updateSurveyImages = useCallback(
    images => {
      const imgIds = images.map(el => el.id);
      if (imgIds !== survey.cardImages) {
        patchSurvey({ cardImages: imgIds });
      }
    },
    [patchSurvey, survey.cardImages]
  );

  useEffect(() => {
    if (survey.title) {
      setSurveyTitle(survey.title);
    }
  }, [survey.title]);

  useEffect(() => {
    if (survey.description) {
      setSurveyDescription(survey.description);
    }
  }, [survey.description]);

  const handleToggleSampling = useCallback(
    val => {
      patchSurvey({ includeSampling: val });
    },
    [patchSurvey]
  );

  return (
    <div className='smv-general-survey-settings container-fluid base-content flex-fill'>
      <Helmet>
        <title>{survey.title + ' - ' + intl.formatMessage({ id: `core.menu.surveysubmenu.item.survey` })}</title>
      </Helmet>
      <PageHeadline headline={'smoove.page.survey.general.headline'} />
      <nav className={'small'} aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <i className='fal fa-folder mr-1'></i>
            <Link to={`/one/projects/${projectid}/projectsettings`}>{project?.title ?? ''}</Link>
          </li>
          <li className='breadcrumb-item'>{survey?.title}</li>
        </ol>
      </nav>

      <Form>
        <TextInputRow
          name={'title'}
          title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-name' })}
          tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-name-tooltip' })}
          value={surveyTitle}
          change={e => setSurveyTitle(e.target.value)}
          onBlur={updateSurveyTitle}
          placeholder={intl.formatMessage({
            id: 'smoove.page.survey.general.survey-name-placeholder'
          })}
          disabled={!userCanEditProject}
        />
        <TextInputRow
          name={'description'}
          title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-description' })}
          tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-description-tooltip' })}
          value={surveyDescription}
          change={e => setSurveyDescription(e.target.value)}
          onBlur={updateSurveyDescription}
          placeholder={intl.formatMessage({
            id: 'smoove.page.survey.general.survey-description-placeholder'
          })}
          disabled={!userCanEditProject}
        />
        <Row className='my-3'>
          <RowLabel
            name={'type'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-type' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-type-tooltip' })}
          />
          <Col md={7} className='py-2'>
            {survey.type && survey.type?.length > 0 ? survey.type : <i>unknown survey type</i>}
          </Col>
        </Row>
        <Row className='my-3'>
          <RowLabel
            name={'relatedproject'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.related-project' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.related-project-tooltip' })}
          />
          <Col md={7} className='py-2'>
            <Link to={`/one/projects/${projectid}/projectsettings`}>{project?.title ?? ''}</Link>
          </Col>
        </Row>
        {(isUserClientAdmin || isUserProjectAdmin) && (
          <Row className='my-4'>
            <RowLabel name={'surveycosts'} title={'Survey costs'} tooltip={'The costs to start the survey'} />
            <Col md={7}>
              <BillingDetailedSurveyCosts />
            </Col>
          </Row>
        )}
        <Row className='my-4'>
          <RowLabel
            name={'type'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-payment-client' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-payment-client-tooltip' })}
          />
          <Col md={7} className='py-2'>
            {(isUserSmooveAdmin || isUserClientAdmin) &&
            billingClient &&
            userAdminClients &&
            userAdminClients.length > 0 ? (
              <Input
                type={'select'}
                id={`clientselect`}
                name='clientselect'
                value={billingClient?.id}
                onChange={e => updateBillingClient(e.target.value)}
              >
                {/* This option is shown only in case, when the current user is a client-admin 
                but doesn't have permissions for billing client which was set by some other admin*/}
                {billingClient && !userIsAdminForBillingClient ? (
                  <option key={billingClient.id} value={billingClient.id}>
                    {billingClient.title}
                  </option>
                ) : (
                  ''
                )}
                {userAdminClients.map(_client => {
                  return (
                    <option key={_client.id} value={_client.id}>
                      {_client.title}
                    </option>
                  );
                })}
              </Input>
            ) : (
              <div>{billingClient && billingClient.title ? billingClient.title : <i>undefined client</i>}</div>
            )}
          </Col>
        </Row>
        <Row className='my-4'>
          <RowLabel
            name={'surveystatus'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-status.label' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-status.tooltip' })}
          />
          <Col md={7}>
            <div className='survey-status'>
              {/* todo: update the functionality for setting surveyStatus when BE is ready */}
              <SurveyStatus handler={internalHandler} />
            </div>
          </Col>
        </Row>
        <SurveyReferenceSettings survey={survey} key={survey.id + '_referencesettings'} />
        {userCanEditProject && <QuestbackSettings survey={survey} key={survey.id + '_qbsettings'} />}
        {userCanEditProject && <DataActions survey={survey} />}

        {userCanEditProject && (
          <>
            <Row className='my-4'>
              <RowLabel name={'sampling'} title={'Sampling'} tooltip={'Sampling settings'} />
              <Col md={7}>
                <FormGroup switch>
                  <Input
                    type='switch'
                    role='switch'
                    className='pointer'
                    id={`toggleSampling`}
                    name='toggleSampling'
                    // todo: does this need a seperate state?
                    checked={survey?.includeSampling ?? false}
                    onChange={() => handleToggleSampling(!survey?.includeSampling)}
                  />
                  <Label for={'toggleSampling'} className='pointer'>
                    Include sampling
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className='my-4'>
              <RowLabel
                name={'sampling_settings'}
                title={intl.formatMessage({ id: 'smoove.page.survey.general.survey-languages.label' })}
                tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.survey-languages.tooltip' })}
              />
              <Col md={7}>
                <SurveyLocalesList />
              </Col>
            </Row>
          </>
        )}

        {userCanEditProject && (
          <Row className='my-4'>
            <RowLabel
              name={'images'}
              title={intl.formatMessage({ id: 'smoove.page.project.general.images.select-project-image' })}
              tooltip={intl.formatMessage({ id: 'smoove.page.project.general.images.select-project-image-tooltip' })}
            />
            <Col md={7}>
              <div className='survey-thumbnails-container'>
                {(survey?.cardImages ?? []).map((imageId, idx) => {
                  const img = _media?.find(el => el.id === imageId);
                  if (!img) return null;
                  return (
                    <div
                      key={`${img?.id}_${idx}`}
                      className='survey-thumbnails-container__thumb'
                      onClick={toggleMediaLibrary}
                    >
                      <img src={img?.local?.thumbnailUrl ?? img?.local?.url} alt={img?.name} />
                    </div>
                  );
                })}
                <div className='survey-thumbnails-container__empty-thumb' onClick={toggleMediaLibrary}>
                  <i className='far fa-plus fa-2x' />
                </div>
              </div>
              <MediaLibraryModal
                toggle={toggleMediaLibrary}
                isOpen={isMediaLibraryOpen}
                writeable={true}
                isSelectionDisabled={false}
                selectFileCb={updateSurveyImages}
                prevSelectedFiles={survey.cardImages}
                allowedFileTypes={['image']}
              />
            </Col>
          </Row>
        )}

        {/* <Row className='my-4'>
          <RowLabel
            name={'targetgroup'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.target-group.label' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.target-group.tooltip' })}
          />
          <Col md={7}>{intl.formatMessage({ id: 'smoove.page.survey.general.target-group.quota' })} (tba)</Col>
        </Row> */}

        {/* <Row className='my-4'>
          <RowLabel
            name={'questionnairesettings'}
            title={intl.formatMessage({ id: 'smoove.page.survey.general.questionnaire-settings.label' })}
            tooltip={intl.formatMessage({ id: 'smoove.page.survey.general.questionnaire-settings.tooltip' })}
          />
          <Col md={7}>
            <Input
              type='checkbox'
              id={'deselectableradiobuttons'}
              label={intl.formatMessage({
                id: 'smoove.page.survey.general.questionnaire-settings.deselectable-radiobuttons'
              })}
              checked={deselectableRadioCb}
              onChange={() => setDeselectableRadioCb(!deselectableRadioCb)}
            />
            <Input
              type='checkbox'
              id={'includebackbuttoninquestionnaire'}
              label={intl.formatMessage({
                id: 'smoove.page.survey.general.questionnaire-settings.include-back-button'
              })}
              checked={includeBackButtonsCb}
              onChange={() => setIncludeBackButtonsCb(!includeBackButtonsCb)}
            />
          </Col>
        </Row> */}
      </Form>
    </div>
  );
};
