import React from 'react';

import { ResultVisual } from './..';
import { ResultTableHeading, ResultTableEmpty, ResultTableDefault, ResultTableOpenEndedListing } from '.';

import './ResultTable.scss';

export const ResultTable = ({
  handler,
  tableConfig,
  tableResult,
  tableResultError,
  isCompactMatrix,
  matrixType,
  resultTableType,
  isEmpty,
  isLoading,
  isConfigChanged
}) => {
  if (isEmpty) {
    return <ResultTableEmpty handler={handler} />;
  }

  return (
    <>
      <ResultTableHeading
        table={tableConfig}
        isLoading={isLoading}
        isError={tableResultError}
        isConfigChanged={isConfigChanged}
      />

      {resultTableType === 'default' && (
        <ResultTableDefault
          handler={handler}
          table={tableConfig}
          tableResult={tableResult}
          isCompactMatrix={isCompactMatrix}
          matrixType={matrixType}
          isConfigChanged={isConfigChanged}
          isLoading={isLoading}
        />
      )}

      {resultTableType === 'listing' && (
        <ResultTableOpenEndedListing
          handler={handler}
          table={tableConfig}
          tableResult={tableResult}
          isConfigChanged={isConfigChanged}
          isLoading={isLoading}
        />
      )}

      {!isEmpty && !tableResultError && (
        <ResultVisual
          table={tableConfig}
          tableResult={tableResult}
          resultTableType={resultTableType}
          isLoading={isLoading}
          handler={handler}
        />
      )}
    </>
  );
};
