import Axios from 'axios';

import 'smv-redux/axiosConfig';
import { store } from 'smv-redux';

import { tablesActionTypes } from '../constants';
import { downloadFile } from './helper';
import { Store as notify } from 'react-notifications-component';
import { defaultNotifyProps } from 'smv-constants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

const loadTables = surveyId => {
  store.dispatch({
    type: tablesActionTypes.LOAD_TABLES_REQUEST,
    payload: { surveyId }
  });

  Axios.get(`/surveys/${surveyId}/tables`)
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.LOAD_TABLES_SUCCESS,
        payload: {
          surveyId,
          tables: res.data
        }
      });
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.LOAD_TABLES_FAILURE,
        payload: err
      });
    });
};

const updateTable = async (surveyId, tableId, table) => {
  store.dispatch({
    type: tablesActionTypes.UPDATE_TABLE_REQUEST,
    payload: { table, tableId }
  });

  const tableData = {
    chart: table.chart,
    config: table.config,
    heads: table.heads,
    name: table.name,
    parentId: table.parentId,
    rows: table.rows,
    surveyId: table.surveyId,
    filters: table.filters,
    position: table.position
  };

  return Axios.patch(`/surveys/${surveyId}/tables/${tableId}`, tableData)
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.UPDATE_TABLE_SUCCESS,
        payload: {
          table: res.data,
          tableId
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.UPDATE_TABLE_FAILURE,
        payload: {
          tableId,
          error: err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const createTable = async (surveyId, table) => {
  store.dispatch({
    type: tablesActionTypes.CREATE_TABLE_REQUEST,
    payload: { table }
  });

  const tableData = {
    chart: table.chart,
    config: table.config,
    heads: table.heads,
    name: table.name,
    parentId: table.parentId,
    rows: table.rows,
    surveyId: table.surveyId,
    filters: table.filters
  };

  return Axios.post(`/surveys/${surveyId}/tables`, tableData)
    .then(res => {
      const table = res.data;

      store.dispatch({
        type: tablesActionTypes.CREATE_TABLE_SUCCESS,
        payload: {
          table
        }
      });

      return table;
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.CREATE_TABLE_FAILURE,
        payload: {
          error: err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const deleteTable = async (surveyId, tableId) => {
  store.dispatch({
    type: tablesActionTypes.DELETE_TABLE_REQUEST,
    payload: { surveyId, tableId }
  });

  return Axios.delete(`/surveys/${surveyId}/tables/${tableId}`)
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.DELETE_TABLE_SUCCESS,
        payload: { surveyId, tableId }
      });

      return true;
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.DELETE_TABLE_FAILURE,
        payload: { surveyId, tableId, error: err }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });

      return false;
    });
};

const loadFolders = async surveyId => {
  store.dispatch({
    type: tablesActionTypes.LOAD_FOLDERS_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/tables/folders`)
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.LOAD_FOLDERS_SUCCESS,
        payload: {
          surveyId,
          folders: res.data
        }
      });

      return res.data;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: tablesActionTypes.LOAD_FOLDERS_FAILURE,
        payload: err
      });
    });
};

const createFolder = async (surveyId, folder) => {
  store.dispatch({
    type: tablesActionTypes.CREATE_FOLDER_REQUEST,
    payload: folder
  });

  return Axios.post(`/surveys/${surveyId}/tables/folders`, folder)
    .then(res => {
      console.log('folders', res.data);
      store.dispatch({
        type: tablesActionTypes.CREATE_FOLDER_SUCCESS,
        payload: {
          tables: res.data
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.CREATE_FOLDER_FAILURE,
        payload: err
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const deleteFolder = async (surveyId, folderId) => {
  store.dispatch({
    type: tablesActionTypes.DELETE_FOLDER_REQUEST,
    payload: { folderId }
  });

  return Axios.delete(`/surveys/${surveyId}/tables/folders/${folderId}`)
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.DELETE_FOLDER_SUCCESS
      });
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.DELETE_FOLDER_FAILURE,
        payload: err
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const updateFolder = async (surveyId, id, folder) => {
  store.dispatch({
    type: tablesActionTypes.UPDATE_FOLDER_REQUEST,
    payload: { folderId: id }
  });

  const { name, parentId, children, position } = folder;

  return Axios.patch(`/surveys/${surveyId}/tables/folders/${id}`, { name, parentId, children, position })
    .then(res => {
      const folder = res.data;

      store.dispatch({
        type: tablesActionTypes.UPDATE_FOLDER_SUCCESS,
        payload: { folder }
      });
    })
    .catch(err => {
      store.dispatch({
        type: tablesActionTypes.UPDATE_FOLDER_FAILURE,
        payload: err
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const copyFolder = async (surveyId, folderId) => {
  store.dispatch({
    type: tablesActionTypes.COPY_FOLDER_REQUEST,
    payload: { surveyId, folderId }
  });

  return Axios.request({
    method: 'copy',
    url: `/surveys/${surveyId}/tables/folders/${folderId}`
  })
    .then(res => {
      store.dispatch({
        type: tablesActionTypes.COPY_FOLDER_SUCCESS,
        payload: {
          surveyId,
          folders: res.data
        }
      });

      return res.data;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: tablesActionTypes.COPY_FOLDER_FAILURE,
        payload: err
      });
    });
};

/**
 *
 * @param {*} surveyId
 * @param {*} table
 * @param {*} selectedLocale
 * @param {*} activeSplits
 * @param {*} activeFilters
 * @returns
 */
const loadTableResults = (surveyId, table, selectedLocale, activeSplits, activeFilters) => {
  store.dispatch({
    type: tablesActionTypes.LOAD_TABLE_RESULT_REQUEST,
    payload: { surveyId, tableid: table?.id, table: table }
  });

  const tableData = {
    table: { rows: table.rows, heads: table.heads, config: table.config, filters: table?.filters ?? null },
    locale: selectedLocale,
    activeSplits: activeSplits ?? {},
    activeFilters: activeFilters ?? {}
  };

  return new Promise((resolve, reject) => {
    Axios.post(`surveys/${surveyId}/tables/results`, tableData, { timeout: 5 * 60 * 1000 })
      .then(res => {
        const results = res.data;

        store.dispatch({
          type: tablesActionTypes.LOAD_TABLE_RESULT_SUCCESS,
          payload: {
            surveyId,
            tableid: table.id,
            result: results
          }
        });
        resolve(results);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: tablesActionTypes.LOAD_TABLE_RESULT_FAILURE,
          payload: {
            surveyId,
            tableid: table?.id,
            error: error
          }
        });

        notify.addNotification({
          title: parseErrorAsMessageString(error),
          type: 'danger',
          ...defaultNotifyProps
        });
        reject(error);
      });
  });
};

/**
 *
 * @param {*} surveyId
 * @param {*} table
 * @param {*} selectedLocale
 * @param {*} activeSplits
 * @param {*} activeFilters
 * @param {*} chartLayer
 * @returns
 */
const loadTableResultsLayered = (surveyId, table, selectedLocale, activeSplits, activeFilters, chartLayer) => {
  store.dispatch({
    type: tablesActionTypes.LOAD_TABLE_RESULT_REQUEST,
    payload: { surveyId, tableid: table?.id, table: table }
  });

  const viewIds = ['default', ...chartLayer.views.order];

  return Promise.all(
    viewIds.map(viewId => {
      const tableData = {
        table: { rows: table.rows, heads: table.heads, config: table.config, filters: table?.filters ?? null },
        locale: selectedLocale,
        activeSplits: activeSplits ?? {},
        activeFilters: activeFilters ?? {},
        activeLayerView: viewId === 'default' ? null : { layerId: chartLayer.id, viewId: viewId }
      };

      return new Promise((resolve, reject) => {
        Axios.post(`surveys/${surveyId}/tables/results`, tableData, { timeout: 5 * 60 * 1000 })
          .then(res => {
            const result = res.data;
            resolve({ result, viewId });
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    })
  )
    .then(resArray => {
      const results = {};
      resArray.forEach(({ result, viewId }) => {
        results[viewId] = result;
      });

      store.dispatch({
        type: tablesActionTypes.LOAD_TABLE_RESULT_SUCCESS,
        payload: {
          surveyId,
          tableid: table.id,
          result: results
        }
      });
      Promise.resolve(results);
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: tablesActionTypes.LOAD_TABLE_RESULT_FAILURE,
        payload: {
          surveyId,
          tableid: table?.id,
          error: error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });
      Promise.reject(error);
    });
};

const exportFile = (surveyId, table, exportType, isShortlabelsActive) => {
  const _table = { ...table };

  if (_table.hasOwnProperty('id')) {
    delete _table.id;
  }
  if (_table.hasOwnProperty('loading')) {
    delete _table.loading;
  }
  if (_table.hasOwnProperty('error')) {
    delete _table.error;
  }
  if (_table.hasOwnProperty('result')) {
    delete _table.result;
  }

  const data = {
    exportType,
    table: _table,
    isShortlabelsActive: isShortlabelsActive
  };

  Axios.post(`/surveys/${surveyId}/tables/export`, data, {
    responseType: 'blob',
    timeout: 5 * 60 * 1000
  }).then(res => {
    let filename = `smoove_export.${exportType}`;
    const contentDispHeader = res.headers?.['content-disposition'] ?? '';
    const filenameMatch = contentDispHeader.match(/filename=([A-Za-z0-9_.]{5,})/);
    if (filenameMatch?.[1] && filenameMatch[1]?.length > 6) filename = filenameMatch[1];
    downloadFile(res.data, filename);
  });
};

export const tablesActions = {
  /** TABLES */
  loadTables,
  createTable,
  updateTable,
  deleteTable,

  // Folders
  loadFolders,
  createFolder,
  deleteFolder,
  updateFolder,
  copyFolder,

  /** RESULTS */
  loadTableResults,
  loadTableResultsLayered,

  exportFile
};
