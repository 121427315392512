import React, { useMemo } from 'react';

import { CalculationMenu } from './menus';
import { ResultRow, ResultRowHeader } from '.';

export const CalculationResultRow = ({ table, tableResult, row, isCompactMatrix, matrixType, handler, hover }) => {
  const { rows, config } = table;
  const { showHiddenRows = true } = config;
  let heads = table.heads;

  if (isCompactMatrix) {
    const defaultRows = { list: {}, order: [] };

    (rows?.order ?? []).forEach(rowId => {
      if (['question', 'sysvar', 'calcvar'].includes(rows.list[rowId].sourceType)) {
        if (matrixType !== 'compact-merged' || defaultRows.order.length === 0) {
          defaultRows.order.push(rowId);
          defaultRows.list[rowId] = rows.list[rowId];
        }
      }
    });

    if (heads.order.length > 0) {
      heads.order.forEach(headId => {
        const head = heads.list[headId];
        if (head.sourceType === 'calculation') {
          defaultRows.order.push(headId);
          defaultRows.list[headId] = head;
        }
      });
    }

    heads = defaultRows;
  }

  // const { showHiddenRows = true } = config;
  // const { id: rowid, config: rowConfig } = row;
  const colSpan = matrixType === 'detail' ? 2 : 1;

  /** @todo: pass in order item and use hidden property from order item */
  const isHidden = useMemo(() => row.config?.hidden?.[row.id] === true, [row]);

  const calculationTitle = row?.config?.title ?? 'Calculation';

  const calculationMenu = useMemo(
    () => (
      <CalculationMenu
        handler={handler}
        tableElement={row}
        tableConfig={table}
        calculationMode={'rows'}
        isHidden={isHidden}
      />
    ),
    [handler, row, table, isHidden]
  );

  const calculationOrderIndex = useMemo(() => {
    if (config.calculationOrder.length <= 1) return null;

    const index = config.calculationOrder.findIndex(orderItem => orderItem.elementId === row.id) ?? 0;
    return index + 1;
  }, [config?.calculationOrder, row.id]);

  if (!showHiddenRows && isHidden) return null;

  return (
    <ResultRow
      rowid={row.id}
      row={row}
      orderItem={{ label: calculationTitle }}
      subelement={{}}
      choiceid={row.id}
      scaleid={null}
      heads={heads}
      config={config}
      tableResult={tableResult}
      isHidden={isHidden}
      isCompactMatrix={isCompactMatrix}
      matrixType={matrixType}
      headMappingTable={{}}
      metric={'smvrslt_calculation'}
      hover={hover}
    >
      <ResultRowHeader
        rowid={row.id}
        colSpan={colSpan}
        label={`Calculation${calculationOrderIndex ? ` (${calculationOrderIndex})` : ''}`}
        menu={calculationMenu}
      />
    </ResultRow>
  );
};
