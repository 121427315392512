import { projectSettingsActionTypes } from '../constants/projectSettingsConstants';
import { store } from 'smv-redux';

const setQLocale = (surveyid, locale) => {
  store.dispatch({
    type: projectSettingsActionTypes.SET_Q_LOCALE,
    payload: { projectId: surveyid, locale }
  });
};

const toggleIsShortlabelsActive = (surveyid, isShortlabelsActive) => {
  store.dispatch({
    type: projectSettingsActionTypes.TOGGLE_IS_SHORTLABELS_ACTIVE,
    payload: { projectId: surveyid, isShortlabelsActive }
  });
};

const setCollapse = (surveyid, elementid, value) => {
  store.dispatch({
    type: projectSettingsActionTypes.SET_QUESTIONNAIRE_ELEMENT_COLLAPSE,
    payload: { surveyid, elementid, value }
  });
};

const setSelectedReport = (projectId, surveyId, reportId) => {
  store.dispatch({
    type: projectSettingsActionTypes.SET_SELECTED_REPORT,
    payload: { projectId, surveyId, reportId }
  });
};

const setSelectedPage = (projectId, surveyId, reportId, pageId) => {
  store.dispatch({
    type: projectSettingsActionTypes.SET_SELECTED_PAGE,
    payload: { projectId, surveyId, reportId, pageId }
  });
};

export const projectSettingsActions = {
  setQLocale,
  setCollapse,
  toggleIsShortlabelsActive,
  setSelectedReport,
  setSelectedPage
};
