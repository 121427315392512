import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { customAlphabet } from 'nanoid';
import produce from 'immer';

import { ResultTableDefaultHead, ResultTableDefaultBody, ResultTableDefaultFooter } from '.';
import { FormGroup, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';

const whitelistVarTypes = ['question', 'sysvar', 'calcvar'];
const whitelistHeadQuestionTypes = ['single_vector', 'multiple_vector'];
const whitelistRowQuestionTypes = [
  'single_vector',
  'multiple_vector',
  'single_matrix',
  'multiple_matrix',
  'custom_shelf'
];

export const ResultTableDefault = ({ handler, table, tableResult, isCompactMatrix, matrixType, isLoading }) => {
  const [hoveredCell, setHoveredCell] = useState(null);

  const isLayered = !!table.chart?.chartLayer;
  const [selectedChartLayerView, setSelectedChartLayerView] = useState('default');

  const onDropHead = item => {
    handler.setTableConfig(state =>
      produce(state, draftState => {
        const headid = customAlphabet('1234567890abcdef', 24)();

        if (!draftState.heads.list) {
          draftState.heads.list = {};
        }

        draftState.heads.order.push(headid);
        draftState.heads.list[headid] = {
          id: headid,
          sourceType: item?.type ?? 'question',
          sourceId: item.id,
          config: {
            sortation: {
              sortationMode: null,
              manualSortation: {
                order: []
              },
              headSortationMode: null,
              manualHeadSortation: {
                order: []
              }
            }
          }
        };
      })
    );
  };

  const onDropRow = item => {
    handler.setTableConfig(state =>
      produce(state, draftState => {
        const rowid = customAlphabet('1234567890abcdef', 24)();
        draftState.rows.order.push(rowid);
        draftState.rows.list[rowid] = {
          id: rowid,
          sourceType: item?.type ?? 'question',
          sourceId: item.id,
          config: {}
        };
      })
    );
  };

  const [{ isOverHead, canDropHead }, dropHead] = useDrop({
    accept: whitelistVarTypes,
    drop: onDropHead,
    canDrop: item => {
      if (
        whitelistVarTypes.includes(item.type) &&
        (item.type !== 'question' || whitelistHeadQuestionTypes.includes(item?.config?.qtype))
      )
        return true;
      else return false;
    },
    collect: monitor => ({
      isOverHead: monitor.isOver(),
      canDropHead: monitor.canDrop()
    })
  });

  const [{ isOverRow, canDropRow }, dropRow] = useDrop({
    accept: whitelistVarTypes,
    drop: onDropRow,
    canDrop: item => {
      if (
        whitelistVarTypes.includes(item.type) &&
        (item.type !== 'question' || whitelistRowQuestionTypes.includes(item?.config?.qtype))
      )
        return true;
      else return false;
    },
    collect: monitor => ({
      isOverRow: monitor.isOver(),
      canDropRow: monitor.canDrop()
    })
  });

  return (
    <>
      <div className={`smv-result-table`}>
        {isLoading && (
          <div className='loading-overlay'>
            <span className={'fas fa-spinner fa-spin fa-3x'}></span>
          </div>
        )}

        {isLayered && (
          <ResultTableChartLayerViewSelect
            table={table}
            selectedChartLayerView={selectedChartLayerView}
            setSelectedChartLayerView={setSelectedChartLayerView}
          />
        )}

        <table className={`table table-striped table-hover w-100 ${isLoading ? 'table--loading' : ''}`}>
          <ResultTableDefaultHead
            headRef={dropHead}
            handler={handler}
            isDraggingOver={isOverHead}
            canDropHead={canDropHead}
            table={table}
            tableResult={isLayered ? tableResult?.[selectedChartLayerView] : tableResult}
            isCompactMatrix={isCompactMatrix}
            matrixType={matrixType}
          />
          <ResultTableDefaultBody
            rowsRef={dropRow}
            handler={handler}
            isDraggingOver={isOverRow}
            canDropRow={canDropRow}
            table={table}
            tableResult={isLayered ? tableResult?.[selectedChartLayerView] : tableResult}
            isCompactMatrix={isCompactMatrix}
            matrixType={matrixType}
            hover={{ hoveredCell, setHoveredCell }}
          />
        </table>
      </div>

      <ResultTableDefaultFooter table={table} isCompactMatrix={isCompactMatrix} />
    </>
  );
};

const ResultTableChartLayerViewSelect = ({ table, selectedChartLayerView, setSelectedChartLayerView }) => {
  const selectedChartLayerId = table?.chart?.chartLayer;

  const chartLayers = useSelector(s => s.survey?.chartLayers ?? {});
  if (!chartLayers || chartLayers?.loading) {
    return null;
  }

  const chartLayer = chartLayers?.list?.[selectedChartLayerId];
  if (!chartLayer) {
    return null;
  }

  return (
    <FormGroup>
      <Label for='exampleSelect'>Chart Layer</Label>
      <Input
        id='exampleSelect'
        name='select'
        type='select'
        value={selectedChartLayerView ?? 'default'}
        onChange={e => setSelectedChartLayerView(e.target.value)}
      >
        <option value={'default'}>default</option>
        {chartLayer.views.order.map(viewId => {
          const view = chartLayer.views.list[viewId];

          return (
            <option key={viewId} value={viewId}>
              {view?.label}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};
