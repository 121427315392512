import React from 'react';
import classNames from 'classnames';

import { getCellValue, useIsHovered, useSignificanceFlags, useSignificanceFlagsHover } from 'smv-helpers';
import { significanceGates } from 'src/smoove/components/project-results/config';

export const ResultTableChartCell = ({
  cell,
  value = null,
  metric,
  config,
  cellIdentifier,
  hover = null,
  significancesShow = false,
  conditionalFormat = null
}) => {
  const significanceDimension = config?.valueSignificancesDimension ?? 'heads';
  const significanceLevel = parseFloat(config.valueSignificancesLevel);
  const significanceGate = significanceGates[1]?.[significanceLevel];
  const significanceBenchmark = config?.valueSignificancesBenchmark ?? null;

  const { isHovered, isHoveredCell } = useIsHovered(hover?.hoveredCell, cellIdentifier, significanceDimension);

  const { isSignificantHigh, isSignificantLow } = useSignificanceFlags(
    cell,
    significanceDimension,
    significanceGate,
    significanceBenchmark
  );
  const { isSignificantHighHover, isSignificantLowHover } = useSignificanceFlagsHover(
    hover?.hoveredCell,
    cellIdentifier,
    cell,
    significanceDimension,
    significanceGate,
    significanceBenchmark
  );

  return (
    <td
      className={classNames({
        'result__cell': true,
        'result__cell--hovered': isHovered,
        'result__cell--hovered-cell': isHoveredCell,
        ...(significancesShow && {
          'sig__high': isSignificantHighHover || isSignificantHigh,
          'sig__low': isSignificantLowHover || isSignificantLow,
          'sig__mutually': isSignificantHigh && isSignificantLow && !isSignificantHighHover && !isSignificantLowHover,
          'sig__high--to-hovered': isSignificantHighHover,
          'sig__low--to-hovered': isSignificantLowHover,
          'sig__not-hovered':
            !significanceBenchmark &&
            !!hover?.hoveredCell &&
            (!isHovered || (isHovered && !isHoveredCell && !isSignificantHighHover && !isSignificantLowHover))
        })
      })}
      style={{
        ...{
          backgroundColor: conditionalFormat?.formats?.backgroundColor ?? null
        }
      }}
      onMouseOver={hover?.setHoveredCell ? () => hover.setHoveredCell(cellIdentifier) : null}
      onMouseOut={hover?.setHoveredCell ? () => hover.setHoveredCell(null) : null}
    >
      {value ?? getCellValue(cell, metric, config)}
    </td>
  );
};
