import { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import { getMediaElementType } from './helpers/getMediaElementType';
import { sortMedia } from './helpers/mediaSorting';
import { MediaLibraryElement } from './MediaLibraryElement';

import PlaceholderImage from 'smv-assets/img/project_placeholder.png';

export const MediaLibraryItemsList = ({
  media,
  selectedFiles,
  isSelectionDisabled,
  handleClickFile,
  sorting,
  fileTypesToShow,
  searchInputValue,
  allowedFileTypes
}) => {
  const [numberOfElementsToShow, setNumberOfElementsToShow] = useState(18);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);

  const isLoadingNewMediaElement = useSelector(state => state.survey.isLoadingMedia ?? false);

  const handleShowMore = useCallback(() => {
    setNumberOfElementsToShow(b => b + 12);
  }, []);

  const handleScroll = useCallback(e => {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
    if (bottom) {
      setNumberOfElementsToShow(b => b + 12);
    }
  }, []);

  const handleLightboxClick = useCallback(image => {
    if (!image) {
      setLightboxImage(null);
      setIsLightboxOpen(false);
    } else {
      setLightboxImage(image);
      setIsLightboxOpen(true);
    }
  }, []);

  // sort
  let mediaToShow = sortMedia(media, sorting);
  // filter by file type
  if (fileTypesToShow !== null) {
    mediaToShow = mediaToShow.filter(el => getMediaElementType(el) === fileTypesToShow);
  }
  if (allowedFileTypes.length < 3) {
    mediaToShow = mediaToShow.filter(el => allowedFileTypes.includes(getMediaElementType(el)));
  }
  // filter by search input
  mediaToShow = mediaToShow.filter(el => el.originalName.toLowerCase().includes(searchInputValue.toLowerCase()));

  if ((!mediaToShow || mediaToShow.length <= 0) && isLoadingNewMediaElement)
    return (
      <div className='medialist'>
        <span className={'fas fa-spinner fa-spin mr-2'}></span>
      </div>
    );

  if (!mediaToShow || mediaToShow.length <= 0) return <div className='medialist'>No media found.</div>;

  return (
    <>
      <div className='medialist' onScroll={handleScroll}>
        {mediaToShow.slice(0, numberOfElementsToShow).map(el => {
          return (
            <MediaLibraryElement
              key={el.id}
              element={el}
              selectedFiles={selectedFiles}
              isSelectionDisabled={isSelectionDisabled}
              handleClickFile={handleClickFile}
              handleLightboxClick={handleLightboxClick}
            />
          );
        })}
        {isLoadingNewMediaElement && (
          <div className={`medialist-item mr-2 `}>
            <div className='medialist-item__loading'>
              <span className={'fas fa-spinner fa-spin'}></span>
            </div>
            <img className='medialist-item__image' src={PlaceholderImage} title={'loading...'} alt={'loading...'} />
            <div className='medialist-item__label'>loading...</div>
          </div>
        )}
        {numberOfElementsToShow < mediaToShow.length && <Button onClick={handleShowMore}>load more</Button>}
      </div>
      {isLightboxOpen && lightboxImage && (
        <Lightbox
          mainSrc={lightboxImage?.local?.url}
          onCloseRequest={() => handleLightboxClick(null)}
          style={{ zIndex: '2000' }}
          imageCaption={<div style={{ width: '100%', textAlign: 'center' }}>{lightboxImage?.originalName}</div>}
        />
      )}
    </>
  );
};
