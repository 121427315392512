import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useMatrixType } from 'smv-helpers';
import { getMergedChoices } from '../../helper';

import { FormulaBuilderTableElementQuestionDefault, FormulaBuilderTableElementQuestionDetailedMatrix } from '.';

export const FormulaBuilderTableElementQuestionSwitch = ({
  main,
  calculationMode,
  tableConfig,
  handleAddReference
}) => {
  const queElementList = useSelector(s => s.survey.questionnaire.list);
  const questionVariable = useMemo(() => queElementList?.[main.sourceId], [queElementList, main.sourceId]);
  const questionValues = getMergedChoices(questionVariable, questionVariable.config.choices, queElementList);
  const questionScales = useMemo(() => questionVariable.config.scales, [questionVariable]);

  const { isDetailedMatrix, isCompactMatrix } = useMatrixType(tableConfig);
  const isMatrix = useMemo(() => ['single_matrix', 'multiple_matrix'].includes(questionVariable.config.qtype), [
    questionVariable
  ]);

  if (isMatrix && isDetailedMatrix) {
    return (
      <FormulaBuilderTableElementQuestionDetailedMatrix
        calculationMode={calculationMode}
        main={main}
        questionVariable={questionVariable}
        questionValues={questionValues}
        questionScales={questionScales}
        handleAddReference={handleAddReference}
      />
    );
  } else if (isMatrix && isCompactMatrix && calculationMode === 'rows') {
    return (
      <FormulaBuilderTableElementQuestionDefault
        calculationMode={calculationMode}
        main={main}
        questionVariable={questionVariable}
        questionValues={questionScales}
        handleAddReference={handleAddReference}
      />
    );
  } else if (isMatrix && isCompactMatrix && calculationMode === 'heads') {
    return (
      <FormulaBuilderTableElementQuestionDefault
        calculationMode={calculationMode}
        main={main}
        questionVariable={questionVariable}
        questionValues={questionValues}
        handleAddReference={handleAddReference}
      />
    );
  } else {
    return (
      <FormulaBuilderTableElementQuestionDefault
        calculationMode={calculationMode}
        main={main}
        questionVariable={questionVariable}
        questionValues={questionValues}
        handleAddReference={handleAddReference}
      />
    );
  }
};
