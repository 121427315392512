import React from 'react';
import { useSelector } from 'react-redux';

import { ReferenceValues } from '.';

export const ReferenceTypeCalculationVariable = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleReferenceSelect
}) => {
  const calculationVariables = useSelector(state => state.survey?.data?.calculations ?? {});
  const { sourceId } = main;

  const calculationVariable = calculationVariables.find(v => v.id === sourceId);
  if (!calculationVariable) return null;

  const label = calculationVariable.label;
  const values = {
    list: Object.fromEntries(calculationVariable.values.map(value => [value.id, value])),
    order: calculationVariable.values.map(value => value.id)
  };

  return (
    <ReferenceValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      matrixType={matrixType}
      values={values}
      handleReferenceSelect={handleReferenceSelect}
    />
  );
};
