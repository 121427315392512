import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Router } from 'react-router-dom';

import { Login, Logout } from './smoove/pages';
import { DynamicImport, Header, Footer, Loading } from 'smv-components';
import { UserInvitation } from './smoove/pages/user/UserInvitation';
import { PasswordResetPage } from './smoove/pages/user/PasswordResetPage';

import { history, userActions } from 'smv-redux';

export const ApplicationRouter = ({ modules = [] }) => {
  useEffect(() => {
    userActions.getUserDetails();
  }, []);

  const moduleRoutes = useMemo(() => {
    if (modules.length > 0)
      return modules.map(module => {
        return (
          <Route key={module.path} path={module.path}>
            <DynamicImport load={module.content}>
              {Component =>
                Component === null ? (
                  <Loading />
                ) : (
                  <Component {...(module?.wizards ? { wizards: module.wizards } : {})} />
                )
              }
            </DynamicImport>
          </Route>
        );
      });
    else return null;
  }, [modules]);

  return (
    <Router history={history}>
      <Route component={Header} />
      <Switch>
        <Route path={'/login'} component={Login} exact />
        <Route path={'/logout'} component={Logout} exact />
        <Route path={'/invitationRequest/:invitationToken'} component={UserInvitation} exact />
        <Route path={'/resetPassword'} component={PasswordResetPage} exact />
        <Route path={'/resetPassword/:resetToken'} component={PasswordResetPage} exact />
        <Route path='/one'>{moduleRoutes}</Route>
        <Route path={'/'} component={Login} />
      </Switch>
      <Footer />
    </Router>
  );
};
