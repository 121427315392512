/**
 *
 * @param {*} element
 * @param {*} currentChoices eg element.config.choices
 * @param {*} list questionnaire element list, eg redux questionnaire.list
 * @returns
 */
export const getMergedChoices = (element, currentChoices = {}, list = {}) => {
  let _choices = {
    list: { ...(currentChoices.list ?? {}) },
    order: [...(currentChoices.order ?? [])]
  };

  if (!element)
    return {
      list: {},
      order: []
    };

  if (element?.config?.choices?.import?.source) {
    const { source } = element.config.choices.import;
    const sourceElement = list?.[source] ?? null;

    if (sourceElement) {
      let _importedChoiceOrder = [];
      sourceElement.config?.choices?.order?.forEach(choiceid => {
        _choices.list[choiceid] = { ...sourceElement.config.choices.list[choiceid], imported: true };
        _importedChoiceOrder.push(choiceid);
      });
      _choices.order = [..._importedChoiceOrder, ..._choices.order];

      if (sourceElement.config?.choices?.import?.source) {
        _choices = getMergedChoices(sourceElement, _choices, list);
      }
    }
  }

  return _choices;
};
