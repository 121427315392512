import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import './SmvCustomDropdown.scss';

/**
 * A custom dropdown menu used all over the application.
 *
 * @param {ReactChild} children //pass (multiple) reactstrap "DropdownItem"
 * @param {string} faIconString //fontawesome icon string to change the icon. Default: "far fa-ellipsis-v"
 * @param {boolean} persist //default false; Set to true if there is a problem with passing state to the children. Set to false if the dropdown is somehow hidden behind a scrolling container. See reactstrap documentation for more info
 * @param {boolean} className className for css classes
 *
 * @returns // returns a reactstrap "Dropdown"
 */
export const SmvCustomDropdown = ({
  children,
  faIconString = 'far fa-ellipsis-v',
  persist = false,
  className = '',
  container = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  const _container = container ? container : 'body';

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className={`smv-custom-dropdown ${className}`}>
      <DropdownToggle tag='span' className='smv-custom-dropdown__dropdown-toggle icon-smv-blue'>
        <i className={`${faIconString}`}></i>
      </DropdownToggle>
      <DropdownMenu className='smv-custom-dropdown__dropdown-menu' persist={persist} container={_container}>
        {children instanceof Function ? children(isOpen, toggle) : children}
      </DropdownMenu>
    </Dropdown>
  );
};
