import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import produce from 'immer';

import {
  getRowOrder,
  useTranslationHooks,
  getSubelementPropertyTranslation,
  useMatrixType,
  getResultValue
} from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { significanceGates } from '../../../project-results/config';
import { getSignificance } from '.';
import { getHighestHeadBase } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const useChartData = ({ chartConfig, table, tableResult, transpose = false }) => {
  const chartLayers = useSelector(s => s.survey?.chartLayers);
  const isLayered = !!chartConfig?.chartLayer;
  const chartLayer = isLayered ? chartLayers.list[chartConfig.chartLayer] : null;

  const elementsQuestion = useSelector(state => state.survey.questionnaire);
  const elementsSysvar = useSelector(state => state.survey?.systemVariables);
  const elementsCalcvar = useSelector(state => state.survey?.data?.calculations);

  const elements = useMemo(() => {
    return {
      question: elementsQuestion?.list ?? {},
      sysvar: elementsSysvar?.list ?? {},
      calcvar: elementsCalcvar
    };
  }, [elementsCalcvar, elementsQuestion?.list, elementsSysvar?.list]);

  const [chartData, setChartData] = useState(null);
  const [chartDimensions, setChartDimensions] = useState(null);

  const { isCompactMatrix, isDetailedMatrix } = useMatrixType(table);

  const showTotal = useMemo(() => !isCompactMatrix && (table.config.showTotal ?? true), [
    isCompactMatrix,
    table.config.showTotal
  ]);

  const significancesShow = chartConfig?.significancesShow ?? false;

  /**
   * Gets the categories/ dimensions.
   *
   * For a regular chart/ table, the categories are build by the table rows,
   * the dimensions by the table head + total.
   *
   * For a compactMatrix, the categories will include the scale points,
   * the dimensions the choices.
   *
   * If the table is transposed, the contents will be reversed.
   */
  const headMappingTable = useHeadMappingTable({ elements, table, isCompactMatrix });
  const categories = useCategories({
    table,
    tableResult: tableResult?.default ?? tableResult,
    showTotal,
    transpose,
    isDetailedMatrix,
    isCompactMatrix,
    elements,
    headMappingTable
  });
  const dimensions = useDimensions({
    table,
    tableResult: tableResult?.default ?? tableResult,
    showTotal,
    transpose,
    isDetailedMatrix,
    isCompactMatrix,
    elements,
    headMappingTable
  });

  /**
   * GET CHART DATA
   */
  useEffect(() => {
    if (!tableResult) return;
    let data = [];

    dimensions.forEach(dimension => {
      dimension.order.forEach(dimValue => {
        if (!chartLayer) {
          getColumnCategories({
            data,
            dimValue: dimValue,
            categories: categories,
            dimension,
            tableResult,
            transpose,
            isCompactMatrix,
            headMappingTable,
            significancesShow,
            tableConfig: table.config
          });
        } else {
          chartLayer.views.order.forEach(viewId => {
            getColumnCategories({
              data,
              dimValue: dimValue,
              categories: categories,
              dimension,
              tableResult: tableResult[viewId],
              transpose,
              isCompactMatrix,
              headMappingTable,
              significancesShow,
              tableConfig: table.config,
              chartLayerViewId: viewId
            });
          });
        }
      });
    });

    setChartData(Object.values(data));
  }, [
    tableResult,
    transpose,
    isCompactMatrix,
    categories,
    dimensions,
    headMappingTable,
    significancesShow,
    table.config,
    chartLayer
  ]);

  /**
   * Gets the relevent (non excluded) dimensions out of the result data
   *
   * For a regular table, this would be the rows of the table, resulting in e.g. the legend entries of a bar chart
   * Sets the calculated rows in `chartDimensions` local state in a structure like:
   * ```
   * {
   *   id: 'id-string',
   *   label: 'translated (for questions) or non translated (for sys- and calcvars) label',
   *   value: 1, //int value
   * }
   * ```
   */
  useEffect(() => {
    let rows = [];
    categories.forEach((category, categoryIndex) => {
      category.order.forEach(catValue => {
        if (category.sourceType !== 'calculation' && transpose && isCompactMatrix && categoryIndex > 0) return;
        const { rowid, rowsubelementid, rowsubsubelementid, headid, headsubelementid } = catValue;

        let categoryKey;
        if (!!headsubelementid) categoryKey = `${headid}~${headsubelementid}`;
        else if (!!rowsubsubelementid) categoryKey = `${rowid}~${rowsubelementid}~${rowsubsubelementid}`;
        else categoryKey = `${rowid}~${rowsubelementid}`;

        rows.push({
          id: categoryKey,
          label: catValue.label,
          value: catValue?.value ?? 1,
          cellIdentifier: {
            rowId: catValue?.rowid ?? null,
            rowSubId: catValue?.rowsubelementid ?? null,
            rowSubSubId: catValue?.rowsubsubelementid ?? null,
            headId: catValue?.headid ?? null,
            headSubId: catValue?.headsubelementid ?? null
          }
        });
      });
    });

    setChartDimensions(rows);
  }, [isCompactMatrix, transpose, categories]);

  return [chartData, chartDimensions];
};

const useHeadMappingTable = ({ elements, table, isCompactMatrix }) => {
  return useMemo(() => {
    let _headMappingTable = {};

    const defaultRowsOrder = table.rows.order.filter(rowid => table.rows.list[rowid].sourceType !== 'calculation');
    if (isCompactMatrix && defaultRowsOrder.length > 1) {
      const _valueIdMap = {};
      defaultRowsOrder.forEach((rowid, rowidx) => {
        const { sourceId, config } = table.rows.list[rowid];
        const sourceElement = elements.question?.[sourceId];
        const _choices = getMergedChoices(sourceElement, sourceElement.config?.choices, elements.question);

        _choices.order.forEach(choiceid => {
          const { value } = _choices.list[choiceid];

          if (rowidx === 0) {
            _headMappingTable[choiceid] = {
              rowid: rowid,
              id: choiceid,
              ids: [choiceid],
              value: value,
              hidden: config?.hidden?.[choiceid] === true
            };
            _valueIdMap[value] = choiceid;
          } else {
            const mainid = _valueIdMap[value];
            _headMappingTable[mainid].ids.push(choiceid);
          }
        });
      });
    }
    return _headMappingTable;
  }, [elements.question, table.rows.list, table.rows.order, isCompactMatrix]);
};

const useCategories = ({
  table,
  tableResult,
  showTotal,
  transpose,
  isDetailedMatrix,
  isCompactMatrix,
  elements,
  headMappingTable
}) => {
  const translationProps = useTranslationHooks();

  return useMemo(() => {
    const { showCodeValuesHead = false, showCodeValuesRow = false } = table.config;
    let _categories = { order: [] };

    // default: not transposed or compact matrix, transposed or not
    if (isCompactMatrix && transpose) {
      _categories = produce(table.rows, draft => {
        table.rows.order.forEach(rowId => {
          if (!['question', 'sysvar', 'calcvar'].includes(table.rows.list[rowId].sourceType)) {
            delete draft.list[rowId];
            draft.order.splice(
              draft.order.findIndex(_rowId => _rowId === rowId),
              1
            );
          }
        });

        if (table.heads.order.length > 0) {
          table.heads.order.forEach(headId => {
            const head = table.heads.list[headId];
            if (head.sourceType === 'calculation') {
              draft.order.push(headId);
              draft.list[headId] = head;
            }
          });
        }
      });
    } else if (transpose) {
      _categories = table.heads;
    } else {
      _categories = table.rows;
    }

    const categories = [];
    _categories.order.forEach(categoryId => {
      const { sourceType, sourceId, config } = _categories.list[categoryId];
      const { hidden = {}, excluded = {}, calcFrequencyGroups = {} } = config ?? {};
      // if (sourceType === 'calculation' && isCompactMatrix && transpose) return;

      let sourceElement;
      let sourceElementValues;
      if (sourceType === 'question') {
        sourceElement = elements?.[sourceType]?.[sourceId];
        if (!sourceElement) throw new Error(`Could not find source element with sourceId '${sourceId}'!`);
        if (!isCompactMatrix || (isCompactMatrix && transpose)) {
          sourceElementValues = getMergedChoices(sourceElement, sourceElement.config?.choices, elements.question);
        } else {
          sourceElementValues = sourceElement.config?.scales;
        }
      } else if (sourceType === 'sysvar') {
        sourceElement = elements?.[sourceType]?.[sourceId];
        if (!sourceElement) throw new Error(`Could not find source element with sourceId '${sourceId}'!`);
        sourceElementValues = { order: sourceElement?.order ?? [], list: sourceElement?.list ?? {} };
      } else if (sourceType === 'calcvar') {
        sourceElement = elements?.[sourceType]?.find(element => element.id === sourceId);
        if (!sourceElement) throw new Error(`Could not find source element with sourceId '${sourceId}'!`);
        sourceElementValues = {
          list: Object.fromEntries(sourceElement?.values?.map(value => [value.id, value]) ?? []),
          order: sourceElement?.values?.map(value => value.id)
        };
      } else if (sourceType === 'calculation') {
        sourceElementValues = {
          order: [categoryId],
          list: {
            [categoryId]: {
              id: categoryId,
              label: config?.title ?? 'Calculation',
              metric: 'smvrslt_calculation'
            }
          }
        };
      }

      const categoryOrder = [];

      // for a default table, the row order must be taken
      if (!transpose) {
        const { rowOrder } = getRowOrder(tableResult?.order, _categories.list[categoryId]);

        rowOrder.forEach(orderItem => {
          const isHidden = (hidden?.[orderItem.subelement] ?? false) || (orderItem?.hidden ?? false);
          const isExcluded = orderItem?.excluded ?? false;

          if (isHidden || isExcluded) return;

          let subelement = {};
          if (calcFrequencyGroups?.[orderItem?.subelement]) {
            subelement = calcFrequencyGroups[orderItem.subelement];
          } else if (sourceElementValues.list?.[orderItem?.subelement]) {
            subelement = sourceElementValues.list[orderItem.subelement];
          }

          let label = getSubelementPropertyTranslation(
            subelement,
            orderItem,
            {
              showCodeValue: showCodeValuesRow,
              useShortcodes: true,
              useStripped: true,
              useFallback: true
            },
            translationProps
          );

          if (isDetailedMatrix && sourceType === 'question' && !!sourceElement?.config?.scales) {
            let subsubelement = {};
            if (calcFrequencyGroups?.[orderItem?.subsubelement]) {
              subsubelement = calcFrequencyGroups[orderItem.subsubelement];
            } else if (sourceElement.config.scales?.list?.[orderItem?.subsubelement]) {
              subsubelement = sourceElement.config.scales?.list[orderItem.subsubelement];
            }

            const scaleLabel = getSubelementPropertyTranslation(
              subsubelement,
              orderItem,
              {
                showCodeValue: showCodeValuesRow,
                useShortcodes: true,
                useStripped: true,
                useFallback: true
              },
              translationProps
            );
            label += ` - ${scaleLabel}`;
          }

          categoryOrder.push({
            label: label,
            rowid: categoryId,
            rowsubelementid: orderItem.subelement,
            rowsubsubelementid: orderItem?.subsubelement ?? null,
            value: orderItem.value,
            metric: sourceElementValues?.list?.[orderItem.subelement]?.metric,
            sourceType: sourceType
          });
        });
      }

      // else use headOrder provided by backend
      else {
        tableResult?.headOrder?.[categoryId]?.forEach(orderItem => {
          const value = sourceElementValues?.list?.[orderItem.headSubId];
          const headMapping =
            Object.values(headMappingTable).find(mapping => mapping.ids.includes(orderItem.headSubId)) ?? {};

          /** @todo: refactor hidden heads to use headOrder */
          let isHidden = hidden?.[orderItem.headSubId] === true || (isCompactMatrix && headMapping?.hidden === true);
          if (!isHidden && (config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config?.autoHideLowBasesHurdle ?? 0;

            const valuesWithoutHidden = {};
            for (const rowId of Object.keys(tableResult.values)) {
              for (const subelement of Object.keys(tableResult.values[rowId])) {
                const hidden =
                  tableResult.order.find(item => item.rowid === rowId && item.subelement === subelement)?.hidden ??
                  false;
                if (!hidden) {
                  if (!valuesWithoutHidden[rowId]) {
                    valuesWithoutHidden[rowId] = {};
                  }
                  valuesWithoutHidden[rowId][subelement] = tableResult.values[rowId][subelement];
                }
              }
            }

            const highestBase = getHighestHeadBase(tableResult, categoryId, orderItem.headSubId, {
              isDetailedMatrix: isDetailedMatrix,
              excludeHidden: true
            });
            if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
          }
          const isExcluded = excluded?.[orderItem.headSubId] === true;

          if (isHidden || isExcluded) return;

          categoryOrder.push({
            label: getSubelementPropertyTranslation(
              value,
              { subelement: orderItem.headSubId, value: value?.value },
              {
                showCodeValue: showCodeValuesHead,
                useShortcodes: true,
                useStripped: true,
                useFallback: true
              },
              translationProps
            ),
            headid: categoryId,
            headsubelementid: orderItem.headSubId,
            value: value?.value
          });
        });
      }

      categories.push({ sourceType, categoryid: categoryId, order: categoryOrder });
    });

    if (!isCompactMatrix && showTotal && transpose) {
      categories.unshift({
        categoryid: '__total__',
        order: [
          {
            label: 'Total',
            headid: '__total__',
            headsubelementid: '__total__'
          }
        ]
      });
    }

    return categories;
  }, [
    table.config,
    table.heads,
    table.rows,
    tableResult,
    showTotal,
    transpose,
    isDetailedMatrix,
    isCompactMatrix,
    elements,
    headMappingTable,
    translationProps
  ]);
};

const useDimensions = ({
  table,
  tableResult,
  showTotal,
  transpose,
  isCompactMatrix,
  isDetailedMatrix,
  elements,
  headMappingTable
}) => {
  const translationProps = useTranslationHooks();

  return useMemo(() => {
    const { showCodeValuesHead = false, showCodeValuesRow = false } = table.config;
    let _dimensions = { order: [] };

    // default: not transposed, no compact matrix
    if (isCompactMatrix && !transpose) {
      _dimensions = produce(table.rows, draft => {
        table.rows.order.forEach(rowId => {
          if (!['question', 'sysvar', 'calcvar'].includes(table.rows.list[rowId].sourceType)) {
            delete draft.list[rowId];
            draft.order.splice(
              draft.order.findIndex(_rowId => _rowId === rowId),
              1
            );
          }
        });

        if (table.heads.order.length > 0) {
          table.heads.order.forEach(headId => {
            const head = table.heads.list[headId];
            if (head.sourceType === 'calculation') {
              draft.order.push(headId);
              draft.list[headId] = head;
            }
          });
        }
      });
    } else if (transpose) {
      _dimensions = table.rows;
    } else {
      _dimensions = table.heads;
    }

    const dimensions = [];
    _dimensions.order.forEach(dimensionid => {
      const { sourceType, sourceId, config = {} } = _dimensions.list[dimensionid];
      const { hidden = {}, excluded = {}, calcFrequencyGroups = {} } = config;
      // if (sourceType === 'calculation' && isCompactMatrix && !transpose) return;

      let sourceElement;
      let sourceElementValues;
      if (sourceType === 'question') {
        sourceElement = elements?.[sourceType]?.[sourceId];
        if (!isCompactMatrix || (isCompactMatrix && !transpose)) {
          sourceElementValues = getMergedChoices(sourceElement, sourceElement.config?.choices, elements.question);
        } else {
          sourceElementValues = sourceElement.config?.scales;
        }
      } else if (sourceType === 'sysvar') {
        sourceElement = elements?.[sourceType]?.[sourceId];
        sourceElementValues = { order: sourceElement?.order ?? [], list: sourceElement?.list ?? {} };
      } else if (sourceType === 'calcvar') {
        sourceElement = elements?.[sourceType].find(element => element.id === sourceId);
        sourceElementValues = {
          list: Object.fromEntries(sourceElement.values.map(value => [value.id, value])),
          order: sourceElement.values.map(value => value.id)
        };
      } else if (sourceType === 'calculation') {
        sourceElementValues = {
          order: [dimensionid],
          list: {
            [dimensionid]: {
              id: dimensionid,
              label: config?.title ?? 'Calculation',
              metric: 'smvrslt_calculation'
            }
          }
        };
      }

      const dimensionOrder = [];

      // for a transposed table, the row order must be taken
      if (transpose) {
        const { rowOrder } = getRowOrder(tableResult?.order, _dimensions.list[dimensionid]);

        rowOrder.forEach(orderItem => {
          const isHidden = orderItem?.hidden ?? false;
          const isExcluded = orderItem?.excluded ?? false;

          if (isHidden || isExcluded) return;

          let subelement = {};
          if (calcFrequencyGroups?.[orderItem?.subelement]) {
            subelement = calcFrequencyGroups[orderItem.subelement];
          } else if (sourceElementValues.list?.[orderItem?.subelement]) {
            subelement = sourceElementValues.list[orderItem.subelement];
          }

          let label = getSubelementPropertyTranslation(
            subelement,
            orderItem,
            {
              showCodeValue: showCodeValuesRow,
              useShortcodes: true,
              useStripped: true,
              useFallback: true
            },
            translationProps
          );

          if (isDetailedMatrix && sourceType === 'question' && !!sourceElement?.config?.scales) {
            let subsubelement;
            if (calcFrequencyGroups?.[orderItem?.subsubelement]) {
              subsubelement = calcFrequencyGroups[orderItem.subsubelement];
            } else if (sourceElement.config?.scales?.list[orderItem.subsubelement]) {
              subsubelement = sourceElement.config?.scales?.list[orderItem.subsubelement];
            }
            const scaleLabel = getSubelementPropertyTranslation(
              subsubelement,
              orderItem,
              {
                showCodeValue: showCodeValuesRow,
                useShortcodes: true,
                useStripped: true,
                useFallback: true
              },
              translationProps
            );
            label += ` - ${scaleLabel}`;
          }

          dimensionOrder.push({
            label: label,
            rowid: dimensionid,
            rowsubelementid: orderItem.subelement,
            rowsubsubelementid: orderItem?.subsubelement ?? null,
            value: orderItem.value,
            metric: sourceElementValues.list[orderItem.subelement]?.metric
          });
        });
      }

      // else use headOrder provided by backend
      else {
        tableResult?.headOrder?.[dimensionid]?.forEach(orderItem => {
          const value = sourceElementValues.list[orderItem.headSubId];
          const headMapping =
            Object.values(headMappingTable).find(mapping => mapping.ids.includes(orderItem.headSubId)) ?? {};

          //in tableResult.order ist ein array von objekten die die property hidden haben und für jede zeile in den rows stehen. die prop rowId und subelement sind auch als ids vorhanden
          let isHidden = hidden?.[orderItem.headSubId] === true || (isCompactMatrix && headMapping?.hidden === true);
          if (!isHidden && (config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config?.autoHideLowBasesHurdle ?? 0;
            const valuesWithoutHidden = {};
            for (const rowId of Object.keys(tableResult.values)) {
              for (const subelement of Object.keys(tableResult.values[rowId])) {
                const hidden =
                  tableResult.order.find(item => item.rowid === rowId && item.subelement === subelement)?.hidden ??
                  false;
                if (!hidden) {
                  if (!valuesWithoutHidden[rowId]) {
                    valuesWithoutHidden[rowId] = {};
                  }
                  valuesWithoutHidden[rowId][subelement] = tableResult.values[rowId][subelement];
                }
              }
            }
            const highestBase = getHighestHeadBase(tableResult, dimensionid, orderItem.headSubId, {
              isDetailedMatrix: isDetailedMatrix,
              excludeHidden: true
            });
            if (highestBase <= autoHideLowBasesHurdle) isHidden = true;
          }
          const isExcluded = excluded?.[orderItem.headSubId] === true;

          if (isHidden || isExcluded) return false;

          const label = getSubelementPropertyTranslation(
            value,
            { subelement: orderItem.headSubId, value: value?.value },
            {
              showCodeValue: showCodeValuesHead,
              useShortcodes: true,
              useStripped: true,
              useFallback: true
            },
            translationProps
          );

          dimensionOrder.push({
            label: label,
            headid: dimensionid,
            headsubelementid: orderItem.headSubId,
            value: value?.value
          });
        });
      }

      dimensions.push({ sourceType, dimensionid: dimensionid, order: dimensionOrder });
    });

    // add total
    if (!isCompactMatrix && showTotal && !transpose) {
      dimensions.unshift({
        categoryid: '__total__',
        order: [
          {
            label: 'Total',
            headid: '__total__',
            headsubelementid: '__total__'
          }
        ]
      });
    }

    return dimensions;
  }, [
    table,
    tableResult,
    showTotal,
    transpose,
    isCompactMatrix,
    isDetailedMatrix,
    elements,
    headMappingTable,
    translationProps
  ]);
};

/**
 * Returns current row with values for each head position.
 *
 * @param {Object} dimValue
 * @param {Object} categories
 * @param {Object} tableResult
 * @param {Boolean} transpose
 *
 * @returns
 */
const getColumnCategories = ({
  data,
  dimValue,
  categories,
  dimension,
  tableResult,
  transpose,
  isCompactMatrix,
  headMappingTable,
  significancesShow,
  tableConfig,
  chartLayerViewId = null
}) => {
  let dimensionKey = dimValue.headsubelementid;

  if (isCompactMatrix && !transpose) {
    const headMapping = Object.values(headMappingTable).find(mapping =>
      mapping.ids.includes(dimValue.headsubelementid)
    );
    if (headMapping && headMapping.id !== dimValue.headsubelementid) {
      dimensionKey = `${dimValue.rowid}~${headMapping.id}`;
    }
  } else if (transpose && !!dimValue.rowsubsubelementid) {
    dimensionKey = `${dimValue.rowid}~${dimValue.rowsubelementid}~${dimValue.rowsubsubelementid}`;
  } else if (transpose) {
    dimensionKey = `${dimValue.rowid}~${dimValue.rowsubelementid}`;
  }

  if (chartLayerViewId) {
    dimensionKey = `${chartLayerViewId}~${dimensionKey}`;
  }

  const significanceLevel = parseFloat(tableConfig.valueSignificancesLevel);
  const significanceGate = significanceGates[1]?.[significanceLevel];
  const significanceDimension = tableConfig?.valueSignificancesDimension ?? 'heads';
  const significanceBenchmark = tableConfig?.valueSignificancesBenchmark ?? null;

  data[dimensionKey] = data?.[dimensionKey] ?? {
    name: dimValue.label,
    significances: {},
    ...(chartLayerViewId ? { viewId: chartLayerViewId } : {})
  };

  categories.forEach(category => {
    if (
      isCompactMatrix &&
      transpose &&
      dimension.sourceType !== 'calculation' &&
      category.sourceType !== 'calculation' &&
      category.categoryid !== dimValue.rowid
    ) {
      return;
    }
    category.order.forEach(catValue => {
      let { rowid, rowsubelementid, rowsubsubelementid, headid, headsubelementid, metric = 'smvrslt_perc' } = {
        ...dimValue,
        ...catValue
      };
      if (dimension.sourceType === 'calculation' || category.sourceType === 'calculation') {
        metric = 'smvrslt_calculation';
      }

      let cell, categoryKey;
      // no detailed matrix
      if (!rowsubsubelementid) {
        cell = tableResult?.values?.[rowid]?.[rowsubelementid]?.[headid]?.[headsubelementid];
        if (!cell) return;
        if (Object.keys(headMappingTable).length > 0) {
          if (!transpose) categoryKey = `${rowid}~${rowsubelementid}`;
          else {
            const headMapping = Object.values(headMappingTable).find(mapping => mapping.ids.includes(headsubelementid));
            categoryKey = `${headMapping.rowid}~${headMapping?.id}`;
          }
        } else {
          if (!transpose) categoryKey = `${rowid}~${rowsubelementid}`;
          else categoryKey = `${headid}~${headsubelementid}`;
        }
      }
      // detailed matrix
      else {
        cell = tableResult?.values?.[rowid]?.[rowsubelementid]?.[rowsubsubelementid]?.[headid]?.[headsubelementid];
        if (!transpose) categoryKey = `${rowid}~${rowsubelementid}~${rowsubsubelementid}`;
        else categoryKey = `${headid}~${headsubelementid}`;
      }
      const value = getResultValue({ cell, metric, fallback: null });

      data[dimensionKey][categoryKey] = {
        value,
        cellIdentifier: {
          rowId: rowid ?? null,
          rowSubId: rowsubelementid ?? null,
          rowSubSubId: rowsubsubelementid ?? null,
          headId: headid ?? null,
          headSubId: headsubelementid ?? null
        }
      };

      if (significancesShow) {
        const { isSignificantHigh, isSignificantLow } = getSignificance(
          cell,
          significanceGate,
          significanceDimension,
          significanceBenchmark
        );

        const significance =
          isSignificantHigh && isSignificantLow ? 'both' : isSignificantHigh ? 'high' : isSignificantLow ? 'low' : null;

        data[dimensionKey].significances[categoryKey] = significance;
      }
    });
  });
};
