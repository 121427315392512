import React, { useRef, useMemo, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { useDrag, useDrop } from 'react-dnd';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';

import { IconProvider } from 'smv-components';
import { createNewQuestionnaireItem, getQuestionnaireElementTypeSpecificIconString } from 'smv-helpers';
import { questionnaireActions } from '../../../../redux/actions';

export const StructureElementNode = ({
  index,
  surveyId,
  elementId,
  nodeOnClick,
  writeable,
  questionnaire,
  parentsIds
}) => {
  const element = questionnaire.list[elementId];

  const isLooped = !!element.config?.loop;

  const [dropMode, setDropMode] = useState(null);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const dropRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: element.type,
    item: {
      id: element.id,
      parentId: element.parentId,
      type: element.type,
      qtype: element?.config?.qtype ?? null
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    canDrag: () => {
      return writeable;
    }
  });

  // eslint-disable-next-line
  const [{ isOver, canDrop, isOverCurrent, dropPosition }, drop] = useDrop({
    accept: ['container', 'page', 'question'],
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
      // dropItem: monitor.getItem()
    }),
    canDrop: (item, monitor) => {
      if (item.type === 'page') {
        if (element.type === 'page') return false;

        // check if parent already contains a page
        for (const id of parentsIds) {
          const parentElement = questionnaire.list[id];
          if (parentElement.type === 'page') {
            return false;
          }
        }
      }

      // move operation
      if (item.id) {
        // dragged item = drop item
        if (item.id === elementId || !monitor.isOver({ shallow: true })) return false;

        // dragged item is an ancestor of dropped item
        if (parentsIds.indexOf(item.id) !== -1) return false;
      }

      return true;
    },
    hover: (item, monitor) => {
      // can not drop on current element or is the element itself
      if (!monitor.canDrop() || !dropRef?.current || !monitor.isOver({ shallow: true })) return;

      const clientOffset = monitor.getClientOffset();
      const componentRect = findDOMNode(dropRef.current).getBoundingClientRect();
      const relY = (clientOffset.y - componentRect.y) / componentRect.height;

      if (element.type === 'question') {
        if (relY <= 0.5) setDropMode('above');
        else if (relY > 0.5) setDropMode('below');
      } else {
        if (relY <= 0.33) setDropMode('above');
        else if (relY > 0.33 && relY <= 0.66) setDropMode('inside');
        else if (relY > 0.66) setDropMode('below');
      }
    },
    drop: (item, monitor) => {
      if (monitor.didDrop() || !monitor.canDrop()) return;

      let parentId = null;
      let position = 0;
      const isNewItem = !Boolean(item.id);
      const hoveredElement = element;
      // default: moving inside root
      let newItemIndex = questionnaire.root.indexOf(item.id);
      let hoveredItemIndex = questionnaire.root.indexOf(hoveredElement.id);
      // moving in or to container container
      if (hoveredElement.parentId) {
        hoveredItemIndex = questionnaire.list[hoveredElement.parentId].children.indexOf(hoveredElement.id);
        newItemIndex = questionnaire.list[hoveredElement.parentId].children.indexOf(item.id);
      }

      switch (dropMode) {
        case 'above':
          parentId = hoveredElement.parentId;
          if (isNewItem || newItemIndex === -1 || newItemIndex >= hoveredItemIndex) {
            position = hoveredItemIndex;
          } else {
            position = hoveredItemIndex - 1;
          }
          break;
        case 'inside':
          parentId = hoveredElement.id;
          position = hoveredElement.children?.length ?? 0;
          break;
        case 'below':
          parentId = hoveredElement.parentId;
          if (isNewItem || newItemIndex === -1 || newItemIndex >= hoveredItemIndex) {
            position = hoveredItemIndex + 1;
          } else {
            position = hoveredItemIndex;
          }
          break;
        default:
          break;
      }

      // item has id, so it is move operation
      if (item?.id) {
        const content = {
          parentId,
          position: position
        };

        questionnaireActions.updateQuestionnaireElement(surveyId, item.id, content);
      }
      // item has no id, so its newly added
      else {
        const _newItem = createNewQuestionnaireItem(item, questionnaire);
        _newItem.parentId = parentId;
        _newItem.position = position;
        questionnaireActions.addQuestionnaireElement(surveyId, _newItem);
      }
    }
  });

  const toggleCollapse = () => {
    setIsCollapseOpen(!isCollapseOpen);
  };

  const nodeTitle = useMemo(() => {
    return (
      (element?.config?.varname ?? '') +
      (element?.config?.varname ? ' - ' : '') +
      (element?.title ?? 'no element title...')
    );
  }, [element?.config?.varname, element?.title]);

  return (
    <div ref={drag} className={classNames({ isDragging: isDragging })}>
      <div
        ref={drop(dropRef)}
        className={classNames({
          'node': true,
          'draggable': true,
          'canDrop': canDrop,
          'isOver': isOver,
          'drop-above': dropMode === 'above',
          'drop-inside': dropMode === 'inside',
          'drop-below': dropMode === 'below'
        })}
      >
        {element.type !== 'question' && (
          <div className='node-collapse-icon' onClick={toggleCollapse}>
            <i className={`fas fa-chevron-right ${isCollapseOpen ? 'expanded' : ''}`} />
          </div>
        )}

        <div
          className='questionnaire-sidebar__text-container'
          onClick={e => {
            nodeOnClick(e, { id: element.id });
          }}
        >
          <div className='node-icon'>
            <IconProvider icon={getQuestionnaireElementTypeSpecificIconString(element)} clickable={false} />
          </div>
          {isLooped && (
            <div className='loop-icon mr-1'>
              <IconProvider icon={'loop'} clickable={false} />
            </div>
          )}

          <div className={classNames({ 'node-title': true, 'placeholder': !element?.title })} title={nodeTitle}>
            {nodeTitle}
          </div>
        </div>
      </div>
      {element?.children && (
        <Collapse isOpen={isCollapseOpen}>
          {element.children.map((childId, childIndex) => (
            <StructureElementNode
              index={childIndex}
              key={childId}
              surveyId={surveyId}
              elementId={childId}
              nodeOnClick={nodeOnClick}
              writeable={writeable}
              questionnaire={questionnaire}
              parentsIds={[...parentsIds, element.id]}
            />
          ))}
        </Collapse>
      )}
    </div>
  );
};
