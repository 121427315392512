export const getSortingIconString = order => {
  switch (order) {
    case 'none':
      return '';
    case 'asc':
      return 'long-arrow-up';
    case 'desc':
      return 'long-arrow-down';
    default:
      return '';
  }
};
