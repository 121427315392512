import produce from 'immer';
import { customAlphabet } from 'nanoid';

import { reportsActions } from 'smv-redux';

/**
 *
 * A asyncrhonous function that creates charts in a report. Makes one request per provided table and updates the redux state by using redux actions to create a chart.
 *
 * @param {array}  tables //array of tables
 * @param {object} report //the report the charts should be created in
 * @param {string} surveyId //surveyId the report is in
 * @param {function} counter //a function that increments a value each time a chart has been created (eg a react useState set-function)
 *
 *  * @returns {Promise} Returns a promise
 */
export const asyncCreateCharts = (tables, report, surveyId, counter) => {
  return new Promise((resolve, reject) => {
    const createChart = (idx, tables, layout, elements) => {
      const _table = tables[idx];
      const sourceType = 'table';
      const elementid = customAlphabet('1234567890abcdef', 24)();

      let element = {
        id: elementid,
        sourceType
      };
      const sourceId = _table.id;
      element.sourceId = sourceId;

      const isOdd = idx % 2;
      const elementYPosition = Math.floor(idx / 2) * 10;
      const newElement = {
        w: 6,
        h: 10,
        x: isOdd ? 6 : 0,
        y: elementYPosition,
        i: elementid,
        moved: false,
        static: false,
        isDraggable: true
      };
      const _layout = [...layout, newElement];

      const updatedLayout = _layout.map(el => {
        const { i, w, h, x, y } = el;
        return { i, w, h, x, y };
      });

      reportsActions
        .updateReport(
          surveyId,
          report.id,
          produce(report, draft => {
            const page = draft.pages.list[report.pages.order[0]];

            if (!page.layouts) {
              page.layouts = {};
            }

            if (!page.elements) {
              page.elements = {};
            }

            page.elements = {
              ...elements,
              [element.id]: element
            };
            page.layouts['lg'] = updatedLayout;
            page.layouts['sm'] = updatedLayout.map(elayout => {
              return { ...elayout, w: Math.ceil(elayout.w / 6) * 6 };
            });

            page.layouts['md'] = updatedLayout.map(elayout => {
              return { ...elayout, w: Math.ceil(elayout.w / 6) * 6 };
            });
          })
        )
        .then(res => {
          const newLayout = res.pages.list[res.pages.order[0]].layouts.lg;
          const newElements = res.pages.list[res.pages.order[0]].elements;
          if (idx + 1 < tables.length) {
            counter(idx + 1);
            createChart(idx + 1, tables, newLayout, newElements);
          } else {
            resolve();
          }
        })
        .catch(err => {
          reject(err);
        });
    };
    // creating charts recursively
    createChart(0, tables, [], {});
  });
};
