import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { QuestionPreview } from './questiontypes/QuestionPreview';

import logo from 'smv-assets/img/smoove.png';

import './QuestionPreviewModal.scss';

export const QuestionPreviewModal = ({ isOpen, toggle, question }) => {
  const intl = useIntl();

  return (
    <Modal className='question-preview-modal' isOpen={isOpen} centered={true} size={'xl'} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <img src={logo} alt='smoove' width='140px' />
      </ModalHeader>
      <ModalBody>
        <QuestionPreview question={question} />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <small>{intl.formatMessage({ id: 'smoove.questionnaire.modal.question-preview.design-hint' })}</small>
          <Button color={'grey'} onClick={toggle}>
            <FormattedMessage id={`smoove.common.buttons.close`} defaultMessage={'Close'} />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
