import { getMediaElementType } from '../components/questionnaire/Content/MediaLibrary/helpers/getMediaElementType';
// import audiofile from '../assets/img/medialibrary/audiofile.png';
// import videofile from '../assets/img/medialibrary/videofile.png';
/**
 * Converts a string containing placeholders for img|video|audio [[image||asdf123] to an html string with img-tags
 *
 * @param {string} questionTest
 * @param {object} media
 *
 * @returns {string} Returns html string containing img|video|audio-tags or null if mediaItem cant be found (so the previous value or the string with placeholders can be used)
 */
export const parsePlaceholdersAndSetHtml = (questionText, question, media) => {
  const newQuestionText = questionText.replaceAll(mediaPlaceholderRegex, (match, type, mediaid) => {
    const queMediaItem = question.config?.media?.[mediaid];
    const surveyMediaItem = media?.[queMediaItem?.source];
    const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

    // type is the html element type, not the media type. needs to be found via mediaid
    const _type = getMediaElementType(surveyMediaItem);

    if (!queMediaItem || !surveyMediaItem) return match;
    else {
      const url = `${baseUrl}/${surveyMediaItem.url}`;
      let htmlElement = null;
      let htmlWrapper = null;

      if (_type === 'image' || _type === 'img') {
        htmlElement = document.createElement('img');
        htmlElement.setAttribute('src', url);
        htmlElement.setAttribute('width', queMediaItem?.config?.width ?? '200px');

        if (queMediaItem?.config?.lightbox) {
          htmlWrapper = document.createElement('a');
          htmlWrapper.setAttribute('href', url);
          htmlWrapper.setAttribute('data-lightbox', queMediaItem.id);
          htmlWrapper.appendChild(htmlElement);
        }
      } else if (_type === 'audio') {
        htmlElement = document.createElement('audio');
        htmlElement.setAttribute('src', url);
        htmlElement.setAttribute('width', queMediaItem?.config?.width ?? '120px');
        htmlElement.setAttribute('title', surveyMediaItem.originalName);
        htmlElement.controls = 'controls';
        // htmlElement.setAttribute('class', 'jodit-audio-html-element');
      } else if (_type === 'video') {
        htmlElement = document.createElement('video');
        htmlElement.setAttribute('src', url);
        htmlElement.setAttribute('width', queMediaItem?.config?.width ?? '360px');
        // htmlElement.setAttribute('title', surveyMediaItem.originalName);
        htmlElement.controls = 'controls';
        // htmlElement.setAttribute('class', 'jodit-video-html-element');
      }
      htmlElement.setAttribute('smv-media-id', queMediaItem.id);
      htmlElement.setAttribute('smv-media-source-id', queMediaItem.source);
      // htmlElement.setAttribute('src', url);
      if (queMediaItem?.config?.height > 0) htmlElement.setAttribute('height', queMediaItem.config.height);
      if (queMediaItem?.config?.style?.length > 0) htmlElement.setAttribute('style', queMediaItem.config.style);

      const imgString = htmlWrapper ? getImageTagAsString(htmlWrapper) : getImageTagAsString(htmlElement);
      return imgString;
    }
  });

  return newQuestionText;
};

const getImageTagAsString = img => {
  const template = document.createElement('div');
  template.appendChild(img);

  return template.innerHTML;
};

export const mediaPlaceholderRegex = /\[\[(image|img|video|audio)\|([\w-]+)\]\]/g;
