export const projectActionTypes = {
  SAVE_WIZARD_REQUEST: 'SAVE_WIZARD_REQUEST',
  SAVE_WIZARD_SUCCESS: 'SAVE_WIZARD_SUCCESS',
  SAVE_WIZARD_FAILURE: 'SAVE_WIZARD_FAILURE',

  EDIT_TEXT: 'EDIT_TEXT',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  SET_SHARED_LIST: 'QUE_SET_SHARED_LIST',

  EXPORT_PROJECT_QUESTBACK_REQUEST: 'EXPORT_PROJECT_QUESTBACK_REQUEST',
  EXPORT_PROJECT_QUESTBACK_SUCCESS: 'EXPORT_PROJECT_QUESTBACK_SUCCESS',
  EXPORT_PROJECT_QUESTBACK_FAILURE: 'EXPORT_PROJECT_QUESTBACK_FAILURE',

  CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',

  // SAVE_PROJECT_REQUEST: 'SAVE_PROJECT_REQUEST',
  // SAVE_PROJECT_SUCCESS: 'SAVE_PROJECT_SUCCESS',
  // SAVE_PROJECT_FAILURE: 'SAVE_PROJECT_FAILURE',

  SURVEY_LIST_SUCCESS: 'SURVEY_LIST_SUCCESS',
  SURVEY_LIST_REQUEST: 'SURVEY_LIST_REQUEST',
  SURVEY_LIST_FAILURE: 'SURVEY_LIST_FAILURE',

  SWITCH_PROJECT: 'SWITCH_PROJECT',

  INIT_RESET_PROJECT: 'INIT_RESET_PROJECT',
  INIT_RESET_PROJECTS_LIST: 'INIT_RESET_PROJECTS_LIST',

  PATCH_PROJECT_REQUEST: 'PATCH_PROJECT_REQUEST',
  PATCH_PROJECT_SUCCESS: 'PATCH_PROJECT_SUCCESS',
  PATCH_PROJECT_FAILURE: 'PATCH_PROJECT_FAILURE',

  PROJECTS_LIST_REQUEST: 'PROJECTS_LIST_REQUEST',
  PROJECTS_LIST_SUCCESS: 'PROJECTS_LIST_SUCCESS',
  PROJECTS_LIST_FAILURE: 'PROJECTS_LIST_FAILURE',

  DUPLICATE_PROJECT_REQUEST: 'DUPLICATE_PROJECT_REQUEST',
  DUPLICATE_PROJECT_SUCCESS: 'DUPLICATE_PROJECT_SUCCESS',
  DUPLICATE_PROJECT_FAILURE: 'DUPLICATE_PROJECT_FAILURE',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE'
};
