import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';

import { permissionEnum, useControlledState, useUserHasPermission } from 'smv-helpers';
import {
  ExportFileButton,
  ExportFileModal,
  SmvCustomDropdown
  // ExportFileButton, ExportFileModal
} from '../../misc';
import {
  EditReportButton,
  EditReportModal,
  DeleteReportButton,
  DeleteReportModal,
  ShareReportButton,
  ShareReportModal,
  DuplicateReportButton,
  DuplicateReportModal
} from '../modals';
import { projectSettingsActions } from 'smv-redux';

import './ReportsListItem.scss';

export const ReportsListItem = ({ reportid, handler, activeFilters }) => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { projectid, surveyid } = params;
  const report = useSelector(state => state.survey.reports?.list?.[reportid]);
  const label = report?.name ?? `unnamed report ${reportid}`;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const isActive = reportid === params.reportid;

  if (!report?.id) return null;

  return (
    <div className='report-item'>
      <div
        onClick={() => {
          projectSettingsActions.setSelectedReport(projectid, surveyid, reportid);
          history.push(`/one/projects/${projectid}/surveys/${surveyid}/reports/${reportid}`);
          handler.resetActiveFiltersAndSplits();
        }}
        className={`report-item__link pointer`}
        unselectable='on'
      >
        <i className={classnames('fa-circle', { fas: isActive, far: !isActive })}></i>
        <span className='report-item__label'>{label}</span>
      </div>

      {userCanEditProject && (
        <ItemOptions report={report} handler={handler} activeFilters={activeFilters} label={label} />
      )}
    </div>
  );
};

const ItemOptions = ({ report, handler, activeFilters, label }) => {
  const [EditButton, EditModal] = useControlledState(EditReportButton, EditReportModal, false);
  const [DeleteButton, DeleteModal] = useControlledState(DeleteReportButton, DeleteReportModal, false);
  const [DuplicateButton, DuplicateModal] = useControlledState(DuplicateReportButton, DuplicateReportModal, false);
  const [ShareButton, ShareModal] = useControlledState(ShareReportButton, ShareReportModal, false);
  const [ExportButton, ExportModal] = useControlledState(ExportFileButton, ExportFileModal, false);

  const exportHandler = useCallback(
    (exportFileType, exportLoopFilters = null) => handler.exportReport(report.id, exportFileType, exportLoopFilters),
    [handler, report.id]
  );

  return (
    <div>
      <SmvCustomDropdown>
        <DropdownItem>
          <EditButton report={report} />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <DuplicateButton />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <ShareButton />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <ExportButton type={'report'} />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <DeleteButton />
        </DropdownItem>
      </SmvCustomDropdown>

      <EditModal report={report} mode={'edit'} saveHandler={handler.saveReport} />
      <DuplicateModal title={`'${label}'`} reportid={report.id} duplicateHandler={handler.duplicateReport} />
      <ShareModal reportid={report.id} handler={handler} />
      <ExportModal exportHandler={exportHandler} type={'report'} reportId={report.id} activeFilters={activeFilters} />
      <DeleteModal title={`'${label}'`} reportid={report.id} deleteHandler={handler.deleteReport} />
    </div>
  );
};
