export const getHighestHeadBase = (
  tableResult = { values: {}, order: [] },
  headid,
  headsubelementid,
  config = {
    isDetailedMatrix: false,
    baseMetric: 'smvrslt_base',
    excludeHidden: false
  }
) => {
  config = {
    isDetailedMatrix: false,
    baseMetric: 'smvrslt_base',
    excludeHidden: false,
    ...config
  };

  let hightestBaseValue = 0;
  const tableValues = tableResult.values;
  let filteredValues = {};
  if (!config.isDetailedMatrix) {
    if (config.excludeHidden) {
      for (const rowId of Object.keys(tableResult.values)) {
        for (const subelement of Object.keys(tableResult.values[rowId])) {
          const hidden =
            tableResult.order.find(item => item.rowid === rowId && item.subelement === subelement)?.hidden ?? false;
          if (!hidden) {
            if (!filteredValues[rowId]) {
              filteredValues[rowId] = {};
            }
            filteredValues[rowId][subelement] = tableResult.values[rowId][subelement];
          }
        }
      }
    } else {
      filteredValues = tableValues;
    }

    Object.values(filteredValues ?? {}).forEach(row =>
      Object.values(row).forEach(rowchoice => {
        if ((rowchoice?.[headid]?.[headsubelementid]?.[config.baseMetric] ?? 0) > hightestBaseValue) {
          hightestBaseValue = parseFloat(rowchoice?.[headid]?.[headsubelementid]?.[config.baseMetric]);
        }
      })
    );
  } else {
    if (config.excludeHidden) {
      for (const rowId of Object.keys(tableResult.values)) {
        for (const rowsubelementId of Object.keys(tableResult.values[rowId])) {
          for (const rowsubsubelementId of Object.keys(tableResult.values[rowId][rowsubelementId])) {
            const hidden =
              tableResult.order.find(
                item =>
                  item.rowid === rowId &&
                  item.subelement === rowsubelementId &&
                  item.subsubelement === rowsubsubelementId
              )?.hidden ?? false;
            if (!hidden) {
              if (!filteredValues[rowId]) {
                filteredValues[rowId] = {};
              }
              filteredValues[rowId][rowsubelementId] = tableResult.values[rowId][rowsubelementId];
            }
          }
        }
      }
    } else {
      filteredValues = tableValues;
    }
    Object.values(filteredValues).forEach(row =>
      Object.values(row).forEach(rowsubelement =>
        Object.values(rowsubelement).forEach(rowsubsubelement => {
          if ((rowsubsubelement?.[headid]?.[headsubelementid]?.[config.baseMetric] ?? 0) > hightestBaseValue) {
            hightestBaseValue = parseFloat(rowsubsubelement?.[headid]?.[headsubelementid]?.[config.baseMetric]);
          }
        })
      )
    );
  }

  return hightestBaseValue;
};
