import React from 'react';
import logo_ball from 'smv-assets/img/smoove_logo.png';
import logo from 'smv-assets/img/smoove.png';

export const HeaderLogo = ({ height = 50, type = 'small', animate = false }) => (
  <>
    <img className='header-logo header-logo--small' src={logo_ball} height={`${height}px`} alt='mindline analytics' />
    <img className='header-logo header-logo--full' src={logo} height={`${height}px`} alt='mindline analytics' />
  </>
);
