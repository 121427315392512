import classNames from 'classnames';
import React, { useCallback } from 'react';

export const ActiveFilterElement = ({ value, filter, isDeselectable = false, reportsHandler, label }) => {
  const handleClick = useCallback(() => {
    reportsHandler.setActiveFilter(filter, value.id);
  }, [reportsHandler, filter, value.id]);

  return (
    <div
      className={classNames({ 'active-filter-list__element': true, 'deselectable': isDeselectable })}
      onClick={handleClick}
    >
      <span>{label}</span>
      <i className='far fa-times active-filter-list__remove-filter' />
    </div>
  );
};
