import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { IconProvider } from 'smv-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

export const DynamicSplitConfigAvailableElements = ({ report, split, handler }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations);

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const vectorQuestions = useMemo(() => {
    const getQuestions = (element, list, questions = []) => {
      if (element?.type === 'question') {
        if (['single_vector', 'multiple_vector'].includes(element.config?.qtype)) {
          questions.push(element.id);
        }
      } else {
        for (const id of element.children) {
          getQuestions(list[id], list, questions);
        }
      }
      return questions;
    };

    return getQuestions({ children: questionnaire.root }, questionnaire.list);
  }, [questionnaire]);

  return (
    <div className='available-filter-list'>
      {/* draggables need to be inside a droppable, even if they are drag only -> droppable needed here*/}
      <MakeDroppable droppableId='que-splits-droppable'>
        <SplitHeader text='Questionnaire'>
          {vectorQuestions.map((id, index) => {
            const element = questionnaire.list[id];
            return (
              <MakeDraggable key={`${element.id}_${index}`} draggableId={element.id} index={index}>
                <SplitListElement
                  key={`${id}_${index}_listelent`}
                  type={'question'}
                  element={element}
                  handler={handler}
                />
              </MakeDraggable>
            );
          })}
        </SplitHeader>
      </MakeDroppable>

      <MakeDroppable droppableId='sys-splits-droppable'>
        <SplitHeader text='Internal Variables'>
          {systemVariables.order.map((systemVariableId, index) => (
            <MakeDraggable key={`${index}_${systemVariableId}`} draggableId={systemVariableId} index={index}>
              <SplitListElement
                key={`${index}_${systemVariableId}`}
                type={'sysvar'}
                element={systemVariables.list[systemVariableId]}
                handler={handler}
              />
            </MakeDraggable>
          ))}
        </SplitHeader>
      </MakeDroppable>

      <MakeDroppable droppableId='cal-splits-droppable'>
        <SplitHeader text='Calculations'>
          {calculations?.map((calculation, index) => (
            <MakeDraggable key={`${index}_${calculation.id}`} draggableId={calculation.id} index={index}>
              <SplitListElement
                key={`${index}_${calculation.id}`}
                type={'calcvar'}
                element={calculation}
                handler={handler}
              />
            </MakeDraggable>
          ))}
        </SplitHeader>
      </MakeDroppable>
    </div>
  );
};

const SplitHeader = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = useCallback(() => setIsOpen(b => !b), [setIsOpen]);

  return (
    <>
      <div className='filter-header' onClick={toggle}>
        <i
          className={classNames(`fas fa-chevron-right pointer mr-2 rotate`, {
            'rotate-90': isOpen
          })}
        />
        <div>{text}</div>
      </div>
      <Collapse isOpen={isOpen} className='available-filter-list__collapse'>
        <div>{children}</div>
      </Collapse>
    </>
  );
};

const SplitListElement = ({ element }) => {
  const label = element?.title ?? element?.label ?? (
    <i>
      <FormattedMessage id={'smoove.page.common.dynamic-splits.unnamed-element'} defaultMessage={'unnamed-element'} />
    </i>
  );

  return (
    <div className='node draggable draggable-default'>
      <IconProvider icon={element.config?.qtype ?? element.type} filled={false} clickable={false} dragable={true} />
      <span className='ml-2 node__title'>{label}</span>
    </div>
  );
};

const getDraggableStyles = isDragging => {
  return {
    //   boxSizing: 'border-box',
    //   borderRadius: isDragging && '4px',
    //   boxShadow:
    //     isDragging && '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)'
  };
};

const MakeDraggable = ({ children, draggableId, index }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...getDraggableStyles(snapshot.isDragging),
              transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)'
            }}
          >
            {children}
          </div>
          {snapshot.isDragging && <div style={{ transform: 'none !important' }}>{children}</div>}
        </>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={true}>
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          <div className='d-none'>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};
