import React from 'react';
import { useSelector } from 'react-redux';

import { useRowOrder } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from './helper';
import {
  DefaultQuestionMenu,
  MultipleVectorQuestionMenu,
  SingleVectorChoiceMenu,
  MultipleVectorChoiceMenu
} from './menus';
import { QuestionSelectionDetails, ResultRow, ResultRowHeader } from '.';

export const DefaultResultRow = ({
  element,
  table,
  tableResult,
  row,
  questionHas,
  handler,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { heads, config } = table;
  const { showHiddenRows = true } = config;
  const { id, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row, showHiddenRows);
  const colSpan = matrixType === 'detail' ? 2 : 1;

  const defaultMetric = useDefaultMetric(table);

  let subelements = element?.config?.choices ?? {};
  let _head = heads;

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const rowHeadLabel = element.title;

  let questionMenu = (
    <DefaultQuestionMenu
      rowid={id}
      row={row}
      element={element}
      subelements={subelements}
      table={table}
      handler={handler}
      questionHas={questionHas}
    />
  );

  if (
    element.config.qtype === 'multiple_vector' ||
    (element.config.qtype === 'custom_shelf' &&
      (element.config?.shelfResultType === 'multiple' || element.config?.shelf?.config?.shelfResultType === 'multiple'))
  ) {
    questionMenu = (
      <MultipleVectorQuestionMenu
        rowid={id}
        row={row}
        element={element}
        subelements={subelements}
        table={table}
        handler={handler}
        questionHas={questionHas}
      />
    );
  }

  const _rows = [];
  rowOrder.forEach(order => {
    let metric = defaultMetric;
    if (order.value === 'mean') metric = 'smvrslt_mean';
    const isHidden = order?.hidden ?? false;
    const isExcluded = order?.excluded ?? false;

    if (!showHiddenRows && (isHidden || isExcluded)) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;

    let choiceMenu = null;
    if (
      element.config.qtype === 'single_vector' ||
      (element.config.qtype === 'custom_shelf' &&
        (element.config?.shelfResultType === 'single' || element.config?.shelf?.config?.shelfResultType === 'single'))
    ) {
      choiceMenu = (
        <SingleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    } else if (
      element.config.qtype === 'multiple_vector' ||
      (element.config.qtype === 'custom_shelf' &&
        (element.config?.shelfResultType === 'multiple' ||
          element.config?.shelf?.config?.shelfResultType === 'multiple'))
    ) {
      choiceMenu = (
        <MultipleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    }

    if (order.value === 'mean') choiceMenu = null;

    _rows.push(
      <ResultRow
        key={`result-row-${row.id}_${order.subelement}`}
        rowid={id}
        orderItem={order}
        subelement={calcFrequencyGroups[order?.subelement] ?? subelements.list?.[order?.subelement]}
        choiceid={order.subelement}
        config={config}
        heads={_head}
        tableResult={tableResult}
        handler={handler}
        metric={metric}
        isHidden={isHidden}
        isExcluded={isExcluded}
        sortationPinned={sortationPinned}
        isCompactMatrix={isCompactMatrix}
        menu={choiceMenu}
        hover={hover}
      >
        {_rows.length === 0 && (
          <ResultRowHeader
            rowid={id}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            colSpan={colSpan}
            handler={handler}
            questionHas={questionHas}
            menu={questionMenu}
            questionSelectionDetails={
              <QuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
            }
          />
        )}
      </ResultRow>
    );
  });

  return _rows;
};
