import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';

import './ConfigBarTop.scss';

export const ConfigBarTop = ({ isAdmin, previewSize, setPreviewSize, enabledScreenSizes = ['sm'] }) => {
  const intl = useIntl();

  if (!isAdmin) return <div></div>;

  return (
    <div className='config-bar-top'>
      <div className='config-bar-top__devices'>
        {/* expert mode does not cange anything on the reports page atm so it is commented out */}
        {/* <div className='mr-1'>{intl.formatMessage({ id: 'smoove.common.project.expert-mode' })}: </div>
        <SmvToggleExpertModeSwitch /> */}
        <div
          className={`device ${previewSize === 'sm' && 'device--active'} ${
            !enabledScreenSizes.includes('sm') && 'device--disabled'
          }`}
          onClick={() => enabledScreenSizes.includes('sm') && setPreviewSize('sm')}
          id={'tt_cb_sm'}
        >
          <UncontrolledTooltip target={`tt_cb_sm`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.page.reports.mobile-preview' })}
          </UncontrolledTooltip>
          <i className='fal fa-mobile-alt'></i>
        </div>
        <div
          className={`device ${previewSize === 'md' && 'device--active'} ${
            !enabledScreenSizes.includes('md') && 'device--disabled'
          }`}
          onClick={() => enabledScreenSizes.includes('md') && setPreviewSize('md')}
          id={'tt_cb_md'}
        >
          <UncontrolledTooltip target={`tt_cb_md`} delay={{ hide: 200 }} autohide={false}>
            {enabledScreenSizes.includes('md')
              ? intl.formatMessage({ id: 'smoove.page.reports.tablet-preview' })
              : intl.formatMessage({ id: 'smoove.page.reports.tablet-prieview-disabled' })}
          </UncontrolledTooltip>
          <i className='fal fa-tablet-alt'></i>
        </div>
        <div
          className={`device ${previewSize === 'lg' && 'device--active'} ${
            !enabledScreenSizes.includes('lg') && 'device--disabled'
          }`}
          onClick={() => enabledScreenSizes.includes('lg') && setPreviewSize('lg')}
          id={'tt_cb_lg'}
        >
          <UncontrolledTooltip target={`tt_cb_lg`} delay={{ hide: 200 }} autohide={false}>
            {enabledScreenSizes.includes('lg')
              ? intl.formatMessage({ id: 'smoove.page.reports.desktop-preview' })
              : intl.formatMessage({ id: 'smoove.page.reports.desktop-preview-disabled' })}
          </UncontrolledTooltip>
          <i className='fal fa-desktop-alt'></i>
        </div>
      </div>
    </div>
  );
};
