import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Form } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { TextInputRow, RowLabel } from 'smv-components';

export const SaveTableModalButton = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(state => !state);

  const intl = useIntl();
  const translationPrefix = 'smoove.page.survey.tables';

  return (
    <>
      <Button block onClick={toggle} color='primary' disabled={props?.isEmpty ?? true}>
        <i className='fal fa-save mr-1'></i>
        {intl.formatMessage({ id: `${translationPrefix}.config.save-table` })}
      </Button>
      {isOpen && <SaveTableModal isOpen={isOpen} close={toggle} {...props} />}
    </>
  );
};

const SaveTableModal = ({ isOpen, close, handler, table }) => {
  const folders = useSelector(state => state.survey.tablesFolders);

  const { params } = useRouteMatch();

  const [name, setName] = useState(table.name);

  const getFolderTableIsCurrentlySavedIn = useCallback(() => {
    const _folders = Object.values(folders.list);
    let folderId = folders.rootFolderId;

    _folders?.forEach(folder => {
      const tableIsIncludedInFolder = folder.children.some(child => child.id === table.id);
      if (folder.id !== folders.rootFolderId && tableIsIncludedInFolder) {
        folderId = folder.id;
      }
    });
    return folderId;
  }, [folders.list, folders.rootFolderId, table.id]);

  const [saveInFolder, setSaveInFolder] = useState(getFolderTableIsCurrentlySavedIn());
  const intl = useIntl();
  const translationPrefix = 'smoove.page.survey.tables';

  useEffect(() => {
    if (isOpen === true) setName(table.name);
  }, [isOpen, table?.name]);

  const updateName = useCallback(({ target }) => {
    const { value } = target;
    setName(value);
  }, []);

  const updateFolder = useCallback(({ target }) => {
    const { value } = target;
    setSaveInFolder(value);
  }, []);

  const saveHandler = useCallback(() => {
    let updatedTable = { ...table, name, parentId: saveInFolder };

    handler.saveTable(updatedTable).then(() => {
      close();
    });
  }, [handler, close, name, saveInFolder, table]);

  const newTable = useCallback(async () => {
    let updatedTable = { ...table, name, parentId: saveInFolder };

    handler.createTable(updatedTable).then(() => {
      handler.setTableConfig(updatedTable);
      close();
    });
  }, [close, handler, name, saveInFolder, table]);

  const saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      params.tableid ? saveHandler() : newTable();
    },
    [newTable, saveHandler, params.tableid]
  );

  return (
    <Modal isOpen={isOpen} toggle={close} size={'lg'}>
      <Form onSubmit={saveOnSubmit}>
        <ModalHeader toggle={close}>
          {intl.formatMessage({ id: `${translationPrefix}.form.save.headline` })}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>{intl.formatMessage({ id: `${translationPrefix}.form.save.text` })}</Col>
          </Row>
          <TextInputRow
            name={'name'}
            title={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.label` })}
            tooltip={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.desc` })}
            value={name}
            change={updateName}
            placeholder={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.name.placeholder` })}
          />
          <Row>
            <RowLabel
              name={'folder'}
              title={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.folder.label` })}
              tooltip={intl.formatMessage({ id: `${translationPrefix}.form.save.fields.folder.tooltip` })}
            />
            <Col lg={8} xl={9}>
              <Input
                type='select'
                label={'Save in'}
                id={`folder`}
                name='folder'
                value={saveInFolder}
                onChange={updateFolder}
              >
                {folders.order.map(folderid => (
                  <option key={folderid} value={folderid}>
                    {folders.list[folderid].name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {params.tableid && (
            <>
              <Button color='secondary' onClick={newTable}>
                {intl.formatMessage({ id: `${translationPrefix}.config.save-as-new-table` })}
              </Button>{' '}
            </>
          )}
          <Button color='primary' onClick={params.tableid ? saveHandler : newTable}>
            <FormattedMessage
              id={`${translationPrefix}.config.${params.tableid ? 'update-table' : 'save-table'}`}
              defaultMessage={`${params.tableid ? 'Update Table' : 'Save Table'}`}
            />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
