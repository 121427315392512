import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Input, Row } from 'reactstrap';

export const MediaLibraryEditImageProperties = ({
  image,
  mediaItem,
  handleSaveMediaItemConfig,
  handleCloseMediaItemConfig
}) => {
  const [internalMediaItem, setInternalMediaItem] = useState({
    source: image.id,
    clickBehavior: 'lightbox',
    linkTarget: '',
    lightbox: true,
    ...mediaItem
  });

  useEffect(() => {
    setInternalMediaItem({
      source: image.id,
      clickBehavior: 'lightbox',
      linkTarget: '',
      lightbox: true,
      ...mediaItem
    });

    return () => {
      setInternalMediaItem();
    };
  }, [image, mediaItem]);

  const handleOnClickChange = useCallback(e => {
    const value = e.target.value;
    setInternalMediaItem(s =>
      produce(s, d => {
        d.clickBehavior = value;

        if (value === 'lightbox') {
          d.lightbox = true;
        } else {
          d.lightbox = false;
        }
      })
    );
  }, []);

  const handleLinkTargetChange = useCallback(e => {
    const value = e.target.value;
    setInternalMediaItem(s =>
      produce(s, d => {
        d.linkTarget = value;
      })
    );
  }, []);

  return (
    <>
      <Row className='mt-4'>
        <Col>
          <h6>Image Settings</h6>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col md={2} className='media-library-edit-image-container'>
          <img
            src={image?.local?.thumbnailUrl + '?' + new Date().getTime()}
            alt={`${image?.originalName}`}
            title={image?.originalName}
            width={'100%'}
          />
        </Col>
        <Col md={4}>
          <b>On click</b>
          <br />
          <Input
            type={'select'}
            id={'onClick'}
            name={'onClick'}
            value={internalMediaItem?.clickBehavior ?? 'lightbox'}
            onChange={handleOnClickChange}
          >
            <option value={'none'}>no click behavior</option>
            <option value={'lightbox'}>Lightbox (image zoom)</option>
            <option value={'link'}>Link</option>
          </Input>
        </Col>
        {internalMediaItem?.clickBehavior === 'link' && (
          <Col>
            <b>Destination</b>
            <br />
            <Input
              value={internalMediaItem?.linkTarget ?? ''}
              placeholder={'enter link...'}
              onChange={handleLinkTargetChange}
            />
          </Col>
        )}
        <Col>
          <b> </b>
          <br />
          <Button color='grey' onClick={handleCloseMediaItemConfig} size={'sm'}>
            <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
          </Button>
          <Button
            color='primary'
            className='ml-2'
            size={'sm'}
            onClick={() => {
              handleSaveMediaItemConfig(internalMediaItem);
              handleCloseMediaItemConfig();
            }}
          >
            save
          </Button>
        </Col>
      </Row>
    </>
  );
};
