import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { getMediaElementType } from './helpers/getMediaElementType';

export const MediaLibraryElementToUpload = ({ element, idx, handleRemoveFilesToUpload }) => {
  const url = URL.createObjectURL(element);
  const media = useSelector(state => state.survey.media ?? {});

  const elementType = getMediaElementType(element);

  const checkForDuplicate = useCallback(
    name => {
      return Object.values(media).find(el => el.originalName === name);
    },
    [media]
  );

  const isDuplicate = checkForDuplicate(element.name);

  return (
    <div
      className={`media-library-upload-stage__item ${isDuplicate ? 'media-library-upload-stage__item--duplicate' : ''}`}
      key={`${element.name}_${idx}`}
    >
      {elementType === 'image' && <img src={url} alt={element.name} />}
      {elementType === 'audio' && (
        <div className='av-media d-flex flex-column align-items-center justify-content-center'>
          <i className={`far fa-2x fa-file-audio `} />
          <div className='av-media-name' title={element.name}>
            {element.name}
          </div>
        </div>
      )}
      {elementType === 'video' && (
        <div className='av-media d-flex flex-column align-items-center justify-content-center'>
          <i className={`far fa-2x fa-file-video `} />
          <div className='av-media-name' title={element.name}>
            {element.name}
          </div>
        </div>
      )}
      {isDuplicate && (
        <span className='duplicate-image-warning' id={`tt_upload_img_${idx}`}>
          <i className='fal fa-2x fa-triangle-exclamation' />
          <UncontrolledTooltip target={`tt_upload_img_${idx}`} delay={{ hide: 200 }} autohide={false}>
            An element with the the name {element.name} already exists and will be overwritten!
          </UncontrolledTooltip>
        </span>
      )}
      <Button
        className='image-remove-btn'
        size={'sm'}
        color={'danger'}
        onClick={() => {
          handleRemoveFilesToUpload(element);
        }}
      >
        <i className='far fa-trash-alt' />
      </Button>
    </div>
  );
};
