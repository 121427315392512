import { useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { useSubelementPropertyTranslation } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { reorderArray } from 'smv-helpers/reorderArray';

export const SplitElementItems = ({ splitelement, handler }) => {
  const elementsQuestion = useSelector(state => state.survey.questionnaire.list);
  const elementsCalcvar = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const elements = useMemo(
    () => ({
      question: elementsQuestion,
      sysvar: systemVariables.list,
      calcvar: elementsCalcvar
    }),
    [elementsQuestion, systemVariables.list, elementsCalcvar]
  );

  const { sourceType, sourceId } = splitelement;

  const sourceElement =
    sourceType === 'calcvar'
      ? elements?.calcvar?.find(element => sourceId === element.id)
      : elements?.[sourceType]?.[sourceId];

  let sourceElementValues;
  if (sourceType === 'question') {
    sourceElementValues = getMergedChoices(sourceElement, sourceElement?.config?.choices, elements.question);
  } else if (sourceType === 'sysvar') {
    sourceElementValues = { order: sourceElement.order ?? [], list: sourceElement.list ?? {} };
  } else if (sourceType === 'calcvar') {
    sourceElementValues = {
      order: sourceElement?.values?.map(el => el.id),
      list: Object.fromEntries(sourceElement?.values?.map(value => [value.id, value]))
    };
  }

  const _itemOrder = useMemo(() => {
    return (
      (splitelement?.config?.sortation?.manualHeadSortation?.order?.length > 0
        ? splitelement.config.sortation?.manualHeadSortation?.order
        : sourceElementValues?.order) ?? []
    );
  }, [splitelement, sourceElementValues?.order]);

  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "#829ec9" : "#fafafa",
  });

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorderArray(_itemOrder, result.source.index, result.destination.index);
    handler.setSplitElementItemOrder(newOrder);
  };

  const items = _itemOrder.map((headelementid, idx) => {
    const isHidden = splitelement.config?.hidden?.[headelementid] ?? false;

    return (
      <SplitElementItem
        key={headelementid}
        index={idx}
        item={sourceElementValues.list[headelementid]}
        isHidden={isHidden}
        handler={handler}
      />
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='split-element-item-list-droppable'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className='droppable-default'
          >
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const SplitElementItem = ({ item, handler, isHidden, index }) => {
  const label = useSubelementPropertyTranslation(item, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const toggleHidden = useCallback(
    id => {
      handler.setSplitElementHiddenItems(id);
    },
    [handler]
  );

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // margin: '4px',
    // background: isDragging ? "#8aa91b" : "#e5e5e5",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  if (!item) return <span>-</span>;

  return (
    <Draggable draggableId={item?.id ?? item?.iso3} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className={`draggable-default split-item ${isHidden ? 'split-item--hidden' : ''}`}
        >
          <span>
            <i className='fal fa-grip-vertical mx-2'></i>
            {label}
          </span>
          <span onClick={() => toggleHidden(item.id)}>
            <i className={`pointer mr-2 fal ${isHidden ? 'fa-eye-slash' : 'fa-eye'}`} />
          </span>
          {item.content}
        </div>
      )}
    </Draggable>
  );
};
