import React from 'react';
import { useIntl } from 'react-intl';

import { TextInputRow } from 'smv-components';

export const Email = ({ state, onChange }) => {
  const intl = useIntl();

  return (
    <TextInputRow
      name={'email'}
      title={intl.formatMessage({ id: 'core.page.login.email.label', defaultMessage: 'Email' })}
      value={state.email}
      placeholder={intl.formatMessage({
        id: 'core.page.login.email.placeholder',
        defaultMessage: 'Enter email'
      })}
      change={onChange}
      required
      autoFocus
    />
  );
};
