import React from 'react';

import { QuestionText } from '../components';
import { FormattedMessage } from 'react-intl';

export const MultipleInputText = ({ question, writeable }) => {
  const { inputCount = 1 } = question?.config ?? {};

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <textarea
        className={'form-control que-input-field que-input-field--multiline'}
        style={{ resize: 'none' }}
        rows={3}
        disabled={true}
        placeholder={'multiline text input...'}
      />
      {inputCount > 1 && (
        <span className={'small font-italic'}>
          <FormattedMessage
            id={`smoove.questionnaire.modal.config-tab.displayed-x-times`}
            defaultMessage={`Displayed ${inputCount} times `}
            values={{ cnt: inputCount }}
          />
        </span>
      )}
    </>
  );
};
