import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, UncontrolledTooltip } from 'reactstrap';

import { ConfirmAction } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

export const EditLaunchedSurveyToggle = ({ questionnaireHandler, isEditLaunchedSurveyEnabled }) => {
  const surveyStatus = useSelector(state => state?.survey?.status);

  const isUserProjectAdmin = useUserHasPermission(permissionEnum.PROJECT_ADMIN);

  const intl = useIntl();

  if (!['running', 'completed'].includes(surveyStatus) || !isUserProjectAdmin) return null;

  return (
    <ConfirmAction
      size={'lg'}
      title={intl.formatMessage({ id: `smoove.questionnaire.options.toggle-edit-launched.confirm-title` })}
      body={<div>{intl.formatMessage({ id: `smoove.questionnaire.options.toggle-edit-launched.confirm-text` })}</div>}
      btnText={intl.formatMessage({ id: `smoove.questionnaire.options.toggle-edit-launched.confirm-button` })}
      callback={questionnaireHandler.toggleIsEditLaunchedSurvey}
    >
      {confirm => (
        <>
          <Button
            outline={true}
            color={'secondary'}
            size={'sm'}
            onClick={isEditLaunchedSurveyEnabled ? questionnaireHandler.toggleIsEditLaunchedSurvey : confirm}
            className='mr-2'
            id={'tt_enable-editing'}
            style={{ marginTop: '1px', height: '30px' }}
          >
            <i className={`far fa-lock${isEditLaunchedSurveyEnabled ? '-open' : ''}`}></i>
          </Button>
          <UncontrolledTooltip target={`tt_enable-editing`} delay={{ hide: 0 }} autohide={false}>
            {isEditLaunchedSurveyEnabled
              ? intl.formatMessage({ id: `smoove.questionnaire.options.toggle-edit-launched.label-active` })
              : intl.formatMessage({ id: `smoove.questionnaire.options.toggle-edit-launched.label-inactive` })}
          </UncontrolledTooltip>
        </>
      )}
    </ConfirmAction>
  );
};
