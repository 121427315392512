import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';

export const ReadOnlyAccessIconAndTooltip = () => {
  const tooltipId = useMemo(() => nanoid(), []);
  const intl = useIntl();

  return (
    <>
      <i
        className='fal fa-user-lock d-flex align-items-center project-list-element__readonly-icon'
        id={`tt_${tooltipId}_readonly`}
      />
      <UncontrolledTooltip target={`tt_${tooltipId}_readonly`} delay={{ hide: 0 }} autohide={false}>
        {intl.formatMessage({ id: 'smoove.page.access.you-have-read-only-access-to-this-project' })}
      </UncontrolledTooltip>
    </>
  );
};
