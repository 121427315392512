import { getRowOrder, getSubelementPropertyTranslation, useGetSourceElement, useTranslationHooks } from 'smv-helpers';
import {
  getConditionalFormat,
  getHighestHeadBase,
  useDefaultMetric
} from 'src/smoove/components/project-results/ResultTable/result-rows/helper';
import { getHeadBaseValue } from '../helper';
import { ResultTableChartCell } from './ResultTableChartCell';

export const ResultTableChartRowTransposed = ({
  head,
  table,
  tableResult,
  chartConfig,
  matrixType,
  isCompactMatrix,
  hover
}) => {
  const getSourceElement = useGetSourceElement();
  const translationProps = useTranslationHooks();
  const { elementValues: elementValuesHead, elementTitle } = getSourceElement(head);
  const { hidden = {}, excluded = {} } = head.config;

  const defaultMetric = useDefaultMetric({ config: table.config });
  const { showCodeValuesHead = false } = table.config;
  const { showBase = true } = chartConfig;

  const tableRows = [];

  tableResult?.headOrder?.[head.id]?.forEach(headOrderItem => {
    const isHiddenOrExcluded =
      hidden?.[headOrderItem.headSubId] === true || excluded?.[headOrderItem.headSubId] === true;
    if (isHiddenOrExcluded) return null;

    if (head.config?.autoHideLowBases ?? false) {
      const autoHideLowBasesHurdle = head.config?.autoHideLowBasesHurdle ?? 0;
      const highestBase = getHighestHeadBase(tableResult, head.id, headOrderItem.headSubId, {
        isDetailedMatrix: matrixType === 'detail',
        excludeHidden: true
      });
      if (highestBase <= autoHideLowBasesHurdle) return null;
    }

    const rowColumns = [];

    const label = getSubelementPropertyTranslation(
      elementValuesHead.list[headOrderItem.headSubId],
      null,
      {
        showCodeValue: showCodeValuesHead,
        useShortcodes: true,
        useStripped: true,
        useFallback: true
      },
      translationProps
    );

    rowColumns.push(<th key={`row-head-${headOrderItem.headSubId}`}>{label}</th>);

    if (showBase) {
      rowColumns.push(
        <td key={`base-cell-total`} className={'small font-italic'}>
          {getHeadBaseValue(tableResult, head.id, headOrderItem.headSubId, 'smvrslt_base')}
        </td>
      );
    }

    table.rows.order.forEach(rowId => {
      const row = table.rows.list[rowId];
      const { rowOrder: headOrder } = getRowOrder(tableResult?.order, row);

      let metric = defaultMetric;
      if (row.sourceType === 'calculation' || head.sourceType === 'calculation') metric = 'smvrslt_calculation';

      headOrder.forEach(orderItem => {
        const isHidden = orderItem?.hidden ?? false;
        const isExcluded = orderItem?.excluded ?? false;

        if (isHidden || isExcluded) return null;

        const applyConditionalFormat = getConditionalFormat({
          conditionalFormats: chartConfig.conditionalFormats,
          tableResult,
          rowId,
          rowSubId: orderItem.subelement,
          rowSubSubId: orderItem.subsubelement,
          headId: head.id,
          headSubId: headOrderItem.headSubId,
          metric: metric,
          defaultMetric
        });

        rowColumns.push(
          <ResultTableChartCell
            key={`cell_${orderItem.rowid}_${[orderItem.subelement, orderItem.subsubelement].filter(Boolean).join('_')}`}
            cell={getResultCell(
              tableResult,
              orderItem.rowid,
              orderItem.subelement,
              orderItem?.subsubelement,
              head.id,
              headOrderItem.headSubId
            )}
            metric={metric}
            config={table.config}
            cellIdentifier={{
              rowId: orderItem.rowid,
              rowSubId: orderItem.subelement,
              rowSubSubId: orderItem?.subsubelement ?? null,
              headId: head.id,
              headSubId: headOrderItem.headSubId
            }}
            hover={hover}
            significancesShow={chartConfig?.significancesShow ?? false}
            conditionalFormat={applyConditionalFormat}
          />
        );
      });
    });

    tableRows.push(rowColumns);
  });

  if (tableRows.length > 0) {
    return tableRows.map((tableRow, tableRowIndex) => {
      return (
        <tr key={`table-row-${head.id}-${tableRowIndex}`} className={'result__row'}>
          {tableRowIndex === 0 && chartConfig.showQuestionTitleColumn && (
            <th rowSpan={tableRows.length}>{elementTitle}</th>
          )}
          {tableRow}
        </tr>
      );
    });
  }

  return null;
};

const getResultCell = (tableResult, rowId, rowSubId, rowSubSubId, headId, headSubId) => {
  if (rowSubSubId) return tableResult?.values?.[rowId]?.[rowSubId]?.[rowSubSubId]?.[headId]?.[headSubId];
  else return tableResult?.values?.[rowId]?.[rowSubId]?.[headId]?.[headSubId];
};
