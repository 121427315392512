import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Label, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { questionnaireActions } from 'smv-redux';

const hideSubmitCompleteSeconds = 65535;

/* @todo: this is a page config, the option should only be available, if the question has no page as a parent */

export const HideSubmitButtonSettings = ({ writeable, question }) => {
  const surveyid = useSelector(state => state.survey.id);
  const intl = useIntl();

  /*
   * hide forever => hideSubmitButtonForSeconds = 65535
   * hide for x seconds => hideSubmitButtonForSeconds > 0 && < 65535
   * dont hide => hideSubmitButtonForSeconds = 0
   */

  const handleUpdateQuestionnaireElement = useCallback(
    value => {
      questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
        config: { hideSubmitButtonForSeconds: value }
      });
    },
    [surveyid, question.id]
  );

  const [hideSubmitButtonForSecondsInputValue, setHideSubmitButtonForSecondsInputValue] = useState(
    question.config?.hideSubmitButtonForSeconds
  );

  const toggleHideSubmitCompletely = useCallback(() => {
    const isHidden = question.config?.hideSubmitButtonForSeconds === hideSubmitCompleteSeconds;
    const newValue = isHidden ? 0 : hideSubmitCompleteSeconds;
    handleUpdateQuestionnaireElement(newValue);
  }, [question.config?.hideSubmitButtonForSeconds, handleUpdateQuestionnaireElement]);

  let isSecondToggleActive = false;
  if (
    question.config?.hideSubmitButtonForSeconds > 0 &&
    question.config?.hideSubmitButtonForSeconds < hideSubmitCompleteSeconds
  )
    isSecondToggleActive = true;

  const toggleIsHideSubmitButtonForSecondsActive = useCallback(() => {
    if (question.config?.hideSubmitButtonForSeconds === hideSubmitCompleteSeconds) {
      handleUpdateQuestionnaireElement(10);
    } else if (
      question.config?.hideSubmitButtonForSeconds > 0 &&
      question.config?.hideSubmitButtonForSeconds < hideSubmitCompleteSeconds
    ) {
      handleUpdateQuestionnaireElement(0);
    } else if (question.config?.hideSubmitButtonForSeconds === 0) {
      handleUpdateQuestionnaireElement(10);
    }
  }, [question.config?.hideSubmitButtonForSeconds, handleUpdateQuestionnaireElement]);

  useEffect(() => {
    setHideSubmitButtonForSecondsInputValue(question.config?.hideSubmitButtonForSeconds);
  }, [question.config?.hideSubmitButtonForSeconds]);

  return (
    <Row>
      <Col sm={'6'} lg={'5'} className='d-flex align-items-center'>
        <FormattedMessage
          id={`smoove.questionnaire.modal.config-tab.hide-submit.label`}
          defaultMessage={'Hide submit button'}
        />
        <i className='fal fa-circle-info ml-2' id={`tt_hide_submit`} />
        <UncontrolledTooltip target={`tt_hide_submit`} delay={{ hide: 200 }} autohide={false}>
          <FormattedMessage
            id={`smoove.questionnaire.modal.config-tab.hide-submit.label-tooltip`}
            defaultMessage={'Hides the submit button completely or for the specified number of seconds.'}
          />
        </UncontrolledTooltip>
      </Col>
      <Col sm={'6'} lg={'7'} className={'d-flex flex-column'}>
        <div className='d-flex flex-row'>
          <FormGroup switch>
            <Input
              disabled={!writeable}
              id={`config_hide_submitbutton_complete_toggle_id_${question.id}`}
              type='switch'
              role='switch'
              onChange={toggleHideSubmitCompletely}
              checked={question.config?.hideSubmitButtonForSeconds === hideSubmitCompleteSeconds ? true : false}
              className={'mr-1'}
            />
            <Label className='mb-0' for={`config_hide_submitbutton_complete_toggle_id_${question.id}`}>
              {intl.formatMessage({
                id: 'smoove.questionnaire.modal.config-tab.hide-submit.completely',
                defaultMessage: 'completely'
              })}
            </Label>
          </FormGroup>
        </div>
        <div className='d-flex flex-row align-items-center'>
          <FormGroup switch>
            <Input
              disabled={!writeable}
              id={`config_hide_submitbutton_toggle_id_${question.id}`}
              type='switch'
              role='switch'
              onChange={toggleIsHideSubmitButtonForSecondsActive}
              checked={isSecondToggleActive}
              className={'mr-1'}
            />
            <Label className='mb-0' for={`config_hide_submitbutton_toggle_id_${question.id}`}>
              {intl.formatMessage({
                id: 'smoove.questionnaire.modal.config-tab.hide-submit.period',
                defaultMessage: 'for time period of'
              })}
            </Label>
          </FormGroup>

          {isSecondToggleActive && (
            <>
              <Input
                id={`config_hide_submitbutton_id_${question.id}`}
                type={'number'}
                min={1}
                max={999}
                style={{ width: '64px', marginLeft: '0.24rem' }}
                bsSize={'sm'}
                disabled={!writeable}
                value={hideSubmitButtonForSecondsInputValue}
                onChange={e => setHideSubmitButtonForSecondsInputValue(e.target.value)}
                onBlur={e => handleUpdateQuestionnaireElement(e.target.value)}
              />
              <span className='ml-2'>
                <FormattedMessage
                  id={`smoove.questionnaire.modal.config-tab.hide-submit.seconds`}
                  defaultMessage={'seconds'}
                />
              </span>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};
