import React from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { generatePath, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ElementSwitch, ElementTitle, ElementCollapseIcon } from '.';
import { ConfirmDelete } from './ConfirmDelete';

import './Page.scss';

export const Page = ({ page, language, isCollapsed, handler, writeable, questionnaire, parentsIds, surveyId }) => {
  const { path } = useRouteMatch();
  const routeParams = useParams();

  // const level = page.path.length + 1;
  const level = 0;
  const smooveLevelClass = level > 0 ? `smoove-level-${level}` : '';

  return (
    <div id={'_' + page.id} className={'smoove-page-container ' + smooveLevelClass}>
      <div className={`smoove-page-container__group ${smooveLevelClass}`}>
        <ElementCollapseIcon toggleCollapse={handler.toggleCollapse} isCollapsed={isCollapsed} />
        <ElementTitle element={page} className='page__title' writeable={writeable} />

        <>
          <Link to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: page.id })}>
            <i id={`btn_edit_${page.id}`} className='far fa-edit icon-smv-blue p-1' />
          </Link>
          <UncontrolledTooltip target={`btn_edit_${page.id}`} delay={{ show: 500, hide: 200 }}>
            <FormattedMessage id={`smoove.common.buttons.edit-page`} defaultMessage={'configure page element'} />
          </UncontrolledTooltip>

          {/* copy icon */}
          {writeable && (
            <>
              <i
                id={`btn_copy_${page.id}`}
                className='far fa-clone icon-smv-blue p-1'
                onClick={handler.duplicateQuestionnaireElement}
              />
              <UncontrolledTooltip target={`btn_copy_${page.id}`} delay={{ show: 500, hide: 200 }}>
                <FormattedMessage id={`smoove.common.buttons.copy-page`} defaultMessage={'copy page'} />
              </UncontrolledTooltip>
            </>
          )}

          {writeable && <ConfirmDelete element={page} />}
        </>
      </div>

      <Collapse isOpen={isCollapsed} timeout={600}>
        <div className={`smoove-page-container__collapse level-${level}`}>
          {page.children.map((id, index) => (
            <ElementSwitch
              key={id}
              index={index}
              language={language}
              elementid={id}
              surveyId={surveyId}
              writeable={writeable}
              questionnaire={questionnaire}
              parentsIds={[...parentsIds, page.parentId]}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

Page.propTypes = {
  page: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  handler: PropTypes.object.isRequired
};
