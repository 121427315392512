export const surveyActionTypes = {
  ADD_SURVEY_REQUEST: 'ADD_SURVEY_REQUEST',
  ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS',
  ADD_SURVEY_FAILURE: 'ADD_SURVEY_FAILURE',

  UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST',
  UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
  UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

  REORDER_SURVEY_REPORTS_REQUEST: 'REORDER_SURVEY_REPORTS_REQUEST',
  REORDER_SURVEY_REPORTS_SUCCESS: 'REORDER_SURVEY_REPORTS_SUCCESS',
  REORDER_SURVEY_REPORTS_FAILURE: 'REORDER_SURVEY_REPORTS_FAILURE',

  DUPLICATE_SURVEY_REQUEST: 'DUPLICATE_SURVEY_REQUEST',
  DUPLICATE_SURVEY_SUCCESS: 'DUPLICATE_SURVEY_SUCCESS',
  DUPLICATE_SURVEY_FAILURE: 'DUPLICATE_SURVEY_FAILURE',

  REMOVE_SURVEY_REQUEST: 'REMOVE_SURVEY_REQUEST',
  REMOVE_SURVEY_SUCCESS: 'REMOVE_SURVEY_SUCCESS',
  REMOVE_SURVEY_FAILURE: 'REMOVE_SURVEY_FAILURE',

  LOAD_SURVEY_REQUEST: 'LOAD_SURVEY_REQUEST',
  LOAD_SURVEY_SUCCESS: 'LOAD_SURVEY_SUCCESS',
  LOAD_SURVEY_FAILURE: 'LOAD_SURVEY_FAILURE',

  SWITCH_SURVEY: 'SWITCH_SURVEY',

  LAUNCH_SURVEY_REQUEST: 'LAUNCH_SURVEY_REQUEST',
  LAUNCH_SURVEY_SUCCESS: 'LAUNCH_SURVEY_SUCCESS',
  LAUNCH_SURVEY_FAILURE: 'LAUNCH_SURVEY_FAILURE',

  START_TEST_SURVEY_REQUEST: 'START_TEST_SURVEY_REQUEST',
  START_TEST_SURVEY_SUCCESS: 'START_TEST_SURVEY_SUCCESS',
  START_TEST_SURVEY_FAILURE: 'START_TEST_SURVEY_FAILURE',

  GENERATE_DUMMY_DATA_REQUEST: 'GENERATE_DUMMY_DATA_REQUEST',
  GENERATE_DUMMY_DATA_SUCCESS: 'GENERATE_DUMMY_DATA_SUCCESS',
  GENERATE_DUMMY_DATA_FAILURE: 'GENERATE_DUMMY_DATA_FAILURE',

  SAVE_SURVEY_ELEMENT_REQUEST: 'SAVE_SURVEY_ELEMENT_REQUEST',
  SAVE_SURVEY_ELEMENT_SUCCESS: 'SAVE_SURVEY_ELEMENT_SUCCESS',
  SAVE_SURVEY_ELEMENT_FAILURE: 'SAVE_SURVEY_ELEMENT_FAILURE',

  DELETE_SURVEY_ELEMENT_REQUEST: 'DELETE_SURVEY_ELEMENT_REQUEST',
  DELETE_SURVEY_ELEMENT_SUCCESS: 'DELETE_SURVEY_ELEMENT_SUCCESS',
  DELETE_SURVEY_ELEMENT_FAILURE: 'DELETE_SURVEY_ELEMENT_FAILURE',

  MOVE_SURVEY_ELEMENT_REQUEST: 'MOVE_SURVEY_ELEMENT_REQUEST',
  MOVE_SURVEY_ELEMENT_SUCCESS: 'MOVE_SURVEY_ELEMENT_SUCCESS',
  MOVE_SURVEY_ELEMENT_FAILURE: 'MOVE_SURVEY_ELEMENT_FAILURE',

  SAVE_SURVEY_SUBELEMENT_REQUEST: 'SAVE_SURVEY_SUBELEMENT_REQUEST',
  SAVE_SURVEY_SUBELEMENT_SUCCESS: 'SAVE_SURVEY_SUBELEMENT_SUCCESS',
  SAVE_SURVEY_SUBELEMENT_FAILURE: 'SAVE_SURVEY_SUBELEMENT_FAILURE',

  DELETE_SURVEY_SUBELEMENT_REQUEST: 'DELETE_SURVEY_SUBELEMENT_REQUEST',
  DELETE_SURVEY_SUBELEMENT_SUCCESS: 'DELETE_SURVEY_SUBELEMENT_SUCCESS',
  DELETE_SURVEY_SUBELEMENT_FAILURE: 'DELETE_SURVEY_SUBELEMENT_FAILURE',

  MOVE_SURVEY_SUBELEMENT_REQUEST: 'MOVE_SURVEY_SUBELEMENT_REQUEST',
  MOVE_SURVEY_SUBELEMENT_SUCCESS: 'MOVE_SURVEY_SUBELEMENT_SUCCESS',
  MOVE_SURVEY_SUBELEMENT_FAILURE: 'MOVE_SURVEY_SUBELEMENT_FAILURE',

  SAVE_SURVEY_TRANSLATION_REQUEST: 'SAVE_SURVEY_TRANSLATION_REQUEST',
  SAVE_SURVEY_TRANSLATION_SUCCESS: 'SAVE_SURVEY_TRANSLATION_SUCCESS',
  SAVE_SURVEY_TRANSLATION_FAILURE: 'SAVE_SURVEY_TRANSLATION_FAILURE',

  UPLOAD_MEDIA_REQUEST: 'UPLOAD_MEDIA_REQUEST',
  UPLOAD_MEDIA_SUCCESS: 'UPLOAD_MEDIA_SUCCESS',
  UPLOAD_MEDIA_FAILURE: 'UPLOAD_MEDIA_FAILURE',

  DELETE_MEDIA_REQUEST: 'DELETE_MEDIA_REQUEST',
  DELETE_MEDIA_SUCCESS: 'DELETE_MEDIA_SUCCESS',
  DELETE_MEDIA_FAILURE: 'DELETE_MEDIA_FAILURE',

  RUN_DATA_CALCULATIONS_REQUEST: 'RUN_DATA_CALCULATIONS_REQUEST',
  RUN_DATA_CALCULATIONS_SUCCESS: 'RUN_DATA_CALCULATIONS_SUCCESS',
  RUN_DATA_CALCULATIONS_FAILURE: 'RUN_DATA_CALCULATIONS_FAILURE'
};
