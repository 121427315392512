import React from 'react';
import { useSelector } from 'react-redux';

import { isLooped } from 'smv-helpers';
import { ResultTableChartBaseRow } from '.';
import {
  ResultTableChartDefaultResultRow,
  ResultTableChartMatrixCompactResultRow,
  ResultTableChartMatrixDetailResultRow,
  ResultTableChartCalcVarResultRow,
  ResultTableChartSystemVarResultRow,
  ResultTableChartCalculationResultRow
} from './result-rows';

export const ResultTableChartBody = ({
  rowsRef,
  chartConfig,
  table,
  tableResult,
  isEmpty,
  matrixType,
  isCompactMatrix,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire?.list ?? {});
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const { rows, config } = table;
  const head = table.heads;

  const showChartBase = chartConfig.showBase === undefined ? true : chartConfig.showBase;

  return (
    <tbody ref={rowsRef} className={`smv-result-table__body`}>
      {!isEmpty && showChartBase && (
        <ResultTableChartBaseRow
          config={config}
          list={list}
          rows={rows}
          heads={head}
          tableResult={tableResult}
          matrixType={matrixType}
          isCompactMatrix={isCompactMatrix}
          isDetailedMatrix={matrixType === 'detail'}
          chartConfig={chartConfig}
        />
      )}
      {/* {!isEmpty && chartConfig.showBaseWeighted && (
        <ResultTableChartBaseRow
          config={config}
          list={list}
          rows={rows}
          head={head}
          tableResult={tableResult}
          matrixType={matrixType}
        />
      )} */}

      {rows.order.map(rowId => {
        const row = rows.list[rowId];
        const { sourceType, sourceId } = row;

        const questionHas = {
          isLooped: false
        };

        if (sourceType === 'question') {
          const element = list[sourceId];

          const { isLoopedQuestion, loopParents } = isLooped(list, element);
          questionHas.isLooped = isLoopedQuestion;
          questionHas.loopParents = loopParents;

          if (matrixType === 'detail' && ['single_matrix', 'multiple_matrix'].includes(element.config.qtype)) {
            return (
              <ResultTableChartMatrixDetailResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                chartConfig={chartConfig}
                row={row}
                tableResult={tableResult}
                questionHas={questionHas}
                hover={hover}
              />
            );
          } else if (isCompactMatrix && ['single_matrix', 'multiple_matrix'].includes(element.config.qtype)) {
            return (
              <ResultTableChartMatrixCompactResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                chartConfig={chartConfig}
                row={row}
                tableResult={tableResult}
                questionHas={questionHas}
                hover={hover}
              />
            );
          } else {
            return (
              <ResultTableChartDefaultResultRow
                key={`result_row_${row.id}`}
                element={element}
                table={table}
                row={row}
                tableResult={tableResult}
                questionHas={questionHas}
                chartConfig={chartConfig}
                isCompactMatrix={isCompactMatrix}
                matrixType={matrixType}
                hover={hover}
              />
            );
          }
        } else if (sourceType === 'sysvar') {
          const element = systemVariables.list[sourceId];

          return (
            <ResultTableChartSystemVarResultRow
              key={`result_row_${row.id}`}
              row={row}
              element={element}
              table={table}
              chartConfig={chartConfig}
              tableResult={tableResult}
              isCompactMatrix={isCompactMatrix}
              questionHas={questionHas}
              matrixType={matrixType}
              hover={hover}
            />
          );
        } else if (sourceType === 'calcvar') {
          const element = calcVars?.find(el => el.id === sourceId);

          return (
            <ResultTableChartCalcVarResultRow
              key={`result_row_${row.id}`}
              row={row}
              element={element}
              table={table}
              chartConfig={chartConfig}
              tableResult={tableResult}
              isCompactMatrix={isCompactMatrix}
              questionHas={questionHas}
              matrixType={matrixType}
              hover={hover}
            />
          );
        } else if (sourceType === 'calculation') {
          return (
            <ResultTableChartCalculationResultRow
              key={`result_row_${row.id}`}
              row={row}
              matrixType={matrixType}
              table={table}
              tableResult={tableResult}
              isCompactMatrix={isCompactMatrix}
              chartConfig={chartConfig}
              hover={hover}
            />
          );
        }

        return null;
      })}
    </tbody>
  );
};
