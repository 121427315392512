import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShelfStatic } from '@mindline-analytics/shelftool';

import { shelfBackgrounds } from '../../../QuestionTypes/CustomShelf';

export const Shelf = ({ question }) => {
  const locales = useSelector(state => state.survey.locales.list);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const surveyMedia = useSelector(state => state.survey.media);

  const countryCode = useMemo(() => locales[selectedLocale].iso3, [locales, selectedLocale]);

  const { boards = [], config = {} } = useMemo(() => question.config.shelf?.[countryCode] ?? {}, [
    question.config.shelf,
    countryCode
  ]);

  const shelfBoards = useMemo(
    () =>
      boards.map(board =>
        board
          .map(productId => {
            const mediaItem = surveyMedia?.[productId] ?? false;
            if (mediaItem) {
              return { ...mediaItem, url: `${process.env.PUBLIC_URL}/${mediaItem.url}` };
            } else return false;
          })
          .filter(Boolean)
      ),
    [boards, surveyMedia]
  );

  const shelfConfig = {
    ...config,
    background: question.config?.shelf?.config?.background ?? config?.background,
    backgrounds: shelfBackgrounds
  };

  return <ShelfStatic shelfConfig={shelfConfig} shelfBoards={shelfBoards} testcells={[]} />;
};
