import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { customAlphabet } from 'nanoid';

import produce from 'immer';

import { FormulaBuilder } from '.';

export const FormulaBuilderButton = ({ toggle }) => {
  return (
    <Button id={`form-builder-modal-button`} onClick={toggle} size={'sm'} color={'secondary'} outline={true}>
      <FormattedMessage
        id={'smoove.page.tables.table-config.calculations.button-add'}
        defaultMessage={'add calculation'}
      />
    </Button>
  );
};

export const FormulaBuilderDropdownButton = ({ toggle }) => {
  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i className={'fal fa-cog icon-smv-blue mr-1'}></i>
      <span>{`settings`}</span>
    </div>
  );
};

export const FormulaBuilderModal = ({
  isOpen,
  toggle,
  tableElement,
  tableConfig,
  calculationMode: externalCalculationMode,
  handler
}) => {
  const [_calculation, setCalculation] = useState({
    id: tableElement?.id,
    list: {},
    order: [],
    title: '',
    ...(tableElement?.config ?? {})
  });
  const [calculationMode, setCalculationMode] = useState(externalCalculationMode ?? 'rows');

  useEffect(() => {
    setCalculation({ id: tableElement?.id, list: {}, order: [], title: '', ...(tableElement?.config ?? {}) });
  }, [tableElement]);

  const setCalculationModeRows = useCallback(() => {
    setCalculationMode(s => {
      if (s !== 'rows') {
        setCalculation({ list: {}, order: [], title: '' });
        return 'rows';
      } else {
        return s;
      }
    });
  }, []);
  const setCalculationModeHeads = useCallback(() => {
    setCalculationMode(s => {
      if (s !== 'heads') {
        setCalculation({ list: {}, order: [], title: '' });
        return 'heads';
      } else {
        return s;
      }
    });
  }, []);

  const saveHandler = useCallback(() => {
    setCalculation(({ id, ...calculation }) => {
      handler.setTableConfig(s =>
        produce(s, d => {
          let calculationId = tableElement?.id;
          if (!calculationId) {
            calculationId = customAlphabet('1234567890abcdef', 24)();
            d[calculationMode].order.push(calculationId);

            if (!d.config?.calculationOrder) d.config.calculationOrder = [];
            d.config.calculationOrder.push({
              source: calculationMode, // 'rows' | 'heads'
              elementId: calculationId // rowId | headId
            });
          }

          d[calculationMode].list[calculationId] = {
            id: calculationId,
            sourceType: 'calculation',
            config: {
              ...calculation
            }
          };
        })
      );
    });
    toggle();
  }, [handler, toggle, calculationMode, tableElement?.id]);

  if (!isOpen) return null;

  return (
    <Modal className='formula-builder-modal' isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>
        <span>
          {!externalCalculationMode
            ? `Add new row/ column calculation`
            : `Edit ${externalCalculationMode === 'rows' ? 'row' : 'column'} calculation`}
        </span>
      </ModalHeader>
      <ModalBody>
        {!externalCalculationMode && (
          <ButtonGroup>
            <Button active={calculationMode === 'rows'} onClick={setCalculationModeRows} outline>
              rows
              <i className='fa-duotone fa-table-rows'></i>
            </Button>
            <Button active={calculationMode === 'heads'} onClick={setCalculationModeHeads} outline>
              cols
              <i className='fa-duotone fa-table-columns'></i>
            </Button>
          </ButtonGroup>
        )}

        <FormulaBuilder
          tableConfig={tableConfig}
          calculationMode={calculationMode}
          calculation={_calculation}
          setCalculation={setCalculation}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        <Button color='primary' onClick={saveHandler}>
          <FormattedMessage defaultMessage={`Save`} id={`smoove.common.buttons.save`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const FormulaBuilderButtonAndModal = ({ tableConfig, handler, calculationMode = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <>
      <FormulaBuilderButton toggle={toggle} />
      {isOpen && (
        <FormulaBuilderModal
          toggle={toggle}
          isOpen={isOpen}
          tableConfig={tableConfig}
          handler={handler}
          calculationMode={calculationMode}
        />
      )}
    </>
  );
};
