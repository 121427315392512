import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { NewSurveyModal } from 'smv-components';

export const SurveyListElementNew = ({ listStyle, project }) => {
  const intl = useIntl();

  const [isNewSurveyModalOpen, setIsNewSurveyModalOpen] = useState(false);
  const toggleNewSurveyModal = useCallback(() => {
    setIsNewSurveyModalOpen(b => !b);
  }, []);

  if (listStyle === 'tiles') {
    return (
      <div className={'survey-list-element-tile'} onClick={toggleNewSurveyModal}>
        <div className='tile-image pointer'>
          <div className='new-survey-img-placeholder'>
            <i className='fal fa-plus fa-2x' />
          </div>
        </div>
        <div className='tile-description'>
          <div className='survey-list-element__title  survey-list-element__title--new py-2 pointer'>
            {intl.formatMessage({ id: `smoove.page.project.general.settings.add-survey-btn` })}
          </div>
        </div>
        <NewSurveyModal isOpen={isNewSurveyModalOpen} toggle={toggleNewSurveyModal} project={project} />
      </div>
    );
  } else if (listStyle === 'rows') {
    return (
      <div className={'survey-list-element-row survey-list-element-row--new'} onClick={toggleNewSurveyModal}>
        <div className='survey-list-element-row__left'>
          <div className='survey-list-element__title'>
            <i className='fal fa-plus mr-2' />
            <span>{intl.formatMessage({ id: `smoove.page.project.general.settings.add-survey-btn` })}</span>
          </div>
        </div>
        <NewSurveyModal isOpen={isNewSurveyModalOpen} toggle={toggleNewSurveyModal} project={project} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
