import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, Input, Label, FormGroup, Row, Button, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { isArray, isEqual } from 'lodash';
import produce from 'immer';
import { useSelector } from 'react-redux';

import { reportsActions } from 'src/smoove/redux/actions';
import { isNumeric, useGetSourceElement } from 'smv-helpers';
import { useResultTableType } from 'smv-helpers';

import { GeneralChartConfig } from '../../project-results/ChartConfig';
import { ChartAxisConfig } from '../../project-results/ChartConfig';
import { MediaImageConfig } from './MediaConfig';

import './ConfigDrawerRight.scss';

const INITIAL_STATE = {
  id: null,
  sourceType: null,
  chartConfig: {}
};

export const ConfigDrawerRight = ({ isOpen, report, page, element = null }) => {
  const [internalConfig, setInternalConfig] = useState(INITIAL_STATE);
  const surveyid = useSelector(state => state.survey.id);

  const tables = useSelector(state => state.survey.tables.list);

  const isModified = useMemo(() => {
    return (
      internalConfig.id !== null &&
      internalConfig.sourceId !== null &&
      (!isEqual(element?.splitConfig ?? {}, internalConfig?.splitConfig ?? {}) ||
        !isEqual(element?.filterConfig ?? {}, internalConfig?.filterConfig ?? {}) ||
        !isEqual(element?.chartConfig ?? {}, internalConfig?.chartConfig ?? {}) ||
        !isEqual(element?.mediaConfig ?? {}, internalConfig?.mediaConfig ?? {}))
    );
  }, [internalConfig, element]);

  const isTable = element?.sourceType === 'table' || element?.sourceType !== 'media';
  const { resultTableType } = useResultTableType(tables?.[element?.sourceId] ?? null);

  const saveHandler = useCallback(() => {
    reportsActions.updateDashboardElement(surveyid, report.id, page.id, internalConfig.id, internalConfig);
  }, [page.id, internalConfig, report.id, surveyid]);

  useEffect(() => {
    // save config on close, if anything has changed
    if (isModified && !isOpen) saveHandler();
  }, [saveHandler, isOpen, isModified]);

  useEffect(() => {
    setInternalConfig(state => {
      if (!element) return INITIAL_STATE;
      else {
        return produce(state, draft => {
          draft.id = element?.id;
          draft.sourceType = element?.sourceType ?? 'table';
          draft.chartConfig = element?.chartConfig ?? {};

          if (draft.sourceType === 'table') {
            draft.sourceId = element?.sourceId;
            draft.splitConfig = element?.splitConfig ?? {};
            draft.filterConfig = element?.filterConfig ?? {};
          } else if (draft.sourceType === 'media') {
            draft.mediaConfig = element?.mediaConfig ?? {};
          }
        });
      }
    });
  }, [element]);

  const internalHandler = useMemo(
    () => ({
      toggleCheckboxValue: e => {
        const { name, checked } = e.target;
        const [configSection, property] = name.split('.');

        if (!name.includes('.')) {
          console.error('`toggleCheckboxValue`: input name must contain a dot path like `table.showTotal`!');
          return;
        }

        if (!['mediaConfig', 'chartConfig'].includes(configSection)) {
          console.error(`\`toggleCheckboxValue\`: invalid config section '${configSection}'!`);
          return;
        }

        setInternalConfig(state =>
          produce(state, draft => {
            draft[configSection][property] = checked;
          })
        );
      },
      setConfigStringValue: e => {
        const { name, value } = e.currentTarget;

        const [configSection, property] = name?.split('.');
        if (!name.includes('.')) {
          console.error('`toggleCheckboxValue`: input name must contain a dot path like `table.showTotal`!');
          return;
        }

        if (!['mediaConfig', 'chartConfig'].includes(configSection)) {
          console.error(`\`toggleCheckboxValue\`: invalid config section '${configSection}'!`);
          return;
        }

        setInternalConfig(state =>
          produce(state, draft => {
            draft[configSection][property] = value;
          })
        );
      },
      setChartConfigValue: e => {
        const { name, value } = e.target;
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = value;
          })
        );
      },
      unsetChartConfigValue: name => {
        setInternalConfig(state =>
          produce(state, draft => {
            delete draft.chartConfig[name];
          })
        );
      },
      toggleSplit: splitid => {
        setInternalConfig(state =>
          produce(state, draft => {
            if (!draft?.splitConfig) draft.splitConfig = { enabledSplits: {} };
            if (!draft.splitConfig?.enabledSplits || isArray(draft.splitConfig.enabledSplits))
              draft.splitConfig.enabledSplits = {};
            // if (!draft.splitConfig.enabledSplits?.[splitid]) draft.splitConfig.enabledSplits[splitid] = true;
            // else delete draft.splitConfig.enabledSplits[splitid];
            if (!draft.splitConfig.enabledSplits?.[splitid]) draft.splitConfig.enabledSplits = { [splitid]: true };
            else delete draft.splitConfig.enabledSplits[splitid];
          })
        );
      },
      toggleDisabledFilter: filterId => {
        setInternalConfig(state =>
          produce(state, draft => {
            if (!draft?.filterConfig) draft.filterConfig = { disabledFilters: {} };
            if (!draft.filterConfig?.disabledFilters) draft.filterConfig.disabledFilters = {};
            if (!draft.filterConfig.disabledFilters?.[filterId]) draft.filterConfig.disabledFilters[filterId] = true;
            else delete draft.filterConfig.disabledFilters[filterId];
          })
        );
      },
      setColorScheme: e => {
        const { name, value } = e.target;
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = value;
          })
        );
      },
      setCheckboxValue: e => {
        const { name, checked } = e.target;
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === checked) delete draft.chartConfig[name];
            else {
              draft.chartConfig[name] = checked;
            }
          })
        );
      },
      setSelectValue: e => {
        const { name, value } = e.target;

        // convert pure number strings to number
        let _value;
        if (!isNaN(+value)) _value = +value;
        else _value = value;

        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = _value;
          })
        );
      },
      setChartDomainRange: e => {
        const { value, name } = e.target;
        setInternalConfig(state =>
          produce(state, draft => {
            const _value = isNumeric(value) ? parseFloat(value) : null;
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = _value;
          })
        );
      }
    }),
    [element]
  );

  if (!element) return null;

  return (
    <div className='reports-config-drawer'>
      <DashboardElementHeaderConfig
        internalConfig={internalConfig}
        internalHandler={internalHandler}
        element={element}
      />

      {isTable && (
        <ChartConfig
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          element={element}
          resultTableType={resultTableType}
        />
      )}

      {isTable && (
        <DynamicSplitSelect
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          report={report}
          element={element}
        />
      )}

      {isTable && (
        <FilterDisableSelect
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          report={report}
          element={element}
        />
      )}

      {element.sourceType === 'media' && (
        <MediaImageConfig internalConfig={internalConfig} internalHandler={internalHandler} element={element} />
      )}
    </div>
  );
};

const DashboardElementHeaderConfig = ({ internalConfig, internalHandler, element }) => {
  const intl = useIntl();
  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.chart-config.chart-header'} defaultMessage={'Chart header'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Label for='chartTitle' md={5}>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-title'} defaultMessage={'Chart title'} />
        </Label>
        <Col md={7}>
          <Input
            type={'text'}
            id={`chartTitle`}
            name={'title'}
            value={internalConfig?.chartConfig?.title ?? ''}
            placeholder={
              element?.table?.name ?? intl.formatMessage({ id: `smoove.page.tables.chart-config.enter-chart-title` })
            }
            onChange={internalHandler.setChartConfigValue}
          ></Input>
        </Col>
      </Row>

      <Row className='mt-1 align-items-center'>
        <Label for='chartSubtitle' md={5}>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-subtitle'} defaultMessage={'Chart subtitle'} />
        </Label>
        <Col md={7}>
          <Input
            type={'text'}
            id={`chartSubtitle`}
            name={'subTitle'}
            value={internalConfig?.chartConfig?.subTitle ?? ''}
            placeholder={'enter an optional subtitle...'}
            onChange={internalHandler.setChartConfigValue}
          ></Input>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

const ChartConfig = ({ internalConfig, internalHandler, element, resultTableType }) => {
  // const intl = useIntl();

  const checkProperties = useMemo(
    () => [
      'chartType',
      'chartOrientation',
      'chartSubtype',
      'colorSchema',
      'colorSchemaReverse',
      'tableTranspose',
      'innerRadius',
      'chartDomainAuto',
      'chartDomainMax',
      'chartDomainXAuto',
      'chartDomainXMin',
      'chartDomainXMax',
      'chartDomainYAuto',
      'chartDomainYMin',
      'chartDomainYMax',
      'axisYreversed',
      'axisYwidth',
      'dimensionsReversed',
      'showGrid',
      'showBase',
      'showQuestionTitleColumn'
    ],
    []
  );

  const isConfigModified = useMemo(
    () => Object.keys(internalConfig.chartConfig).some(k => checkProperties.includes(k)),
    [internalConfig, checkProperties]
  );

  const resetChanges = useCallback(() => checkProperties.forEach(k => internalHandler.unsetChartConfigValue(k)), [
    internalHandler,
    checkProperties
  ]);

  const currentChartConfig = useMemo(() => {
    return {
      chartType: internalConfig.chartConfig?.chartType ?? element?.table?.chart?.chartType ?? 'bar',
      chartOrientation:
        internalConfig.chartConfig?.chartOrientation ?? element?.table?.chart?.chartOrientation ?? 'horizontal',
      chartSubtype: internalConfig.chartConfig?.chartSubtype ?? element?.table?.chart?.chartSubtype ?? 'grouped',
      colorSchema: internalConfig?.chartConfig?.colorSchema ?? element?.table?.chart?.colorSchema,
      colorSchemaReverse:
        internalConfig?.chartConfig?.colorSchemaReverse ?? element?.table?.chart?.colorSchemaReverse ?? false,
      tableTranspose: internalConfig?.chartConfig?.tableTranspose ?? element?.table?.chart?.tableTranspose ?? false,
      // axis settings
      innerRadius: internalConfig?.chartConfig?.innerRadius ?? element?.table?.chart?.innerRadius ?? 0,
      chartDomainAuto: internalConfig?.chartConfig?.chartDomainAuto ?? element?.table?.chart?.chartDomainAuto ?? true,
      chartDomainMax: internalConfig?.chartConfig?.chartDomainMax ?? element?.table?.chart?.chartDomainMax ?? '',
      chartDomainXAuto:
        internalConfig?.chartConfig?.chartDomainXAuto ?? element?.table?.chart?.chartDomainXAuto ?? true,
      chartDomainXMin: internalConfig?.chartConfig?.chartDomainXMin ?? element?.table?.chart?.chartDomainXMin ?? true,
      chartDomainXMax: internalConfig?.chartConfig?.chartDomainXMax ?? element?.table?.chart?.chartDomainXMax ?? '',
      chartDomainYAuto:
        internalConfig?.chartConfig?.chartDomainYAuto ?? element?.table?.chart?.chartDomainYAuto ?? true,
      chartDomainYMin: internalConfig?.chartConfig?.chartDomainYMin ?? element?.table?.chart?.chartDomainYMin ?? '',
      chartDomainYMax: internalConfig?.chartConfig?.chartDomainYMax ?? element?.table?.chart?.chartDomainYMax ?? '',
      axisYreversed: internalConfig?.chartConfig?.axisYreversed ?? element?.table?.chart?.axisYreversed ?? false,
      axisYwidth: internalConfig?.chartConfig?.axisYwidth ?? element?.table?.chart?.axisYwidth ?? 60,
      dimensionsReversed:
        internalConfig?.chartConfig?.dimensionsReversed ?? element?.table?.chart?.dimensionsReversed ?? false,
      showGrid: internalConfig?.chartConfig?.showGrid ?? element?.table?.chart?.showGrid ?? false,
      showBase: internalConfig?.chartConfig?.showBase ?? element?.table?.chart?.showBase ?? true,
      showQuestionTitleColumn:
        internalConfig?.chartConfig?.showQuestionTitleColumn ?? element?.table?.chart?.showQuestionTitleColumn ?? false
    };
  }, [internalConfig, element]);

  const generalChartConfigHandler = useMemo(() => {
    return {
      setChartType: internalHandler.setChartConfigValue,
      setChartOrientation: internalHandler.setChartConfigValue,
      setChartSubtype: internalHandler.setChartConfigValue,
      setChartColorSchema: internalHandler.setColorScheme,
      setCheckboxValue: internalHandler.setCheckboxValue
    };
  }, [internalHandler]);

  const axisHandler = useMemo(() => {
    return {
      setChartDomainAuto: internalHandler.setCheckboxValue,
      setChartDomainRange: internalHandler.setChartDomainRange,
      setCheckboxValue: internalHandler.setCheckboxValue,
      setSelectValue: internalHandler.setSelectValue,
      setChartAxisYwidth: internalHandler.setSelectValue
    };
  }, [internalHandler]);

  // Todo: translations!!

  return (
    <>
      <GeneralChartConfig
        chartConfig={currentChartConfig}
        handler={generalChartConfigHandler}
        resultTableType={resultTableType}
      />
      <ChartAxisConfig chartConfig={currentChartConfig} handler={axisHandler} />
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button size={'sm'} color={'secondary'} onClick={resetChanges} disabled={!isConfigModified}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.reset-to-default'}
              defaultMessage={'Reset to default'}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

const DynamicSplitSelect = ({ internalConfig, internalHandler, report, element }) => {
  if (report?.splits?.order?.length <= 0) return null;

  return (
    <ListGroupItem className='result-config mt-4'>
      <ListGroupItemHeading>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.dynamic-splitting'}
          defaultMessage={'Dynamic splitting'}
        />
      </ListGroupItemHeading>
      <div>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.dynamic-splitting-hint'}
          defaultMessage={'Dynamic splitting-hint'}
        />
      </div>
      {report?.splits?.order?.map(splitid => {
        const split = report.splits.list[splitid];
        return (
          <Row key={splitid} className='mt-1 align-items-center'>
            <Col md={5}>{split?.label ?? 'unlabeled split'}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`enableSplit_${split.id}`}
                  name={`enableSplit_${split.id}`}
                  checked={
                    internalConfig.splitConfig?.enabledSplits?.[split.id] === true ??
                    element?.splitConfig?.enabledSplits?.[split.id] === true ??
                    false
                  }
                  onChange={() => internalHandler.toggleSplit(split.id)}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </ListGroupItem>
  );
};

const FilterDisableSelect = ({ internalConfig, internalHandler, report, element }) => {
  const intl = useIntl();
  const getSourceElement = useGetSourceElement();

  if (report?.filters?.order?.length <= 0) return null;

  return (
    <ListGroupItem className='result-config mt-4'>
      <ListGroupItemHeading>
        {intl.formatMessage({
          id: 'smoove.page.reports.dashboard-element-config.filter-disable-select.headline',
          defaultMessage: 'Disable filters'
        })}
      </ListGroupItemHeading>
      <div>
        {intl.formatMessage({
          id: 'smoove.page.reports.dashboard-element-config.filter-disable-select.hint',
          defaultMessage: 'disables selected filters for this element'
        })}
      </div>
      {report?.filters?.order?.map(filterId => {
        const filter = report.filters.list[filterId];
        const { elementTitle } = getSourceElement(filter);
        const label = filter?.label?.length > 0 ? filter.label : elementTitle;

        return (
          <Row key={filterId} className='mt-1 align-items-center'>
            <Col md={5}>{label}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`disableFilter_${filter.id}`}
                  name={`disableFilter_${filter.id}`}
                  checked={
                    internalConfig.filterConfig?.disabledFilters?.[filter.id] === true ??
                    element?.filterConfig?.disabledFilters?.[filter.id] === true ??
                    false
                  }
                  onChange={() => internalHandler.toggleDisabledFilter(filter.id)}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </ListGroupItem>
  );
};
