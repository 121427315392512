import React, { useMemo } from 'react';
import { Col, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

import { chartSettings } from '../config';
import { ConditionalFormattingTableButtonAndModal } from './modals';
export const ChartTableHighlightConfig = ({ tableConfig, chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.conditionalFormattingTable !== true) return null;

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            {intl.formatMessage({ id: `smoove.page.tables.chart-config.conditional-formatting-table.title` })}
          </Col>
          <Col md={7}>
            <ConditionalFormattingTableButtonAndModal
              tableConfig={tableConfig}
              chartConfig={chartConfig}
              handler={handler}
            />
          </Col>
        </Row>
      </ListGroupItemHeading>
    </ListGroupItem>
  );
};
