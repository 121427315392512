import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Input, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';

import { getRowOrder, useGetSourceElement, useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import produce from 'immer';
import { isEqual } from 'lodash';

export const ChartDimensionsConfig = ({ chartConfig, tableConfig, tableResult, resultTableType, handler }) => {
  const intl = useIntl();

  const getSourceElement = useGetSourceElement();
  const { isCompactMatrix } = useMatrixType(tableConfig);

  const colSources = useMemo(() => {
    const _colSources = {};

    let heads = tableConfig.heads;

    const valueStructureCheck = hasDuplicateValueStructure('heads', heads, getSourceElement, tableResult);

    if (isCompactMatrix) {
      heads = produce(heads, d => {
        if (isCompactMatrix) {
          tableConfig.rows.order.forEach(rowid => {
            if (tableConfig.rows.list[rowid].sourceType === 'question') {
              d.order = [rowid, ...d.order];
              d.list[rowid] = tableConfig.rows.list[rowid];
            }
          });
        }
      });
    }

    for (const headId of heads.order) {
      const head = heads.list[headId];
      const { element, elementValues } = getSourceElement(head);

      for (const orderItem of tableResult?.headOrder?.[headId] ?? []) {
        const isHidden = orderItem?.hidden ?? false;

        if (isHidden) continue;

        const subelement = elementValues.list[orderItem.headSubId];

        if (chartConfig.scatterDimensionsDimension === 'heads') {
          _colSources[`${headId}`] = {
            element,
            cellIdentifier: { headId }
          };
        } else {
          _colSources[`${headId}~${orderItem.headSubId}`] = {
            element,
            subelement,
            cellIdentifier: { headId, headSubId: orderItem.headSubId }
          };
        }
      }
    }

    Object.keys(_colSources).map(sourceKey => {
      return (_colSources[sourceKey]['duplicatedValueStructure'] =
        valueStructureCheck?.[_colSources[sourceKey].cellIdentifier.headId] ?? false);
    });

    return _colSources;
  }, [chartConfig.scatterDimensionsDimension, getSourceElement, tableConfig, tableResult, isCompactMatrix]);

  const rowSources = useMemo(() => {
    const _rowSources = {};

    const valueStructureCheck = hasDuplicateValueStructure('rows', tableConfig.rows, getSourceElement, tableResult);

    for (const rowId of tableConfig.rows.order) {
      const row = tableConfig.rows.list[rowId];
      const { element, elementValues, elementScales } = getSourceElement(row);

      const { rowOrder } = getRowOrder(tableResult?.order ?? [], row);

      for (const orderItem of rowOrder) {
        let sourceId;
        let subelement = null;
        let subsubelement = null;

        const isHidden = orderItem?.hidden ?? false;
        const isExcluded = orderItem?.excluded ?? false;

        if (isHidden || isExcluded) continue;

        if (orderItem?.subsubelement && tableResult?.tableStruct === 'matrix_detail') {
          subelement = elementValues.list[orderItem.subelement];
          subsubelement = elementScales.list[orderItem.subsubelement];
          sourceId = `${rowId}~${orderItem.subelement}~${orderItem.subsubelement}`;
        } else if (
          element.type === 'question' &&
          ['single_matrix', 'multiple_matrix'].includes(element.config.qtype) &&
          tableResult?.tableStruct === 'matrix_compact_single'
        ) {
          subelement = elementScales.list[orderItem.subelement];
          sourceId = `${rowId}~${orderItem.subelement}~${null}`;
        } else {
          subelement = elementValues.list[orderItem.subelement];
          sourceId = `${rowId}~${orderItem.subelement}~${null}`;
        }

        if (chartConfig.scatterDimensionsDimension === 'rows') {
          _rowSources[`${rowId}`] = {
            element,
            cellIdentifier: { rowId }
          };
        } else {
          _rowSources[`${sourceId}`] = {
            element,
            subelement,
            subsubelement,
            cellIdentifier: { rowId, rowSubId: orderItem.subelement, rowSubSubId: orderItem?.subsubelement ?? null }
          };
        }
      }
    }

    Object.keys(_rowSources).map(sourceKey => {
      return (_rowSources[sourceKey]['duplicatedValueStructure'] =
        valueStructureCheck?.[_rowSources[sourceKey].cellIdentifier.rowId] ?? false);
    });

    return _rowSources;
  }, [chartConfig.scatterDimensionsDimension, getSourceElement, tableConfig, tableResult]);

  const { selectedDimensionSources, subDimensionSources } = useMemo(() => {
    let selected = {};
    let sub = {};

    if (chartConfig?.scatterDimensionsDimension === 'allHeads' || chartConfig?.scatterDimensionsDimension === 'rows') {
      selected = rowSources;
      sub = colSources;
    } else if (
      chartConfig?.scatterDimensionsDimension === 'heads' ||
      chartConfig?.scatterDimensionsDimension === 'allRows'
    ) {
      selected = colSources;
      sub = rowSources;
    }

    if (['heads', 'rows'].includes(chartConfig.scatterDimensionsDimension)) {
      selected = Object.fromEntries(Object.entries(selected).filter(([, value]) => value.duplicatedValueStructure));
    }

    return { selectedDimensionSources: selected, subDimensionSources: sub };
  }, [chartConfig.scatterDimensionsDimension, rowSources, colSources]);

  const rowsDoHaveDuplicatedValueStructure =
    Object.keys(rowSources).length > 1
      ? Object.values(rowSources).some(item => item.duplicatedValueStructure === true) !== false
      : undefined;

  const colsDoHaveDuplicatedValueStructure =
    Object.keys(colSources).length > 1
      ? Object.values(colSources).some(item => item.duplicatedValueStructure === true) !== false
      : undefined;

  const ScatterDimensionsSubDimensionKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsSubDimension === null) {
      const firstKey = Object.keys(subDimensionSources)?.[0];
      return firstKey;
    } else if (
      isEqual(chartConfig?.scatterDimensionsSubDimension ?? {}, { headId: '__total__', headSubId: '__total__' })
    ) {
      return '__total__';
    } else if (!!chartConfig?.scatterDimensionsSubDimension) {
      return Object.values(chartConfig?.scatterDimensionsSubDimension ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsSubDimension, subDimensionSources]);

  const ScatterDimensionsXKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsX === null) {
      const firstKey = Object.keys(selectedDimensionSources)?.[0];
      return firstKey;
    } else if (isEqual(chartConfig?.scatterDimensionsX ?? {}, { headId: '__total__', headSubId: '__total__' })) {
      return '__total__';
    } else if (!!chartConfig?.scatterDimensionsX) {
      return Object.values(chartConfig?.scatterDimensionsX ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsX, selectedDimensionSources]);

  const ScatterDimensionsYKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsY === null) {
      const secondKey = Object.keys(selectedDimensionSources)?.[1];
      return secondKey;
    } else if (isEqual(chartConfig?.scatterDimensionsY ?? {}, { headId: '__total__', headSubId: '__total__' })) {
      return '__total__';
    } else if (!!chartConfig?.scatterDimensionsY) {
      return Object.values(chartConfig?.scatterDimensionsY ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsY, selectedDimensionSources]);

  const ScatterDimensionsLabelsKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsLabels === null) {
      return Object.keys(selectedDimensionSources)?.[0];
    } else if (!!chartConfig?.scatterDimensionsLabels) {
      return Object.values(chartConfig?.scatterDimensionsLabels ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsLabels, selectedDimensionSources]);

  useEffect(() => {
    // initial 'allHeads' (default logic) selection
    if (chartConfig?.scatterDimensionsDimension === undefined) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = 'allHeads';
        })
      );
    }

    // if the table has only one head switch to 'allHeads'(default logic)
    const oneHeadNoDuplicatedRows = Object.keys(colSources).length < 2 && rowsDoHaveDuplicatedValueStructure === false;
    // if 'heads' modus is on and heads do not have subelements with matching values anymore (due to activated sortation or removing from head elements)
    const headsActiveNoDuplicatedHeads =
      chartConfig?.scatterDimensionsDimension === 'heads' &&
      (colsDoHaveDuplicatedValueStructure === false || Object.keys(colSources).length === 1);
    // if 'rows' modus is on and rows do not have subelements with matching values anymore (due to activated sortation or removing from row elements)
    const rowsActiveNoDuplicatedRows =
      chartConfig?.scatterDimensionsDimension === 'rows' &&
      (rowsDoHaveDuplicatedValueStructure === false || Object.keys(rowSources).length === 1);

    if (oneHeadNoDuplicatedRows || headsActiveNoDuplicatedHeads || rowsActiveNoDuplicatedRows) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = 'allHeads';
        })
      );
    }

    // initial 'heads' or 'rows 'selection -> select first row (if heads) or first col (if rows) as subdimension
    if (chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') {
      const rowKey = Object.keys(subDimensionSources)?.[0];

      if (!chartConfig?.scatterDimensionsSubDimension) {
        handler.setTableConfig(state =>
          produce(state, draftState => {
            draftState.chart.scatterDimensionsSubDimension = rowKey
              ? subDimensionSources?.[rowKey]?.cellIdentifier ?? null
              : { headId: '__total__', headSubId: '__total__' };
          })
        );
      }
    } else {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsSubDimension = null;
        })
      );
    }

    // take first entry from selected sources as x
    const xKey = Object.keys(selectedDimensionSources)?.[0] ?? null;
    if (!chartConfig?.scatterDimensionsX && xKey) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsX = selectedDimensionSources?.[xKey]?.cellIdentifier ?? null;
          draftState.chart.scatterDimensionsLabels = selectedDimensionSources?.[xKey]?.cellIdentifier ?? null;
        })
      );
    }

    // take second entry from selected sources as y
    const yKey = Object.keys(selectedDimensionSources)?.[1] ?? null;
    if (!chartConfig?.scatterDimensionsY && yKey) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsY = selectedDimensionSources?.[yKey]?.cellIdentifier ?? null;
        })
      );
    }
  }, [
    chartConfig?.scatterDimensionsDimension,
    chartConfig?.scatterDimensionsSubDimension,
    chartConfig?.scatterDimensionsX,
    chartConfig?.scatterDimensionsY,
    chartConfig?.scatterDimensionsLabels,
    selectedDimensionSources,
    subDimensionSources,
    rowSources,
    colSources,
    rowsDoHaveDuplicatedValueStructure,
    colsDoHaveDuplicatedValueStructure,
    handler
  ]);

  const setScatterDimensionsDimension = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = value;
          draftState.chart.scatterDimensionsSubDimension = null;
          draftState.chart.scatterDimensionsX = null;
          draftState.chart.scatterDimensionsY = null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler]
  );

  const setDimensionsSubDimension = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (value === '__total__') {
            draftState.chart.scatterDimensionsSubDimension = { headId: '__total__', headSubId: '__total__' };
          } else {
            draftState.chart.scatterDimensionsSubDimension = subDimensionSources?.[value]?.cellIdentifier ?? null;
          }

          draftState.chart.scatterDimensionsX = null;
          draftState.chart.scatterDimensionsY = null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, subDimensionSources]
  );

  const setScatterDimensionsX = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (value === '__total__') {
            draftState.chart.scatterDimensionsX = { headId: '__total__', headSubId: '__total__' };
          } else {
            draftState.chart.scatterDimensionsX = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
          }
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  const setScatterDimensionsY = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (value === '__total__') {
            draftState.chart.scatterDimensionsY = { headId: '__total__', headSubId: '__total__' };
          } else {
            draftState.chart.scatterDimensionsY = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
          }
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  const setScatterDimensionsLabels = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsLabels = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  // console.log('chartConfig?.scatterDimensionsDimension', chartConfig?.scatterDimensionsDimension)
  // console.log('chartConfig?.scatterDimensionsX', chartConfig?.scatterDimensionsX)
  // console.log('chartConfig?.scatterDimensionsY', chartConfig?.scatterDimensionsY)
  // console.log('chartConfig?.scatterDimensionsSubDimension', chartConfig?.scatterDimensionsSubDimension)
  // console.log('colSources', colSources)
  // console.log('rowSources', rowSources)
  // console.log('selectedDimensionSources', selectedDimensionSources)
  // console.log('subDimensionSources', subDimensionSources)
  // console.log('colsDoHaveDuplicatedValueStructure', colsDoHaveDuplicatedValueStructure)
  // console.log('rowsDoHaveDuplicatedValueStructure', rowsDoHaveDuplicatedValueStructure)

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.scatter-dimensions.settings'}
          defaultMessage={'Dimensions settings'}
        />
      </ListGroupItemHeading>
      {/* xy dimension : heads, rows, heads */}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.dimension'}
            defaultMessage={'Dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allHeads` })}
            id={`scatterDimensionsDimension`}
            name='scatterDimensionsDimension'
            value={chartConfig?.scatterDimensionsDimension ?? 'allHeads'}
            onChange={setScatterDimensionsDimension}
          >
            <option value={'allHeads'}>
              {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allHeads` })}
            </option>
            {Object.keys(colSources).length > 1 && colsDoHaveDuplicatedValueStructure && (
              <option value={'heads'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.heads` })}
              </option>
            )}
            {Object.keys(colSources).length > 1 && (
              <option value={'allRows'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allRows` })}
              </option>
            )}
            {/* there have to be two rows with the same amount of subRows (values) */}
            {/* only display 'rows' if this condition is met */}
            {rowsDoHaveDuplicatedValueStructure && (
              <option value={'rows'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.rows` })}
              </option>
            )}
          </Input>
        </Col>
      </Row>
      {/* row/column selection */}
      {(chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? 'smoove.page.tables.chart-config.scatter-dimensions.row-selection'
                  : 'smoove.page.tables.chart-config.scatter-dimensions.col-selection'
              }
              defaultMessage={'row selection'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              label={
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.row-selection' })
                  : intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.col-selection' })
              }
              id={`scatterDimensionsSubDimension`}
              name='scatterDimensionsSubDimension'
              value={ScatterDimensionsSubDimensionKey}
              onChange={setDimensionsSubDimension}
            >
              {chartConfig?.scatterDimensionsDimension === 'rows' && !isCompactMatrix && (
                <option value='__total__'>{intl.formatMessage({ id: `smoove.page.tables.total` })}</option>
              )}
              {Object.keys(subDimensionSources).map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={subDimensionSources[sourceKey]} />
                );
              })}
            </Input>
          </Col>
        </Row>
      )}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.x'}
            defaultMessage={'x dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.x` })}
            id={`scatterDimensionsX`}
            name='scatterDimensionsX'
            value={ScatterDimensionsXKey}
            onChange={setScatterDimensionsX}
          >
            {chartConfig?.scatterDimensionsDimension === 'allRows' && !isCompactMatrix && (
              <option value='__total__'>{intl.formatMessage({ id: `smoove.page.tables.total` })}</option>
            )}
            {Object.keys(selectedDimensionSources)
              .filter(sourceKey =>
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? selectedDimensionSources[sourceKey].duplicatedValueStructure
                  : true
              )
              .map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={selectedDimensionSources[sourceKey]} />
                );
              })}
          </Input>
        </Col>
      </Row>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.y'}
            defaultMessage={'y dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.y` })}
            id={`scatterDimensionsY`}
            name='scatterDimensionsY'
            value={ScatterDimensionsYKey}
            onChange={setScatterDimensionsY}
          >
            {chartConfig?.scatterDimensionsDimension === 'allRows' && !isCompactMatrix && (
              <option value='__total__'>{intl.formatMessage({ id: `smoove.page.tables.total` })}</option>
            )}
            {Object.keys(selectedDimensionSources)
              .filter(sourceKey =>
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? selectedDimensionSources[sourceKey].duplicatedValueStructure
                  : true
              )
              .map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={selectedDimensionSources[sourceKey]} />
                );
              })}
          </Input>
        </Col>
      </Row>
      {(chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.scatter-dimensions.labels'}
              defaultMessage={'labels'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              label={intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.labels' })}
              id={`scatterDimensionsLabels`}
              name='scatterDimensionsLabels'
              value={ScatterDimensionsLabelsKey}
              onChange={setScatterDimensionsLabels}
            >
              {Object.keys(selectedDimensionSources)
                .filter(sourceKey => selectedDimensionSources[sourceKey].duplicatedValueStructure)
                .map(sourceKey => {
                  return (
                    <QuestionOptions
                      key={sourceKey}
                      sourceKey={sourceKey}
                      source={selectedDimensionSources[sourceKey]}
                    />
                  );
                })}
            </Input>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};

const QuestionOptions = ({ sourceKey, source }) => {
  const elementLabel = source.element?.label ?? source.element?.title ?? source.element.id;

  const labels = [];

  labels[0] = useSubelementPropertyTranslation(source.subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  labels[1] = useSubelementPropertyTranslation(source.subsubelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const finalLabel = source.subelement ? `${elementLabel} - ${labels.filter(Boolean).join(' | ')}` : `${elementLabel}`;

  return <option value={sourceKey}>{finalLabel}</option>;
};

function hasDuplicateValueStructure(mode, items, getSourceElement, tableResult) {
  const elements = {};

  if (mode === 'rows') {
    for (const rowId of items.order) {
      const row = items.list[rowId];
      let { elementValues } = getSourceElement(row);
      const { rowOrder } = getRowOrder(tableResult?.order ?? [], row);

      elementValues.order.sort((a, b) => {
        const orderA = rowOrder.findIndex(obj => obj.subelement === a);
        const orderB = rowOrder.findIndex(obj => obj.subelement === b);
        return orderA - orderB;
      });

      elements[rowId] = elementValues;
    }
  } else {
    for (const headId of items.order) {
      const head = items.list[headId];
      const { elementValues } = getSourceElement(head);
      const headOrder = tableResult?.headOrder?.[headId];

      if (headOrder) {
        elementValues.order.sort((a, b) => {
          const orderA = headOrder.findIndex(obj => obj.headSubId === a);
          const orderB = headOrder.findIndex(obj => obj.headSubId === b);
          return orderA - orderB;
        });
      }

      elements[headId] = elementValues;
    }
  }

  const entries = Object.entries(elements); // Convert the object to an array of its values

  const result = {}; // Result object

  // Iterate through each element
  for (let i = 0; i < entries.length; i++) {
    const [keyFirst, first] = entries[i];
    let foundMatch = false;

    // Compare with every other element
    for (let j = 0; j < entries.length; j++) {
      if (i !== j) {
        const [, second] = entries[j];

        // Check if both have the same order length
        if (first.order.length === second.order.length) {
          let allMatch = true;

          // Compare the values in both lists according to the order
          for (let k = 0; k < first.order.length; k++) {
            const idFirst = first.order[k];
            const idSecond = second.order[k];

            const valueFirst = first.list[idFirst].value;
            const valueSecond = second.list[idSecond].value;

            if (valueFirst !== valueSecond) {
              allMatch = false;
              break; // Stop if any value doesn't match
            }
          }

          // If all values match for this pair, set foundMatch to true
          if (allMatch) {
            foundMatch = true;
            break;
          }
        }
      }
    }

    // Set the result for the current object key
    result[keyFirst] = foundMatch;
  }

  return result;
}
