export const createNewQuestionnaireItem = (item, questionnaire) => {
  const _config = {};
  let qtype = item.qtype;
  let title = item.type;

  if (item.type === 'container') title = 'group';

  if (item.qtype === 'ranking_matrix') {
    _config.displayType = 'ranking';

    qtype = 'single_matrix';
  } else if (item.qtype === 'single_matrix') {
    _config.displayType = 'matrix';
  }
  const content = {
    title: `New ${title}`,
    parentId: null,
    type: item.type,
    config: _config,
    position: questionnaire?.root?.length ?? 0
  };

  // Set qtype for questions
  if (item.type === 'question') content.config.qtype = qtype;

  return content;
};
