import React, { useCallback } from 'react';
import { DropdownItem } from 'reactstrap';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ApplyToValue = ({ element, value, isSelected, handleApplyToSelect }) => {
  const _handleApplyToSelect = useCallback(() => handleApplyToSelect(element), [element, handleApplyToSelect]);

  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  return (
    <DropdownItem
      onClick={_handleApplyToSelect}
      className={classNames({
        'smv-custom-dropdown__buttons': true,
        'dropdown-item--active': isSelected
      })}
      style={{ justifyContent: 'space-between' }}
    >
      <span>{label}</span>
      <i
        className={classNames({
          'fa-regular': true,
          'fa-check ml-s': isSelected
        })}
      />
    </DropdownItem>
  );
};
