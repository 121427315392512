import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Card, CardBody, ModalFooter } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { dataPrepActions } from 'smv-redux';

import { WeightAsCustom } from './WeightAsCustom';
import { WeightAsFile } from './WeightAsFile';
import { PerformCalculationsButton } from '../PerformCalculationsButton';

import './DataWeighting.scss';

export const DataWeighting = () => {
  const surveyId = useSelector(state => state.survey.id);
  const weights = useSelector(state => state.survey?.data?.weights);
  const isRunningCalculations = useSelector(state => state.survey?.isRunningCalculations);

  const intl = useIntl();

  const [isNewWeightModalOpen, setIsNewWeightModalOpen] = useState(false);
  const toggleIsNewWeightModalOpen = useCallback(() => setIsNewWeightModalOpen(b => !b), []);

  const weightHandler = useMemo(() => {
    return {
      addWeight: (mode, label) => {
        dataPrepActions.addWeight(surveyId, mode, label);
      },
      deleteWeight: id => {
        dataPrepActions.deleteWeight(surveyId, id);
      },
      updateWeight: (weightId, updatedWeight) => {
        const _weight = _.cloneDeep(updatedWeight);
        delete _weight.definitions;

        dataPrepActions.updateWeight(surveyId, weightId, _weight);
      },
      updateWeightDefinition: (weightId, definition) => {
        dataPrepActions.updateWeightDefinition(surveyId, weightId, definition.id, definition);
      },
      addWeightDefinition: weightId => {
        dataPrepActions.addWeightDefinition(surveyId, weightId);
      },
      deleteWeightDefinition: (weightId, definitionId) => {
        dataPrepActions.deleteWeightDefinition(surveyId, weightId, definitionId);
      }
    };
  }, [surveyId]);

  const handleAddWeight = useCallback(
    (type, name) => {
      setIsNewWeightModalOpen(false);
      weightHandler.addWeight(type, name);
    },
    [weightHandler]
  );

  return (
    <>
      <Row>
        <Col lg={12} xl={12} className={'d-flex justify-content-between my-4'}>
          <Button onClick={toggleIsNewWeightModalOpen} color='primary' disabled={isRunningCalculations}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.add-weight' })}
          </Button>
          {weights?.length > 0 && <PerformCalculationsButton />}
        </Col>
      </Row>
      {(!weights || weights?.length === 0) && (
        <Row>
          <Col className='mb-2'>
            <i>{intl.formatMessage({ id: 'smoove.page.data.weight.no-weights-defined' })}</i>
          </Col>
        </Row>
      )}
      {(weights ?? []).map(weight => {
        if (weight?.mode === 'iterative') {
          return <WeightAsCustom key={weight.id} weight={weight} weightId={weight.id} weightHandler={weightHandler} />;
        }

        return <WeightAsFile key={weight.id} weight={weight} weightHandler={weightHandler} surveyId={surveyId} />;
      })}
      <Modal toggle={toggleIsNewWeightModalOpen} isOpen={isNewWeightModalOpen}>
        <ModalHeader toggle={toggleIsNewWeightModalOpen}>
          {intl.formatMessage({ id: 'smoove.page.data.weight.select-weight-type' })}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <Card
                onClick={() => handleAddWeight('iterative', `weight ${(weights?.length || 0) + 1}`)}
                className='pointer'
              >
                <CardBody className='text-center'>
                  <div className='d-flex align-items-center justify-content-center p-4 text-secondary'>
                    <i className='fal fa-calculator fa-4x' />
                  </div>
                  <Button color='primary' className='text-center mt-2' size={'sm'}>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.custom-weight-calculation' })}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card onClick={() => handleAddWeight('file', `weight ${weights.length + 1}`)} className='pointer'>
                <CardBody className='text-center'>
                  <div className='d-flex align-items-center justify-content-center p-4 text-secondary'>
                    <i className='fal fa-upload fa-4x' />
                  </div>
                  <Button color='primary' className='text-center mt-2' size={'sm'}>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.upload-weight-file' })}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color={'grey'} size={'sm'} onClick={toggleIsNewWeightModalOpen}>
            {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
