import { useCallback, useRef, useState, useMemo } from 'react';

/**
 * Adds a controlled state (isOpen, toggle) to Button and controlled Target component.
 *
 * @param {Component} Button The button component
 * @param {Component} Target The controlled target component
 * @param {boolean} initalState Inital state for isOpen
 * @returns {array} Returns array of ControlledButton, ControlledTarget, isOpen, toggle
 */
export const useControlledState = (Button, Target, initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggleTarget = useCallback(() => {
    setIsOpen(b => !b);
  }, []);
  const handlerRef = useRef();

  const button = useMemo(() => ControlledButton(Button, toggleTarget, handlerRef), [Button, toggleTarget]);
  const target = useMemo(() => ControlledTarget(Target, toggleTarget, isOpen, handlerRef), [
    Target,
    toggleTarget,
    isOpen
  ]);

  return [button, target, isOpen, toggleTarget];
};

const ControlledButton = (Component, toggle, handlerRef) => ({ ...props }) => (
  <Component toggle={toggle} handlerRef={handlerRef} {...props} />
);

const ControlledTarget = (Component, toggle, isOpen, handlerRef) => ({ ...props }) => (
  <Component toggle={toggle} isOpen={isOpen} handlerRef={handlerRef} {...props} />
);
