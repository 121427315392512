export const sortSurveysAlphAsc = surveys => {
  return [...surveys].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
};

export const sortSurveysAlphDesc = surveys => {
  return [...surveys].sort((a, b) => b.title.toLowerCase().localeCompare(a.title.toLowerCase()));
};

export const sortSurveysByDateNewestFirst = surveys => {
  return [...surveys].sort((a, b) => new Date(b.createdAt ?? Date.now()) - new Date(a.createdAt ?? Date.now()));
};

export const sortSurveysByDateOldestFirst = surveys => {
  return [...surveys].sort((a, b) => new Date(a.createdAt ?? Date.now()) - new Date(b.createdAt ?? Date.now()));
};

export const sortSurveysByStatusDraftToCompleted = surveys => {
  return [...surveys].sort((a, b) => b.status - a.status);
};
export const sortSurveysByStatusCompletedToDraft = surveys => {
  return [...surveys].sort((a, b) => a.status - b.status);
};

export const sortSurveys = (surveys, sorting) => {
  switch (sorting) {
    case 'alphAsc':
      surveys = sortSurveysAlphAsc(surveys);
      break;
    case 'alphDesc':
      surveys = sortSurveysAlphDesc(surveys);
      break;
    case 'newestFirst':
      surveys = sortSurveysByDateNewestFirst(surveys);
      break;
    case 'oldestFirst':
      surveys = sortSurveysByDateOldestFirst(surveys);
      break;
    case 'statusDraftCompleted':
      surveys = sortSurveysByStatusDraftToCompleted(surveys);
      break;
    case 'statusCompletedDraft':
      surveys = sortSurveysByStatusCompletedToDraft(surveys);
      break;
    default:
      break;
  }
  return surveys;
};
