import React from 'react';

import {
  ApplyToTypeCalculation,
  ApplyToTypeCalculationVariable,
  ApplyToTypeQuestion,
  ApplyToTypeSystemVariable
} from '.';

export const ApplyToTypeSwitch = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleApplyToSelect
}) => {
  const { sourceType } = main;
  if (sourceType === 'question') {
    return (
      <ApplyToTypeQuestion
        conditionalFormat={conditionalFormat}
        main={main}
        matrixType={matrixType}
        dimensionKey={dimensionKey}
        handleApplyToSelect={handleApplyToSelect}
      />
    );
  } else if (sourceType === 'sysvar') {
    return (
      <ApplyToTypeSystemVariable
        conditionalFormat={conditionalFormat}
        main={main}
        dimensionKey={dimensionKey}
        handleApplyToSelect={handleApplyToSelect}
      />
    );
  } else if (sourceType === 'calcvar') {
    return (
      <ApplyToTypeCalculationVariable
        conditionalFormat={conditionalFormat}
        main={main}
        dimensionKey={dimensionKey}
        handleApplyToSelect={handleApplyToSelect}
      />
    );
  } else if (sourceType === 'calculation') {
    return (
      <ApplyToTypeCalculation
        conditionalFormat={conditionalFormat}
        main={main}
        dimensionKey={dimensionKey}
        handleApplyToSelect={handleApplyToSelect}
      />
    );
  }

  return null;
};
