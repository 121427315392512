import { useSelector } from 'react-redux';
import produce from 'immer';

import { getMergedChoices } from '../components/misc/helper';

export const useGetSourceElement = () => {
  const questionVariables = useSelector(state => state.survey.questionnaire ?? { order: [], list: {} });
  const calculationVariables = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  return rowOrHead => {
    const { id, sourceId, sourceType, config = {} } = rowOrHead;
    let element;
    let elementTitle;
    let elementValues = { order: [], list: {} };
    let elementScales = { order: [], list: {} };

    if (sourceType === 'question') {
      element = questionVariables.list[sourceId];
      elementTitle = element.title;
      elementValues = getMergedChoices(element, element.config.choices, questionVariables.list);
      elementScales = {
        order: [...(element.config.scales?.order ?? [])],
        list: { ...(element.config.scales?.list ?? {}) }
      };
    } else if (sourceType === 'sysvar') {
      element = systemVariables.list[sourceId];
      elementTitle = element.label;
      elementValues = { list: element.list, order: element.order };
    } else if (sourceType === 'calcvar') {
      const calculationVariable = calculationVariables?.find(v => v.id === sourceId);
      element = produce(calculationVariable, draft => {
        draft.type = 'calcvar';
      });
      elementTitle = element.label;
      elementValues = {
        list: Object.fromEntries(element.values.map(value => [value.id, value])),
        order: element.values.map(value => value.id)
      };
    } else if (sourceType === 'calculation') {
      element = {
        id: id,
        type: 'calculation',
        title: 'Calculation'
      };
      elementTitle = 'Calculation';
      elementValues = {
        list: {
          [id]: {
            id: id,
            label: config.title,
            value: id
          }
        },
        order: [id]
      };
    }

    if (rowOrHead?.config?.calcFrequencyGroups) {
      if (element?.config?.qtype === 'single_matrix' || element?.config?.qtype === 'multiple_matrix') {
        elementScales = produce(elementScales, d => {
          d.order.push(...Object.keys(rowOrHead.config.calcFrequencyGroups));
          d.list = { ...d.list, ...rowOrHead.config.calcFrequencyGroups };
        });
      } else {
        elementValues = produce(elementValues, d => {
          d.order.push(...Object.keys(rowOrHead.config.calcFrequencyGroups));
          d.list = { ...d.list, ...rowOrHead.config.calcFrequencyGroups };
        });
      }
    }

    return { element, elementValues, elementScales, elementTitle };
  };
};
