/**
 * Checks if the currently logged in user has the permission for the passed client or project permissionName. Returns boolean
 *
 * @param {object} user the user the role should be found for
 * @param {object} client the client the role is defined in
 *
 * @returns {object} the role-object of the selected role which is defined in client.roles.clientRoles
 */

export const getUserClientRole = (user, client) => {
  if (!user || !client) return null;

  const clientRoles = client?.roles?.clientRoles;
  const userClientRoleId = user.clientRoles?.[client.id]?.clientRole?.role;

  if (!clientRoles) return null;
  return clientRoles.find(el => el.id === userClientRoleId);
};
