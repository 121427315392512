import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export const DynamicSplit = ({ split, handler, activeSplits }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const intl = useIntl();

  const questionnaireList = useSelector(state => state.survey.questionnaire.list);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const label = split?.label ?? 'unlabeled split';

  return (
    <div className={'smv-dynamic-split__split-element'}>
      <div className='smv-dynamic-split__split-element__head'>
        <span className={'smv-dynamic-split__split-element__head--label'} onClick={toggle}>
          <i className={`pl-1 far fa-chevron-${isOpen ? 'down' : 'right'}`} />
          <div className='mb-0 ml-2' style={{ fontSize: '.85rem' }}>
            {label ? label : <i>{intl.formatMessage({ id: 'smoove.page.reports.modals.filters.unnamed-element' })}</i>}
          </div>
        </span>
        {!!activeSplits?.[split.id] && (
          <span
            className={'smv-dynamic-split__split-element__head--clear'}
            onClick={() => handler.resetSplit(split.id)}
          >
            <i className={`icon-smv-blue far fa-trash`} />
          </span>
        )}
      </div>

      <Collapse isOpen={isOpen}>
        <div>
          {split.elements.order?.map(splitelementid => {
            const splitelement = split.elements.list[splitelementid];
            const isActive = activeSplits?.[split.id]?.[splitelementid] === true;

            let label, sourceElement;
            if (splitelement.sourceType === 'question') {
              sourceElement = (questionnaireList ?? {})[splitelement.sourceId];
              label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.title;
            } else if (splitelement.sourceType === 'sysvar') {
              sourceElement = systemVariables.list[splitelement.sourceId];
              label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
            } else if (splitelement.sourceType === 'calcvar') {
              sourceElement = calcVars?.find(el => el.id === splitelement.sourceId);
              label = splitelement?.label?.length > 0 ? splitelement.label : sourceElement?.label ?? '';
            }

            return (
              <div
                key={`${split.id}_${splitelementid}`}
                className='d-flex align-items-center pointer'
                onClick={() => handler.setActiveSplitElement(split.id, splitelementid)}
              >
                <h5
                  className={classNames('p-1 mb-0 far', {
                    'fa-circle': !isActive,
                    'fa-dot-circle': isActive
                  })}
                >
                  {''}
                </h5>

                <div className='ml-2' style={{ fontSize: '0.85rem' }}>
                  {label}
                </div>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};
