import React, { useEffect, useState } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { dataPrepActions } from 'smv-redux';
import { useUserHasPermission, permissionEnum } from 'smv-helpers';
import { DataWeighting, DataCalculation } from '../components/data-preparation';
import { PageHeadline } from '../components';

import './Data.scss';

export const Data = () => {
  const intl = useIntl();

  const surveyId = useSelector(state => state.survey?.id);
  const isLoading = useSelector(state => state.survey?.data?.isLoading);
  const surveyTitle = useSelector(state => state.survey?.title);

  const [activeTab, setActiveTab] = useState('1');

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  useEffect(() => {
    if (surveyId) {
      dataPrepActions.getDataPrepBySurveyId(surveyId).then(res => {
        if (res?.data === null) {
          dataPrepActions.addDataPrep(surveyId);
        }
      });
    }
  }, [surveyId]);

  if (!userCanEditProject)
    return (
      <div className='container-fluid base-content flex-fill mw-100 tables-page'>
        {intl.formatMessage({ id: 'smoove.page.access.sorry-you-dont-have-the-rights-to-view-this-page' })}
      </div>
    );

  return (
    <div className='smv-data-page container-fluid base-content flex-fill'>
      <Helmet>
        <title>
          {surveyTitle ?? ''} - {intl.formatMessage({ id: `core.menu.surveysubmenu.item.data` })}
        </title>
      </Helmet>
      <Row>
        <Col sm='12' className='d-flex align-items-center'>
          <PageHeadline headline={'smoove.page.survey.data.headline'} />
          {isLoading && <i className='fal fa-spinner fa-spin ml-2 mb-4'></i>}
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '1', pointer: true })}
            onClick={() => setActiveTab('1')}
          >
            {intl.formatMessage({ id: 'smoove.page.data.calculation.calculations' })}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === '2', pointer: true })}
            onClick={() => setActiveTab('2')}
          >
            {intl.formatMessage({ id: 'smoove.page.data.weight.weights' })}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <DataCalculation />
        </TabPane>
        <TabPane tabId='2'>
          <DataWeighting />
        </TabPane>
      </TabContent>
    </div>
  );
};
