import { useMemo } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

import { chartSettings } from '../config';

export const ChartValueConfig = ({ chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartTypeSettings?.valuesShow !== true) return null;

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        {chartConfig?.chartType === 'scatter'
          ? intl.formatMessage({ id: `smoove.page.tables.chart-config.labels` })
          : intl.formatMessage({ id: `smoove.page.tables.chart-config.values` })}
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          {chartConfig?.chartType === 'scatter'
            ? intl.formatMessage({ id: `smoove.page.tables.chart-config.show-labels` })
            : intl.formatMessage({ id: `smoove.page.tables.chart-config.show-values` })}
        </Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`valuesShow`}
              name='valuesShow'
              checked={chartConfig?.valuesShow ?? true}
              onChange={handler.setCheckboxValue}
            />
          </FormGroup>
        </Col>
      </Row>

      {((chartConfig?.chartType === 'bar' && chartConfig?.chartSubtype !== 'stacked') ||
        chartConfig?.chartType === 'pie') && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.value-position` })}</Col>
          <Col md={7}>
            <Input
              type='select'
              label={intl.formatMessage({ id: `smoove.page.tables.chart-config.value-position` })}
              id={`valuesValuePosition`}
              name='valuesValuePosition'
              value={chartConfig?.valuesValuePosition ?? 'inside'}
              onChange={handler.setSelectValue}
              disabled={!chartConfig?.valuesShow ?? true}
            >
              <option value={'inside'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.inside-default` })}
              </option>
              <option value={'outside'}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.outside` })}</option>
            </Input>
          </Col>
        </Row>
      )}

      {(chartConfig?.chartType === 'line' || chartConfig?.chartType === 'scatter') && (
        <>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              {chartConfig?.chartType === 'scatter'
                ? intl.formatMessage({ id: `smoove.page.tables.chart-config.label-position` })
                : intl.formatMessage({ id: `smoove.page.tables.chart-config.value-position` })}
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.value-position` })}
                id={`valuesValuePosition`}
                name='valuesValuePosition'
                value={chartConfig?.valuesValuePosition ?? 'inside'}
                onChange={handler.setSelectValue}
                disabled={!chartConfig?.valuesShow ?? true}
              >
                <option value={'top'}>
                  {intl.formatMessage({ id: `smoove.page.tables.chart-config.top-default` })}
                </option>
                <option value={'inside'}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.inside` })}</option>
                <option value={'bottom'}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.bottom` })}</option>
              </Input>
            </Col>
          </Row>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>
              {chartConfig?.chartType === 'scatter'
                ? intl.formatMessage({ id: `smoove.page.tables.chart-config.label-size` })
                : intl.formatMessage({ id: `smoove.page.tables.chart-config.value-size` })}
            </Col>
            <Col md={7}>
              <Input
                type='select'
                step={5}
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.text-size` })}
                id={`valuesValueSize`}
                name='valuesValueSize'
                value={chartConfig?.valuesValueSize ?? 12}
                onChange={handler.setSelectValue}
                disabled={!chartConfig?.valuesShow ?? true}
              >
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12 (default)</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
              </Input>
            </Col>
          </Row>
          <Row className='mt-1 align-items-center'>
            <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.circle-radius` })}</Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: `smoove.page.tables.chart-config.circle-radius` })}
                id={`valuesCircleRadius`}
                name='valuesCircleRadius'
                value={chartConfig?.valuesCircleRadius ?? 5}
                onChange={handler.setSelectValue}
                disabled={
                  chartConfig?.valuesValuePosition === 'inside' && chartConfig?.valuesShow === true ? true : false
                }
              >
                <option value={4}>4</option>
                <option value={5}>5 (default)</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
              </Input>
            </Col>
          </Row>
        </>
      )}
    </ListGroupItem>
  );
};
