import React from 'react';
import { useSelector } from 'react-redux';
import { HeaderPublic, HeaderPrivate } from './index';

export const Header = props => {
  const loggedIn = useSelector(state => state.authentication.loggedIn);

  if (!loggedIn) return <HeaderPublic />;
  else return <HeaderPrivate {...props} />;
};
