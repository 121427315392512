import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

export const CopyFolderButton = ({ handler, folder }) => {
  const intl = useIntl();

  const copyFolderHandler = useCallback(() => {
    handler.copyFolder(folder.id);
  }, [handler, folder.id]);

  return (
    <div className='smv-custom-dropdown__buttons' onClick={copyFolderHandler}>
      <i
        className={classNames({
          'fal fa-copy': true,
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>{intl.formatMessage({ id: `smoove.page.project.tables.form.copy-folder.button` })}</span>
    </div>
  );
};
