import React from 'react';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ResultTableChartHeadColumnHeader = ({ column, config, colSpan = 1 }) => {
  const { showCodeValuesHead = false } = config;

  const label = useSubelementPropertyTranslation(column, null, {
    showCodeValue: showCodeValuesHead,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <th colSpan={colSpan} className={classNames('head__column')}>
      <span className={'head__column_content'}>
        <span className='pr-1'>{label}</span>
      </span>
    </th>
  );
};
