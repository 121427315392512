import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useElementPropertyTranslation } from 'smv-helpers';

import { useSaveHandler } from './inputs';
import { EditableTextHover } from '../../../EditableText';

export const ConfigMultipleInput = ({ question, handler, writeable }) => {
  const surveyid = useSelector(state => state.survey?.id);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[state.survey?.id]?.selectedLocale ?? state.survey.locales.main
  );

  const intl = useIntl();

  const prefixText = useElementPropertyTranslation(question, {
    property: 'prefix',
    useFallback: !writeable,
    useShortcodes: false
  });

  const [internalState, setInternalState] = useState({
    inputCount: question.config?.inputCount ?? 1,
    inputType: question.config?.qtype === 'multiple_input_number' ? 'number' : 'text',
    minValue: question.config?.minValue ?? null,
    maxValue: question.config?.maxValue ?? null,
    maxInputLength: question.config?.maxInputLength ?? null,
    lines: question.config?.lines ?? null,
    fieldPercent: question.config?.fieldPercent ?? null
  });

  const saveHandler = useSaveHandler(question, surveyid);

  return (
    <>
      <Row>
        <div className='config-group col-6'>
          <h6>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-tab.number-available-inputs`}
              defaultMessage={'Number of available inputs'}
            />
          </h6>
          <Input
            id={`config_no_of_lines_id_${question?.config?.varname ?? ''}`}
            bsSize='sm'
            type='number'
            disabled={!writeable}
            placeholder={intl.formatMessage({
              id: `smoove.questionnaire.modal.config-tab.input-count-value`,
              defaultMessage: 'enter number of inputs...'
            })}
            min={1}
            max={50}
            value={internalState?.inputCount ?? 1}
            onChange={e => setInternalState({ ...internalState, inputCount: e.target.value })}
            onBlur={saveHandler.setInputCount}
          />
        </div>
      </Row>
      {question.config.qtype === 'multiple_input_number' && (
        <ConfigMultipleInputNumber
          question={question}
          writeable={writeable}
          internalState={internalState}
          setInternalState={setInternalState}
          saveHandler={saveHandler}
        />
      )}
      <div className='config-group'>
        <h6>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.prefix`} defaultMessage={'Prefix'} />
        </h6>
        <small>Text that will be rendered in front of the input.</small>
        <EditableTextHover
          className='border'
          writeable={writeable}
          value={prefixText ?? ''}
          replacing={true}
          // onChange={e => setInternalState({ ...internalState, prefix: e.target.value })}
          onBlur={e => saveHandler.setPrefix(e, selectedLocale)}
        />
      </div>
      <Row>
        <div className='config-group col-6'>
          <h6>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-tab.field-percent`}
              defaultMessage={'Field Percent'}
            />
          </h6>
          <Input
            disabled={!writeable}
            id={`config_fieldPercent_id_${question.config.varname}`}
            placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-field-percent` })}
            bsSize='sm'
            type='number'
            value={internalState.fieldPercent ?? ''}
            onChange={e => setInternalState({ ...internalState, fieldPercent: e.target.value })}
            onBlur={saveHandler.setFieldPercent}
          />
        </div>
        <div className='config-group col-6'>
          <h6>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-tab.max-input-length`}
              defaultMessage={'Max. input length (number of characters)'}
            />
          </h6>
          <Input
            disabled={!writeable}
            id={`config_maxinputlength_id_${question.id}`}
            bsSize='sm'
            type='number'
            placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-max-input-length` })}
            value={internalState.maxInputLength ?? ''}
            onChange={e => setInternalState({ ...internalState, maxInputLength: e.target.value })}
            onBlur={saveHandler.setMaxLength}
            min={1}
            max={255}
          />
        </div>
      </Row>
    </>
  );
};

const ConfigMultipleInputNumber = ({ internalState, setInternalState, saveHandler, question, writeable }) => {
  const intl = useIntl();

  return (
    <Row>
      <div className='config-group col-6'>
        <h6>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.minimum`} defaultMessage={'Minimum'} />
        </h6>
        <Input
          disabled={!writeable}
          id={`config_minCount_id_${question.id}`}
          bsSize='sm'
          type='number'
          placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-min-value` })}
          value={internalState?.minValue ?? ''}
          onChange={e => setInternalState({ ...internalState, minValue: e.target.value })}
          onBlur={saveHandler.setMinValue}
        />
      </div>

      <div className='config-group col-6'>
        <h6>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.maximum`} defaultMessage={'Maximum'} />
        </h6>
        <Input
          disabled={!writeable}
          id={`config_maxCount_id_${question.id}`}
          bsSize='sm'
          type='number'
          placeholder={intl.formatMessage({ id: `smoove.questionnaire.modal.config-tab.enter-max-value` })}
          value={internalState?.maxValue ?? ''}
          onChange={e => setInternalState({ ...internalState, maxValue: e.target.value })}
          onBlur={saveHandler.setMaxValue}
        />
      </div>
    </Row>
  );
};
