import { useState, useEffect, useContext } from 'react';

import { routeActions } from '../redux';
import { store } from 'smv-redux';
import { IntlContext } from '../translation/context';

export function DynamicImport({ load, children }) {
  const [loadedComponent, setComponent] = useState(null);
  const intlContext = useContext(IntlContext);

  useEffect(() => {
    if (load) {
      load()
        .then(loadedModule => {
          const { key, routes, component, reducers, messages } = loadedModule;
          if (key) console.log(`Registering "${key}"`);

          if (reducers) {
            store.injectReducers(reducers);
          }

          if (routes) {
            store.dispatch(routeActions.add(routes));
          }

          if (messages) {
            intlContext.injectMessages(messages);
          }

          if (component) {
            setComponent(() => component);
          }
        })
        .catch(error => console.log(error));
    }
  }, [load, intlContext]);

  return loadedComponent ? children(loadedComponent) : null;
}
