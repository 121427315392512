import { FormattedMessage } from 'react-intl';

const surveyStatus = {
  wizard: 'smoove.page.projects.status.wizard',
  draft: 'smoove.page.projects.status.draft',
  testing: 'smoove.page.projects.status.testing',
  running: 'smoove.page.projects.status.running',
  completed: 'smoove.page.projects.status.completed'
};

const surveyStatusPillClass = {
  wizard: 'badge-smv-outline',
  draft: 'badge-smv-orange-outline',
  testing: 'badge-smv-orange-outline',
  running: 'badge-smv-blue-outline',
  completed: 'badge-smv-green-outline'
};

export const getSurveyStatusBadge = status => {
  return (
    <span className={'badge rounded-pill ' + surveyStatusPillClass[status]}>
      <FormattedMessage id={surveyStatus[status] ?? surveyStatus['draft']} />
    </span>
  );
};
