import { useState, useEffect } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';

import { CustomNode, CustomDragPreview, Placeholder } from './';
import { surveyActions } from 'smv-redux';
import { useSelector } from 'react-redux';

export const ReportsList = ({ readonly = false, reports, handler, activeFilters }) => {
  const [data, setData] = useState([]);

  const surveyId = useSelector(state => state.survey.id);
  const projectId = useSelector(state => state.project.id);

  useEffect(() => {
    if (!reports?.order?.length) return;

    const getTree = () => {
      const tree = [{ id: 'root', parent: null, text: 'root node', droppable: true }];
      return getNodes(tree, reports.order);
    };

    const getNodes = (tree, order) => {
      if (!order?.length) return;

      order.forEach(id => {
        const report = reports.list[id];

        if (report) {
          tree.push({
            id,
            parent: 'root',
            text: report.name,
            droppable: true
          });
        }
      });

      return tree;
    };

    const tree = getTree();
    setData(tree);
  }, [reports]);

  const handleDrop = (newTree, { dragSourceId }) => {
    const newOrder = newTree.map(el => el.id).filter(el => el !== 'root');

    surveyActions.reorderSurveyReports(projectId, surveyId, { reports: newOrder });
  };

  return (
    <Tree
      tree={data}
      rootId='root'
      render={(node, { isOpen, onToggle, isDropTarget, isDragSource, containerRef }) => {
        return (
          <CustomNode
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            isDropTarget={isDropTarget}
            isDragSource={isDragSource}
            readonly={readonly}
            containerRef={containerRef}
            handler={handler}
            activeFilters={activeFilters}
          />
        );
      }}
      dragPreviewRender={monitorProps => <CustomDragPreview monitorProps={monitorProps} />}
      onDrop={handleDrop}
      classes={{
        container: 'table-tree',
        draggingSource: 'table-tree--dragging-source',
        placeholder: 'table-tree--placeholder-container'
        // dropTarget: 'table-tree--drop-target'
      }}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
        if (readonly) return false;

        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      canDrag={() => {
        if (readonly) return false;
        return true;
      }}
      dropTargetOffset={5}
      placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
    />
  );
};
