import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import classNames from 'classnames';

import { SmvCustomDropdown } from 'src/smoove/components/misc';
import { ResultRowHeaderLoopSelect } from '../result-rows';

export const ResultTableOpenEndedListingHead = ({
  table,
  row,
  questionHas,
  element,
  handler,
  headRef,
  isOverHead,
  canDropHead
}) => {
  const elements = useSelector(state => state.survey.questionnaire.list);
  const { heads } = table;

  const headColumns = useMemo(
    () =>
      heads.order.map(headid => {
        const headElement = heads.list[headid];
        const element = elements[headElement.elementid];
        return (
          <div key={`head-column-${headid}`} className={'result-table--cell'}>
            <div className={'result-table--head-cell'}>
              <span>{element.title}</span>
              <SmvCustomDropdown>
                <DropdownItem onClick={() => handler.removeElement(headid, 'heads')}>
                  <div className='smv-custom-dropdown__buttons'>
                    <i className='fal fa-trash-alt mr-1' />
                    <span>{`remove`}</span>
                  </div>
                </DropdownItem>
              </SmvCustomDropdown>
            </div>
          </div>
        );
      }),
    [handler, elements, heads]
  );

  return (
    <div
      className={classNames({
        'result-table--head': true,
        'result-table--head--is-over': isOverHead && canDropHead,
        'result-table--head--can-drop': !isOverHead && canDropHead
      })}
      ref={headRef}
    >
      <div className={'result-table--row'}>
        <div className={'result-table--cell'}>&nbsp;</div>
        <div className={'result-table--cell'}>#</div>
        <div className={'result-table--cell'}>
          <div className={'result-table--head-cell'}>
            <span>{element.title}</span>
            {questionHas.isLooped && (
              <SmvCustomDropdown>
                {(isParentDropdownOpen, toggleParentDropdown) => (
                  <ResultRowHeaderLoopSelect
                    rowid={row.id}
                    row={row}
                    handler={handler}
                    loopParents={questionHas.loopParents}
                    toggleParentDropdown={toggleParentDropdown}
                    isParentDropdownOpen={isParentDropdownOpen}
                  />
                )}
              </SmvCustomDropdown>
            )}
          </div>
        </div>

        {/* additional columns */}
        {headColumns}
      </div>
    </div>
  );
};
