import React, { useCallback, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IconProvider } from 'smv-components';

import './FiltersListAvailable.scss';
import { FormattedMessage } from 'react-intl';

export const FiltersListAvailable = ({ handler }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const allQuestions = useMemo(() => {
    const getQuestions = (element, list, questions = []) => {
      if (element?.type === 'question') {
        if (['single_vector', 'multiple_vector'].includes(element.config?.qtype)) {
          questions.push(element.id);
        }
      } else {
        for (const id of element.children) {
          getQuestions(list[id], list, questions);
        }
      }
      return questions;
    };

    return getQuestions({ children: questionnaire.root }, questionnaire.list);
  }, [questionnaire]);

  return (
    <>
      <h6>
        <FormattedMessage id={'smoove.page.common.filters.available.label'} defaultMessage={'Available Filters'} />
      </h6>

      <div className='available-filter-list'>
        <MakeDroppable droppableId='question-droppable'>
          <FilterHeader text='Questionnaire'>
            {allQuestions.map((id, index) => {
              const element = questionnaire.list[id];
              return (
                <MakeDraggable key={`${element.id}_${index}`} draggableId={element.id} index={index}>
                  <FilterListElement key={`${id}_${index}_listelent`} element={element} handler={handler} />
                </MakeDraggable>
              );
            })}
          </FilterHeader>
        </MakeDroppable>

        <MakeDroppable droppableId='sysvar-droppable'>
          <FilterHeader text='Internal Variables'>
            {systemVariables.order.map((systemVariableId, index) => (
              <MakeDraggable key={`${systemVariableId}_${index}`} draggableId={systemVariableId} index={index}>
                <FilterListElement element={systemVariables.list[systemVariableId]} handler={handler} />
              </MakeDraggable>
            ))}
          </FilterHeader>
        </MakeDroppable>

        <MakeDroppable droppableId='calcvar-droppable'>
          <FilterHeader text='Calculations'>
            {calculations?.map((calculation, index) => (
              <MakeDraggable key={`${calculation.id}_${index}`} draggableId={calculation.id} index={index}>
                <FilterListElement element={calculation} handler={handler} />
              </MakeDraggable>
            ))}
          </FilterHeader>
        </MakeDroppable>
      </div>
    </>
  );
};

const FilterHeader = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = useCallback(() => setIsOpen(b => !b), [setIsOpen]);

  return (
    <>
      <div className='filter-header' onClick={toggle}>
        <i
          className={classNames(`fas fa-chevron-right pointer mr-2 rotate`, {
            'rotate-90': isOpen
          })}
        />
        <div>{text}</div>
      </div>
      <Collapse isOpen={isOpen} className='available-filter-list__collapse'>
        {children}
      </Collapse>
    </>
  );
};

const FilterListElement = ({ element }) => {
  const label = element?.title ?? element?.label ?? (
    <i>
      <FormattedMessage id='smoove.page.reports.modals.filters.unnamed-element' defaultMessage='unnamed element' />
    </i>
  );

  return (
    <div className='node draggable draggable-default'>
      <IconProvider icon={element.config?.qtype ?? element.type} filled={false} clickable={false} dragable={true} />
      <span className='ml-2 node__title'>{label}</span>
    </div>
  );
};

const MakeDraggable = ({ children, draggableId, index }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} type='custom type'>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)'
            }}
          >
            {children}
          </div>
          {snapshot.isDragging && <div style={{ transform: 'none !important' }}>{children}</div>}
        </>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={true}>
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          // className={`droppable-default `}
        >
          {children}
          <div className='d-none'>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};
