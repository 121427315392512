import React from 'react';

import { ReferenceValues } from '.';

export const ReferenceTypeTotal = ({ conditionalFormat, handleReferenceSelect }) => {
  return (
    <ReferenceValues
      conditionalFormat={conditionalFormat}
      label={'Total'}
      main={{ id: '__total__' }}
      dimensionKey={'heads'}
      values={{ order: ['__total__'], list: { __total__: { id: '__total__', label: 'Total' } } }}
      handleReferenceSelect={handleReferenceSelect}
    />
  );
};
