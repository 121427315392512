import React, { useCallback, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import '../../../assets/css/react-datepicker-importer.scss';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Table,
  Row,
  UncontrolledTooltip
} from 'reactstrap';

import { ConfirmAction } from 'smv-components';

import './ShareReport.scss';

registerLocale('enUS', enUS);

export const ShareReportButton = props => {
  return (
    <div className='smv-custom-dropdown__buttons' onClick={props.toggle}>
      <i className={'fal fa-share-alt icon-smv-blue mr-1'} />
      <span>
        <FormattedMessage id={`smoove.page.reports.modals.share-report.button-open`} defaultMessage={'Share report'} />
      </span>
    </div>
  );
};

export const ShareReportModal = ({ isOpen, toggle, handler, reportid }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'} className='share-report-modal' autoFocus={false}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={`smoove.page.reports.modals.share-report.headline`} defaultMessage={'Share Report'} />
      </ModalHeader>
      <ModalBody>
        <CreateShare handler={handler} reportid={reportid} />
        <hr />
        <ActiveShares reportid={reportid} handler={handler} />
      </ModalBody>
      <ModalFooter>
        <Button color='grey' onClick={toggle}>
          <FormattedMessage id={`smoove.common.buttons.dismiss`} defaultMessage={'Dismiss'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ActiveShares = ({ reportid, handler }) => {
  const report = useSelector(state => state.survey.reports.list[reportid]);

  const intl = useIntl();

  const getLink = useCallback(
    share =>
      generatePath(`/one/shares/reports/:reportid/:token`, {
        reportid,
        token: share.token
      }),
    [reportid]
  );

  const copyLink = useCallback(
    share => {
      const baseUrl = window.location.origin;
      const link = `${baseUrl}${getLink(share)}`;
      copy(link, { debug: true, message: 'Copy to clipboard' });
    },
    [getLink]
  );

  const openLink = useCallback(
    share => {
      const link = getLink(share);
      window.open(link, '_blank');
    },
    [getLink]
  );

  if (report.accessLinks && report.accessLinks?.length === 0) return null;

  return (
    <>
      <h6>
        <FormattedMessage
          id={`smoove.page.reports.modals.share-report.active-shares.headline`}
          defaultMessage={'Active Public Shares'}
        />
      </h6>
      <Table size='sm' className='share-report-modal__shares-table small'>
        <thead>
          <tr>
            <td>
              <FormattedMessage id={`smoove.page.reports.modals.share-report.form.label`} defaultMessage={'Label'} />
            </td>
            <td>
              {' '}
              <FormattedMessage
                id={`smoove.page.reports.modals.share-report.form.valid-until`}
                defaultMessage={'Valid until'}
              />
            </td>
            <td className={'text-right'}>
              {' '}
              <FormattedMessage
                id={`smoove.page.reports.modals.share-report.form.actions`}
                defaultMessage={'Actions'}
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {report.accessLinks.map(el => {
            const { label, validUntil } = el;
            return (
              <tr key={el.id}>
                <td>
                  {label ? (
                    label
                  ) : (
                    <i>
                      <FormattedMessage
                        id={`smoove.page.reports.modals.share-report.active-shares.unlabeled-share`}
                        defaultMessage={'Unlabeled share'}
                      />
                    </i>
                  )}
                </td>
                <td>
                  <FormattedDate value={validUntil} year={'numeric'} month={'2-digit'} day={'2-digit'} />
                </td>
                <td className={'text-right'}>
                  <ButtonGroup size={'sm'}>
                    <Button
                      id={'shared-links-copy-' + el.id}
                      outline={true}
                      color={'primary'}
                      onClick={() => copyLink(el)}
                    >
                      <i className='fal fa-copy' />
                    </Button>
                    <UncontrolledTooltip target={'shared-links-copy-' + el.id}>
                      <FormattedMessage
                        id={`smoove.page.reports.modals.share-report.active-shares.copy-clipboard`}
                        defaultMessage={'Copy to clipboard'}
                      />
                    </UncontrolledTooltip>
                    <Button
                      id={'shared-links-open-' + el.id}
                      outline={true}
                      color={'secondary'}
                      onClick={() => openLink(el)}
                    >
                      <i className='fal fa-external-link-alt' />
                    </Button>
                    <UncontrolledTooltip target={'shared-links-open-' + el.id}>
                      <FormattedMessage
                        id={`smoove.page.reports.modals.share-report.active-shares.open`}
                        defaultMessage={'Open shared report'}
                      />
                    </UncontrolledTooltip>
                    <ConfirmAction
                      title={intl.formatMessage({
                        id: `smoove.page.reports.modals.share-report.active-shares.delete-shared-report`
                      })}
                      size={'md'}
                      body={
                        <div>
                          {intl.formatMessage({
                            id: `smoove.page.reports.modals.share-report.active-shares.delete-shared-report-text`
                          })}
                        </div>
                      }
                      callback={() => {
                        handler.deleteShare(reportid, el.id);
                      }}
                    >
                      {confirm => (
                        <Button
                          id={'shared-links-delete-' + el.id}
                          size={'sm'}
                          outline={true}
                          color={'danger'}
                          onClick={confirm}
                        >
                          <i className='fal fa-trash-alt' />
                        </Button>
                      )}
                    </ConfirmAction>
                    <UncontrolledTooltip target={'shared-links-delete-' + el.id}>
                      <FormattedMessage
                        id={`smoove.page.reports.modals.share-report.active-shares.delete`}
                        defaultMessage={'Delete shared repord link'}
                      />
                    </UncontrolledTooltip>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const CreateShare = ({ handler, reportid }) => {
  const locale = useSelector(state => state.appSettings?.locale ?? 'en_US');

  const todayDate = new Date();
  todayDate.setFullYear(todayDate.getFullYear() + 1, todayDate.getMonth() + 1, 0);

  let datepickerLocale = 'enUS';
  let datepickerFormat = 'MM/dd/yyyy';
  if (locale === 'de_DE') {
    datepickerLocale = 'de';
    datepickerFormat = 'dd.MM.yyyy';
    registerLocale('de', de);
  }

  const [config, setConfig] = useState({ label: '', password: '', validUntil: todayDate });

  const intl = useIntl();
  const calendarRef = useRef();

  const changeValue = useCallback(
    (name, e) => {
      const { value } = e.target;
      setConfig(s => ({ ...s, [name]: value }));
    },
    [setConfig]
  );

  const changeDate = value => {
    setConfig(s => ({ ...s, validUntil: value }));
  };

  return (
    <>
      <h6>
        <FormattedMessage
          id={`smoove.page.reports.modals.share-report.create-share.headline`}
          defaultMessage={'Create Public Share'}
        />
      </h6>
      <Form className={'create-share'} autoComplete='new-password'>
        <Row>
          <Col lg={6} xl={4}>
            <FormGroup>
              <div className={'text-smv fw-bold'}>
                <FormattedMessage id={`smoove.page.reports.modals.share-report.form.label`} defaultMessage={'Label'} />:
              </div>
              <div>
                <Input
                  autoComplete='new-password'
                  id={'shareName'}
                  type='text'
                  className={'question'}
                  value={config.label}
                  placeholder={intl.formatMessage({
                    id: `smoove.page.reports.modals.share-report.form.label-placeholder`,
                    defaultMessage: 'enter label...'
                  })}
                  onChange={e => changeValue('label', e)}
                  autoFocus={true}
                />
                <Label className={'text-smv fw-bold'} for={'shareName'}></Label>
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} xl={4}>
            <FormGroup>
              <div>
                <span className='fw-bold'>
                  {intl.formatMessage({
                    id: `smoove.page.reports.modals.share-report.form.password`,
                    defaultMessage: 'Password'
                  })}
                </span>
                <span> </span>
                <small>
                  (
                  {intl.formatMessage({
                    id: `smoove.page.reports.modals.share-report.form.optional`,
                    defaultMessage: 'optional'
                  })}
                  )
                </small>
              </div>
              <div>
                <Input
                  id={'sharePassword'}
                  type='password'
                  className={'question'}
                  value={config.password}
                  placeholder={intl.formatMessage({
                    id: `smoove.page.reports.modals.share-report.form.password-placeholder`,
                    defaultMessage: 'enter password...'
                  })}
                  onChange={e => changeValue('password', e)}
                  autoFocus={false}
                  autoComplete='new-password'
                />
                <Label className={'text-smv'} for={'sharePassword'} />
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} xl={4}>
            <FormGroup>
              <Label className={'text-smv fw-bold'}>
                <FormattedMessage
                  id={`smoove.page.reports.modals.share-report.form.valid-until`}
                  defaultMessage={'Valid until'}
                />
                :
              </Label>
              <div className={'d-flex align-items-center pl-2'}>
                <DatePicker
                  className={'flex-fill'}
                  ref={calendarRef}
                  selected={config.validUntil}
                  minDate={new Date()}
                  onChange={changeDate}
                  locale={datepickerLocale}
                  dateFormat={datepickerFormat}
                  placeholderText={intl.formatMessage({
                    id: `smoove.page.reports.modals.share-report.form.valid-placeholder`,
                    defaultMessage: 'select date...'
                  })}
                />
                <i
                  className='datepicker-calendar-icon far fa-lg fa-calendar-week ml-2 pointer'
                  onClick={() => calendarRef.current.setOpen(true)}
                ></i>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              size={'sm'}
              color={config.label.length <= 0 ? 'secondary' : 'primary'}
              outline={config.label.length <= 0}
              onClick={() => {
                handler.saveShare(reportid, config);
                setConfig(s => ({ ...s, label: '' }));
              }}
              disabled={config.label.length <= 0}
            >
              <i className='fal fa-plus mr-2' />
              <span>
                <FormattedMessage
                  id={`smoove.page.reports.modals.share-report.create-link`}
                  defaultMessage={'Create link'}
                />
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
