import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useRowOrder } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

import {
  ResultTableChartQuestionSelectionDetails,
  ResultTableChartResultRow,
  ResultTableChartResultRowHeader
} from '.';

export const ResultTableChartDefaultResultRow = ({
  element,
  table,
  tableResult,
  chartConfig,
  row,
  questionHas,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { heads, config } = table;
  const { id, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row);
  const colSpan = matrixType === 'detail' && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) ? 2 : 1;

  const defaultMetric = useDefaultMetric(table);

  let subelements = element?.config?.choices ?? {};
  let _head = heads;

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const rowHeadLabel = element.title;

  const significancesShow = chartConfig?.significancesShow ?? false;

  const _rows = [];
  rowOrder.forEach(order => {
    let metric = defaultMetric;
    if (order.value === 'mean') metric = 'smvrslt_mean';

    const isHidden = order?.hidden ?? false;
    const isExcluded = order?.excluded ?? false;

    if (isHidden || isExcluded) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;

    let subelement = {};
    const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};
    if (calcFrequencyGroups?.[order?.subelement]) {
      subelement = calcFrequencyGroups[order.subelement];
    } else if (subelements.list?.[order?.subelement]) {
      subelement = subelements.list?.[order?.subelement];
    }

    _rows.push(
      <ResultTableChartResultRow
        key={`result-row-${row.id}_${order.subelement}`}
        rowid={id}
        orderItem={order}
        subelement={subelement}
        choiceid={order.subelement}
        config={config}
        heads={_head}
        tableResult={tableResult}
        conditionalFormats={chartConfig.conditionalFormats}
        metric={metric}
        sortationPinned={sortationPinned}
        isCompactMatrix={isCompactMatrix}
        significancesShow={significancesShow}
        rowHeadColSpan={colSpan > 1 && !chartConfig.showQuestionTitleColumn ? 2 : 1}
        hover={hover}
      >
        {_rows.length === 0 && chartConfig.showQuestionTitleColumn && (
          <ResultTableChartResultRowHeader
            rowid={id}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            colSpan={colSpan}
            questionHas={questionHas}
            questionSelectionDetails={
              <ResultTableChartQuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
            }
          />
        )}
      </ResultTableChartResultRow>
    );
  });

  return _rows;
};

ResultTableChartDefaultResultRow.propTypes = {
  element: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  tableResult: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  questionHas: PropTypes.object.isRequired,
  isCompactMatrix: PropTypes.bool.isRequired
};
