import React, { useCallback, useState } from 'react';
import { Collapse, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// import { GeneralSettings, ValueSettings, SignificanceSettings, WeightSettings, CalculationSettings } from '.';
import { GeneralSettings } from './GeneralSettings';
import { ValueSettings } from './ValueSettings';
import { SignificanceSettings } from './SignificanceSettings';
import { WeightSettings } from './WeightSettings';
import { CalculationSettings } from './CalculationSettings';
import { StaticTableFilterSettings } from './StaticTableFilterSettings';
import { TableSortation } from './TableSortation';

import '../ResultConfig.scss';

export const TableConfig = ({ handler, tableConfig, tableResult, isEmpty, resultTableType }) => {
  const [isOpen, setIsOpen] = useState(isEmpty ? false : true);
  const toggleOpen = useCallback(
    () =>
      setIsOpen(b => {
        if (isEmpty) return false;
        else return !b;
      }),
    [isEmpty]
  );

  if (!tableConfig) return null;

  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading
        onClick={toggleOpen}
        className={classNames({
          'd-flex align-items-center py-1 list-item-sticky': true,
          'pointer': !isEmpty
        })}
      >
        <i className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
        <span className='ml-1'>
          <FormattedMessage id={'smoove.page.tables.table-config.table-config'} defaultMessage={'Table Config'} />
        </span>
      </ListGroupItemHeading>
      <Collapse isOpen={!isEmpty && isOpen}>
        <ListGroup flush={true}>
          {resultTableType !== 'listing' && <GeneralSettings handler={handler} tableConfig={tableConfig} />}
          {resultTableType !== 'listing' && <ValueSettings handler={handler} tableConfig={tableConfig} />}
          {resultTableType !== 'listing' && (
            <SignificanceSettings handler={handler} tableConfig={tableConfig} tableResult={tableResult} />
          )}
          {resultTableType !== 'listing' && <WeightSettings handler={handler} tableConfig={tableConfig} />}
          <StaticTableFilterSettings handler={handler} tableConfig={tableConfig} />
          {resultTableType !== 'listing' && <CalculationSettings handler={handler} tableConfig={tableConfig} />}
          <TableSortation handler={handler} tableConfig={tableConfig} />
        </ListGroup>
      </Collapse>
    </ListGroupItem>
  );
};
