import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import produce from 'immer';

import { useMatrixType } from 'smv-helpers';

import { ReferenceDimension } from '.';

export const ReferenceDropdown = ({ tableConfig, conditionalFormat, handleReferenceSelect }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const { matrixType, isCompactMatrix } = useMatrixType(tableConfig);

  const sourceElements = useMemo(
    () => ({
      rows: produce(tableConfig.rows, draft => {}),
      heads: produce(tableConfig.heads, draft => {
        if (isCompactMatrix) {
          tableConfig.rows.order.forEach(rowid => {
            if (tableConfig.rows.list[rowid].sourceType === 'question') {
              draft.order = [rowid, ...draft.order];
              draft.list[rowid] = tableConfig.rows.list[rowid];
            }
          });
        }
      })
    }),
    [tableConfig, isCompactMatrix]
  );

  const referenceCount = useMemo(() => {
    const countNonNullItems = obj => {
      return Object.values(obj).filter(value => value !== null).length;
    };

    return countNonNullItems(conditionalFormat.reference);
  }, [conditionalFormat.reference]);

  return (
    <Dropdown
      tag={'div'}
      direction={'down'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'mr-2': true
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'w-100': true
        })}
      >
        <span>
          {referenceCount <= 0 &&
            intl.formatMessage({
              id: 'smoove.page.tables.chart-config.conditional-formatting-table.reference.placeholder',
              defaultMessage: 'select reference'
            })}
          {referenceCount > 0 &&
            intl.formatMessage({
              id: 'smoove.page.tables.chart-config.conditional-formatting-table.reference.references',
              defaultMessage: 'Reference'
            })}
        </span>
      </DropdownToggle>
      <DropdownMenu end className={'smv-custom-dropdown__dropdown-menu'}>
        {Object.keys(sourceElements).map(dimensionKey => {
          return (
            <ReferenceDimension
              key={dimensionKey}
              conditionalFormat={conditionalFormat}
              showTotal={tableConfig.config.showTotal}
              matrixType={matrixType}
              dimension={sourceElements[dimensionKey]}
              dimensionKey={dimensionKey}
              handleReferenceSelect={handleReferenceSelect}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
