import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import produce from 'immer';

import { getPreviousQuestions } from '../../../../../helpers';
import { questionnaireActions } from 'smv-redux/actions';

export const LoopConfig = ({ container, writeable }) => {
  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main
  );
  const surveyid = useSelector(state => state.survey.id);
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const translations = useSelector(state => state.survey.translations);

  const [loopConfig, setLoopConfig] = useState(container.config?.loop ?? {});
  const [isLoop, setIsLoop] = useState(!!loopConfig.source);

  const loopSources = getPreviousQuestions(questionnaire, container.id);

  const loopItems = useMemo(() => {
    const source = questionnaire.list?.[loopConfig?.source];
    return (
      source?.config?.choices?.order?.map((choiceid, index) => {
        const choice = source.config.choices.list[choiceid];
        return <div key={index}>{translations[choice.config?.translations?.label]?.[locale]}</div>;
      }) ?? []
    );
  }, [loopConfig?.source, questionnaire.list, translations, locale]);

  const updateLoopConfig = useCallback(
    (key, value) => {
      const _loop = { ...loopConfig, [key]: value };
      setLoopConfig(_loop);
      questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
        config: { loop: _loop }
      });
    },
    [loopConfig, surveyid, container.id]
  );

  const toggleIsLoop = useCallback(() => {
    const _initialLoopConfig = {
      source: null,
      sortation: '0',
      randomize: false
    };
    if (isLoop) {
      setIsLoop(false);
      questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
        config: produce(container.config, draftState => {
          draftState.loop = null;
        })
      });
    } else {
      setIsLoop(true);
      questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
        config: { loop: _initialLoopConfig }
      });
      setLoopConfig(_initialLoopConfig);
    }
  }, [container.id, container.config, surveyid, isLoop]);

  return (
    <div className='config-group'>
      <h6>Loop Construct:</h6>
      <FormGroup switch>
        <Input
          disabled={!writeable}
          type='switch'
          role='switch'
          id='loop_switch'
          checked={isLoop}
          onChange={toggleIsLoop}
        />
      </FormGroup>
      <small>
        <i>
          To ask a set of questions as a loop construct, you can select a source element (a question or monad
          definition), for which items the question set should be asked iteratively.
        </i>
      </small>
      {isLoop && (
        <>
          <FormGroup row>
            <Label sm={3}>Source</Label>
            <Col sm={9}>
              <Input
                disabled={!writeable}
                type='select'
                name='loop-input'
                defaultValue={loopConfig?.source || ''}
                onChange={e => updateLoopConfig('source', e.target.value)}
              >
                <option value='' disabled>
                  Please choose
                </option>
                {loopSources.map(field => (
                  <option key={field.id} value={field.id}>
                    {field.title}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>

          {isLoop && (
            <>
              <FormGroup row>
                <Label sm={3}>Items</Label>
                <Col sm={9}>{loopItems}</Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={3}>Randomize Order</Label>
                <Col sm={9}>
                  <Input
                    disabled={!writeable}
                    type='switch'
                    id='loop_randomize'
                    checked={loopConfig?.randomize === true}
                    onChange={() => updateLoopConfig('randomize', !loopConfig?.randomize)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={3}>Sortation</Label>
                <Col sm={9}>
                  <Input
                    disabled={!writeable}
                    type='select'
                    name='loop-sortation'
                    defaultValue={loopConfig?.sortation ?? '0'}
                    onChange={e => updateLoopConfig('sortation', e.target.value)}
                  >
                    <option value='0'>unsorted/ inital sortation</option>
                    <option value='1'>Numerisch sortiert nach Antworten in der Quellfrage (aufsteigend)</option>
                    <option value='2'>Numerisch sortiert nach Antworten in der Quellfrage (absteigend)</option>
                    <option value='3'>Alphabetisch sortiert nach Antworten in der Quellfrage (aufsteigend)</option>
                    <option value='4'>Alphabetisch sortiert nach Antworten in der Quellfrage (absteigend)</option>
                    <option value='5'>Alphabetisch sortiert nach Listenelement-Label (aufsteigend)</option>
                    <option value='6'>Alphabetisch sortiert nach Listenelement-Label (absteigend)</option>
                  </Input>
                </Col>
              </FormGroup>
            </>
          )}
        </>
      )}
    </div>
  );
};
