import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import classNames from 'classnames';

import { ApplyToTypeSwitch, ApplyToTypeTotal } from '.';

export const ApplyToDimension = ({
  dimension,
  dimensionKey = 'rows',
  conditionalFormat,
  showTotal,
  matrixType = null,
  handleApplyToSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <DropdownItem toggle={false} tag={'div'}>
      <Dropdown
        tag={'div'}
        direction={'end'}
        group={true}
        className={classNames({
          'smv-custom-dropdown': true,
          'dropdown-item': true,
          'dropdown-item--active': Object.keys(conditionalFormat.applyTo?.[dimensionKey] ?? {}).length > 0
        })}
        isOpen={isOpen}
        toggle={toggle}
      >
        <DropdownToggle
          tag={'div'}
          className={classNames({
            'smv-custom-dropdown__buttons': true,
            'smv-custom-dropdown__dropdown-toggle': true,
            'smv-custom-dropdown__dropdown-toggle--flex': true
          })}
        >
          <span>{dimensionKey}</span>
          <i className={'fa-thin fa-chevron-right'} />
        </DropdownToggle>

        <DropdownMenu
          end
          className={classNames({
            'smv-custom-dropdown__dropdown-menu': true,
            'smv-custom-dropdown__dropdown-menu--nested': isOpen
          })}
        >
          {dimensionKey === 'heads' && showTotal && matrixType !== 'compact' && (
            <ApplyToTypeTotal conditionalFormat={conditionalFormat} handleApplyToSelect={handleApplyToSelect} />
          )}

          {dimension.order.map(mainId => (
            <ApplyToTypeSwitch
              key={mainId}
              conditionalFormat={conditionalFormat}
              matrixType={matrixType}
              main={dimension.list[mainId]}
              dimensionKey={dimensionKey}
              handleApplyToSelect={handleApplyToSelect}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </DropdownItem>
  );
};
