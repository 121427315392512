import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const VectorItemsPreview = ({ question }) => {
  const itemOrder = question?.config?.choices?.order ?? [];
  const qtype = question.config?.qtype;
  const list = useSelector(state => state.survey.questionnaire.list);
  const [selectedChoiceId, setSelectedChoiceId] = useState(null);
  const [selectedMultipleChoices, setSelectedMultipleChoices] = useState([]);
  // todo: this should be true by default
  const alternateBackgroundColors = question.config?.alternateBackgroundColors;
  const displayItemsHorizontal = question.config?.displayItemsHorizontal;
  const displayAsCards = question.config?.displayAsCards;

  const handleClickChoice = useCallback(id => {
    setSelectedChoiceId(id);
  }, []);

  const handleClickMultipleChoice = useCallback(
    choice => {
      let _selected = [...selectedMultipleChoices];
      if (_.some(_selected, choice)) {
        _selected = _.pull(_selected, choice);
      } else {
        if (choice.config?.exclusive || (_selected.length === 1 && _selected[0].config?.exclusive)) {
          _selected = [];
        }
        _selected.push(choice);
      }
      setSelectedMultipleChoices(_selected);
    },
    [selectedMultipleChoices]
  );

  const importedChoices = useMemo(() => {
    const importedSource = question.config?.choices?.import?.source;
    const sourceQuestion = list?.[importedSource];
    let _importedChoices = [];

    if (!sourceQuestion || !importedSource) return null;

    if (sourceQuestion.config?.choices?.order && sourceQuestion.config.choices.order.length > 0) {
      sourceQuestion.config.choices.order.forEach(choiceid => {
        if (qtype === 'single_vector') {
          _importedChoices.push(
            <SingleChoice
              key={choiceid}
              choiceid={choiceid}
              isActive={choiceid === selectedChoiceId}
              question={sourceQuestion}
              clickHandler={handleClickChoice}
            />
          );
        } else if (qtype === 'multiple_vector') {
          const choice = sourceQuestion.config?.choices?.list?.[choiceid];
          _importedChoices.push(
            <MultipleChoice
              key={choiceid}
              choiceid={choiceid}
              isActive={_.includes(selectedMultipleChoices, choice)}
              question={sourceQuestion}
              clickHandler={handleClickMultipleChoice}
            />
          );
        }
      });
    }
    return _importedChoices;
  }, [handleClickChoice, handleClickMultipleChoice, list, qtype, question, selectedChoiceId, selectedMultipleChoices]);

  return (
    <ul
      className={classNames({
        'preview-choices-list': true,
        'preview-choices-list--horizontal': displayItemsHorizontal,
        'preview-choices-list--alternate-background-colors': alternateBackgroundColors,
        'preview-choices-list--display-cards': displayAsCards
      })}
    >
      {importedChoices}
      {itemOrder?.map((id, index) => {
        if (qtype === 'single_vector') {
          const isActive = id === selectedChoiceId;
          return (
            <SingleChoice
              key={id}
              choiceid={id}
              isActive={isActive}
              question={question}
              clickHandler={handleClickChoice}
            />
          );
        }
        if (qtype === 'multiple_vector') {
          const choice = question.config?.choices?.list?.[id];
          const isActive = _.includes(selectedMultipleChoices, choice);
          return (
            <MultipleChoice
              key={id}
              choiceid={id}
              isActive={isActive}
              question={question}
              clickHandler={handleClickMultipleChoice}
            />
          );
        }
        return <div></div>;
      })}
    </ul>
  );
};

const SingleChoice = ({ choiceid, question, isActive, clickHandler }) => {
  const choice = question.config?.choices?.list?.[choiceid];

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  if (!choice) {
    return null;
  }

  return (
    <li
      className={`previewitem__choice ${isActive ? 'previewitem__choice--selected' : ''}`}
      onClick={() => clickHandler(choiceid)}
    >
      <span className='choice__radio'>
        <i className={`circle`} />
      </span>
      <span className='choice__label'>
        <div dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
        {/* <EditableTextHover
          value={choiceLabel}
          writeable={false}
          onBlur={() => { }}
          className={'no-padding'}
        /> */}
      </span>
    </li>
  );
};
const MultipleChoice = ({ choiceid, question, isActive, clickHandler }) => {
  const choice = question.config?.choices?.list?.[choiceid];

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  if (!choice) {
    return null;
  }

  return (
    <li
      className={`previewitem__choice ${isActive ? 'previewitem__choice--selected' : ''}`}
      onClick={() => clickHandler(choice)}
    >
      <span className='choice__checkbox'>
        <i className={`checkbox`} />
      </span>
      <span className='choice__label'>
        <div dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
        {/* <EditableTextHover
          value={choiceLabel}
          writeable={false}
          onBlur={() => { }}
          className={'no-padding'}
        /> */}
      </span>
    </li>
  );
};
