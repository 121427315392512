import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';

import { questionnaireActions } from 'smv-redux';
import { IconProvider, InlineEdit } from 'smv-components';

import { getQuestionElementsTranslationIds } from './helpers/getQuestionElementsTranslationIds';
import { TranslateQuestionText, TranslateChoices, TranslateScales } from './';
import { AiTranslationButton } from './AiTranslation';

const hasChoicesQuestionTypes = [
  'single_vector',
  'multiple_vector',
  'single_matrix',
  'multiple_matrix',
  'custom_shelf'
];
const hasScaleQuestionTypes = ['single_matrix', 'multiple_matrix'];

export const TranslationElement = ({
  elementid,
  className,
  localeA,
  localeB,
  nodeOnClick,
  writeable,
  translationModalContainerRef,
  aiTranslationList,
  updateAiTranslationList,
  getSingleTranslation,
  getTranslationsForIds
}) => {
  const surveyid = useSelector(state => state.survey.id);
  const element = useSelector(state => state.survey.questionnaire.list[elementid]);

  const intl = useIntl();

  const { id, title, type } = element;

  const calculatedPlaceholderHeight = '210px';

  // intersection Observer (lazyload) logic start
  const contentRef = useRef();
  const [isRendered, setIsRendered] = useState(false);
  const [willBeRendered, setWillBeRendered] = useState(false);

  const intersectionCb = useCallback(
    entries => {
      const [entry] = entries;
      if (!isRendered) {
        if (!willBeRendered && entry.isIntersecting) {
          //will be rendered
          setWillBeRendered(true);
        }
        if (willBeRendered && !entry.isIntersecting) {
          // cancel rendering, will not be rendered (just scrolled over question)
          setWillBeRendered(false);
        }
        if (willBeRendered && entry.isIntersecting) {
          //render
          setIsRendered(true);
        }
      }
    },
    [isRendered, willBeRendered]
  );

  useEffect(() => {
    const colRef = contentRef.current;
    let observer = null;
    let timer = null;
    let options = {
      root: null, //viewport
      rootMargin: '0px',
      threshold: 0.9
    };
    if (!isRendered && !willBeRendered) {
      observer = new IntersectionObserver(intersectionCb, options);
      if (colRef) observer.observe(colRef);
    }
    if (!isRendered && willBeRendered) {
      timer = setTimeout(() => {
        observer = new IntersectionObserver(intersectionCb, options);
        if (colRef) observer.observe(colRef);
      }, 250);
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (colRef && observer) observer.unobserve(colRef);
    };
  }, [willBeRendered, isRendered, intersectionCb]);
  // intersection Observer (lazyload) logic end

  const handleGetQuestionTranslations = useCallback(() => {
    const ids = getQuestionElementsTranslationIds(element);
    getTranslationsForIds(ids);
  }, [element, getTranslationsForIds]);

  const hasChoices = useMemo(() => hasChoicesQuestionTypes.includes(element.config?.qtype), [element.config?.qtype]);
  const hasScale = useMemo(() => hasScaleQuestionTypes.includes(element.config?.qtype), [element.config?.qtype]);

  const translationElementRows = useMemo(() => {
    if (!isRendered) {
      return (
        <tr style={{ height: isRendered ? 'auto' : calculatedPlaceholderHeight }}>
          <td colSpan={2}>
            <div className='d-flex flex-column justify-content-center align-items-center my-2 h-100'>
              <span className='fas fa-spinner fa-spin fa-2x' />
            </div>
          </td>
        </tr>
      );
    }
    return (
      <>
        <TranslateQuestionText
          question={element}
          localeA={localeA}
          localeB={localeB}
          writeable={writeable}
          aiTranslationList={aiTranslationList}
          updateAiTranslationList={updateAiTranslationList}
          getSingleTranslation={getSingleTranslation}
        />
        {hasChoices && (
          <TranslateChoices
            question={element}
            localeA={localeA}
            localeB={localeB}
            nodeOnClick={nodeOnClick}
            writeable={writeable}
            translationModalContainerRef={translationModalContainerRef}
            aiTranslationList={aiTranslationList}
            updateAiTranslationList={updateAiTranslationList}
            getSingleTranslation={getSingleTranslation}
          />
        )}
        {hasScale && (
          <TranslateScales
            question={element}
            localeA={localeA}
            localeB={localeB}
            writeable={writeable}
            translationModalContainerRef={translationModalContainerRef}
            aiTranslationList={aiTranslationList}
            updateAiTranslationList={updateAiTranslationList}
            getSingleTranslation={getSingleTranslation}
          />
        )}
      </>
    );
  }, [
    isRendered,
    element,
    localeA,
    localeB,
    nodeOnClick,
    calculatedPlaceholderHeight,
    writeable,
    hasChoices,
    hasScale,
    translationModalContainerRef,
    aiTranslationList,
    updateAiTranslationList,
    getSingleTranslation
  ]);

  if (type === 'question') {
    return (
      <>
        <tr ref={contentRef}>
          <td colSpan={2} className='translation-question-title translation-question-title--big'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <span className='ml-1 mr-2' id={`icon_qtype_${id}`}>
                  <IconProvider icon={element.config.qtype} filled={true} />
                  <UncontrolledTooltip target={`icon_qtype_${id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
                    <FormattedMessage
                      id={`smoove.questionnaire.question.${element.config.qtype.split('_').join('-')}`}
                      defaultMessage={element.config.qtype}
                    />
                  </UncontrolledTooltip>
                </span>
                <span className='text-uppercase mr-auto' id={'_' + id}>
                  {element.config?.varname ?? ''}
                  {element.config?.varname ? ' - ' : ''}
                  <InlineEdit
                    className={className}
                    defaultValue={title}
                    placeholder={intl.formatMessage({
                      id: `smoove.questionnaire.translation.${type}.title.placeholder`
                    })}
                    disabled={!writeable}
                    saveFn={newTitle => {
                      if (writeable && title !== newTitle) {
                        questionnaireActions.updateQuestionnaireElement(surveyid, element.id, { title: newTitle });
                      }
                    }}
                  />
                </span>
              </div>
              {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
                <AiTranslationButton getTranslations={handleGetQuestionTranslations} />
              )}
            </div>
          </td>
        </tr>
        {translationElementRows}
      </>
    );
  }

  // page or container
  return (
    <tr>
      <td colSpan={2} className='translation-question-title translation-question-title--big'>
        <div className='d-flex align-items-center'>
          <span className='ml-1 mr-2' id={`icon_qtype_${id}`}>
            <IconProvider icon={type} filled={true} />
            <UncontrolledTooltip target={`icon_qtype_${id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
              <FormattedMessage
                id={`smoove.questionnaire.question.${type.split('_').join('-')}`}
                defaultMessage={type}
              />
            </UncontrolledTooltip>
          </span>

          <span className='text-uppercase mr-auto' id={'_' + id}>
            <InlineEdit
              className={className}
              defaultValue={title}
              placeholder={intl.formatMessage({
                id: `smoove.questionnaire.translation.${type}.title.placeholder`
              })}
              disabled={!writeable}
              saveFn={newTitle => {
                if (writeable && title !== newTitle) {
                  questionnaireActions.updateQuestionnaireElement(surveyid, element.id, { title: newTitle });
                }
              }}
            />
          </span>
        </div>
      </td>
    </tr>
  );
};
