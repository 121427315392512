import React from 'react';
import { DropdownItem } from 'reactstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useControlledState } from 'src/smoove/helpers';
import { SmvCustomDropdown } from '../../../../misc';
import { FormulaBuilderDropdownButton, FormulaBuilderModal } from 'src/smoove/components/misc';

/**
 * @param {object} handler
 * @param {object} tableElement Row or head element
 * @param {object} tableConfig Complete table config object
 * @param {string} calculationMode Calculation mode 'rows' or 'heads'
 */
export const CalculationMenu = ({ handler, tableElement, tableConfig, calculationMode = 'rows', isHidden = false }) => {
  /** @todo: the hide/exclude properties should only consider the configured state, not hidden by low base/ frequency */
  const [ControlledFormulaButton, ControlledFormulaBuilderModal, isOpenFormulaBuilder] = useControlledState(
    FormulaBuilderDropdownButton,
    FormulaBuilderModal
  );

  const intl = useIntl();

  return (
    <>
      <SmvCustomDropdown>
        <DropdownItem>
          <ControlledFormulaButton />
        </DropdownItem>
        {calculationMode === 'rows' && (
          <>
            <DropdownItem
              className={classNames({ active: !isHidden })}
              disabled={!isHidden}
              onClick={() => handler.toggleSubelementVisibility('rows', null, tableElement.id, tableElement.id)}
            >
              <div className='smv-custom-dropdown__buttons'>
                <i className={`fal fa-eye mr-1`} />
                <span>
                  {intl.formatMessage({
                    id: 'smoove.page.survey.tables.options.show-subelement.label',
                    defaultMessage: 'show element'
                  })}
                </span>
              </div>
            </DropdownItem>
            <DropdownItem
              className={classNames({ active: isHidden })}
              disabled={isHidden}
              onClick={() => handler.toggleSubelementVisibility('rows', 'hidden', tableElement.id, tableElement.id)}
            >
              <div className={'smv-custom-dropdown__buttons'}>
                <i className={`fal fa-eye-slash mr-1`} />
                <span>
                  {intl.formatMessage({
                    id: 'smoove.page.survey.tables.options.hide-subelement.label',
                    defaultMessage: 'hide element'
                  })}
                </span>
              </div>
            </DropdownItem>
          </>
        )}
        <DropdownItem onClick={() => handler.removeElement(tableElement.id, calculationMode)}>
          <div className='smv-custom-dropdown__buttons'>
            <i className='fal fa-trash-alt mr-1' />
            <span>{`remove`}</span>
          </div>
        </DropdownItem>
      </SmvCustomDropdown>
      {isOpenFormulaBuilder && (
        <ControlledFormulaBuilderModal
          isOpen={true}
          tableConfig={tableConfig}
          tableElement={tableElement}
          handler={handler}
          calculationMode={calculationMode}
        />
      )}
    </>
  );
};
