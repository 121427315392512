import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormulaBuilderReferenceElement } from '.';

export const FormulaBuilderReferenceCalcFrequencyGroupElement = ({
  tableConfig,
  element,
  isSelected,
  renderParentLabels,
  ...rest
}) => {
  const questionVariables = useSelector(state => state.survey.questionnaire.list);
  const questionVariable = useMemo(() => questionVariables?.[element.value.elementid], [
    questionVariables,
    element.value.elementid
  ]);

  let label = tableConfig.rows.list[element.value.rowId].config.calcFrequencyGroups[element.value.rowSubId].label;
  if (renderParentLabels) {
    label = (questionVariable?.title ?? '') + ' - ' + label;
  }

  return (
    <FormulaBuilderReferenceElement
      tableConfig={tableConfig}
      element={element}
      label={label}
      options={{ metricSelect: false, allowFixation: true }}
      {...rest}
    />
  );
};
