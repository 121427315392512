import axios from 'axios';

import 'smv-redux/axiosConfig';
import { getFormData } from 'smv-helpers';
import { store } from 'smv-redux';
import { userConstants } from '../constants';

/**
 * Login a user using username and password. Fetches userInformation on success and stores them to the redux store
 *
 * @param {string} username //email of user
 * @param {string} password
 * @param {boolean} remember
 *
 */
function login({ username, password, remember = false }) {
  store.dispatch({
    type: userConstants.USERS_LOGIN_REQUEST,
    payload: { user: { username } }
  });

  return axios
    .post(`/auth`, { email: username, password: password })
    .then(res => {
      /** be sure that the jwt token is present */
      if (!res.data?.jwt_token || res.data.jwt_token.length === 0) {
        store.dispatch({
          type: userConstants.USERS_LOGIN_FAILURE,
          payload: { error: 'there is no jwt token in a successful response' }
        });
        return false;
      }

      /** instead of using redux for storing the token -> use session/localStorage */
      localStorage.removeItem('auth_token');
      // if (remember === true) {
      //   localStorage.setItem('auth_token', res.data.jwt_token);
      // } else {
      // sessionStorage.setItem('auth_token', res.data.jwt_token);
      // }
      localStorage.setItem('auth_token', res.data.jwt_token);

      store.dispatch({
        type: userConstants.USERS_LOGIN_SUCCESS,
        payload: {
          acls: [] /** TODO: use new backend permission system -> currently there is no permission system */,
          remember
        }
      });

      /** fetch user detail information */
      // store.dispatch({
      //   type: userConstants.USERS_DETAILS_REQUEST
      // });
      getUserDetails();

      return true;
    })
    .catch(error => {
      store.dispatch({
        type: userConstants.USERS_LOGIN_FAILURE,
        payload: { error: error }
      });
    });
}

/**
 * Updates userInformation.
 *
 * @param {string} userId //email of user
 * @param {object} user
 * @param {function} callbackFn //Callback is executed onSuccess
 *
 */
const patchUserInformation = (userId, user, callbackFn = () => {}) => {
  store.dispatch({
    type: userConstants.EDIT_USER_REQUEST,
    payload: { userId }
  });

  return axios
    .patch(`/users/${userId}`, user)
    .then(res => {
      const user = res.data;
      store.dispatch({ type: userConstants.EDIT_USER_SUCCESS, payload: user });
      callbackFn();
      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: userConstants.EDIT_USER_FAILURE,
        payload: 'Something went wrong while fetching user edit data'
      });
    });
};

/**
 * Loads user Details of the currently logged-in user and stores them to the redux store.
 *
 */
async function getUserDetails() {
  store.dispatch({
    type: userConstants.USERS_DETAILS_REQUEST
  });

  try {
    const result = await axios.get('/users/me');
    const user = result.data;
    user.username = user.email;

    store.dispatch({
      type: userConstants.USERS_DETAILS_SUCCESS,
      payload: {
        user
      }
    });
  } catch (error) {
    store.dispatch({
      type: userConstants.USERS_DETAILS_FAILURE,
      payload: { error }
    });
  }
}

/**
 * Logout a user.
 *
 */
function logout() {
  store.dispatch({
    type: userConstants.USERS_LOGOUT_REQUEST
  });

  axios
    .delete(`/auth`)
    .then(res => {
      localStorage.removeItem('auth_token');
      // sessionStorage.removeItem('auth_token');

      store.dispatch({
        type: userConstants.USERS_LOGOUT_SUCCESS
      });
    })
    .catch(err => {
      store.dispatch({
        type: userConstants.USERS_LOGOUT_FAILURE,
        payload: { err }
      });
    });
}

// todo: Add similar functionality for new backend
function resetPassword({ username, pw, pw1, pw2, captcha }) {
  store.dispatch({
    type: userConstants.USERS_RESET_PASSWORD_REQUEST,
    payload: {}
  });

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'login'
  };

  const fd = getFormData({
    username,
    captcha,
    pw,
    pw1,
    pw2
  });

  //Todo: Handle error response
  return axios
    .post(`/jsonrpc.php`, fd, { params: pca })
    .then(res => {
      const { result } = res.data;
      const actionResult = result.program.User.XIndex.login;

      if (actionResult?.status ?? false) {
        /**
         * The parent site only handles the first login,
         * all data is stored in the subsites
         */
        const sites = res.data.result?.toolbox?.sites ?? {};
        if (sites?.smoove) delete sites.smoove;
        if (Object.keys(sites).length === 0) {
          throw new Error('No sites available');
        }

        store.dispatch({
          type: userConstants.USERS_RESET_PASSWORD_SUCCESS,
          payload: {
            acls: result.toolbox.navi.actions,
            sites: sites
          }
        });

        /**
         * Call site switch if only one site available
         */
        const siteKeys = Object.keys(sites);
        if (siteKeys.length === 1) {
          userActions.switchSite(siteKeys[0]);
        }
      } else {
        const pwexpired = !!actionResult?.messages?.find(message => message.code === 'pwexpired') ?? false;
        const captchaInvalid = !!actionResult?.data?.captcha?.errors;
        const captcha = actionResult?.data?.captcha?.required === true;
        const newPasswordInvalid = !!actionResult?.data?.pw1?.errors;

        store.dispatch({
          type: userConstants.USERS_RESET_PASSWORD_FAILURE,
          payload: {
            pwexpired: pwexpired,
            captcha: captcha,
            captchaInvalid: captchaInvalid,
            newPasswordInvalid: newPasswordInvalid,
            error: false
          }
        });
      }
    })
    .catch(err => {
      store.dispatch({
        type: userConstants.USERS_LOGIN_FAILURE,
        payload: { error: err }
      });
    });
}

export const userActions = {
  login,
  logout,
  resetPassword,
  getUserDetails,
  patchUserInformation
};
