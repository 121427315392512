import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';

import { useControlledState, stripHtml } from 'smv-helpers';

import { HeadQuestionSettingsButton, HeadQuestionSettingsModal } from '../modals';
import { SmvCustomDropdown } from '../../../misc';
import { CalculationMenu } from '../result-rows/menus';

export const ResultTableDefaultHeadHeader = ({
  element,
  columns,
  colSpan = 1,
  head,
  headid,
  handler,
  showDeleteButton = true,
  isCompactMatrix = false,
  headConfig,
  tableConfig
}) => {
  let label;

  const [SettingsButton, SettingsModal] = useControlledState(HeadQuestionSettingsButton, HeadQuestionSettingsModal);

  const surveyid = useSelector(state => state.survey?.id || false);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyid]?.selectedLocale ?? state.survey.locales.main
  );
  const translations = useSelector(state => state.survey?.translations[selectedLocale]);

  if (element.type === 'question') {
    label = stripHtml(element.title);
  } else if (element.type === 'sysvar' || element.type === 'calcvar') {
    label = element.label;
  } else if (element.type === 'calculation') {
    label = element.title;
  }

  if (['question', 'sysvar', 'calcvar'].includes(element.type)) {
    return (
      <th scope='col' colSpan={colSpan} className={'head__header'}>
        <div className={'head__header_label'}>
          <span className={`head__header_label--label`}>{label}</span>
          <SmvCustomDropdown>
            <DropdownItem>
              <SettingsButton mode={'edit'} />
            </DropdownItem>
            {showDeleteButton && (
              <DropdownItem onClick={() => handler.removeElement(headid, 'heads')}>
                <div className='smv-custom-dropdown__buttons'>
                  <i className='fal fa-trash-alt mr-1' />
                  <span>{`remove`}</span>
                </div>
              </DropdownItem>
            )}
          </SmvCustomDropdown>
          <SettingsModal
            headid={headid}
            subelements={columns}
            element={element}
            handler={handler}
            translation={translations}
            isCompactMatrix={isCompactMatrix}
            headConfig={headConfig}
            tableConfig={tableConfig}
          />
        </div>
      </th>
    );
  } else if (element.type === 'calculation') {
    const calculationOrderIndex =
      (tableConfig.config.calculationOrder.findIndex(orderItem => orderItem.elementId === element.id) ?? 0) + 1;

    return (
      <th scope='col' colSpan={colSpan} className={'head__header'}>
        <div className={'head__header_label'}>
          <span className={`head__header_label--label`}>{`${label} (${calculationOrderIndex})`}</span>
          <CalculationMenu handler={handler} calculationMode={'heads'} tableConfig={tableConfig} tableElement={head} />
        </div>
      </th>
    );
  } else {
    return null;
  }
};
