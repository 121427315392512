import React from 'react';

import { stripHtml } from 'smv-helpers';

export const ResultTableChartHeadHeader = ({ element, colSpan = 1 }) => {
  let label;

  if (element.type === 'question') {
    label = stripHtml(element.title);
  } else if (element.type === 'sysvar' || element.type === 'calcvar') {
    label = element.label;
  } else if (element.type === 'calculation') {
    label = element.title;
  }

  return (
    <th scope='col' colSpan={colSpan} className={'head__header'}>
      <div className={'head__header_label'}>
        <span className={`head__header_label--label`}>{label}</span>
      </div>
    </th>
  );
};
