// https://en.wikipedia.org/wiki/Chi-squared_distribution
export const significanceGates = {
  1: { 0.7: 1.07, 0.8: 1.64, 0.9: 2.71, 0.95: 3.84, 0.975: 5.02, 0.99: 6.63, 0.995: 7.88 }
  // 2: { 0.9: 4.61, 0.95: 5.99, 0.975: 7.38, 0.99: 9.21, 0.995: 10.6 },
  // 3: { 0.9: 6.25, 0.95: 7.81, 0.975: 9.35, 0.99: 11.34, 0.995: 12.84 },
  // 4: { 0.9: 7.78, 0.95: 9.49, 0.975: 11.14, 0.99: 13.28, 0.995: 14.86 },
  // 5: { 0.9: 9.24, 0.95: 11.07, 0.975: 12.83, 0.99: 15.09, 0.995: 16.75 },
  // 6: { 0.9: 10.64, 0.95: 12.59, 0.975: 14.45, 0.99: 16.81, 0.995: 18.55 },
  // 7: { 0.9: 12.02, 0.95: 14.07, 0.975: 16.01, 0.99: 18.48, 0.995: 20.28 },
  // 8: { 0.9: 13.36, 0.95: 15.51, 0.975: 17.53, 0.99: 20.09, 0.995: 21.95 },
  // 9: { 0.9: 14.68, 0.95: 16.92, 0.975: 19.02, 0.99: 21.67, 0.995: 23.59 },
  // 10: { 0.9: 15.99, 0.95: 18.31, 0.975: 20.48, 0.99: 23.21, 0.995: 25.19 },
  // 11: { 0.9: 17.28, 0.95: 19.68, 0.975: 21.92, 0.99: 24.73, 0.995: 26.76 },
  // 12: { 0.9: 18.55, 0.95: 21.03, 0.975: 23.34, 0.99: 26.22, 0.995: 28.3 },
  // 13: { 0.9: 19.81, 0.95: 22.36, 0.975: 24.74, 0.99: 27.69, 0.995: 29.82 },
  // 14: { 0.9: 21.06, 0.95: 23.68, 0.975: 26.12, 0.99: 29.14, 0.995: 31.32 },
  // 15: { 0.9: 22.31, 0.95: 25, 0.975: 27.49, 0.99: 30.58, 0.995: 32.8 },
  // 16: { 0.9: 23.54, 0.95: 26.3, 0.975: 28.85, 0.99: 32, 0.995: 34.27 },
  // 17: { 0.9: 24.77, 0.95: 27.59, 0.975: 30.19, 0.99: 33.41, 0.995: 35.72 },
  // 18: { 0.9: 25.99, 0.95: 28.87, 0.975: 31.53, 0.99: 34.81, 0.995: 37.16 },
  // 19: { 0.9: 27.2, 0.95: 30.14, 0.975: 32.85, 0.99: 36.19, 0.995: 38.58 },
  // 20: { 0.9: 28.41, 0.95: 31.41, 0.975: 34.17, 0.99: 37.57, 0.995: 40 },
  // 21: { 0.9: 29.62, 0.95: 32.67, 0.975: 35.48, 0.99: 38.93, 0.995: 41.4 },
  // 22: { 0.9: 30.81, 0.95: 33.92, 0.975: 36.78, 0.99: 40.29, 0.995: 42.8 },
  // 23: { 0.9: 32.01, 0.95: 35.17, 0.975: 38.08, 0.99: 41.64, 0.995: 44.18 },
  // 24: { 0.9: 33.2, 0.95: 36.42, 0.975: 39.36, 0.99: 42.98, 0.995: 45.56 },
  // 25: { 0.9: 34.38, 0.95: 37.65, 0.975: 40.65, 0.99: 44.31, 0.995: 46.93 },
  // 26: { 0.9: 35.56, 0.95: 38.89, 0.975: 41.92, 0.99: 45.64, 0.995: 48.29 },
  // 27: { 0.9: 36.74, 0.95: 40.11, 0.975: 43.19, 0.99: 46.96, 0.995: 49.65 },
  // 28: { 0.9: 37.92, 0.95: 41.34, 0.975: 44.46, 0.99: 48.28, 0.995: 50.99 },
  // 29: { 0.9: 39.09, 0.95: 42.56, 0.975: 45.72, 0.99: 49.59, 0.995: 52.34 },
  // 30: { 0.9: 40.26, 0.95: 43.77, 0.975: 46.98, 0.99: 50.89, 0.995: 53.67 },
  // 31: { 0.9: 41.42, 0.95: 44.99, 0.975: 48.23, 0.99: 52.19, 0.995: 55 },
  // 32: { 0.9: 42.59, 0.95: 46.19, 0.975: 49.48, 0.99: 53.49, 0.995: 56.33 },
  // 33: { 0.9: 43.75, 0.95: 47.4, 0.975: 50.73, 0.99: 54.78, 0.995: 57.65 },
  // 34: { 0.9: 44.9, 0.95: 48.6, 0.975: 51.97, 0.99: 56.06, 0.995: 58.96 },
  // 35: { 0.9: 46.06, 0.95: 49.8, 0.975: 53.2, 0.99: 57.34, 0.995: 60.28 },
  // 36: { 0.9: 47.21, 0.95: 51, 0.975: 54.44, 0.99: 58.62, 0.995: 61.58 },
  // 37: { 0.9: 48.36, 0.95: 52.19, 0.975: 55.67, 0.99: 59.89, 0.995: 62.88 },
  // 38: { 0.9: 49.51, 0.95: 53.38, 0.975: 56.9, 0.99: 61.16, 0.995: 64.18 },
  // 39: { 0.9: 50.66, 0.95: 54.57, 0.975: 58.12, 0.99: 62.43, 0.995: 65.48 },
  // 40: { 0.9: 51.8, 0.95: 55.76, 0.975: 59.34, 0.99: 63.69, 0.995: 66.77 }
};
