import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { chartLayersActions, surveyActions } from '../../redux/actions';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';
import { Data, Questionnaire, Reports, Tables } from '..';
import { SurveySettings } from './SurveySettings';

export const Survey = ({ match }) => {
  const { path, params } = match;
  const paramsSurveyId = params.surveyid;
  const surveyId = useSelector(state => state.survey?.id);
  const isLoading = useSelector(state => state.survey?.loading);
  const surveyError = useSelector(state => state.survey?.error);
  const questionnaireId = useSelector(state => state.survey?.questionnaire?.id);

  useEffect(() => {
    if (paramsSurveyId && (paramsSurveyId !== surveyId || !questionnaireId)) {
      if (!isLoading && !surveyError) {
        surveyActions.getSurvey(paramsSurveyId, {
          questionnaire: '1',
          translations: '1',
          data: '1',
          tablesFolders: '1',
          tables: '1',
          reports: '1'
        });
        chartLayersActions.loadChartLayers(paramsSurveyId);
      }
    }
  }, [paramsSurveyId, surveyId, isLoading, questionnaireId, surveyError]);

  if (surveyError)
    return (
      <div className='container-fluid base-content flex-fill'>
        {parseErrorAsMessageString(
          surveyError,
          'It seems like you do not have access to this page. Please refresh the page and try selecing a different project.'
        )}
      </div>
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <Switch>
        <Route path={`${path}/survey`} component={SurveySettings} />
        <Route path={`${path}/que`} component={Questionnaire} />
        <Route path={`${path}/data`} component={Data} />
        <Route path={`${path}/tables/:tableid?`} component={Tables} />
        <Route path={`${path}/reports/:reportid?/pages/:pageid?`} component={Reports} />
        <Route path={`${path}/reports/:reportid?`} component={Reports} />
      </Switch>
    </DndProvider>
  );
};

// const Loading = () => (
//   <div style={{ height: '90vh' }} className='d-flex justify-content-center align-items-center'>
//     <span className='fas fa-spinner fa-spin fa-3x' />
//   </div>
// );
