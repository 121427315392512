import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const MatrixItemsScalePreview = ({ question }) => {
  const itemOrder = question?.config?.choices?.order ?? [];
  const scaleOrder = question?.config?.scales?.order ?? [];
  const list = useSelector(state => state.survey.questionnaire.list);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = useCallback(
    id => {
      let _selected = [...selectedItems];
      if (_selected.includes(id)) {
        _selected = _selected.filter(el => el !== id);
      } else {
        if (question.config?.qtype === 'single_matrix') {
          const choiceRegex = /([^__]+)/;
          const choiceId = id.match(choiceRegex)?.[0];
          selectedItems.forEach(item => {
            const match = item.match(choiceRegex);
            if (match.length > 0 && match[0] === choiceId) {
              _selected = _selected.filter(el => el !== item);
            }
          });
        }
        _selected.push(id);
      }
      setSelectedItems(_selected);
    },
    [selectedItems, question.config?.qtype]
  );

  const importedChoices = useMemo(() => {
    const importedSource = question.config?.choices?.import?.source;
    const sourceQuestion = list?.[importedSource];
    let _importedChoices = [];

    if (!sourceQuestion || !importedSource) return null;

    if (sourceQuestion.config?.choices?.order && sourceQuestion.config.choices.order.length > 0) {
      sourceQuestion.config.choices.order.forEach(choiceid => {
        _importedChoices.push(
          <MatrixChoice
            key={choiceid}
            choiceid={choiceid}
            question={question}
            importedSourceQuestion={sourceQuestion}
            selectedItems={selectedItems}
            handleSelectItem={handleSelectItem}
          />
        );
      });
    }
    return _importedChoices;
  }, [list, question, selectedItems, handleSelectItem]);

  return (
    <table className='matrix-preview'>
      <thead>
        <tr>
          <td className='head-spacer'></td>
          {scaleOrder?.map((id, index) => {
            return <ScaleLabel key={id} scaleid={id} question={question} />;
          })}
        </tr>
      </thead>
      <tbody>
        {importedChoices}
        {itemOrder?.map((id, index) => {
          return (
            <MatrixChoice
              key={id}
              choiceid={id}
              question={question}
              selectedItems={selectedItems}
              handleSelectItem={handleSelectItem}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const ScaleLabel = ({ scaleid, question }) => {
  const choice = question.config?.scales?.list?.[scaleid];

  const scaleLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });
  return (
    <td className='scale__label'>
      <div dangerouslySetInnerHTML={{ __html: scaleLabel }}></div>
      {/* <EditableTextHover
    value={scaleLabel}
    writeable={false}
    onBlur={() => { }}
    className={'no-padding'}
  /> */}
    </td>
  );
};

const MatrixChoice = ({ choiceid, question, importedSourceQuestion, selectedItems, handleSelectItem }) => {
  let choice = question.config?.choices?.list?.[choiceid];
  let scaleOrder = question?.config?.scales?.order ?? [];

  if (importedSourceQuestion) {
    choice = importedSourceQuestion.config?.choices?.list?.[choiceid];
    scaleOrder = question?.config?.scales?.order ?? [];
  }

  const width = 100 / scaleOrder.length + 1;
  const qtype = question?.config?.qtype;
  const alternateBackgroundColors = question.config?.alternateBackgroundColors;

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  if (!choice) {
    return null;
  }

  return (
    <tr
      className={`matrix-row previewitem__choice ${
        alternateBackgroundColors ? 'matrix-row--alternate-background' : ''
      }`}
    >
      <td>
        <span className='choice__label'>
          <div dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
        </span>
      </td>
      {scaleOrder.map(scaleid => {
        const choiceScaleId = `${choiceid}__${scaleid}`;
        const isActive = selectedItems.includes(choiceScaleId);
        return (
          <td
            className='matrix-choice'
            key={choiceScaleId}
            onClick={() => handleSelectItem(choiceScaleId)}
            style={{ width: `${width}%` }}
          >
            <span className={`choice ${isActive ? 'choice--active' : ''}`}>
              {qtype === 'single_matrix' && <i className={`circle`} />}
              {qtype === 'multiple_matrix' && <i className={`checkbox`} />}
            </span>
          </td>
        );
      })}
    </tr>
  );
};
