import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import axios from 'axios';

import { BillingCoinIcon } from './BillingCoinIcon';
import './BillingDetailedSurveyCosts.scss';
import { useEffect, useMemo, useState } from 'react';

export const BillingDetailedSurveyCosts = () => {
  const survey = useSelector(state => state.survey);

  const [surveyCosts, setSurveyCosts] = useState({
    basePrice: 0,
    samplingCost: 0,
    total: 0
  });

  // const [surveyPaidCosts, setSurveyPaidCosts] = useState(0);
  // const [surveyRestCosts, setSurveyRestCosts] = useState(0);

  useEffect(() => {
    if (survey.id) {
      axios
        .post(`/surveys/${survey.id}/credits/costs`)
        .then(res => {
          setSurveyCosts(res.data);
        })
        .catch(err => {
          console.log(err);
          setSurveyCosts({
            basePrice: 0,
            samplingCost: 0,
            total: 0
          });
        });
    }
  }, [survey.id, survey.locales, survey.includeSampling]);

  const surveyPaidCosts = useMemo(() => {
    if (survey.id && survey.billing?.history?.length > 0) {
      return survey.billing.history.reduce((sum, data) => {
        return sum + (data.paid ?? 0);
      }, 0);
    }
    return 0;
  }, [survey?.id, survey.billing?.history]);

  const surveyRestCosts = useMemo(() => {
    if (survey.id && survey.billing?.history?.length > 0) {
      return (surveyCosts?.total ?? 0) - surveyPaidCosts;
    }
    return 0;
  }, [survey?.id, survey.billing?.history, surveyCosts?.total, surveyPaidCosts]);

  return (
    <>
      <Table className='billing-survey-cost-table' striped>
        <thead>
          <tr>
            <th>Item</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Survey Fee</td>
            <td>{surveyCosts?.basePrice}</td>
          </tr>
          <tr>
            <td>Sampling Costs</td>
            <td>{surveyCosts?.samplingCost}</td>
          </tr>
          <tr>
            <td>Total survey cost</td>
            <td className='fw-bold'>
              <BillingCoinIcon /> {surveyCosts?.total}
            </td>
          </tr>
          {surveyPaidCosts > 0 && (
            <>
              <tr>
                <td>Paid</td>
                <td className='fw-bold' style={{ color: '#198754' }}>
                  <BillingCoinIcon className={'text-success'} /> {surveyPaidCosts} -
                </td>
              </tr>
              <tr>
                <td>Rest</td>
                <td className='fw-bold' style={surveyRestCosts > 0 ? { color: 'red' } : { color: 'green' }}>
                  <BillingCoinIcon /> {surveyRestCosts}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {/* <div style={{ fontSize: '0.75rem' }}>
        <p>
          <span className='far fa-circle-info mr-1'></span>
          Your have a total of <BillingCoinIcon /> <span className='fw-bold'>{availableCredits} </span> credits in your
          wallet. For more information about your available funds, please visit the "Organization settings" page.
        </p>
      </div> */}
    </>
  );
};
