import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { stripHtml } from 'smv-helpers';

export const FilterElement = ({ filterid, handler, activeFilters, reportid }) => {
  const report = useSelector(state => state.survey?.reports?.list?.[reportid] ?? null);
  const filter = report?.filters?.list?.[filterid];

  const [isInitialized, setIsInitialized] = useState(false);
  const [isOpen, setIsOpen] = useState(!!filter?.defaultSelected);
  const toggle = () => setIsOpen(!isOpen);

  const intl = useIntl();

  const surveyid = useSelector(state => state.survey?.id || false);
  const elements = useSelector(state => state.survey.questionnaire.list);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const locales = useSelector(state => state.survey.locales);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const selectedLocale = useSelector(state => state.projectSettings?.[surveyid]?.selectedLocale ?? locales.main);
  const translations = useSelector(state => state.survey?.translations);

  useEffect(() => {
    if (!isInitialized && filter?.defaultSelected) {
      setIsInitialized(true);
      handler.setActiveFilter(filter);
    }
  }, [handler, filter, isInitialized]);

  let label, element, values;
  if (filter.sourceType === 'question') {
    element = elements[filter.sourceId];
    label = filter?.label?.length > 0 ? filter.label : element?.title;
    values = {
      ...element?.config?.choices,
      ...elements[element?.config?.choices?.import?.source]?.config?.choices
    };
  } else if (filter.sourceType === 'sysvar') {
    element = systemVariables.list[filter.sourceId];
    label = filter?.label?.length > 0 ? filter?.label : element?.label ?? '';
    values = { list: element.list, order: element.order };
  } else if (filter.sourceType === 'calcvar') {
    element = calcVars?.find(el => el.id === filter.sourceId);
    label = filter?.label?.length > 0 ? filter?.label : element?.label ?? '';
    values = {
      order: element?.values?.map(el => el.id),
      list: Object.fromEntries(element?.values?.map(value => [value.id, value]))
    };
  }

  const isSingleSelect = filter?.isSingleSelect ?? false;
  const hidden = filter?.hidden;

  const _itemOrder = useMemo(() => {
    const _order = filter?.order ?? [];

    values.order.forEach(valueid => {
      if (!_order.includes(valueid)) {
        _order.push(valueid);
      }
    });

    return _order.filter(valueid => !(hidden?.[valueid] ?? false));
  }, [filter?.order, hidden, values]);

  if (!filter || _itemOrder.length <= 0) return null;

  return (
    <div className={'smv-filter-list__filter-element'}>
      <div className='smv-filter-list__filter-element__head'>
        <span className={'smv-filter-list__filter-element__head--label'} onClick={toggle}>
          <i className={`pl-1 far fa-chevron-${isOpen ? 'down' : 'right'}`} />
          <div className='mb-0 ml-2' style={{ fontSize: '.85rem' }}>
            {label ? label : <i>{intl.formatMessage({ id: 'smoove.page.reports.modals.filters.unnamed-element' })}</i>}
          </div>
        </span>
        {!!activeFilters?.[filterid] && (
          <span
            className={'smv-filter-list__filter-element__head--clear'}
            onClick={() => handler.setActiveFilter(filter)}
          >
            <i className={`icon-smv-blue far fa-trash`} />
          </span>
        )}
      </div>

      <Collapse isOpen={isOpen}>
        <div>
          {_itemOrder?.map((valueid, idx) => {
            const isActive = activeFilters?.[filterid]?.[valueid] === true;
            if (!values.list[valueid]) return null;

            let valueLabel;
            if (filter.sourceType === 'question') {
              valueLabel = stripHtml(translations[values.list[valueid]?.config?.translations?.label]?.[selectedLocale]);
            } else if (filter.sourceType === 'sysvar' || filter.sourceType === 'calcvar') {
              valueLabel = stripHtml(values.list[valueid]?.label);
            }

            return (
              <div
                key={`${valueid}_${idx}`}
                className='d-flex align-items-center pointer'
                onClick={() => handler.setActiveFilter(filter, valueid)}
              >
                <h5
                  className={classNames('p-1 mb-0 far', {
                    'fa-square': !isSingleSelect && !isActive,
                    'fa-check-square': !isSingleSelect && isActive,
                    'fa-circle': isSingleSelect && !isActive,
                    'fa-dot-circle': isSingleSelect && isActive
                  })}
                >
                  {' '}
                </h5>
                <div className='ml-2' style={{ fontSize: '0.85rem' }}>
                  {valueLabel}
                </div>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export const FilterElementInvalid = ({ filterid }) => {
  const intl = useIntl();

  console.log(`Invalid filter element with id ${filterid}`);

  return (
    <div className={'smv-filter-list__filter-element'}>
      <div className='smv-filter-list__filter-element__head'>
        <span className={'smv-filter-list__filter-element__head--label'}>
          <div className='mb-0 ml-2' style={{ fontSize: '.85rem' }}>
            <i>{intl.formatMessage({ id: 'smoove.page.reports.modals.filters.invalid-element' })}</i>
          </div>
        </span>
      </div>
    </div>
  );
};
