import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import produce from 'immer';

import 'tippy.js/themes/material.css';

import { getSchemeColor } from './helper';

import './WordCloud/material.scss';

export const WordcloudChart = ({ handler, elementid, chartConfig, table, tableResult }) => {
  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );
  const rowid = table.rows.order[0];
  const { colorSchema, colorSchemaReverse = false } = chartConfig;

  const _words = useMemo(() => {
    const wrds = [];
    tableResult.order.forEach(el => {
      if (!el.excluded && el.rowid === rowid) {
        const textVariations = tableResult.values[rowid][el.key]['__total__']['__total__'].variations;
        let label = tableResult.values[rowid][el.key]['__total__']['__total__'].key;
        if (textVariations) {
          const variationKeys = Object.keys(tableResult.values[rowid][el.key]['__total__']['__total__'].variations);
          const maxVariationCount = Math.max(...Object.values(textVariations));
          const maxVariationIndex = Object.values(textVariations).indexOf(maxVariationCount);
          label = variationKeys?.[maxVariationIndex];
        }

        wrds.push({
          key: tableResult.values[rowid][el.key]['__total__']['__total__'].key,
          text: label,
          value: tableResult.values[rowid][el.key]['__total__']['__total__'].smvrslt_abs,
          data: tableResult.values[rowid][el.key]['__total__']['__total__']
        });
      }
    });
    const blacklist = chartConfig.wordcloud?.blacklist;
    if (blacklist) {
      return wrds.filter(Boolean).filter(el => !blacklist.includes(el.key));
    }
    return wrds.filter(Boolean);
  }, [tableResult.values, rowid, tableResult.order, chartConfig.wordcloud?.blacklist]);

  const getWordColor = useCallback(
    word => {
      let value = Math.ceil(Math.random() * 20) / 20;
      if (colorSchemaReverse) value = 1 - value;
      const { color } = getSchemeColor({ colorScales, colorSchema, value });
      return color;
    },
    [colorScales, colorSchema, colorSchemaReverse]
  );

  const handleWordClick = useCallback(
    word => {
      if (!handler) return false;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (!draftState.chart?.wordcloud) draftState.chart.wordcloud = { blacklist: [] };
          draftState.chart.wordcloud.blacklist.push(word.key);
        })
      );
    },
    [handler]
  );

  const callbacks = {
    getWordColor,
    getWordTooltip: word => {
      return `<table>
      <tr>
        <th colspan="2">${word.text}</th>
      </tr>
      <tr>
        <th>Count:</th>
        <th>${word.value}</th>
      </tr>
      ${
        Object.keys(word.data.variations).length > 1
          ? `<tr>
          <td>Variations:</td>
          <td>${Object.keys(word.data.variations).join(', ')}</td>
        </tr>`
          : ''
      }
    </table>`;
    },
    onWordClick: handleWordClick
    // onWordMouseOver: (a, b, c) => console.log(a, b, c),
    // onWordMouseOut: (a, b, c) => console.log(a, b, c)
  };

  // https://react-wordcloud.netlify.app/props#options
  const options = useMemo(() => {
    const wordcloudFontSizes = chartConfig.wordcloud?.wordcloudFontSizes ?? [10, 50];

    return {
      deterministic: true,
      fontSizes: wordcloudFontSizes,
      rotations: 10,
      rotationAngles: [-90, 90],
      tooltipOptions: {
        theme: 'material',
        allowHTML: true
      }
    };
  }, [chartConfig.wordcloud?.wordcloudFontSizes]);
  // const size = [600, 400];

  return (
    <>
      <ReactWordcloud
        callbacks={callbacks}
        options={options}
        // size={size}
        words={_words}
      />
    </>
  );
};
