import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import logo from 'smv-assets/img/smoove.png';

import './Footer.scss';

export const Footer = () => {
  const emoji = useRandomEmoji();

  const loggedIn = useSelector(state => state.authentication.loggedIn);

  return (
    <div className='base-footer-container container-fluid'>
      <a href='/' className='base-footer-container__logo' draggable={false}>
        <img src={logo} height={`24px`} alt='mindline analytics' />
        <small className='small fw-lighter text-muted' style={{ fontSize: '0.7em', paddingLeft: '10px' }}>
          {`v${global.REACT_APP_VERSION}`}
        </small>
      </a>

      <div className='base-footer-container__links'>
        <Link to={'/one/imprint/'} draggable={false}>
          <FormattedMessage id={'core.link.label.imprint'} defaultMessage={'Imprint'} />
        </Link>
        <div className='footer-separator'>|</div>
        <Link to={'/one/gdpr'} draggable={false}>
          <FormattedMessage id={'core.link.label.gdpr'} defaultMessage={'Data Protection & Privacy'} />
        </Link>
        {/* <div className='footer-separator'>|</div>
        <Link to={'/one/gtc'} draggable={false}>
          <FormattedMessage id={'core.link.label.gtc'} defaultMessage={'Terms & Conditions'} />
        </Link> */}
        {loggedIn && (
          <>
            <div className='footer-separator'>|</div>
            <Link to={'/one/help'} draggable={false}>
              <FormattedMessage id={'core.link.label.help'} defaultMessage={'Contact'} />
            </Link>
          </>
        )}
      </div>

      <div className='base-footer-container__info'>
        <span>&copy; 2024 mindline analytics</span>
        <small className='base-footer-container__emoji'>Made with {emoji} in Hamburg, Germany.</small>
      </div>
    </div>
  );
};

const useRandomEmoji = () => {
  const [emoji, setEmoji] = useState(null);

  useEffect(() => {
    const getEmoji = () => {
      const emojis = ['❤️', '🦄', '🚀', '🍺', '🏳️‍🌈'];
      return emojis[Math.floor(Math.random() * emojis.length)];
    };

    setEmoji(getEmoji);
  }, []);

  return emoji;
};
