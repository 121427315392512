import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { stripHtml } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

import {
  ResultTableChartQuestionSelectionDetails,
  ResultTableChartResultRow,
  ResultTableChartResultRowHeader
} from '.';

export const ResultTableChartMatrixDetailResultRow = ({
  element,
  table,
  chartConfig,
  tableResult,
  row,
  questionHas,
  hover
}) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { heads, config } = table;

  const significancesShow = chartConfig?.significancesShow ?? false;

  // const { head, config } = tableConfig;

  const { showCodeValuesRow = false } = config;
  const { id, config: rowConfig } = row;
  const { sortation } = rowConfig;

  const rowOrder = useMemo(() => tableResult?.order?.filter(row => row.rowid === id) ?? [], [tableResult?.order, id]);

  const defaultMetric = useDefaultMetric(table);

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};
  let subelements = element?.config?.choices ?? {};
  let _head = heads;

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const subelementsOrder = useMemo(() => {
    let _subelementsOrder = subelements?.order ?? [];
    /** check for manual row sortation or default order */
    if (sortation?.sortationMode === 'manual' && !!sortation?.manualSortation?.order) {
      const _subelementOrderElementsNotSorted = [..._subelementsOrder].filter(
        subelementid => !sortation.manualSortation.order.includes(subelementid)
      );

      _subelementsOrder = [...sortation.manualSortation.order, ..._subelementOrderElementsNotSorted];
    }

    return _subelementsOrder;
  }, [subelements?.order, sortation]);

  const rowHeadLabel = stripHtml(element.title);

  /** Calculate Rowspans */
  const { totalSpan, rowSpans } = useMemo(() => {
    let totalSpan = 0,
      rowSpans = {};

    subelementsOrder.forEach(choiceid => {
      let isHiddenChoice = rowConfig?.hidden?.[choiceid] === true;

      const visibleChoiceOrderItems = rowOrder.filter(
        order => order.subelement === choiceid && order?.hidden !== true && order?.excluded !== true
      );
      if (visibleChoiceOrderItems.length === 0) isHiddenChoice = true;

      if (isHiddenChoice) {
        rowSpans[choiceid] = 0;
      } else {
        totalSpan += visibleChoiceOrderItems.length;
        rowSpans[choiceid] = visibleChoiceOrderItems.length;
      }
    });

    return { totalSpan, rowSpans };
  }, [rowOrder, subelementsOrder, rowConfig]);

  const _rows = [];

  subelementsOrder.forEach(choiceid => {
    let isHiddenChoice = rowConfig?.hidden?.[choiceid] === true;
    const visibleChoiceOrderItems = rowOrder.filter(
      order => order.subelement === choiceid && order?.hidden !== true && order?.excluded !== true
    );
    if (visibleChoiceOrderItems.length === 0) isHiddenChoice = true;

    if (isHiddenChoice) return null;

    const _choiceRows = [];

    visibleChoiceOrderItems.forEach(order => {
      const isHiddenScale = order?.hidden ?? false;
      const isExcludedScale = order?.excluded ?? false;

      if (isHiddenScale || isExcludedScale) return null;

      const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subsubelement] ?? null;
      let metric = defaultMetric;
      if (order.value === 'mean') metric = 'smvrslt_mean';

      let subelement = {};
      if (calcFrequencyGroups?.[order?.subsubelement]) {
        subelement = calcFrequencyGroups[order.subsubelement];
      } else if (element?.config?.scales?.list?.[order?.subsubelement]) {
        subelement = element.config.scales.list[order.subsubelement];
      }

      _choiceRows.push(
        <ResultTableChartResultRow
          key={`result-row-${order.rowid}_${order.subelement}_${order.subsubelement}`}
          rowid={id}
          orderItem={order}
          subelement={subelement}
          choiceid={choiceid}
          scaleid={order.subsubelement}
          config={config}
          heads={_head}
          tableResult={tableResult}
          conditionalFormats={chartConfig.conditionalFormats}
          metric={metric}
          isCompactMatrix={false}
          isDetailedMatrix={true}
          matrixType={'detail'}
          sortationPinned={sortationPinned}
          significancesShow={significancesShow}
          hover={hover}
        >
          <>
            {_rows.length === 0 && _choiceRows.length === 0 && chartConfig.showQuestionTitleColumn && (
              <ResultTableChartResultRowHeader
                rowid={id}
                row={row}
                element={element}
                label={rowHeadLabel}
                rowSpan={totalSpan}
                questionHas={questionHas}
                questionSelectionDetails={
                  <ResultTableChartQuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
                }
              />
            )}
            {_choiceRows.length === 0 && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) && (
              <ResultTableChartResultRowHeader
                rowid={id}
                row={row}
                orderItem={{ value: subelements?.list?.[choiceid]?.value }}
                subelement={subelements?.list?.[choiceid]}
                rowSpan={rowSpans[choiceid]}
                element={element}
                showCodeValuesRow={showCodeValuesRow}
              />
            )}
          </>
        </ResultTableChartResultRow>
      );
    });

    _rows.push(..._choiceRows);
  });

  return _rows;
};

ResultTableChartMatrixDetailResultRow.propTypes = {
  element: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  tableResult: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  questionHas: PropTypes.object.isRequired
};
