import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch, generatePath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { QuestionConfigModal, ContainerConfigModal, PageConfigModal } from '.';
import { getQuestionnaireElementsOrder } from 'smv-helpers';

export const ElementConfigSwitch = ({ writeable }) => {
  const { elementid } = useParams();
  const { path } = useRouteMatch();
  const routeParams = useParams();
  const history = useHistory();

  const element = useSelector(state => state.survey.questionnaire.list?.[elementid]);
  const questionnaire = useSelector(state => state.survey.questionnaire);

  const questionnaireOrder = getQuestionnaireElementsOrder(questionnaire);

  const currentIndex = questionnaireOrder.indexOf(elementid);
  const prevElementId = questionnaireOrder[currentIndex - 1];
  const nextElementId = questionnaireOrder[currentIndex + 1];

  const generateNewPath = useCallback(
    id => {
      return generatePath(`${path}`, {
        ...routeParams,
        elementid: id,
        section: 'config'
      });
    },
    [path, routeParams]
  );

  useEffect(() => {
    const handleKeyDown = event => {
      const activeElement = document.activeElement;
      if (
        activeElement &&
        ((activeElement.tagName === 'INPUT' && activeElement.type === 'text') ||
          (activeElement.tagName === 'TEXTAREA' && activeElement.type === 'text') ||
          activeElement.classList.contains('ql-editor') ||
          activeElement.contentEditable === 'true')
      ) {
        return;
      }

      if (event.key === 'ArrowLeft' && prevElementId) {
        history.push(generateNewPath(prevElementId));
      } else if (event.key === 'ArrowRight' && nextElementId) {
        history.push(generateNewPath(nextElementId));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [prevElementId, nextElementId, history, generateNewPath]);

  const toggleModal = () => {
    history.push(generatePath(path.replace('/:elementid/:section?', ''), routeParams));
  };

  return (
    <Modal
      isOpen={true}
      centered={true}
      size={'xl'}
      className={'config-modal-container'}
      toggle={toggleModal}
      onClick={() => {
        // Modal stops propogation of click event, the click event is used by ReactQuill to hide tooltip
        document.body.click();
      }}
    >
      <div className='config-modal-question-navigation-container'>
        {prevElementId && (
          <Link
            to={generateNewPath(prevElementId)}
            className={`config-modal-question-navigation-container__btn-previous ${
              currentIndex === 0 ? 'config-modal-question-navigation-container__btn-previous--disabled' : ''
            }`}
          >
            <i className='fal fa-arrow-left' />
          </Link>
        )}
        {nextElementId && (
          <Link
            to={generateNewPath(nextElementId)}
            className={`config-modal-question-navigation-container__btn-next ${
              currentIndex >= questionnaireOrder.length
                ? 'config-modal-question-navigation-container__btn-next--disabled'
                : ''
            }`}
          >
            <i className='fal fa-arrow-right' />
          </Link>
        )}
      </div>
      {element?.type === 'question' && <QuestionConfigModal question={element} writeable={writeable} />}
      {element?.type === 'container' && <ContainerConfigModal container={element} writeable={writeable} />}
      {element?.type === 'page' && <PageConfigModal page={element} writeable={writeable} />}
      {!['question', 'container', 'page'].includes(element?.type) && <div>Unsupported element type.</div>}
    </Modal>
  );
};
