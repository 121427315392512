import React, { useCallback, useState } from 'react';
import { Structure, QuestionnaireElementsBar } from '.';

import './Sidebar.scss';

export const Sidebar = ({ style, writeable }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = useCallback(() => setIsSidebarOpen(state => !state), [setIsSidebarOpen]);

  return (
    <div className={`questionaire-sidebar ${!isSidebarOpen ? 'questionaire-sidebar--closed' : ''}`} style={style}>
      <Structure writeable={writeable} />
      <QuestionnaireElementsBar toggleSidebar={toggleSidebar} writeable={writeable} />
    </div>
  );
};
