import React from 'react';
import classNames from 'classnames';

import { useSubelementPropertyTranslation, getSortingIconString } from 'smv-helpers';

export const ResultTableDefaultHeadColumnHeader = ({
  headid,
  column,
  toggleHidden = null,
  sortTable = null,
  isHidden,
  config
}) => {
  const { sortation = {}, showCodeValuesHead = false } = config;

  const label = useSubelementPropertyTranslation(column, null, {
    showCodeValue: showCodeValuesHead,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <th className={classNames('head__column', { head__column_hidden: isHidden })}>
      <span className={'head__column_content'}>
        <span onClick={sortTable} className='pr-1 pointer'>
          {label}
        </span>
        {sortation?.headElemId === headid && sortation?.headChoiceId === column.id && (
          <i onClick={sortTable} className={`fal fa-${getSortingIconString(sortation?.direction)} mr-1 pointer`}></i>
        )}
        {toggleHidden && (
          <i
            className={classNames({
              'pointer': true,
              'fal fa-eye-slash': isHidden,
              'fal fa-eye': !isHidden
            })}
            onClick={toggleHidden}
          />
        )}
      </span>
    </th>
  );
};
