import React, { useState, useEffect, useCallback } from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { ConfigScaleItem } from './ConfigScaleItem';
import { arrayMoveImmutable } from 'array-move';
import { questionnaireActions } from 'smv-redux/actions';
import { NewItem } from '../../../../question/components';
// import { useIntl } from 'react-intl';

import './ConfigScaleItem.scss';
import { FormattedMessage } from 'react-intl';

// const defaultNotifyProps = {
//   insert: 'bottom',
//   container: 'bottom-center',
//   animationIn: ['animate__animated', 'animate__fadeIn'],
//   animationOut: ['animate__animated', 'animate__fadeOut'],
//   dismiss: {
//     duration: 3000,
//     showIcon: true
//   }
// };

export const ConfigScale = ({ question, expertMode, writeable, surveyid }) => {
  const [order, setOrder] = useState([]);
  // const intl = useIntl();

  const [paginationLimit, setPaginationLimit] = useState(10);

  useEffect(() => {
    if (question.config?.scales?.order) {
      setOrder(question.config?.scales?.order);
    }
  }, [question]);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;
      const newOrder = arrayMoveImmutable(order, source.index, destination.index);
      setOrder(newOrder);

      questionnaireActions.updateScale(surveyid, question.id, order[source.index], { position: destination.index });

      // surveyActions.moveSubElement(surveyid, question.id, order[source.index], 'scale', targetid).then(res => {
      //   if (res.status === 'error') {
      //     notify.addNotification({
      //       title: intl.formatMessage({
      //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-title',
      //         defaultMessage: 'An error occured.'
      //       }),
      //       message: intl.formatMessage({
      //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-message',
      //         defaultMessage: 'The move operation was unsuccessful.'
      //       }),
      //       type: 'danger',
      //       ...defaultNotifyProps
      //     });
      //     // incase of error revert to previous order
      //     setOrder(order);
      //   }
      // });
    },
    [order, surveyid, question.id]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <hr />
        <div className='config-scale__headline'>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.choices.scale`} defaultMessage={'Scale'} />
        </div>
        <div>
          <MakeDroppable droppableId='scale-item-droppable' writeable={writeable}>
            {order.slice(0, paginationLimit).map((id, index) => {
              const scale = question.config?.scales?.list?.[id];
              if (!scale) return null;

              return (
                <MakeDraggable key={id} draggableId={id} index={index} isDragDisabled={!writeable}>
                  <ConfigScaleItem
                    key={`scaleitem_${id}`}
                    index={index}
                    question={question}
                    scale={scale}
                    writeable={writeable}
                  />
                </MakeDraggable>
              );
            })}
            {order.length > paginationLimit && (
              <small className='text-smv-blue my-1 ml-2 pointer' onClick={() => setPaginationLimit(b => b + 50)}>
                <i className='fal mr-2 fa-angle-double-down' />
                <span>
                  ...show {order.length - paginationLimit > 50 ? '50' : (order.length - paginationLimit).toString()}{' '}
                  more items (total: {order.length.toString()})
                </span>
              </small>
            )}
          </MakeDroppable>
          {writeable && <NewItem question={question} type={'scale'} renderInConfigModal={true} />}
        </div>
      </div>
    </DragDropContext>
  );
};

const MakeDraggable = ({ children, draggableId, index, isDragDisabled }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style
          }}
          className='draggable-default'
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId, writeable }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={!writeable}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`droppable-default ${writeable ? 'config-scale-droppable' : 'config-scale-droppable--disabled'}`}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
