import React from 'react';
import { Col, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

import { ChartLayersButtonAndModal } from './modals';
export const ChartLayersConfig = ({ tableConfig, chartConfig, resultTableType, handler }) => {
  const intl = useIntl();

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.chart-config.layers.title` })}</Col>
          <Col md={7}>
            <ChartLayersButtonAndModal tableConfig={tableConfig} chartConfig={chartConfig} handler={handler} />
          </Col>
        </Row>
      </ListGroupItemHeading>
    </ListGroupItem>
  );
};
