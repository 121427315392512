export const getFormattedValue = (value, { valuePrecision = 0, valueForcePrecision = false }) => {
  if (!value) return '-';

  let formattedValue = value;
  if (!Number.isInteger(value)) {
    formattedValue = Math.round(value * Math.pow(10, valuePrecision)) / Math.pow(10, valuePrecision);
  }

  if (valuePrecision > 0 && valueForcePrecision) {
    formattedValue = formattedValue.toFixed(valuePrecision);
  }

  return `${formattedValue}`;
};
