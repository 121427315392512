import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classNames from 'classnames';

import { IconProvider } from 'smv-components';
import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ResultRowHeaderLoopSelect = ({ row, handler, translation, loopParents, toggleParentDropdown = false }) => {
  const list = useSelector(state => state.survey.questionnaire.list);
  if (loopParents.length === 0) return null;

  return (
    <>
      <DropdownItem header>Loops</DropdownItem>
      {loopParents.map(loopParent => (
        <LoopSelectDropdown
          key={`${row.id}_loop_${loopParent.config.loop.source}`}
          row={row}
          loopParent={loopParent}
          element={list[loopParent.config.loop.source]}
          translation={translation}
          handler={handler}
          toggleParentDropdown={toggleParentDropdown}
        />
      ))}
    </>
  );
};

const LoopSelectDropdown = ({ handler, loopParent, element, row, toggleParentDropdown }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const handleSetLoopChoice = useCallback(
    (e, choiceid) => {
      const isCtrlKeyPressed = e?.ctrlKey ?? false;
      handler.setLoopChoice(row.id, loopParent, choiceid, isCtrlKeyPressed);
      if (toggleParentDropdown) toggleParentDropdown();
    },
    [row.id, handler, loopParent, toggleParentDropdown]
  );

  const isAllIterationsActive =
    Object.values(row.config?.loop?.[loopParent.id]?.included ?? {}).filter(Boolean).length <= 0;

  return (
    <Dropdown
      direction={'end'}
      group={true}
      className={'smv-custom-dropdown smv-custom-dropdown--nested dropdown-item'}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={'dropdown-item smv-custom-dropdown__buttons smv-custom-dropdown__dropdown-toggle'}
      >
        <IconProvider icon={'loop'} filled={false} clickable={false} />
        <span className={'ml-1'}>{element.title}</span>
      </DropdownToggle>

      <DropdownMenu end className={'smv-custom-dropdown__dropdown-menu'}>
        <DropdownItem
          className={classNames('smv-custom-dropdown__buttons', { active: isAllIterationsActive })}
          onClick={handleSetLoopChoice}
        >
          All Iterations
        </DropdownItem>
        {element.config?.choices?.order?.map(choiceid => {
          const isActive = row.config?.loop?.[loopParent.id]?.included?.[choiceid] ?? false;

          return (
            <LoopSelectDropdownItem
              key={`${row.id}_loop_${loopParent.config.loop.source}_${choiceid}`}
              choice={element.config.choices.list[choiceid]}
              active={isActive}
              handleSetLoopChoice={handleSetLoopChoice}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const LoopSelectDropdownItem = ({ choice, active = false, handleSetLoopChoice }) => {
  const label = useSubelementPropertyTranslation(choice, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  return (
    <DropdownItem
      className={classNames('smv-custom-dropdown__buttons', { active })}
      onClick={e => handleSetLoopChoice(e, choice.id)}
    >
      {label}
    </DropdownItem>
  );
};
