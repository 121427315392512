import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { isArray } from 'lodash';
import produce from 'immer';

import { Metrics, ManualSortation, BaseFilter, FrequencyGroups } from '../../setting-components';

const metrics = [
  {
    prop: 'calcFrequencies',
    label: 'Frequencies',
    default: true
  }
];

export const MultipleMatrixSettings = props => {
  const { rowid, internalRowConfig } = props;

  const internalHandler = useMemo(() => {
    return {
      setInternalConfig: props.internalHandler.setInternalRowConfig,
      setCheckboxValue: e => {
        const { name, checked } = e.target;
        props.internalHandler.setInternalRowConfig(state =>
          produce(state, draftState => {
            if (!draftState || isArray(draftState)) draftState = {};
            draftState[name] = checked;
            if (metrics.map(metric => !!draftState?.[metric.prop]).filter(Boolean).length === 0) {
              draftState[metrics[0].prop] = true;
            }
          })
        );
      },
      setNumericValue: e => {
        const { name, value } = e.target;
        const _value = parseFloat(value);
        props.internalHandler.setInternalRowConfig(state =>
          produce(state, draftState => {
            if (!draftState || isArray(draftState)) draftState = {};
            draftState[name] = _value;
          })
        );
      }
    };
  }, [props.internalHandler]);

  return (
    <>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <Metrics rowid={rowid} metrics={metrics} handler={internalHandler} config={internalRowConfig} />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <BaseFilter rowid={rowid} handler={internalHandler} config={internalRowConfig} />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <ManualSortation
            {...props}
            sortationKey={'manualSortation'}
            sortationConfig={internalRowConfig?.sortation ?? {}}
            handler={internalHandler}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <FrequencyGroups {...props} />
        </Col>
      </Row>
    </>
  );
};
