import React from 'react';
import { useRenderCalcFrequencies } from './helper';
import { FormulaBuilderTableElement } from './FormulaBuilderTableElement';

export const FormulaBuilderTableElementQuestionDetailedMatrix = ({
  calculationMode,
  main,
  questionVariable,
  questionValues,
  questionScales,
  handleAddReference
}) => {
  const renderedCalcFrequencies = useRenderCalcFrequencies(handleAddReference, main);

  return (
    <div key={`${main.id}`}>
      <h6>{questionVariable.title}</h6>
      {questionValues.order?.map((valueId, idx) => {
        const value = questionValues.list[valueId];

        return (
          <div key={`${valueId}_${idx}`} className='ml-2'>
            <FormulaBuilderTableElement value={value} renderAsHeadline={true} />
            {questionScales.order?.map(scaleId => {
              const scale = questionScales.list[scaleId];
              const element = {
                sourceId: main.sourceId,
                sourceType: main.sourceType,
                metric: 'smvrslt_perc',

                // cell identifiers
                rowId: calculationMode === 'rows' ? main.id : null,
                rowSubId: calculationMode === 'rows' ? valueId : null,
                rowSubSubId: calculationMode === 'rows' ? scaleId : null,
                headId: calculationMode === 'heads' ? main.id : null,
                headSubId: calculationMode === 'heads' ? valueId : null
              };

              return (
                <FormulaBuilderTableElement
                  key={`${main.id}~${valueId}~${scaleId}`}
                  element={element}
                  value={scale}
                  handleAddReference={handleAddReference}
                />
              );
            })}
          </div>
        );
      })}
      {renderedCalcFrequencies}
    </div>
  );
};
