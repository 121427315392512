import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMatrixType } from 'smv-helpers';
import {
  ResultTableChartHead,
  ResultTableChartBody,
  ResultTableChartHeadTransposed,
  ResultTableChartBodyTransposed
} from '.';

import './ResultTableChart.scss';

export const ResultTableChart = ({ chartConfig, table, tableResult, isEmpty }) => {
  const [hoveredCell, setHoveredCell] = useState(null);

  const { matrixType, isCompactMatrix } = useMatrixType(table);
  const isTransposed = chartConfig?.tableTranspose ?? false;

  if (isEmpty) return null;

  return (
    <div className={`smv-result-table smv-result-table--chart`}>
      <table className={`table table-striped table-hover w-100`}>
        {!isTransposed && (
          <>
            <ResultTableChartHead
              table={table}
              tableResult={tableResult}
              chartConfig={chartConfig}
              isCompactMatrix={isCompactMatrix}
              matrixType={matrixType}
            />
            <ResultTableChartBody
              table={table}
              tableResult={tableResult}
              chartConfig={chartConfig}
              isCompactMatrix={isCompactMatrix}
              matrixType={matrixType}
              hover={{ hoveredCell, setHoveredCell }}
            />
          </>
        )}

        {isTransposed && (
          <>
            <ResultTableChartHeadTransposed
              table={table}
              tableResult={tableResult}
              chartConfig={chartConfig}
              isCompactMatrix={isCompactMatrix}
              matrixType={matrixType}
            />
            <ResultTableChartBodyTransposed
              table={table}
              tableResult={tableResult}
              chartConfig={chartConfig}
              isCompactMatrix={isCompactMatrix}
              matrixType={matrixType}
              hover={{ hoveredCell, setHoveredCell }}
            />
          </>
        )}
      </table>
    </div>
  );
};

ResultTableChart.propTypes = {
  table: PropTypes.object.isRequired
};
