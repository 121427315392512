// import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { ConfirmAction } from 'smv-components';
import { stripHtml } from 'smv-helpers';

import { questionnaireActions } from 'src/smoove/redux/actions';
import './ChoiceConfigContainer.scss';

export const ScaleConfigContainer = ({ question, scale, qtype, writeable }) => {
  const survey = useSelector(state => state.survey);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';

  const surveyid = survey.id;
  const scaleLabel = survey?.translations?.[scale?.config?.translations?.label]?.[locale];

  if (!scale) return <div></div>;

  return (
    <div className='choice-config-container' id={`scale_${scale.id}_container`}>
      {writeable && (
        <div className='d-flex align-items-center'>
          <ConfirmAction
            title={
              <FormattedMessage
                id={'smoove.questionnaire.modal.config-container.delete-item-confirm-head'}
                defaultMessage={'Delete this element?'}
                values={{ element: <span>"{stripHtml(scaleLabel)}"</span> }}
              />
            }
            size={'md'}
            body={
              <div>
                <FormattedMessage
                  id={'smoove.questionnaire.modal.config-container.delete-item-confirm-text'}
                  defaultMessage={'Are you sure you want to delete this element?'}
                  values={{ element: <b>"{stripHtml(scaleLabel)}"</b> }}
                />
              </div>
            }
            callback={() => {
              if (!writeable) return;
              questionnaireActions.removeScale(surveyid, question.id, scale.id);
            }}
          >
            {confirm => (
              <>
                <i
                  id={`trash_${scale.id}`}
                  className='far fa-fw fa-trash-alt pointer ml-1 icon-smv-font-light'
                  onClick={confirm}
                />
                <UncontrolledTooltip
                  placement='top'
                  target={`trash_${scale.id}`}
                  container={`scale_${scale.id}_container`}
                >
                  <FormattedMessage id={'smoove.misc.delete'} />
                </UncontrolledTooltip>
              </>
            )}
          </ConfirmAction>
        </div>
      )}
    </div>
  );
};
