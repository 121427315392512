import { useSelector } from 'react-redux';

import './ClientTitle.scss';

export const ClientTitle = () => {
  const selectedClientId = useSelector(state => state?.clients?.selected);
  const clients = useSelector(state => state?.clients);
  const selectedClient = clients?.list?.[selectedClientId];

  return (
    <small className='smv-client-title' style={{ fontSize: '10px' }} title={selectedClient?.title ?? ''}>
      {selectedClient?.title ?? ''}
    </small>
  );
};
