import { createContext, useState, useCallback } from 'react';
import { merge } from 'lodash';

import { smooveMessages } from './locales';

export const IntlContext = createContext();

export const useIntlContext = () => {
  const [messages, setMessages] = useState(merge(smooveMessages));

  const injectMessages = useCallback(inject => {
    setMessages(state => merge(state, inject));
  }, []);

  const removeMessages = useCallback(remove => {
    console.log('removeMessages not implemented');
  }, []);

  return {
    defaultLocale: 'en-US',
    messages,
    injectMessages,
    removeMessages
  };
};
