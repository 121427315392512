import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink as RRNavLink, matchPath, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from 'reactstrap';
import classNames from 'classnames';

import { settingsActions } from '../redux';
import { clientsActions } from 'smv-redux';
import { permissionEnum, useIsUserSmooveAdmin, useUserHasPermission } from 'smv-helpers';

import { HeaderLogo, useScrollOffset } from '.';
import { ExportsCenter } from './exports-center/ExportsCenter';
import { ClientTitle } from './client-title/ClientTitle';

import './Header.scss';
import 'flag-icon-css/css/flag-icon.min.css';

const surveySubmenuLinks = [
  {
    to: '/survey',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.surveysubmenu.item.survey',
    defaultMessage: 'Survey'
  },
  {
    to: '/que',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.surveysubmenu.item.questionnaire',
    defaultMessage: 'Questionnaire'
  },
  {
    to: '/data',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.surveysubmenu.item.data',
    defaultMessage: 'Data'
  },
  {
    to: '/tables',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.surveysubmenu.item.tables',
    defaultMessage: 'Tables'
  },
  {
    to: '/reports',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.surveysubmenu.item.reports',
    defaultMessage: 'Reports'
  }
];

const projectSubmenuLinks = [
  {
    path: 'one/projects/:projectid/projectsettings',
    exact: true,
    activeClassName: 'active',
    messageId: 'core.menu.projectsubmenu.item.project-settings',
    defaultMessage: 'Project Settings'
  }
];

export const HeaderPrivate = ({ history }) => {
  const selectedClientId = useSelector(state => state?.clients?.selected);
  const clients = useSelector(state => state?.clients);
  const user = useSelector(state => state.authentication.user);
  const locale = useSelector(state => state.appSettings.locale);

  const isUserSmooveAdmin = useIsUserSmooveAdmin();
  const isUserClientAdmin = useUserHasPermission(permissionEnum.CLIENT_ADMIN);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);
  const hiddenLinksForReadOnlyUsers = !userCanEditProject ? ['/tables', '/data'] : [];

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const toggleSubmenu = useCallback(() => setIsSubmenuOpen(b => !b), []);

  const [isBurgerMenuOpen, setIsBurgerMenu] = useState(false);
  const toggleIsMenuOpen = useCallback(() => setIsBurgerMenu(b => !b), []);

  const matchProjectUrl = matchPath(history.location.pathname, {
    path: '/one/projects/:projectid?'
  });

  const matchSurveyUrl = matchPath(history.location.pathname, {
    path: '/one/projects/:projectid/surveys/:surveyid'
  });

  const isSurveyMenuOpen = matchSurveyUrl?.params?.surveyid;

  const shouldShrink = useScrollOffset(30);

  useEffect(() => {
    if (isSubmenuOpen) {
      document.addEventListener('click', toggleSubmenu, true);
    }
    return () => {
      document.removeEventListener('click', toggleSubmenu, true);
    };
    // document.body.classList.toggle('overflow-hidden', isOpen);
  }, [isSubmenuOpen, toggleSubmenu]);

  const selectedClient = clients?.list?.[selectedClientId];
  const changeClient = clientId => {
    if (clientId !== selectedClientId) {
      clientsActions.switchClient(clientId);
      history.push('/one');
    }
  };

  return (
    <div
      className={classNames('smv-header container-fluid fixed-top', {
        'box-shadow': shouldShrink,
        'smv-header--shrinked': shouldShrink,
        'smv-header--projectmenu-open': isSurveyMenuOpen,
        'smv-header--mobilemenu-open': isSubmenuOpen
      })}
    >
      <Navbar light expand='lg' container={false} className='smv-header__navbar'>
        <NavbarBrand tag={Link} to={'/one/clients'}>
          <HeaderLogo animate={true} />
        </NavbarBrand>

        <div onClick={toggleSubmenu}>
          {!isSubmenuOpen && <NavbarToggler />}
          {isSubmenuOpen && (
            <div className='navbar-close'>
              <i className='fal fa-times' />
            </div>
          )}
        </div>
        <Collapse isOpen={isSubmenuOpen} navbar className='smv-header__collapse'>
          <Nav className='navbar-nav' navbar>
            <NavItem className='menu-item'>
              <NavLink
                to={'/one'}
                activeClassName={'active'}
                exact={true}
                tag={RRNavLink}
                className='d-flex h-100 align-items-center text-uppercase'
                draggable={false}
              >
                <FormattedMessage id={'core.menu.item.dashboard'} defaultMessage={'Overview'} />
              </NavLink>
            </NavItem>
            <NavItem className='menu-item'>
              <NavLink
                to={'/one/projects'}
                activeClassName={'active'}
                exact={true}
                tag={RRNavLink}
                className='d-flex h-100 align-items-center text-uppercase'
                draggable={false}
              >
                <div style={{ position: 'relative' }}>
                  <FormattedMessage id={'core.menu.item.projects'} defaultMessage={'Projects'} />
                  <div style={{ position: 'absolute', top: '22px' }}>
                    <ClientTitle />
                  </div>
                </div>
              </NavLink>
            </NavItem>
            {matchSurveyUrl && isSurveyMenuOpen && (
              <div className={`smv-header__projectmenu ${isSurveyMenuOpen ? 'smv-header__projectmenu--active' : ''}`}>
                <div className='smv-header__menuitem-separator'></div>
                {surveySubmenuLinks
                  .filter(el => !hiddenLinksForReadOnlyUsers.includes(el.to))
                  .map(({ messageId, defaultMessage, to, ...rest }) => {
                    return (
                      <NavItem key={to} className='menu-item'>
                        <NavLink
                          to={matchSurveyUrl.url + to}
                          tag={RRNavLink}
                          className='d-flex h-100 align-items-center text-uppercase'
                          draggable={false}
                          {...rest}
                        >
                          <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                        </NavLink>
                      </NavItem>
                    );
                  })}
              </div>
            )}

            {matchProjectUrl?.params?.projectid && !isSurveyMenuOpen && (
              <div className={`smv-header__projectmenu smv-header__projectmenu--active`}>
                <div className='smv-header__menuitem-separator'></div>
                {projectSubmenuLinks.map(({ messageId, defaultMessage, path, ...rest }) => {
                  const pathname = generatePath(path, { projectid: matchProjectUrl.params.projectid });
                  const active = history.location.pathname === `/${pathname}`;

                  if (!active) return null;

                  console.log(window.location.pathname);

                  return (
                    <NavItem key={path} className='menu-item'>
                      <NavLink
                        to={pathname}
                        tag={RRNavLink}
                        className='d-flex h-100 align-items-center text-uppercase'
                        draggable={false}
                        {...rest}
                        active={active}
                        disabled={true}
                      >
                        <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                      </NavLink>
                    </NavItem>
                  );
                })}
              </div>
            )}
          </Nav>
          {user && (
            <>
              <div>
                <div className='d-flex justify-content-end'>
                  <ExportsCenter className='mr-2' isSharedView={false} />
                  <Dropdown
                    isOpen={isBurgerMenuOpen}
                    toggle={toggleIsMenuOpen}
                    inNavbar
                    className='smv-header__dropdown'
                  >
                    <DropdownToggle nav className='d-flex' draggable={false}>
                      <div className={`header-bubble-icon ${isBurgerMenuOpen ? 'header-bubble-icon--open' : ''}`}>
                        <i className={`far fa-${isBurgerMenuOpen ? 'xmark' : 'bars'} fa-fw text-secondary `} />
                      </div>
                    </DropdownToggle>

                    <DropdownMenu end>
                      <RRNavLink to={'/one/profile'} exact={true} activeClassName='active' draggable={false}>
                        <DropdownItem className='text-smv'>
                          <FormattedMessage id={'core.menu.item.profile'} defaultMessage={'Profile'} />
                        </DropdownItem>
                      </RRNavLink>

                      {isUserSmooveAdmin && (
                        <RRNavLink to={'/one/admin'} exact={true} activeClassName='active' draggable={false}>
                          <DropdownItem className='text-smv'>
                            <FormattedMessage id={'core.menu.item.administration'} defaultMessage={'Administration'} />
                          </DropdownItem>
                        </RRNavLink>
                      )}
                      {selectedClient && isUserClientAdmin && (
                        <RRNavLink
                          to={`/one/admin/clients/${selectedClientId}`}
                          exact={true}
                          activeClassName='active'
                          draggable={false}
                        >
                          <DropdownItem className='text-smv'>
                            <FormattedMessage
                              id={'core.menu.item.client-settings'}
                              defaultMessage={'Client Settings'}
                            />
                          </DropdownItem>
                        </RRNavLink>
                      )}

                      <DropdownItem className='p-0'>
                        <div className='dropstart dropdown-item dropdown-hover text-smv'>
                          <div
                            className='dropdown-toggle'
                            id='navbarDropdownLanguage'
                            role={'button'}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <FormattedMessage id={'core.menu.item.language'} defaultMessage={'Language'} />
                          </div>
                          <div
                            className='dropdown-menu dropdown-menu--language'
                            aria-labelledby='navbarDropdownLanguage'
                          >
                            <div
                              className={`dropdown-item ${locale === 'de_DE' ? 'active' : ''}`}
                              onClick={() => settingsActions.setAppLocale('de_DE')}
                            >
                              <span className={`flag-icon flag-icon-de mr-2`} />
                              <span>
                                <FormattedMessage id={'core.languages.german'} defaultMessage={'German'} />
                              </span>
                            </div>
                            <div
                              className={`dropdown-item ${locale === 'en_US' ? 'active' : ''}`}
                              onClick={() => settingsActions.setAppLocale('en_US')}
                            >
                              <span className={`flag-icon flag-icon-us mr-2`} />
                              <span>
                                <FormattedMessage id={'core.languages.english'} defaultMessage={'English'} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </DropdownItem>

                      <DropdownItem divider />

                      {Object.keys(clients?.list ?? {}).length > 1 && (
                        <>
                          <DropdownItem className='p-0'>
                            <div className='dropstart dropdown-item dropdown-hover text-smv'>
                              <div
                                className='dropdown-toggle'
                                id='navbarDropdownSiteSwitch'
                                role={'button'}
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <FormattedMessage
                                  id={'core.menu.item.site-switch'}
                                  defaultMessage={'Switch Organization'}
                                />
                              </div>
                              <div
                                className='dropdown-menu dropdown-menu--language'
                                aria-labelledby='navbarDropdownSiteSwitch'
                              >
                                {Object.values(clients.list).map(client => (
                                  <DropdownItem
                                    key={client.id}
                                    disabled={client.id === selectedClientId}
                                    tag={'div'}
                                    className={classNames('text-smv', { active: client.id === selectedClientId })}
                                    onClick={() => changeClient(client.id)}
                                  >
                                    {client.title}
                                  </DropdownItem>
                                ))}
                              </div>
                            </div>
                          </DropdownItem>
                          <DropdownItem divider />
                        </>
                      )}

                      <RRNavLink to={'/logout'} exact={true} draggable={false}>
                        <DropdownItem className='text-smv'>
                          <FormattedMessage id={'core.menu.item.logout'} defaultMessage={'Logout'} />
                        </DropdownItem>
                      </RRNavLink>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              {/* user-dropdown content on mobile */}
              <div className='smv-header__usermenu-mobile'>
                <div className='d-flex'>
                  <div
                    className={`usermenu-mobile__flag ${locale === 'de_DE' ? 'usermenu-mobile__flag--active' : ''}`}
                    onClick={() => settingsActions.setAppLocale('de_DE')}
                  >
                    <span className={`flag-icon flag-icon-de`} />
                  </div>
                  <div
                    className={`usermenu-mobile__flag ${locale === 'en_US' ? 'usermenu-mobile__flag--active' : ''}`}
                    onClick={() => settingsActions.setAppLocale('en_US')}
                  >
                    <span className={`flag-icon flag-icon-us`} />
                  </div>
                </div>
                {clients?.list && Object.keys(clients.list).length > 1 && (
                  <div className='usermenu-mobile__organization'>
                    <DropdownItem divider className='w-100 my-2' />
                    <div className='usermenu-mobile__organization-headline'>
                      <FormattedMessage id={'core.menu.item.organizations'} defaultMessage={'Organizations'} />
                    </div>
                    {Object.values(clients.list).map(client => (
                      <div
                        key={client.id}
                        tag={'div'}
                        className={classNames('text-smv', { active: client === selectedClientId })}
                        onClick={() => changeClient(client.id)}
                      >
                        {client.title}
                      </div>
                    ))}
                    <DropdownItem divider className='w-100 my-2' />
                  </div>
                )}
                <RRNavLink to={'/one/profile'} exact={true} activeClassName='active' draggable={false}>
                  <FormattedMessage id={'core.menu.item.profile'} defaultMessage={'Profile'} />
                </RRNavLink>
                <RRNavLink to={'/one/admin'} exact={true} activeClassName='active' draggable={false}>
                  <FormattedMessage id={'core.menu.item.administration'} defaultMessage={'Administration'} />
                </RRNavLink>
                <RRNavLink
                  to={`/one/admin/clients/${selectedClientId}`}
                  exact={true}
                  activeClassName='active'
                  draggable={false}
                >
                  <FormattedMessage id={'core.menu.item.client-settings'} defaultMessage={'Client Settings'} />
                </RRNavLink>
                <RRNavLink to={'/logout'} exact={true} draggable={false}>
                  <FormattedMessage id={'core.menu.item.logout'} defaultMessage={'Logout'} />
                </RRNavLink>
              </div>
            </>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
};
