import axios from 'axios';
import { store } from 'smv-redux';
import { history } from 'smv-redux';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 30000;
axios.defaults.baseURL = process.env?.REACT_APP_API ?? '';

axios.interceptors.request.use(
  request => {
    request.headers.Authorization = localStorage.getItem('auth_token')
      ? 'Bearer ' + localStorage.getItem('auth_token')
      : sessionStorage.getItem('auth_token')
      ? 'Bearer ' + sessionStorage.getItem('auth_token')
      : null;

    const locale = store.getState().appSettings.locale;
    // CORS-safelisted chars for request header are: 0-9 A-Z a-z *,-.;=.
    // So converts: 'en_US' to 'en-US'
    const requestLocale = locale.replace('_', '-');
    request.headers.common['Content-Language'] = requestLocale;

    return request;
  },
  error => error
);

// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     console.log(error);
//     if (error.response.data.status === 401 && history.location.pathname !== '/login') {
//       history.push('/login');
//     }
//     return Promise.reject(error);
//   }
// );

axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.__CANCEL__) {
      // error.message contains redux cancel action
      // store.dispatch(error.message);
      return Promise.reject(error);
    } else if (error?.response) {
      const { status } = error?.response;

      if (status === 401) {
        // const { authentication } = store.getState();
        // console.log(authentication);
        if (
          error.response.data.status === 401 &&
          history.location.pathname !== '/login' &&
          !history.location.pathname.includes('invitationRequest') &&
          !history.location.pathname.includes('resetPassword') &&
          !history.location.pathname.includes('shares')
        ) {
          history.push('/login');
        }
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
