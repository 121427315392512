import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'smv-assets/img/smoove.png';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import './Imprint.scss';
export const Imprint = () => {
  const intl = useIntl();
  const translationPrefix = 'core.page.imprint';

  return (
    <div className='smv-imprint base-content container-fluid'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'core.link.label.imprint' })} - smoove</title>
      </Helmet>
      <h1 className='text-uppercase text-smv fw-bold'>
        <FormattedMessage id={`${translationPrefix}.imprint`} defaultMessage={`Imprint`} />
      </h1>
      <div className='row'>
        <div className='col-md-9'>
          <div className='row mt-4'>
            <div className='col-4'>
              <p>
                <FormattedMessage
                  id={`${translationPrefix}.responsible-for-content`}
                  defaultMessage={`Responsible for content: `}
                />
              </p>
            </div>
            <div className='col-8'>
              <p>
                <b>mindline analytics GmbH</b>
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={`${translationPrefix}.manager`} defaultMessage={`Managing director: `} />
              </p>
            </div>
            <div className='col-8'>
              <p>Stephan Winkelmann, Jeremy Kirsten</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={`${translationPrefix}.address`} defaultMessage={`Address`} />
              </p>
            </div>
            <div className='col-8'>
              <p>
                Nordostpark 45
                <br />
                D-90411 Nürnberg
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage
                  id={`${translationPrefix}.register.headline`}
                  defaultMessage={`Register court/ number:`}
                />
              </p>
            </div>
            <div className='col-8'>
              <p>
                <FormattedMessage
                  id={`${translationPrefix}.register.court`}
                  defaultMessage={`Registergericht Nürnberg`}
                />
                <br />
                <FormattedMessage
                  id={`${translationPrefix}.register.number`}
                  defaultMessage={`Gerichtsstand Nürnberg`}
                />{' '}
                HRB 31256
                <br />
                <FormattedMessage
                  id={`${translationPrefix}.register.jurisdiction`}
                  defaultMessage={`Gerichtsstand Nürnberg`}
                />
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <p>VAT/ Ust.-ID:</p>
            </div>
            <div className='col-8'>
              <p>DE 299 103 544</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <p>
                <FormattedMessage id={`${translationPrefix}.contact`} defaultMessage={`Contact: `} />
              </p>
            </div>
            <div className='col-8'>
              <p>
                +49 (0)911 - 95 64 98 - 0 <br />
                <a
                  href='https://mindline.de/mindline-analytics/'
                  target='_blank'
                  rel='noopener noreferrer'
                  draggable={false}
                >
                  www.mindline-analytics.de
                </a>
                <br />
                <a href='mailto:info@mindline-analytics.de' draggable={false}>
                  info@mindline-analytics.de
                </a>
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'></div>
            <div className='col-8'>
              <div className='smv-imprint__social'>
                <a
                  href='https://www.linkedin.com/company/mindline-analytics/about/'
                  target='_blank'
                  rel='noreferrer'
                  draggable={false}
                >
                  <i className='fab fa-linkedin'></i>
                </a>
                <a
                  href='https://www.xing.com/pages/mindlineanalyticsgmbh'
                  target='_blank'
                  rel='noreferrer'
                  draggable={false}
                >
                  <i className='fab fa-xing-square'></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-3 mt-4'>
          <div className='d-flex justify-content-center flex-column align-items-center'>
            <img src={logo} height={`32px`} alt='mindline analytics' />
            <div>{`v${global.REACT_APP_VERSION}`}</div>
            <div>
              <small className='text-center d-flex mt-2'>
                <FormattedMessage
                  id={`${translationPrefix}.product-of-mla`}
                  defaultMessage={`A product of mindline analytics GmbH`}
                />
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-5 smv-imprint__text'>
        <div className='col-md-12'>
          <h4 className='mb-4'>
            <FormattedMessage id={`${translationPrefix}.copyright-and-tc`} defaultMessage={`Copyright & T&C`} />
          </h4>
        </div>
        <div className='col-md-6'>
          <p>
            <strong>
              <FormattedMessage id={`${translationPrefix}.copyright`} defaultMessage={`Copyright`} />
            </strong>
            <FormattedMessage
              id={`${translationPrefix}.copyright-text`}
              defaultMessage={`Text, images, graphics, sound, animations and videos as well as their arrangement on the mindline website are protected by copyright and other protective laws. The content of this website may not be copied, distributed, modified or made available to third parties for commercial purposes. We would like to point out that some of the images contained on the websites are subject to the copyright of third parties.`}
            />
          </p>
          <p>
            <strong>
              <FormattedMessage id={`${translationPrefix}.liability`} defaultMessage={`Liability`} />
            </strong>
            <FormattedMessage
              id={`${translationPrefix}.liability-text`}
              defaultMessage={`This website was compiled with the greatest possible care. Nevertheless, no guarantee can be given for the correctness and accuracy of the information contained. Any liability for damages arising directly or indirectly from the use of this website is excluded, unless caused by intent or gross negligence.`}
            />{' '}
            &nbsp;
          </p>
        </div>
        <div className='col-md-6'>
          <p>
            <strong>
              <FormattedMessage id={`${translationPrefix}.tc`} defaultMessage={`T&C`} />{' '}
            </strong>
            <FormattedMessage id={`${translationPrefix}.tc-text`} defaultMessage={`T&C - Text`} />
          </p>
          <p>
            <strong>
              <FormattedMessage id={`${translationPrefix}.external-links`} defaultMessage={`External Links`} />
            </strong>
            <FormattedMessage
              id={`${translationPrefix}.external-links-text`}
              defaultMessage={`Text, images, graphics, sound, animations and videos as well as their arrangement on the mindline website are protected by copyright and other protective laws. The content of this website may not be copied, distributed, modified or made available to third parties for commercial purposes. We would like to point out that some of the images contained on the websites are subject to the copyright of third parties.`}
            />
          </p>
          <p>
            <strong>
              <FormattedMessage id={`${translationPrefix}.privacy`} defaultMessage={`Privacy`} />{' '}
            </strong>
            <FormattedMessage
              id={`${translationPrefix}.privacy-text`}
              defaultMessage={`We take the protection of your personal data very seriously and adhere strictly to the rules of data protection laws. Personal data is only collected on this website to the extent technically necessary. `}
            />{' '}
            <FormattedMessage
              id={`${translationPrefix}.privacy-detailed-info`}
              defaultMessage={`You can find a detailed explanation of this on our `}
            />
            <Link to={'/one/gdpr/'} draggable={false}>
              <FormattedMessage id={`${translationPrefix}.privacy-page`} defaultMessage={`Privacy page`} />.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
