import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';
import produce from 'immer';
import classNames from 'classnames';
import {
  ResponsiveContainer,
  ScatterChart as ScatterChartReCharts,
  Scatter,
  LabelList,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Surface,
  Symbols
} from 'recharts';
import { rgb } from 'd3-color';

import { isNumeric, useGetSourceElement } from 'smv-helpers';
import {
  flattenKeys,
  getContrastColor,
  getFixedColor,
  getFormattedValue,
  getSchemeColor,
  useChartData,
  useLegendMouseEffects
} from './helper';

export const ScatterChart = ({ elementid, chartConfig, table, tableResult, handler }) => {
  const chartLayers = useSelector(s => s.survey?.chartLayers);
  const isLayered = !!table.chart?.chartLayer;
  const chartLayer = isLayered ? chartLayers.list[chartConfig.chartLayer] : null;

  const getSourceElement = useGetSourceElement();

  const dimensionMode = chartConfig?.scatterDimensionsDimension ?? 'allHeads';

  let [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: dimensionMode === 'allHeads' || dimensionMode === 'heads' ? true : false //chartConfig?.tableTranspose ?? false
  });

  let chartDimensionsX;
  let chartDimensionsY;

  if (dimensionMode === 'rows' || dimensionMode === 'heads') {
    if (!chartLayer) {
      const subDimension = chartConfig?.scatterDimensionsSubDimension
        ? findDataKey(chartData, chartConfig?.scatterDimensionsSubDimension) ?? 0
        : 0;
      chartData = chartData?.[subDimension];
    }

    chartDimensionsX = chartConfig?.scatterDimensionsX
      ? getDimensions(chartDimensions, chartConfig?.scatterDimensionsX)
      : chartDimensions;
    chartDimensionsY = chartConfig?.scatterDimensionsY
      ? getDimensions(chartDimensions, chartConfig?.scatterDimensionsY)
      : chartDimensions;

    [chartDimensionsX, chartDimensionsY] = unifyDimensions(chartDimensionsX, chartDimensionsY, table, getSourceElement);

    chartDimensions = isEqual(chartConfig?.scatterDimensionsLabels, chartConfig?.scatterDimensionsX)
      ? chartDimensionsX
      : chartDimensionsY;
  }

  let xIndex;
  let yIndex;

  if (dimensionMode === 'allHeads' || dimensionMode === 'allRows') {
    xIndex = chartConfig?.scatterDimensionsX ? findDataKey(chartData, chartConfig?.scatterDimensionsX) ?? 0 : 0;
    yIndex = chartConfig?.scatterDimensionsY ? findDataKey(chartData, chartConfig?.scatterDimensionsY) ?? 1 : 1;
  }

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  /**
   * DOMAINS
   */
  const domainX = useMemo(() => {
    if (chartConfig?.chartDomainXAuto ?? true) return [0, 'auto'];
    let domainMin = chartConfig?.chartDomainXMin ?? 'auto';
    let domainMax = chartConfig?.chartDomainXMax ?? 'auto';

    if (isNumeric(domainMin)) domainMin = parseFloat(domainMin);
    if (isNumeric(domainMax)) domainMax = parseFloat(domainMax);

    return [domainMin, domainMax];
  }, [chartConfig?.chartDomainXAuto, chartConfig?.chartDomainXMin, chartConfig?.chartDomainXMax]);

  const domainY = useMemo(() => {
    if (chartConfig?.chartDomainYAuto ?? true) return [0, 'auto'];
    let domainMin = chartConfig?.chartDomainYMin ?? 'auto';
    let domainMax = chartConfig?.chartDomainYMax ?? 'auto';

    if (isNumeric(domainMin)) domainMin = parseFloat(domainMin);
    if (isNumeric(domainMax)) domainMax = parseFloat(domainMax);

    return [domainMin, domainMax];
  }, [chartConfig?.chartDomainYAuto, chartConfig?.chartDomainYMin, chartConfig?.chartDomainYMax]);

  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * AXIS
   */
  const { xAxis, yAxis } = useMemo(() => {
    let xAxis, yAxis;

    let xAxisName;
    let yAxisName;

    if (dimensionMode === 'allHeads' || dimensionMode === 'allRows') {
      xAxisName = chartData?.[xIndex]?.name ?? '';
      yAxisName = chartData?.[yIndex]?.name ?? '';
    } else {
      xAxisName = chartConfig?.scatterDimensionsX
        ? getAxisName(table, chartConfig?.scatterDimensionsX, getSourceElement)
        : '';
      yAxisName = chartConfig?.scatterDimensionsY
        ? getAxisName(table, chartConfig?.scatterDimensionsY, getSourceElement)
        : '';
    }

    xAxis = (
      <XAxis
        type='number'
        dataKey='x'
        name={xAxisName}
        domain={domainX}
        padding={{ left: 25, right: 25 }}
        label={{ value: xAxisName, position: 'insideBottomRight', offset: 0, fill: '#666' }}
      />
    );
    yAxis = (
      <YAxis
        type='number'
        dataKey='y'
        name={yAxisName}
        domain={domainY}
        padding={{ top: 25 }}
        label={{ value: yAxisName, angle: -90, position: 'center', fill: '#666' }}
      />
    );

    return { xAxis, yAxis };
  }, [
    chartData,
    dimensionMode,
    domainX,
    domainY,
    xIndex,
    yIndex,
    chartConfig?.scatterDimensionsX,
    chartConfig?.scatterDimensionsY,
    getSourceElement,
    table
  ]);

  /**
   * HOVER EFFECTS
   */
  const flattendHiddenSeries = useMemo(() => {
    if (chartConfig.legendSaveHiddenSeries ?? false) {
      return flattenKeys(chartConfig.legendHiddenSeries ?? {});
    } else {
      return {};
    }
  }, [chartConfig.legendSaveHiddenSeries, chartConfig.legendHiddenSeries]);
  const { onMouseEnter, onMouseLeave, getOpacity, isHidden, toggleSeries, hiddenSeries } = useLegendMouseEffects({
    hiddenSeries: flattendHiddenSeries
  });

  const initialHiddenSeriesViews = useMemo(() => {
    return Object.fromEntries(
      chartLayer?.views?.order?.map(viewId => [viewId, chartLayer.views.list[viewId].hidden]) ?? []
    );
  }, [chartLayer]);
  const {
    onMouseEnter: onMouseEnterView,
    onMouseLeave: onMouseLeaveView,
    getOpacity: getOpacityView,
    isHidden: isHiddenView,
    toggleSeries: toggleSeriesView,
    hiddenSeries: hiddenSeriesView
  } = useLegendMouseEffects({
    hiddenSeries: initialHiddenSeriesViews
  });

  useEffect(() => {
    if (handler?.setTableConfig) {
      handler.setTableConfig(s => {
        // hidden series should be saved, and the current state is different to stored state => update table config
        if (
          s.chart.legendSaveHiddenSeries === true &&
          !isEqual(flattenKeys(s.chart.legendHiddenSeries), hiddenSeries)
        ) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = hiddenSeries;
          });
        }
        // hidden series should not be saved, but the stored state not empty => update table config
        else if (s.chart.legendSaveHiddenSeries !== true && !isEqual(s.chart.legendHiddenSeries, {})) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = {};
          });
        }
        // no update required, series should not be saved or stored state equals current state
        else {
          return s;
        }
      });
    }
  }, [handler, hiddenSeries]);

  if (!chartData || chartData.length < 2) return null;

  const { colorSchema, colorSchemaReverse } = chartConfig;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {(chartConfig?.showLegend ?? true) && isLayered && (
        <LayerLegend
          chartLayer={chartLayer}
          iconSize={chartConfig?.legendIconSize ?? 14}
          onMouseEnterView={onMouseEnterView}
          onMouseLeaveView={onMouseLeaveView}
          toggleSeriesView={toggleSeriesView}
          hiddenSeriesView={hiddenSeriesView}
        />
      )}

      <ResponsiveContainer width='100%' height='100%'>
        <ScatterChartReCharts width={200} height={120} data={chartData} layout={chartConfig?.chartOrientation}>
          {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}

          {xAxis}
          {yAxis}

          {(chartConfig?.showTooltip ?? true) && (
            <Tooltip content={<CustomTooltip formatter={valueFormatter} chartLayer={chartLayer} />} />
          )}

          {(chartConfig?.showLegend ?? true) && (
            <Legend
              iconType={'circle'}
              iconSize={chartConfig?.legendIconSize ?? 14}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={toggleSeries}
              formatter={(value, entry) => {
                const val = entry?.payload?.name ?? value;
                return <span style={{ color: '#666' }}>{val}</span>;
              }}
              content={isLayered ? <LegendWhenLayered getOpacity={getOpacity} /> : null}
            />
          )}

          {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
            (dimension, index) => {
              // get colors
              let scalePointValue = index / (chartDimensions.length - 1);
              if (isNaN(scalePointValue)) scalePointValue = 0;
              if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

              let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

              const cellIdentifier = dimension.cellIdentifier;
              const fixedColor = getFixedColor(chartConfig, cellIdentifier);
              if (fixedColor) {
                color = fixedColor.hex;
                contrastColor = getContrastColor(fixedColor.rgb);
              }

              const valueSize = chartConfig?.valuesValueSize ? parseInt(chartConfig.valuesValueSize) : 12;
              const circleRadius =
                chartConfig?.valuesShow && chartConfig?.valuesValuePosition === 'inside'
                  ? valueSize * 1.5
                  : chartConfig?.valuesCircleRadius
                  ? parseInt(chartConfig.valuesCircleRadius)
                  : 5;
              const valuePosition = chartConfig?.valuesValuePosition ?? 'top';

              let coordinates = [];
              let xCoordinate;
              let yCoordinate;

              if (chartLayer) {
                chartLayer?.views?.order.forEach(viewId => {
                  /** @todo: check if view is hidden by default or disabled by the user */
                  // const view = chartLayer.views.list[viewId];
                  // const isHidden = view.hidden ?? customHiddenViews[viewId]

                  if (dimensionMode === 'rows' || dimensionMode === 'heads') {
                    const subDimension = chartConfig?.scatterDimensionsSubDimension
                      ? findDataKey(chartData, chartConfig?.scatterDimensionsSubDimension, viewId) ?? 0
                      : 0;

                    let chartDataView = chartData?.[subDimension];

                    xCoordinate = chartDataView?.[chartDimensionsX?.[index]?.id]?.value;
                    yCoordinate = chartDataView?.[chartDimensionsY?.[index]?.id]?.value;
                  } else {
                    // search for datakey with correct viewId
                    xIndex = chartConfig?.scatterDimensionsX
                      ? findDataKey(chartData, chartConfig?.scatterDimensionsX, viewId) ?? 0
                      : 0;
                    yIndex = chartConfig?.scatterDimensionsY
                      ? findDataKey(chartData, chartConfig?.scatterDimensionsY, viewId) ?? 1
                      : 1;

                    xCoordinate = chartData?.[xIndex]?.[dimension?.id]?.value;
                    yCoordinate = chartData?.[yIndex]?.[dimension?.id]?.value;
                  }

                  if (!xCoordinate || !yCoordinate) return null;

                  // skip hidden views
                  if (isHiddenView(viewId)) return null;

                  coordinates.push({ name: dimension.label, x: xCoordinate, y: yCoordinate, viewId: viewId });
                });
              } else {
                if (dimensionMode === 'rows' || dimensionMode === 'heads') {
                  xCoordinate = chartData?.[chartDimensionsX?.[index]?.id]?.value;
                  yCoordinate = chartData?.[chartDimensionsY?.[index]?.id]?.value;
                } else {
                  xCoordinate = chartData?.[xIndex]?.[dimension?.id]?.value;
                  yCoordinate = chartData?.[yIndex]?.[dimension?.id]?.value;
                }

                if (!xCoordinate || !yCoordinate) return null;

                coordinates.push({ name: dimension.label, x: xCoordinate, y: yCoordinate });
              }

              if (coordinates.length === 0) return null;

              return (
                <Scatter
                  key={dimension.id}
                  name={dimension.label}
                  dataKey={`${dimension.id}.value`}
                  data={coordinates}
                  fill={color}
                  shape={
                    <CustomizedCircle
                      dataKey={`${dimension.id}.value`}
                      circleRadius={circleRadius}
                      fill={color}
                      opacity={getOpacity(dimension.id)}
                      getOpacityView={getOpacityView}
                      chartLayer={chartLayer}
                    />
                  }
                  isAnimationActive={false}
                  hide={isHidden(`${dimension.id}.value`)}
                >
                  {chartConfig?.valuesShow && (
                    <LabelList
                      dataKey={`${dimension.id}`}
                      position={valuePosition || 'top'}
                      content={payload => {
                        const { x, y, offset } = payload;
                        let _y = y;
                        if (valuePosition === 'top') _y -= valueSize / 2 + circleRadius + 2;
                        else if (valuePosition === 'bottom') _y += valueSize / 2 + circleRadius + 2;

                        const label = index + 1;

                        // when layered, use color/ contrast color from view config
                        if (isLayered) {
                          contrastColor = getContrastColor(
                            rgb(chartLayer?.views?.list[chartLayer?.views?.order?.[payload.index]]?.color)
                          );
                        }

                        return (
                          <g>
                            <text
                              dx={offset}
                              x={x}
                              y={_y}
                              dy={valueSize / 3}
                              fill={valuePosition === 'inside' ? contrastColor : `#666`}
                              fontSize={valueSize}
                              textAnchor='middle'
                              dominantBaseline={'middle'}
                              opacity={getOpacity(dimension.id)}
                            >
                              {label}
                            </text>
                          </g>
                        );
                      }}
                    />
                  )}
                </Scatter>
              );
            }
          )}
        </ScatterChartReCharts>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedCircle = ({
  cx,
  cy,
  fill,
  circleRadius,
  name,
  opacity,
  getOpacityView,
  payload,
  chartLayer,
  ...rest
}) => {
  let color = fill;
  let _opacity = opacity;

  if (chartLayer && !!payload?.viewId) {
    // when layered, use color/ contrast color from view config
    color = chartLayer?.views?.list[payload.viewId]?.color ?? '#dedede';

    // when layered, also use mouseOver opacity from view legend
    const viewOpacity = getOpacityView(payload?.viewId);
    if (opacity === 1 && viewOpacity < 1) _opacity = viewOpacity;
  }

  return (
    <circle cx={cx} cy={cy} r={circleRadius} fill={color} stroke={`#fff`} strokeWidth={2} opacity={_opacity}></circle>
  );
};

const CustomTooltip = ({ active, payload, label, formatter, chartLayer }) => {
  if (active && payload && payload.length) {
    const viewId = payload?.[0]?.payload?.viewId ?? null;

    return (
      <div
        className='recharts-default-tooltip'
        style={{
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          margin: 0,
          padding: 10,
          whiteSpace: 'nowrap'
        }}
      >
        <ul className='recharts-tooltip-item-list' style={{ margin: 0, padding: 0 }}>
          {viewId && (
            <p className='recharts-tooltip-label' style={{ margin: 0 }}>
              <strong>{`${chartLayer?.views?.list[viewId]?.label ?? ''}`}</strong>
            </p>
          )}
          <p className='recharts-tooltip-label' style={{ margin: 0 }}>
            <strong>{`${payload[0]?.payload?.name}`}</strong>
          </p>
          <li className='recharts-tooltip-item' style={{ display: 'block', paddingTop: 4, paddingBottom: 4 }}>
            <span className='recharts-tooltip-item-name'>{`${payload[0].name}`}</span>
            <span className='recharts-tooltip-item-separator'> : </span>
            <span className='recharts-tooltip-item-value'>{`${formatter(payload[0].value)}`}</span>
            <span className='recharts-tooltip-item-unit'></span>
          </li>
          <li className='recharts-tooltip-item' style={{ display: 'block', paddingTop: 4, paddingBottom: 4 }}>
            <span className='recharts-tooltip-item-name'>{`${payload[1].name}`}</span>
            <span className='recharts-tooltip-item-separator'> : </span>
            <span className='recharts-tooltip-item-value'>{`${formatter(payload[1].value)}`}</span>
            <span className='recharts-tooltip-item-unit'></span>
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

function findDataKey(chartData, dimensionConfig, viewId = null) {
  let dataKey = null;

  for (const dataKey in chartData) {
    if (viewId && chartData[dataKey].viewId !== viewId) continue;

    for (const dataSubKey in chartData[dataKey]) {
      if (chartData[dataKey][dataSubKey]?.cellIdentifier) {
        const cellIdentifier = chartData[dataKey][dataSubKey].cellIdentifier;
        // check if all keys in scatterDimensionsX match the cellIdentifier
        const isMatch = Object.keys(dimensionConfig).every(
          dimKey => dimensionConfig[dimKey] === cellIdentifier[dimKey]
        );

        if (isMatch) {
          return dataKey;
        }
      }
    }
  }

  return dataKey;
}

function getDimensions(chartDimensions, dimensionConfig) {
  const dataKeys = [];

  for (const dataKey in chartDimensions) {
    if (chartDimensions[dataKey]?.cellIdentifier) {
      const cellIdentifier = chartDimensions[dataKey]?.cellIdentifier;
      // check if all keys in scatterDimensionsX match the cellIdentifier
      const isMatch = Object.keys(dimensionConfig).every(dimKey => dimensionConfig[dimKey] === cellIdentifier[dimKey]);

      if (isMatch) {
        dataKeys.push(parseInt(dataKey));
      }
    }
  }

  const dimensions = dataKeys.map(index => chartDimensions[index]);

  return dimensions;
}

function unifyDimensions(dimX, dimY, table, getSourceElement) {
  // mapping dimension id to the value, e.g rowId~rowSubId: 1
  let dimValueMap = {};

  dimX.concat(dimY).forEach(dimElement => {
    const cellIdentifier = dimElement.cellIdentifier;
    const { rowId, rowSubId, headId, headSubId } = cellIdentifier;

    if (rowId) {
      const row = table.rows.list[rowId];
      if (row) {
        const { elementValues } = getSourceElement(row);

        if (rowSubId) {
          dimValueMap[dimElement.id] = elementValues?.list?.[rowSubId]?.value;
        }
      }
    } else if (headId) {
      const head = table.heads.list[headId];
      if (head) {
        const { elementValues } = getSourceElement(head);

        if (headSubId) {
          dimValueMap[dimElement.id] = elementValues?.list?.[headSubId]?.value;
        }
      }
    }
  });

  // keep only matching values
  if (!isEmpty(dimValueMap)) {
    dimValueMap = removeUniqueValues(dimValueMap);

    // clean x chart dimension and y chart dimensions from values with no match
    dimX = dimX.filter(item => dimValueMap.hasOwnProperty(item.id));
    dimY = dimY.filter(item => dimValueMap.hasOwnProperty(item.id));
  }

  return [dimX, dimY];
}

function removeUniqueValues(data) {
  const valueCount = Object.values(data).reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  return Object.fromEntries(Object.entries(data).filter(([, value]) => valueCount[value] > 1));
}

function getAxisName(table, dimensionConfig, getSourceElement) {
  let axisName;
  let sourceElement;

  if (dimensionConfig && 'headId' in dimensionConfig) {
    const head = table.heads.list?.[dimensionConfig.headId];
    if (head) {
      const { element } = getSourceElement(head);
      sourceElement = element;
    }
  } else if (dimensionConfig && 'rowId' in dimensionConfig) {
    const row = table.rows.list?.[dimensionConfig.rowId];
    if (row) {
      const { element } = getSourceElement(row);
      sourceElement = element;
    }
  }

  axisName = sourceElement?.label ?? sourceElement?.title ?? sourceElement?.id ?? '';

  return axisName;
}

const LegendWhenLayered = props => {
  const { payload } = props;

  return (
    <div className={classNames({ 'd-flex': true, 'justify-content-center': props.align === 'center' })}>
      <ul style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {payload.map((entry, index) => {
          // const dimensionId = entry.dataKey.replace(/\.value$/, '')

          return (
            <span
              key={index}
              className={'legend-item pl-2'}
              style={{ opacity: entry.inactive && 0.2 }}
              onClick={() => props.onClick(entry)}
              onMouseEnter={() => props.onMouseEnter(entry)}
              onMouseLeave={() => props.onMouseLeave(entry)}
            >
              <Surface width={props.iconSize + 4} height={props.iconSize + 4}>
                <Symbols
                  cx={props.iconSize / 2 + 2}
                  cy={props.iconSize / 2 + 2}
                  type={'circle'}
                  size={props.iconSize + 4}
                  sizeType='diameter'
                  fill={'#666'}
                />
                <g>
                  <text
                    dx={props.iconSize / 2 + 2}
                    // x={x}
                    // y={_y}
                    dy={props.iconSize / 2 + 2}
                    fill={`#fff`}
                    fontSize={props.iconSize}
                    textAnchor='middle'
                    dominantBaseline={'middle'}
                  >
                    {index + 1}
                  </text>
                </g>
              </Surface>
              <span style={{ paddingLeft: 2 }}> {entry.value}</span>
            </span>
          );
        })}
      </ul>
    </div>
  );
};

const LayerLegend = ({
  chartLayer,
  iconSize = 14,
  onMouseEnterView,
  onMouseLeaveView,
  toggleSeriesView,
  hiddenSeriesView
}) => {
  return (
    <div>
      <ul style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {chartLayer?.views?.order.map(viewId => {
          const view = chartLayer.views.list[viewId];
          return (
            <span
              key={viewId}
              className={'legend-item pl-2'}
              style={{ opacity: hiddenSeriesView[viewId] && 0.2 }}
              onClick={() => toggleSeriesView({ dataKey: viewId })}
              onMouseEnter={() => onMouseEnterView({ dataKey: viewId })}
              onMouseLeave={() => onMouseLeaveView({ dataKey: viewId })}
            >
              <Surface width={iconSize + 4} height={iconSize + 4}>
                <Symbols
                  cx={iconSize / 2 + 2}
                  cy={iconSize / 2 + 2}
                  type={'circle'}
                  size={iconSize + 4}
                  sizeType={'diameter'}
                  fill={view.color}
                />
              </Surface>
              <span style={{ paddingLeft: 2 }}>
                {' '}
                {view.label.length > 20 ? view.label.substring(0, 17) + '...' : view.label}
              </span>
            </span>
          );
        })}
      </ul>
    </div>
  );
};
