import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classNames from 'classnames';
import produce from 'immer';
import { useIntl } from 'react-intl';

import { useMatrixType } from 'smv-helpers';

import { ApplyToDimension } from '.';

export const ApplyToDropdown = ({ tableConfig, conditionalFormat, handleApplyToSelect, dropdownContainerRef }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const { matrixType, isCompactMatrix } = useMatrixType(tableConfig);

  const sourceElements = useMemo(
    () => ({
      rows: produce(tableConfig.rows, draft => {}),
      heads: produce(tableConfig.heads, draft => {
        if (isCompactMatrix) {
          tableConfig.rows.order.forEach(rowid => {
            if (tableConfig.rows.list[rowid].sourceType === 'question') {
              draft.order = [rowid, ...draft.order];
              draft.list[rowid] = tableConfig.rows.list[rowid];
            }
          });
        }
      })
    }),
    [tableConfig, isCompactMatrix]
  );

  // const applyToCount = useMemo(() => {
  //   const countTrueItems = obj => {
  //     let count = 0;
  //     function countTrue(obj) {
  //       if (!obj) return 0;
  //       Object.keys(obj).forEach(key => {
  //         if (typeof obj[key] === 'object') {
  //           countTrue(obj[key]);
  //         } else if (obj[key] === true) {
  //           count++;
  //         }
  //       });
  //     }
  //     countTrue(obj);
  //     return count;
  //   };
  //   const headsCount = countTrueItems(conditionalFormat.applyTo?.heads);
  //   const rowsCount = countTrueItems(conditionalFormat.applyTo?.rows);

  //   return headsCount + rowsCount;
  // }, [conditionalFormat.applyTo]);

  const rowsCount = Object.keys(conditionalFormat?.applyTo?.rows ?? {})?.length ?? 0;
  const headsCount = Object.keys(conditionalFormat?.applyTo?.heads ?? {})?.length ?? 0;
  const isValidSelection = rowsCount > 0 && headsCount > 0;

  let applyToStateString = 'all cells';
  if (rowsCount > 0 && headsCount === 0) {
    applyToStateString = `${rowsCount} row(s), all heads`;
  } else if (rowsCount === 0 && headsCount > 0) {
    applyToStateString = `all rows, ${headsCount} head(s)`;
  } else if (rowsCount > 0 && headsCount > 0) {
    applyToStateString = `${rowsCount} row(s), ${headsCount} head(s)`;
  }

  return (
    <Dropdown
      tag={'div'}
      direction={'down'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'conditional-format__apply-to': true,
        'conditional-format__apply-to--invalid': !isValidSelection
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'w-100': true
        })}
      >
        <span>
          {intl.formatMessage({ id: 'smoove.page.tables.chart-config.conditional-formatting-table.apply-to' })}
          {' ' + applyToStateString}
        </span>
      </DropdownToggle>
      <DropdownMenu
        container={dropdownContainerRef}
        end
        className={classNames({
          'smv-custom-dropdown__dropdown-menu': true,
          'smv-custom-dropdown__dropdown-menu--nested': isOpen
        })}
      >
        {Object.keys(sourceElements).map(dimensionKey => {
          return (
            <ApplyToDimension
              key={dimensionKey}
              conditionalFormat={conditionalFormat}
              matrixType={matrixType}
              showTotal={tableConfig.config.showTotal}
              dimension={sourceElements[dimensionKey]}
              dimensionKey={dimensionKey}
              handleApplyToSelect={handleApplyToSelect}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
