import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DropdownItem, UncontrolledTooltip } from 'reactstrap';
// import produce from 'immer';

import { translationsActions } from 'smv-redux/actions';
import { getMergedChoices } from '../../misc/helper';
import { useControlledState } from 'smv-helpers';

import { questionnaireActions } from 'smv-redux';

import { SmvCustomDropdown } from '../../misc';
import { EditableTextHover } from '../Content';
import { ConfirmCopyTranslationButton, ConfirmCopyTranslationModal } from './ConfirmCopyTranslation';
import { PasteMultipleTranslationsButton, PasteMultipleTranslationsModal } from './PasteMultipleTranslations';
import { AiTranslationButton, AiTranslationResult } from './AiTranslation';

export const TranslateChoices = ({
  question,
  localeA,
  localeB,
  nodeOnClick,
  writeable,
  translationModalContainerRef,
  aiTranslationList,
  updateAiTranslationList,
  getSingleTranslation
}) => {
  const [CopyTranslationButton1, CopyTranslationModal1] = useControlledState(
    ConfirmCopyTranslationButton,
    ConfirmCopyTranslationModal,
    false
  );
  const [CopyTranslationButton2, CopyTranslationModal2] = useControlledState(
    ConfirmCopyTranslationButton,
    ConfirmCopyTranslationModal,
    false
  );

  const [PasteMultipleTranslationsButton1, PasteMultipleTranslationsModal1] = useControlledState(
    PasteMultipleTranslationsButton,
    PasteMultipleTranslationsModal,
    false
  );
  const [PasteMultipleTranslationsButton2, PasteMultipleTranslationsModal2] = useControlledState(
    PasteMultipleTranslationsButton,
    PasteMultipleTranslationsModal,
    false
  );

  const list = useSelector(state => state.survey.questionnaire.list);

  const choiceTranslationRows = useMemo(() => {
    const _choices = getMergedChoices(question, question?.config?.choices ?? {}, list);
    return _choices.order.map(choiceid => {
      const choice = _choices.list[choiceid];
      if (choice?.imported === true) {
        return (
          <tr key={choiceid}>
            <td>
              <TranslateSharedChoice
                question={question}
                choice={choice}
                locale={localeA}
                nodeOnClick={nodeOnClick}
                writeable={writeable}
              />
            </td>
            <td>
              <TranslateSharedChoice
                question={question}
                choice={choice}
                locale={localeB}
                nodeOnClick={nodeOnClick}
                writeable={writeable}
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={choiceid}>
            <td>
              <Choice question={question} choice={choice} locale={localeA} writeable={writeable} />
            </td>
            <td>
              <Choice
                question={question}
                choice={choice}
                locale={localeB}
                writeable={writeable}
                aiTranslationList={aiTranslationList}
                updateAiTranslationList={updateAiTranslationList}
                getSingleTranslation={
                  localeA !== 'shortlabels' && localeB !== 'shortlabels' ? getSingleTranslation : null
                }
              />
            </td>
          </tr>
        );
      }
    });
  }, [
    list,
    question,
    localeA,
    localeB,
    nodeOnClick,
    writeable,
    aiTranslationList,
    updateAiTranslationList,
    getSingleTranslation
  ]);

  return (
    <>
      <tr>
        <td
          className='translation-question-title'
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <h6 className='mt-2 ml-1' style={{ fontWeight: '300' }}>
            Items
          </h6>
          {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
            <>
              <SmvCustomDropdown container={translationModalContainerRef}>
                <DropdownItem>
                  <CopyTranslationButton1 />
                </DropdownItem>
                <DropdownItem>
                  <PasteMultipleTranslationsButton1 />
                </DropdownItem>
              </SmvCustomDropdown>
              <CopyTranslationModal1
                locale1={localeB}
                locale2={localeA}
                question={question}
                subelementType={'choices'}
              />
              <PasteMultipleTranslationsModal1 locale={localeA} question={question} subelementType={'choices'} />
            </>
          )}
        </td>
        <td className={'translation-question-title'} style={{ textAlign: 'right' }}>
          {localeA !== 'shortlabels' && localeB !== 'shortlabels' && (
            <>
              <SmvCustomDropdown container={translationModalContainerRef}>
                <DropdownItem>
                  <CopyTranslationButton2 />
                </DropdownItem>
                <DropdownItem>
                  <PasteMultipleTranslationsButton2 />
                </DropdownItem>
              </SmvCustomDropdown>
              <CopyTranslationModal2 locale1={localeA} locale2={localeB} question={question} />
              <PasteMultipleTranslationsModal2 locale={localeB} question={question} subelementType={'choices'} />
            </>
          )}
        </td>
      </tr>

      {choiceTranslationRows}
    </>
  );
};

export const Choice = ({
  question,
  choice,
  locale,
  writeable,
  getSingleTranslation,
  aiTranslationList,
  updateAiTranslationList
}) => {
  const surveyid = useSelector(state => state.survey.id);
  const choiceLabel = useSelector(
    state => state.survey?.translations?.[choice.config.translations.label]?.[locale] ?? ''
  );
  const mainLanguage = useSelector(state => state.survey.locales.main);

  const intl = useIntl();

  const isMainLanguage = useMemo(() => mainLanguage === locale, [mainLanguage, locale]);

  const placeholderText = useMemo(() => {
    if (writeable) return intl.formatMessage({ id: 'smoove.questionnaire.translation.question.choice.placeholder' });
    if (isMainLanguage)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.missing-tanslation.placeholder' });
    return intl.formatMessage({
      id: 'smoove.questionnaire.translation.missing-tanslation-with-fallback.placeholder'
    });
  }, [intl, writeable, isMainLanguage]);

  const checkUpdateLabel = useCallback(
    value => {
      if (value !== choiceLabel)
        translationsActions.updateTranslation(surveyid, choice.config.translations.label, {
          [locale]: value
        });
    },
    [choiceLabel, surveyid, locale, choice.config.translations.label]
  );

  const updateShortlabel = useCallback(
    value => {
      questionnaireActions.updateChoice(surveyid, question.id, choice.id, {
        config: { shortLabel: value }
      });
      // const _choice = produce(choice, draftChoice => {
      //   draftChoice.label_short = value;
      // });
      // const path = [surveyid, question.id, choice.id];
      // surveyActions.saveSubElement(path, 'choice', _choice);
    },
    [surveyid, question.id, choice.id]
  );

  if (locale === 'shortlabels') {
    return (
      <EditableTextHover
        className={'help-text text-font mb-2'}
        value={choice.config?.shortLabel ?? null}
        placeholder={placeholderText}
        replacing={true}
        onBlur={updateShortlabel}
        writeable={true}
      />
    );
  }

  return (
    <>
      <div id={`_${choice.id}_${locale}`} className='d-flex'>
        <div className='flex-grow-1'>
          <EditableTextHover
            className={'no-padding'}
            value={choiceLabel}
            placeholder={placeholderText}
            replacing={true}
            onBlur={checkUpdateLabel}
            writeable={writeable}
          />
        </div>
        {getSingleTranslation && (
          <AiTranslationButton
            getTranslations={() => getSingleTranslation(choice.config.translations.label)}
            whiteBackground={true}
          />
        )}
      </div>
      <div>
        {aiTranslationList?.[choice.config.translations.label]?.[locale] && (
          <AiTranslationResult
            aiTranslation={aiTranslationList[choice.config.translations.label][locale]}
            saveHandler={checkUpdateLabel}
            updateAiTranslationList={updateAiTranslationList}
            translationId={choice.config.translations.label}
          />
        )}
      </div>
    </>
  );
};

export const TranslateSharedChoice = ({ question, nodeOnClick, choice, locale, writeable }) => {
  const choiceLabel = useSelector(
    state => state.survey?.translations?.[choice?.config?.translations?.label]?.[locale] ?? ''
  );
  const mainLanguage = useSelector(state => state.survey.locales.main);

  const intl = useIntl();

  const isMainLanguage = useMemo(() => mainLanguage === locale, [mainLanguage, locale]);

  const placeholderText = useMemo(() => {
    if (writeable) return intl.formatMessage({ id: 'smoove.questionnaire.translation.question.choice.placeholder' });
    if (isMainLanguage)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.missing-tanslation.placeholder' });
    return intl.formatMessage({
      id: 'smoove.questionnaire.translation.missing-tanslation-with-fallback.placeholder'
    });
  }, [intl, writeable, isMainLanguage]);

  if (locale === 'shortlabels') return <div>-</div>;

  return (
    <div
      className={'d-flex align-items-center translate-shared'}
      onClick={e => {
        nodeOnClick(e, `${choice.id}_${locale}`);
      }}
    >
      <UncontrolledTooltip target={`tt_${choice.id}_translationmodal`} delay={{ hide: 200 }} autohide={false}>
        {intl.formatMessage({ id: 'smoove.questionnaire.shared-choice' })}
      </UncontrolledTooltip>
      <i className={'fal fa-arrow-turn-down-right mx-2'} id={`tt_${choice.id}_translationmodal`}></i>
      <EditableTextHover className={'no-padding'} placeholder={placeholderText} value={choiceLabel} writeable={false} />
    </div>
  );
};
