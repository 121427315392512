// export const stripHtml = string => {
//   const tmp = document.createElement('div');
//   tmp.innerHTML = string;

//   return tmp.textContent || tmp.innerText || '';
// };

/**
 * Removes html tags from string or array of strings, then checks the string for htmlEntities such as &amp; for a "&"-sign and replaces that with human readable values using decodeHtmlEntities
 *
 * @param  {string | string[]} input
 *
 * @return {string | string[]} stripedHtml
 */
export const stripHtml = input => {
  const regExp = /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g;

  if (Array.isArray(input)) {
    return input.map(item => decodeHtmlEntities(item.replace(regExp, '')));
  }

  if (typeof input === 'string') {
    return decodeHtmlEntities(input?.replace(regExp, ''));
  }

  return input;
};

const decodeHtmlEntities = str => {
  const entityMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&#47;': '/',
    '&#96;': '`',
    '&#61;': '=',
    '&nbsp;': ' '
  };

  return Object.keys(entityMap).reduce((acc, entity) => {
    const regex = new RegExp(entity, 'g');
    return acc.replace(regex, entityMap[entity]);
  }, str);
};
