import produce from 'immer';

import { projectActionTypes } from '../constants';

const INITIAL_STATE = {
  fetching: false,
  list: []
};

export const projectsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case projectActionTypes.PROJECTS_LIST_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.isLoadingCompleted = false;
      });

    case projectActionTypes.PROJECTS_LIST_SUCCESS:
      return produce(state, draft => {
        const { projects } = payload;
        draft.loading = false;
        draft.error = false;
        draft.list = projects;
        draft.isLoadingCompleted = true;
      });

    case projectActionTypes.PROJECTS_LIST_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.isLoadingCompleted = true;
      });

    case projectActionTypes.DUPLICATE_PROJECT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case projectActionTypes.DUPLICATE_PROJECT_SUCCESS:
      return produce(state, draft => {
        const { project } = payload;
        draft.list.push(project);
        draft.loading = false;
      });

    case projectActionTypes.DUPLICATE_PROJECT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    case projectActionTypes.DELETE_PROJECT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case projectActionTypes.DELETE_PROJECT_SUCCESS:
      return produce(state, draft => {
        const { projectid } = payload;
        draft.list = draft.list.filter(project => project.id !== projectid);
        draft.loading = false;
      });

    case projectActionTypes.DELETE_PROJECT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    case projectActionTypes.INIT_RESET_PROJECTS_LIST:
      return produce(state, draft => {
        draft.loading = false;
        draft.fetching = false;
        draft.isLoadingCompleted = false;
        draft.list = [];
      });

    case projectActionTypes.PATCH_PROJECT_SUCCESS: {
      return produce(state, draft => {
        const projectIndex = draft.list.findIndex(project => project.id === payload.project.id);
        draft.list[projectIndex] = payload.project;
      });
    }

    default:
      return state;
  }
};
