import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { ErrorBoundary } from '../misc';
import { DashboardElementInvalid, DashboardElementMedia } from './dashboard-element-types';
import { DashboardText } from './dashboard-element-types/DashboardText';

import './DashboardElement.scss';

const DashboardChart = lazy(() => import('./dashboard-element-types/DashboardChart'));

export const DashboardElement = ({
  reportid,
  pageid,
  elementid,
  chartConfig,
  reportsHandler,
  handler,
  readonly,
  activeSplits,
  activeFilters
}) => {
  const survey = useSelector(state => state.survey);

  const chartRef = useRef(null);
  const [isRendered, setIsRendered] = useState(false);
  const [willBeRendered, setWillBeRendered] = useState(false);

  // intersection Observer (lazyload) logic start
  // using the in-between state "willBeRendered" to check if element is still in viewport after a short timeout. If it is, set isRendered & render element, if not, reset willBeRendered. This prevents elements from loading when they are scrolled over fast / jumped to element via sidebar (current timeout see setTimeout in useEffect)
  const intersectionCb = useCallback(
    entries => {
      const [entry] = entries;
      if (!isRendered) {
        if (!willBeRendered && entry.isIntersecting) {
          //will be rendered
          setWillBeRendered(true);
        }
        if (willBeRendered && !entry.isIntersecting) {
          // cancel rendering, will not be rendered (just scrolled over question)
          setWillBeRendered(false);
        }
        if (willBeRendered && entry.isIntersecting) {
          //render
          setIsRendered(true);
        }
      }
    },
    [isRendered, willBeRendered]
  );

  useEffect(() => {
    const colRef = chartRef.current;
    let observer = null;
    let timer = null;
    let options = {
      root: null, //viewport
      rootMargin: '0px',
      threshold: 0.1
    };
    if (!isRendered && !willBeRendered) {
      observer = new IntersectionObserver(intersectionCb, options);
      if (colRef) observer.observe(colRef);
    }
    if (!isRendered && willBeRendered) {
      timer = setTimeout(() => {
        observer = new IntersectionObserver(intersectionCb, options);
        if (colRef) observer.observe(colRef);
      }, 100);
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (colRef && observer) observer.unobserve(colRef);
    };
  }, [willBeRendered, isRendered, intersectionCb]);
  // intersection Observer (lazyload) logic end

  const element = survey?.reports?.list?.[reportid]?.pages?.list?.[pageid]?.elements?.[elementid] ?? {};
  const table = survey.tables.list[element.sourceId];

  const { loading = false, error, sourceType } = element;

  if (sourceType === 'media') {
    const mediaType = element?.mediaConfig?.mediaType;

    if (mediaType === 'image') {
      return (
        <div ref={chartRef} className={`h-100 w-100 ${!isRendered ? 'dashboard-element__placeholder-loading' : ''}`}>
          {!isRendered && <ChartSkeleton sourceType={sourceType} />}
          {isRendered && (
            <Suspense fallback={<div className={`h-100 w-100`} style={{ backgroundColor: 'white' }}></div>}>
              <DashboardElementMedia
                element={element}
                reportid={reportid}
                pageid={pageid}
                readonly={readonly}
                handler={handler}
              />
            </Suspense>
          )}
        </div>
      );
    } else if (mediaType === 'text') {
      return (
        <div ref={chartRef} className={`h-100 w-100 ${!isRendered ? 'dashboard-element__placeholder-loading' : ''}`}>
          {!isRendered && <ChartSkeleton sourceType={sourceType} />}
          {isRendered && (
            <Suspense fallback={<div className={`h-100 w-100`} style={{ backgroundColor: 'white' }}></div>}>
              <DashboardText
                element={element}
                reportid={reportid}
                pageid={pageid}
                readonly={readonly}
                handler={handler}
              />
            </Suspense>
          )}
        </div>
      );
    } else {
      return <DashboardElementInvalid handler={handler} element={element} readonly={readonly} />;
    }
  } else if (sourceType === 'table') {
    return (
      <div ref={chartRef} className={`h-100 w-100 ${!isRendered ? 'dashboard-element__placeholder-loading' : ''}`}>
        {!isRendered && <ChartSkeleton sourceType={sourceType} />}
        {isRendered && (
          <Suspense fallback={<div className={`h-100 w-100`} style={{ backgroundColor: 'white' }}></div>}>
            {
              <ErrorBoundary
                fallback={<DashboardElementInvalid handler={handler} element={element} readonly={readonly} />}
              >
                <DashboardChart
                  surveyId={survey.id}
                  elementid={elementid}
                  table={table}
                  element={element}
                  reportid={reportid}
                  pageid={pageid}
                  readonly={readonly}
                  handler={handler}
                  reportsHandler={reportsHandler}
                  chartConfig={chartConfig}
                  activeSplits={activeSplits}
                  activeFilters={activeFilters}
                />
              </ErrorBoundary>
            }
          </Suspense>
        )}
      </div>
    );
  }

  if (!loading && (!table || !!error)) {
    return <DashboardElementInvalid />;
  }

  return null;
};

const ChartSkeleton = ({ sourceType }) => {
  return (
    <div className='chart-skeleton'>
      <div className='chart-header'>
        <div className='line'></div>
      </div>
      {/* todo: show skeleton content differntly depending on sourceType? */}
      <div className='lines'>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
      <div className='chart-footer'>
        <div className='line'></div>
        <div className='line'></div>
      </div>
    </div>
  );
};
