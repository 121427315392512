import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, FormGroup, Input, InputGroup, Row, UncontrolledTooltip } from 'reactstrap';
import { ConfirmAction, InlineEdit } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { dataPrepActions } from 'smv-redux';

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const WeightAsFile = ({ weight, surveyId, weightHandler }) => {
  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);
  const inputFile = useRef(null);
  const intl = useIntl();

  const setWeightLabel = value => {
    const _weight = { ...weight };
    _weight.label = value;
    weightHandler.updateWeight(_weight.id, _weight);
  };

  const setStandardWeight = value => {
    const _weight = { ...weight };
    _weight.isDefault = value;
    weightHandler.updateWeight(_weight.id, _weight);
  };

  const handleClickUploadFileButton = () => {
    inputFile.current.click();
  };

  const handleDeleteCsvFile = useCallback(() => {
    dataPrepActions.deleteWeightCsvFile(surveyId, weight.id);
  }, [surveyId, weight.id]);

  const handleFileUploadDialog = useCallback(
    e => {
      let files = Object.values(e?.target?.files).map(el => {
        el.id = weight.id;
        return el;
      });

      files.forEach(file => {
        dataPrepActions.uploadWeightCsvFile(surveyId, weight.id, file);
      });
      e.target.value = null;
    },
    [surveyId, weight.id]
  );

  const isFileUploaded = Boolean(weight?.config?.weightFileUrl);

  return (
    <div className='data-weight' id={weight.id}>
      <Row key={weight.id} className='mb-1 d-flex align-items-center'>
        <Col md={{ size: 6, offset: 0 }}>
          <div className='d-flex align-items-center'>
            <i className='fal fa-file mr-2' />
            <b className='bold mr-1'>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-name' })}:</b>
            <InlineEdit
              className='data-weight__title mr-4'
              defaultValue={weight.label}
              placeholder='enter weight name...'
              saveFn={value => setWeightLabel(value)}
              disabled={!userCanEditProject}
            />
            <CopyWeightIdInput idstring={`weight_${weight.id}`} />
          </div>
        </Col>
        <Col md={6} className='d-flex justify-content-end align-items-center'>
          <div className='d-flex align-items-center' id={`weight_standard_toggle-${weight.id}`}>
            <div className='mr-2'>{intl.formatMessage({ id: 'smoove.page.data.weight.standard-weight' })} </div>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`toggleSetStandardWeight_${weight.id}`}
                name={`toggleSetStandardWeight_${weight.id}`}
                className='pointer'
                checked={weight.isDefault ?? false}
                disabled={!userCanEditProject}
                onChange={e => setStandardWeight(e.target.checked)}
              />
            </FormGroup>
          </div>
          <UncontrolledTooltip placement='top' target={`weight_standard_toggle-${weight.id}`}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.default-weight-for-newly-created-tables' })}
          </UncontrolledTooltip>
          {isFileUploaded && userCanEditProject && (
            <ConfirmAction
              title={intl.formatMessage({ id: 'smoove.page.data.weight.delete-weight-csv-file' })}
              size={'md'}
              body={
                <div>
                  {intl.formatMessage({
                    id: 'smoove.page.data.weight.do-you-really-want-to-delete-the-csv-file-for-this-weight'
                  })}
                </div>
              }
              callback={handleDeleteCsvFile}
            >
              {confirm => (
                <Button color='danger' outline onClick={confirm} className='mr-2'>
                  <span>{intl.formatMessage({ id: 'smoove.page.data.weight.delete-csv-file' })}</span>
                </Button>
              )}
            </ConfirmAction>
          )}
          {isFileUploaded && (
            <a className='btn btn-sm btn-smv-blue-outline mr-2' href={`${baseUrl}/${weight.weightFileUrl}`}>
              {intl.formatMessage({ id: 'smoove.page.data.weight.download-csv-file' })}
            </a>
          )}
          <Button onClick={handleClickUploadFileButton} className='mr-4' disabled={!userCanEditProject}>
            <span>
              {isFileUploaded
                ? intl.formatMessage({ id: 'smoove.page.data.weight.change-csv-file' })
                : intl.formatMessage({ id: 'smoove.page.data.weight.upload-csv-file' })}
            </span>
          </Button>
          <input
            type='file'
            accept='.csv'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleFileUploadDialog}
          />

          {userCanEditProject && (
            <>
              <ConfirmAction
                title={intl.formatMessage({ id: `smoove.page.data.weight.delete-weight` })}
                size={'md'}
                body={<div>{intl.formatMessage({ id: `smoove.page.data.weight.delete-weight-confirm-text` })}</div>}
                callback={() => weightHandler.deleteWeight(weight.id)}
              >
                {confirm => (
                  <Button color='danger' outline className='mr-1' onClick={confirm} id={`delweight_${weight.id}`}>
                    <i className='fal fa-trash-alt'></i>
                  </Button>
                )}
              </ConfirmAction>
              <UncontrolledTooltip placement='top' target={`delweight_${weight.id}`} fade={false}>
                {intl.formatMessage({ id: 'smoove.page.data.weight.delete-weight' })}
              </UncontrolledTooltip>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const CopyWeightIdInput = ({ idstring }) => {
  const [showNotification, setShowNotification] = useState(false);

  const intl = useIntl();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(idstring);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 1200);
  };

  return (
    <div className='copy-weight-input'>
      <InputGroup>
        <Input type='text' className='pointer' value={idstring} readOnly onClick={copyToClipboard} />
        <Button color='primary' onClick={copyToClipboard}>
          <i className='fal fa-copy'></i>
        </Button>
      </InputGroup>
      {showNotification && (
        <div className='copied-notification'>
          {intl.formatMessage({ id: 'smoove.page.data.weight.weight-id-copied' })}
        </div>
      )}
    </div>
  );
};
