import { useState, useCallback } from 'react';

export const useSignificanceFooterMouseEffectHighlight = () => {
  const [activeSignificanceType, setActiveSignificanceType] = useState(null);

  const onMouseEnterSignificance = useCallback(e => {
    const significanceType = e.target.dataset.significancetype ?? null;
    setActiveSignificanceType(significanceType);
  }, []);

  const onMouseLeaveSignificance = useCallback(() => {
    setActiveSignificanceType(null);
  }, []);

  const getOpacitySignificance = significanceType => {
    return activeSignificanceType !== null ? (significanceType === activeSignificanceType ? 1 : 0.2) : 1;
  };

  return [onMouseEnterSignificance, onMouseLeaveSignificance, getOpacitySignificance, activeSignificanceType];
};
