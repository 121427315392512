import {
  FormulaBuilderReferenceCalcFrequencyGroupElement,
  FormulaBuilderReferenceCalculationValueElement,
  FormulaBuilderReferenceCalculationVariableElement,
  FormulaBuilderReferenceQuestionElement,
  FormulaBuilderReferenceSystemVariableElement,
  FormulaBuilderReferenceTotalElement
} from '.';

export const FormulaBuilderReferenceElementSwitch = props => {
  switch (props.element.value.sourceType) {
    case 'question':
      if (props.element.value.sourceId === '__total__') {
        return <FormulaBuilderReferenceTotalElement {...props} />;
      } else {
        return <FormulaBuilderReferenceQuestionElement {...props} />;
      }
    case 'sysvar':
      return <FormulaBuilderReferenceSystemVariableElement {...props} />;
    case 'calcvar':
      return <FormulaBuilderReferenceCalculationVariableElement {...props} />;
    case 'calcFrequencyGroup':
      return <FormulaBuilderReferenceCalcFrequencyGroupElement {...props} />;
    case 'calculation':
      return <FormulaBuilderReferenceCalculationValueElement {...props} />;
    default:
      return null;
  }
};
