import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { translationsActions } from 'smv-redux';
import { parsePlaceholdersAndSetImages, useElementPropertyTranslation } from 'smv-helpers';

import { EditableTextJodit as EditableText, EditableTextHover } from '../..';
import { saveQuestionText } from '../../MediaLibrary/helpers/saveQuestionText';

export const QuestionText = ({ question, writeable }) => {
  const survey = useSelector(state => state.survey);

  const surveyid = survey.id;
  const surveyMedia = survey.media;

  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main ?? 'en-US'
  );
  const isFetchingTranslation = useSelector(
    state => state.survey.translations?.[question.translations.text]?.fetching ?? false
  );
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const toggleMediaLibrary = useCallback(() => setIsMediaLibraryOpen(b => !b), []);

  const intl = useIntl();

  const questionText = useElementPropertyTranslation(question, {
    locale,
    useStripped: false,
    useFallback: !writeable,
    useShortcodes: false
  });

  const renderedQuestionText = useMemo(() => parsePlaceholdersAndSetImages(questionText, question, surveyMedia), [
    questionText,
    surveyMedia,
    question
  ]);

  const questionInstruction = useElementPropertyTranslation(question, {
    property: 'instruction',
    useStripped: false,
    useFallback: !writeable,
    useShortcodes: false
  });

  const saveTextUpdate = useCallback(
    value => {
      if (isMediaLibraryOpen) return;
      saveQuestionText(value, question, surveyMedia, questionText, surveyid, locale);
    },
    [questionText, surveyid, question, locale, surveyMedia, isMediaLibraryOpen]
  );

  const checkUpdateInstruction = useCallback(
    value => {
      if (value !== questionInstruction)
        // surveyActions.saveTranslation([surveyid, question.id], 'question', 'instruction', locale, value);
        translationsActions.updateTranslation(surveyid, question.translations.instruction, {
          [locale]: value
        });
    },
    [questionInstruction, surveyid, locale, question.translations.instruction]
  );

  // const checkUpdateAssistance = useCallback(value => {
  //   if (value !== questionAssistance) surveyActions.saveTranslation([surveyid, question.id], 'question', 'assistance', locale, value);
  // }, [questionAssistance, surveyid, question.id, locale]);

  const placeholderQuestion = intl.formatMessage({
    id: 'smoove.questionnaire.translation.question.text.placeholder',
    defaultMessage: 'enter question...'
  });

  const placeholderInstruction = intl.formatMessage({
    id: 'smoove.questionnaire.translation.question.instruction.placeholder',
    defaultMessage: 'enter instructions...'
  });

  return (
    <div className='mt-1 mb-2'>
      {/* Question Text */}
      <div className='mb-2'>
        <EditableText
          value={renderedQuestionText}
          placeholder={renderedQuestionText?.length <= 0 ? placeholderQuestion : ''}
          onBlur={saveTextUpdate}
          isMediaLibraryOpen={isMediaLibraryOpen}
          toggleMediaLibrary={toggleMediaLibrary}
          writeable={writeable}
          isLoading={question.loading ?? isFetchingTranslation}
        />
      </div>

      {/* Instruction Text */}
      <EditableTextHover
        value={questionInstruction}
        onBlur={checkUpdateInstruction}
        placeholder={placeholderInstruction}
        className={'help-text text-font mb-2'}
        writeable={writeable}
      />
    </div>
  );
};

QuestionText.propTypes = {
  question: PropTypes.object.isRequired,
  writeable: PropTypes.bool.isRequired
};

export default QuestionText;
