import { useCallback } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import produce from 'immer';

export const ValueSettings = ({ tableConfig, handler }) => {
  const intl = useIntl();

  const setValueType = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valueType = value;
        })
      );
    },
    [handler]
  );

  const setValuePrecision = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valuePrecision = value;
        })
      );
    },
    [handler]
  );

  const toggleValueForcePrecision = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.valueForcePrecision = !draftState.config.valueForcePrecision;
        })
      ),
    [handler]
  );

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.table-config.values'} defaultMessage={'Values'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage id={'smoove.page.tables.table-config.value-type'} defaultMessage={'Value-Type'} />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.table-config.value-type` })}
            id={`valueType`}
            name='valueType'
            value={tableConfig.config.valueType}
            onChange={setValueType}
          >
            <option value='' disabled>
              {intl.formatMessage({ id: `smoove.page.tables.table-config.choose-value-type` })}
            </option>
            <option value='perc'>{intl.formatMessage({ id: `smoove.page.tables.table-config.percentages` })}</option>
            <option value='abs'>{intl.formatMessage({ id: `smoove.page.tables.table-config.absolute-values` })}</option>
          </Input>
        </Col>
      </Row>

      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage id={'smoove.page.tables.table-config.value-precision'} defaultMessage={'Value precision'} />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.table-config.value-precision` })}
            id={`valuePrecision`}
            name='valuePrecision'
            value={tableConfig.config.valuePrecision}
            onChange={setValuePrecision}
          >
            <option value='' disabled>
              {intl.formatMessage({ id: `smoove.page.tables.table-config.choose-value-precision` })}
            </option>
            <option value={0}># (0)</option>
            <option value={1}>#.# (1)</option>
            <option value={2}>#.## (2)</option>
            <option value={3}>#.### (3)</option>
          </Input>
        </Col>
      </Row>
      {tableConfig.config.valuePrecision > 0 && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.force-value-precision` })}</Col>
          <Col md={7}>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`valueForcePrecision`}
                name='valueForcePrecision'
                checked={tableConfig.config.valueForcePrecision}
                onChange={toggleValueForcePrecision}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};
