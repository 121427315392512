import React from 'react';

export const OpenEndedDefaultResultRow = ({ element, tableConfig, row, tableResult, questionHas, handler }) => {
  return (
    Object.values(tableResult?.values?.[row.id]?.__total__?.__total__ ?? {}).map(resultRow => {
      return (
        <tr key={`result_row_${row.id}_${resultRow.pid}`}>
          <td>{resultRow.key}</td>
        </tr>
      );
    }) ?? null
  );
};
