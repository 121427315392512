import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ActiveFilterGroup, ActiveFilterElement } from '.';
import { stripHtml } from 'src/smoove/helpers';

import './ActiveFilterList.scss';

export const ActiveFilterList = ({ activeFilters, report, reportsHandler }) => {
  const filters = report.filters.list;

  const surveyid = useSelector(state => state.survey?.id ?? false);
  const elements = useSelector(state => state.survey.questionnaire.list);
  const calcVars = useSelector(state => state.survey?.data?.calculations);
  const locales = useSelector(state => state.survey.locales);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const selectedLocale = useSelector(state => state.projectSettings?.[surveyid]?.selectedLocale ?? locales.main);
  const translations = useSelector(state => state.survey?.translations);

  const renderFilters = useMemo(() => {
    return Object.keys(activeFilters).map(filterid => {
      let label, element, valueList;
      const filter = filters[filterid];
      if (!filter) return '';

      const isSingleSelect = filter?.isSingleSelect ?? false;
      const isMandatory = filter?.isMandatory ?? false;
      const valueids = Object.keys(activeFilters[filterid]);

      let isDeselectable = true;
      if (isMandatory && (isSingleSelect || valueids.length <= 1)) {
        isDeselectable = false;
      }

      if (filter.sourceType === 'question') {
        element = elements[filter.sourceId];
        label = filter?.label?.length > 0 ? filter.label : element?.title ?? '';
        valueList = {
          ...element?.config?.choices?.list,
          ...elements[element?.config?.choices?.import?.source]?.config?.choices?.list
        };
      } else if (filter.sourceType === 'sysvar') {
        element = systemVariables.list[filter.sourceId];
        label = filter?.label?.length > 0 ? filter.label : element?.label ?? '';
        valueList = element?.list ?? {};
      } else if (filter.sourceType === 'calcvar') {
        element = calcVars?.find(el => el.id === filter.sourceId);
        label = filter?.label?.length > 0 ? filter.label : element.label ?? '';
        valueList = Object.fromEntries(element?.values?.map(value => [value.id, value]));
      }

      const filterElements = valueids.map((filterelementid, idx) => {
        const subelement = valueList[filterelementid];
        let label = '';

        if (filter.sourceType === 'question') {
          label += stripHtml(translations[subelement.config.translations.label][selectedLocale]);
        } else if (filter.sourceType === 'sysvar' || filter.sourceType === 'calcvar') {
          label += stripHtml(subelement?.label);
        }
        return (
          <ActiveFilterElement
            key={`${filterid}_${filterelementid}_${idx}`}
            value={subelement}
            reportsHandler={reportsHandler}
            label={label}
            filter={filter}
            isDeselectable={isDeselectable}
          />
        );
      });

      return (
        <ActiveFilterGroup key={`${filterid}_active-filter-group`} label={`${label}: `}>
          {filterElements}
        </ActiveFilterGroup>
      );
    });
  }, [activeFilters, reportsHandler, calcVars, systemVariables, elements, filters, translations, selectedLocale]);

  if (_.isEmpty(activeFilters)) return <div></div>;

  return (
    <div className='active-filter-list'>
      <span>Filter:</span>
      {renderFilters}
    </div>
  );
};
