import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Input, Row } from 'reactstrap';

import { RowLabel } from 'smv-components';
import { surveyActions } from 'smv-redux';
import { useSelector } from 'react-redux';

export const QuestbackSettings = () => {
  const intl = useIntl();
  const survey = useSelector(state => state.survey ?? {});
  const projectId = useSelector(state => state.project.id ?? {});

  const [questbackProjectId, setQuestbackProjectId] = useState(survey?.questback?.id ?? '');

  useEffect(() => {
    setQuestbackProjectId(survey?.questback?.id ?? '');
  }, [survey?.questback?.id]);

  const handleChange = useCallback(e => {
    if (e.target.value.length === 0) {
      setQuestbackProjectId('');
      return;
    } else {
      const qbProjectId = parseInt(e.target.value);
      if (isNaN(qbProjectId)) return;
      else setQuestbackProjectId(qbProjectId);
    }
  }, []);

  const handleSave = useCallback(
    e => {
      const qbProjectId = parseInt(e.target.value);
      if (e.target.value.length <= 0 || isNaN(qbProjectId)) {
        if (survey?.questback?.id) {
          surveyActions.updateSurvey(projectId, survey.id, {
            questback: {
              ...(survey?.questback ?? {}),
              id: null
            }
          });
        } else {
          return;
        }
      } else {
        surveyActions.updateSurvey(projectId, survey.id, {
          questback: {
            ...(survey?.questback ?? {}),
            id: qbProjectId
          }
        });
      }
    },
    [survey.id, projectId, survey.questback]
  );

  return (
    <Row className='my-4'>
      <RowLabel
        name={'questback-settings'}
        title={intl.formatMessage({
          id: 'smoove.page.project.general.tivian-settings.label',
          defaultMessage: 'Tivian Settings'
        })}
        tooltip={intl.formatMessage({
          id: 'smoove.page.project.general.tivian-settings.tooltip',
          defaultMessage:
            'Settings for the connection to Questback, such as the project number and other configurations'
        })}
      />
      <Col md={7}>
        <Input
          name={'questback-project-id'}
          title={intl.formatMessage({ id: 'smoove.page.project.general.tivian-settings.tivian-id.label' })}
          tooltip={intl.formatMessage({ id: 'smoove.page.project.general.tivian-settings.tivian-id.desc' })}
          placeholder={intl.formatMessage({ id: 'smoove.page.project.general.tivian-settings.tivian-id.placeholder' })}
          type={'number'}
          value={questbackProjectId}
          onWheel={e => e.target.blur()}
          onChange={handleChange}
          onBlur={handleSave}
        />
      </Col>
    </Row>
  );
};
