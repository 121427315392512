import { useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { useIntl } from 'react-intl';

import { settingsActions } from 'smv-redux';

import { downloadFile } from 'src/smoove/redux/actions/helper';

import './ExportsCenter.scss';

const ExportsWindow = ({ isSharedView, toggle, isOpen }) => {
  const isLoadingExports = useSelector(state => state.smooveSettings?.globalState?.loadingExports ?? false);

  const params = useParams();
  const intl = useIntl();

  const [files, setFiles] = useState(null);

  const getData = useCallback(() => {
    if (isSharedView) {
      settingsActions
        .getSharedExports(params.reportid, params.token)
        .then(res => {
          setFiles(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      settingsActions
        .getUserExports()
        .then(res => {
          setFiles(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [params.reportid, params.token, isSharedView]);

  // get data on initial load
  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen, getData]);

  // start polling if an item has status "running"
  useEffect(() => {
    let timer = null;
    if (files) {
      if (files.some(item => item.status === 'running')) {
        timer = setTimeout(() => {
          getData();
        }, 10000);
      }
    }
    return () => clearTimeout(timer);
  }, [files, getData]);

  // //show only items that belong to the current survey
  // const _items = useMemo(() => {
  //   return items.filter(item => {
  //     return (
  //       item.source.clientId === selectedClientId &&
  //       item.source.projectId === projectId &&
  //       item.source.surveyId === surveyId
  //     );
  //   });
  // }, [selectedClientId, projectId, surveyId]);

  return (
    <div className='exports-window'>
      <i className='exports-window__close-button fal fa-fw fa-xmark pointer' onClick={toggle} />
      <div className='ml-3 mt-2'>
        <b>{intl.formatMessage({ id: 'smoove.page.reports.export.export-center.exports' })}</b>{' '}
      </div>
      {isLoadingExports && (
        <div className='d-flex align-items-center justify-content-center p-4'>
          <i className='fal fa-spinner fa-spin' />
        </div>
      )}
      {!isLoadingExports && files?.length <= 0 && (
        <div className='ml-3 mt-2'>
          <small>
            <i>{intl.formatMessage({ id: 'smoove.page.reports.export.export-center.no-exports-found' })}</i>
          </small>
        </div>
      )}
      {!isLoadingExports && (
        <div className='exports-window__list'>
          {(files ?? [])
            .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
            .map((item, idx) => {
              return (
                <ExportItem
                  item={item}
                  key={`${item.id}`}
                  isSharedView={isSharedView}
                  reportId={params.reportid}
                  token={params.token}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

const ExportItem = ({ item, isSharedView, reportId, token }) => {
  const surveyId = useSelector(state => state.survey?.id);

  let faFileTypeString = 'file';
  switch (item.type) {
    case 'xlsx':
      faFileTypeString = 'excel';
      break;
    case 'pptx':
      faFileTypeString = 'powerpoint';
      break;
    default:
      break;
  }

  const showSourceInfo =
    Boolean(item.source.clientTitle) && Boolean(item.source.projectTitle) && Boolean(item.source.surveyTitle);

  const download = () => {
    if (item.status !== 'finished') return null;
    if (isSharedView) {
      axios
        .get(`/shares/reports/${reportId}/exports/${item.id}/file/${token}`, {
          responseType: 'blob',
          timeout: 5 * 60 * 1000
        })
        .then(res => {
          downloadFile(res.data, `${item.fileName}.${item.type}`);
        });
    } else {
      axios
        .get(`/users/me/exports/surveys/${surveyId}/${item.id}/file`, {
          responseType: 'blob',
          timeout: 5 * 60 * 1000
        })
        .then(res => {
          downloadFile(res.data, `${item.fileName}.${item.type}`);
        });
    }
  };

  return (
    <div className={`export-item ${item.status === 'finished' ? 'export-item--finished' : ''}`} onClick={download}>
      <div className='export-item__type'>
        <i className={`fal fa-file-${faFileTypeString}`} />
      </div>
      <div className='export-item__info-block'>
        <div className='export-item__name'>
          {item.fileName}.{item.type}
        </div>
        {showSourceInfo && (
          <div className='export-item__project-info'>
            <small>{item.source.clientTitle}</small>
            <small> - </small>
            <small>{item.source.projectTitle}</small>
            <small> - </small>
            <small>{item.source.surveyTitle}</small>
          </div>
        )}
        <div className='export-item__date'>
          <small>{formatDate(item.createdAt)}</small>
        </div>
      </div>
      <div
        className={`export-item__download ${
          item.status === 'finished' ? 'export-item__download--ready' : 'export-item__download--loading'
        }`}
      >
        {item.status === 'running' && <i className='fal fa-spinner fa-spin' />}
        {item.status === 'finished' && <i className='fal fa-download' />}
        {item.status === 'error' && <i className='fal fa-triangle-exclamation' />}
      </div>
    </div>
  );
};

export const ExportsCenter = ({ isSharedView = false, className = '' }) => {
  const intl = useIntl();

  const isSharedExportWindowOpen = useSelector(
    state => state.smooveSettings?.globalState?.isSharedReportExportsWindowOpen ?? false
  );
  const isUserExportsWindowOpen = useSelector(
    state => state.smooveSettings?.globalState?.isUserExportsWindowOpen ?? false
  );

  const isOpen = isSharedView ? isSharedExportWindowOpen : isUserExportsWindowOpen;

  const toggle = isSharedView
    ? settingsActions.toggleSharedReportExportsWindow
    : settingsActions.toggleUserExportsWindow;

  return (
    <div className={`exports-center ${className}`}>
      <Dropdown
        className={`${isSharedView ? 'btn btn-outline-secondary btn-sm' : 'smv-header__dropdown'}`}
        tag={isSharedView ? 'button' : 'div'}
        isOpen={isOpen}
        toggle={toggle}
        inNavbar
      >
        <DropdownToggle
          nav
          id={`tt_export-center${isSharedView ? '-shared' : ''}`}
          className={`${isSharedView ? '' : 'header-bubble-icon'} ${isOpen ? 'header-bubble-icon--open' : ''}`}
          draggable={false}
        >
          <i className={`fal fa-${isOpen && !isSharedView ? 'xmark' : 'download'} fa-fw text-secondary `} />
        </DropdownToggle>
        {isSharedView && (
          <UncontrolledTooltip
            target={`tt_export-center${isSharedView ? '-shared' : ''}`}
            delay={{ hide: 200 }}
            autohide={false}
          >
            {intl.formatMessage({ id: 'smoove.page.reports.export.export-center.download-exports' })}
          </UncontrolledTooltip>
        )}
        <DropdownMenu end>
          {isOpen && <ExportsWindow isSharedView={isSharedView} toggle={toggle} isOpen={isOpen} />}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

function formatDate(randomDate) {
  const now = moment();
  const date = moment(randomDate);

  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');

  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffHours < 2) {
    return `${diffHours} hour ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else {
    return date.format('DD.MM - HH:mm');
  }
}
