import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, UncontrolledTooltip, Row, Col } from 'reactstrap';

import { questionnaireActions } from 'smv-redux';

const getPath = (element, questionnaire) => {
  const path = [];
  let parentId = element.parentId;
  while (parentId) {
    path.push(parentId);
    const parent = questionnaire.list[parentId];
    parentId = parent.parenetId;
  }

  return path.reverse();
};

export const ConfigTabVarname = ({ question, writeable }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const surveyid = useSelector(state => state.survey.id);

  const [varname, setVarname] = useState(question.config?.varname);

  useEffect(() => {
    setVarname(question.config?.varname);
  }, [question.config?.varname]);

  const handleUpdateVarname = useCallback(() => {
    if (writeable && varname !== question.config?.varname) {
      questionnaireActions
        .updateQuestionnaireElement(surveyid, question.id, {
          config: { varname: varname }
        })
        .then(res => {})
        .catch(err => {
          setVarname(question.config?.varname);
        });
    }
  }, [varname, question.config?.varname, question.id, surveyid, writeable]);

  const breadcrumbs = useMemo(() => {
    if (question.parentId !== null) {
      const path = getPath(question, questionnaire);
      console.log(path);

      return (
        <div className='input-group-prepend'>
          {path.map(parentId => {
            const parent = questionnaire.list[parentId];
            if (parent.config?.varprefix) {
              return (
                <React.Fragment key={parentId}>
                  <Link
                    to={`/one/projects/${surveyid}/que/${parentId}/config?`}
                    id={`tt_${parentId}`}
                    className='btn btn-outline-info'
                  >
                    {parent.config.varprefix}
                  </Link>
                  <UncontrolledTooltip target={`tt_${parentId}`} delay={{ hide: 500 }} autohide={false}>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-tab.breadcrumb-tooltip`}
                      defaultMessage={'Variable prefix configured by'}
                    />
                    {parent.title}
                  </UncontrolledTooltip>
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>
      );
    }

    return null;
  }, [question, surveyid, questionnaire]);

  return (
    <>
      <Row>
        <Col sm='6' lg='5' className='d-flex align-items-center'>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.varname`} defaultMessage={'Varname'} />
          <i className='fal fa-circle-info ml-2' id={`tt_varname`} />
          <UncontrolledTooltip target={`tt_varname`} delay={{ hide: 200 }} autohide={false}>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-tab.varname-tooltip`}
              defaultMessage={
                'The varname is displayed in front of the question name and is used as a unique identifier internally.'
              }
            />
          </UncontrolledTooltip>
        </Col>
        <Col sm='6' lg='4'>
          <div className='input-group input-group-sm'>
            {breadcrumbs}
            <Input
              id={`config_suffix_id_${question.id}`}
              placeholder='Enter varname'
              bsSize='sm'
              type='text'
              disabled={!writeable}
              // todo: use generated varname when available
              value={varname}
              onChange={e => setVarname(e.target.value)}
              onBlur={handleUpdateVarname}
            />
          </div>
        </Col>
      </Row>
      <hr className='my-2' />
    </>
  );
};
