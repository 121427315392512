import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteFolderButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i className='fal fa-trash-alt icon-smv-blue mr-1' />
      <span>
        <FormattedMessage defaultMessage={`delete folder`} id={`smoove.page.survey.tables.form.delete-folder.button`} />
      </span>
    </div>
  );
};

export const DeleteFolderModal = ({ isOpen, toggle, deleteHandler, title = '' }) => {
  const _deleteHandler = useCallback(() => {
    deleteHandler();
    toggle();
  }, [deleteHandler, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage
          id={'smoove.page.survey.tables.form.delete-folder.headline'}
          defaultMessage={'Delete Folder'}
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id={'smoove.page.survey.tables.form.delete-folder.body'}
          defaultMessage={"Are you sure you want to delete report '{title}'?"}
          values={{ title: title }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.cancel'} defaultMessage={'Cancel'} />
        </Button>
        <Button color={'danger'} onClick={_deleteHandler}>
          <FormattedMessage id={'smoove.common.buttons.delete'} defaultMessage={'Delete'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
