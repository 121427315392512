import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, UncontrolledTooltip } from 'reactstrap';

import { EditableTextHover } from '../../../..';
import { questionnaireActions, translationsActions } from 'smv-redux/actions';

export const ConfigScaleItem = ({ question, scale, writeable }) => {
  const survey = useSelector(state => state.survey);
  const surveyid = useSelector(state => state.survey.id);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';
  const expertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);

  const intl = useIntl();

  const scaleLabel = survey?.translations?.[scale?.config?.translations?.label]?.[locale];

  const [scaleValueInput, setScaleValueInput] = useState(scale?.value ?? null);

  const questionnaireConflicts = useSelector(state => state.survey.questionnaire?.conflicts);
  const hasConflicts = questionnaireConflicts?.[question.id]?.scales?.includes(scale.id);

  const checkUpdateLabel = useCallback(
    value => {
      if (!writeable) return;
      if (value !== scaleLabel)
        translationsActions.updateTranslation(surveyid, scale.config.translations.label, {
          [locale]: value
        });
    },
    [scaleLabel, surveyid, locale, writeable, scale.config.translations.label]
  );

  const handleUpdateScaleValue = useCallback(() => {
    if (!writeable) return;
    if (!scaleValueInput && scaleValueInput !== 0) {
      setScaleValueInput(scale.value);
      return;
    }
    if (scaleValueInput !== scale.value) {
      questionnaireActions.updateScale(surveyid, question.id, scale.id, { value: scaleValueInput });
    }
  }, [scaleValueInput, scale, question.id, surveyid, writeable]);

  const deleteScale = useCallback(() => {
    if (!writeable) return;
    questionnaireActions.removeScale(surveyid, question.id, scale.id);
  }, [writeable, surveyid, question.id, scale.id]);

  return (
    <div key={scale?.id} className='scaleItem'>
      {writeable && (
        <div className='scaleItem__grab'>
          <i id={`move_${scale?.id}`} className='fa-grip-vertical fal'></i>
          <UncontrolledTooltip placement='top' target={`move_${scale?.id}`}>
            Drag to move
          </UncontrolledTooltip>
        </div>
      )}
      <div className='scaleItem__label mr-3'>
        <EditableTextHover
          writeable={writeable}
          value={scaleLabel}
          replacing={true}
          onBlur={checkUpdateLabel}
          className='no-padding'
        />
      </div>
      {expertMode && (
        <div
          className={`scaleItem__value mr-2 ${hasConflicts ? 'scaleItem__value--warning' : ''}`}
          id={`value_${scale?.id}`}
        >
          <Input
            disabled={!writeable}
            bsSize='sm'
            type='number'
            min={1}
            value={scaleValueInput ?? ''}
            onChange={e => setScaleValueInput(parseInt(e.target.value))}
            onBlur={handleUpdateScaleValue}
            className={`scaleitem-input ${scaleValueInput <= 0 ? 'scaleitem-input--invalid' : ''}`}
          ></Input>
          <UncontrolledTooltip placement='top' target={`value_${scale?.id}`}>
            Value
          </UncontrolledTooltip>
          {scaleValueInput <= 0 && (
            <small className='scaleitem-danger'>
              {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-container.value-must-be-greater-than-0' })}
            </small>
          )}
        </div>
      )}
      {writeable && (
        <div className='scaleItem__trash'>
          <i id={`trash_${scale?.id}`} className='far fa-trash-alt pointer' onClick={deleteScale} />
          <UncontrolledTooltip placement='top' target={`trash_${scale?.id}`}>
            <FormattedMessage id={'smoove.misc.delete'} />
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  );
};
