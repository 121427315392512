import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { ApplyToValue } from '.';

export const ApplyToValues = ({
  conditionalFormat,
  dimensionKey,
  matrixType,
  label,
  main,
  values = { order: [], list: {} },
  handleApplyToSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const isSelected = useMemo(() => {
    return !!conditionalFormat.applyTo?.[dimensionKey]?.[main.id];
  }, [conditionalFormat.applyTo, dimensionKey, main.id]);

  const _element = useMemo(() => ({ dimensionKey, mainId: main.id }), [dimensionKey, main.id]);

  const _handleApplyToSelect = useCallback(() => handleApplyToSelect(_element), [_element, handleApplyToSelect]);

  return (
    <Dropdown
      tag={'div'}
      direction={'end'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'dropdown-item--active': isSelected
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'smv-custom-dropdown__dropdown-toggle--flex': true
        })}
      >
        <div className={'smv-custom-dropdown__buttons'} onClick={_handleApplyToSelect}>
          {label}
          <i
            className={classNames({
              'fa-regular': true,
              'fa-check ml-2': !!conditionalFormat.applyTo?.[dimensionKey]?.[main.id]
            })}
          />
        </div>

        <DropdownToggle
          tag={'div'}
          className={classNames({
            'smv-custom-dropdown__buttons': true,
            'smv-custom-dropdown__dropdown-toggle': true
          })}
        >
          <i className={'fa-thin fa-chevron-right'} />
        </DropdownToggle>
      </div>

      <DropdownMenu
        end
        className={classNames({
          'smv-custom-dropdown__dropdown-menu': true,
          'smv-custom-dropdown__dropdown-menu--nested': isOpen
        })}
      >
        {values.order?.map(valueId => {
          const value = values.list[valueId];
          const _element = { dimensionKey, mainId: main.id, subId: valueId };

          const isSelected = conditionalFormat.applyTo?.[dimensionKey]?.[main.id]?.[valueId] ?? false;

          return (
            <ApplyToValue
              key={`formula-builder-fixed-element-item-${main.id}-${valueId}`}
              element={_element}
              value={value}
              isSelected={isSelected}
              handleApplyToSelect={handleApplyToSelect}
            />
          );
        })}

        {dimensionKey === 'rows' &&
          matrixType !== 'detail' &&
          Object.values(main.config?.calcFrequencyGroups ?? {}).map(calcGroup => {
            const _element = {
              dimensionKey,
              mainId: main.id,
              subId: calcGroup.id
            };

            const isSelected = conditionalFormat.applyTo?.[dimensionKey]?.[main.id]?.[calcGroup.id] ?? false;

            return (
              <ApplyToValue
                key={`formula-builder-fixed-element-item-${main.id}-${calcGroup.id}`}
                element={_element}
                value={calcGroup}
                isSelected={isSelected}
                handleApplyToSelect={handleApplyToSelect}
              />
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};
