import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

export const WeightProtocolModal = ({ isOpen, toggle, weight }) => {
  const protocol = weight?.result?.weightProtocol ?? null;

  const intl = useIntl();

  if (!protocol || !isOpen) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'} className='weight-protocol-modal'>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-protocol' })}</ModalHeader>
      <ModalBody>
        <div className='mt-2 mb-4'>
          <i
            className={`weighting-message weighting-message--${
              weight.result?.weightingSuccessful ? 'success' : 'error'
            }`}
          >
            {weight.result?.message && intl.formatMessage({ id: `smoove.page.data.${weight.result?.message}` })}
          </i>
        </div>
        <Table size={'sm'} striped>
          <thead>
            <tr>
              <th>#</th>
              <th>{intl.formatMessage({ id: 'smoove.page.data.weight.min-weight-value' })}</th>
              <th>{intl.formatMessage({ id: 'smoove.page.data.weight.max-weight-value' })}</th>
              <th>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-accuracy' })}</th>
              <th>{intl.formatMessage({ id: 'smoove.page.data.weight.correction-factor-needed' })}</th>
            </tr>
            {protocol.map(el => {
              return (
                <tr key={el.iterationNr}>
                  <td>{el.iterationNr}</td>
                  <td>{el.minWeightValue}</td>
                  <td>{el.maxWeightValue}</td>
                  <td>{el.weightAccuracy}</td>
                  <td>{el.correctionFactorNeeded ? 'yes' : 'no'}</td>
                </tr>
              );
            })}
          </thead>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.close'} defaultMessage={'close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
