import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';
import classNames from 'classnames';

import { translationsActions, questionnaireActions } from 'smv-redux/actions';

import { EditableTextHover } from '../../';
import { ScaleConfigContainer, NewItem } from '.';

import './MatrixScales.scss';

export const MatrixScales = ({ question, writeable, limit = 10 }) => {
  const surveyid = useSelector(state => state.survey.id);
  const [collapsed, setCollapsed] = useState(true);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    if (question.config?.scales?.order) {
      setOrder(question.config?.scales?.order);
    } else {
      setOrder([]);
    }
  }, [question.config?.scales?.order]);

  const onDragUpdate = useCallback(r => {
    console.log(r);
  }, []);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;
      const newOrder = arrayMoveImmutable(order, source.index, destination.index);
      setOrder(newOrder);

      questionnaireActions.updateScale(surveyid, question.id, order[source.index], {
        position: destination.index
      });

      if (!_.isEqual(order, newOrder)) {
        // surveyActions.moveSubElement(surveyid, question.id, order[source.index], 'scale', targetid).then(res => {
        //   if (res.status === 'error') {
        //     notify.addNotification({
        //       title: intl.formatMessage({
        //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-title',
        //         defaultMessage: 'An error occured.'
        //       }),
        //       message: intl.formatMessage({
        //         id: 'smoove.questionnaire.modal.config-tab.move-sub-element-error-message',
        //         defaultMessage: 'The move operation was unsuccessful.'
        //       }),
        //       type: 'danger',
        //       ...defaultNotifyProps
        //     });
        //     // incase of error revert to previous order
        //     setOrder(order);
        //   }
        // });
      }
    },
    [order, surveyid, question.id]
  );

  const scaleLength = useMemo(() => {
    return question.config?.scales?.order?.length ?? 0;
  }, [question.config?.scales]);

  const shortOrder = useMemo(() => {
    if (!collapsed || order.length < limit) return order;

    if (collapsed) {
      return order.slice(0, limit);
    }
  }, [collapsed, order, limit]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <div className={`matrix-scale-container`}>
        <div className='my-1 fw-bold'>
          <FormattedMessage id={`smoove.questionnaire.modal.config-tab.choices.scale`} defaultMessage={'Scale'} />
        </div>
        <div className={'scale-items-container'}>
          <MakeDroppable droppableId='scale-item-droppable-que' writeable={writeable}>
            {shortOrder.map((id, index) => {
              const scale = question.config?.scales?.list?.[id];
              if (!scale) return null;

              return (
                <MakeDraggable key={id} draggableId={id} index={index} isDragDisabled={!writeable}>
                  <MatrixScale
                    key={`scaleitem_${id}`}
                    index={index}
                    question={question}
                    scale={scale}
                    writeable={writeable}
                  />
                </MakeDraggable>
              );
            }) ?? (
              <i>
                <FormattedMessage
                  id={'smoove.questionnaire.modal.config-tab.scale.no-scale-yet'}
                  defaultMessage={'No scale defined yet.'}
                />
              </i>
            )}
          </MakeDroppable>
        </div>
        {scaleLength > limit && (
          <div className='pointer btn-link text-smv-blue pt-2' onClick={() => setCollapsed(!collapsed)}>
            <i
              className={classNames('fa mr-2', {
                'fa-angle-double-down': collapsed,
                'fa-angle-double-up': !collapsed
              })}
            ></i>
            {collapsed && (
              <FormattedMessage
                defaultMessage={'show all'}
                id={`smoove.common.buttons.unfold-x-items`}
                values={{ cnt: scaleLength - limit }}
              />
            )}
            {!collapsed && <FormattedMessage defaultMessage={'show less'} id={`smoove.common.buttons.fold-items`} />}
          </div>
        )}
        {writeable && (
          <>
            <hr className='my-1' />
            <NewItem question={question} type={'scale'} placeholderText={'Add scale item'} />
          </>
        )}
      </div>
    </DragDropContext>
  );
};

const getDraggableStyles = isDragging => {
  return {
    boxSizing: 'border-box',
    borderRadius: isDragging && '4px',
    background: 'white',
    boxShadow: isDragging
      ? '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)'
      : 'none'
  };
};

const MakeDraggable = ({ children, draggableId, index, isDragDisabled }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            ...getDraggableStyles(snapshot.isDragging)
          }}
          // className='draggable-default'
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId, writeable }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={!writeable}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const MatrixScale = ({ question, scale, writeable }) => {
  const survey = useSelector(state => state.survey);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';

  const label = survey?.translations?.[scale?.config?.translations?.label]?.[locale];

  const updateScaleLabel = value => {
    if (!writeable) return;
    if (value !== label) {
      translationsActions.updateTranslation(survey.id, scale.config.translations.label, {
        [locale]: value
      });
    }
  };

  return (
    <div className='d-flex ml-1 matrix-scale' style={{ fontWeight: 'normal', fontSize: '0.88rem' }}>
      <i className={`fal qtype fa-fw fa-${question?.config?.qtype === 'multiple_matrix' ? 'square' : 'circle'}`} />
      <EditableTextHover
        value={label}
        writeable={writeable}
        replacing={true}
        onBlur={updateScaleLabel}
        className={'no-padding'}
      />
      <ScaleConfigContainer scale={scale} question={question} writeable={writeable} />
    </div>
  );
};
