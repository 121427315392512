import { produce } from 'immer';
import { chartLayersActionTypes, sharedReportActionTypes } from '../constants';

const CHARTLAYERS_INITIAL_STATE = {
  loading: false,
  error: false,
  list: {},
  order: []
};

export const chartLayersReducer = (state = CHARTLAYERS_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * LOAD_CHARTLAYERS_X
     */
    case chartLayersActionTypes.LOAD_CHARTLAYERS_REQUEST: {
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    }
    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
    case chartLayersActionTypes.LOAD_CHARTLAYERS_SUCCESS: {
      return produce(state, draft => {
        draft.loading = false;
        draft.list = payload.chartLayers.list;
        draft.order = payload.chartLayers.order;
      });
    }
    case chartLayersActionTypes.LOAD_CHARTLAYERS_FAILURE: {
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });
    }

    /**
     * UPDATE_CHARTLAYERVIEWSORDER_X
     */
    case chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_REQUEST: {
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    }
    case chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_SUCCESS: {
      return produce(state, draft => {
        draft.loading = false;
        draft.list[payload.layer.id] = payload.layer;
      });
    }
    case chartLayersActionTypes.UPDATE_CHARTLAYERVIEWSORDER_FAILURE: {
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });
    }

    // case chartLayersActionTypes.LOAD_CHARTLAYERS_REQUEST: {}
    // case chartLayersActionTypes.LOAD_CHARTLAYERS_SUCCESS: {}
    // case chartLayersActionTypes.LOAD_CHARTLAYERS_FAILURE: {}

    default:
      return state;
  }
};
