import React from 'react';

import { useRowOrder } from 'smv-helpers';
import { useDefaultMetric } from './helper';
import { DefaultQuestionMenu, SingleVectorChoiceMenu, MultipleVectorChoiceMenu } from './menus';
import { ResultRow, ResultRowHeader } from '.';

export const CalcVarResultRow = ({
  element,
  table,
  tableResult,
  row,
  translation,
  questionHas,
  isCompactMatrix,
  matrixType,
  handler,
  hover
}) => {
  const { heads, config } = table;
  const { showHiddenRows = true } = config;
  const { id: rowid, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row, showHiddenRows);
  const colSpan = matrixType === 'detail' ? 2 : 1;

  const defaultMetric = useDefaultMetric(table);
  const rowHeadLabel = element.label;
  const subelements = {
    order: element?.values?.map(el => el.id),
    list: Object.fromEntries(element?.values?.map(value => [value.id, value]))
  };

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};

  let questionMenu = (
    <DefaultQuestionMenu
      rowid={rowid}
      row={row}
      element={element}
      subelements={subelements}
      handler={handler}
      questionHas={questionHas}
      table={table}
    />
  );

  const _rows = [];
  rowOrder.forEach(order => {
    const metric = defaultMetric;
    let subelement = calcFrequencyGroups[order?.subelement] ?? subelements.list[order.subelement];

    const isHidden = order?.hidden ?? false;
    const isExcluded = order?.excluded ?? false;

    if (!showHiddenRows && (isHidden || isExcluded)) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;

    let choiceMenu = null;
    if (element.multiple === false) {
      choiceMenu = (
        <SingleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={rowid}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    } else if (element.multiple === true) {
      choiceMenu = (
        <MultipleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={rowid}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHidden}
          isExcluded={isExcluded}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    }

    _rows.push(
      <ResultRow
        key={`result-row-${rowid}_${order.subelement}`}
        rowid={rowid}
        orderItem={order}
        subelement={subelement}
        choiceid={order.subelement}
        config={config}
        heads={heads}
        tableResult={tableResult}
        handler={handler}
        metric={metric}
        isHidden={isHidden}
        isExcluded={isExcluded}
        sortationPinned={sortationPinned}
        isCompactMatrix={isCompactMatrix}
        menu={choiceMenu}
        hover={hover}
      >
        {_rows.length === 0 && (
          <ResultRowHeader
            rowid={rowid}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            colSpan={colSpan}
            handler={handler}
            questionHas={questionHas}
            menu={questionMenu}
          />
        )}
      </ResultRow>
    );
  });

  return _rows;
};
