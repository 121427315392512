// old backend upload functionality
import Axios from 'axios';

export function uploadMediaItem(payload, file) {
  const pca = {
    program: 'Smoove',
    controller: 'XMedia',
    action: 'uploaditem'
  };

  const fd = new FormData();
  fd.append('payload', JSON.stringify(payload));
  fd.append('file', file);

  return Axios.post('/jsonrpc.php', fd, {
    params: pca,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${fd._boundary}`
    }
    // withCredentials: false
  });
}
