import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Gets `isEmpty` and `resultTableType` for current table config.
 *
 * - `isEmpty` returns a boolean, which indicates if rows and heads are both empty.
 * - `resultTableType` returns a string (`default` or `listing`), which indicates,
 *   if the current table configuration must be rendered as a default of a listing
 *   (of open ended mentions) table.
 *
 * @param {object} table
 *
 * @returns {object} { isEmpty: Boolean, resultTableType: String }
 */
export const useResultTableType = table => {
  const questionnaire = useSelector(state => state.survey.questionnaire ?? null);

  return useMemo(() => {
    const rowsLength = table?.rows?.order?.length ?? 0;
    const headLength = table?.heads?.order?.length ?? 0;
    const isEmpty = rowsLength === 0 && headLength === 0;

    if (isEmpty) return { isEmpty, resultTableType: false };

    let resultTableType = isEmpty ? false : 'default';
    const questionIdRow0 = table?.rows?.list[table?.rows?.order?.[0]]?.sourceId;
    const questionRow0 = questionnaire.list?.[questionIdRow0];

    if (
      !isEmpty &&
      rowsLength === 1 &&
      questionRow0?.type === 'question' &&
      ['single_input_text', 'multiple_input_text'].includes(questionRow0.config.qtype)
    ) {
      resultTableType = 'listing';
    }

    return { isEmpty, resultTableType };
  }, [questionnaire.list, table]);
};
