import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, CardBody, Col, Collapse, FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';

// import { dataPrepActions } from "smv-redux";

import { ConfirmAction, InlineEdit } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

import { WeightDefinition } from './WeightDefinition/WeightDefinition';
import { WeightSettingsModal } from './WeightSettingsModal';
import { WeightProtocolModal } from './WeightProtocolModal';

export const WeightAsCustom = ({ weightId, weight, weightHandler }) => {
  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);
  const isRunningCalculations = useSelector(state => state.survey?.isRunningCalculations);
  const readOnly = useMemo(() => !userCanEditProject || isRunningCalculations, [
    userCanEditProject,
    isRunningCalculations
  ]);

  const intl = useIntl();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapse = () => setIsCollapseOpen(b => !b);

  const [isWeightSettingsModalOpen, setIsWeightSettingsModalOpen] = useState(false);
  const toggleWeightSettingsModal = () => setIsWeightSettingsModalOpen(b => !b);

  const [isWeightProtocolModalOpen, setIsWeightProtocolModalOpen] = useState(false);
  const toggleWeightProtocolModal = () => setIsWeightProtocolModalOpen(b => !b);

  const setWeightLabel = value => {
    const _weight = { ...weight };
    _weight.label = value;
    weightHandler.updateWeight(_weight.id, _weight);
  };

  const setStandardWeight = value => {
    const _weight = { ...weight };
    _weight.isDefault = value;
    weightHandler.updateWeight(_weight.id, _weight);
  };

  const isModified = !weight?.isUnmodified;

  if (!weight) return null;

  return (
    <div className={`data-weight`} id={weightId}>
      <Row className='mb-1 d-flex align-items-center'>
        <Col md={{ size: 6, offset: 0 }} className=' d-flex align-items-center'>
          <div onClick={toggleCollapse}>
            <i className={`fal fa-fw fa-chevron-${isCollapseOpen ? 'down' : 'right'} pointer pr-2`} />
          </div>
          <div className='d-flex align-items-center'>
            <b className='bold mr-1 pointer' onClick={toggleCollapse}>
              {intl.formatMessage({ id: 'smoove.page.data.weight.weight-name' })}:
            </b>
            <InlineEdit
              className='data-weight__title mr-4'
              defaultValue={weight.label}
              placeholder='enter weight name...'
              saveFn={value => setWeightLabel(value)}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col md={6} className='d-flex justify-content-end align-items-center'>
          <div className='d-flex align-items-center' id={`weight_standard_toggle-${weightId}`}>
            <div className='mr-2'>{intl.formatMessage({ id: 'smoove.page.data.weight.standard-weight' })} </div>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`toggleSetStandardWeight_${weightId}`}
                name={`toggleSetStandardWeight_${weightId}`}
                className='pointer'
                checked={weight.isDefault ?? false}
                disabled={readOnly}
                onChange={e => setStandardWeight(e.target.checked)}
              />
            </FormGroup>
          </div>
          <UncontrolledTooltip placement='top' target={`weight_standard_toggle-${weightId}`}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.default-weight-for-newly-created-tables' })}
          </UncontrolledTooltip>
          <Button
            color='secondary'
            id={`procotol_${weightId}`}
            outline
            onClick={toggleWeightProtocolModal}
            className='mr-2'
            disabled={readOnly || !weight?.result?.weightProtocol}
          >
            <i className='fal fa-file-circle-info' />
          </Button>
          <UncontrolledTooltip placement='top' target={`procotol_${weightId}`} fade={false}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.show-weighting-protocol' })}
          </UncontrolledTooltip>
          <Button
            color='primary'
            outline
            id={`add_definition_${weightId}`}
            onClick={() => {
              weightHandler.addWeightDefinition(weightId);
              setIsCollapseOpen(true);
            }}
            className='mr-2'
            disabled={readOnly}
          >
            <i className='fal fa-plus' />
          </Button>
          <UncontrolledTooltip placement='top' target={`add_definition_${weightId}`} fade={false}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.add-weight-definition' })}
          </UncontrolledTooltip>
          <Button
            color='secondary'
            outline
            id={`weight_settings_${weightId}`}
            onClick={toggleWeightSettingsModal}
            className='mr-2'
            disabled={readOnly}
          >
            <i className='fal fa-cog' />
          </Button>
          <UncontrolledTooltip placement='top' target={`weight_settings_${weightId}`} fade={false}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.weight-settings' })}
          </UncontrolledTooltip>
          {userCanEditProject && (
            <>
              <ConfirmAction
                title={intl.formatMessage({ id: `smoove.page.data.weight.delete-weight` })}
                size={'md'}
                body={<div>{intl.formatMessage({ id: `smoove.page.data.weight.delete-weight-confirm-text` })}</div>}
                callback={() => weightHandler.deleteWeight(weightId)}
              >
                {confirm => (
                  <Button
                    color='danger'
                    outline
                    className='mr-1'
                    onClick={confirm}
                    id={`delweight_${weightId}`}
                    disabled={readOnly}
                  >
                    <i className='fal fa-trash-alt'></i>
                  </Button>
                )}
              </ConfirmAction>
              <UncontrolledTooltip placement='top' target={`delweight_${weightId}`} fade={false}>
                {intl.formatMessage({ id: 'smoove.page.data.weight.delete-weight' })}
              </UncontrolledTooltip>
            </>
          )}
        </Col>
      </Row>
      {isModified && (
        <Row>
          <Col>
            <>
              <small className='text-warning'>
                <i className='fal fa-triangle-exclamation mx-1' />
                Gewichtseinstellungen wurden geändert, bitte Berechnungen ausführen.
              </small>
            </>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={{ size: 12 }}>
          <Collapse isOpen={isCollapseOpen}>
            {weight.result && (
              <Row>
                <Col md={12}>
                  <Card className='weight-info'>
                    <CardBody className='py-2'>
                      <div className='d-flex align-items-center mt-1 pb-2'>
                        <div>
                          {intl.formatMessage({ id: 'smoove.page.data.weight.definition.number-of-iterations' })}{' '}
                          {weight.result.neededIterations}
                        </div>
                        <i className='fal fa-pipe mx-2' />
                        <div>
                          {intl.formatMessage({ id: 'smoove.page.data.weight.definition.min-weight-value' })}{' '}
                          {weight.result.minWeightValue}
                        </div>
                        <i className='fal fa-pipe mx-2' />
                        <div>
                          {intl.formatMessage({ id: 'smoove.page.data.weight.definition.max-weight-value' })}{' '}
                          {weight.result.maxWeightValue}
                        </div>
                        <i className='fal fa-pipe mx-2' />
                        <div>
                          {intl.formatMessage({ id: 'smoove.page.data.weight.definition.accuracy' })}{' '}
                          {weight.result.reachedWeightAccuracy}
                        </div>
                      </div>
                      <div>
                        <i
                          className={`weighting-message weighting-message--${
                            weight.result?.weightingSuccessful ? 'success' : 'error'
                          }`}
                        >
                          {weight.result?.message &&
                            intl.formatMessage({ id: `smoove.page.data.${weight.result?.message}` })}
                        </i>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {(weight?.definitions ?? [])?.map(definition => {
              return (
                <WeightDefinition
                  key={definition.id}
                  weightId={weightId}
                  definition={definition}
                  definitionCount={Object.values(weight.definitions)?.length}
                  weightHandler={weightHandler}
                  readOnly={readOnly}
                />
              );
            })}
          </Collapse>
        </Col>
      </Row>
      <WeightSettingsModal
        weight={weight}
        isOpen={isWeightSettingsModalOpen}
        toggle={toggleWeightSettingsModal}
        weightHandler={weightHandler}
        readOnly={readOnly}
      />
      <WeightProtocolModal weight={weight} isOpen={isWeightProtocolModalOpen} toggle={toggleWeightProtocolModal} />
    </div>
  );
};
