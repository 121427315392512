import React, { useCallback } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export const DeleteReportButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i className='fal fa-trash-alt icon-smv-blue mr-1' />
      <span>
        <FormattedMessage
          defaultMessage={`Delete Report`}
          id={`smoove.page.reports.modals.delete-report.button-open`}
        />
      </span>
    </div>
  );
};

export const DeleteReportModal = ({ isOpen, toggle, reportid, deleteHandler, title = '' }) => {
  const history = useHistory();
  const { path, params } = useRouteMatch();

  const resetRoute = useCallback(() => {
    if (reportid === params.reportid)
      history.push(generatePath(path, { projectid: params.projectid, surveyid: params.surveyid }));
  }, [history, path, params, reportid]);

  const _deleteHandler = useCallback(() => {
    resetRoute();
    toggle();

    deleteHandler(reportid);
  }, [deleteHandler, reportid, toggle, resetRoute]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={'smoove.page.reports.modals.delete-report.headline'} defaultMessage={'Delete Report'} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id={'smoove.page.reports.modals.delete-report.body'}
          defaultMessage={"Are you sure you want to delete report '{title}'?"}
          values={{ title: title }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.cancel'} defaultMessage={'Cancel'} />
        </Button>
        <Button color={'danger'} onClick={_deleteHandler}>
          <FormattedMessage id={'smoove.common.buttons.delete'} defaultMessage={'Delete'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
