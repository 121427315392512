import { Collapse, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { EditDynamicSplitsButtonAndModal } from '../modals';
import { DynamicSplit } from '.';

import './DynamicSplits.scss';
import { useCallback, useMemo, useState } from 'react';

export const DynamicSplits = ({ report, handler, activeSplits, readonly }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(state => !state), []);

  const splits = useMemo(() => {
    if (report?.splits?.order?.length <= 0) return <div className='ml-3'>no splits available</div>;

    return report?.splits?.order?.map(splitid => {
      const split = report.splits.list[splitid];
      return <DynamicSplit key={splitid} split={split} handler={handler} activeSplits={activeSplits} />;
    });
  }, [activeSplits, handler, report.splits]);

  return (
    <ListGroupItem className={'reports-list smv-dynamic-split-list '}>
      <ListGroupItemHeading className='pointer mb-0 py-2 list-item-sticky'>
        <div className='reports-list__headline'>
          <i onClick={toggleOpen} className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
          <span onClick={toggleOpen} className='ml-1 reports-list__headline-text'>
            <FormattedMessage id={'smoove.page.common.dynamic-splits.label'} defaultMessage={'Splitting'} />
          </span>
          {!readonly && <EditDynamicSplitsButtonAndModal report={report} />}
        </div>
      </ListGroupItemHeading>
      <Collapse isOpen={isOpen}>{splits}</Collapse>
    </ListGroupItem>
  );
};
