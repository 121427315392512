import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';

export const RememberMe = ({ show = true, state, onChange }) => {
  if (!show) return null;
  return (
    <>
      <FormGroup switch>
        <Input
          type='switch'
          role='switch'
          id={`remembermeSwitch`}
          name={'remember'}
          checked={state.remember}
          onChange={onChange}
        />
        <Label for={'remembermeSwitch'}>
          <FormattedMessage id={'core.page.login.remember.label'} defaultMessage={'Remember Login'} />
        </Label>
      </FormGroup>
    </>
  );
};
