import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

export const BaseFilter = ({ rowid, config, handler }) => {
  const autoHideLowBases = config?.autoHideLowBases ?? false;
  const autoHideLowBasesHurdle = config?.autoHideLowBasesHurdle ?? 0;
  const autoHideLowFrequencies = config?.autoHideLowFrequencies ?? false;
  const autoHideLowFrequenciesHurdle = config?.autoHideLowFrequenciesHurdle ?? 0;

  return (
    <>
      <div className='mb-3'>
        <h6>Base Filter</h6>
        <FormGroup switch>
          <Input
            id={`${rowid}_autoHideLowBases`}
            type='switch'
            role='switch'
            name={'autoHideLowBases'}
            checked={autoHideLowBases}
            onChange={handler.setCheckboxValue}
          />
          <Label for={`${rowid}_autoHideLowBases`}>Hide rows with low base</Label>
        </FormGroup>
        {autoHideLowBases && (
          <FormGroup>
            <Label for={`${rowid}_autoHideLowBasesHurdle`}>Minimum Base (abs.)</Label>
            <Input
              id={`${rowid}_autoHideLowBasesHurdle`}
              name={'autoHideLowBasesHurdle'}
              onChange={handler.setNumericValue}
              value={autoHideLowBasesHurdle}
              type='number'
              style={{ maxWidth: '200px', fontSize: '0.75rem' }}
            />
          </FormGroup>
        )}
      </div>
      <div>
        <h6>Frequency Filter</h6>
        <FormGroup switch>
          <Input
            id={`${rowid}_autoHideLowFrequencies`}
            type='switch'
            role='switch'
            name={'autoHideLowFrequencies'}
            checked={autoHideLowFrequencies}
            onChange={handler.setCheckboxValue}
          />
          <Label for={`${rowid}_autoHideLowFrequencies`}>Hide rows with low frequencies</Label>
        </FormGroup>
        {autoHideLowFrequencies && (
          <FormGroup>
            <Label for={`${rowid}_autoHideLowFrequenciesHurdle`}>Minimum Frequency (%)</Label>
            <Input
              id={`${rowid}_autoHideLowFrequenciesHurdle`}
              name={'autoHideLowFrequenciesHurdle'}
              onChange={handler.setNumericValue}
              value={autoHideLowFrequenciesHurdle}
              type='number'
              style={{ maxWidth: '200px', fontSize: '0.75rem' }}
            />
          </FormGroup>
        )}
      </div>
    </>
  );
};
