import React from 'react';
import { useSelector } from 'react-redux';

export const ResultTableOpenEndedListingBody = ({ table, tableResult }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire.list);
  const head = table.heads;
  const rowid = table.rows.order[0];

  // Todo: Backend currently changes the tableResult depending on defined list&offset set in the table. It should return the whole table for this view, ignoring a saved limt & offset so we can use local state for these variables.

  const rawData = tableResult?.values?.[rowid]?.__input_raw__?.__total__?.__total__;

  return (
    <div className={'result-table--body'}>
      {(rawData ?? []).map((response, index) => {
        return (
          <div key={`result-row-${rowid}-${index}`} className={'result-table--row'}>
            <div className={'result-table--cell'}>&nbsp;</div>
            <div className={'result-table--cell'}>{response?.pid}</div>
            <div className={'result-table--cell'}>{response?.input_raw}</div>

            {/* additional columns */}
            {head.order.map(headid => {
              const headElement = head.list[headid];
              const element = questionnaire[headElement.sourceId];
              return (
                <div key={`result-column-${headid}-cell`} className={'result-table--cell'}>
                  {response?.[element?.config?.varname] ?? '-'}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
