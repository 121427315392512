import produce from 'immer';
import { isEqual } from 'lodash';
import { useState, useCallback, useEffect } from 'react';

export const useLegendMouseEffects = props => {
  const [activeDimension, setActiveDimension] = useState(null);
  const [hiddenSeries, setHiddenSeries] = useState(props?.hiddenSeries ?? {});

  useEffect(() => {
    if (props?.hiddenSeries) {
      setHiddenSeries(s => {
        if (!isEqual(s, props.hiddenSeries)) {
          return props.hiddenSeries;
        } else {
          return s;
        }
      });
    }
  }, [props?.hiddenSeries]);

  const onMouseEnter = useCallback(
    o => {
      const dimension = o.dataKey?.split('.')?.[0] || o.value;
      if (!(hiddenSeries?.[o.dataKey] ?? false)) setActiveDimension(dimension);
    },
    [hiddenSeries]
  );

  const onMouseLeave = useCallback(() => {
    setActiveDimension(null);
  }, []);

  const getOpacity = dimension => {
    return activeDimension !== null ? (dimension === activeDimension ? 1 : 0.2) : 1;
  };

  const toggleSeries = useCallback(
    p => {
      setHiddenSeries(s =>
        produce(s, d => {
          if (d?.[p.dataKey]) d[p.dataKey] = !d[p.dataKey];
          else d[p.dataKey] = true;

          const isInactive = d?.[p.dataKey] ?? false;
          const dimensionId = p.dataKey?.split('.')?.[0];
          setActiveDimension(s => {
            if (isInactive) return null;
            else return dimensionId;
          });
        })
      );
    },
    [setHiddenSeries, setActiveDimension]
  );

  const isHidden = useCallback(dimension => hiddenSeries?.[dimension] ?? false, [hiddenSeries]);

  return { onMouseEnter, onMouseLeave, getOpacity, toggleSeries, isHidden, hiddenSeries };
};
