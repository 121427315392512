import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

export const ResultErrorWarning = ({ className = '' }) => {
  const surveyStatus = useSelector(state => state.survey.status);

  if (['running', 'completed'].includes(surveyStatus)) return null;

  return (
    <Alert color={'danger'} style={{ fontSize: '0.75rem' }} className={className}>
      <i className='fal fa-exclamation-triangle mr-2'></i>
      <FormattedMessage
        id={'smoove.page.survey.results.error.warning'}
        defaultMessage={
          'An error occurred while calculating the results. Please check the settings or contact mindline analytics support.'
        }
      />
    </Alert>
  );
};
