import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// import produce from 'immer';
// import { isArray } from 'lodash';

// import { surveyActions } from 'smv-redux/actions';
import { questionnaireActions, translationsActions } from 'smv-redux';
import { parsePlaceholdersAndSetImages } from 'smv-helpers';

import { EditableTextJodit as EditableText } from '../Content/EditableTextJodit';
import { EditableTextHover } from '../Content';
import { saveQuestionText } from '../Content/MediaLibrary/helpers/saveQuestionText';
import { AiTranslationButton, AiTranslationResult } from './AiTranslation';

export const TranslateQuestionText = ({
  question,
  localeA,
  localeB,
  writeable,
  aiTranslationList,
  updateAiTranslationList,
  getSingleTranslation
}) => {
  return (
    <>
      <tr>
        <td>
          <QuestionText question={question} locale={localeA} writeable={writeable} />
        </td>
        <td>
          <QuestionText
            question={question}
            locale={localeB}
            writeable={writeable}
            aiTranslationList={aiTranslationList}
            updateAiTranslationList={updateAiTranslationList}
            getSingleTranslation={localeA !== 'shortlabels' && localeB !== 'shortlabels' ? getSingleTranslation : null}
          />
        </td>
      </tr>
      <tr>
        <td>
          <InstructionText question={question} locale={localeA} writeable={writeable} />
        </td>
        <td>
          <InstructionText
            question={question}
            locale={localeB}
            writeable={writeable}
            aiTranslationList={aiTranslationList}
            updateAiTranslationList={updateAiTranslationList}
            getSingleTranslation={localeA !== 'shortlabels' && localeB !== 'shortlabels' ? getSingleTranslation : null}
          />
        </td>
      </tr>
    </>
  );
};

export const QuestionText = ({
  question,
  locale,
  writeable,
  getSingleTranslation,
  aiTranslationList,
  updateAiTranslationList
}) => {
  const { text: questionTextId } = question.translations;

  const surveyid = useSelector(state => state.survey.id);
  const mainLanguage = useSelector(state => state.survey.locales.main);
  const surveyMedia = useSelector(state => state.survey.media);

  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const toggleMediaLibrary = useCallback(() => setIsMediaLibraryOpen(b => !b), []);
  const intl = useIntl();

  const isMainLanguage = useMemo(() => mainLanguage === locale, [mainLanguage, locale]);

  const placeholderText = useMemo(() => {
    if (writeable) return intl.formatMessage({ id: 'smoove.questionnaire.translation.question.text.placeholder' });
    if (isMainLanguage)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.missing-tanslation.placeholder' });
    return intl.formatMessage({
      id: 'smoove.questionnaire.translation.missing-tanslation-with-fallback.placeholder'
    });
  }, [intl, writeable, isMainLanguage]);

  const questionText = useSelector(state => state.survey?.translations?.[questionTextId]?.[locale] ?? '');

  const renderedQuestionText = useMemo(() => parsePlaceholdersAndSetImages(questionText, question, surveyMedia), [
    questionText,
    surveyMedia,
    question
  ]);

  const saveTextUpdate = useCallback(
    value => {
      if (isMediaLibraryOpen) return;
      saveQuestionText(value, question, surveyMedia, questionText, surveyid, locale);
    },
    [questionText, surveyid, question, surveyMedia, locale, isMediaLibraryOpen]
  );

  const updateShortlabel = useCallback(
    value => {
      if (value !== question?.config?.shortLabel) {
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { shortLabel: value }
        });
      }
    },
    [surveyid, question]
  );

  if (locale === 'shortlabels') {
    return (
      <div>
        <EditableText
          value={question.config.shortLabel ?? null}
          onBlur={updateShortlabel}
          placeholder='enter shortlabel ...'
          className='help-text text-font mb-2'
          writeable={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className='d-flex align-items-bottom'>
        <div className='flex-grow-1'>
          <EditableText
            value={renderedQuestionText}
            onBlur={saveTextUpdate}
            isMediaLibraryOpen={isMediaLibraryOpen}
            toggleMediaLibrary={toggleMediaLibrary}
            placeholder={placeholderText}
            className={'help-text text-font mb-2'}
            writeable={writeable}
          />
        </div>
        {getSingleTranslation && (
          <AiTranslationButton getTranslations={() => getSingleTranslation(questionTextId)} whiteBackground={true} />
        )}
      </div>
      <div>
        {aiTranslationList?.[questionTextId]?.[locale] && (
          <AiTranslationResult
            aiTranslation={aiTranslationList[questionTextId][locale]}
            saveHandler={saveTextUpdate}
            updateAiTranslationList={updateAiTranslationList}
            translationId={questionTextId}
          />
        )}
      </div>
    </>
  );
};

export const InstructionText = ({
  question,
  locale,
  writeable,
  getSingleTranslation,
  aiTranslationList,
  updateAiTranslationList
}) => {
  const { instruction: questionInstructionId } = question.translations;

  const surveyid = useSelector(state => state.survey.id);
  const mainLanguage = useSelector(state => state.survey.locales.main);

  const intl = useIntl();

  const isMainLanguage = useMemo(() => mainLanguage === locale, [mainLanguage, locale]);

  const placeholderText = useMemo(() => {
    if (writeable)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.question.instruction.placeholder' });
    if (isMainLanguage)
      return intl.formatMessage({ id: 'smoove.questionnaire.translation.missing-tanslation.placeholder' });
    return intl.formatMessage({
      id: 'smoove.questionnaire.translation.missing-tanslation-with-fallback.placeholder'
    });
  }, [intl, writeable, isMainLanguage]);

  const questionInstruction = useSelector(state => state.survey?.translations?.[questionInstructionId]?.[locale] ?? '');

  const checkUpdateInstruction = useCallback(
    value => {
      if (value !== questionInstruction)
        translationsActions.updateTranslation(surveyid, question.translations.instruction, {
          [locale]: value
        });
    },
    [questionInstruction, surveyid, locale, question.translations.instruction]
  );

  // no need to set que.config.shortLabel - instruction text does not have a shortlabel

  if (locale === 'shortlabels') return <div>-</div>;

  return (
    <>
      <div className='d-flex align-items-bottom'>
        <div className='flex-grow-1'>
          <EditableTextHover
            value={questionInstruction}
            onBlur={checkUpdateInstruction}
            placeholder={placeholderText}
            className={'help-text text-font mb-2'}
            writeable={writeable}
          />
        </div>
        {getSingleTranslation && (
          <AiTranslationButton
            getTranslations={() => getSingleTranslation(questionInstructionId)}
            whiteBackground={true}
          />
        )}
      </div>
      <div>
        {aiTranslationList?.[questionInstructionId]?.[locale] && (
          <AiTranslationResult
            aiTranslation={aiTranslationList[questionInstructionId][locale]}
            saveHandler={checkUpdateInstruction}
            updateAiTranslationList={updateAiTranslationList}
            translationId={questionInstructionId}
          />
        )}
      </div>
    </>
  );
};
