import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isArray, isEqual } from 'lodash';
import classNames from 'classnames';
import produce from 'immer';

import { useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import { getMergedChoices } from '../../../helper';

import { FormulaBuilderReferenceElementFixedReferenceDropdownItem } from '.';

export const FormulaBuilderReferenceElementFixedReferenceSelect = ({
  calculationId,
  calculationMode,
  tableConfig,
  element,
  handleFixedElementSelect,
  dropdownContainerRef
}) => {
  const questionVariables = useSelector(s => s.survey.questionnaire.list);
  const calculationVariables = useSelector(s => s.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const { isCompactMatrix, isDetailedMatrix } = useMatrixType(tableConfig);

  const oppositeSourceElements = useMemo(() => {
    const _opposite = calculationMode === 'rows' ? 'heads' : 'rows';
    return produce(tableConfig[_opposite], draft => {
      if (isCompactMatrix && _opposite === 'heads') {
        tableConfig.rows.order.forEach(rowid => {
          if (tableConfig.rows.list[rowid].sourceType === 'question') {
            if (isArray(draft.list)) draft.list = {};
            draft.order = [rowid, ...draft.order];
            draft.list[rowid] = tableConfig.rows.list[rowid];
          }
        });
      }
    });
  }, [calculationMode, tableConfig, isCompactMatrix]);

  const totalReference = useMemo(() => {
    if (calculationMode === 'heads' || isCompactMatrix) return null;

    const _element = {
      rowId: null,
      rowSubId: null,
      rowSubSubId: null,
      headId: '__total__',
      headSubId: '__total__'
    };

    const isActive = isEqual(element?.value?.fixed, _element);

    return (
      <FormulaBuilderReferenceElementFixedReferenceDropdownItem
        element={_element}
        value={{ label: 'Total' }}
        isActive={isActive}
        handleFixedElementSelect={handleFixedElementSelect}
      />
    );
  }, [calculationMode, isCompactMatrix, element, handleFixedElementSelect]);

  return (
    <>
      <DropdownItem header>Fixed Reference</DropdownItem>
      {totalReference}

      {oppositeSourceElements?.order?.map(mainid => {
        const main = oppositeSourceElements.list[mainid];
        const { sourceType, sourceId } = main;

        if (sourceType === 'question') {
          let questionElement = questionVariables?.[sourceId] ?? {};
          if (!questionElement) return null;

          const isMatrix = ['single_matrix', 'multiple_matrix'].includes(questionElement.config.qtype);
          if (isMatrix && isDetailedMatrix) {
            const choices = getMergedChoices(questionElement, questionElement?.config?.choices, questionVariables);
            const scales = questionElement?.config?.scales;

            return (
              <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
                key={`formula-builder-fixed-element-select-${main.id}`}
                calculationMode={calculationMode}
                sourceElement={questionElement}
                element={element}
                values={choices}
                scales={scales}
                main={main}
                handleFixedElementSelect={handleFixedElementSelect}
                dropdownContainerRef={dropdownContainerRef}
              />
            );
          } else if (isMatrix && isCompactMatrix && calculationMode === 'rows') {
            const values = getMergedChoices(questionElement, questionElement?.config?.choices, questionVariables);
            return (
              <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
                key={`formula-builder-fixed-element-select-${main.id}`}
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                sourceElement={questionElement}
                element={element}
                values={values}
                main={main}
                handleFixedElementSelect={handleFixedElementSelect}
                dropdownContainerRef={dropdownContainerRef}
              />
            );
          } else if (isMatrix && isCompactMatrix && calculationMode === 'heads') {
            const values = questionElement?.config?.scales;
            return (
              <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
                key={`formula-builder-fixed-element-select-${main.id}`}
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                sourceElement={questionElement}
                element={element}
                values={values}
                main={main}
                handleFixedElementSelect={handleFixedElementSelect}
                dropdownContainerRef={dropdownContainerRef}
              />
            );
          } else if (!isMatrix) {
            const values = getMergedChoices(questionElement, questionElement?.config?.choices, questionVariables);
            return (
              <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
                key={`formula-builder-fixed-element-select-${main.id}`}
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                sourceElement={questionElement}
                element={element}
                values={values}
                main={main}
                handleFixedElementSelect={handleFixedElementSelect}
                dropdownContainerRef={dropdownContainerRef}
              />
            );
          } else {
            return null;
          }
        } else if (sourceType === 'calcvar') {
          const sourceElement = calculationVariables?.find(el => el.id === sourceId) ?? {};
          sourceElement.sourceType = 'calcvar';

          return (
            <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
              key={`formula-builder-fixed-element-select-${main.id}`}
              calculationMode={calculationMode}
              tableConfig={tableConfig}
              sourceElement={sourceElement}
              element={element}
              values={sourceElement.values}
              main={main}
              handleFixedElementSelect={handleFixedElementSelect}
              dropdownContainerRef={dropdownContainerRef}
            />
          );
        } else if (sourceType === 'sysvar') {
          const sourceElement = systemVariables?.list?.[sourceId] ?? {};
          sourceElement.sourceType = 'sysvar';

          return (
            <FormulaBuilderReferenceElementFixedReferenceSelectDropdown
              key={`formula-builder-fixed-element-select-${main.id}`}
              calculationMode={calculationMode}
              tableConfig={tableConfig}
              sourceElement={sourceElement}
              element={element}
              values={{ list: sourceElement.list, order: sourceElement.order }}
              main={main}
              handleFixedElementSelect={handleFixedElementSelect}
              dropdownContainerRef={dropdownContainerRef}
            />
          );
        } else if (sourceType === 'calculation') {
          const currentIndex = tableConfig.config.calculationOrder.findIndex(
            orderItem => orderItem.elementId === calculationId
          );
          const otherIndex = tableConfig.config.calculationOrder.findIndex(orderItem => orderItem.elementId === mainid);

          if (!!element.id && currentIndex <= otherIndex) {
            return null;
          }

          const _element = {
            rowId: calculationMode === 'rows' ? null : main.id,
            rowSubId: calculationMode === 'rows' ? null : main.id,
            rowSubSubId: calculationMode === 'rows' ? null : null,
            headId: calculationMode === 'rows' ? main.id : null,
            headSubId: calculationMode === 'rows' ? main.id : null
          };
          const isActive = isEqual(element?.value?.fixed, _element);

          return (
            <FormulaBuilderReferenceElementFixedReferenceDropdownItem
              key={`formula-builder-fixed-element-item-${main.id}-${main.id}`}
              element={_element}
              value={{ label: main.config.title }}
              isActive={isActive}
              handleFixedElementSelect={handleFixedElementSelect}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const FormulaBuilderReferenceElementFixedReferenceSelectDropdown = ({
  calculationMode,
  sourceElement,
  element,
  values,
  scales = null,
  main,
  handleFixedElementSelect,
  dropdownContainerRef
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const label = useMemo(() => {
    switch (sourceElement.type) {
      case 'question':
        return sourceElement.title;
      case 'calcvar':
        return sourceElement.label;
      case 'sysvar':
        return sourceElement.label;
      default:
        return '';
    }
  }, [sourceElement]);

  const isActiveParent = useMemo(() => {
    if (!element?.value?.fixed) return false;
    if (calculationMode === 'rows' && main.id === element.value.fixed.headId) return true;
    if (calculationMode === 'heads' && main.id === element.value.fixed.rowId) return true;
    return false;
  }, [calculationMode, main.id, element?.value?.fixed]);

  return (
    <Dropdown
      tag={'div'}
      direction={'right'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'dropdown-item--active': isActiveParent
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'w-100': true
        })}
      >
        <span>{label}</span>
      </DropdownToggle>
      <i className='fa-regular'></i>

      <DropdownMenu end className={'smv-custom-dropdown__dropdown-menu'} container={dropdownContainerRef}>
        {values.order?.map(valueid => {
          const value = values.list[valueid];
          const _element = {
            rowId: calculationMode === 'rows' ? null : main.id,
            rowSubId: calculationMode === 'rows' ? null : value.id,
            rowSubSubId: calculationMode === 'rows' ? null : null,
            headId: calculationMode === 'rows' ? main.id : null,
            headSubId: calculationMode === 'rows' ? value.id : null
          };

          if (!scales) {
            const isActive = isEqual(element?.value?.fixed, _element);
            return (
              <FormulaBuilderReferenceElementFixedReferenceDropdownItem
                key={`formula-builder-fixed-element-item-${main.id}-${value.id}`}
                element={_element}
                value={value}
                isActive={isActive}
                handleFixedElementSelect={handleFixedElementSelect}
              />
            );
          } else {
            return (
              <FormulaBuilderReferenceElementFixedReferenceSelectDropdownNested
                key={`formula-builder-fixed-element-select-${main.id}-${valueid}`}
                calculationMode={calculationMode}
                element={element}
                value={value}
                scales={scales}
                main={main}
                handleFixedElementSelect={handleFixedElementSelect}
              />
            );
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const FormulaBuilderReferenceElementFixedReferenceSelectDropdownNested = ({
  calculationMode,
  element,
  value,
  scales,
  main,
  handleFixedElementSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  const isActiveParent = useMemo(() => {
    if (!element?.value?.fixed) return false;
    if (
      calculationMode === 'heads' &&
      main.id === element.value.fixed.rowId &&
      value.id === element.value.fixed.rowSubId
    ) {
      return true;
    }
    return false;
  }, [calculationMode, main.id, value.id, element?.value?.fixed]);

  return (
    <Dropdown
      tag={'div'}
      direction={'right'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'dropdown-item--nested': true,
        'dropdown-item--active': isActiveParent,
        'smv-custom-dropdown__dropdown-menu': true
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true
        })}
      >
        <span>{label}</span>
      </DropdownToggle>
      <i className='fa-regular'></i>

      <DropdownMenu end className={'smv-custom-dropdown__dropdown-menu'}>
        {scales.order?.map(scaleId => {
          const scale = scales.list[scaleId];
          const _element = {
            rowId: main.id,
            rowSubId: value.id,
            rowSubSubId: scale.id,
            headId: null,
            headSubId: null
          };

          const isActive = isEqual(element?.value?.fixed, _element);

          return (
            <FormulaBuilderReferenceElementFixedReferenceDropdownItem
              key={`formula-builder-fixed-element-select-${main.id}-${value.id}-${scaleId}`}
              element={_element}
              value={scale}
              isActive={isActive}
              handleFixedElementSelect={handleFixedElementSelect}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
