import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqualWith, difference } from 'lodash';

import { getMergedChoices } from 'src/smoove/components/misc/helper';

/**
 * Get matrix type
 *
 * @param {object} table
 * @param {object} questionsList
 * @returns
 */
export const useMatrixType = table => {
  const questionsList = useSelector(state => state.survey.questionnaire.list);

  return useMemo(() => {
    // mixed single and matrix question types => detailed matrix
    // only matrix question types =>
    //   with same number of choices and same values => compact
    //   with different number of choices or different values => detail

    let matrixType = null;
    const defaultRows = (table?.rows?.order ?? []).filter(rowid =>
      ['question', 'sysvar', 'calcvar'].includes(table.rows.list[rowid].sourceType)
    );

    const elementTypes = defaultRows.map(rowid => {
      const row = table.rows.list[rowid];
      return questionsList?.[row?.sourceId]?.config?.qtype ?? null;
    });

    const defaultHeads = (table?.heads?.order ?? []).filter(headId =>
      ['question', 'sysvar', 'calcvar'].includes(table.heads.list[headId].sourceType)
    );

    const containsMatrix = elementTypes.includes('single_matrix') || elementTypes.includes('multiple_matrix');
    const containsOnlyMatrix = difference(elementTypes, ['single_matrix', 'multiple_matrix']).length === 0;

    if (containsMatrix && defaultHeads.length > 0) matrixType = 'detail';
    else if (containsMatrix && !containsOnlyMatrix) matrixType = 'detail';
    else if (containsMatrix && containsOnlyMatrix && defaultRows.length === 1) matrixType = 'compact';
    else if (containsMatrix && containsOnlyMatrix && defaultRows.length > 1) {
      matrixType = 'detail';

      // check for same choices/ values
      const choiceValues = defaultRows.map(rowid => {
        const row = table.rows.list[rowid];
        const element = questionsList[row.sourceId];
        const _choices = getMergedChoices(element, element.config?.choices, questionsList);

        return _choices.order.map(choiceid => _choices.list[choiceid].value);
      });

      let choiceValuesIdentical = null;
      choiceValues.forEach(values => {
        const isEqual = isEqualWith(choiceValues[0], values);
        if (isEqual && (choiceValuesIdentical || choiceValuesIdentical === null)) choiceValuesIdentical = true;
        else choiceValuesIdentical = false;
      });

      if (choiceValuesIdentical) matrixType = 'compact-merged';
    }

    const isCompactMatrix = matrixType === 'compact' || matrixType === 'compact-merged';
    const isDetailedMatrix = matrixType === 'detail';

    return { matrixType, isCompactMatrix, isDetailedMatrix };
  }, [table?.rows, table?.heads, questionsList]);
};
