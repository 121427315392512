import Axios from 'axios';

import 'smv-redux/axiosConfig';
import { settingsActions, store } from 'smv-redux';
import { sharedReportActionTypes } from '../constants';

// import { downloadFile } from './helper';

/**
 * Loads a shared report with the given reportid, which
 * must be configured and accessible inside the project.
 *
 * @param {string} projectid
 * @param {string} reportid
 */

const loadSharedReport = (reportid, token, password = null) => {
  store.dispatch({
    type: sharedReportActionTypes.LOAD_REPORT_REQUEST,
    payload: { reportid, token }
  });

  if (!reportid || !token) return;
  const data = password && password.length > 0 ? { password: password } : null;

  return new Promise((resolve, reject) => {
    Axios.post(`/shares/reports/${reportid}/${token}`, data)
      .then(res => {
        const resData = res.data;
        store.dispatch({
          type: sharedReportActionTypes.LOAD_REPORT_SUCCESS,
          payload: {
            accessLinkToken: token,
            surveyid: resData.survey.id,
            clientSettings: {},
            projectid: resData.project.id,
            survey: resData.survey,
            questionnaire: resData.questionnaire,
            locales: resData.survey.locales,
            translations: resData.survey.translations,
            report: resData.survey.reports[reportid],
            data: resData.survey.data,
            tables: resData.tables,
            client: resData.client,
            media: resData.survey.media,
            project: resData.project,
            chartLayers: resData.chartLayers
          }
        });
        resolve(res);
      })
      .catch(err => {
        console.log(err);
        store.dispatch({
          type: sharedReportActionTypes.LOAD_REPORT_FAILURE,
          payload: err
        });
        reject(err);
      });
  });
};

/**
 * Loads results for a given chart for the specified report/ project.
 * If a files array is passed in, the selected filters will be applied.
 *
 * @param {string} client
 * @param {string} projectid
 * @param {string} surveyid
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} elementid
 * @param {object} filter
 */
// const loadTableResults = (client, projectid, surveyid, reportid, config, filter) => {
const loadElementResults = (() => {
  let cancelTokenSources = {};

  return (client, projectid, surveyid, reportid, pageid, elementid, filter, splits) => {
    let previousSource = cancelTokenSources[elementid];
    if (previousSource) {
      previousSource.cancel({ type: sharedReportActionTypes.LOAD_REPORT_ELEMENT_RESULT_CANCEL });
    }

    const cancelTokenSource = Axios.CancelToken.source();
    cancelTokenSources[elementid] = cancelTokenSource;

    store.dispatch({
      type: sharedReportActionTypes.LOAD_REPORT_ELEMENT_RESULT_REQUEST,
      payload: { projectid, surveyid, reportid, pageid, elementid, filter, splits }
    });

    const data = new FormData();

    data.append(
      'payload',
      JSON.stringify({ client, projectid, surveyid, reportid, pageid, elementid, filter, splits })
    );

    Axios.post(`/jsonrpc.php`, data, {
      timeout: 300000, // 5 min
      cancelToken: cancelTokenSource.token,
      params: {
        program: 'Smoove',
        controller: 'XSharedReport',
        action: 'tableResult'
      }
    })
      .then(response => {
        const { status, error, table, result } = response.data.result;

        if (status) {
          store.dispatch({
            type: sharedReportActionTypes.LOAD_REPORT_ELEMENT_RESULT_SUCCESS,
            payload: {
              projectid,
              surveyid,
              reportid,
              pageid,
              elementid,
              table,
              result
            }
          });
        } else {
          throw error;
        }
      })
      .catch(error => {
        if (!error.__CANCEL__) {
          store.dispatch({
            type: sharedReportActionTypes.LOAD_REPORT_ELEMENT_RESULT_FAILURE,
            payload: {
              projectid,
              surveyid,
              reportid,
              pageid,
              elementid,
              error: error
            }
          });
        }
      });
  };
})();

/**
 * Exports a report/ page or single element and triggers a download.
 *
 * @param {string} surveyid
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} elementid
 * @param {string} exportType Requested export type, possible values are `powerpoint`
 * @param {array} filters
 */
const exportElement = (
  projectId,
  surveyId,
  reportId,
  pageId,
  elementId,
  exportType,
  filters,
  splits,
  token,
  isShortlabelsActive,
  exportLoopFilters = null
) => {
  const data = {
    exportType,
    filters,
    splits,
    isShortlabelsActive,
    exportLoopFilters
  };

  let endpoint = '';
  if (elementId) endpoint = `/shares/reports/${reportId}/pages/${pageId}/elements/${elementId}/export/${token}`;
  else if (pageId) endpoint = `/shares/reports/${reportId}/pages/${pageId}/export/${token}`;
  else if (reportId) endpoint = `/shares/reports/${reportId}/export/${token}`;

  return Axios.post(endpoint, data, {
    responseType: 'blob',
    timeout: 5 * 60 * 1000
  }).then(res => {
    settingsActions.toggleSharedReportExportsWindow();
    // let filename = `smoove_export.${exportType}`;
    // const contentDispHeader = res.headers?.['content-disposition'] ?? '';
    // const filenameMatch = contentDispHeader.match(/filename=([A-Za-z0-9_.]{5,})/);
    // if (filenameMatch?.[1] && filenameMatch[1]?.length > 6) filename = filenameMatch[1];
    // downloadFile(res.data, filename);
  });
};

export const sharedReportActions = {
  loadSharedReport,
  loadElementResults,
  exportElement
};
