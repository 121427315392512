export const projectSettingsActionTypes = {
  SET_QUESTIONNAIRE_ELEMENT_COLLAPSE: 'SET_QUESTIONNAIRE_ELEMENT_COLLAPSE',
  SET_Q_LOCALE: 'SET_Q_LOCALE',
  TOGGLE_IS_SHORTLABELS_ACTIVE: 'TOGGLE_IS_SHORTLABELS_ACTIVE',
  SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
  SET_SELECTED_REPORT: 'SET_SELECTED_REPORT',
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',

  SELECT_PROJECT_FAILURE: 'SELECT_PROJECT_FAILURE'
};
