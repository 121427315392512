import React from 'react';
import { useSelector } from 'react-redux';

import { useRenderCalcFrequencies } from './helper';
import { FormulaBuilderTableElement } from '.';

export const FormulaBuilderTableElementCalculationVariable = ({ calculationMode, main, handleAddReference }) => {
  const calculations = useSelector(state => state.survey?.data?.calculations);
  const calculationVariable = calculations.find(el => el.id === main.sourceId);
  const renderedCalcFrequencies = useRenderCalcFrequencies(handleAddReference, main);
  return (
    <div key={`${main.id}`}>
      <h6>{calculationVariable?.label}</h6>
      {calculationVariable?.values?.map((el, idx) => {
        const valueId = el.id;
        const value = calculationVariable.values.find(a => a.id === valueId);

        const element = {
          sourceId: main.sourceId,
          sourceType: main.sourceType,
          metric: 'smvrslt_perc',

          // cell identifiers
          rowId: calculationMode === 'rows' ? main.id : null,
          rowSubId: calculationMode === 'rows' ? valueId : null,
          rowSubSubId: null,
          headId: calculationMode === 'heads' ? main.id : null,
          headSubId: calculationMode === 'heads' ? valueId : null
        };

        return (
          <FormulaBuilderTableElement
            key={`${main.id}_${valueId}`}
            element={element}
            value={value}
            handleAddReference={handleAddReference}
          />
        );
      })}
      {renderedCalcFrequencies}
    </div>
  );
};
