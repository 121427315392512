import React from 'react';

import { FormulaBuilderTableElement } from '.';

export const FormulaBuilderTableElementCalculationValue = ({ calculationMode, main, handleAddReference }) => {
  const element = {
    sourceId: main.sourceId,
    sourceType: main.sourceType,
    metric: 'smvrslt_calculation',

    // cell identifiers
    rowId: calculationMode === 'rows' ? main.id : null,
    rowSubId: calculationMode === 'rows' ? main.id : null,
    rowSubSubId: null,
    headId: calculationMode === 'heads' ? main.id : null,
    headSubId: calculationMode === 'heads' ? main.id : null
  };

  return (
    <div key={`${main.id}_${main.id}`}>
      <h6>{main.config.title}</h6>
      <FormulaBuilderTableElement
        element={element}
        value={{ label: main.config.title }}
        handleAddReference={handleAddReference}
      />
    </div>
  );
};
