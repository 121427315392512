import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ListGroupItem, ListGroupItemHeading, Collapse } from 'reactstrap';
import { QuestionItem, MatrixQuestionItem, CalculationItem, SystemVariableItem } from '.';

import './QuestionList.scss';

const whitelistQuestions = [
  'single_vector',
  'multiple_vector',
  'single_input',
  'multiple_input',
  'single_input_text',
  'multiple_input_text',
  'single_input_number',
  'multiple_input_number'
];
const matrixQuestions = ['single_matrix', 'multiple_matrix'];

const recursiveQuestionList = (list, order) =>
  order.map(qid => {
    const element = list[qid];
    if (element.type === 'container' || element.type === 'page') {
      if (element?.children?.length > 0) {
        return recursiveQuestionList(list, element.children);
      }
    } else if (element.type === 'question' && matrixQuestions.includes(element.config.qtype)) {
      return <MatrixQuestionItem key={qid} question={element} />;
    } else if (
      element.type === 'question' &&
      (whitelistQuestions.includes(element.config.qtype) ||
        (element.config.qtype === 'custom_shelf' &&
          ['single', 'multiple'].includes(
            element.config?.shelfResultType ?? element.config?.shelf?.config?.shelfResultType
          )))
    ) {
      return <QuestionItem key={qid} question={element} />;
    }
    return null;
  });

export const QuestionList = () => {
  const list = useSelector(state => state.survey.questionnaire?.list ?? {});
  const root = useSelector(state => state.survey.questionnaire?.root ?? []);
  const calculations = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const [openArea, setOpenArea] = useState(null);
  const toggleOpen = useCallback(area => setOpenArea(state => (state === area ? null : area)), []);

  const questionItems = useMemo(() => recursiveQuestionList(list, root), [list, root]);

  const calculationItems = useMemo(() => {
    return calculations?.map(calculation => {
      return <CalculationItem key={calculation.id} calculation={calculation} />;
    });
  }, [calculations]);

  const systemItems = useMemo(() => {
    return systemVariables.order.map(systemVariableId => {
      const systemVariable = systemVariables.list[systemVariableId];
      return <SystemVariableItem key={systemVariable.id} systemVariable={systemVariable} />;
    });
  }, [systemVariables]);

  return (
    <>
      <ListGroupItem style={{ padding: '0.75rem 0.5rem' }} className='question-list'>
        <ListGroupItemHeading
          onClick={() => toggleOpen('question')}
          className='pointer d-flex align-items-center py-1 list-item-sticky'
        >
          <i className={`far fa-chevron-${openArea === 'question' ? 'down' : 'right'}`}></i>
          <span className='ml-1'>
            <FormattedMessage id={'smoove.page.tables.questions'} defaultMessage={'Questions'} />
          </span>
        </ListGroupItemHeading>
        <Collapse isOpen={openArea === 'question'}>{questionItems}</Collapse>
      </ListGroupItem>

      {systemVariables && (
        <ListGroupItem style={{ padding: '0.75rem 0.5rem' }} className='question-list'>
          <ListGroupItemHeading
            onClick={() => toggleOpen('systemVariables')}
            className='pointer d-flex align-items-center py-1 list-item-sticky'
          >
            <i className={`far fa-chevron-${openArea === 'systemVariables' ? 'down' : 'right'}`}></i>
            <span className='ml-1'>
              <FormattedMessage id={'smoove.page.tables.internal-variables'} defaultMessage={'Internal Variables'} />
            </span>
          </ListGroupItemHeading>
          <Collapse isOpen={openArea === 'systemVariables'}>
            {systemVariables.length === 0 && (
              <div className='ml-3'>
                <FormattedMessage
                  id={'smoove.page.tables.no-internal-variables'}
                  defaultMessage={'No internal variables available'}
                />
              </div>
            )}
            {systemItems}
          </Collapse>
        </ListGroupItem>
      )}

      {calculations && (
        <ListGroupItem style={{ padding: '0.75rem 0.5rem' }} className='question-list'>
          <ListGroupItemHeading
            onClick={() => toggleOpen('calculate')}
            className='pointer d-flex align-items-center py-1 list-item-sticky'
          >
            <i className={`far fa-chevron-${openArea === 'calculate' ? 'down' : 'right'}`}></i>
            <span className='ml-1'>
              <FormattedMessage
                id={'smoove.page.tables.calculated-variables'}
                defaultMessage={'Calculated Variables'}
              />
            </span>
          </ListGroupItemHeading>
          <Collapse isOpen={openArea === 'calculate'}>
            {calculations.length === 0 && (
              <div className='ml-3'>
                <FormattedMessage
                  id={'smoove.page.tables.no-calculated-variables'}
                  defaultMessage={'No calculated variables available'}
                />
              </div>
            )}
            {calculationItems}
          </Collapse>
        </ListGroupItem>
      )}
    </>
  );
};
