import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Text } from 'recharts';
import { truncate } from 'lodash';

export const CustomizedAxisTick = ({
  elementid,
  axisType = 'x',
  x,
  y,
  payload,
  textAnchor,
  verticalAnchor,
  width,
  visibleTicksCount
}) => {
  const targetId = `${axisType}axis_tick_${elementid}_${payload.index}`;

  let availableWidth;
  if (axisType === 'x') availableWidth = Math.floor(width / visibleTicksCount - 16);
  else if (axisType === 'y') availableWidth = width;

  return (
    <>
      <Text
        id={targetId}
        x={x}
        y={y}
        width={axisType === 'y' ? width : availableWidth}
        textAnchor={textAnchor}
        verticalAnchor={axisType === 'y' ? verticalAnchor : 'start'}
      >
        {truncate(payload.value, { length: availableWidth / 4, separator: ' ' })}
      </Text>
      <UncontrolledTooltip
        target={targetId}
        trigger={'hover click'}
        placement={axisType === 'y' ? 'right' : 'top'}
        delay={500}
      >
        {payload.value}
      </UncontrolledTooltip>
    </>
  );
};
