import React from 'react';

import { ReferenceValues } from '.';

export const ReferenceTypeCalculation = ({ conditionalFormat, main, dimensionKey, handleReferenceSelect }) => {
  return (
    <ReferenceValues
      conditionalFormat={conditionalFormat}
      label={main.config.title}
      main={main}
      dimensionKey={dimensionKey}
      values={{ order: [main.id], list: { [main.id]: { id: main.id, label: main.config.title } } }}
      handleReferenceSelect={handleReferenceSelect}
    />
  );
};
