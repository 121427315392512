import produce from 'immer';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';

export const makeFlat = nested => {
  const id = customAlphabet('1234567890abcdef', 24)();
  const flat = {
    rootElement: id,
    elements: {}
  };

  const unfold = element => {
    return element.rules.map(element => {
      element.id = customAlphabet('1234567890abcdef', 24)();

      if (element.hasOwnProperty('rules')) {
        element.rules = unfold(element);
      }

      flat.elements[element.id] = element;
      return element.id;
    });
  };

  const rootElement = {
    id,
    ...nested,
    rules: unfold(nested)
  };

  flat.elements[rootElement.id] = rootElement;
  return flat;
};

/**
 * Check a querybuilder for errors. Returns an array of objects containing the element id and a message.
 *
 * @param {Object} queryBuilder a querybuilder structure
 * @param {Array} sources an array of all sources used for the queryBuilder
 *
 * @returns {Array} Returns an array of objects containing the element id and a message.
 */
export const getQuerybuilderErrors = (queryBuilder, sources) => {
  const warnings = {
    emptyGroup: 'smoove.common.querybuilder.warnings.please-add-a-rule-to-the-marked-empty-group',
    selectSource: 'smoove.common.querybuilder.warnings.please-select-a-source',
    selectOperator: 'smoove.common.querybuilder.warnings.please-select-an-operator',
    selectValue: 'smoove.common.querybuilder.warnings.please-select-one-or-multiple-values',
    selectChoice: 'smoove.common.querybuilder.warnings.please-select-a-choice'
  };

  const errors = [];

  const processGroup = el => {
    if ((!el.children || el.children.length <= 0) && el.id !== queryBuilder.rootElementId) {
      errors.push({ id: el.id, message: warnings.emptyGroup });
    }
  };

  const processRule = el => {
    if (!el.sourceId) {
      errors.push({ id: el.id, message: warnings.selectSource });
    } else {
      const sourceType = sources?.find(source => source.id === el.sourceId)?.type;
      if (['single_matrix', 'multiple_matrix'].includes(sourceType) && !el.choiceId) {
        errors.push({ id: el.id, message: warnings.selectChoice });
      }
      if (!el.operator) {
        errors.push({ id: el.id, message: warnings.selectOperator });
      }
      if (!el.values || el.values.length <= 0) {
        errors.push({ id: el.id, message: warnings.selectValue });
      }
    }
  };

  Object.values(queryBuilder.elements)?.forEach(el => {
    if (el.type === 'group') {
      processGroup(el);
    } else if (el.type === 'rule') {
      processRule(el);
    }
  });

  return errors;
};
/**
 * Create an empty QueryBuilder state.
 *
 * @returns {Object} Returns a new empty state for a QueryBuilder component
 */
export const getQueryBuilderEmptyState = () => {
  const rootElementId = customAlphabet('1234567890abcdef', 24)();
  const emptyRuleId = customAlphabet('1234567890abcdef', 24)();
  return {
    rootElementId: rootElementId,
    elements: {
      [rootElementId]: { id: rootElementId, type: 'group', condition: 'or', not: false, children: [emptyRuleId] },
      [emptyRuleId]: { id: emptyRuleId, type: 'rule' }
    }
  };
};

export const isQueryBuilderEmptyState = queryBuilder => {
  const elements = queryBuilder?.elements;

  // If there are 2 elements, one is the root group and one is an empty rule (a rule with no sourceId), the querybuilder is in the empty state
  if (elements && Object.values(elements).length === 2) {
    const rootElement = elements[queryBuilder.rootElementId];

    if (rootElement?.children?.length === 1) {
      const childId = rootElement.children[0];
      const childRule = elements[childId];

      if (childRule?.type === 'rule' && !childRule?.sourceId) {
        return true;
      }
    }
  }

  return false;
};

export const isQueryBuilderAnEmptyGroup = queryBuilder => {
  if (Object.values(queryBuilder?.elements)?.length <= 1) return true;
  return false;
};

/**
 * Function that handles autosaving a querybuilder structure whenever the structure is valid, different to the original and there is no saving in progress
 *
 * @param {Object} queryBuilder a querybuilder structure (local state)
 * @param {Object} originialQuerybuilder the original querybuilder state (probably the currently saved backend state)
 * @param {Array} sources an array of all sources used for the queryBuilder
 * @param {Boolean} isSaving true if a save/delete request is not finished
 * @param {Function} deleteHandler
 * @param {Function} setWarning
 * @param {Function} saveHandler
 *
 */
export const handleQuerybuilderAutosave = (
  queryBuilder,
  originialQuerybuilder,
  sources,
  isSaving,
  deleteHandler,
  setWarning,
  saveHandler
) => {
  if (!queryBuilder) return;

  const queryBuilderErrors = getQuerybuilderErrors(queryBuilder, sources);

  // if a rule is defined but a value is removed so there are no values or a question is switched to matrix without a choiceId, do not allow saving
  const canSaveChangesOfExistingRules = Object.values(queryBuilder.elements).some(el => {
    if (queryBuilderErrors.length <= 0) return true;

    const sourceType = sources?.find(source => source.id === el.sourceId)?.type;
    const isMatrix = ['single_matrix', 'multiple_matrix'].includes(sourceType);

    if (
      (!isMatrix && el.type === 'rule' && el.sourceId && el.operator && el.values?.length <= 0) ||
      (isMatrix && (!el.choiceId || el.values?.length <= 0))
    )
      return false;

    return true;
  });

  const _strippedQuerybuilder = getQueryBuilderWithoutElementsWithErrors(queryBuilder, sources);

  const canDeleteQuerybuilderBackendState =
    isQueryBuilderAnEmptyGroup(queryBuilder) ||
    (isQueryBuilderEmptyState(queryBuilder) && !isQueryBuilderEmptyState(originialQuerybuilder));

  const canSaveQueryBuilder =
    !_.isEqual(_strippedQuerybuilder, originialQuerybuilder) &&
    !isQueryBuilderEmptyState(_strippedQuerybuilder) &&
    !isQueryBuilderAnEmptyGroup(_strippedQuerybuilder) &&
    canSaveChangesOfExistingRules;

  if (originialQuerybuilder?.rootElementId && canDeleteQuerybuilderBackendState && !isSaving) {
    deleteHandler();
  } else if (!isSaving && canSaveQueryBuilder && !canDeleteQuerybuilderBackendState) {
    saveHandler();
  } else {
    setWarning(null);
  }
};

export const getQueryBuilderWithoutElementsWithErrors = (queryBuilder, sources) => {
  const queryBuilderErrors = getQuerybuilderErrors(queryBuilder, sources);
  let _strippedQuerybuilder = _.cloneDeep(queryBuilder);

  if (queryBuilderErrors.length > 0) {
    const _errorIds = _.uniq(queryBuilderErrors.map(el => el.id));
    _errorIds.forEach(errorId => {
      if (_strippedQuerybuilder.elements[errorId]) {
        _strippedQuerybuilder = produce(_strippedQuerybuilder, draftState => {
          delete draftState.elements[errorId];
        });
      }
      Object.values(_strippedQuerybuilder?.elements).forEach(el => {
        if (el.children?.includes(errorId)) {
          _strippedQuerybuilder = produce(_strippedQuerybuilder, draftState => {
            draftState.elements[el.id].children = draftState.elements[el.id].children?.filter(id => id !== errorId);
          });
        }
      });
    });
  }
  return _strippedQuerybuilder;
};
