import React, { useMemo } from 'react';
import produce from 'immer';

import { useMatrixType } from 'smv-helpers';

import {
  FormulaBuilderTableElement,
  FormulaBuilderTableElementCalculationValue,
  FormulaBuilderTableElementCalculationVariable,
  FormulaBuilderTableElementQuestionSwitch,
  FormulaBuilderTableElementSystemVariable
} from '.';

export const FormulaBuilderTableList = ({ calculationMode, tableConfig, calculation, handleAddReference }) => {
  const { isCompactMatrix } = useMatrixType(tableConfig);

  const sourceElements = useMemo(
    () =>
      produce(tableConfig[calculationMode], draft => {
        if (isCompactMatrix && calculationMode === 'heads') {
          tableConfig.rows.order.forEach(rowid => {
            if (tableConfig.rows.list[rowid].sourceType === 'question') {
              draft.order = [rowid, ...draft.order];
              draft.list[rowid] = tableConfig.rows.list[rowid];
            }
          });
        }
      }),
    [calculationMode, tableConfig, isCompactMatrix]
  );

  return (
    <div className={`formula-builder__table-list`}>
      {calculationMode === 'heads' && !isCompactMatrix && (
        <div key={`${'__total__'}`}>
          <h6>Total</h6>
          <FormulaBuilderTableElement
            key={`${'__total__'}_${'__total__'}`}
            element={{
              sourceId: '__total__',
              sourceType: 'question',
              metric: 'smvrslt_perc',

              // cell identifiers
              rowId: null,
              rowSubId: null,
              rowSubSubId: null,
              headId: '__total__',
              headSubId: '__total__'
            }}
            value={{ label: 'Total' }}
            handleAddReference={handleAddReference}
          />
        </div>
      )}

      {sourceElements?.order?.map(mainid => {
        const main = sourceElements.list[mainid];

        switch (main.sourceType) {
          case 'question':
            return (
              <FormulaBuilderTableElementQuestionSwitch
                key={`${main.id}`}
                main={main}
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                handleAddReference={handleAddReference}
              />
            );
          case 'sysvar':
            return (
              <FormulaBuilderTableElementSystemVariable
                key={`${main.id}`}
                calculationMode={calculationMode}
                main={main}
                tableConfig={tableConfig}
                handleAddReference={handleAddReference}
              />
            );
          case 'calcvar':
            return (
              <FormulaBuilderTableElementCalculationVariable
                key={`${main.id}`}
                calculationMode={calculationMode}
                main={main}
                tableConfig={tableConfig}
                handleAddReference={handleAddReference}
              />
            );
          case 'calculation':
            const currentIndex = tableConfig.config.calculationOrder.findIndex(
              orderItem => orderItem.elementId === calculation?.id
            );
            const otherIndex = tableConfig.config.calculationOrder.findIndex(
              orderItem => orderItem.elementId === mainid
            );

            if (!!calculation.id && currentIndex <= otherIndex) {
              return null;
            }

            return (
              <FormulaBuilderTableElementCalculationValue
                key={`${main.id}`}
                calculationMode={calculationMode}
                main={main}
                tableConfig={tableConfig}
                handleAddReference={handleAddReference}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
