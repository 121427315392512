import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Link, useHistory, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import {
  Col,
  Row,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

import { projectsActions, projectActions } from 'smv-redux/actions';
import { Stone, SurveyList } from 'smv-components';
import { useUserHasPermission, permissionEnum, useIsUserSmooveAdmin } from 'smv-helpers';

import { ReadOnlyAccessIconAndTooltip } from 'src/smoove/components/misc/ReadOnlyAccessIconAndTooltip';
import { SearchInput } from 'src/smoove/components/misc/SearchInput';

import './Projects.scss';

export const Projects = ({ history, match }) => {
  const intl = useIntl();
  // const { params } = match;
  const projects = useSelector(state => state.projects.list ?? []);
  const clientId = useSelector(state => state.clients.selected);
  const selectedProjectId = useSelector(state => state.project.id ?? null);

  const isProjectsLoading = useSelector(state => state.projects?.loading ?? false);
  const isLoadingCompleted = useSelector(state => state.projects.isLoadingCompleted ?? false);

  const userCanCreateNewProject = useUserHasPermission(permissionEnum.PROJECT_CREATE);
  const isUserSmooveAdmin = useIsUserSmooveAdmin();

  const dropdownContainerRef = useRef(null);

  // handles initial loading of the first project or the last project the user was working on (redux persisted)
  useEffect(() => {
    if (clientId && !isProjectsLoading && !isLoadingCompleted) {
      if (!selectedProjectId || selectedProjectId === 'initial' || projects.length <= 0) {
        projectsActions
          .getAllProjects(clientId)
          .then(res => {
            const _projects = res;
            const selectedProjectIdIsInRequestedProjects = _projects
              .map(project => project.id)
              .includes(selectedProjectId);
            if (_projects?.length > 0 && !selectedProjectIdIsInRequestedProjects && selectedProjectId !== 'initial') {
              projectActions.switchProject(_projects[0]);
              history.push(`/one/projects/${_projects[0]?.id}`);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }, [clientId, history, selectedProjectId, projects.length, isLoadingCompleted, isProjectsLoading]);

  // handles loading of survey list
  useEffect(() => {
    if (selectedProjectId && selectedProjectId !== 'initial') {
      projectActions.getAllSurveys(selectedProjectId);
    }
  }, [selectedProjectId]);

  const [filteredProjects, setFilteredProjects] = useState(projects);
  useEffect(() => {
    setFilteredProjects([...projects]);
  }, [projects]);

  return (
    <>
      <Stone type={'B'} color={'red'} width={'500px'} position={[null, 0, 0]} translate={[110, 250]} />
      <Helmet>
        <title>{intl.formatMessage({ id: `core.menu.item.projects` })}</title>
      </Helmet>
      <div className='base-content d-flex flex-fill container-fluid projects' ref={dropdownContainerRef}>
        <div className={'container-fluid p-0'}>
          <Row>
            <Col md={4}>
              <h2 className='text-uppercase text-smv fw-bold'>
                <FormattedMessage id={`smoove.page.projects.headline`} />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} className='projects__column-left'>
              <div className='projects__head py-2'>
                {projects.length > 0 && (
                  <SearchInput
                    allItems={projects}
                    filterValues={['title']}
                    setFilteredItems={setFilteredProjects}
                    placeholderText={intl.formatMessage({ id: `smoove.page.projects.search-project` })}
                  />
                )}
              </div>
              <div className='project-list'>
                {filteredProjects.map(project => (
                  <ProjectListElement project={project} key={project.id} dropdownContainerRef={dropdownContainerRef} />
                ))}
                {filteredProjects.length <= 0 && <div>no projects found.</div>}
              </div>
              <div className='mr-4 mt-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isUserSmooveAdmin && <ImportProjectButtonAndModal />}
                {/* todo: move creating new project into modal instad of new page, add tooltip to btn? */}
                {userCanCreateNewProject && (
                  <Link
                    to={'/one/newproject'}
                    className={'btn btn-sm btn-smv-green d-flex align-items-center justify-content-center'}
                  >
                    <FormattedMessage id={`smoove.page.projects.button.create`} />
                    {/* <i className='fal fa-plus' /> */}
                  </Link>
                )}
              </div>
            </Col>
            <Col md={8}>
              <Route path={match.path} component={SurveyList} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const ProjectListElement = ({ project, dropdownContainerRef }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const toggleDeleteModal = useCallback(() => setIsOpenDeleteModal(prev => !prev), []);

  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
  const toggleDuplicateModal = useCallback(() => setIsOpenDuplicateModal(prev => !prev), []);

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);

  const userCanAdministrateProject = useUserHasPermission(permissionEnum.PROJECT_ADMIN, project.id);

  const history = useHistory();
  // const { projectid } = useParams();

  const selectedProjectId = useSelector(state => state.project.id);

  const handleSelectProject = () => {
    if (project.id !== selectedProjectId) {
      projectActions.switchProject(project);
      history.push(`/one/projects/${project.id}`);
    }
  };

  const openProjectSettings = useCallback(
    project => {
      const projectid = project.id;
      projectActions.switchProject(project);

      history.push(`/one/projects/${projectid}/projectsettings`);
    },
    [history]
  );

  useEffect(() => {
    return () => {
      toggleDeleteModal();
    };
  }, [toggleDeleteModal]);

  const isSelectedProject = useMemo(() => {
    return project.id === selectedProjectId;
  }, [project.id, selectedProjectId]);

  return (
    <div
      id={`widget_${project.id}`}
      className={`project-list-element p-2 mb-2 ${isSelectedProject ? 'project-list-element--selected' : ''}`}
      onClick={() => handleSelectProject()}
    >
      <div className='d-flex flex-row justify-content-between w-100'>
        <div className='project-list-element__title'>{project.title}</div>

        {userCanAdministrateProject && (
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            onClick={e => {
              e.stopPropagation();
            }}
            direction='left'
            className='project-list-element__settings-button'
          >
            <DropdownToggle className='btn btn-sm btn-light'>
              <i className='fal fa-ellipsis-h pointer'></i>
            </DropdownToggle>
            <DropdownMenu container={dropdownContainerRef}>
              <DropdownItem
                onClick={() => {
                  setDropdownOpen(false);
                  openProjectSettings(project);
                }}
              >
                <div className='d-flex align-items-baseline'>
                  <i className='fal fa-cog pl-1 pr-2 pointer' />
                  <div>
                    <FormattedMessage
                      id={`smoove.page.projects.project-settings`}
                      defaultMessage={`Project settings`}
                    />
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDropdownOpen(false);
                  setIsOpenDuplicateModal(true);
                }}
              >
                <div className='d-flex align-items-baseline'>
                  <i className='fal fa-copy pl-1 pr-2 pointer' />
                  <div>
                    <FormattedMessage id='smoove.page.projects.button.duplicate' defaultMessage='Duplicate Project' />
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDropdownOpen(false);
                  setIsOpenDeleteModal(true);
                }}
              >
                <div className='d-flex align-items-baseline'>
                  <i className='fal fa-trash-alt pl-1 pr-2 pointer' />
                  <div>
                    <FormattedMessage id='smoove.page.projects.button.delete' defaultMessage='Delete Project' />
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
        {!userCanEditProject && <ReadOnlyAccessIconAndTooltip />}
      </div>
      {/* <p>
        Created on: <FormattedDate day='2-digit' year='numeric' month='long' value={project.createdAt} />
      </p> */}
      {isOpenDuplicateModal && userCanEditProject && (
        <DuplicateProjectModal isOpen={isOpenDuplicateModal} toggle={toggleDuplicateModal} project={project} />
      )}

      {isOpenDeleteModal && <DeleteModal isOpen={isOpenDeleteModal} toggle={toggleDeleteModal} project={project} />}
    </div>
  );
};

export const DuplicateProjectModal = ({ isOpen, toggle, project }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const _duplicateHandler = useCallback(() => {
    console.log('duplicate');
    setIsLoading(true);
    projectActions
      .duplicate(project.id)
      .then(res => {
        toggle();
        setIsLoading(false);
      })
      .catch(err => {
        toggle();
        setIsLoading(false);
      });
  }, [project.id, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id='smoove.page.projects.modal.duplicate.head' defaultMessage='Duplicate project' />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id='smoove.page.projects.modal.duplicate.body'
          defaultMessage='Are you sure you want to duplicate the project <b>"{title}"</b>?'
          values={{ b: text => <b>{text}</b>, title: project.title }}
        />
        {isLoading && (
          <div>
            <span className='mr-2 mt-2 text-warning'>
              {intl.formatMessage({ id: 'smoove.page.projects.modal.duplicate.duplicating-please-wait' })}
            </span>
            <i className='fal fa-spinner fa-spin' />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id='smoove.common.buttons.cancel' defaultMessage='Cancel' />
        </Button>
        <Button color={'primary'} onClick={_duplicateHandler} disabled={isLoading}>
          <FormattedMessage id='smoove.common.buttons.duplicate' defaultMessage='Duplicate' />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const DeleteModal = ({ isOpen, toggle, project }) => {
  const clientid = useSelector(state => state.clients.selected ?? null);
  const isFetching = useSelector(state => state.projects.fetching ?? false);
  const projects = useSelector(state => state.projects ?? false);
  const history = useHistory();
  const params = useParams();
  const [message, setMessage] = useState('');

  const deleteCb = useCallback(
    (isDeleted, res = '') => {
      const remainingProjects = projects.list.filter(el => el.id !== project.id);
      if (isDeleted) {
        if (remainingProjects.length > 0 && (project.id === params.projectid || !params.projectid)) {
          projectActions.switchProject(remainingProjects[0]);
          history.push(`/one/projects/${remainingProjects[0].id}`);
        } else {
          history.push(`/one/projects/`);
        }
        toggle();
      } else {
        setMessage('Error: ' + res.message);
      }
    },
    [toggle, projects.list, history, params.projectid, project.id]
  );

  return (
    <div>
      <Modal isOpen={isOpen} centered={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id='smoove.page.projects.modal.head.delete' defaultMessage='Confirm Delete' />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            id='smoove.page.projects.modal.body.delete'
            defaultMessage={`Do you want to delete the project ${project.title}?`}
            values={{ title: project.title, b: chunks => <b>{chunks}</b> }}
          />
          <div>
            {isFetching && <i className='fas fa-spinner fa-spin' />}
            {message && (
              <div>
                <br />
                {message}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color={'secondary'} outline={true} onClick={toggle}>
            <FormattedMessage id='smoove.common.buttons.cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            color={'danger'}
            disabled={isFetching}
            onClick={() => {
              projectsActions.deleteProject(clientid, project.id, deleteCb);
            }}
          >
            <FormattedMessage id='smoove.page.projects.button.delete' defaultMessage='Delete' />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const ImportProjectButtonAndModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(prev => !prev), []);

  const clientId = useSelector(state => state.clients.selected);

  const [isLoading, setIsLoading] = useState(null);

  const [filesToUpload, setFilesToUpload] = useState([]);

  const inputFile = useRef(null);

  const handleClickUploadFileButton = () => {
    inputFile.current.click();
  };

  const handleFileUploadDialog = e => {
    let files = Object.values(e?.target?.files).map(el => {
      return el;
    });

    const _files = [...filesToUpload, ...files].filter(
      (el, index, self) => index === self.findIndex(t => t.name === el.name)
    );

    setFilesToUpload(_files ?? []);
    e.target.value = null;
  };

  const handleUploadFile = useCallback(() => {
    const formData = new FormData();
    formData.append('file', filesToUpload[0]);
    formData.append('name', filesToUpload[0].name);

    setIsLoading(true);

    projectActions
      .importProject(clientId, formData)
      .then(res => {
        projectsActions.getAllProjects(clientId);
        setIsLoading(false);
        setFilesToUpload([]);
        setIsOpen(false);
      })
      .catch(err => {
        setFilesToUpload([]);
        setIsLoading(false);
      });
  }, [filesToUpload, clientId]);

  return (
    <>
      <Button className='mr-2' color={'secondary'} size={'sm'} onClick={toggle}>
        Import project
      </Button>
      <Modal isOpen={isOpen} centered={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>Import project</ModalHeader>
        <ModalBody>
          <Button onClick={handleClickUploadFileButton} disabled={isLoading}>
            Select project file (.zip)
          </Button>
          <input
            type='file'
            // for the moment only .zip files are handled in BE. No handling for: .rar,.7zip
            accept='.zip'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleFileUploadDialog}
          />
          {filesToUpload.length > 0 && (
            <div className='my-4'>
              <div className='my-2 fw-bold'>Project to import:</div>
              <span>{filesToUpload[0]?.name}</span>
              {!isLoading && (
                <span className='ml-2'>
                  <i className='fal fa-trash-alt pointer text-danger' onClick={() => setFilesToUpload([])} />
                </span>
              )}
            </div>
          )}
          {isLoading && (
            <div>
              <div className='my-2'>
                Importing, this might take a while. You will be notified when the import is finished. You can savely
                close this window.
              </div>
              <div>
                <i className='fal fa-spinner fa-spin fa-2x' />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color={'secondary'} outline={true} onClick={toggle} disabled={isLoading}>
            <FormattedMessage id='smoove.common.buttons.cancel' defaultMessage='Cancel' />
          </Button>
          <Button color={'primary'} disabled={filesToUpload.length <= 0 || isLoading} onClick={handleUploadFile}>
            Import project
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
