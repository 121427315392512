import Axios from 'axios';
import produce from 'immer';

import 'smv-redux/axiosConfig';
import { settingsActions, store } from 'smv-redux';
import { reportsActionTypes } from '../constants';
// import { downloadFile } from './helper';

/**
 * Loads all reports for a specific survey
 *
 * @param {string} projectid
 * @param {string} surveyid
 */
const loadReports = surveyid => {
  store.dispatch({
    type: reportsActionTypes.LOAD_REPORTS_REQUEST,
    payload: { surveyid }
  });

  return Axios.get(`/surveys/${surveyid}/reports`)
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTS_SUCCESS,
        payload: {
          surveyid,
          reports: res.data
        }
      });
      return Promise.resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTS_FAILURE,
        payload: {
          surveyid,
          error: err
        }
      });
      return Promise.reject(err);
    });
};

/**
 * Adds a new report
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {object} report
 */
const addReport = async (surveyId, report) => {
  store.dispatch({
    type: reportsActionTypes.ADD_REPORT_REQUEST,
    payload: { surveyId, report: report }
  });

  return Axios.post(`/surveys/${surveyId}/reports`, report)
    .then(res => {
      const report = res.data;
      store.dispatch({
        type: reportsActionTypes.ADD_REPORT_SUCCESS,
        payload: { surveyId, report: report }
      });
      return report;
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.ADD_REPORT_FAILURE,
        payload: { surveyId, error: err }
      });
      return false;
    });
};

/**
 * Deletes a report
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {object} report
 */
const deleteReport = async (surveyId, reportId) => {
  store.dispatch({
    type: reportsActionTypes.DELETE_REPORT_REQUEST,
    payload: { surveyId, reportId }
  });

  return Axios.delete(`/surveys/${surveyId}/reports/${reportId}`)
    .then(() => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_SUCCESS,
        payload: { surveyId, reportId }
      });

      return true;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_FAILURE,
        payload: { surveyId, reportId, error }
      });

      return false;
    });
};

/**
 * Duplicates a report
 *
 * @param {string} projectid
 * @param {string} surveyid
 */
const duplicateReport = async (surveyId, reportId) => {
  store.dispatch({
    type: reportsActionTypes.DUPLICATE_REPORT_REQUEST,
    payload: { surveyId, reportId }
  });

  return Axios.request({ method: 'copy', url: `/surveys/${surveyId}/reports/${reportId}` })
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.DUPLICATE_REPORT_SUCCESS,
        payload: { surveyId, reportId }
      });

      return Promise.resolve(res.data);
    })
    .catch(error => {
      store.dispatch({
        type: reportsActionTypes.DUPLICATE_REPORT_FAILURE,
        payload: { surveyId, reportId, error }
      });

      return Promise.reject(error);
    });
};

/**
 * @param {string} surveyid
 * @param {string} reportid
 * @param {object} data - report data to update
 */
const updateReport = async (surveyId, reportId, data) => {
  store.dispatch({
    type: reportsActionTypes.UPDATE_REPORT_REQUEST,
    payload: { surveyId, reportId }
  });
  // strip accesLinks from data
  const _data = produce(data, draft => {
    delete draft.accessLinks;
  });

  return Axios.patch(`/surveys/${surveyId}/reports/${reportId}`, _data, { timeout: 5 * 60 * 1000 })
    .then(res => {
      const report = res.data;
      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORT_SUCCESS,
        payload: { surveyId, reportId, report }
      });
      Promise.resolve(res);
      return report;
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORT_FAILURE,
        payload: { surveyId, reportId, error: err }
      });
      Promise.reject(err);
      return err;
    });
};

/**
 * Duplicates a report page
 *
 * @param {string} surveyId
 * @param {string} reportId
 * @param {string} pageId
 */
const duplicateReportPage = (surveyId, reportId, pageid) => {
  store.dispatch({
    type: reportsActionTypes.UPDATE_REPORT_REQUEST,
    payload: { surveyId, reportId }
  });

  return Axios.request({ method: 'copy', url: `/surveys/${surveyId}/reports/${reportId}/pages/${pageid}` })
    .then(res => {
      const report = res.data;
      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORT_SUCCESS,
        payload: { surveyId, reportId, report }
      });
      return report;
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORT_FAILURE,
        payload: { surveyId, reportId, error: err }
      });
      return err;
    });
};

/**
 * Deletes a dashboard element from a page
 *
 * @param {string} surveyId
 * @param {string} reportId
 * @param {string} pageId
 * @param {string} elementId
 */
const deleteDashboardElement = async (surveyId, reportId, pageId, elementId) => {
  store.dispatch({
    type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_REQUEST,
    payload: { reportId: reportId, pageId: pageId, elementId: elementId }
  });

  return Axios.delete(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/elements/${elementId}`)
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_SUCCESS,
        payload: {
          reportId: reportId,
          pageId: pageId,
          elementId: elementId,
          layouts: res.data.pages.list[pageId].layouts
        }
      });
      Promise.resolve(res);
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_FAILURE,
        payload: { reportId: reportId, pageId: pageId, elementId: elementId, error: err }
      });
      Promise.reject(err);
    });
};
/**
 * Updates a dashboard element from a page
 *
 * @param {string} surveyId
 * @param {string} reportId
 * @param {string} pageId
 * @param {string} elementId
 */
const updateDashboardElement = async (surveyId, reportId, pageId, elementId, newElementData) => {
  store.dispatch({
    type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_REQUEST,
    payload: { reportId, pageId, newElementData }
  });

  // should patch the new element and save the data to redux

  return Axios.patch(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/elements/${elementId}`, newElementData)
    .then(res => {
      const element = res.data;
      store.dispatch({
        type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_SUCCESS,
        payload: { reportId, pageId, element }
      });
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_FAILURE,
        payload: { reportId, pageId, elementId, error: err }
      });
    });
};

/**
 * Saves element config
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {object} element
 * @param {object|null} layouts
 */
const saveElementConfig = (reportid, pageid, element, layout, breakpoint) => {
  store.dispatch({
    type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_REQUEST,
    payload: { reportid, pageid, element }
  });

  const data = new FormData();

  data.append(
    'payload',
    JSON.stringify({
      reportid,
      pageid,
      element,
      layout,
      breakpoint
    })
  );

  Axios.post(`/jsonrpc.php`, data, {
    params: {
      program: 'Smoove',
      controller: 'XReport',
      action: 'saveElementConfig'
    }
  })
    .then(res => {
      const { element, layouts } = res.data.result;
      store.dispatch({
        type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_SUCCESS,
        payload: { reportid, pageid, element, layouts }
      });
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.SAVE_REPORT_PAGE_ELEMENT_CONFIG_FAILURE,
        payload: { reportid, pageid, elementid: element.id, error: err }
      });
    });
};

/**
 * Deletes element config
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} elementid
 */
const deleteElementConfig = (reportid, pageid, elementid) => {
  store.dispatch({
    type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_REQUEST,
    payload: { reportid: reportid, pageid: pageid, elementid: elementid }
  });

  const data = new FormData();
  data.append(
    'payload',
    JSON.stringify({
      reportid: reportid,
      pageid: pageid,
      elementid: elementid
    })
  );

  Axios.post(`/jsonrpc.php`, data, {
    params: {
      program: 'Smoove',
      controller: 'XReport',
      action: 'deleteElementConfig'
    }
  })
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_SUCCESS,
        payload: { reportid: reportid, pageid: pageid, elementid: elementid }
      });
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORT_PAGE_ELEMENT_FAILURE,
        payload: { reportid: reportid, pageid: pageid, elementid: elementid, error: err }
      });
    });
};

const loadTableResultForChart = (
  surveyId,
  reportId,
  pageid,
  dashboardElementId,
  selectedLocale,
  activeSplits,
  activeFilters,
  signal
) => {
  const tableData = {
    locale: selectedLocale,
    activeSplits: activeSplits ?? {},
    activeFilters: activeFilters ?? {}
  };
  return new Promise((resolve, reject) => {
    Axios.post(
      `surveys/${surveyId}/reports/${reportId}/pages/${pageid}/elements/${dashboardElementId}/results`,
      tableData,
      {
        timeout: 5 * 60 * 1000,
        signal: signal
      }
    )
      .then(res => {
        const results = res.data;
        resolve(results);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

const loadTableResultForChartLayered = (
  surveyId,
  reportId,
  pageid,
  dashboardElementId,
  selectedLocale,
  activeSplits,
  activeFilters,
  signal,
  chartLayer
) => {
  const viewIds = ['default', ...chartLayer.views.order];

  return Promise.all(
    viewIds.map(viewId => {
      const tableData = {
        locale: selectedLocale,
        activeSplits: activeSplits ?? {},
        activeFilters: activeFilters ?? {},
        activeLayerView: viewId === 'default' ? null : { layerId: chartLayer.id, viewId: viewId }
      };

      return new Promise((resolve, reject) => {
        Axios.post(
          `surveys/${surveyId}/reports/${reportId}/pages/${pageid}/elements/${dashboardElementId}/results`,
          tableData,
          {
            timeout: 5 * 60 * 1000,
            signal: signal
          }
        )
          .then(res => {
            const result = res.data.data;
            if (viewId === 'default') {
              const table = res.data.table;
              resolve({ result, table, viewId });
            } else {
              resolve({ result, viewId });
            }
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    })
  )
    .then(resArray => {
      const results = {};
      resArray.forEach(({ result, table = null, viewId }) => {
        if (viewId === 'default') {
          results[viewId] = { ...result, table };
        } else {
          results[viewId] = { ...result };
        }
      });
      return Promise.resolve(results);
    })
    .catch(error => {
      console.log(error);
      return Promise.reject(error);
    });
};

const loadTableResultsForSharedReport = (
  surveyId,
  reportId,
  pageid,
  dashboardElementId,
  selectedLocale,
  activeSplits,
  activeFilters,
  accessLinkToken,
  signal
) => {
  const tableData = {
    locale: selectedLocale,
    activeFilters: activeFilters ?? {},
    activeSplits: activeSplits ?? {},
    accessLinkToken: accessLinkToken
  };

  return new Promise((resolve, reject) => {
    Axios.post(
      `surveys/${surveyId}/reports/${reportId}/pages/${pageid}/elements/${dashboardElementId}/results`,
      tableData,
      {
        timeout: 5 * 60 * 1000,
        signal: signal
      }
    )
      .then(res => {
        const results = res.data;
        resolve(results);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

const loadTableResultsForSharedReportLayered = (
  surveyId,
  reportId,
  pageid,
  dashboardElementId,
  selectedLocale,
  activeSplits,
  activeFilters,
  accessLinkToken,
  signal,
  chartLayer
) => {
  const viewIds = ['default', ...chartLayer.views.order];

  return Promise.all(
    viewIds.map(viewId => {
      const tableData = {
        locale: selectedLocale,
        activeFilters: activeFilters ?? {},
        activeSplits: activeSplits ?? {},
        activeLayerView: viewId === 'default' ? null : { layerId: chartLayer.id, viewId: viewId },
        accessLinkToken: accessLinkToken
      };

      return new Promise((resolve, reject) => {
        Axios.post(
          `surveys/${surveyId}/reports/${reportId}/pages/${pageid}/elements/${dashboardElementId}/results`,
          tableData,
          {
            timeout: 5 * 60 * 1000,
            signal: signal
          }
        )
          .then(res => {
            const result = res.data.data;
            if (viewId === 'default') {
              const table = res.data.table;
              resolve({ result, table, viewId });
            } else {
              resolve({ result, viewId });
            }
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    })
  )
    .then(resArray => {
      const results = {};
      resArray.forEach(({ result, table = null, viewId }) => {
        if (viewId === 'default') {
          results[viewId] = { ...result, table };
        } else {
          results[viewId] = { ...result };
        }
      });
      return Promise.resolve(results);
    })
    .catch(error => {
      console.log(error);
      return Promise.reject(error);
    });
};

/**
 * Adds a new filter item to the report or page
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {object} filter
 */
// const saveFilter = async (reportid, pageid, filter, aboveid = null) => {
//   store.dispatch({
//     type: reportsActionTypes.SAVE_FILTER_REQUEST,
//     payload: { reportid, pageid, filter, aboveid }
//   });

//   const data = new FormData();

//   data.append(
//     'payload',
//     JSON.stringify({
//       reportid: reportid,
//       pageid: pageid,
//       filter: filter,
//       aboveid
//     })
//   );

//   return Axios.post(`/jsonrpc.php`, data, {
//     params: {
//       program: 'Smoove',
//       controller: 'XReport',
//       action: 'saveFilter'
//     }
//   })
//     .then(res => {
//       // returns all filters: {list: {], order: [] }}
//       const { filters } = res.data.result;
//       store.dispatch({
//         type: reportsActionTypes.SAVE_FILTER_SUCCESS,
//         payload: { reportid: reportid, pageid: pageid, filters: filters }
//       });
//       return { status: 'success', payload: filters };
//     })
//     .catch(err => {
//       store.dispatch({
//         type: reportsActionTypes.SAVE_FILTER_FAILURE,
//         payload: { reportid: reportid, pageid: pageid, error: err }
//       });
//       return { status: 'error', payload: err };
//     });
// };

/**
 * Deletes a filter item from the report or page
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} filterid
 */
// const deleteFilter = async (reportid, pageid, filterid) => {
//   store.dispatch({
//     type: reportsActionTypes.DELETE_FILTER_REQUEST,
//     payload: { reportid, pageid, filterid }
//   });

//   const data = new FormData();

//   data.append(
//     'payload',
//     JSON.stringify({
//       reportid: reportid,
//       pageid: pageid,
//       filterid: filterid
//     })
//   );

//   return Axios.post(`/jsonrpc.php`, data, {
//     params: {
//       program: 'Smoove',
//       controller: 'XReport',
//       action: 'deleteFilter'
//     }
//   })
//     .then(res => {
//       // returns all filters: {list: {], order: [] }}
//       const { filters } = res.data.result;
//       store.dispatch({
//         type: reportsActionTypes.DELETE_FILTER_SUCCESS,
//         payload: { reportid: reportid, pageid: pageid, filters: filters }
//       });
//       return { status: 'success', payload: filters };
//     })
//     .catch(err => {
//       store.dispatch({
//         type: reportsActionTypes.DELETE_FILTER_FAILURE,
//         payload: { reportid: reportid, pageid: pageid, error: err }
//       });
//       return { status: 'error', payload: err };
//     });
// };

/**
 * Repositions a filter item from the report or page
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} filterid
 */
// const repositionFilter = async (reportid, pageid, filterid, targetid) => {
//   store.dispatch({
//     type: reportsActionTypes.REPOSITION_FILTER_REQUEST,
//     payload: { reportid, pageid, filterid, targetid }
//   });

//   const data = new FormData();

//   data.append(
//     'payload',
//     JSON.stringify({
//       reportid: reportid,
//       pageid: pageid,
//       filterid: filterid,
//       targetid: targetid
//     })
//   );

//   return Axios.post(`/jsonrpc.php`, data, {
//     params: {
//       program: 'Smoove',
//       controller: 'XReport',
//       action: 'movefilter'
//     }
//   })
//     .then(res => {
//       // returns all filters: {list: {], order: [] }}
//       const { filters } = res.data.result;
//       store.dispatch({
//         type: reportsActionTypes.REPOSITION_FILTER_SUCCESS,
//         payload: { reportid: reportid, pageid: pageid, filters: filters }
//       });
//       return { status: 'success', payload: filters };
//     })
//     .catch(err => {
//       store.dispatch({
//         type: reportsActionTypes.REPOSITION_FILTER_FAILURE,
//         payload: { reportid: reportid, pageid: pageid, error: err }
//       });
//       return { status: 'error', payload: err };
//     });
// };

/**
 * Saves a reports/ pages split config (all splits of this report!)
 *
 * @param {string} surveyId
 * @param {string} reportId
 * @param {object} split //list/oder structure
 */
const saveSplit = (surveyId, reportId, split) => {
  store.dispatch({
    type: reportsActionTypes.REPORT_SAVE_SPLIT_REQUEST,
    payload: { reportId, split }
  });

  return Axios.post(`/surveys/${surveyId}/reports/${reportId}/splits`, split)
    .then(res => {
      // returns all splits: {list: {], order: [] }}
      console.log(res.data.splits);
      const splits = res.data.splits;
      store.dispatch({
        type: reportsActionTypes.REPORT_SAVE_SPLIT_SUCCESS,
        payload: { reportId, splits }
      });
    })
    .catch(error => {
      store.dispatch({
        type: reportsActionTypes.REPORT_SAVE_SPLIT_FAILURE,
        payload: { reportId, error }
      });
    });
};

/**
 * Deletes a reports/ pages split config
 *
 * @param {string} surveyId
 * @param {string} reportId
 * @param {string} splitId
 */
const deleteSplit = (surveyId, reportId, splitId) => {
  store.dispatch({
    type: reportsActionTypes.REPORT_DELETE_SPLIT_REQUEST,
    payload: { surveyId, reportId, splitId }
  });

  return Axios.delete(`/surveys/${surveyId}/reports/${reportId}/splits/${splitId}`)
    .then(res => {
      console.log(res);
      console.log(res.data.splits);
      // returns all splits: {list: {], order: [] }}
      const splits = res.data.splits;
      store.dispatch({
        type: reportsActionTypes.REPORT_DELETE_SPLIT_SUCCESS,
        payload: { reportId, splits }
      });
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: reportsActionTypes.REPORT_DELETE_SPLIT_FAILURE,
        payload: { reportId, splitId, error }
      });
    });
};

/**
 * Repositions a split item
 *
 * Repositions a split inside the edit splits modal
 *
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} splitid
 * @param {string} targetid
 */
// const repositionSplit = async (reportid, pageid, splitid, targetid) => {
//   store.dispatch({
//     type: reportsActionTypes.REPOSITION_SPLIT_REQUEST,
//     payload: { reportid, pageid, splitid, targetid }
//   });

//   const data = new FormData();

//   data.append(
//     'payload',
//     JSON.stringify({
//       reportid: reportid,
//       pageid: pageid,
//       splitid: splitid,
//       targetid: targetid
//     })
//   );

//   return Axios.post(`/jsonrpc.php`, data, {
//     params: {
//       program: 'Smoove',
//       controller: 'XReport',
//       action: 'moveSplit'
//     }
//   })
//     .then(res => {
//       // returns all splits: {list: {], order: [] }}
//       const { splits } = res.data.result;
//       store.dispatch({
//         type: reportsActionTypes.REPOSITION_SPLIT_SUCCESS,
//         payload: { reportid: reportid, pageid: pageid, splits: splits }
//       });
//       return { status: 'success', payload: splits };
//     })
//     .catch(err => {
//       store.dispatch({
//         type: reportsActionTypes.REPOSITION_SPLIT_FAILURE,
//         payload: { reportid: reportid, pageid: pageid, error: err }
//       });
//       return { status: 'error', payload: err };
//     });
// };

const saveSharedReport = (projectid, surveyid, reportid, content) => {
  store.dispatch({
    type: reportsActionTypes.SAVE_SHARED_REPORT_REQUEST,
    payload: { projectid, surveyid, reportid }
  });

  Axios.post(`/surveys/${surveyid}/reports/${reportid}/accessLinks`, content)
    .then(res => {
      try {
        store.dispatch({
          type: reportsActionTypes.SAVE_SHARED_REPORT_SUCCESS,
          payload: { reportid: reportid, accessLinks: res.data }
        });
      } catch (error) {
        console.log(error);
      }
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: reportsActionTypes.SAVE_SHARED_REPORT_FAILURE,
        payload: {
          reportid: reportid,
          error: err
        }
      });
    });
};

const deleteSharedReport = (surveyid, reportid, accessLinkId) => {
  store.dispatch({
    type: reportsActionTypes.DELETE_SHARED_REPORT_REQUEST,
    payload: { reportid: reportid, accessLinkId: accessLinkId }
  });

  Axios.delete(`/surveys/${surveyid}/reports/${reportid}/accessLinks/${accessLinkId}`)
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.DELETE_SHARED_REPORT_SUCCESS,
        payload: { reportid, accessLinks: res.data }
      });
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.DELETE_SHARED_REPORT_FAILURE
        // payload: { reportid: reportid, shareid: shareid, error: err }
      });
    });
};

/**
 * Loads results/ config for a dashboard element
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {string} reportid
 * @param {string} pageid
 * @param {string} elementid
 * @param {object} filter
 */
const loadElementResults = (() => {
  let cancelTokenSources = {};

  return (projectid, surveyid, reportid, pageid, elementid, filter = null, splits = null) => {
    let previousSource = cancelTokenSources[elementid];
    if (previousSource) {
      previousSource.cancel({ type: reportsActionTypes.LOAD_REPORT_ELEMENT_RESULT_CANCEL });
    }

    const cancelTokenSource = Axios.CancelToken.source();
    cancelTokenSources[elementid] = cancelTokenSource;

    store.dispatch({
      type: reportsActionTypes.LOAD_REPORT_ELEMENT_RESULT_REQUEST,
      payload: { projectid, surveyid, reportid, pageid, elementid, filter, splits }
    });

    const data = new FormData();

    data.append('payload', JSON.stringify({ projectid, surveyid, reportid, pageid, elementid, filter, splits }));

    Axios.post(`/jsonrpc.php`, data, {
      timeout: 300000, // 5 min
      cancelToken: cancelTokenSource.token,
      params: {
        program: 'Smoove',
        controller: 'XReport',
        action: 'result'
      }
    })
      .then(response => {
        const { status, error, table, result } = response.data.result;

        if (status) {
          store.dispatch({
            type: reportsActionTypes.LOAD_REPORT_ELEMENT_RESULT_SUCCESS,
            payload: {
              projectid,
              surveyid,
              reportid,
              pageid,
              elementid,
              table,
              result
            }
          });
        } else {
          throw error;
        }
      })
      .catch(error => {
        // action for cancelled request is dispatched in axios config
        if (!error.__CANCEL__) {
          store.dispatch({
            type: reportsActionTypes.LOAD_REPORT_ELEMENT_RESULT_FAILURE,
            payload: {
              projectid,
              surveyid,
              reportid,
              pageid,
              elementid,
              error: error
            }
          });
        }
      });

    return cancelTokenSource;
  };
})();

const exportElement = (
  projectId,
  surveyId,
  reportId,
  pageId = null,
  elementId = null,
  exportType,
  filters,
  splits,
  isShortlabelsActive,
  exportLoopFilters = null
) => {
  const data = {
    exportType,
    filters,
    splits,
    isShortlabelsActive,
    exportLoopFilters
  };

  let endpoint = '';
  if (elementId) endpoint = `/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/elements/${elementId}/export`;
  else if (pageId) endpoint = `/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/export`;
  else if (reportId) endpoint = `/surveys/${surveyId}/reports/${reportId}/export`;

  return Axios.post(endpoint, data, {
    responseType: 'blob',
    timeout: 5 * 60 * 1000
  }).then(res => {
    settingsActions.toggleUserExportsWindow();
    // let filename = `smoove_export.${exportType}`;
    // const contentDispHeader = res.headers?.['content-disposition'] ?? '';
    // const filenameMatch = contentDispHeader.match(/filename=([A-Za-z0-9_.]{5,})/);
    // if (filenameMatch?.[1] && filenameMatch[1]?.length > 6) filename = filenameMatch[1];
    // downloadFile(res.data, filename);
  });
};

// --- c&a pagecontent list logic start ---
const loadFolders = async (surveyId, reportId, pageId) => {
  store.dispatch({
    type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/folders`)
    .then(res => {
      console.log(res);
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_SUCCESS,
        payload: {
          surveyId,
          folders: res.data
        }
      });

      return res.data;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_FAILURE,
        payload: err
      });
    });
};

const loadFoldersSharedReport = async (surveyId, reportId, pageId, accessToken) => {
  store.dispatch({
    type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_REQUEST,
    payload: { surveyId }
  });

  return Axios.post(`/shares/reports/${reportId}/pages/${pageId}/folders/${accessToken}`)
    .then(res => {
      // console.log(res);
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_SUCCESS,
        payload: {
          surveyId,
          folders: res.data
        }
      });

      return res.data;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: reportsActionTypes.LOAD_REPORTPAGE_FOLDERS_FAILURE,
        payload: err
      });
    });
};

const createFolder = async (surveyId, reportId, pageId, folder) => {
  store.dispatch({
    type: reportsActionTypes.CREATE_REPORTPAGE_FOLDER_REQUEST,
    payload: folder
  });

  return Axios.post(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/folders`, folder)
    .then(res => {
      console.log('folders', res.data);
      store.dispatch({
        type: reportsActionTypes.CREATE_REPORTPAGE_FOLDER_SUCCESS,
        payload: {
          tables: res.data
        }
      });
      return res.data;
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.CREATE_REPORTPAGE_FOLDER_FAILURE,
        payload: err
      });
    });
};

const deleteFolder = async (surveyId, reportId, pageId, folderId) => {
  store.dispatch({
    type: reportsActionTypes.DELETE_REPORTPAGE_FOLDER_REQUEST,
    payload: { folderId }
  });

  return Axios.delete(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/folders/${folderId}`)
    .then(res => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORTPAGE_FOLDER_SUCCESS
      });
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.DELETE_REPORTPAGE_FOLDER_FAILURE,
        payload: err
      });
    });
};

const updateFolder = async (surveyId, reportId, pageId, folderId, folder) => {
  store.dispatch({
    type: reportsActionTypes.UPDATE_REPORTPAGE_FOLDER_REQUEST,
    payload: { folderId: folderId }
  });

  const { name, parentId, children, position } = folder;

  return Axios.patch(`/surveys/${surveyId}/reports/${reportId}/pages/${pageId}/folders/${folderId}`, {
    name,
    parentId,
    children,
    position
  })
    .then(res => {
      const folder = res.data;

      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORTPAGE_FOLDER_SUCCESS,
        payload: { folder }
      });
      Promise.resolve(res);
    })
    .catch(err => {
      store.dispatch({
        type: reportsActionTypes.UPDATE_REPORTPAGE_FOLDER_FAILIURE,
        payload: err
      });
      Promise.reject(err);
    });
};
// --- c&a pagecontent list logic end ---

export const reportsActions = {
  loadReports,

  addReport,
  deleteReport,
  duplicateReport,
  updateReport,

  duplicateReportPage,

  deleteDashboardElement,
  updateDashboardElement,

  saveElementConfig,
  deleteElementConfig,

  // todo: Re-Enable and fix filter and split functions when backend can hande updating elements more granularly

  // saveFilter,
  // deleteFilter,
  // repositionFilter,

  saveSplit,
  deleteSplit,
  // repositionSplit,

  saveSharedReport,
  deleteSharedReport,

  exportElement,
  loadTableResultForChart,
  loadTableResultForChartLayered,
  loadTableResultsForSharedReport,
  loadTableResultsForSharedReportLayered,
  loadElementResults,

  loadFolders,
  loadFoldersSharedReport,
  createFolder,
  deleteFolder,
  updateFolder
};
