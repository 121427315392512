export const AiTranslationButton = ({ getTranslations, whiteBackground = false }) => {
  // todo: disable when getting one translation from another element
  return (
    <div className='d-flex align-items-center m-1 pointer' onClick={getTranslations}>
      <button className={`ai-translation-button ${whiteBackground ? 'ai-translation-button--white' : ''}`}>
        <i className='fal fa-wand-magic-sparkles' />
      </button>
    </div>
  );
};
