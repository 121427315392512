import React from 'react';

import { useRenderCalcFrequencies } from './helper';
import { FormulaBuilderTableElement } from '.';

export const FormulaBuilderTableElementQuestionDefault = ({
  calculationMode,
  main,
  questionVariable,
  questionValues,
  handleAddReference
}) => {
  const renderedCalcFrequencies = useRenderCalcFrequencies(handleAddReference, main);

  return (
    <div key={`${main.id}`}>
      <h6>{questionVariable.title}</h6>
      {questionValues.order?.map(valueId => {
        const value = questionValues.list[valueId];
        const element = {
          sourceId: main.sourceId,
          sourceType: main.sourceType,
          metric: 'smvrslt_perc',

          // cell identifiers
          rowId: calculationMode === 'rows' ? main.id : null,
          rowSubId: calculationMode === 'rows' ? valueId : null,
          rowSubSubId: null,
          headId: calculationMode === 'heads' ? main.id : null,
          headSubId: calculationMode === 'heads' ? valueId : null
        };

        return (
          <FormulaBuilderTableElement
            key={`${main.id}_${valueId}`}
            element={element}
            value={value}
            handleAddReference={handleAddReference}
          />
        );
      })}
      {renderedCalcFrequencies}
    </div>
  );
};
