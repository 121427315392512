import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormFeedback, FormGroup, FormText } from 'reactstrap';
import { RowLabel } from './RowLabel';

export const TextInputRow = props => {
  const {
    title,
    type = 'text',
    id = null,
    name,
    value,
    change = () => console.log('Info: no change handler specified.'),
    onKeyPress = null,
    onPaste = null,
    onBlur = null,
    placeholder = null,
    formText = null,
    invalid = false,
    formFeedback = null,
    tooltip = null,
    disabled = false,
    required = false,
    autoFocus = false,
    maxLength = null
  } = props;

  return (
    <FormGroup row>
      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9} className={'d-flex justify-content-between'}>
        <span className={'flex-fill'}>
          <Input
            id={id ?? name}
            type={type}
            name={name}
            autoComplete='off'
            autoFocus={autoFocus}
            className={'question'}
            placeholder={placeholder}
            value={value}
            onChange={change}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            onBlur={onBlur}
            disabled={disabled}
            required={required}
            invalid={invalid}
            maxLength={maxLength}
          />
          <label htmlFor='name'>
            <span />
          </label>
          {!!formText && <FormText>{formText}</FormText>}
          {!!formFeedback && <FormFeedback>{formFeedback}</FormFeedback>}
        </span>

        {props.children}
      </Col>
    </FormGroup>
  );
};

TextInputRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  change: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string
};
