import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';

import { IconProvider } from 'smv-components';
import { getQuestionnaireElementTypeSpecificIconString, useIsLooped } from 'smv-helpers';
// import { SpinnerFast } from 'smv-components';

import { ConfirmDelete } from '../ConfirmDelete';
import { ElementTitle, ElementCollapseIcon } from '..';
import { InternalNote, QuestionTypeSelector } from './components';
import { QuestionPreviewModal } from './components/question-preview/QuestionPreviewModal';

import './Question.scss';

/**
 * What might needs to be indicated in the plain question view:
 *
 * - Header:
 *    - question.id/ question title
 *    - configuration icon
 *    - incomplete/ ready
 *    - filter or jump conditions/ rotated/ monads
 *    -
 *
 * - Question Body (depending on question type)
 *    - question text (with media)
 *    - responses
 *    - items
 *    - other question type specific display types, e.g. heatmap/ shelf etc
 */
export const Question = ({ question, isCollapsed, handler, writeable }) => {
  const { path } = useRouteMatch();
  const routeParams = useParams();
  const intl = useIntl();

  const questionnaire = useSelector(state => state.survey.questionnaire);

  const calcNestingLevel = useCallback(
    questionId => {
      let level = 0;
      const getParent = id => {
        const que = questionnaire.list[id];
        if (que.parentId) {
          level++;
          getParent(que.parentId);
        }
      };
      getParent(questionId);
      return level;
    },
    [questionnaire]
  );

  const level = calcNestingLevel(question.id);
  const smooveLevelClass = level > 0 ? ` smoove-level-${level}` : '';

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  // intersection Observer (lazyload) logic start
  const collapseRef = useRef();
  const [isRendered, setIsRendered] = useState(false);
  const [willBeRendered, setWillBeRendered] = useState(false);
  const [isCommentVisible, setIsCommentVisible] = useState(false);

  const handleSetCommentVisibility = useCallback((newState = null) => {
    setIsCommentVisible(b => (isBoolean(newState) ? newState : !b));
  }, []);
  // const [isLoading, setIsLoading] = useState(false);

  // using the in-between state "willBeRendered" to check if element is still in viewport after a short timeout. If it is, set isRendered & render question, if not, reset willBeRendered. This prevents elements from loading when they are scrolled over fast / jumped to question via sidebar and thereby pushing content down (current timeout see setTimeout in useEffect)
  // alternative: use a "isScrolling" functionality instead of timeout and only load content if user is not scrolling?
  const intersectionCb = useCallback(
    entries => {
      const [entry] = entries;
      if (!isRendered) {
        if (!willBeRendered && entry.isIntersecting) {
          //will be rendered
          setWillBeRendered(true);
        }
        if (willBeRendered && !entry.isIntersecting) {
          // cancel rendering, will not be rendered (just scrolled over question)
          setWillBeRendered(false);
        }
        if (willBeRendered && entry.isIntersecting) {
          //render
          setIsRendered(true);
        }
      }
    },
    [isRendered, willBeRendered]
  );

  useEffect(() => {
    const colRef = collapseRef.current;
    let observer = null;
    let timer = null;
    let options = {
      root: null, //viewport
      rootMargin: '0px',
      threshold: 0.9
    };
    if (!isRendered && !willBeRendered) {
      observer = new IntersectionObserver(intersectionCb, options);
      if (colRef) observer.observe(colRef);
    }
    if (!isRendered && willBeRendered) {
      timer = setTimeout(() => {
        observer = new IntersectionObserver(intersectionCb, options);
        if (colRef) observer.observe(colRef);
      }, 250);
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (colRef && observer) observer.unobserve(colRef);
    };
  }, [willBeRendered, isRendered, intersectionCb]);
  // intersection Observer (lazyload) logic end

  const handlePreviewQuestionClick = useCallback(() => {
    setIsPreviewModalOpen(b => !b);
  }, []);

  const questionCollapseContent = useMemo(() => {
    if (!isRendered) {
      return (
        <div className='col d-flex flex-column justify-content-center align-items-center my-2 h-100'>
          <span className='fas fa-spinner fa-spin fa-2x' />
        </div>
      );
    }
    return (
      <>
        <InternalNote
          question={question}
          writeable={writeable}
          isCommentVisible={isCommentVisible}
          handleSetCommentVisibility={handleSetCommentVisibility}
        />
        <QuestionTypeSelector question={question} writeable={writeable} />
      </>
    );
  }, [question, isRendered, writeable, isCommentVisible, handleSetCommentVisibility]);

  const questionConflicts = questionnaire?.conflicts?.[question?.id];

  let conflictDefaultMessage = 'Question has conflicts';
  let conflictMessageId = 'smoove.questionnaire.question.conflicts';

  if (questionConflicts) {
    switch (true) {
      case 'choices' in questionConflicts:
      case 'importChoices' in questionConflicts: {
        conflictDefaultMessage = 'Question has conflicting values in one or more items';
        conflictMessageId = 'smoove.questionnaire.question.conflicts.choices';
        break;
      }
      case 'scales' in questionConflicts: {
        conflictDefaultMessage = 'Question has conflicting values in one or more scales';
        conflictMessageId = 'smoove.questionnaire.question.conflicts.scales';
        break;
      }
      case 'importSources' in questionConflicts: {
        conflictDefaultMessage = 'Question should be placed below the question used in import';
        conflictMessageId = 'smoove.questionnaire.question.conflicts.import-sources';
        break;
      }
      default: {
        conflictDefaultMessage = 'Question has conflicts';
        conflictMessageId = 'smoove.questionnaire.question.conflicts';
      }
    }
  }

  return (
    <div id={'_' + question.id} className={`smoove-question-container ${smooveLevelClass}`}>
      {/* title, badges, and icons */}
      <div className='smoove-question-container__container'>
        <div className='question-title-group'>
          <ElementCollapseIcon toggleCollapse={handler.toggleCollapse} isCollapsed={isCollapsed} />
          <ElementTitle element={question} writeable={writeable} />
          <QuestionIcons question={question} />
        </div>

        <div className='question-edit-button-group'>
          {/* conflict icon */}
          {questionConflicts && (
            <>
              <Link
                to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: question.id })}
                id={`btn_conflict_${question.id}`}
              >
                <i className='far fa-warning p-1 text-warning' />
                <span className='text-warning mr-2'>Conflict</span>
              </Link>
              <UncontrolledTooltip target={`btn_conflict_${question.id}`} delay={{ show: 500, hide: 200 }}>
                <FormattedMessage id={conflictMessageId} defaultMessage={conflictDefaultMessage} />
              </UncontrolledTooltip>
            </>
          )}

          {/* edit icon */}
          <Link to={generatePath(`${path}/:elementid/:section?`, { ...routeParams, elementid: question.id })}>
            <i id={`btn_edit_${question.id}`} className='far fa-edit icon-smv-blue p-1' />
          </Link>
          <UncontrolledTooltip target={`btn_edit_${question.id}`} delay={{ show: 500, hide: 200 }}>
            <FormattedMessage
              id={`smoove.common.buttons.edit-question`}
              defaultMessage={'configure question element'}
            />
          </UncontrolledTooltip>

          {/* comment icon */}
          <i
            id={`btn_comment_${question.id}`}
            className={`far fa-comment icon-smv-blue p-1`}
            onClick={handleSetCommentVisibility}
          />
          <UncontrolledTooltip target={`btn_comment_${question.id}`} delay={{ show: 500, hide: 200 }}>
            {isCommentVisible
              ? intl.formatMessage({ id: 'smoove.questionnaire.internal-note.hide-note' })
              : intl.formatMessage({ id: 'smoove.questionnaire.internal-note.show-note' })}
          </UncontrolledTooltip>

          {/* preview icon */}
          <i
            id={`btn_preview_${question.id}`}
            className='far fa-magnifying-glass icon-smv-blue p-1'
            onClick={handlePreviewQuestionClick}
          />
          <UncontrolledTooltip target={`btn_preview_${question.id}`} delay={{ show: 500, hide: 200 }}>
            <FormattedMessage id={`smoove.questionnaire.preview-question`} defaultMessage={'preview'} />
          </UncontrolledTooltip>

          {/* copy icon */}
          {writeable && (
            <>
              <i
                id={`btn_copy_${question.id}`}
                className='far fa-clone icon-smv-blue p-1'
                onClick={handler.duplicateQuestionnaireElement}
              />
              <UncontrolledTooltip target={`btn_copy_${question.id}`} delay={{ show: 500, hide: 200 }}>
                <FormattedMessage id={`smoove.common.buttons.copy-question`} defaultMessage={'copy question'} />
              </UncontrolledTooltip>
            </>
          )}

          {/* delete icon */}
          {writeable && <ConfirmDelete element={question} />}
        </div>
      </div>
      <div ref={collapseRef} className='row smoove-question-container__selection'>
        <Collapse isOpen={isCollapsed} timeout={600}>
          <div className={'que-selector-container'} style={{ minHeight: isRendered ? 'auto' : '210px' }}>
            {questionCollapseContent}
          </div>
        </Collapse>
      </div>
      {isPreviewModalOpen && (
        <QuestionPreviewModal isOpen={isPreviewModalOpen} toggle={handlePreviewQuestionClick} question={question} />
      )}
    </div>
  );
};

Question.propTypes = {
  // elements: PropTypes.object.isRequired,
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired
  }).isRequired,
  writeable: PropTypes.bool.isRequired
};

const QuestionIcons = ({ question }) => {
  const routeParams = useParams();
  const intl = useIntl();
  const { path } = useRouteMatch();
  const list = useSelector(state => state.survey.questionnaire.list);

  const { isLoopedQuestion } = useIsLooped(list, question);

  const questionHas = {
    isLooped: isLoopedQuestion,
    screenout: Boolean(Object.values(question.config.screenout?.elements ?? {})?.[0]?.children?.length),
    filter: Boolean(Object.values(question.config.filter?.elements ?? {})?.[0]?.children?.length),
    jump: Boolean(Object.values(question.config.jump?.elements ?? {})?.[0]?.children?.length),
    sharedList: !!question?.config?.choices?.config?.share,
    useList: !!question?.config?.choices?.config?.use
  };

  return (
    <div className='question-icons'>
      <span className='ml-2' id={`icon_qtype_${question.id}`}>
        <Link
          to={generatePath(`${path}/:elementid/:section?`, {
            ...routeParams,
            elementid: question.id,
            section: 'config'
          })}
        >
          <IconProvider icon={getQuestionnaireElementTypeSpecificIconString(question)} filled={true} />
          <UncontrolledTooltip target={`icon_qtype_${question.id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
            <FormattedMessage
              id={`smoove.questionnaire.question.${getQuestionnaireElementTypeSpecificIconString(question)
                ?.split('_')
                .join('-')}`}
              defaultMessage={getQuestionnaireElementTypeSpecificIconString(question)}
            />
          </UncontrolledTooltip>
        </Link>
      </span>
      {questionHas.isLooped && (
        <span id={`icon_loop_${question.id}`} className={'ml-2'}>
          <IconProvider icon='loop' filled={false} clickable={false} />
          <UncontrolledTooltip target={`icon_loop_${question.id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.is-looped' })}
          </UncontrolledTooltip>
        </span>
      )}
      {question?.importedBy?.length > 0 && (
        <span id={`icon_import_${question.id}`} className={'ml-2'}>
          <IconProvider icon='imported' filled={true} clickable={true} />
          <UncontrolledTooltip target={`icon_import_${question.id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
            {intl.formatMessage({
              id: 'smoove.questionnaire.question.question-is-imported-in-one-or-more-questions'
            })}
          </UncontrolledTooltip>
        </span>
      )}

      {questionHas.screenout && (
        <span id={`icon_screenout_${question.id}`} className={'ml-2'}>
          <Link
            to={generatePath(`${path}/:elementid/:section?`, {
              ...routeParams,
              elementid: question.id,
              section: 'screenout'
            })}
          >
            <IconProvider icon='screenout' filled={true} clickable={true} />
            <UncontrolledTooltip
              target={`icon_screenout_${question.id}`}
              delay={{ show: 500, hide: 200 }}
              autohide={false}
            >
              <FormattedMessage id={`smoove.questionnaire.screenout`} defaultMessage={'Screenout'} />
            </UncontrolledTooltip>
          </Link>
        </span>
      )}

      {questionHas.filter && (
        <span id={`icon_filter_${question.id}`} className={'ml-2'}>
          <Link
            to={generatePath(`${path}/:elementid/:section?`, {
              ...routeParams,
              elementid: question.id,
              section: 'filter'
            })}
          >
            <IconProvider icon='filter' filled={true} clickable={true} />
            <UncontrolledTooltip
              target={`icon_filter_${question.id}`}
              delay={{ show: 500, hide: 200 }}
              autohide={false}
            >
              <FormattedMessage id={`smoove.questionnaire.filter`} defaultMessage={'Filter'} />
            </UncontrolledTooltip>
          </Link>
        </span>
      )}

      {questionHas.jump && (
        <span id={`icon_jump_${question.id}`} className={'ml-2'}>
          <Link
            to={generatePath(`${path}/:elementid/:section?`, {
              ...routeParams,
              elementid: question.id,
              section: 'jump'
            })}
          >
            <IconProvider icon='jump' filled={true} clickable={true} />
            <UncontrolledTooltip target={`icon_jump_${question.id}`} delay={{ show: 500, hide: 200 }} autohide={false}>
              <FormattedMessage id={`smoove.questionnaire.jump`} defaultMessage={'Jump'} />
            </UncontrolledTooltip>
          </Link>
        </span>
      )}
    </div>
  );
};
