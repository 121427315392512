import { customAlphabet } from 'nanoid';

export const getTableElement = sourceItem => {
  const id = customAlphabet('1234567890abcdef', 24)();
  const element = {
    id,
    sourceId: sourceItem.id,
    config: {}
  };

  if (sourceItem?.type) element.sourceType = sourceItem?.type ?? 'question';

  return element;
};
