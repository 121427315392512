import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import { translationsActions } from 'smv-redux';

import { EditableTextHover } from '../Content';

export const PasteMultipleTranslationsButton = ({ toggle }) => {
  const intl = useIntl();

  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i className={'fal fa-exchange pointer icon-smv-blue mr-1'} />
      <span>{intl.formatMessage({ id: `smoove.questionnaire.translation.modal.paste-subelement.button-label` })}</span>
    </div>
  );
};

export const PasteMultipleTranslationsModal = ({ isOpen, toggle, locale, subelementType = 'choices', question }) => {
  const translations = useSelector(state => state.survey.translations);
  const surveyid = useSelector(state => state.survey.id);

  const [pastedTranslations, setPastedTranslations] = useState({});

  const intl = useIntl();

  const subelementOrder = useMemo(() => question?.config?.[subelementType]?.order ?? [], [question, subelementType]);

  const handlePaste = useCallback(
    e => {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('Text');
      const pastedElements = pastedText.trim().split(/\r?\n|\r/);

      setPastedTranslations(s => {
        return pastedElements.reduce((acc, cur, i) => {
          if (subelementOrder[i]) acc[subelementOrder[i]] = cur;
          return acc;
        }, {});
      });

      return false;
    },
    [subelementOrder]
  );

  const handleUpdatePastedTranslation = useCallback((subelementId, value) => {
    setPastedTranslations(s => {
      return { ...s, [subelementId]: value };
    });
  }, []);

  const handleCopyChoicesTranslation = useCallback(() => {
    Object.keys(pastedTranslations).forEach(subelementId => {
      const value = pastedTranslations[subelementId];
      const subelement = question.config?.[subelementType]?.list?.[subelementId];
      const subelementTranslationId = subelement.config.translations?.label;

      translationsActions.updateTranslation(surveyid, subelementTranslationId, {
        [locale]: value
      });
    });
  }, [surveyid, subelementType, locale, pastedTranslations, question.config]);

  const handleSave = useCallback(() => {
    handleCopyChoicesTranslation();
    toggle();
  }, [handleCopyChoicesTranslation, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={'body'} size={'lg'} onPaste={handlePaste} autoFocus>
      <ModalHeader>
        {intl.formatMessage({ id: `smoove.questionnaire.translation.modal.paste-subelement.modal-title` })}
      </ModalHeader>
      <ModalBody>
        {intl.formatMessage({ id: `smoove.questionnaire.translation.modal.paste-subelement.modal-text` })}

        <Input onPaste={handlePaste} />

        <table className={'w-100'}>
          <thead>
            <tr>
              <th width='50%'>Current</th>
              <th width='50%'>New</th>
            </tr>
          </thead>
          <tbody>
            {question?.config?.[subelementType]?.order?.map(subelementId => {
              const subelement = question.config[subelementType].list[subelementId];
              const subelementTranslationId = subelement.config.translations?.label;

              return (
                <tr key={subelementId}>
                  <td>
                    <EditableTextHover value={translations[subelementTranslationId]?.[locale]} writeable={false} />
                  </td>
                  <td>
                    <EditableTextHover
                      value={pastedTranslations?.[subelementId] ?? ''}
                      writeable={true}
                      replacing={true}
                      onBlur={value => handleUpdatePastedTranslation(subelementId, value)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          {intl.formatMessage({ id: `smoove.common.buttons.cancel` })}
        </Button>
        <Button color={'primary'} onClick={handleSave}>
          {intl.formatMessage({ id: 'smoove.common.buttons.save' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
