import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input } from 'reactstrap';

import { useIntl } from 'react-intl';
import { useCallback } from 'react';

import './SmvExportFileButtonModal.scss';
import { useGetSourceElement } from 'smv-helpers';

export const SmvExportFileOptionsModal = ({
  isOpen,
  toggle,
  reportFilters,
  selectedLoopFilters,
  setSelectedLoopFilters
}) => {
  const intl = useIntl();

  const getSourceElement = useGetSourceElement();

  const handleFilterOptionsChange = filterId => {
    if (selectedLoopFilters[filterId]) {
      const updatedFilters = { ...selectedLoopFilters };
      delete updatedFilters[filterId];
      setSelectedLoopFilters(updatedFilters);
    } else {
      const filterElement = reportFilters.list[filterId];

      const subFilters = {};

      filterElement.order.forEach(subFilterId => {
        if (filterElement?.hidden && filterElement.hidden?.[subFilterId]) return;

        subFilters[subFilterId] = true;
      });

      const updatedFilters = { ...selectedLoopFilters, [filterId]: subFilters };

      const sortedFilters = Object.keys(updatedFilters)
        .sort((a, b) => reportFilters.order.indexOf(a) - reportFilters.order.indexOf(b))
        .reduce((acc, key) => {
          acc[key] = updatedFilters[key];
          return acc;
        }, {});
      setSelectedLoopFilters(sortedFilters);
    }
  };

  const saveHandler = useCallback(() => {
    setSelectedLoopFilters(selectedLoopFilters);
    toggle();
  }, [setSelectedLoopFilters, selectedLoopFilters, toggle]);

  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggle} size={'xl'} className={'export-options-modal-container'}>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'smoove.page.reports.export-options' })}</ModalHeader>
      <ModalBody>
        <Row className='mt-2'>
          <Col>
            <strong>{intl.formatMessage({ id: 'smoove.page.reports.export-options.explanation.title' })}</strong>
          </Col>
        </Row>
        <Row className='mt-2 mb-4'>
          <Col>{intl.formatMessage({ id: 'smoove.page.reports.export-options.explanation.text' })}</Col>
        </Row>
        {reportFilters?.order?.map(filterId => {
          const filter = reportFilters.list[filterId];
          const { elementTitle } = getSourceElement(filter);
          const label = filter?.label?.length > 0 ? filter.label : elementTitle;

          return (
            <Row key={filterId} className='export-file-modal-container__filter-list'>
              <Col xs='auto'>
                <Input
                  type='checkbox'
                  onChange={() => handleFilterOptionsChange(filterId)}
                  checked={!!selectedLoopFilters[filterId]}
                />
              </Col>
              <Col>{label}</Col>
            </Row>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button color={'primary'} onClick={saveHandler}>
          {intl.formatMessage({ id: 'smoove.common.buttons.save' })}
        </Button>
        <Button color={'grey'} onClick={toggle}>
          {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
