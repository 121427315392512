import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyAccessIconAndTooltip } from './misc/ReadOnlyAccessIconAndTooltip';
import { useUserHasPermission } from '../helpers/useUserHasPermission';
import { permissionEnum } from '../helpers/roleConstants';

/**
 * Renders a <h2>headline</h2> and a readOnly icon if user does not have access rights for the selected project
 *
 * @param {string} headline an existing translation-id
 * @param {string} fallback used if no headline is present. Use fallback instead of headline if you want to render a custom string, eg a survey title etc.
 */
export const PageHeadline = ({ headline, fallback = null, ...rest }) => {
  const string = headline ? <FormattedMessage id={headline} defaultMessage={fallback} {...rest} /> : fallback;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  return (
    <h2 className='text-uppercase text-smv fw-bold'>
      <div className='d-flex align-items-center'>
        {!userCanEditProject && (
          <span className='mr-2' style={{ fontSize: '0.75em' }}>
            <ReadOnlyAccessIconAndTooltip />
          </span>
        )}
        <span>{string}</span>
      </div>
    </h2>
  );
};
