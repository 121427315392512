import { arrayMoveImmutable } from 'array-move';
import produce from 'immer';
import { isArray, isNull } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Input, FormGroup, Label } from 'reactstrap';

import { useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';

export const AutomaticHeadSortation = ({ tableConfig, handler, sortationConfig }) => {
  const intl = useIntl();
  const queElementList = useSelector(s => s.survey.questionnaire.list);

  const { isDetailedMatrix, isCompactMatrix } = useMatrixType(tableConfig);

  const isSortedAutomatic = useMemo(() => {
    if (!(sortationConfig?.automaticHeadSortation?.enabled ?? false)) return false;
    else if (isCompactMatrix && sortationConfig?.headSortationMode === 'automatic') return true;
    else if (!isCompactMatrix && sortationConfig?.sortationMode === 'automatic') return true;
    return false;
  }, [sortationConfig, isCompactMatrix]);

  const assortmentCount = useMemo(() => sortationConfig?.automaticHeadSortation?.assortments?.length ?? 0, [
    sortationConfig
  ]);

  const sourceElements = useMemo(() => {
    const _sources = { list: {}, order: [] };
    tableConfig.rows.order.forEach(rowId => {
      const element = tableConfig.rows.list[rowId];
      const { sourceType, sourceId } = element;
      if (sourceType === 'question') {
        let queElement = queElementList?.[sourceId] ?? {};
        if (!queElement) return null;
        const values = getMergedChoices(queElement, queElement?.config?.choices, queElementList);
        const scales = queElement?.config?.scales;

        if (isDetailedMatrix) {
          values.order.forEach(valueid => {
            scales.order.forEach(scaleid => {
              const _key = `${rowId}~${valueid}~${scaleid}`;
              _sources.order.push(_key);
              _sources.list[_key] = {
                subelement: values.list[valueid],
                subsubelement: scales.list[scaleid],
                rowId,
                rowSubId: valueid,
                rowSubSubId: scaleid
              };
            });
          });
        } else if (isCompactMatrix) {
          scales.order.forEach(scaleid => {
            const _key = `${rowId}~${scaleid}~null`;
            _sources.order.push(_key);
            _sources.list[_key] = {
              label: `${rowId} ${scaleid}`,
              subelement: scales.list[scaleid],
              rowId,
              rowSubId: scaleid,
              rowSubSubId: null
            };
          });
        } else {
          values.order.forEach(valueid => {
            const _key = `${rowId}~${valueid}~null`;
            _sources.order.push(_key);
            _sources.list[_key] = {
              subelement: values.list[valueid],
              rowId,
              rowSubId: valueid,
              rowSubSubId: null
            };
          });
        }
      }
    });

    return _sources;
  }, [queElementList, isCompactMatrix, isDetailedMatrix, tableConfig]);

  const toggleSortationMode = useCallback(
    e => {
      const { checked } = e.target;
      handler.setInternalConfig(state =>
        produce(state, draft => {
          if (!draft.sortation?.automaticHeadSortation || isArray(draft.sortation.automaticHeadSortation)) {
            draft.sortation.automaticHeadSortation = { enabled: false };
          }

          draft.sortation.automaticHeadSortation.enabled = checked;
          if (isCompactMatrix) {
            draft.sortation.headSortationMode = checked ? 'automatic' : null;
          } else {
            draft.sortation.sortationMode = checked ? 'automatic' : null;
          }
        })
      );
    },
    [handler, isCompactMatrix]
  );

  const handleSaveSortationSource = useCallback(
    (index, element) => {
      handler.setInternalConfig(state =>
        produce(state, draft => {
          if (!draft.sortation?.automaticHeadSortation || isArray(draft.sortation.automaticHeadSortation)) {
            draft.sortation.automaticHeadSortation = { enabled: false, assortments: [] };
          } else if (!draft.sortation.automaticHeadSortation?.assortments) {
            draft.sortation.automaticHeadSortation.assortments = [];
          }

          if (!isNull(index)) {
            draft.sortation.automaticHeadSortation.assortments[index] = element;
          } else {
            draft.sortation.automaticHeadSortation.assortments.push(element);
          }
        })
      );
    },
    [handler]
  );

  const handleDeleteSortationSource = useCallback(
    index => {
      handler.setInternalConfig(state =>
        produce(state, draft => {
          draft.sortation.automaticHeadSortation.assortments.splice(index, 1);
        })
      );
    },
    [handler]
  );

  const configuredAssortmentConfigElements = useMemo(
    () =>
      (sortationConfig?.automaticHeadSortation?.assortments ?? []).map((element, idx) => {
        const { rowId, rowSubId, rowSubSubId } = element;
        return (
          <AssortmentConfigElementDraggable
            key={`${rowId}~${rowSubId}~${rowSubSubId}`}
            id={`${rowId}~${rowSubId}~${rowSubSubId}`}
            element={element}
            index={idx}
            handleDeleteSortationSource={handleDeleteSortationSource}
            handleSaveSortationSource={handleSaveSortationSource}
            sourceElements={sourceElements}
          />
        );
      }),
    [handleDeleteSortationSource, handleSaveSortationSource, sourceElements, sortationConfig]
  );

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      handler.setInternalConfig(state =>
        produce(state, draft => {
          draft.sortation.automaticHeadSortation.assortments = arrayMoveImmutable(
            draft.sortation.automaticHeadSortation.assortments,
            result.source.index,
            result.destination.index
          );
        })
      );
    },
    [handler]
  );

  return (
    <div className='mb-2'>
      <h6>Automatic sorting</h6>
      <FormGroup switch>
        <Input
          type='switch'
          role='switch'
          id={`automatic_head_sorting`}
          name='automaticHeadSortation'
          checked={isSortedAutomatic}
          onChange={toggleSortationMode}
        />
        <Label for={'automatic_head_sorting'}>
          {intl.formatMessage({
            id: 'smoove.page.tables.table-config.automatic-head-sortation.headline',
            defaultMessage: 'Automatic sorting'
          })}
        </Label>
      </FormGroup>

      {isSortedAutomatic && (
        <>
          <div className={'d-flex justify-content-between align-items-center mt-2'}>
            <AssortmentConfigElement
              handleSaveSortationSource={handleSaveSortationSource}
              sourceElements={sourceElements}
              assortmentCount={assortmentCount}
            />
          </div>

          {(sortationConfig?.automaticHeadSortation?.assortments ?? []).length > 0 && (
            <>
              <h6 className={'mt-4'}>
                {intl.formatMessage({
                  id: 'smoove.page.tables.table-config.automatic-head-sortation.assortments.headline',
                  defaultMessage: 'Sort by'
                })}
                :
              </h6>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-automatic-sortation'>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={'mt-2'}>
                      {configuredAssortmentConfigElements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </>
      )}
    </div>
  );
};

const AssortmentConfigElementDraggable = ({ id, index, ...props }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={'d-flex justify-content-between align-items-center mt-2'}
        >
          <i className='fal fa-grip-vertical mr-2' />
          <AssortmentConfigElement index={index} {...props} />
        </div>
      )}
    </Draggable>
  );
};

const AssortmentConfigElement = ({
  element = {},
  sourceElements,
  index = null,
  assortmentCount = 0,
  handleSaveSortationSource,
  handleDeleteSortationSource = null
}) => {
  const intl = useIntl();

  const [internalElement, setInternalElement] = useState({
    rowId: null,
    rowSubId: null,
    rowSubSubId: null,
    direction: 'DESC',
    ...element
  });

  const _key = useMemo(() => {
    const rowId = internalElement?.rowId ?? null;
    const rowSubId = internalElement?.rowSubId ?? null;
    const rowSubSubId = internalElement?.rowSubSubId ?? null;

    return `${rowId}~${rowSubId}~${rowSubSubId}`;
  }, [internalElement]);

  useEffect(() => {
    if (isNull(index)) {
      setInternalElement({
        rowId: null,
        rowSubId: null,
        rowSubSubId: null,
        direction: 'DESC'
      });
    }
  }, [index, assortmentCount]);

  const handleSelectSortationSource = useCallback(
    e => {
      const element = sourceElements.list?.[e.target.value] ?? {};
      setInternalElement(state => {
        const updated = produce(state, draft => {
          draft.rowId = element?.rowId ?? null;
          draft.rowSubId = element?.rowSubId ?? null;
          draft.rowSubSubId = element?.rowSubSubId ?? null;
        });

        if (!isNull(index)) handleSaveSortationSource(index, updated);

        return updated;
      });
    },
    [handleSaveSortationSource, index, sourceElements]
  );

  const handleSelectSortationDirection = useCallback(
    e => {
      const value = e.target.value;
      setInternalElement(state => {
        const updated = produce(state, draft => {
          draft.direction = value;
        });

        if (!isNull(index)) handleSaveSortationSource(index, updated);
        return updated;
      });
    },
    [handleSaveSortationSource, index]
  );

  const _handleDeleteSortationSource = useCallback(() => handleDeleteSortationSource(index), [
    handleDeleteSortationSource,
    index
  ]);

  return (
    <>
      <Input
        type={'select'}
        bsSize={'sm'}
        label={'Select sortation source row'}
        id={`sortation_source_row`}
        name={`sortation_source_row`}
        className={'mr-1'}
        value={_key}
        onChange={handleSelectSortationSource}
      >
        <option value={'null~null~null'} style={{ fontStyle: 'italic' }} disabled>
          {intl.formatMessage({ id: 'smoove.common.select.option', defaultMessage: 'select' })}
        </option>

        {sourceElements.order.map(key => (
          <AssortmentConfigElementOption key={key} value={key} element={sourceElements.list[key]} />
        ))}
      </Input>

      <Input
        type={'select'}
        bsSize={'sm'}
        label={'Sortation direction'}
        id={`sortation_direction`}
        name={`sortation_direction`}
        className={'mr-1'}
        value={internalElement.direction}
        onChange={handleSelectSortationDirection}
      >
        <option value={'DESC'}>
          {intl.formatMessage({
            id: 'smoove.page.tables.table-config.automatic-head-sortation.direction.descending',
            defaultMessage: 'descending'
          })}
        </option>
        <option value={'ASC'}>
          {intl.formatMessage({
            id: 'smoove.page.tables.table-config.automatic-head-sortation.direction.ascending',
            defaultMessage: 'ascending'
          })}
        </option>
      </Input>

      {isNull(index) && (
        <Button size={'sm'} outline className={'mr-1'} onClick={() => handleSaveSortationSource(null, internalElement)}>
          <i className='fal fa-plus'></i>
        </Button>
      )}
      {!!handleDeleteSortationSource && (
        <Button size={'sm'} outline className={'mr-1'} onClick={_handleDeleteSortationSource}>
          <i className={'fal fa-trash-alt'} />
        </Button>
      )}
    </>
  );
};

const AssortmentConfigElementOption = ({ element, value }) => {
  const labelSubElement = useSubelementPropertyTranslation(element?.subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const labelSubSubElement = useSubelementPropertyTranslation(element?.subsubelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const label = [labelSubElement, labelSubSubElement].filter(Boolean).join(' - ');

  return <option value={value}>{label}</option>;
};
