import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Store as notificationStore } from 'react-notifications-component';

import { settingsActions } from '../redux';
import { SentryReporting } from './SentryReporting';
// import { SentryReporting } from '.';

export const VersionCheck = ({ children }) => {
  const version = useSelector(state => state.appSettings.version);

  const intl = useIntl();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;

    if (!version) settingsActions.checkAppVersion();

    if (version && version !== global.REACT_APP_VERSION) {
      notificationStore.addNotification({
        type: 'warning',
        container: 'bottom-right',
        title: intl.formatMessage({
          id: 'core.notifications.version-outdated.header',
          defaultMessage: 'Update available!'
        }),
        message: (
          <>
            <div>
              {intl.formatMessage({
                id: 'core.notifications.version-outdated.message',
                defaultMessage: 'The application has been updated, please reload the page to use the latest version!'
              })}
            </div>
            <div className={'small font-italic pt-2'} style={{ lineHeight: '11px' }}>
              {intl.formatMessage({
                id: 'core.notifications.version-outdated.available-label',
                defaultMessage: 'Available version'
              })}
              : {version}
              <br />
              {intl.formatMessage({
                id: 'core.notifications.version-outdated.loaded-label',
                defaultMessage: 'Loaded version'
              })}
              : {global.REACT_APP_VERSION}
            </div>
          </>
        ),
        dismiss: {
          showIcon: true,
          duration: 5000,
          onScreen: true,
          pauseOnHover: true
        }
      });
    }
  }, [version, intl]);

  return <SentryReporting>{children}</SentryReporting>;
};
