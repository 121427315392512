import produce from 'immer';
import { sharedReportActionTypes } from '../constants';

export const project_sharedReportReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case sharedReportActionTypes.LOAD_REPORT_REQUEST:
      return produce(state, draftState => {
        draftState.fetching = true;
        draftState.error = false;
      });
    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
      return produce(state, draftState => {
        if (!draftState?.reports || Array.isArray(draftState.reports)) draftState.reports = {};
        draftState.survey = payload.survey;
        draftState.reports[payload.report.id] = payload.report;

        if (payload.project) {
          draftState.id = payload.project.id;
        }

        draftState.error = false;
        draftState.fetching = false;
      });
    case sharedReportActionTypes.LOAD_REPORT_FAILURE:
      return produce(state, draftState => {
        draftState.fetching = false;
        draftState.error = true;
      });

    default:
      return state;
  }
};
