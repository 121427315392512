import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ConfirmAction } from 'smv-components';
import { surveyActions } from 'smv-redux';

import { getMediaElementType } from './helpers/getMediaElementType';

export const MediaLibraryElement = ({
  element,
  selectedFiles,
  isSelectionDisabled,
  handleClickFile,
  handleLightboxClick
}) => {
  const surveyid = useSelector(state => state.survey.id);

  const [playing, setPlaying] = useState(false);
  const togglePlaying = useCallback(() => setPlaying(b => !b), []);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  selectedFiles = selectedFiles.filter(el => el !== undefined);

  const intl = useIntl();
  const inputFile = useRef(null);

  const handleDeleteMediaItem = useCallback(media => surveyActions.deleteMediaElement(surveyid, media.id), [surveyid]);

  const handleClickChangeFile = () => {
    inputFile.current.click();
  };

  const handleFileUploadDialog = useCallback(
    e => {
      let files = Object.values(e?.target?.files).map(el => {
        el.id = element.id;
        return el;
      });

      // handleUploadImages(files)
      files.forEach(file => {
        surveyActions.updateMediaElement(surveyid, file.id, file);
      });
      e.target.value = null;
    },
    [element.id, surveyid]
  );

  const handleClickVideoPreview = useCallback(() => {
    setIsVideoModalOpen(b => !b);
  }, []);

  const elementType = getMediaElementType(element);

  const mediaElement = useMemo(() => {
    if (elementType === 'audio') {
      return new Audio(element?.local?.url);
    } else return null;
  }, [elementType, element?.local?.url]);

  useEffect(() => {
    if (mediaElement) {
      playing ? mediaElement.play() : mediaElement.pause();
    }
  }, [playing, mediaElement]);

  useEffect(() => {
    if (mediaElement) {
      mediaElement.addEventListener('ended', () => setPlaying(false));
    }
    return () => {
      if (mediaElement) {
        mediaElement.removeEventListener('ended', () => setPlaying(false));
      }
    };
  }, [mediaElement]);

  const isSelected = selectedFiles.find(a => a?.id === element?.id);
  if (!element) return null;

  return (
    <div className={`medialist-item mr-2 ${isSelected ? 'medialist-item--selected' : ''}`}>
      {element?.loading && (
        <div className='medialist-item__loading'>
          <span className={'fas fa-spinner fa-spin'}></span>
        </div>
      )}
      {elementType === 'image' && (
        <img
          className='medialist-item__image'
          // todo: remove date, the backend should provide a last edited parameter in the media url to reload media, if file has changed
          src={(element?.local?.thumbnailUrl ?? element?.local?.url) + '?' + new Date().getTime()}
          title={element.originalName}
          alt={element.originalName}
        />
      )}
      {elementType === 'audio' && (
        <div
          className='medialist-item__av-media d-flex align-items-center justify-content-center'
          title={element.originalName}
        >
          <i className={`far fa-2x fa-music`} />
        </div>
      )}
      {elementType === 'video' && (
        <div
          className='medialist-item__av-media d-flex align-items-center justify-content-center'
          title={element.originalName}
        >
          <video width='100%'>
            <source src={element.local.url} type={element.mimeType} />
          </video>
        </div>
      )}
      <div className='medialist-item__label' title={element.originalName}>
        {element.originalName}
      </div>
      <div className='medialist-item__buttons' title={element.originalName}>
        <div className='medialist-item__buttons-top-right'>
          <ConfirmAction
            title={intl.formatMessage({ id: `smoove.common.media-library.delete-image` })}
            size={'md'}
            body={
              <div>
                <FormattedMessage
                  id={'smoove.common.media-library.confirm-image-delete'}
                  defaultMessage={'Are you sure you want to delete this file?'}
                />
              </div>
            }
            callback={() => handleDeleteMediaItem(element)}
            btnColor={'danger'}
            btnText={intl.formatMessage({ id: `smoove.common.media-library.delete-image` })}
          >
            {confirm => (
              <>
                <Button
                  className='medialist-item__button'
                  size={'sm'}
                  color={'danger'}
                  onClick={confirm}
                  id={`tt_delete_${element.id}`}
                >
                  <i className='far fa-fw fa-trash-alt' />
                </Button>
                <UncontrolledTooltip target={`tt_delete_${element.id}`}>Delete file</UncontrolledTooltip>
              </>
            )}
          </ConfirmAction>
          <Button
            className='medialist-item__button'
            onClick={handleClickChangeFile}
            size={'sm'}
            color={'secondary'}
            id={`tt_edit_${element.id}`}
          >
            <i className='far fa-fw fa-edit'></i>
          </Button>
          <UncontrolledTooltip target={`tt_edit_${element.id}`}>Change file</UncontrolledTooltip>
          <input
            type='file'
            // todo: specify which files are allowed and which are not
            accept='image/*,audio/*,video/*'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleFileUploadDialog}
          />
        </div>
        <div className='medialist-item__cta-buttons'>
          {elementType === 'image' && (
            <>
              <Button
                className='medialist-item__button'
                onClick={() => handleLightboxClick(element)}
                size={'sm'}
                color={'secondary'}
                id={`tt_preview_img_${element.id}`}
              >
                <i className='far fa-fw fa-magnifying-glass-plus'></i>
              </Button>
              <UncontrolledTooltip target={`tt_preview_img_${element.id}`}>Preview</UncontrolledTooltip>
            </>
          )}
          {elementType === 'video' && (
            <>
              <Button
                className='medialist-item__button'
                onClick={handleClickVideoPreview}
                size={'sm'}
                color={'secondary'}
                id={`tt_preview_video_${element.id}`}
              >
                <i className='far fa-fw fa-magnifying-glass-plus'></i>
              </Button>
              <UncontrolledTooltip target={`tt_preview_video_${element.id}`}>Preview</UncontrolledTooltip>
            </>
          )}
          {elementType === 'audio' && (
            <Button className='medialist-item__button' onClick={togglePlaying} size={'sm'} color={'secondary'}>
              <i className={`far fa-circle-${playing ? 'pause' : 'play'} `} />
            </Button>
          )}
          {!isSelectionDisabled && (
            <>
              <Button
                className='medialist-item__button'
                onClick={!isSelectionDisabled ? () => handleClickFile(element) : null}
                size={'sm'}
                color={`${isSelected ? 'danger' : 'primary'}`}
                id={`tt_add_${element.id}`}
              >
                <i className={`far fa-fw fa-${isSelected ? 'minus' : 'plus'}`}></i>
              </Button>
              <UncontrolledTooltip target={`tt_add_${element.id}`}>{isSelected ? 'remove' : 'add'}</UncontrolledTooltip>
            </>
          )}
        </div>
      </div>
      {elementType === 'video' && (
        <Modal isOpen={isVideoModalOpen} toggle={handleClickVideoPreview} size={'md'}>
          <ModalHeader toggle={handleClickVideoPreview}>{element.originalName}</ModalHeader>
          <ModalBody>
            <video controls width='100%'>
              <source src={element.local.url} type={element.mimeType} />
            </video>
          </ModalBody>
          <ModalFooter>
            <Button color='grey' onClick={handleClickVideoPreview}>
              <FormattedMessage defaultMessage={`close`} id={`smoove.common.buttons.close`} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
