import React from 'react';
import { useSelector } from 'react-redux';

import { ReferenceValues } from '.';

export const ReferenceTypeSystemVariable = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleReferenceSelect
}) => {
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const { sourceId } = main;

  const systemVariable = systemVariables.list[sourceId];
  if (!systemVariable) return null;

  const label = systemVariable.label;

  return (
    <ReferenceValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      matrixType={matrixType}
      values={{ order: systemVariable.order, list: systemVariable.list }}
      handleReferenceSelect={handleReferenceSelect}
    />
  );
};
