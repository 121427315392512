import { useCallback, useMemo } from 'react';
import { DropdownItem } from 'reactstrap';
import { isEqual } from 'lodash';
import produce from 'immer';
import classNames from 'classnames';

import { useSmooveCustomDropdown } from '../../useSmooveCustomDropdown';
import { FormulaBuilderReferenceElementFixedReferenceSelect } from '.';

export const FormulaBuilderReferenceElement = ({
  calculationId,
  calculationMode,
  tableConfig,
  element,
  label,
  handleSaveElement,
  handleRemoveElement,
  dropdownContainerRef,
  options: externalOptions = {}
}) => {
  const options = {
    metricSelect: false,
    allowFixation: false,
    ...externalOptions
  };

  const isWeighted = tableConfig.config.weightResult ?? false;

  const [SmvCustomDropdown, , toggle] = useSmooveCustomDropdown();
  const hasFixation = useMemo(() => !!element.value?.fixed, [element.value?.fixed]);

  const metricIcon = useMemo(() => {
    switch (element.value?.metric) {
      case 'smvrslt_perc':
        return <i className={'far fa-solid fa-percent mr-2'}></i>;
      case 'smvrslt_abs':
        return <i className={'far fa-solid fa-tally mr-2'}></i>;
      case 'smvrslt_base':
      case 'smvrslt_base_weighted':
        return <i className={'far fa-solid fa-people-group mr-2'}></i>;
      case 'smvrslt_calculation':
        return <i className={'far fa-solid fa-calculator mr-2'}></i>;
      default:
        return null;
    }
  }, [element.value?.metric]);

  const handleMetricSelect = useCallback(
    (element, metric) => {
      handleSaveElement(
        produce(element, draft => {
          draft.value.metric = metric;
        })
      );
    },
    [handleSaveElement]
  );

  const handleMetricSelectFrequency = useCallback(() => handleMetricSelect(element, 'smvrslt_perc'), [
    handleMetricSelect,
    element
  ]);
  const handleMetricSelectAbsolute = useCallback(() => handleMetricSelect(element, 'smvrslt_abs'), [
    handleMetricSelect,
    element
  ]);
  const handleMetricSelectBase = useCallback(() => handleMetricSelect(element, 'smvrslt_base'), [
    handleMetricSelect,
    element
  ]);
  const handleMetricSelectBaseWeighted = useCallback(() => handleMetricSelect(element, 'smvrslt_base_weighted'), [
    handleMetricSelect,
    element
  ]);

  const handleFixedElementSelect = useCallback(
    reference => {
      handleSaveElement(
        produce(element, draft => {
          // unlock fixation
          if (isEqual(element.value?.fixed, reference)) delete draft.value.fixed;
          // set fixation
          else draft.value.fixed = reference;
        })
      );
      toggle(false);
    },
    [handleSaveElement, toggle, element]
  );

  return (
    <div className={'formula-builder__element'}>
      <div className='formula-builder__element-label'>
        {hasFixation && <i className='fa-regular fa-lock mr-2'></i>}
        {metricIcon}
        <span>{label}</span>
      </div>

      {!(options.metricSelect || options.allowFixation) && (
        <div className='formula-builder__element-delete-btn' onClick={() => handleRemoveElement(element.id)}>
          <i className='far fa-trash-alt fa-fw' />
        </div>
      )}

      {(options.metricSelect || options.allowFixation) && (
        <SmvCustomDropdown faIconString={'far fa-edit icon-smv-blue p-1'} container={dropdownContainerRef}>
          {options.metricSelect && (
            <>
              <DropdownItem header>Value Type</DropdownItem>
              <DropdownItem
                onClick={handleMetricSelectFrequency}
                className={classNames({
                  'dropdown-item--active': element.value?.metric === 'smvrslt_perc'
                })}
              >
                <div className={'smv-custom-dropdown__buttons'}>
                  <i className={'far fa-solid fa-percent mr-1'}></i>
                  <span>frequency</span>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={handleMetricSelectAbsolute}
                className={classNames({
                  'dropdown-item--active': element.value?.metric === 'smvrslt_abs'
                })}
              >
                <div
                  className={classNames('smv-custom-dropdown__buttons', {
                    'dropdown-item--active': element.value?.metric === 'smvrslt_abs'
                  })}
                >
                  <i className={'far fa-solid fa-tally mr-1'}></i>
                  <span>absolute</span>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={handleMetricSelectBase}
                className={classNames({
                  'dropdown-item--active': element.value?.metric === 'smvrslt_base'
                })}
              >
                <div
                  className={classNames('smv-custom-dropdown__buttons', {
                    'dropdown-item--active': element.value?.metric === 'smvrslt_base'
                  })}
                >
                  <i className={'far fa-solid fa-people-group mr-1'}></i>
                  <span>base</span>
                </div>
              </DropdownItem>
              {isWeighted && (
                <DropdownItem
                  onClick={handleMetricSelectBaseWeighted}
                  className={classNames({
                    'dropdown-item--active': element.value?.metric === 'smvrslt_base_weighted'
                  })}
                >
                  <div
                    className={classNames('smv-custom-dropdown__buttons', {
                      'dropdown-item--active': element.value?.metric === 'smvrslt_base_weighted'
                    })}
                  >
                    <i className={'far fa-solid fa-people-group mr-1'}></i>
                    <span>base weighted</span>
                  </div>
                </DropdownItem>
              )}
              <DropdownItem divider />
            </>
          )}

          {options.allowFixation && (
            <>
              <FormulaBuilderReferenceElementFixedReferenceSelect
                calculationId={calculationId}
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                element={element}
                handleFixedElementSelect={handleFixedElementSelect}
              />
              <DropdownItem divider />
            </>
          )}

          <DropdownItem className={'smv-custom-dropdown__buttons'} onClick={() => handleRemoveElement(element.id)}>
            <div className='smv-custom-dropdown__buttons'>
              <i className={'far fa-trash-alt fa-fw mr-1'} />
              <span>remove</span>
            </div>
          </DropdownItem>
        </SmvCustomDropdown>
      )}
    </div>
  );
};
