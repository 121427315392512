// permissionEnum and smooveRoles should be identical to smoove_nodejs_datastructures: src/models/Auth/Permission/PermissionEnum.ts
export const permissionEnum = {
  PROJECT_READ: 'PROJECT_READ',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CHANGE: 'PROJECT_CHANGE',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_ADMIN: 'PROJECT_ADMIN',

  CLIENT_READ: 'CLIENT_READ',
  CLIENT_CREATE: 'CLIENT_CREATE',
  CLIENT_CHANGE: 'CLIENT_CHANGE',
  CLIENT_DELETE: 'CLIENT_DELETE',
  CLIENT_ADMIN: 'CLIENT_ADMIN'
};

export const smooveRoles = {
  SMOOVE_GUEST: 'SMOOVE_GUEST',
  SMOOVE_USER: 'SMOOVE_USER',
  SMOOVE_ADMIN: 'SMOOVE_ADMIN'
};

// used to map role names to translations, icon and a roleWeight to be able to deactivate buttons of lower rights
export const rolesMap = [
  {
    name: 'client_moderator',
    translationId: 'smoove.page.administration.role.admin',
    icon: 'fa-user-crown',
    roleWeight: 40
  },
  {
    name: 'client_writer',
    translationId: 'smoove.page.administration.role.read-and-write',
    icon: 'fa-user',
    roleWeight: 30
  },
  {
    name: 'client_readonly',
    translationId: 'smoove.page.administration.role.read-only',
    icon: 'fa-user-lock',
    roleWeight: 20
  },
  {
    name: 'client_viewer',
    translationId: 'smoove.page.administration.role.undefined-role',
    icon: 'fa-user-slash',
    roleWeight: 10
  },
  {
    name: 'project_moderator',
    translationId: 'smoove.page.administration.role.admin',
    icon: 'fa-user-crown',
    roleWeight: 40
  },
  {
    name: 'project_writer',
    translationId: 'smoove.page.administration.role.read-and-write',
    icon: 'fa-user',
    roleWeight: 30
  },
  {
    name: 'project_readonly',
    translationId: 'smoove.page.administration.role.read-only',
    icon: 'fa-user-lock',
    roleWeight: 20
  },
  {
    name: 'no-role',
    translationId: 'smoove.page.administration.role.undefined-role',
    icon: 'fa-user-slash',
    roleWeight: 10
  },
  { name: null, translationId: 'smoove.page.administration.role.undefined-role', icon: 'fa-user-slash', roleWeight: 10 }
];

export const smooveRolesMap = {
  SMOOVE_ADMIN: { name: 'SMOOVE_ADMIN', translationId: 'smoove.page.administration.role.admin', icon: 'fa-user-crown' },
  SMOOVE_USER: {
    name: 'SMOOVE_USER',
    translationId: 'smoove.page.administration.role.read-and-write',
    icon: 'fa-user'
  },
  SMOOVE_GUEST: {
    name: 'SMOOVE_GUEST',
    translationId: 'smoove.page.administration.role.read-only',
    icon: 'fa-user-lock'
  }
};
