import { useCallback, useState } from 'react';
import { Collapse, ListGroupItem, ListGroupItemHeading } from 'reactstrap';

import './MediaItemsList.scss';

export const MediaItemsList = ({ readonly = false, handler, containerRef }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(state => !state), []);

  return (
    <ListGroupItem className={'reports-list '}>
      <ListGroupItemHeading className='pointer mb-0 py-2 list-item-sticky'>
        <div className='reports-list__headline'>
          <i onClick={toggleOpen} className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
          <span onClick={toggleOpen} className='ml-1 reports-list__headline-text'>
            Media & Text
          </span>
        </div>
      </ListGroupItemHeading>
      <Collapse isOpen={isOpen}>
        <div
          className={'ml-3 py-2 d-flex align-items-center reports-list__droppable-element'}
          draggable={true}
          unselectable='on'
          onDragStart={e => {
            e.dataTransfer.setData('text/plain', '');
            e.dataTransfer.setData('sourceType', 'media');
            e.dataTransfer.setData('mediaType', 'image');
          }}
        >
          <i className='fas fa-photo-film mr-1' />
          <span>Media</span>
        </div>
        <div
          className={'ml-3 py-2 d-flex align-items-center reports-list__droppable-element'}
          draggable={true}
          unselectable='on'
          onDragStart={e => {
            e.dataTransfer.setData('text/plain', '');
            e.dataTransfer.setData('sourceType', 'media');
            e.dataTransfer.setData('mediaType', 'text');
          }}
        >
          <i className='fas fa-text mr-1' />
          <span>Text</span>
        </div>
      </Collapse>
    </ListGroupItem>
  );
};
