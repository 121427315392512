import React, { useCallback, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';

import { getFixedColor } from 'src/smoove/components/dashboard/charts/helper';

export const DropdownItemColorPicker = ({
  handler,
  chartConfig,
  rowId = null,
  rowSubId = null,
  rowSubSubId = null,
  headId = null,
  headSubId = null
}) => {
  const cellIdentifier = useMemo(() => ({ rowId, rowSubId, rowSubSubId, headId, headSubId }), [
    rowId,
    rowSubId,
    rowSubSubId,
    headId,
    headSubId
  ]);
  const fixedColor = getFixedColor(chartConfig, cellIdentifier);

  const [colorHexValue, setColorHexValue] = useState(fixedColor ? fixedColor.hex : '#FFFFFF');
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const toggleColorPickerOpen = useCallback(() => setColorPickerOpen(b => !b), []);

  const handleChangeColor = color => {
    setColorHexValue(color.hex);
  };

  const handleSaveColor = useCallback(_color => handler.setTableElementColor(_color, cellIdentifier), [
    handler,
    cellIdentifier
  ]);

  const handleRemoveColor = useCallback(_color => handler.setTableElementColor(null, cellIdentifier), [
    handler,
    cellIdentifier
  ]);

  return (
    <>
      <DropdownItem onClick={toggleColorPickerOpen} toggle={false}>
        <div className='smv-custom-dropdown__buttons' id={'_colorpicker'}>
          <i className='fal fa-fill-drip mr-1' />
          <span>
            <FormattedMessage
              id={'smoove.page.survey.tables.options.color.active-color'}
              defaultMessage={'select color'}
            />
          </span>
          {fixedColor && (
            <span
              className='ml-1'
              style={{
                width: '16px',
                height: '16px',
                border: '1px solid black',
                borderRadius: '2px',
                backgroundColor: colorHexValue
              }}
            />
          )}
        </div>
      </DropdownItem>
      {colorPickerOpen && (
        <ChromePicker
          color={colorHexValue}
          disableAlpha={true}
          onChange={handleChangeColor}
          onChangeComplete={handleSaveColor}
        />
      )}
      {fixedColor && (
        <DropdownItem onClick={handleRemoveColor}>
          <div className='smv-custom-dropdown__buttons' id={'_colorpicker'}>
            <i className='fal fa-droplet-slash mr-1' />
            <span>
              <FormattedMessage
                id={'smoove.page.survey.tables.options.color.remove-color'}
                defaultMessage={'remove color'}
              />
            </span>
          </div>
        </DropdownItem>
      )}
    </>
  );
};
