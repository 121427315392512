import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import produce from 'immer';
import JoditEditor from 'jodit-react';
import { useSelector } from 'react-redux';
// import { ChromePicker } from 'react-color';

import { reportsActions } from 'smv-redux';

import { DashboardElementMenu } from '../DashboardElementMenu';

import './DashboardText.scss';

export const DashboardText = ({ element, reportid, pageid, readonly, handler }) => {
  const writeable = !readonly;
  const isLoading = element?.loading ?? false;

  const surveyid = useSelector(state => state.survey.id);

  const editorRef = useRef(null);
  const [isEditTextModalOpen, setIsEditTextModalOpen] = useState(false);
  const [content, setContent] = useState(element?.mediaConfig?.text ?? '');
  // eslint-disable-next-line
  const [backgroundColor, setBackgroundColor] = useState(element?.mediaConfig?.backgroundColor ?? '#ffffff');

  const chartConfig = element.chartConfig;

  const saveHandler = useCallback(() => {
    const _config = produce(element.mediaConfig, draft => {
      draft.text = content;
      draft.backgroundColor = backgroundColor;
    });

    const _element = produce(element, draft => {
      draft.mediaConfig = _config;
    });

    reportsActions.updateDashboardElement(surveyid, reportid, pageid, element.id, _element);
  }, [element, backgroundColor, content, pageid, reportid, surveyid]);

  const handleChange = useCallback(newContent => {
    setContent(newContent);
  }, []);

  // const handleChangeBackgroundColor = useCallback(color => {
  //   setBackgroundColor(color.hex);
  // }, []);

  const toggleEditTextModal = useCallback(() => {
    saveHandler();
    setIsEditTextModalOpen(b => !b);
  }, [saveHandler]);

  const exportOptions = useMemo(() => {
    return {
      pptx: true,
      image: false,
      xlsx: false
    };
  }, []);

  const exportHandler = useCallback(filetype => handler.exportElement(element.id, filetype), [handler, element.id]);

  const chartTitle = chartConfig?.title?.length > 0 ? chartConfig?.title : 'Element title';
  const chartSubtitle = chartConfig?.subTitle ?? null;

  const buttons = useMemo(() => {
    return ['bold', 'italic', 'underline', 'eraser', 'fontsize', 'brush', 'align'];
  }, []);

  const config = useMemo(() => {
    return {
      readonly: !writeable || isLoading, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: 'enter text...',
      buttons: buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      colorPickerDefaultTab: 'text',
      controls: {
        image: {
          openOnDblClick: false
        }
      },
      allowTabNavigation: true,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      showBrowserColorPicker: false,
      defaultActionOnPaste: 'insert_only_text', //"insert_as_html" | "insert_as_text" | "insert_clear_html" | "insert_only_text"
      statusbar: false,
      hidePoweredByJodit: true
    };
  }, [buttons, writeable, isLoading]);
  // const config = {buttons: ['bold', 'italic', 'underline']};

  if (!element) return <div>missing element</div>;

  return (
    <Card
      className={`h-100 w-100 smv-dashboard-element dashboard-element--text ${
        readonly ? 'smv-dashboard-element--readonly' : ''
      }`}
    >
      <CardHeader>
        <CardTitle tag='h5'>{chartTitle}</CardTitle>
        <CardSubtitle tag='h6' className='mb text-muted'>
          <small>
            <i>{chartSubtitle}</i>
          </small>
        </CardSubtitle>
        {!readonly && (
          <DashboardElementMenu
            handler={handler}
            exportHandler={exportHandler}
            exportOptions={exportOptions}
            element={element}
            toggleEditTextModal={toggleEditTextModal}
          />
        )}
      </CardHeader>
      <CardBody style={{ backgroundColor: backgroundColor }}>
        {element.loading && (
          <div className='smv-dashboard-element__loading-backdrop'>
            <i className='fas fa-spinner fa-spin' />
          </div>
        )}

        {<div dangerouslySetInnerHTML={{ __html: content }}></div>}
      </CardBody>
      <Modal isOpen={isEditTextModalOpen} className='dashboard-text-modal' toggle={toggleEditTextModal} size={'lg'}>
        <ModalHeader toggle={toggleEditTextModal}>Edit Text</ModalHeader>
        <ModalBody>
          <div className='dashboard-text-modal__jodit-container' style={{ backgroundColor: backgroundColor }}>
            <JoditEditor
              ref={editorRef}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={newContent => setContent(newContent)}
              onChange={newContent => handleChange(newContent)}
            />
          </div>
          {/* {!readonly && (
            <>
              <h5 className='mt-2'>Background Color</h5>
              <p>Change the background color of the dashboard element.</p>
              <ChromePicker
                color={backgroundColor}
                disableAlpha={true}
                onChangeComplete={handleChangeBackgroundColor}
              />
            </>
          )} */}
        </ModalBody>
        <ModalFooter>
          <Button className={`edit-text-btn mt-2 mb-2`} size={'sm'} outline={true} onClick={toggleEditTextModal}>
            Save & Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};
