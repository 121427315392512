import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classNames from 'classnames';

import { ReferenceValue } from './ReferenceValue';
import { ReferenceValuesDetailedMatrix } from '.';

export const ReferenceValues = ({
  conditionalFormat,
  dimensionKey,
  label,
  main,
  matrixType = null,
  values = { order: [], list: {} },
  scales = null,
  handleReferenceSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  const isSelected = useMemo(() => {
    if (dimensionKey === 'rows' && conditionalFormat.reference.rowId === main.id) return true;
    else if (dimensionKey === 'heads' && conditionalFormat.reference.headId === main.id) return true;
    else return false;
  }, [dimensionKey, conditionalFormat.reference, main]);

  return (
    <Dropdown
      tag={'div'}
      direction={'end'}
      group={true}
      className={classNames({
        'smv-custom-dropdown': true,
        'dropdown-item': true,
        'dropdown-item--active': isSelected
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag={'div'}
        className={classNames({
          'smv-custom-dropdown__buttons': true,
          'smv-custom-dropdown__dropdown-toggle': true,
          'smv-custom-dropdown__dropdown-toggle--flex': true
        })}
      >
        <span>{label}</span>
        <i className={'fa-thin fa-chevron-right'} />
      </DropdownToggle>
      <i className='fa-regular'></i>

      <DropdownMenu
        className={classNames({
          'smv-custom-dropdown__dropdown-menu': true,
          'smv-custom-dropdown__dropdown-menu--nested': isOpen
        })}
      >
        {values.order?.map(valueId => {
          const value = values.list[valueId];

          const _element = {
            ...(dimensionKey === 'rows' && { rowId: main.id }),
            ...(dimensionKey === 'rows' && { rowSubId: value.id }),
            ...(dimensionKey === 'heads' && { headId: main.id }),
            ...(dimensionKey === 'heads' && { headSubId: value.id })
          };

          if (!scales) {
            let isSelected = false;
            if (
              dimensionKey === 'rows' &&
              conditionalFormat.reference.rowId === main.id &&
              conditionalFormat.reference.rowSubId === value.id
            )
              isSelected = true;
            else if (
              dimensionKey === 'heads' &&
              conditionalFormat.reference.headId === main.id &&
              conditionalFormat.reference.headSubId === value.id
            )
              isSelected = true;

            return (
              <ReferenceValue
                key={`formula-builder-fixed-element-item-${main.id}-${value.id}`}
                element={_element}
                value={value}
                isSelected={isSelected}
                handleReferenceSelect={handleReferenceSelect}
              />
            );
          } else {
            return (
              <ReferenceValuesDetailedMatrix
                key={`formula-builder-fixed-element-item-${main.id}-${value.id}`}
                conditionalFormat={conditionalFormat}
                main={main}
                dimensionKey={dimensionKey}
                matrixType={matrixType}
                value={value}
                scales={scales}
                handleReferenceSelect={handleReferenceSelect}
              />
            );
          }
        })}

        {dimensionKey === 'rows' &&
          !scales &&
          Object.values(main.config?.calcFrequencyGroups ?? {}).map(calcGroup => {
            const _element = {
              rowId: main.id,
              rowSubId: calcGroup.id
            };

            let isSelected = false;
            if (
              conditionalFormat.reference.rowId === main.id &&
              conditionalFormat.reference.rowSubId === calcGroup.id
            ) {
              isSelected = true;
            }

            return (
              <ReferenceValue
                key={`formula-builder-fixed-element-item-${main.id}-${calcGroup.id}`}
                element={_element}
                value={calcGroup}
                isSelected={isSelected}
                handleReferenceSelect={handleReferenceSelect}
              />
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};
