import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Input,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  Badge,
  UncontrolledTooltip
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { isArray } from 'lodash';
import produce from 'immer';

import { chartSettings } from '../config';
import './ChartWordcloudConfig.scss';

export const ChartWordcloudConfig = ({ chartConfig, resultTableType, handler, tableResult }) => {
  const intl = useIntl();
  const [fontSizeMin, _setFontSizeMin] = useState(chartConfig?.wordcloud?.wordcloudFontSizes?.[0] ?? 10);
  const [fontSizeMax, _setFontSizeMax] = useState(chartConfig?.wordcloud?.wordcloudFontSizes?.[1] ?? 50);

  useEffect(() => {
    if (chartConfig?.wordcloud?.wordcloudFontSizes) {
      _setFontSizeMin(chartConfig?.wordcloud?.wordcloudFontSizes?.[0]);
      _setFontSizeMax(chartConfig?.wordcloud?.wordcloudFontSizes?.[1]);
    }
  }, [chartConfig?.wordcloud?.wordcloudFontSizes]);

  const setFontSizeMin = useCallback(e => _setFontSizeMin(parseInt(e?.target?.value ?? 10)), [_setFontSizeMin]);
  const setFontSizeMax = useCallback(e => _setFontSizeMax(parseInt(e?.target?.value ?? 50)), [_setFontSizeMax]);

  const [isWordcloudModalOpen, _setIsWordcloudModalOpen] = useState(false);

  const toggleWordcloudModal = useCallback(() => {
    _setIsWordcloudModalOpen(b => !b);
  }, []);

  const setFontSizes = useCallback(
    e => {
      const { name, value } = e?.target ?? {};
      let _value = parseInt(value);

      if (name === 'wordcloudFontSizesMin' && _value < 5) {
        _setFontSizeMin(5);
        _value = 5;
      }
      if (name === 'wordcloudFontSizesMax' && _value > 100) {
        _setFontSizeMax(100);
        _value = 100;
      }

      handler.setTableConfig(s =>
        produce(s, d => {
          if (!d.chart?.wordcloud)
            d.chart.wordcloud = {
              wordcloudFontSizes: null
            };
          if (!isArray(d.chart?.wordcloud?.wordcloudFontSizes)) d.chart.wordcloud.wordcloudFontSizes = [10, 50];
          if (name === 'wordcloudFontSizesMin') {
            d.chart.wordcloud.wordcloudFontSizes[0] = _value;
          } else if (name === 'wordcloudFontSizesMax') {
            d.chart.wordcloud.wordcloudFontSizes[1] = _value;
          }
        })
      );
    },
    [handler]
  );

  // const handleWordcloudBlacklistRemove = useCallback(
  //   word => {
  //     handler.setTableConfig(state =>
  //       produce(state, draftState => {
  //         draftState.chart.wordcloud.blacklist = draftState.chart.wordcloud.blacklist.filter(_word => _word !== word);
  //       })
  //     );
  //   },
  //   [handler]
  // );

  const chartTypeSettings = useMemo(() => chartSettings?.[resultTableType]?.[chartConfig?.chartType] ?? {}, [
    resultTableType,
    chartConfig?.chartType
  ]);

  if (chartConfig?.chartType !== 'wordcloud') return null;

  return (
    <ListGroupItem className='wordcloud-config'>
      <ListGroupItemHeading>
        {intl.formatMessage({ id: `smoove.page.tables.chart-config.wordcloud.title` })}
      </ListGroupItemHeading>

      {chartTypeSettings?.wordcloudFontSizes && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.wordcloud.font-sizes'}
              defaultMessage={'Font Sizes'}
            />
          </Col>
          <Col md={3} className={'pr-1'}>
            <Input
              type='number'
              step={1}
              min={5}
              max={(chartConfig?.wordcloud?.wordcloudFontSizes?.[1] ?? 50) - 1}
              id={`wordcloudFontSizesMin`}
              name='wordcloudFontSizesMin'
              value={fontSizeMin}
              placeholder={10}
              onChange={setFontSizeMin}
              onBlur={setFontSizes}
            />
          </Col>
          <Col md={3} className={'px-1'}>
            <Input
              type='number'
              step={1}
              min={(chartConfig?.wordcloud?.wordcloudFontSizes?.[0] ?? 10) + 1}
              max={100}
              id={`wordcloudFontSizesMax`}
              name='wordcloudFontSizesMax'
              value={fontSizeMax}
              placeholder={50}
              onChange={setFontSizeMax}
              onBlur={setFontSizes}
            />
          </Col>
        </Row>
      )}
      <Row className='mt-1 align-items-center'>
        <Col md={12}>
          <span className='text-smv-secondary pointer fw-bold' onClick={toggleWordcloudModal}>
            {intl.formatMessage({ id: 'smoove.page.tables.chart-config.blacklist' })}
          </span>
          <WordcloudSettingsModal
            isOpen={isWordcloudModalOpen}
            toggle={toggleWordcloudModal}
            chartConfig={chartConfig}
            handler={handler}
            tableResult={tableResult}
          />
          {/* {intl.formatMessage({
            id: 'smoove.page.tables.chart-config.wordcloud.blacklist.blacklisted-words',
            defaultMessage: 'Blacklisted Words:'
          })} */}
        </Col>
        {/* <Col md={12}>
          {(chartConfig?.wordcloud?.blacklist ?? []).map(word => (
            <Button outline key={word}>
              <span>{word}</span>{' '}
              <i className='fa-regular fa-circle-xmark' onClick={() => handleWordcloudBlacklistRemove(word)} />
            </Button>
          ))}
        </Col> */}
      </Row>
    </ListGroupItem>
  );
};

const WordcloudSettingsModal = ({ isOpen, toggle, chartConfig, handler, tableResult }) => {
  const intl = useIntl();

  const [sortBy, setSortBy] = useState('key_asc'); //can be: key_asc, key_desc, count_asc, count_desc

  const handleWordcloudBlacklistRemove = useCallback(
    word => {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.wordcloud.blacklist = draftState.chart.wordcloud.blacklist.filter(_word => _word !== word);
        })
      );
    },
    [handler]
  );

  const handleAddToBlacklist = useCallback(
    word => {
      if (!handler) return false;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (!draftState.chart?.wordcloud?.blacklist)
            draftState.chart.wordcloud = {
              ...state.chart.wordcloud,
              blacklist: []
            };
          draftState.chart.wordcloud.blacklist.push(word);
        })
      );
    },
    [handler]
  );

  const blacklist = useMemo(() => {
    if (isArray(chartConfig?.wordcloud?.blacklist)) {
      return [...chartConfig?.wordcloud?.blacklist].sort((a, b) =>
        a.toString().localeCompare(b.toString(), undefined, { numeric: true, sensitivity: 'base' })
      );
    } else {
      return [];
    }
  }, [chartConfig?.wordcloud?.blacklist]);

  const allWordsList = useMemo(() => {
    if (!tableResult || !isOpen) {
      return null;
    }
    const rowid = Object.keys(tableResult.values)[0];

    let _wrds = tableResult.order
      .filter(el => el.key !== '__input_raw__')
      .filter(el => !el.excluded)
      .map(el => {
        return tableResult.values[rowid][el.key]['__total__']['__total__'];
      });

    const sortByKey = words => {
      return [...words].sort((a, b) => {
        if (sortBy === 'key_desc') {
          return b.key.toLowerCase().localeCompare(a.key.toLowerCase());
        }
        return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
      });
    };

    const sortByCount = words => {
      if (sortBy === 'count_desc') {
        return [...words].sort((a, b) => b.smvrslt_abs - a.smvrslt_abs);
      }
      return [...words].sort((a, b) => a.smvrslt_abs - b.smvrslt_abs);
    };

    switch (sortBy) {
      case 'key_asc':
      case 'key_desc':
        _wrds = sortByKey(_wrds);
        break;
      case 'count_asc':
      case 'count_desc':
        _wrds = sortByCount(_wrds);
        break;
      default:
        break;
    }

    return (
      <div className='mt-2 blacklist-table-container'>
        <Table striped size='sm' className='blacklist-table'>
          <thead className='sticky-table-head'>
            <tr>
              <th onClick={() => setSortBy(sortBy === 'key_asc' ? 'key_desc' : 'key_asc')} className='pointer'>
                {sortBy === 'key_asc' && <i className='fal fa-arrow-down mr-2' />}
                {sortBy === 'key_desc' && <i className='fal fa-arrow-up mr-2' />}
                <span>{intl.formatMessage({ id: 'smoove.common.strings.word' })}</span>
              </th>
              <th onClick={() => setSortBy(sortBy === 'count_asc' ? 'count_desc' : 'count_asc')} className='pointer'>
                {sortBy === 'count_asc' && <i className='fal fa-arrow-down mr-2' />}
                {sortBy === 'count_desc' && <i className='fal fa-arrow-up mr-2' />}
                <span>{intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.count' })}</span>
              </th>
              <th style={{ textAlign: 'right' }}>
                {intl.formatMessage({ id: 'smoove.page.tables.chart-config.blacklist' })}
              </th>
            </tr>
          </thead>
          <tbody className='blacklist-table-content'>
            {_wrds.map((word, idx) => {
              const isInBlacklist = blacklist.includes(word.key);
              const wordCount = tableResult.values[rowid][word.key]['__total__']['__total__'].smvrslt_abs;

              if (isInBlacklist) {
                return (
                  <tr key={word.key}>
                    <td style={{ textDecoration: 'line-through' }}>{word.key}</td>
                    <td style={{ textDecoration: 'line-through' }}>{wordCount}</td>
                    <td className='text-right pr-2 trash-btn'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <small className='mr-2 text-danger'>
                          <Badge color={'danger'}>
                            {intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.banned' })}
                          </Badge>
                        </small>
                        <i
                          onClick={() => handleWordcloudBlacklistRemove(word.key)}
                          className='fal fa-eye-slash pointer text-secondary'
                          id={`remove-from-blacklist-${idx}`}
                        />
                        <UncontrolledTooltip
                          target={`remove-from-blacklist-${idx}`}
                          delay={{ hide: 0 }}
                          autohide={false}
                        >
                          {intl.formatMessage({
                            id: 'smoove.page.tables.chart-config.wordcloud.remove-from-blacklist'
                          })}
                        </UncontrolledTooltip>
                      </div>
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={word.key}>
                  <td>{word.key}</td>
                  <td>{wordCount}</td>
                  <td className='text-right pr-2 trash-btn'>
                    <i
                      onClick={() => handleAddToBlacklist(word.key)}
                      className='fal fa-eye pointer text-secondary'
                      id={`add-to-blacklist-${idx}`}
                    />
                    <UncontrolledTooltip target={`add-to-blacklist-${idx}`} delay={{ hide: 0 }} autohide={false}>
                      {intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.add-to-blacklist' })}
                    </UncontrolledTooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }, [blacklist, handleAddToBlacklist, handleWordcloudBlacklistRemove, tableResult, sortBy, intl, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size={'xl'} className='wordcloud-settings-modal'>
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.blacklist-settings' })}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p className='mb-3'>
              {intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.blacklist-info-text' })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h5>{intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.all-words' })}</h5>
            {allWordsList}
          </Col>
          <Col>
            <h5>{intl.formatMessage({ id: 'smoove.page.tables.chart-config.blacklist' })}</h5>
            <div className='mt-2 blacklist-table-container blacklist-table-container'>
              <Table striped size='sm' className='blacklist-table'>
                <tbody>
                  {blacklist.map((word, idx) => (
                    <tr key={word}>
                      <td>{word}</td>
                      <td className='text-right pr-2 trash-btn'>
                        <div className='d-flex align-items-center justify-content-end'>
                          <small className='mr-2 text-danger'>
                            <Badge color={'danger'}>
                              {intl.formatMessage({ id: 'smoove.page.tables.chart-config.wordcloud.banned' })}
                            </Badge>
                          </small>
                          <i
                            onClick={() => handleWordcloudBlacklistRemove(word)}
                            className='fal fa-eye-slash pointer text-secondary'
                            id={`edit-text-${idx}`}
                          />
                          <UncontrolledTooltip target={`edit-text-${idx}`} delay={{ hide: 0 }} autohide={false}>
                            {intl.formatMessage({
                              id: 'smoove.page.tables.chart-config.wordcloud.remove-from-blacklist'
                            })}
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={false} onClick={toggle}>
          <FormattedMessage id={'smoove.common.buttons.close'} defaultMessage={'Close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
