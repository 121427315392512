import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { reorderArray, useSubelementPropertyTranslation } from 'smv-helpers';

import './RankingMatrixPreview.scss';

export const RankingMatrixPreview = ({ question }) => {
  const itemOrder = question?.config?.choices?.order ?? [];
  const list = useSelector(state => state.survey.questionnaire.list);

  const [selectedItems, setSelectedItems] = useState([]);

  const rankingType = question.config.rankingType ?? 'click';

  const maximumNumberOfResponses = 3;

  const handleSelectItem = useCallback(
    id => {
      let _selected = [...selectedItems];
      if (_selected.map(el => el.id).includes(id)) {
        _selected = _selected.filter(el => el.id !== id);
      } else {
        if (selectedItems.length < maximumNumberOfResponses) {
          const _occupiedPositions = _selected.map(el => el.position);
          const presenceArray = new Array(maximumNumberOfResponses + 1).fill(false);
          _occupiedPositions.forEach(num => (presenceArray[num] = true));
          const missingNumber = presenceArray.findIndex((present, index) => index > 0 && !present);
          _selected.push({
            id: id,
            position: missingNumber
          });
        }
      }
      setSelectedItems(_selected);
    },
    [selectedItems]
  );

  const importedChoices = useMemo(() => {
    const importedSource = question.config?.choices?.import?.source;
    const sourceQuestion = list?.[importedSource];
    let _importedChoices = [];

    if (!sourceQuestion || !importedSource) return null;

    if (sourceQuestion.config?.choices?.order && sourceQuestion.config.choices.order.length > 0) {
      sourceQuestion.config.choices.order.forEach(choiceid => {
        _importedChoices.push(
          <RankingMatrixChoice
            key={choiceid}
            choiceid={choiceid}
            question={question}
            importedSourceQuestion={sourceQuestion}
            selectedItems={selectedItems}
            handleSelectItem={handleSelectItem}
          />
        );
      });
    }
    return _importedChoices;
  }, [list, question, selectedItems, handleSelectItem]);

  const onDragEnd = useCallback(
    dragEl => {
      if (!dragEl.destination) {
        return;
      }

      let _selected = [...selectedItems];

      // sort selected list
      if (dragEl.destination?.droppableId === dragEl.source?.droppableId) {
        if (dragEl.destination?.droppableId === 'ranking-matrix-unselected-droppable') {
          return null;
        } else {
          const items = reorderArray(_selected, dragEl.source.index, dragEl.destination.index);
          setSelectedItems(items);
          return;
        }
      }

      if (dragEl.source.droppableId === 'ranking-matrix-selected-droppable') {
        _selected = _selected.filter(el => el.id !== dragEl.draggableId);
      } else {
        // add elements to correct position in selected elements list
        let _itemIds = _selected.map(el => el.id);
        _itemIds = _itemIds.toSpliced(dragEl.destination.index, 0, dragEl.draggableId);
        _selected = [];
        _itemIds.forEach((el, idx) => {
          _selected.push({
            id: el,
            position: idx
          });
        });
      }
      setSelectedItems(_selected);
    },
    [selectedItems]
  );

  if (rankingType === 'drag') {
    const importedSource = question.config?.choices?.import?.source;
    const sourceQuestion = list?.[importedSource];
    let allChoiceIds = [...(sourceQuestion?.config?.choices?.order ?? []), ...question.config.choices.order];
    const _unselectedIds = [];
    const selectedIds = selectedItems.map(el => el.id);

    allChoiceIds.forEach(id => {
      if (!selectedIds.includes(id)) {
        _unselectedIds.push(id);
      }
    });

    return (
      <div className='ranking-matrix-preview ranking-matrix-preview--drag'>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='ranking-matrix-dnd-container'>
            <div className='ranking-matrix-dnd-container__droppable-column'>
              <MakeDroppable
                droppableId='ranking-matrix-unselected-droppable'
                className={'ranking-droppable-unselected'}
              >
                {_unselectedIds.map((id, idx) => {
                  return (
                    <RankingMatrixChoice
                      key={id}
                      choiceid={id}
                      question={question}
                      importedSourceQuestion={sourceQuestion}
                      selectedItems={selectedItems}
                      handleSelectItem={handleSelectItem}
                      index={idx}
                    />
                  );
                })}
              </MakeDroppable>
            </div>
            <div className='ranking-matrix-dnd-container__arrow-column'>
              <div className='text-center'>
                <i className='fas fa-arrow-right fa-4x' />
              </div>
            </div>
            <div className='ranking-matrix-dnd-container__droppable-column'>
              <MakeDroppable droppableId='ranking-matrix-selected-droppable' className={'ranking-droppable-selected'}>
                {selectedItems.map((el, index) => {
                  return (
                    <RankingMatrixChoice
                      key={el.id}
                      choiceid={el.id}
                      question={question}
                      selectedItems={selectedItems}
                      handleSelectItem={handleSelectItem}
                      index={index}
                      importedSourceQuestion={sourceQuestion}
                    />
                  );
                })}
              </MakeDroppable>
            </div>
          </div>
        </DragDropContext>
      </div>
    );
  }

  return (
    <div className='ranking-matrix-preview ranking-matrix-preview--click'>
      {importedChoices}
      {itemOrder?.map((id, index) => {
        return (
          <RankingMatrixChoice
            key={id}
            choiceid={id}
            question={question}
            selectedItems={selectedItems}
            handleSelectItem={handleSelectItem}
            index={index}
          />
        );
      })}
    </div>
  );
};

const RankingMatrixChoice = ({
  choiceid,
  question,
  importedSourceQuestion,
  selectedItems,
  handleSelectItem,
  index
}) => {
  let choice = question.config?.choices?.list?.[choiceid];

  const indentation = question.config.indentation ?? 'left';
  const textAlignment = question.config.alignment ?? 'center'; //alignment left center right
  const rankingType = question.config.rankingType ?? 'click';

  if (importedSourceQuestion && importedSourceQuestion.config?.choices?.list?.[choiceid]) {
    choice = importedSourceQuestion.config?.choices?.list?.[choiceid];
  }

  const _selectedElement = selectedItems.find(el => el.id === choiceid);

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: false,
    useFallback: true,
    useShortcodes: false
  });

  if (!choice) {
    return null;
  }

  const choiceClasses = classNames({
    'matrix-ranking-preview__item': true,
    'matrix-ranking-preview__item--selected': rankingType === 'click' && _selectedElement,
    'matrix-ranking-preview__item--indent-right': rankingType === 'click' && indentation === 'right',
    'text-center': textAlignment === 'center',
    'text-right': textAlignment === 'right'
  });

  if (rankingType === 'drag') {
    return (
      <MakeDraggable key={`${choiceid}_${index}`} draggableId={choiceid} index={index}>
        <div className={choiceClasses}>
          <span className='choice__label'>
            <div dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
          </span>
        </div>
      </MakeDraggable>
    );
  }

  return (
    <div className={choiceClasses} onClick={() => handleSelectItem(choiceid)}>
      {_selectedElement && <span className={`choice__rank`}>{_selectedElement.position}</span>}
      <span className='choice__label'>
        <div dangerouslySetInnerHTML={{ __html: choiceLabel }}></div>
      </span>
    </div>
  );
};

const MakeDraggable = ({ children, draggableId, index }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // margin: '4px',
    // background: isDragging ? "#8aa91b" : "#e5e5e5",
    // styles we need to apply on draggables
    ...draggableStyle
  });
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} type='custom type'>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // style={{
            //   ...provided.draggableProps.style,
            //   transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)'
            // }}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {children}
          </div>
          {/* {snapshot.isDragging && <div style={{ transform: 'none !important' }}>{children}</div>} */}
        </>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId, className }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={false}>
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`droppable-default ${className}`}
          style={{ minHeight: '200px' }}
        >
          {children}
          <div className='d-none'>{provided.placeholder}</div>
          {/* {provided.placeholder} */}
        </div>
      )}
    </Droppable>
  );
};
