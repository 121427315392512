import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import produce from 'immer';

import { useRowOrder, stripHtml } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from './helper';
import {
  DefaultQuestionMenu,
  MultipleVectorChoiceMenu,
  MultipleVectorQuestionMenu,
  SingleVectorChoiceMenu
} from './menus';
import { QuestionSelectionDetails, ResultRow, ResultRowHeader } from '.';

export const MatrixCompactResultRow = ({ element, table, tableResult, row, questionHas, handler, hover }) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { rows, config } = table;
  const { showHiddenRows = true } = config;
  const { id, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row, showHiddenRows);

  const defaultRows = useMemo(
    () =>
      (table?.rows?.order ?? []).filter(rowid =>
        ['question', 'sysvar', 'calcvar'].includes(table.rows.list[rowid].sourceType)
      ),
    [table?.rows]
  );

  const defaultMetric = useDefaultMetric(table);

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};
  let subelements = element?.config?.scales ?? {};

  const heads = produce(rows, draft => {
    draft.order = draft.order.filter(rowId => {
      const row = draft.list[rowId];
      if (row.sourceType !== 'question') {
        delete draft.list[rowId];
        return false;
      } else {
        return true;
      }
    });

    if (table.heads.order.length > 0) {
      table.heads.order.forEach(headId => {
        const head = table.heads.list[headId];
        if (head.sourceType === 'calculation') {
          draft.order.push(headId);
          draft.list[headId] = head;
        }
      });
    }
  });

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const rowHeadLabel = stripHtml(element.title);

  let headMappingTable = {};

  if (tableResult?.tableStruct === 'matrix_compact_merged' && defaultRows.length > 1) {
    const valueIdMap = {};
    defaultRows.forEach((rowId, rowidx) => {
      const { sourceId, config } = table.rows.list[rowId];
      const sourceElement = list?.[sourceId];
      const _choices = getMergedChoices(sourceElement, sourceElement.config?.choices, list);

      _choices.order.forEach(choiceid => {
        const { value } = _choices.list[choiceid];

        if (rowidx === 0) {
          headMappingTable[choiceid] = {
            id: choiceid,
            rowid: rowId,
            ids: [choiceid],
            idmap: { [rowId]: choiceid },
            value: value,
            hidden: config?.hidden?.[choiceid] === true
          };
          valueIdMap[value] = choiceid;
        } else {
          const mainid = valueIdMap[value];
          headMappingTable[mainid].ids.push(choiceid);
          headMappingTable[mainid].idmap[rowId] = choiceid;
        }
      });
    });
  }

  let questionMenu = null;
  if (element.config.qtype === 'single_matrix') {
    questionMenu = (
      <DefaultQuestionMenu
        rowid={id}
        row={row}
        element={element}
        subelements={subelements}
        handler={handler}
        questionHas={questionHas}
        table={table}
      />
    );
  } else if (element.config.qtype === 'multiple_matrix') {
    questionMenu = (
      <MultipleVectorQuestionMenu
        rowid={id}
        row={row}
        element={element}
        subelements={subelements}
        handler={handler}
        questionHas={questionHas}
        table={table}
      />
    );
  }

  const _rows = [];
  rowOrder.forEach(order => {
    // skip, if current tableResult still contains the results of a detailed matrix (from which the head has been removed)
    if (tableResult.tableStruct === 'matrix_detail') return;

    let metric = defaultMetric;
    if (order.value === 'mean') metric = 'smvrslt_mean';

    const isHiddenScale = order?.hidden ?? false;
    const isExcludedScale = order?.excluded ?? false;

    if (!showHiddenRows && (isHiddenScale || isExcludedScale)) return null;

    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;
    let choiceMenu = null;

    let subelement = {};
    if (calcFrequencyGroups?.[order?.subelement]) {
      subelement = calcFrequencyGroups[order.subelement];
    } else if (subelements.list?.[order?.subelement]) {
      subelement = subelements.list[order.subelement];
    }

    if (element.config.qtype === 'single_matrix' && order.value !== 'mean') {
      choiceMenu = (
        <SingleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHiddenScale}
          isExcluded={isExcludedScale}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    } else if (element.config.qtype === 'multiple_matrix' && order.value !== 'mean') {
      choiceMenu = (
        <MultipleVectorChoiceMenu
          handler={handler}
          table={table}
          rowid={id}
          choiceid={order.subelement}
          scaleid={order?.subsubelement ?? null}
          isHidden={isHiddenScale}
          isExcluded={isExcludedScale}
          sortationPinned={sortationPinned}
          subelements={subelements}
        />
      );
    }

    _rows.push(
      <ResultRow
        key={`result-row-compact-${order.rowid}_${order.subelement}`}
        rowid={id}
        orderItem={order}
        subelement={subelement}
        choiceid={order.subelement}
        list={list}
        config={config}
        heads={heads}
        tableResult={tableResult}
        handler={handler}
        metric={metric}
        isHidden={isHiddenScale}
        isExcluded={isExcludedScale}
        sortationPinned={sortationPinned}
        isCompactMatrix={true}
        matrixType={'compact'}
        headMappingTable={headMappingTable}
        menu={choiceMenu}
        hover={hover}
      >
        {_rows.length === 0 && (
          <ResultRowHeader
            rowid={id}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            handler={handler}
            questionHas={questionHas}
            menu={questionMenu}
            questionSelectionDetails={
              <QuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
            }
          />
        )}
      </ResultRow>
    );
  });

  return _rows;
};
