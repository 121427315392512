import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSubelementPropertyTranslation } from 'smv-helpers';
import { EditableTextHover } from '../..';
import { ChoiceConfigContainer } from '.';
import { translationsActions } from 'smv-redux/actions';

import './Choice.scss';

export const Choice = ({ question, choice, writeable }) => {
  const survey = useSelector(state => state.survey);
  const locale =
    useSelector(state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main) ||
    'en-US';

  const surveyId = survey?.id;

  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useStripped: !writeable,
    useFallback: !writeable,
    useShortcodes: false
  });

  const updateLabel = useCallback(
    value => {
      if (value !== choiceLabel)
        translationsActions.updateTranslation(surveyId, choice.config.translations.label, {
          [locale]: value
        });
    },
    [choiceLabel, surveyId, locale, choice.config.translations.label]
  );

  switch (question.config.qtype) {
    case 'single_vector':
      return (
        <div className='question-choice'>
          <i className='fal qtype fa-circle fa-fw' />
          <EditableTextHover
            value={choiceLabel}
            writeable={writeable}
            replacing={true}
            onBlur={updateLabel}
            className={'no-padding'}
          />
          <ChoiceConfigContainer
            choice={choice}
            qtype={question.config.qtype}
            question={question}
            writeable={writeable}
          />
        </div>
      );

    case 'multiple_vector':
      return (
        <div className='question-choice'>
          <i className='fal qtype fa-square fa-fw' />
          <EditableTextHover
            value={choiceLabel}
            writeable={writeable}
            replacing={true}
            onBlur={updateLabel}
            className={'no-padding'}
          />
          <ChoiceConfigContainer
            choice={choice}
            qtype={question.config.qtype}
            question={question}
            writeable={writeable}
          />
        </div>
      );

    case 'multiple_matrix':
    case 'single_matrix':
      return (
        <div className='question-choice'>
          <i className={'fas qtype fa-grip-dots-vertical fa-fw'} />
          <EditableTextHover
            value={choiceLabel}
            writeable={writeable}
            replacing={true}
            onBlur={updateLabel}
            className={'no-padding'}
          />
          <ChoiceConfigContainer
            choice={choice}
            qtype={question.config.qtype}
            question={question}
            writeable={writeable}
          />
        </div>
      );

    default:
      return <div>question type unknown</div>;
  }
};

Choice.propTypes = {
  question: PropTypes.object.isRequired,
  choice: PropTypes.object.isRequired,
  writeable: PropTypes.bool.isRequired
};
