import classNames from 'classnames';

import { FormulaBuilderReferenceElement } from '.';

export const FormulaBuilderReferenceTotalElement = ({ element, tableConfig, ...rest }) => {
  const label = 'Total';

  const classes = classNames({
    'formula-builder__element': true
  });

  return (
    <FormulaBuilderReferenceElement
      tableConfig={tableConfig}
      element={element}
      label={label}
      classes={classes}
      options={{ metricSelect: true, allowFixation: true }}
      {...rest}
    />
  );
};
