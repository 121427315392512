import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.bubble.css';
import './EditableText.scss';

const defaultModules = {
  toolbar: [['bold', 'italic', 'underline', 'strike'], ['clean']],
  history: {
    userOnly: true
  },
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function (range, context) {
          return true;
        }
      }
    }
  }
};
const formats = ['bold', 'italic', 'underline', 'strike'];

export const EditableTextHover = ({
  value = '',
  onBlur,
  writeable = true,
  placeholder = '',
  modules = defaultModules,
  preserveWhitespace = false,
  className,
  replacing = false
}) => {
  const { quillRef, quill } = useQuill({
    modules,
    formats,
    theme: 'bubble',
    placeholder,
    preserveWhitespace,
    readOnly: !writeable
  });

  useEffect(() => {
    if (quill && className) {
      quill.root.classList.add(className.split(' '));
    }
  }, [quill, className]);

  useEffect(() => {
    if (quill && value !== quill.root.innerHTML) {
      const convertedValue = quill.clipboard.convert(value);
      quill.setContents(convertedValue);
    }
  }, [quill, value]);

  useEffect(() => {
    if (writeable && quill) {
      const handleBlur = (range, oldRange, source) => {
        // range = null: indicates selection loss = focus loss of editor.
        if (oldRange && range === null && source === 'user' && onBlur) {
          let text = quill.root.innerHTML;

          if (replacing) {
            // the order of replacements is important!
            text = text.replace(/<p><br(?:\/)?><\/p>/gi, '<br/><br/>');
            text = text.replace(/<\/p><p>/gi, '<br/>');
            text = text.replace(/<p>/gi, '<span>');
            text = text.replace(/<\/p>/gi, '</span>');
          }
          onBlur(text);
        }
        // }

        // hide tooltip on focus
        if (range === null) {
          quill.theme.tooltip.hide();
        }
      };

      //selection-change is emitted when a user|prog causes the selection to change, with a range representing the selection boundaries
      quill.on('selection-change', handleBlur);

      return () => {
        quill.off('selection-change', handleBlur);
      };
    }
  }, [quill, writeable, onBlur, replacing]);

  useEffect(() => {
    if (!quill) return;

    if (writeable) {
      quill.enable();
    } else {
      quill.disable();
    }
  }, [quill, writeable]);

  useEffect(() => {
    if (!quill) return;
    quill.root.setAttribute('data-placeholder', placeholder);
  }, [quill, placeholder]);

  return <div ref={quillRef} className='editabletext smoove-ql' />;
};

EditableTextHover.propTypes = {
  onBlur: PropTypes.func,
  value: PropTypes.string
};
