import React from 'react';

import { QuestionText } from '../components';
import { FormattedMessage } from 'react-intl';

export const MultipleInputNumber = ({ question, writeable }) => {
  const { inputCount = 1, min = 0, max = 0 } = question?.config ?? {};

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <input
        className={'form-control que-input-field'}
        type={'number'}
        readOnly={true}
        placeholder={'numeric input'}
        min={min ? min : undefined}
        max={max}
        disabled={true}
      />

      {inputCount > 1 && (
        <span className={'small font-italic'}>
          <FormattedMessage
            id={`smoove.questionnaire.modal.config-tab.displayed-x-times`}
            defaultMessage={`Displayed ${inputCount} times `}
            values={{ cnt: inputCount }}
          />
        </span>
      )}
    </>
  );
};
