import { useCallback } from 'react';
import { Col, Input, ListGroupItem, ListGroupItemHeading, Row, Label, FormGroup } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import produce from 'immer';

export const GeneralSettings = ({ tableConfig, handler }) => {
  const intl = useIntl();

  const toggleShowTotal = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.showTotal = !draftState.config.showTotal;
        })
      ),
    [handler]
  );

  const toggleShowCodeValuesHead = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.showCodeValuesHead = !draftState?.config?.showCodeValuesHead ?? false;
        })
      ),
    [handler]
  );

  const toggleShowCodeValuesRow = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.showCodeValuesRow = !draftState?.config?.showCodeValuesRow ?? false;
        })
      ),
    [handler]
  );

  const toggleShowHiddenColumns = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.showHiddenColumns = !(draftState?.config?.showHiddenColumns ?? true);
        })
      ),
    [handler]
  );

  const toggleShowHiddenRows = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.showHiddenRows = !(draftState?.config?.showHiddenRows ?? true);
        })
      ),
    [handler]
  );

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.table-config.general-settings'} defaultMessage={'General settings'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.display-total` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={tableConfig?.config?.showTotal}
              onChange={toggleShowTotal}
              name='showTotal'
              id={`showTotal`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.table-config.display-code-values'}
            defaultMessage={'Display code values'}
          />
        </Col>
        <Col md={7}>
          <Row>
            <Col>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`showCodeValuesHead`}
                  name='showCodeValuesHead'
                  checked={tableConfig.config?.showCodeValuesHead ?? false}
                  onChange={toggleShowCodeValuesHead}
                />
                <Label for={'showCodeValuesHead'}>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.in-head` })}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`showCodeValuesRow`}
                  name='showCodeValuesRow'
                  checked={tableConfig.config?.showCodeValuesRow ?? false}
                  onChange={toggleShowCodeValuesRow}
                />
                <Label for={'showCodeValuesRow'}>
                  {intl.formatMessage({ id: `smoove.page.tables.table-config.in-row` })}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.display-hidden-columns` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`showHiddenColumns`}
              name='showHiddenColumns'
              checked={tableConfig.config?.showHiddenColumns ?? true}
              onChange={toggleShowHiddenColumns}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.display-hidden-rows` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`showHiddenRows`}
              name='showHiddenRows'
              checked={tableConfig.config?.showHiddenRows ?? true}
              onChange={toggleShowHiddenRows}
            />
          </FormGroup>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
