import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useMatrixType } from 'smv-helpers';

import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { getHighestHeadBase } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const useChartBases = ({ table, tableResult, isCompactMatrix }) => {
  const questionVariables = useSelector(state => state.survey.questionnaire ?? { list: {}, order: [] });
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });
  const calculationVariables = useSelector(state => state.survey?.data?.calculations ?? {});
  const { isDetailedMatrix } = useMatrixType(table);

  if (!tableResult || !tableResult?.values) return '-';
  const bases = {};

  if (!isCompactMatrix && table.config.showTotal === true) {
    bases.__total__ = {
      __total__: getColumnBases(table, tableResult, { headid: '__total__', headsubid: '__total__' })
    };
  }

  if (isCompactMatrix) {
    table?.rows?.order?.forEach(headid => {
      const { sourceType, sourceId } = table.rows.list?.[headid];
      const config = table.rows.list?.[headid].config ?? {
        sortation: {},
        excluded: {},
        hidden: {}
      };

      /** @todo: refactor hidden heads to use headOrder */
      const { hidden = {}, sortation = {} } = config;
      if (sourceType === 'calculation') return;

      bases[headid] = {};
      const sourceElement = questionVariables.list?.[sourceId];
      const sourceElementValues = getMergedChoices(
        sourceElement,
        sourceElement.config?.choices,
        questionVariables.list
      );

      if (sortation?.headSortationMode === 'manual' && !!sortation?.manualHeadSortation?.order) {
        const combinedHeadOrder = getCombinedHeadOrder(
          tableResult?.headOrder?.[headid],
          sortation?.manualHeadSortation?.order
        );
        combinedHeadOrder.forEach(valueid => {
          if (hidden?.[valueid] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, valueid, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][valueid] = getColumnBases(table, tableResult, { headid, headsubid: valueid });
        });
      } else if (sortation?.headSortationMode === 'automatic' && !!tableResult?.headOrder?.[headid]?.length > 0) {
        tableResult?.headOrder?.[headid]?.forEach(orderItem => {
          if (hidden?.[orderItem.headSubId] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][orderItem.headSubId] = getColumnBases(table, tableResult, {
            headid,
            headsubid: orderItem.headSubId
          });
        });
      } else {
        sourceElementValues.order.forEach(valueid => {
          if (hidden?.[valueid] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, valueid, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][valueid] = getColumnBases(table, tableResult, { headid, headsubid: valueid });
        });
      }
    });
  } else {
    table?.heads?.order?.forEach(headid => {
      const { sourceType, sourceId, config = {} } = table.heads.list?.[headid];
      const { hidden = {}, sortation = {} } = config;
      if (sourceType === 'calculation') return;

      bases[headid] = {};

      let sourceElement;
      let sourceElementValues;
      if (sourceType === 'question') {
        sourceElement = questionVariables.list?.[sourceId];
        sourceElementValues = getMergedChoices(sourceElement, sourceElement.config?.choices, questionVariables.list);
      } else if (sourceType === 'sysvar') {
        sourceElement = systemVariables.list?.[sourceId];
        sourceElementValues = { order: sourceElement.order, list: sourceElement.list };
      } else if (sourceType === 'calcvar') {
        sourceElement = calculationVariables.find(v => v.id === sourceId);
        sourceElementValues = {
          list: Object.fromEntries(sourceElement.values.map(value => [value.id, value])),
          order: sourceElement.values.map(value => value.id)
        };
      }

      if (sortation?.sortationMode === 'manual' && !!sortation?.manualSortation?.order) {
        const combinedHeadOrder = getCombinedHeadOrder(
          tableResult?.headOrder?.[headid],
          sortation?.manualHeadSortation?.order
        );
        combinedHeadOrder.forEach(valueid => {
          if (hidden?.[valueid] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, valueid, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][valueid] = getColumnBases(table, tableResult, { headid, headsubid: valueid });
        });
      } else if (sortation?.sortationMode === 'automatic' && !!tableResult?.headOrder?.[headid]?.length > 0) {
        tableResult?.headOrder?.[headid]?.forEach(orderItem => {
          if (hidden?.[orderItem.headSubId] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][orderItem.headSubId] = getColumnBases(table, tableResult, {
            headid,
            headsubid: orderItem.headSubId
          });
        });
      } else {
        sourceElementValues?.order?.forEach(valueid => {
          if (hidden?.[valueid] === true) return;
          if ((config.autoHideLowBases ?? false) || (config.config?.autoHideLowBases ?? false)) {
            const autoHideLowBasesHurdle = config.autoHideLowBasesHurdle ?? config.config?.autoHideLowBasesHurdle ?? 0;
            const highestBase = getHighestHeadBase(tableResult, headid, valueid, {
              isDetailedMatrix: isDetailedMatrix
            });
            if (highestBase <= autoHideLowBasesHurdle) return;
          }
          bases[headid][valueid] = getColumnBases(table, tableResult, { headid, headsubid: valueid });
        });
      }
    });
  }

  let baseStrings = [];
  Object.keys(bases).forEach(baseKey => {
    Object.values(bases[baseKey]).forEach(colBases => {
      const min = Math.min(...colBases.filter(isNumber));
      const max = Math.max(...colBases.filter(isNumber));

      if (colBases.length === 0) baseStrings.push(0);
      else if (min === max) baseStrings.push(Math.round(max));
      else if (min !== max) baseStrings.push(`${Math.round(min)}-${Math.round(max)}`);
    });
  });

  const isDimensionsReversed = table.chart.dimensionsReversed ?? false;
  const isTableTransposed = table.chart.tableTranspose ?? false;
  if (isTableTransposed && isDimensionsReversed) return baseStrings.reverse().join(' | ');
  else return baseStrings.join(' | ');
};

const getColumnBases = (tableConfig, tableResult, column) => {
  const columnBases = [];

  tableResult?.order?.forEach(orderRow => {
    const { rowid, subelement, subsubelement, excluded } = orderRow;
    const { config = {}, sourceType = 'question' } = tableConfig.rows.list?.[rowid] ?? {};
    const { hidden: hiddenRowElements = {}, excluded: excludedRowElements = {} } = config;

    if (
      sourceType === 'calculation' ||
      excluded === true ||
      hiddenRowElements?.[subelement] === true ||
      hiddenRowElements?.[subsubelement] === true ||
      excludedRowElements?.[subelement] === true ||
      excludedRowElements?.[subsubelement] === true
    )
      return;

    const cell =
      tableResult.values?.[rowid]?.[subelement]?.[subsubelement]?.[column.headid]?.[column.headsubid] ??
      tableResult.values?.[rowid]?.[subelement]?.[column.headid]?.[column.headsubid] ??
      null;

    if (!!cell) columnBases.push(cell?.smvrslt_base);
  });

  return columnBases;
};

const getCombinedHeadOrder = (allItems, manualSortationOrderArray) => {
  // in case the heads are sorted manually and the user adds items to the question, they are not existent in the manual sortation. Hence we need to use a combined array of items sorted like the manual sortation, appending any newly added elements.
  const headSubIds = allItems?.map(el => el.headSubId);
  const filterdB = headSubIds.filter(el => !manualSortationOrderArray?.includes(el));
  return [...manualSortationOrderArray, ...filterdB];
};
