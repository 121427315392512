import React from 'react';
import { useSelector } from 'react-redux';

import { getMergedChoices } from 'src/smoove/components/misc/helper';

import { ApplyToValues } from '.';

export const ApplyToTypeQuestion = ({
  conditionalFormat,
  main,
  dimensionKey,
  matrixType = null,
  handleApplyToSelect
}) => {
  const questionVariables = useSelector(s => s.survey.questionnaire.list);
  const { sourceId } = main;

  const questionElement = questionVariables?.[sourceId] ?? {};
  if (!questionElement) return null;

  const label = questionElement.title;

  let values = getMergedChoices(questionElement, questionElement?.config?.choices, questionVariables);
  if (dimensionKey === 'rows' && (matrixType === 'compact' || matrixType === 'detailed')) {
    values = questionElement?.config?.scales;
  }

  return (
    <ApplyToValues
      conditionalFormat={conditionalFormat}
      label={label}
      main={main}
      dimensionKey={dimensionKey}
      matrixType={matrixType}
      values={values}
      handleApplyToSelect={handleApplyToSelect}
    />
  );
};
