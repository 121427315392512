import React from 'react';
// import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import './SmvSidebarToggleButton.scss';

export const SmvSidebarToggleButton = ({ position, toggleFn, isOpen }) => {
  return (
    <div className={`smv-sidebar-${position}-toggle-open`}>
      <div
        className='smv-sidebar-toggle-btn'
        onClick={() => {
          toggleFn();
        }}
      >
        {position === 'left' && <i className={`fas fa-chevron-double-${isOpen ? 'left' : 'right'}`}></i>}
        {position === 'right' && <i className={`fas fa-chevron-double-${isOpen ? 'right' : 'left'}`}></i>}
      </div>
    </div>
  );
};
