import { useMemo } from 'react';

import { FormulaBuilderTableElement } from '..';

export const useRenderCalcFrequencies = (handleAddReference, row) => {
  return useMemo(() => {
    if (!row?.config?.calcFrequencyGroups) return null;

    return Object.values(row.config?.calcFrequencyGroups ?? {})?.map(calcGroup => {
      const element = {
        sourceId: row.elementid,
        sourceType: 'calcFrequencyGroup',
        metric: 'smvrslt_perc',

        // cell identifiers
        rowId: row.id,
        rowSubId: calcGroup.id,
        rowSubSubId: null,
        headId: null,
        headSubId: null
      };

      return (
        <FormulaBuilderTableElement
          key={`${calcGroup.id}`}
          element={element}
          value={{ label: calcGroup.label }}
          handleAddReference={handleAddReference}
        />
      );
    });
  }, [handleAddReference, row]);
};
