import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Charts from './charts';

export const ChartRouter = props => {
  /**
   * Incomplete table
   */
  if (!props.loading && !props?.table?.rows?.order?.length) {
    return (
      <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
        <span className=''>
          <FormattedMessage
            id={'smoove.page.tables.add-question-warning'}
            defaultMessage={'Please add at least one question item to the rows to load tables results.'}
          />
        </span>
      </div>
    );
  }

  /**
   * Missing results
   */
  if (!props?.tableResult || !props?.table) {
    return <div className='h-100 w-100 d-flex justify-content-center align-items-center'></div>;
  }

  switch (`${props.resultTableType}-${props.chartConfig?.chartType}`) {
    case 'default-bar':
      return <Charts.BarChart {...props} />;
    case 'default-line':
      return <Charts.LineChart {...props} />;
    case 'default-area':
      return <Charts.AreaChart {...props} />;
    case 'default-scatter':
      return <Charts.ScatterChart {...props} />;
    case 'default-pie':
      return <Charts.PieChart {...props} />;
    case 'default-table':
      return <Charts.TableChart {...props} />;
    case 'listing-table':
      return <Charts.TableChartListing {...props} />;
    case 'default-wordcloud':
    case 'listing-wordcloud':
      return <Charts.WordcloudChart {...props} />;
    default:
      return (
        <span>
          <FormattedMessage id={'smoove.page.tables.chart-type-not-found'} defaultMessage={'Chart type not found'} />
        </span>
      );
  }
};
