import { useCallback, useEffect, useState } from 'react';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { questionnaireActions } from 'smv-redux';

import './InternalNote.scss';

export const InternalNote = ({ question, writeable, isCommentVisible, handleSetCommentVisibility }) => {
  const intl = useIntl();
  const [text, setText] = useState('');
  const surveyid = useSelector(state => state.survey.id);

  useEffect(() => {
    if (question?.config?.internalNote) {
      setText(question.config.internalNote);
      handleSetCommentVisibility(true);
    }
  }, [question?.config?.internalNote, handleSetCommentVisibility]);

  const handleChange = useCallback(e => {
    setText(e.target.value);
  }, []);

  const handleSaveText = useCallback(
    e => {
      if (!writeable) return;

      const text = e.target.value;
      const currentText = question?.config?.internalNote;

      // save if text has changed
      if (text !== currentText) {
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { internalNote: text }
        });
      }
    },
    [writeable, surveyid, question.id, question?.config?.internalNote]
  );

  if (!isCommentVisible) return null;

  return (
    <div className='internal-note'>
      <div id={`tt_${question.id}_comment`}>
        <i className='fal fa-comment' />
      </div>
      <UncontrolledTooltip placement='top' target={`tt_${question.id}_comment`} fade={true}>
        {intl.formatMessage({ id: 'smoove.questionnaire.internal-note.tooltip' })}
      </UncontrolledTooltip>
      <Input
        className='internal-note__input'
        id={`internal-note-${question.id}`}
        name={'comment'}
        placeholder={intl.formatMessage({ id: 'smoove.questionnaire.internal-note.placeholder' })}
        value={text}
        disabled={!writeable}
        onChange={handleChange}
        onBlur={handleSaveText}
        bsSize={'sm'}
        type='textarea'
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        spellCheck='false'
      />
    </div>
  );
};
