import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { useIntl } from 'react-intl';

import { ConfirmAction } from 'smv-components';

import { isQueryBuilderEmptyState } from './helper';

export const GroupHeader = ({ group, handler, name, readOnly = false, queryBuilder }) => {
  const disabled = group?.children?.length <= 1 || readOnly;
  const isEmpty = group?.children?.length <= 0;
  const isRootGroup = group.id === queryBuilder.rootElementId;

  const intl = useIntl();

  return (
    <div className={`row querybuilder__group-header ${isEmpty ? 'querybuilder__group-header--empty' : ''}`}>
      <div className={'col-12 mb-2 d-flex align-items-center'}>
        <ButtonGroup size={'sm'}>
          <Button
            disabled={disabled}
            outline={group.condition === 'or'}
            color={'secondary'}
            onClick={() =>
              !disabled && group.condition !== 'and' && handler.updateGroup({ ...group, condition: 'and' })
            }
          >
            {intl.formatMessage({ id: `smoove.common.querybuilder.and` })}
          </Button>
          <Button
            disabled={disabled}
            outline={group.condition === 'and'}
            color={'secondary'}
            onClick={() => !disabled && group.condition !== 'or' && handler.updateGroup({ ...group, condition: 'or' })}
          >
            {intl.formatMessage({ id: `smoove.common.querybuilder.or` })}
          </Button>
        </ButtonGroup>
        <Button
          className='ml-2'
          size={'sm'}
          disabled={readOnly}
          color={'danger'}
          outline={!group.not}
          onClick={() => handler.updateGroup({ ...group, not: !group.not })}
        >
          {intl.formatMessage({ id: `smoove.common.querybuilder.not` })}
        </Button>
        <Button
          className='ml-4'
          size={'sm'}
          disabled={readOnly}
          color={'primary'}
          onClick={() => {
            handler.addRule({ type: 'rule', parent: group.id });
          }}
          id={name + '_addrule_' + group.id}
        >
          <i className='fal fa-plus mr-1' />
          <span>{intl.formatMessage({ id: `smoove.common.querybuilder.rule` })}</span>
        </Button>
        <Button
          className='ml-2'
          disabled={readOnly}
          size={'sm'}
          color={'primary'}
          onClick={() => {
            handler.addGroup({ type: 'group', parent: group.id });
          }}
          id={name + '_addgroup_' + group.id}
        >
          <i className='fal fa-plus mr-1' />
          <span>{intl.formatMessage({ id: `smoove.common.querybuilder.group` })}</span>
        </Button>

        {isRootGroup && (
          <ConfirmAction
            size={'lg'}
            title={intl.formatMessage({ id: 'smoove.common.querybuilder.delete-query' })}
            body={<div>{intl.formatMessage({ id: 'smoove.common.querybuilder.confirm-delete-text' })}</div>}
            btnText={intl.formatMessage({ id: 'smoove.common.buttons.delete' })}
            callback={() => handler.resetToInitialState()}
          >
            {confirm => (
              <Button
                disabled={readOnly || isQueryBuilderEmptyState(queryBuilder)}
                className='ml-4'
                color={readOnly || isQueryBuilderEmptyState(queryBuilder) ? 'grey' : 'danger'}
                size={'sm'}
                outline={true}
                onClick={confirm}
                id={name + '_rmgroup_' + group?.id}
              >
                <i className='fal fa-trash-alt' />
              </Button>
            )}
          </ConfirmAction>
        )}
        {!isRootGroup && (
          <Button
            disabled={readOnly}
            className='ml-4'
            color={'danger'}
            size={'sm'}
            outline={true}
            onClick={() => handler.removeGroup(group)}
            id={name + '_rmgroup_' + group?.id}
          >
            <i className='fal fa-trash-alt' />
          </Button>
        )}
        {isEmpty && !isRootGroup && (
          <>
            <span className='d-flex align-items-center text-warning'>
              <i className='mx-2 fal fa-exclamation-triangle' style={{ fontSize: '1.25rem' }} />
              <small>{intl.formatMessage({ id: 'smoove.common.querybuilder.please-add-a-rule-to-this-group' })}</small>
            </span>
          </>
        )}
      </div>
    </div>
  );
};
