import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import { MediaLibraryElementToUpload } from './MediaLibraryElementToUpload';

export const MediaLibraryUploadStage = ({
  filesToUpload,
  handleRemoveFilesToUpload,
  uploadFilesNew,
  uploadFilesDuplicates,
  handleUploadFiles
}) => {
  /* Todo: add dropzone here */

  if (!filesToUpload || filesToUpload.length <= 0) return null;

  return (
    <>
      <div className='media-library-upload-stage'>
        {filesToUpload?.map((el, idx) => {
          return (
            <MediaLibraryElementToUpload
              element={el}
              idx={idx}
              key={el.id + '-' + idx}
              handleRemoveFilesToUpload={handleRemoveFilesToUpload}
            />
          );
        })}
      </div>
      {uploadFilesNew.length > 0 && (
        <Button onClick={() => handleUploadFiles(uploadFilesNew)} className='mb-4 mr-2'>
          {uploadFilesDuplicates.length > 0 ? (
            'upload new files only'
          ) : uploadFilesNew?.length > 1 ? (
            <FormattedMessage id={'smoove.common.media-library.upload-images'} defaultMessage={'Upload files'} />
          ) : (
            <FormattedMessage id={'smoove.common.media-library.upload-image'} defaultMessage={'Upload image'} />
          )}
        </Button>
      )}
      {uploadFilesDuplicates.length > 0 && (
        <Button onClick={() => handleUploadFiles(filesToUpload)} className='mb-4' color={'warning'}>
          Upload all & replace duplicates
        </Button>
      )}
    </>
  );
};
