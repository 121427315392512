import React from 'react';
import { useSelector } from 'react-redux';

import { QuestionnaireElementDragIcon } from './';

const groupElementTypes = ['container', 'page'];

const customTextInputType = ['custom_text'];

const questionElementTypes = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix', 'ranking_matrix'];

const textInputTypes = ['single_input_text', 'multiple_input_text', 'single_input_number', 'multiple_input_number'];

export const QuestionnaireElementsBar = ({ toggleSidebar, writeable }) => {
  const questionnaire = useSelector(state => state.survey.questionnaire);
  const isQueEmpty = !('root' in questionnaire) || questionnaire?.root?.length === 0;

  return (
    <div
      className={`questionaire-sidebar__column-right ${
        isQueEmpty ? 'questionaire-sidebar__column-right--empty-que' : ''
      }`}
    >
      <div className='questionaire-sidebar__controls'>
        <button className={'questionaire-sidebar__controls-close'} onClick={toggleSidebar}>
          <i className='fas fa-chevron-double-right' />
        </button>
      </div>

      {groupElementTypes.map(type => (
        <QuestionnaireElementDragIcon key={type} type={type} writeable={writeable} />
      ))}
      <hr style={{ margin: '2px', border: '1px solid #ebebeb', width: '100%' }} />
      <div className='dropdown-divider w-100' />
      {customTextInputType.map(qtype => (
        <QuestionnaireElementDragIcon key={qtype} qtype={qtype} writeable={writeable} />
      ))}
      <hr style={{ margin: '2px', border: '1px solid #ebebeb', width: '100%' }} />
      <div className='dropdown-divider w-100' />
      {questionElementTypes.map(qtype => (
        <QuestionnaireElementDragIcon key={qtype} qtype={qtype} writeable={writeable} />
      ))}
      <hr style={{ margin: '2px', border: '1px solid #ebebeb', width: '100%' }} />
      <div className='dropdown-divider w-100' />
      {textInputTypes.map(qtype => (
        <QuestionnaireElementDragIcon key={qtype} qtype={qtype} writeable={writeable} />
      ))}
    </div>
  );
};
