import { useMemo } from 'react';

export const useDefaultMetric = table => {
  return useMemo(() => {
    switch (table.config.valueType) {
      case 'abs':
        return 'smvrslt_abs';
      case 'base':
        return 'smvrslt_base';
      case 'perc':
      default:
        return 'smvrslt_perc';
    }
  }, [table.config.valueType]);
};
