import { useState } from 'react';

import { SmvCustomDropdown } from '.';

export const useSmooveCustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return [props => <SmvCustomDropdown isOpen={isOpen} toggle={toggle} {...props} />, isOpen, toggle];
};
