import produce from 'immer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';

import { dataPrepActions } from 'smv-redux';
import { checkTotalSumEqualsOne } from '../helpers/checkTotalSumEqualsOne';

export const DesiredDistributionInput = ({
  weightId,
  definition,
  choiceId,
  index,
  isMultiple = false,
  readOnly,
  subgroupSource,
  subgroupChoiceId,
  setShowInputWarning,
  totalResult
}) => {
  const surveyId = useSelector(state => state.survey.id);

  const isDisabledAndInverted = index === 1 && isMultiple;

  const [inputValue, setInputValue] = useState(null);
  const [hasWarning, setHasWarning] = useState(null);

  const isExcluded = definition.exclude?.rowChoiceIds?.includes(choiceId);

  useEffect(() => {
    const inputTargetValues = { ...definition.inputTargetValues } || {};

    const calculateAndSetInputValue = value => {
      const parsedValue = isExcluded ? parseFloat(totalResult) * 100 : parseFloat(value) * 100;

      setInputValue(isDisabledAndInverted ? ((1 - parsedValue / 100) * 100).toFixed(2) : parsedValue.toFixed(2));
    };

    // value depends on definition.inSubgroups and subgroupSourceType (question/calcvar/sysvar). Can this be simplified to just pass the second value (eg "__total__" from the parent?)
    const _value =
      inputTargetValues?.[choiceId]?.['__total__'] || // no subgroups
      inputTargetValues?.[choiceId]?.[subgroupChoiceId] || // subgroup is multiple_vector
      inputTargetValues?.[choiceId]?.[subgroupSource?.config?.choices?.order?.[0]] || // subgroup is single vector
      inputTargetValues?.[choiceId]?.[subgroupSource?.order?.[0]] || // subgroup is sysvar
      inputTargetValues?.[choiceId]?.[subgroupSource?.values?.[0]?.id]; // subgroup is calcvar

    if (_value !== undefined) {
      const key =
        subgroupChoiceId || // subgroup is multiple_vector
        subgroupSource?.config?.choices?.order?.[0] || // subgroup is single vector
        subgroupSource?.order?.[0] || // subgroup is sysvar
        subgroupSource?.values?.[0]?.id || // subgroup is calcvar
        '__total__'; // no subgroups

      if (!isMultiple) {
        setHasWarning(!checkTotalSumEqualsOne(definition?.inputTargetValues, key));
        setShowInputWarning(!checkTotalSumEqualsOne(definition?.inputTargetValues, key));
      } else {
        setShowInputWarning(false);
      }
      calculateAndSetInputValue(_value);
    } else {
      setInputValue(isExcluded ? (parseFloat(totalResult) * 100).toFixed(2) : '');
    }
  }, [
    definition.inputTargetValues,
    choiceId,
    isDisabledAndInverted,
    isMultiple,
    subgroupChoiceId,
    subgroupSource?.config?.choices?.order,
    subgroupSource?.order,
    subgroupSource?.values,
    setShowInputWarning,
    isExcluded,
    totalResult
  ]);

  const handleSaveTargetValueInput = (inputValue, choiceId, subgroupChoiceId, subgroupSource, surveyId, weightId) => {
    const _definition = handleCreateDefinitionInputTargetValues(
      inputValue,
      choiceId,
      subgroupChoiceId,
      subgroupSource,
      definition
    );

    dataPrepActions.updateWeightDefinition(surveyId, weightId, definition.id, _definition);
  };

  return (
    <div className='d-flex align-items-center justify-content-end'>
      <Input
        disabled={readOnly || isDisabledAndInverted || isExcluded}
        id={`desired_distrib_${choiceId}`}
        type={'number'}
        name={`desired_distrib_${choiceId}`}
        autoComplete='off'
        className={`desired-distribution-input ${hasWarning ? 'desired-distribution-input--warning' : ''}`}
        placeholder='enter value'
        value={inputValue ?? ''}
        onBlur={() =>
          handleSaveTargetValueInput(inputValue, choiceId, subgroupChoiceId, subgroupSource, surveyId, weightId)
        }
        onChange={({ target: { value } }) => setInputValue(value)}
        max={100}
      />
      <b className='ml-2'>%</b>
    </div>
  );
};

// const getPercentageValue = (value, precision, fallback = '-') => {
//   let _value = fallback;
//   if (!isNaN(value)) {
//     _value = Math.round(value * 100 * (precision * 10)) / (precision * 10);
//   }

//   return _value;
// };

/**
 * Updates the definition object with the new inputValue and returns the definition (not sending it to the backend - use the returned definition object to update the definition).
 * @param {number} inputValue - the new input value for one input
 * @param {string} choiceId - the choice id
 * @param {string} subgroupChoiceId - the subgroup choice id
 * @param {object} subgroupSource - the subgroup source object
 * @param {object} definition - the definition object
 * @returns {object} - the updated definition object
 */
export const handleCreateDefinitionInputTargetValues = (
  inputValue,
  choiceId,
  subgroupChoiceId,
  subgroupSource,
  definition
) => {
  const normalizedValue = parseFloat(Math.min(inputValue, 100) / 100).toFixed(4);

  const updateInputTargetValues = (draftState, key, value) => {
    if (!draftState.inputTargetValues) {
      draftState.inputTargetValues = {};
    }
    if (!draftState.inputTargetValues[choiceId]) {
      draftState.inputTargetValues[choiceId] = {};
    }
    draftState.inputTargetValues[choiceId][key] = value;
  };

  const updateSubgroupTargetValues = (draftState, source, keyExtractor) => {
    source.forEach(key => updateInputTargetValues(draftState, keyExtractor(key), normalizedValue));
  };

  return produce(definition, draftState => {
    if (!definition.inSubgroups) {
      updateInputTargetValues(draftState, '__total__', normalizedValue);
    } else if (subgroupChoiceId) {
      updateInputTargetValues(draftState, subgroupChoiceId, normalizedValue);
    } else {
      const { subgroupSourceType } = definition;

      switch (subgroupSourceType) {
        case 'question':
          updateSubgroupTargetValues(draftState, subgroupSource.config.choices.order, cid => cid);
          break;
        case 'calcvar':
          updateSubgroupTargetValues(draftState, subgroupSource.values, val => val.id);
          break;
        case 'sysvar':
          updateSubgroupTargetValues(draftState, subgroupSource.order, cid => cid);
          break;
        default:
          break;
      }
    }
  });
};
