import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import produce from 'immer';

import { QueryBuilder } from 'smv-components/querybuilder';
import {
  getQueryBuilderEmptyState,
  getQuerybuilderErrors,
  isQueryBuilderAnEmptyGroup,
  isQueryBuilderEmptyState
} from '../../../querybuilder/helper';
import { getQueryBuilderSources, getQuestionnaireElementsOrder, isLooped, useTranslationHooks } from 'smv-helpers';
import classNames from 'classnames';

export const StaticRowOrHeadFilterButton = ({ toggle }) => {
  const intl = useIntl();
  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i
        className={classNames({
          'fal fa-cog': true,
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>
        {intl.formatMessage({
          id: 'smoove.page.tables.table-config.static-filters.button-add',
          defaultMessage: 'configure filter'
        })}
      </span>
    </div>
  );
};

export const StaticRowOrHeadFilterModal = ({
  isOpen,
  toggle,
  mode = 'rows' | 'heads',
  rowOrHead = {},
  handler = {}
}) => {
  const questions = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations ?? []);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const [_filter, setFilter] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  useEffect(() => {
    if (!!rowOrHead?.config?.staticFilters && !!rowOrHead?.config?.staticFilters?.rootElementId) {
      setFilter(rowOrHead.config.staticFilters);
    } else {
      setFilter(getQueryBuilderEmptyState());
    }
  }, [rowOrHead?.config?.staticFilters]);

  const translationProps = useTranslationHooks();

  const sources = useMemo(() => {
    const WHITELIST_QUESTION_TYPES = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix'];

    const queOrder = getQuestionnaireElementsOrder(questions);
    const _questions = { list: {}, order: [] };
    (queOrder ?? []).forEach(elementid => {
      const element = questions.list[elementid];
      const { isLoopedQuestion } = isLooped(questions.list, element);

      if (element.type === 'question' && !isLoopedQuestion && WHITELIST_QUESTION_TYPES.includes(element.config.qtype)) {
        _questions.order.push(elementid);
        _questions.list[elementid] = element;
      }
    });
    const rawSources = {
      question: _questions,
      sysvar: systemVariables,
      calcvar: {
        list: Object.fromEntries(calculations?.map(value => [value.id, value])),
        order: calculations?.map(el => el.id)
      }
    };

    const _sources = getQueryBuilderSources(rawSources, null, 'above', true, true, translationProps);

    return _sources;
  }, [questions, systemVariables, calculations, translationProps]);

  const saveHandler = useCallback(() => {
    if (isQueryBuilderEmptyState(_filter)) {
      handler.setTableConfig(s =>
        produce(s, d => {
          d[mode].list[rowOrHead.id].config.staticFilters = null;
        })
      );
    } else {
      handler.setTableConfig(s =>
        produce(s, d => {
          d[mode].list[rowOrHead.id].config.staticFilters = _filter;
        })
      );
    }
    toggle();
  }, [handler, mode, rowOrHead, _filter, toggle]);

  useEffect(() => {
    if (_filter) {
      const queryBuilderErrors = getQuerybuilderErrors(_filter, sources);

      if (isQueryBuilderAnEmptyGroup(_filter)) {
        setFilter(getQueryBuilderEmptyState());
      }
      if (queryBuilderErrors.length > 0 && !isQueryBuilderEmptyState(_filter)) {
        setIsSaveButtonDisabled(true);
      } else {
        setIsSaveButtonDisabled(false);
      }
    }
  }, [_filter, sources]);

  if (!isOpen) return null;

  return (
    <Modal className='formula-builder-modal' isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage
          id={'smoove.page.tables.table-config.static-filters.title'}
          defaultMessage={'Static Filters'}
        />
      </ModalHeader>
      <ModalBody>
        <QueryBuilder
          name='value'
          fields={sources}
          queryBuilder={_filter}
          readOnly={false}
          setQueryBuilder={setFilter}
        />
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        <Button color={isSaveButtonDisabled ? 'grey' : 'primary'} disabled={isSaveButtonDisabled} onClick={saveHandler}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.save`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
