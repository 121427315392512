import React, { useMemo } from 'react';
import { ResultTableChart } from './table/ResultTableChart';

export const TableChart = ({ chartConfig, table, tableResult }) => {
  const isEmpty = useMemo(() => table.heads.order.length === 0 && table.rows.order.length === 0, [
    table.rows.order,
    table.heads.order
  ]);

  if (!chartConfig) return <span>table config not defined</span>;

  return <ResultTableChart chartConfig={chartConfig} table={table} tableResult={tableResult} isEmpty={isEmpty} />;
};
