import React from 'react';
// import PropTypes from 'prop-types';

import { QuestionText } from '../components';

export const CustomText = ({ question, writeable }) => {
  // eslint-disable-next-line
  const { config } = question;

  // let input = <div>no input configuration found!</div>;
  //
  // if (config.input === 'number') {
  //   const { min, max, decimals = 0 } = config;
  //   input = <input
  //     type={'number'}
  //     readOnly={true}
  //     placeholder={'numeric input'}
  //     min={min}
  //     max={max}
  //   />;
  // }

  // else if (config.input === 'text') {
  //   input = <input type={'text'} readOnly={true} placeholder={'text input'} />;
  // }

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
    </>
  );
};

CustomText.propTypes = {
  // question: PropTypes.shape({
  //   config: PropTypes.shape({
  //     input: PropTypes.oneOf(['number', 'text']).isRequired
  //   }).isRequired
  // }).isRequired
};
