import React from 'react';
import { Col, Row } from 'reactstrap';

import { useMatrixType } from 'smv-helpers';

import { ManualSortation } from '../setting-components';
import { AutomaticHeadSortation } from '.';

export const HeadSortationSettings = ({ tableConfig, internalHandler, internalHeadConfig, ...rest }) => {
  const { isCompactMatrix } = useMatrixType(tableConfig);

  return (
    <>
      <Row>
        <Col className={'d-flex flex-column'} sm={12}>
          <h6>Sortation</h6>
        </Col>
      </Row>
      <Row>
        <Col className={'d-flex flex-column'} md={6}>
          <AutomaticHeadSortation
            {...rest}
            tableConfig={tableConfig}
            sortationConfig={internalHeadConfig?.sortation ?? {}}
            sortationKey={'automaticHeadSortation'}
            handler={internalHandler}
          />
        </Col>
        <Col className={'d-flex flex-column'} md={6}>
          <ManualSortation
            {...rest}
            sortationConfig={internalHeadConfig?.sortation ?? {}}
            // Is this correct or do I only need manualHeadSortation for a compact matrix?
            sortationKey={isCompactMatrix ? 'manualHeadSortation' : 'manualSortation'}
            handler={internalHandler}
          />
        </Col>
      </Row>
    </>
  );
};
