import React, { useCallback } from 'react';
import { Col, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import { FormattedMessage } from 'react-intl';
import produce from 'immer';

import { FormulaBuilderButtonAndModal } from '../../misc';

export const CalculationSettings = ({ tableConfig, handler }) => {
  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.table-config.calculations.title'}
              defaultMessage={'Calculations'}
            />
          </Col>
          <Col md={7}>
            <FormulaBuilderButtonAndModal tableConfig={tableConfig} handler={handler} />
          </Col>
        </Row>
      </ListGroupItemHeading>
      <CalculationOrder tableConfig={tableConfig} handler={handler} />
    </ListGroupItem>
  );
};

const CalculationOrder = ({ tableConfig, handler }) => {
  const onDragEnd = useCallback(
    result => {
      const { destination, source } = result;
      if (!destination || !source || destination.index === source.index) {
        return;
      }

      handler.setTableConfig(s =>
        produce(s, d => {
          d.config.calculationOrder = arrayMoveImmutable(d.config.calculationOrder, source.index, destination.index);
        })
      );
    },
    [handler]
  );

  if (!tableConfig.config?.calculationOrder?.length) return null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='selected-filters-droppable'>
        {provided => (
          <div
            className={`droppable-default droppable-default--filters-selected`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {tableConfig.config.calculationOrder.map(({ source, elementId }, index) => {
              const calculation = tableConfig[source].list[elementId];
              return (
                <Draggable key={`${elementId}`} draggableId={elementId} index={index}>
                  {provided => (
                    <div
                      className={`draggable-default `}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style
                      }}
                    >
                      {index + 1}
                      {': '}
                      {source === 'rows' ? (
                        <i className='fa-duotone fa-table-rows' />
                      ) : (
                        <i className='fa-duotone fa-table-columns' />
                      )}{' '}
                      {calculation.config.title}
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
