/**
 * Returns the type of an element in the media library (image, audio, video) using element.type
 *
 * @param {object} element the media element
 *
 * @returns string "image", "audio", "video" or null if type is unknown
 */

export const getMediaElementType = element => {
  if (!element) return null;
  switch (element.type) {
    case 'image':
      return 'image';
    case 'image/png':
      return 'image';
    case 'image/jpeg':
      return 'image';
    // case 'image/svg+xml':
    //   return 'image'
    case 'audio':
      return 'audio';
    case 'audio/mpeg':
      return 'audio';
    case 'audio/wav':
      return 'audio';
    case 'audio/ogg':
      return 'audio';
    case 'video':
      return 'video';
    case 'video/mp4':
      return 'video';
    case 'video/webm':
      return 'video';
    case 'video/avi':
      return 'video';
    case 'video/ogg':
      return 'video';
    default:
      return null;
  }
};
