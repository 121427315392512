import React from 'react';

import { GroupHeader, Rule } from '.';

export const GroupRules = ({ name, queryBuilder, fields, group, handler, readOnly = false, translations, locale }) => {
  return (
    <div className='querybuilder__group-rules'>
      {group && (
        <GroupHeader group={group} handler={handler} name={name} readOnly={readOnly} queryBuilder={queryBuilder} />
      )}

      {group?.children?.map(id => {
        const element = queryBuilder.elements[id];
        if (!element) {
          return null;
        }

        if (element.type === 'group') {
          return (
            <GroupRules
              key={element.id}
              readOnly={readOnly}
              queryBuilder={queryBuilder}
              fields={fields}
              group={element}
              handler={handler}
              translations={translations}
              locale={locale}
            />
          );
        } else if (element.type === 'rule') {
          return (
            <Rule
              key={element.id}
              readOnly={readOnly}
              fields={fields}
              rule={element}
              handler={handler}
              translations={translations}
              locale={locale}
              queryBuilder={queryBuilder}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
