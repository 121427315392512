export const getChartTypeIcon = chartType => {
  switch (chartType) {
    case 'bar':
      return 'fa-chart-bar far';
    case 'pie':
      return 'fa-chart-pie-alt far';
    case 'line':
      return 'fa-chart-line far';
    case 'scatter':
      return 'fa-chart-scatter far';
    case 'area':
      return 'fa-chart-area far';
    case 'table':
      return 'fa-table far';
    case 'wordcloud':
      return 'fa-cloud-word far';
    default:
      return 'fa-question far';
  }
};
