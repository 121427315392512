import React from 'react';
import PropTypes from 'prop-types';

import { useRowOrder } from 'smv-helpers';

import { ResultTableChartResultRow, ResultTableChartResultRowHeader } from '.';
import { useDefaultMetric } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const ResultTableChartCalcVarResultRow = ({
  element,
  table,
  tableResult,
  chartConfig,
  row,
  questionHas,
  isCompactMatrix,
  matrixType,
  hover
}) => {
  const { heads, config } = table;
  const { id: rowid, config: rowConfig } = row;

  const { rowOrder, rowSpan } = useRowOrder(tableResult?.order, row);
  const colSpan = matrixType === 'detail' && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) ? 2 : 1;

  const rowHeadLabel = element.label;

  const significancesShow = chartConfig?.significancesShow ?? false;

  const defaultMetric = useDefaultMetric(table);

  const _rows = [];
  rowOrder.forEach(order => {
    let metric = defaultMetric;

    const isHidden = order?.hidden ?? false;
    const isExcluded = order?.excluded ?? false;

    if (isHidden || isExcluded) return null;

    const subelement = (element?.values ?? {}).find(v => v.id === order?.subelement);
    const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subelement] ?? null;
    _rows.push(
      <ResultTableChartResultRow
        key={`result-row-${rowid}_${order.subelement}`}
        rowid={rowid}
        orderItem={order}
        subelement={subelement}
        choiceid={order.subelement}
        config={config}
        heads={heads}
        tableResult={tableResult}
        conditionalFormats={chartConfig.conditionalFormats}
        metric={metric}
        sortationPinned={sortationPinned}
        isCompactMatrix={isCompactMatrix}
        significancesShow={significancesShow}
        rowHeadColSpan={colSpan > 1 && !chartConfig.showQuestionTitleColumn ? 2 : 1}
        hover={hover}
      >
        {_rows.length === 0 && chartConfig.showQuestionTitleColumn && (
          <ResultTableChartResultRowHeader
            rowid={rowid}
            row={row}
            label={rowHeadLabel}
            rowSpan={rowSpan}
            colSpan={colSpan}
            questionHas={questionHas}
          />
        )}
      </ResultTableChartResultRow>
    );
  });

  return _rows;
};

ResultTableChartCalcVarResultRow.propTypes = {
  element: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  tableResult: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  questionHas: PropTypes.object.isRequired,
  isCompactMatrix: PropTypes.bool.isRequired
};
