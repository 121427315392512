import { useSelector } from 'react-redux';

import { useSubelementPropertyTranslation } from 'smv-helpers';

import { FormulaBuilderReferenceElement } from '.';

export const FormulaBuilderReferenceCalculationVariableElement = ({
  calculationId,
  calculationMode,
  element,
  tableConfig,
  renderParentLabels,
  handleSaveElement,
  handleRemoveElement,
  dropdownContainerRef
}) => {
  const calculationVariables = useSelector(s => s.survey?.data?.calculations);
  const parent = calculationVariables?.find(el => el.id === element.value.sourceId);
  const value =
    calculationMode === 'rows'
      ? parent.values.find(el => el.id === element.value.rowSubId)
      : parent.values.find(el => el.id === element.value.headSubId);

  let label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  if (renderParentLabels) {
    label = (parent?.label ?? '') + ' - ' + label;
  }

  return (
    <FormulaBuilderReferenceElement
      calculationId={calculationId}
      calculationMode={calculationMode}
      tableConfig={tableConfig}
      element={element}
      label={label}
      handleSaveElement={handleSaveElement}
      handleRemoveElement={handleRemoveElement}
      options={{ metricSelect: true, allowFixation: true }}
      dropdownContainerRef={dropdownContainerRef}
    />
  );
};
