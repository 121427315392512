import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEqual } from 'lodash';

import { getQuestionnaireElementsOrder, isLooped, useTranslationHooks, getQueryBuilderSources } from 'smv-helpers';
import { reportsActions } from 'smv-redux';

import { QueryBuilder } from 'smv-components/querybuilder';
import {
  getQueryBuilderEmptyState,
  getQuerybuilderErrors,
  isQueryBuilderAnEmptyGroup,
  isQueryBuilderEmptyState
} from '../../querybuilder/helper';

export const StaticPageFilterButton = ({ toggle }) => {
  const intl = useIntl();
  return (
    <div className={`smv-custom-dropdown__buttons`} onClick={toggle}>
      <i className={'fal fa-filter icon-smv-blue mr-1'} />
      <span>{intl.formatMessage({ id: 'smoove.page.reports.filter-page' })}</span>
    </div>
  );
};

export const StaticPageFilterModal = ({ isOpen, toggle, pageConfig }) => {
  const questions = useSelector(state => state.survey.questionnaire);
  const calculations = useSelector(state => state.survey?.data?.calculations ?? []);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const intl = useIntl();

  const { params } = useRouteMatch();
  const report = useSelector(state => state.survey.reports.list[params.reportid]);

  const [_filter, setFilter] = useState(pageConfig?.staticFilters ?? getQueryBuilderEmptyState());
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const translationProps = useTranslationHooks();

  const sources = useMemo(() => {
    const WHITELIST_QUESTION_TYPES = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix'];

    const queOrder = getQuestionnaireElementsOrder(questions);
    const _questions = { list: {}, order: [] };
    (queOrder ?? []).forEach(elementid => {
      const element = questions.list[elementid];
      const { isLoopedQuestion } = isLooped(questions.list, element);

      if (element.type === 'question' && !isLoopedQuestion && WHITELIST_QUESTION_TYPES.includes(element.config.qtype)) {
        _questions.order.push(elementid);
        _questions.list[elementid] = element;
      }
    });
    const rawSources = {
      question: _questions,
      sysvar: systemVariables,
      calcvar: {
        list: Object.fromEntries(calculations?.map(value => [value.id, value])),
        order: calculations?.map(el => el.id)
      }
    };

    const _sources = getQueryBuilderSources(rawSources, null, 'above', true, true, translationProps);

    return _sources;
  }, [questions, systemVariables, calculations, translationProps]);

  const saveHandler = useCallback(() => {
    if (!isEqual(_filter, report.pages.list?.[params.pageid]?.config?.staticFilters)) {
      reportsActions.updateReport(
        params.surveyid,
        params.reportid,
        produce(report, draft => {
          if (!draft.pages.list[params.pageid]?.config) draft.pages.list[params.pageid].config = {};
          if (isQueryBuilderEmptyState(_filter)) {
            draft.pages.list[params.pageid].config.staticFilters = null;
          } else {
            draft.pages.list[params.pageid].config.staticFilters = _filter;
          }
        })
      );
    }

    toggle();
  }, [_filter, toggle, params.pageid, params.surveyid, params.reportid, report]);

  useEffect(() => {
    if (_filter) {
      const queryBuilderErrors = getQuerybuilderErrors(_filter, sources);

      if (isQueryBuilderAnEmptyGroup(_filter)) {
        setFilter(getQueryBuilderEmptyState());
      }
      if (queryBuilderErrors.length > 0 && !isQueryBuilderEmptyState(_filter)) {
        setIsSaveButtonDisabled(true);
      } else {
        setIsSaveButtonDisabled(false);
      }
    }
  }, [_filter, sources]);

  if (!isOpen) return null;

  return (
    <Modal className='formula-builder-modal' isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'smoove.page.reports.static-page-filter' })}</ModalHeader>
      <ModalBody>
        <QueryBuilder
          name='value'
          fields={sources}
          queryBuilder={_filter}
          readOnly={false}
          setQueryBuilder={setFilter}
        />
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
        <Button color={isSaveButtonDisabled ? 'grey' : 'primary'} disabled={isSaveButtonDisabled} onClick={saveHandler}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.save`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const StaticPageFilterButtonAndModal = ({ page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(b => !b), []);

  return (
    <>
      <StaticPageFilterButton toggle={toggle} />
      {isOpen && <StaticPageFilterModal toggle={toggle} isOpen={isOpen} pageConfig={page.config} />}
    </>
  );
};
