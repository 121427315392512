import Axios from 'axios';
import { translationActionTypes } from '../constants/translationsActionTypes';
import { store, intl } from 'smv-redux';
import { Store as notify } from 'react-notifications-component';
import { defaultNotifyProps } from 'smv-constants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

const getAllTranslations = async surveyId => {
  store.dispatch({
    type: translationActionTypes.GET_ALL_TRANSLATIONS_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/translations`)
    .then(res => {
      store.dispatch({
        type: translationActionTypes.GET_ALL_TRANSLATIONS_SUCCESS,
        payload: {
          surveyId,
          translations: res.data
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: translationActionTypes.GET_ALL_TRANSLATIONS_FAILURE,
        payload: {
          surveyId,
          error
        }
      });

      return error;
    });
};

const updateTranslation = async (surveyId, translationId, translations) => {
  store.dispatch({
    type: translationActionTypes.UPDATE_TRANSLATION_REQUEST,
    payload: { surveyId, translationId }
  });

  return Axios.patch(`/surveys/${surveyId}/translations/${translationId}`, translations)
    .then(res => {
      store.dispatch({
        type: translationActionTypes.UPDATE_TRANSLATION_SUCCESS,
        payload: {
          surveyId,
          translations: res.data
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: translationActionTypes.UPDATE_TRANSLATION_FAILURE,
        payload: {
          surveyId,
          translationId,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

const updateMultipleTranslations = async (surveyId, translations) => {
  store.dispatch({
    type: translationActionTypes.UPDATEMULTIPLE_TRANSLATION_REQUEST,
    payload: { surveyId, translations }
  });

  return Axios.patch(`/surveys/${surveyId}/translations`, translations)
    .then(res => {
      store.dispatch({
        type: translationActionTypes.UPDATEMULTIPLE_TRANSLATION_SUCCESS,
        payload: {
          surveyId,
          translations: res.data
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: translationActionTypes.UPDATEMULTIPLE_TRANSLATION_FAILURE,
        payload: {
          surveyId,
          translations,
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

const importTranslations = (surveyId, file) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('name', file.name);

  const endpoint = `surveys/${surveyId}/translations/import`;

  return Axios.post(endpoint, fd)
    .then(res => {
      notify.addNotification({
        title: intl.formatMessage({
          id: 'smoove.questionnaire.translations.import.success',
          defaultMessage: 'Successfully imported data.'
        }),
        type: 'success',
        ...defaultNotifyProps
      });
      translationsActions.getAllTranslations(surveyId);
      return;
    })
    .catch(error => {
      console.log(error);

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps,
        dismiss: {
          duration: 8000,
          showIcon: true
        }
      });

      return;
    });
};

export const translationsActions = {
  getAllTranslations,
  updateTranslation,
  importTranslations,
  updateMultipleTranslations
};
