export const getQuestionnaireElementsOrder = questionnaire => {
  let order = [];

  const getChildrenRec = rootEl => {
    if (rootEl.type === 'container' || rootEl.type === 'page') {
      order.push(rootEl.id);
      rootEl.children?.forEach(elId => {
        const child = questionnaire.list[elId];
        getChildrenRec(child);
      });
    } else {
      order.push(rootEl.id);
    }
  };

  questionnaire.root?.forEach(id => {
    const rootEl = questionnaire.list[id];
    getChildrenRec(rootEl);
  });

  return order;
};
