import { useCallback, useMemo, useState } from 'react';
import { surveyActions } from 'smv-redux';
import { useHistory } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import produce from 'immer';
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledCarousel
} from 'reactstrap';

import { permissionEnum, useUserHasPermission } from 'smv-helpers';

import { getSurveyStatusBadge } from 'src/smoove/helpers/getSurveyStatus';
import ProjectPlaceholder from 'smv-assets/img/project_placeholder.png';
import { ReadOnlyAccessIconAndTooltip } from '../misc/ReadOnlyAccessIconAndTooltip';

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const SurveyListElementTile = ({ openSurvey, survey, project }) => {
  const history = useHistory();

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE, project.id);
  const translationPrefix = 'smoove.page.projects';

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
  const toggleDuplicateModal = useCallback(() => setIsOpenDuplicateModal(prev => !prev), []);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const toggleDeleteModal = useCallback(() => setIsOpenDeleteModal(prev => !prev), []);

  const cardImages = useMemo(() => survey?.cardImages ?? [], [survey.cardImages]);

  const openSurveySettings = useCallback(() => {
    surveyActions.getSurvey(survey?.id);
    history.push(`/one/projects/${project?.id}/surveys/${survey?.id}/survey`);
  }, [history, survey?.id, project?.id]);

  const handleOpenSurvey = useCallback(
    e => {
      e.stopPropagation();
      openSurvey(project, survey);
    },
    [project, survey, openSurvey]
  );

  const _media = useMemo(() => {
    const media = survey.media ?? {};
    if (!media) {
      console.log('no media!');
      return [];
    }
    return produce(Object.values(media), draft => {
      draft.forEach((media, index) => {
        draft[index].local = {
          url: `${baseUrl}/${media.url}`,
          thumbnailUrl: `${baseUrl}/${media.thumbnailUrl}`
        };
        draft[index].loading = media.loading;
      });
    });
  }, [survey.media]);

  const carouselImages = useMemo(() => {
    let images = [];
    cardImages.forEach(mediaid => {
      const image = _media?.find(_media => _media.id === mediaid);
      if (image) {
        images.push({
          src: `${image.local?.thumbnailUrl ?? image.local?.url ?? ''}`,
          // altText: 'Slide 1',
          caption: ''
          // header: 'Slide 1 Header'
        });
      }
    });
    return images;
  }, [_media, cardImages]);

  return (
    <div key={survey.id} className={'survey-list-element-tile'}>
      <div className='tile-image pointer' onClick={handleOpenSurvey}>
        {cardImages.length > 0 && (
          <UncontrolledCarousel
            className={'carousel-fade w-100'}
            items={carouselImages}
            // captionText={''}
            indicators={false}
            controls={false}
            autoPlay={true}
          />
        )}
        {cardImages.length <= 0 && (
          <img
            src={ProjectPlaceholder}
            alt={survey.title}
            style={{ objectPosition: 'center center', objectFit: 'cover', width: '100%', height: '100%' }}
          />
        )}
      </div>
      <div className='tile-description'>
        <div className='survey-list-element__title pt-2 pointer d-flex align-items-center' onClick={handleOpenSurvey}>
          {!userCanEditProject && (
            <span className='mr-2' style={{ fontSize: '0.75em' }}>
              <ReadOnlyAccessIconAndTooltip />
            </span>
          )}
          {survey.title}
        </div>
        <div className='pt-1 pointer' onClick={handleOpenSurvey}>
          <small>
            <FormattedMessage id={`${translationPrefix}.surveys.created-on`} />:{' '}
            <FormattedDate day='2-digit' year='numeric' month='long' value={survey.createdAt} />
          </small>
        </div>
        <div className='mt-1 d-flex justify-content-between align-items-center'>
          {getSurveyStatusBadge(survey.status)}
          {userCanEditProject && (
            <Dropdown
              // className='ml-auto'
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
              onClick={e => {
                e.stopPropagation();
              }}
              direction='left'
            >
              <DropdownToggle className='btn btn-sm btn-light'>
                <i className='fal fa-ellipsis-h pointer'></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setDropdownOpen(false);
                    openSurveySettings();
                  }}
                >
                  <i className='fal fa-cog p-1 mr-1' />
                  <span>
                    <FormattedMessage id={`${translationPrefix}.survey-settings`} defaultMessage={`Survey settings`} />
                  </span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setDropdownOpen(false);
                    setIsOpenDuplicateModal(true);
                  }}
                >
                  <div className='d-flex align-items-baseline'>
                    <i className='fal fa-copy p-1 mr-1 pointer' />
                    <div>
                      <FormattedMessage
                        id={`${translationPrefix}.surveys.menu.item.duplicate`}
                        defaultMessage={`Duplicate Survey`}
                      />
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setDropdownOpen(false);
                    setIsOpenDeleteModal(true);
                  }}
                >
                  <div className='d-flex align-items-baseline'>
                    <i className='fal fa-trash-alt pl-1 pr-2 pointer' />
                    <div>
                      <FormattedMessage id={`${translationPrefix}.surveys.delete-survey`} />
                    </div>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
      {userCanEditProject && (
        <DuplicateSurveyModal
          isOpen={isOpenDuplicateModal}
          toggle={toggleDuplicateModal}
          project={project}
          survey={survey}
        />
      )}
      {userCanEditProject && (
        <DeleteModal isOpen={isOpenDeleteModal} toggle={toggleDeleteModal} project={project} survey={survey} />
      )}
    </div>
  );
};

const DeleteModal = ({ isOpen, toggle, project, survey }) => {
  const translationPrefix = 'smoove.page.projects';
  const intl = useIntl();
  return (
    <div>
      <Modal isOpen={isOpen} centered={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id='smoove.page.projects.modal.head.delete' defaultMessage='Confirm Delete' />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            id={`${translationPrefix}.surveys.delete-survey-confirmation-text`}
            defaultMessage={`Do you want to delete this survey?`}
          />
        </ModalBody>
        <ModalFooter>
          <Button color={'secondary'} outline={true} onClick={toggle}>
            <FormattedMessage id='smoove.common.buttons.cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            color={'danger'}
            onClick={() => {
              surveyActions.removeSurvey(project.id, survey.id);
            }}
          >
            {/* <FormattedMessage id='smoove.page.projects.button.delete' defaultMessage='Delete' /> */}
            {intl.formatMessage({ id: 'smoove.page.projects.surveys.delete-survey' })}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const DuplicateSurveyButton = ({ toggle, className = '' }) => {
  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={toggle}>
      <i className='fal fa-copy icon-smv-blue mr-1' />
      <span>
        <FormattedMessage
          id='smoove.page.projects.surveys.modal.button-open.duplicate'
          defaultMessage='Duplicate Survey'
        />
      </span>
    </div>
  );
};

export const DuplicateSurveyModal = ({ isOpen, toggle, project, survey }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const _duplicateHandler = useCallback(() => {
    setIsLoading(true);
    surveyActions
      .duplicateSurvey(project.id, survey.id)
      .then(res => {
        toggle();
        setIsLoading(false);
      })
      .catch(err => {
        toggle();
        setIsLoading(false);
      });
  }, [project, survey, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id='smoove.page.projects.surveys.modal.head.duplicate' defaultMessage='Duplicate Survey' />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id={'smoove.page.projects.surveys.modal.body.duplicate'}
          defaultMessage={'Are you sure you want to duplicate the survey <b>"{title}"</b>?'}
          values={{ b: text => <b>{text}</b>, title: survey.title }}
        />
        {isLoading && (
          <div>
            <span className='mr-2 mt-2 text-warning'>
              {intl.formatMessage({ id: 'smoove.page.projects.surveys.modal.body.duplicating-survey-please-wait' })}
            </span>
            <i className='fal fa-spinner fa-spin' />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} outline={true} onClick={toggle}>
          <FormattedMessage id='smoove.common.buttons.cancel' defaultMessage='Cancel' />
        </Button>
        <Button color={'primary'} onClick={_duplicateHandler} disabled={isLoading}>
          <FormattedMessage id='smoove.common.buttons.duplicate' defaultMessage='Duplicate' />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
