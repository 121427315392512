import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { DynamicSplitConfigAvailableElements } from '.';

export const DynamicSplitConfigAddElements = ({ handler, report, split }) => {
  return (
    <Col md={6} className={'d-flex flex-column'}>
      <h6>
        <FormattedMessage
          id={'smoove.page.common.dynamic-splits.add-split-element'}
          defaultMessage={'Add Split Elements'}
        />
      </h6>
      <DynamicSplitConfigAvailableElements report={report} split={split} handler={handler} />
    </Col>
  );
};
