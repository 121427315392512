export const getConditionalFormat = ({
  conditionalFormats,
  tableResult,
  rowId,
  rowSubId,
  rowSubSubId = null,
  headId,
  headSubId,
  metric,
  defaultMetric
}) => {
  if (!conditionalFormats) return null;

  const cell = getResultCell({ tableResult, rowId, rowSubId, rowSubSubId, headId, headSubId });

  let applyConditionalFormat = null;

  conditionalFormats.order.forEach(formatId => {
    if (applyConditionalFormat) return;

    const conditionalFormat = conditionalFormats.list[formatId];

    const hasApplyToRows = Object.keys(conditionalFormat.applyTo?.rows ?? {}).length > 0;
    const hasApplyToHeads = Object.keys(conditionalFormat.applyTo?.heads ?? {}).length > 0;

    /**
     * check if format should be applied to row/ head in applyTo property
     *
     * apply, of no applyTo is configured
     * OR the correct row is configured, no head selected
     * OR the correct head is configured, no row selected
     * OR the correct row & head is configured
     */
    if (
      // applyTo is configured
      (hasApplyToRows || hasApplyToHeads) &&
      // row is configured, head is NOT configured - row does not match
      ((hasApplyToRows &&
        !hasApplyToHeads &&
        !(
          conditionalFormat.applyTo?.rows?.[rowId] === true ||
          conditionalFormat.applyTo?.rows?.[rowId]?.[rowSubId] === true ||
          conditionalFormat.applyTo?.rows?.[rowId]?.[rowSubSubId] === true
        )) ||
        // row is NOT configured, head is configured - head does not match
        (!hasApplyToRows &&
          hasApplyToHeads &&
          !(
            conditionalFormat.applyTo?.heads?.[headId] === true ||
            conditionalFormat.applyTo?.heads?.[headId]?.[headSubId] === true
          )) ||
        // row is configured, head is configured - at least one does not match
        (hasApplyToRows &&
          hasApplyToHeads &&
          !(
            (conditionalFormat.applyTo?.rows?.[rowId] === true ||
              conditionalFormat.applyTo?.rows?.[rowId]?.[rowSubId] === true ||
              conditionalFormat.applyTo?.rows?.[rowId]?.[rowSubSubId] === true) &&
            (conditionalFormat.applyTo?.heads?.[headId] === true ||
              conditionalFormat.applyTo?.heads?.[headId]?.[headSubId] === true)
          )))
    ) {
      return;
    }

    let compareValue;
    if (conditionalFormat.conditionType === 'value') {
      compareValue = cell?.[metric];
      if (metric === 'smvrslt_perc') compareValue *= 100;
    } else if (conditionalFormat.conditionType === 'difference') {
      // get compareCell
      const reference = {
        rowId: rowId,
        rowSubId: rowSubId,
        rowSubSubId: rowSubSubId,
        headId: headId,
        headSubId: headSubId,

        ...(conditionalFormat.reference.rowId && {
          rowId: conditionalFormat.reference.rowId,
          rowSubId: conditionalFormat.reference.rowSubId,
          rowSubSubId: conditionalFormat.reference.rowSubSubId
        }),

        ...(conditionalFormat.reference.headId && {
          headId: conditionalFormat.reference.headId,
          headSubId: conditionalFormat.reference.headSubId
        })
      };
      const compareCell = getResultCell({ tableResult, ...reference });

      let _cellValue, _compareCellValue;
      if (cell?.[metric]) _cellValue = metric === 'smvrslt_perc' ? cell[metric] * 100 : cell[metric];
      if (compareCell) {
        if (compareCell?.[metric])
          _compareCellValue = metric === 'smvrslt_perc' ? compareCell[metric] * 100 : compareCell[metric];
        else if (compareCell?.[defaultMetric])
          _compareCellValue =
            defaultMetric === 'smvrslt_perc' ? compareCell[defaultMetric] * 100 : compareCell[defaultMetric];
      }

      if (_cellValue && _compareCellValue) compareValue = _cellValue - _compareCellValue;
    }

    // check if conditionalFormat condition matches compare value
    if (compareValue) {
      if (conditionalFormat.operator === 'RANGE') {
        if (compareValue >= conditionalFormat.values[0] && compareValue <= conditionalFormat.values[1]) {
          applyConditionalFormat = conditionalFormat;
        }
      } else if (conditionalFormat.operator === 'GE') {
        if (compareValue >= conditionalFormat.values[0]) {
          applyConditionalFormat = conditionalFormat;
        }
      } else if (conditionalFormat.operator === 'LE') {
        if (compareValue <= conditionalFormat.values[0]) {
          applyConditionalFormat = conditionalFormat;
        }
      }
    }
  });

  return applyConditionalFormat;
};

const getResultCell = ({ tableResult, rowId, rowSubId, rowSubSubId, headId, headSubId }) => {
  if (rowSubSubId) return tableResult?.values?.[rowId]?.[rowSubId]?.[rowSubSubId]?.[headId]?.[headSubId];
  else return tableResult?.values?.[rowId]?.[rowSubId]?.[headId]?.[headSubId];
};
