import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import { getHeadBaseValue } from '../helper';
import { ResultTableChartCell } from '.';
import { getHighestHeadBase } from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const ResultTableChartBaseRow = ({
  heads,
  rows,
  list,
  config,
  tableResult,
  chartConfig,
  metric = 'smvrslt_base',
  isCompactMatrix,
  matrixType = null
}) => {
  const isDetailedMatrix = matrixType === 'detail';

  const { showTotal = true, showHiddenColumns = true } = config;

  let colSpan = matrixType === 'detail' && (chartConfig?.showDetailedMatrixChoiceTitleColumn ?? true) ? 2 : 1;

  let headCols = heads;
  /**
   * Set headcols from rows, if compact matrix should be displayed,
   * to provide the matrixs choices as head columns
   */
  if (isCompactMatrix) {
    headCols = produce(rows, draft => {
      const [firstRowId, ...otherRows] = draft.order;
      draft.order = [firstRowId];
      otherRows.forEach(_rowId => delete draft.list[_rowId]);

      if (heads.order.length > 0) {
        heads.order.forEach(headId => {
          const head = heads.list[headId];
          if (head.sourceType === 'calculation') {
            draft.order.push(headId);
            draft.list[headId] = head;
          }
        });
      }
    });
  }

  if (!chartConfig.showQuestionTitleColumn) colSpan = colSpan - 1;

  return (
    <tr className={'base-row result__row small font-italic'}>
      {/* Row Label */}
      {/* showQuestionTitleColumn */}
      {colSpan > 0 && <th colSpan={colSpan} scope='row'></th>}
      <th colSpan={1} scope='row'>
        Base
      </th>

      {/* Total Column */}
      {showTotal && tableResult && !isCompactMatrix && (
        <ResultTableChartCell
          key={`baserow_${metric}`}
          value={getHeadBaseValue(tableResult, '__total__', '__total__', metric)}
          config={{}}
          idHidden={false}
        />
      )}

      {/* Other Columns */}
      {headCols.order.length > 0 &&
        headCols.order.map(headid => {
          const { sourceType } = headCols.list[headid];

          /** @todo: refactor hidden heads to use headOrder */
          return tableResult?.headOrder?.[headid]?.map(orderItem => {
            let isHiddenColumn = headCols.list[headid]?.config?.hidden?.[orderItem.headSubId] ?? false;

            if (
              !isHiddenColumn &&
              ((headCols.list[headid]?.config.autoHideLowBases ?? false) ||
                (headCols.list[headid]?.config.config?.autoHideLowBases ?? false))
            ) {
              const autoHideLowBasesHurdle =
                headCols.list[headid]?.config.autoHideLowBasesHurdle ??
                headCols.list[headid]?.config.config?.autoHideLowBasesHurdle ??
                0;
              const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
                isDetailedMatrix: isDetailedMatrix,
                excludeHidden: true
              });
              if (highestBase <= autoHideLowBasesHurdle) isHiddenColumn = true;
            }

            if (isHiddenColumn) return null;
            if (!showHiddenColumns && isHiddenColumn) return null;

            return (
              <ResultTableChartCell
                key={`baserow_${metric}_${orderItem.headSubId}`}
                value={
                  sourceType === 'calculation'
                    ? '-'
                    : getHeadBaseValue(tableResult, headid, orderItem.headSubId, metric)
                }
                config={{}}
                isHidden={isHiddenColumn}
              />
            );
          });
        })}
    </tr>
  );
};

ResultTableChartBaseRow.propTypes = {
  heads: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  tableResult: PropTypes.object,
  chartConfig: PropTypes.object.isRequired,
  metric: PropTypes.string,
  matrixType: PropTypes.string,
  isCompactMatrix: PropTypes.bool.isRequired
};
