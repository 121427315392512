import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListGroupItem, ListGroupItemHeading, Collapse, DropdownItem } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { tablesActions } from 'smv-redux/actions';
import { useControlledState } from 'smv-helpers';

import { SmvCustomDropdown } from '../../misc';
import { EditFolderButton, EditFolderModal } from '../modals';
import { WeightTablesButton, WeightTablesModal } from '../modals/WeightTablesModal';
import { TableTree } from '../TableTree';

import './TableList.scss';

export const TablesList = ({ surveyid, tables, readonly, tablesHandler, clearTable, isTableConfigInitialState }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(state => !state), []);

  const folders = useSelector(state => state.survey.tablesFolders);
  const weights = useSelector(state => state.survey?.data?.weights ?? []);
  const hasWeights = weights.length > 0;

  const [WeightButton, WeightModal] = useControlledState(WeightTablesButton, WeightTablesModal, false);

  const [EditButton, EditModal] = useControlledState(EditFolderButton, EditFolderModal, false);

  const handler = useMemo(() => {
    return {
      deleteTable: tablesHandler?.deleteTable ?? (() => console.log('deleteTable handler missing!')),
      saveFolder: folder => {
        if (folder.id) {
          tablesActions.updateFolder(surveyid, folder.id, folder);
        } else {
          tablesActions
            .createFolder(surveyid, { ...folder, parentId: folder.parentId ?? folders.rootFolderId })
            .then(() => {
              tablesActions.loadFolders(surveyid);
            });
        }
      },
      deleteFolder: async folderid => {
        await tablesActions.deleteFolder(surveyid, folderid);
        await tablesActions.loadFolders(surveyid);
        await tablesActions.loadTables(surveyid);
      },
      copyFolder: async folderid => {
        await tablesActions.copyFolder(surveyid, folderid);
        await tablesActions.loadFolders(surveyid);
        await tablesActions.loadTables(surveyid);
      }
    };
  }, [folders.rootFolderId, surveyid, tablesHandler?.deleteTable]);

  return (
    <ListGroupItem className='tables-list'>
      <ListGroupItemHeading className='pointer d-flex justify-content-between mb-0 py-2 list-item-sticky'>
        <div onClick={toggleOpen} className='tables-list__headline'>
          <i className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
          <span className='ml-1 tables-list__headline-text'>
            <FormattedMessage id={'smoove.page.tables.tables'} defaultMessage={'Tables'} />
          </span>
        </div>
        {!readonly && (
          <SmvCustomDropdown faIconString={'fal fa-ellipsis-v'}>
            <DropdownItem>
              <div className='smv-custom-dropdown__buttons' onClick={clearTable}>
                <i className={'fal fa-square-plus icon-smv-blue mr-1'}></i>
                <FormattedMessage
                  id={'smoove.page.tables.create-new-empty-table'}
                  defaultMessage={'Create new empty table'}
                />
              </div>
            </DropdownItem>
            <DropdownItem>
              <div>
                <EditButton mode={'add'} />
              </div>
              <EditModal mode={'add'} handler={handler} />
            </DropdownItem>
            {hasWeights && (
              <>
                <DropdownItem divider />
                <DropdownItem>
                  <WeightButton
                    buttonText={intl.formatMessage({
                      id: 'smoove.page.tables.weight-tables.apply-weight-to-all-tables'
                    })}
                  />
                </DropdownItem>
              </>
            )}
          </SmvCustomDropdown>
        )}
      </ListGroupItemHeading>
      <Collapse isOpen={isOpen} className='mt-1'>
        {tables?.loading && <i className='fas fa-spinner fa-spin ml-3'></i>}
        {!tables?.loading && tables?.order?.length === 0 && (
          <div className='ml-3'>
            <FormattedMessage id={'smoove.page.tables.no-tables-available'} defaultMessage={'No tables available'} />
          </div>
        )}

        <TableTree
          handler={handler}
          tablesHandler={tablesHandler}
          readonly={readonly}
          isTableConfigInitialState={isTableConfigInitialState}
          clearTable={clearTable}
        />
      </Collapse>
      {hasWeights && (
        <WeightModal folder={'root'} isTableConfigInitialState={isTableConfigInitialState} clearTable={clearTable} />
      )}
    </ListGroupItem>
  );
};
