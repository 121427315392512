import produce from 'immer';

import { getMergedChoices } from '../components/misc/helper';
import { getSubelementPropertyTranslation } from '.';

const getElementsGlobalOrder = (questionnaire, children, globalOrder = []) => {
  children.forEach(id => {
    globalOrder.push(id);

    const element = questionnaire.list[id];

    if (element?.children?.length > 0) {
      getElementsGlobalOrder(questionnaire, element.children, globalOrder);
    }
  });

  return globalOrder;
};

/**
 * Returns list of questions (id, title) previous to specified question.
 *
 * @param {object} elements
 * @param {array} globalOrder
 * @param {string} elementid
 *
 * @returns {array} An array of question elements `[ {id: '', title: ''}, ... ]`
 */
export const getPreviousQuestions = (questionnaire, elementid = null) => {
  let sources = [];
  const globalOrder = getElementsGlobalOrder(questionnaire, questionnaire.root);
  const whitelistedQuestionTypes = [
    'single_vector',
    'multiple_vector',
    'single_matrix',
    'multiple_matrix',
    'custom_shelf'
  ];

  let startIndex = 0;
  let stopIndex = globalOrder.indexOf(elementid);

  for (let i = startIndex; i < stopIndex; i++) {
    const id = globalOrder[i];
    const element = questionnaire.list[id];
    if (element.type === 'question' && whitelistedQuestionTypes.includes(element.config?.qtype)) {
      sources.push({
        id: element.id,
        title: element.title
      });
    }
  }

  return sources;
};

/**
 * Returns question elements as sources for the QueryBuilder component.
 *
 * If no elementid is specified, all elements will be returned, otherwise all
 * elements above or below the specified element. If `included` is true, the
 * element itself will also be included.
 *
 * @param {object} rawSources { question: {list: {}, order: []}, sysvar: {}, calcvar: {} }
 * @param {string} elementid
 * @param {string} mode
 * @param {boolean} included
 * @param {boolean} withLoops
 *
 * @returns {array} An array of elements, which can be used as sources in a QueryBuilder component
 */
export const getQueryBuilderSources = (
  rawSources,
  elementid = null,
  mode = 'above',
  included = true,
  withLoops = false,
  translationProps = {},
  whitelistedQuestionTypes = []
) => {
  let sources = [];

  if (rawSources?.question?.order?.length > 0) {
    sources = getQueryBuilderQuestions(
      sources,
      rawSources.question,
      elementid,
      mode,
      included,
      withLoops,
      translationProps,
      whitelistedQuestionTypes
    );
  }

  if (rawSources?.sysvar?.order?.length > 0) {
    sources = getQueryBuilderSystemVariables(sources, rawSources.sysvar);
  }

  if (rawSources?.calcvar?.order?.length > 0) {
    sources = getQueryBuilderCalculationVariables(sources, rawSources.calcvar);
  }

  return sources;
};

export const getQueryBuilderQuestions = (
  sources = [],
  elements,
  elementid = null,
  mode = 'above',
  included = true,
  withLoops = false,
  translationProps = {},
  whitelistedQuestionTypes = []
) => {
  const _whitelistedQuestionTypes = [
    'single_vector',
    'multiple_vector',
    'single_matrix',
    'multiple_matrix',
    'single_input_number',
    'single_input_text',
    ...whitelistedQuestionTypes
    // 'custom_shelf' - need further check on shelfResultType
  ];

  const questionTypesWithChoices = [
    'single_vector',
    'multiple_vector',
    'single_matrix',
    'multiple_matrix',
    'custom_shelf'
  ];

  let startIndex = 0;
  let stopIndex = elements.order.length;

  if (mode === 'above') {
    if (elementid && included) stopIndex = elements.order.indexOf(elementid) + 1;
    else if (elementid && !included) stopIndex = elements.order.indexOf(elementid);
  } else if (mode === 'below') {
    if (elementid && included) startIndex = elements.order.indexOf(elementid);
    else if (elementid && !included) startIndex = elements.order.indexOf(elementid) + 1;
  }

  for (let i = startIndex; i < stopIndex; i++) {
    const id = elements.order[i];

    let _element = produce(elements.list[id], _element => _element);
    const shelfResultType = _element.config?.shelfResultType ?? _element.config?.shelf?.config?.shelfResultType ?? null;

    if (
      _element.type === 'question' &&
      (_whitelistedQuestionTypes.includes(_element.config?.qtype) ||
        (_element.config?.qtype === 'custom_shelf' && ['single', 'multiple'].includes(shelfResultType)))
    ) {
      if (['single', 'multiple'].includes(shelfResultType)) {
        _element = produce(_element, draft => {
          if (shelfResultType === 'single') draft.config.qtype = 'single_vector';
          if (shelfResultType === 'multiple') draft.config.qtype = 'multiple_vector';
        });
      }

      // get merged choices
      if (questionTypesWithChoices.includes(_element.config?.qtype)) {
        _element = produce(_element, draft => {
          const _choices = getMergedChoices(_element, _element?.config?.choices, elements.list);
          draft.config.choices = _choices;
        });
      }

      // TODO: calculate path as path is not available in elmenet anymore
      // const loopParents = _element.path.filter(
      //   parent => elements.list?.[parent]?.type === 'container' && !!elements.list?.[parent]?.config?.loop?.source
      // );
      const loopParents = [];

      if (withLoops && loopParents.length > 0) {
        const recursiveLoopedSource = _getRecursiveLoopedSources(_element, elements, loopParents, translationProps);
        sources.push(...recursiveLoopedSource);
      } else {
        sources.push({
          id: _element.id,
          type: _element.config.qtype,
          label: _element.title,
          element: _element
        });
      }
    }
  }

  return sources;
};

export const convertValuesToChoices = values => {
  let choices = { list: {}, order: [] };

  values.forEach(value => {
    choices.order.push(value.id);
    choices.list[value.id] = value;
  });

  return choices;
};

export const getQueryBuilderSystemVariables = (sources, elements) => {
  elements.order.forEach(systemVariableId => {
    const systemVariable = elements.list[systemVariableId];
    const _element = {
      id: systemVariableId,
      config: {
        qtype: 'single_vector',
        choices: {
          list: produce(systemVariable.list, draft => {
            for (const key in draft) {
              if (systemVariable.id === 'countries') {
                draft[key].id = draft[key].iso3;
              } else if (systemVariable.id === 'locales') {
                draft[key].id = draft[key].locale;
              }
            }
          }),
          order: systemVariable.order
        }
      }
    };

    sources.push({
      id: systemVariableId,
      type: 'question',
      label: systemVariable.label,
      element: _element
    });
  });

  return sources;
};

export const getQueryBuilderCalculationVariables = (sources, elements) => {
  elements?.order?.forEach(calcvarId => {
    const calcvar = elements.list[calcvarId];
    const _element = {
      id: calcvar.id,
      config: {
        qtype: calcvar.multiple ? 'multiple_vector' : 'single_vector',
        choices: convertValuesToChoices(calcvar.values)
      }
    };

    sources.push({
      id: calcvar.id,
      type: 'question',
      label: calcvar.label,
      element: _element
    });
  });

  return sources;
};

const _getRecursiveLoopedSources = (_element, elements, loopParents, translationProps) => {
  let sources = [
    {
      id: _element.id,
      type: _element.config.qtype,
      label: _element.title,
      element: _element,
      sourceLoopConfig: {}
    }
  ];

  loopParents.forEach(loopedParentId => {
    const _sources = [];
    const loopedParentElement = elements.list[loopedParentId];
    const loopSourceId = loopedParentElement?.config?.loop?.source;
    const loopSourceElement = elements.list[loopSourceId];

    const loopIterations = getMergedChoices(loopSourceElement, loopSourceElement?.config?.choices, elements.list);

    loopIterations.order.forEach(loopIterationId => {
      const label = getSubelementPropertyTranslation(
        loopIterations.list[loopIterationId],
        {},
        {
          useShortcodes: true,
          useStripped: true,
          useFallback: true
        },
        translationProps
      );

      sources.forEach(source => {
        _sources.push({
          ...source,
          label: `${source.label} - ${label}`,
          sourceLoopConfig: { ...source.sourceLoopConfig, [loopSourceId]: loopIterationId }
        });
      });
    });

    sources = _sources;
  });

  sources = sources.map((source, idx) =>
    produce(source, draft => {
      draft.id = `${draft.id}_${idx}`;
    })
  );

  return sources;
};
