import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem, ListGroupItemHeading, Collapse } from 'reactstrap';

import { permissionEnum, useUserHasPermission } from 'smv-helpers';

import { AddReportButton } from './AddReportButton';
import { ReportsList } from './';

import './ReportsListContainer.scss';

export const ReportsListContainer = ({ reports, handler, activeFilters }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(state => !state), []);
  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  return (
    <ListGroupItem className='reports-list'>
      <ListGroupItemHeading className='pointer d-flex justify-content-between mb-0 py-2 list-item-sticky'>
        <div onClick={toggleOpen} className='reports-list__headline'>
          <i className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
          <span className='ml-1 reports-list__headline-text'>
            <FormattedMessage id={'smoove.page.reports.reports-list.reports'} defaultMessage={'Reports'} />
          </span>
        </div>
        {userCanEditProject && <AddReportButton saveHandler={handler.addReport} size='icon' />}
      </ListGroupItemHeading>

      <Collapse isOpen={isOpen} className='mt-1'>
        <ReportsList reports={reports} handler={handler} activeFilters={activeFilters} />
        {reports?.loading && !reports?.order && <i className='fas fa-spinner fa-spin ml-3'></i>}
        {reports?.order?.length === 0 && (
          <div className='ml-3'>
            <FormattedMessage
              id={'smoove.page.reports.reports-list.none-available'}
              defaultMessage={'No reports available'}
            />
          </div>
        )}
      </Collapse>
    </ListGroupItem>
  );
};
