import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { tablesActions } from 'smv-redux';

import './WeightTablesModal.scss';

export const WeightTablesModal = ({ isOpen, toggle, folder, isTableConfigInitialState, clearTable }) => {
  const intl = useIntl();

  const surveyId = useSelector(state => state.survey.id);
  const weights = useSelector(state => state.survey.data?.weights);
  const tablesList = useSelector(state => state.survey.tables.list);
  const tablesOrder = useSelector(state => state.survey.tables.order);
  const allFoldersList = useSelector(state => state.survey?.tablesFolders?.list ?? {});

  const [selectedWeight, setSelectedWeight] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, []);

  const tables = useMemo(() => {
    if (folder === 'root') return tablesOrder ?? [];
    if (!folder?.children || !allFoldersList) return [];

    const getNestedTableIds = (ids = [], folder) => {
      if (!folder) return;
      (folder.children ?? []).forEach(el => {
        if (el.type === 'folder') {
          getNestedTableIds(ids, allFoldersList[el.id]);
        } else {
          if (el.type === 'table') ids.push(el.id);
        }
      });
      return ids;
    };

    let tableIds = [];
    return getNestedTableIds(tableIds, folder);
  }, [allFoldersList, folder, tablesOrder]);

  useEffect(() => {
    if (weights && weights.length > 0) {
      setSelectedWeight(weights[0]);
    }
  }, [weights]);

  const applyWeights = useCallback(() => {
    let applyToFolderId = 'root';
    if (folder.id) applyToFolderId = folder.id;
    axios
      .post(`/surveys/${surveyId}/tables/folders/${applyToFolderId}/weight`, { weightId: selectedWeight.id })
      .then(res => {
        setErrorMessage('');
        tablesActions.loadTables(surveyId);
        toggle();
      })
      .catch(err => {
        console.log(err);
        setErrorMessage('something went wrong, please try again');
      });
  }, [surveyId, selectedWeight?.id, folder.id, toggle]);

  if (!weights || !isOpen) return null;

  return (
    <Modal isOpen={isOpen} size={'lg'} toggle={toggle} className='weight-tables-modal'>
      <ModalHeader toggle={toggle}>
        {intl.formatMessage({ id: 'smoove.page.tables.weight-tables.weight-tables' })}
      </ModalHeader>
      <ModalBody>
        {isTableConfigInitialState && (
          <>
            <h5>{intl.formatMessage({ id: 'smoove.page.tables.weight-tables.select-weight' })}</h5>
            {(weights ?? []).map(weight => {
              const isSelectedWeight = weight.id === selectedWeight?.id;
              return (
                <div key={weight.id} onClick={() => setSelectedWeight(weight)} className='pointer'>
                  <span
                    className={`fa${isSelectedWeight ? 's' : 'l'} fa-circle mr-2 ${
                      isSelectedWeight ? 'text-primary' : 'text-secondary'
                    }`}
                  ></span>
                  <span className={`${isSelectedWeight ? 'fw-bold' : ''}`}>{weight.label}</span>
                </div>
              );
            })}
            <h6 className='mt-4'>
              <FormattedMessage
                id={'smoove.page.tables.weight-tables.applies-weight-to-x-tables'}
                defaultMessage={'Apply weight to the following tables'}
                values={{ x: <span>{tables.length}</span> }}
              />
            </h6>
            <div className='apply-to-list'>
              {(tables ?? []).map(id => {
                return (
                  <div key={id}>
                    <small>{tablesList?.[id]?.name}</small>
                  </div>
                );
              })}
            </div>
            {Boolean(errorMessage) && <div className='text-warning mt-2'>{errorMessage}</div>}
          </>
        )}
        {!isTableConfigInitialState && (
          <>
            <div>{intl.formatMessage({ id: 'smoove.page.tables.weight-tables.working-on-table-text' })}</div>
            <Button color='danger' outline onClick={clearTable} className='mt-2'>
              {intl.formatMessage({ id: 'smoove.page.tables.weight-tables.reset-tables-page' })}
            </Button>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button className={`edit-text-btn mt-2 mb-2`} outline={true} onClick={toggle}>
          {intl.formatMessage({ id: 'smoove.common.buttons.close' })}
        </Button>
        {isTableConfigInitialState && (
          <Button className={`edit-text-btn mt-2 mb-2`} color={'primary'} onClick={applyWeights}>
            {intl.formatMessage({ id: 'smoove.page.tables.weight-tables.apply-weight-to-all-listed-tables' })}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export const WeightTablesButton = ({ buttonText = 'Apply weight', toggle }) => {
  return (
    <>
      <div className='smv-custom-dropdown__buttons' onClick={toggle}>
        <i className={'fal fa-scale-unbalanced icon-smv-blue mr-1'}></i>
        <span>{buttonText}</span>
      </div>
    </>
  );
};
