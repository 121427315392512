import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Collapse, Row, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import produce from 'immer';

import { QueryBuilder, InlineEdit } from 'smv-components';
import { getQueryBuilderSources, getQuestionnaireElementsOrder, useTranslationHooks } from 'smv-helpers';
import { dataPrepActions } from '../../../redux/actions';

import {
  getQueryBuilderEmptyState,
  getQueryBuilderWithoutElementsWithErrors,
  handleQuerybuilderAutosave
} from '../../querybuilder/helper';

import './Calculation.scss';

export const CalculationValue = ({ calculation, value, handler, readOnly }) => {
  const survey = useSelector(state => state.survey);
  const questions = useSelector(state => state.survey.questionnaire);
  const data = useSelector(state => state.survey?.data);

  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const translationProps = useTranslationHooks();

  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const sources = useMemo(() => {
    const rawSources = {
      question: { list: questions.list, order: getQuestionnaireElementsOrder(questions) },
      sysvar: systemVariables,
      calcvar: data.calculations
    };

    const _sources = getQueryBuilderSources(rawSources, null, 'above', true, true, translationProps, [
      'multiple_input_number'
    ]);

    return _sources;
  }, [questions, systemVariables, data, translationProps]);

  const internalHandler = {
    setLabel: newLabel => {
      handler.updateValue(value.id, { ...value, label: newLabel });
    },
    setValue: newValue => {
      handler.updateValue(value.id, { ...value, value: newValue });
    },
    // setCondition: val => {
    //   dataPrepActions.saveCalculationValue(surveyid, calculation, {
    //     ...value,
    //     condition: { elements: val.elements, rootElement: val.rootElement }
    //   });
    // },
    deleteValue: () => handler.deleteValue(value.id)
  };

  return (
    <Row className='smv-calculation-value'>
      <Col md={{ size: 3, offset: 0 }}>
        <div className='d-flex align-items-center'>
          <i
            onClick={() => setIsOpen(b => !b)}
            id={`toggle_${value.id}_calc`}
            className={`pointer fas fa-fw fa-chevron-right rotate ${isOpen ? 'rotate-90' : ''}`}
          ></i>
          <UncontrolledTooltip className='tooltip-test' placement='top' target={`toggle_${value.id}_calc`} fade={true}>
            {isOpen ? (
              <FormattedMessage id={'smoove.common.buttons.hide'} defaultMessage={'Hide'} />
            ) : (
              <FormattedMessage id={'smoove.common.buttons.show'} defaultMessage={'Show'} />
            )}
          </UncontrolledTooltip>
          <div className='d-flex align-items-center'>
            <b className='bold mr-1 pointer smv-calculation-label' onClick={() => setIsOpen(b => !b)}>
              <FormattedMessage id={'smoove.page.data.calculation.label'} defaultMessage={'Label:'} />
            </b>
            <InlineEdit
              className='smv-calculation__title'
              defaultValue={value?.label || ''}
              placeholder={intl.formatMessage({ id: `smoove.page.data.calculation.enter-label` })}
              saveFn={val => internalHandler.setLabel(val)}
              disabled={readOnly}
            />
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <div className='d-flex align-items-center mt-1'>
            <b className='bold mr-1 smv-calculation-label' style={{ marginLeft: '18px' }}>
              <FormattedMessage id={'smoove.page.data.calculation.value'} defaultMessage={'Value:'} />
            </b>
            <InlineEdit
              className='smv-calculation__title'
              defaultValue={value?.value || 1}
              placeholder={intl.formatMessage({ id: `smoove.page.data.calculation.enter-value` })}
              saveFn={value => internalHandler.setValue(value)}
              disabled={readOnly}
            />
          </div>
        </Collapse>
      </Col>
      {value.id && (
        <Col md={{ size: 8, offset: 0 }}>
          <Collapse isOpen={isOpen}>
            {isOpen && (
              <CalculationCondition
                sources={sources}
                surveyId={survey.id}
                calculationId={calculation.id}
                value={value}
                readOnly={readOnly}
              />
            )}
          </Collapse>
        </Col>
      )}

      <Col md={{ size: 1, offset: 0 }} className='d-flex justify-content-end p-0'>
        {value.id && (
          <>
            <Button
              color='danger'
              outline
              className='mr-1'
              onClick={internalHandler.deleteValue}
              id={`del_value_${value.id}`}
              disabled={readOnly}
            >
              <i className='fal fa-trash-alt'></i>
            </Button>
            <UncontrolledTooltip placement='top' target={`del_value_${value.id}`} fade={true}>
              <FormattedMessage id={'smoove.page.data.calculation.delete-value'} defaultMessage={'Delete value'} />
            </UncontrolledTooltip>
          </>
        )}
      </Col>
    </Row>
  );
};

// CalculationValueCondition would be a better name?
const CalculationCondition = ({ sources, surveyId, calculationId, value, readOnly }) => {
  const [_queryBuilder, setQueryBuilder] = useState(value?.condition);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    if (!!value.condition && !!value.condition?.rootElementId) {
      if (!_queryBuilder?.id) {
        setQueryBuilder(state =>
          produce(state, d => {
            d.id = value.condition.id;
          })
        );
      }
    } else {
      setQueryBuilder(getQueryBuilderEmptyState());
    }
  }, [value.condition, _queryBuilder?.id]);

  const saveHandler = useCallback(() => {
    const conditionId = value.condition?.id ? value.condition.id : null;
    setIsSaving(true);
    dataPrepActions
      .updateCalculationValueConditionElement(
        surveyId,
        calculationId,
        value.id,
        conditionId,
        getQueryBuilderWithoutElementsWithErrors(_queryBuilder)
      )
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [_queryBuilder, surveyId, calculationId, value?.condition?.id, value?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    dataPrepActions
      .removeCalculationValueConditionElement(surveyId, calculationId, value.id, value.condition.rootElementId)
      .then(res => {
        console.log(res);
        setIsSaving(false);
        setWarning(null);
      })
      .catch(err => {
        console.log(err);
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyId, calculationId, value.id, value.condition.rootElementId]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      _queryBuilder,
      value.condition,
      sources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [
    _queryBuilder,
    saveHandler,
    isSaving,
    value.condition,
    sources,
    calculationId,
    surveyId,
    value.id,
    deleteHandler
  ]);

  if (!value?.condition) {
    return null;
  }

  return (
    <QueryBuilder
      name='calculation_condition'
      fields={sources}
      queryBuilder={_queryBuilder}
      readOnly={readOnly}
      setQueryBuilder={setQueryBuilder}
      warning={warning}
      isSaving={isSaving}
    />
  );
};
