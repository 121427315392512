import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { Store as notify } from 'react-notifications-component';
import { arrayMoveImmutable } from 'array-move';
// import _ from 'lodash';
import classNames from 'classnames';

import { questionnaireActions } from 'smv-redux';

import { SharedChoice, Choice, NewItem } from '.';

import './VectorChoices.scss';

export const VectorChoices = ({ question, writeable, limit = 10 }) => {
  const list = useSelector(state => state.survey.questionnaire.list);
  const surveyid = useSelector(state => state.survey.id);

  const [collapsedImported, setCollapsedImported] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [order, setOrder] = useState(question?.config?.choices?.order ?? []);

  useEffect(() => {
    if (question?.config?.choices?.order) {
      setOrder(question?.config?.choices?.order);
    }
  }, [question?.config?.choices?.order]);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;
      const newOrder = arrayMoveImmutable(order, source.index, destination.index);

      setOrder(newOrder);
      questionnaireActions.updateChoice(surveyid, question.id, order[source.index], { position: destination.index });
    },
    [order, surveyid, question.id]
  );

  const choicesLength = useMemo(() => {
    return question.config?.choices?.order?.length ?? 0;
  }, [question.config?.choices]);

  const importedChoicesLength = useMemo(() => {
    if (
      question.config?.choices?.import &&
      question.config.choices.import?.source &&
      list[question.config.choices.import.source]
    ) {
      return list[question.config.choices.import.source]?.config?.choices?.order?.length ?? 0;
    }
    return 0;
  }, [list, question.config?.choices]);

  const importedChoices = useMemo(() => {
    let _importedChoices = [];
    let _importedCounter = 0;
    if (question.config?.choices?.import && question.config.choices.import?.source) {
      const sourceQuestion = list?.[question.config.choices.import.source];
      if (!sourceQuestion)
        _importedChoices.push(
          <div key='invalid_config_for_imported_answers' className='d-flex align-items-center my-2'>
            <i className='far fa-exclamation-triangle text-smv-orange' />
            <small className='d-block ml-2'>
              Invalid configuration for imported answers, maybe the source question has been deleted. Please review the
              configuration.
            </small>
          </div>
        );
      else if (sourceQuestion.config?.choices?.order && sourceQuestion.config.choices.order.length > 0) {
        sourceQuestion.config.choices.order.forEach(choiceid => {
          if (_importedCounter < limit || !collapsedImported) {
            const choice = sourceQuestion.config.choices.list[choiceid];
            _importedChoices.push(
              <SharedChoice key={choiceid} question={question} choice={choice} writeable={writeable} />
            );
            _importedCounter++;
          }
        });
      }
    }
    return _importedChoices;
  }, [collapsedImported, limit, list, question, writeable]);

  const shortOrder = useMemo(() => {
    if (!collapsed || order.length < limit) return order;

    if (collapsed) {
      return order.slice(0, limit);
    }
  }, [collapsed, order, limit]);

  return (
    <>
      {/* Todo: Add import option here? or maybe after the items headline? */}
      {importedChoicesLength > 0 && <div className='mt-1 mb-1 fw-bold'>Imported Items</div>}
      {importedChoices}
      {importedChoicesLength > limit && (
        <div className='pointer btn-link text-smv-blue pt-2' onClick={() => setCollapsedImported(!collapsedImported)}>
          <i
            className={classNames('fa mr-2', {
              'fa-angle-double-down': collapsedImported,
              'fa-angle-double-up': !collapsedImported
            })}
          ></i>
          {collapsedImported && (
            <FormattedMessage
              defaultMessage={'show all'}
              id={`smoove.common.buttons.unfold-x-items`}
              values={{ cnt: importedChoicesLength - limit }}
            />
          )}
          {!collapsedImported && (
            <FormattedMessage defaultMessage={'show less'} id={`smoove.common.buttons.fold-items`} />
          )}
        </div>
      )}

      <div className='mt-1 mb-1 fw-bold'>Items</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={'question-items-container'}>
          <MakeDroppable droppableId='choice-item-droppable-que' writeable={writeable}>
            {shortOrder.map((id, index) => {
              const choice = question.config?.choices?.list?.[id];
              if (!choice) {
                return null;
              }

              return (
                <MakeDraggable key={id} draggableId={id} index={index} isDragDisabled={!writeable}>
                  <Choice key={id} question={question} choice={choice} writeable={writeable} />
                </MakeDraggable>
              );
            })}
          </MakeDroppable>
        </div>
        {choicesLength > limit && (
          <div className='pointer btn-link text-smv-blue pt-2' onClick={() => setCollapsed(!collapsed)}>
            <i
              className={classNames('fa mr-2', {
                'fa-angle-double-down': collapsed,
                'fa-angle-double-up': !collapsed
              })}
            ></i>
            {collapsed && (
              <FormattedMessage
                defaultMessage={'show all'}
                id={`smoove.common.buttons.unfold-x-items`}
                values={{ cnt: choicesLength - limit }}
              />
            )}
            {!collapsed && <FormattedMessage defaultMessage={'show less'} id={`smoove.common.buttons.fold-items`} />}
          </div>
        )}
      </DragDropContext>

      {writeable && (
        <>
          <hr className='my-1' />
          <NewItem question={question} type={'choice'} />
        </>
      )}
    </>
  );
};

const getDraggableStyles = isDragging => {
  return {
    boxSizing: 'border-box',
    borderRadius: isDragging && '4px',
    background: 'white',
    boxShadow: isDragging
      ? '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)'
      : 'none'
  };
};

const MakeDraggable = ({ children, draggableId, index, isDragDisabled }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            ...getDraggableStyles(snapshot.isDragging)
          }}
          // className='draggable-default'
          tabIndex='-1'
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const MakeDroppable = ({ children, droppableId, writeable }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={!writeable}>
      {(provided, snapshot) => {
        return (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
