import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { questionnaireActions } from 'smv-redux';
import { getMergedChoices } from 'src/smoove/components/misc/helper';

export const RankingMatrixConfig = ({ writeable, question }) => {
  const intl = useIntl();
  const surveyid = useSelector(state => state.survey.id);
  const questionnaireList = useSelector(state => state.survey.questionnaire?.list ?? {});
  const choices = getMergedChoices(question, question.config.choices, questionnaireList);
  const numberOfChoices = useMemo(() => choices?.order?.length ?? 0, [choices?.order?.length]);

  const [isRankingTypeDropdownOpen, setIsRankingTypeDropdownOpen] = useState(false);
  const toggleRankingTypeDropdown = useCallback(() => {
    setIsRankingTypeDropdownOpen(b => !b);
  }, []);

  const [isIndentationDropdownOpen, setIsIndentaionDropdownOpen] = useState(false);
  const toggleIndentationDropdown = useCallback(() => {
    setIsIndentaionDropdownOpen(b => !b);
  }, []);

  const [isAlignmentDropdownOpen, setIsAlignmentDropdownOpen] = useState(false);
  const toggleAlignmentDropdown = useCallback(() => {
    setIsAlignmentDropdownOpen(b => !b);
  }, []);

  const [minimumNumberOfResponses, setMinimumNumberOfResponses] = useState(0);
  const [maximumNumberOfResponses, setMaximumNumberOfResponses] = useState(0); // 0 = tivian not set === max items

  useEffect(() => {
    setMinimumNumberOfResponses(question.config.minSelections);
    setMaximumNumberOfResponses(question.config.maxSelections);
  }, [question]);

  const handleUpdateQuestinGeneric = useCallback(
    (item, value) => {
      questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
        config: { [item]: value }
      });
    },
    [surveyid, question.id]
  );

  const handleSaveMinimumNumberOfResponses = useCallback(
    value => {
      const _value = parseInt(value);
      if (_value > numberOfChoices) {
        // add hint that it has been reset
        setMinimumNumberOfResponses(question.config.minSelections);
      } else {
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { minSelections: _value }
        });
      }
    },
    [numberOfChoices, surveyid, question.id, question.config.minSelections]
  );

  const handleSaveMaximumNumberOfResponses = useCallback(
    value => {
      const _value = parseInt(value);
      if (_value > numberOfChoices) {
        // add hint that it has been reset
        setMaximumNumberOfResponses(question.config.maxSelections);
      } else {
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { maxSelections: _value }
        });
      }
    },
    [numberOfChoices, surveyid, question.config.maxSelections, question.id]
  );

  return (
    <div>
      <Row>
        <Col sm='6' lg='5' className='d-flex align-items-center'>
          {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.ranking-type' })}
          <i className='fal fa-circle-info ml-2' id={`tt_ranking-type`} />
          <UncontrolledTooltip target={`tt_ranking-type`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.select-the-ranking-type' })}
          </UncontrolledTooltip>
        </Col>
        <Col sm='6' lg='4'>
          <Dropdown isOpen={isRankingTypeDropdownOpen} size='sm' toggle={toggleRankingTypeDropdown}>
            <DropdownToggle caret outline color={'secondary'} className='role-dropdown'>
              {question.config.rankingType}
            </DropdownToggle>
            <DropdownMenu>
              {['click', 'drag'].map(el => {
                return (
                  <DropdownItem
                    key={el}
                    active={question.config.rankingType === el}
                    onClick={() => {
                      handleUpdateQuestinGeneric('rankingType', el);
                      toggleRankingTypeDropdown();
                    }}
                  >
                    {el}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          {question.config.rankingType === 'drag' && (
            <small>
              {intl.formatMessage({
                id: 'smoove.questionnaire.modal.config-tab.note-the-drag-ranking-is-not-suitable-for-mobile'
              })}
            </small>
          )}
        </Col>
      </Row>
      <hr />
      {question.config.rankingType === 'click' && (
        <div>
          <Row>
            <Col sm='6' lg='5' className='d-flex align-items-center'>
              {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.indentation' })}
              <i className='fal fa-circle-info ml-2' id={`tt_ranking-indentation`} />
              <UncontrolledTooltip target={`tt_ranking-indentation`} delay={{ hide: 200 }} autohide={false}>
                {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.select-indentation' })}
              </UncontrolledTooltip>
            </Col>
            <Col sm='6' lg='4'>
              <Dropdown isOpen={isIndentationDropdownOpen} size='sm' toggle={toggleIndentationDropdown}>
                <DropdownToggle caret outline color={'secondary'} className='role-dropdown'>
                  {intl.formatMessage({ id: `smoove.common.${question.config.indentation}` })}
                </DropdownToggle>
                <DropdownMenu>
                  {['left', 'right'].map(el => {
                    return (
                      <DropdownItem
                        key={el}
                        active={question.config.indentation === el}
                        onClick={() => {
                          handleUpdateQuestinGeneric('indentation', el);
                          toggleIndentationDropdown();
                        }}
                      >
                        {intl.formatMessage({ id: `smoove.common.${el}` })}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <hr />
        </div>
      )}
      <Row>
        <Col sm='6' lg='5' className='d-flex align-items-center'>
          {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.alignment' })}
          <i className='fal fa-circle-info ml-2' id={`tt_ranking-alignment`} />
          <UncontrolledTooltip target={`tt_ranking-alignment`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.select-alignment' })}
          </UncontrolledTooltip>
        </Col>
        <Col sm='6' lg='4'>
          <Dropdown isOpen={isAlignmentDropdownOpen} size='sm' toggle={toggleAlignmentDropdown}>
            <DropdownToggle caret outline color={'secondary'} className='role-dropdown'>
              {intl.formatMessage({ id: `smoove.common.${question.config.alignment}` })}
            </DropdownToggle>
            <DropdownMenu>
              {['left', 'center', 'right'].map(el => {
                return (
                  <DropdownItem
                    key={el}
                    active={question.config.alignment === el}
                    onClick={() => {
                      handleUpdateQuestinGeneric('alignment', el);
                      toggleAlignmentDropdown();
                    }}
                  >
                    {intl.formatMessage({ id: `smoove.common.${el}` })}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm='6' lg='5' className='d-flex align-items-center'>
          {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.minimum-number-of-responses' })}
          <i className='fal fa-circle-info ml-2' id={`tt_min_number_of_responses`} />
          <UncontrolledTooltip target={`tt_min_number_of_responses`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.minimum-number-of-responses-tt' })}
          </UncontrolledTooltip>
        </Col>
        <Col sm='6' lg='4'>
          <Input
            id={`input_min_number_of_responses`}
            placeholder='Min. responses'
            bsSize='sm'
            type='number'
            min={0}
            max={numberOfChoices}
            disabled={!writeable}
            value={minimumNumberOfResponses}
            onChange={e => setMinimumNumberOfResponses(e.target.value)}
            onBlur={e => handleSaveMinimumNumberOfResponses(e.target.value)}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm='6' lg='5' className='d-flex align-items-center'>
          {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.maximum-number-of-responses' })}
          <i className='fal fa-circle-info ml-2' id={`tt_max_number_of_responses`} />
          <UncontrolledTooltip target={`tt_max_number_of_responses`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.enter-maximum-number-of-responses' })}
          </UncontrolledTooltip>
        </Col>
        <Col sm='6' lg='4'>
          <Input
            id={`input_max-number_of_responses`}
            placeholder='Max. responses'
            bsSize='sm'
            type='number'
            min={0}
            max={numberOfChoices}
            disabled={!writeable}
            value={maximumNumberOfResponses}
            onChange={e => setMaximumNumberOfResponses(e.target.value)}
            onBlur={e => handleSaveMaximumNumberOfResponses(e.target.value)}
          />
          <small>
            {intl.formatMessage({
              id: 'smoove.questionnaire.modal.config-tab.0-equals-to-the-maximum-amount-of-choices'
            })}
          </small>
        </Col>
      </Row>
      <hr />
    </div>
  );
};
