import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Collapse, Row, Col, Input, UncontrolledTooltip, FormGroup } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import produce from 'immer';

import { getQuestionnaireElementsOrder, stripHtml } from 'smv-helpers';
import { SyntheticDataWarning } from '../../../misc/SyntheticDataWarning';
import { getMergedChoices } from '../../../misc/helper';
import { ActiveIndicator } from 'src/smoove/components/misc';

import { WeightDefinitionTable } from '..';
import { WeightDefinitionFilterModal } from './WeightDefinitionFilterModal';
import { isCalculation } from '../helpers/isCalculation';

export const WeightDefinition = ({ weightId, definition, weightHandler, readOnly, definitionCount }) => {
  const [isDefinitionTableOpen, setIsDefinitionTableOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const toggleFilterModal = useCallback(() => {
    setIsFilterModalOpen(b => !b);
  }, []);

  const questionnaire = useSelector(state => state.survey.questionnaire);
  const translations = useSelector(state => state.survey.translations);
  const surveyId = useSelector(state => state.survey.id);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyId]?.selectedLocale ?? state.survey.locales.main
  );

  const calculations = useSelector(state => state.survey?.data?.calculations);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const intl = useIntl();

  const sources = useMemo(() => {
    const WHITELIST_QUESTION_TYPES = ['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix'];
    const queOrder = getQuestionnaireElementsOrder(questionnaire);

    // filter valid question types
    const _questions = { list: {}, order: [] };
    (queOrder ?? []).forEach(elementid => {
      const element = questionnaire.list[elementid];
      // const { isLoopedQuestion } = isLooped(questions.list, element);

      if (
        element.type === 'question' &&
        WHITELIST_QUESTION_TYPES.includes(element.config.qtype) &&
        element.config.displayType !== 'ranking'
      ) {
        _questions.order.push(elementid);
        _questions.list[elementid] = element;
      }
    });

    const calcvars = {
      list: Object.fromEntries((calculations ?? [])?.map(value => [value.id, value])),
      order: (calculations ?? [])?.map(el => el.id)
    };

    const rawSources = {
      questions: _questions,
      sysvars: systemVariables,
      calcvars: calcvars
    };

    const flatSources = {};
    Object.keys(rawSources).forEach(key => {
      rawSources[key].order.forEach(elementid => {
        flatSources[elementid] = rawSources[key].list[elementid];
      });
    });

    return flatSources;
  }, [questionnaire, systemVariables, calculations]);

  const setDefinitionProperty = (property, value) => {
    if (definition) {
      weightHandler.updateWeightDefinition(
        weightId,
        produce(definition, draft => {
          draft[property] = value;
          delete draft.inputTargetValues;
          draft.inputTargetValues = {};
          draft.exclude = {
            headChoiceIds: [],
            rowChoiceIds: []
          };
        })
      );
    }
  };

  const setSource = sourceId => {
    const source = sources[sourceId];
    if (definition) {
      weightHandler.updateWeightDefinition(
        weightId,
        produce(definition, draft => {
          draft.sourceId = source.id;
          draft.sourceType = source.type ? source.type : isCalculation(calculations, sourceId) ? 'calcvar' : null;
          draft.choiceId = null;
          draft.scaleId = null;
          delete draft.inputTargetValues;
          draft.inputTargetValues = {};
          draft.exclude = {
            rowChoiceIds: []
          };
        })
      );
    }
  };

  const setSubgroupSource = sourceId => {
    const source = sources[sourceId];
    if (definition) {
      weightHandler.updateWeightDefinition(
        weightId,
        produce(definition, draft => {
          draft.subgroupSourceId = source.id;
          draft.subgroupSourceType = source.type
            ? source.type
            : isCalculation(calculations, sourceId)
            ? 'calcvar'
            : null;
          draft.subgroupChoiceId = null;
          delete draft.inputTargetValues;
          draft.inputTargetValues = {};
          draft.exclude = {
            headChoiceIds: []
          };
          // draft.subgroupScaleId = null;
        })
      );
    }
  };

  const toggleInSubgroups = value => {
    if (definition) {
      weightHandler.updateWeightDefinition(
        weightId,
        produce(definition, draft => {
          draft.inSubgroups = value;
          draft.subgroupSourceType = null;
          draft.subgroupSourceId = null;
          draft.subgroupChoiceId = null;
          draft.inputTargetValues = {};
          draft.exclude = {
            headChoiceIds: []
          };
          draft.mode = 'manual_align';
          // draft.subgroupScaleId = null;
        })
      );
    }
  };

  const sourceId = definition?.sourceId;
  const sourceElement = sources[sourceId];
  const isSourceMatrixQuestion = ['single_matrix', 'multiple_matrix'].includes(sourceElement?.config?.qtype);
  const isSourceMultiMatrix = sourceElement?.config?.qtype === 'multiple_matrix';
  const isSourceMultiVector = sourceElement?.config?.qtype === 'multiple_vector';
  const isSourceMultiCalc = isCalculation(calculations, sourceId) && sourceElement.multiple;
  let sourceChoices = null;
  if (definition.sourceType === 'question') {
    sourceChoices = getMergedChoices(sourceElement, sourceElement?.config?.choices ?? {}, questionnaire.list);
  }
  if (definition.sourceType === 'calcvar' && isSourceMultiCalc) {
    sourceChoices = sourceElement.values;
  }

  const subgroupSourceId = definition?.subgroupSourceId;
  const subgroupSourceElement = sources[subgroupSourceId];
  const isSubgroupSourceMatrixQuestion = ['single_matrix', 'multiple_matrix'].includes(
    subgroupSourceElement?.config?.qtype
  );
  const isSubgroupSourceMultiVector = subgroupSourceElement?.config?.qtype === 'multiple_vector';
  const isSubgroupSourceMultiCalc = isCalculation(calculations, subgroupSourceId) && subgroupSourceElement?.multiple;

  let subgroupSourceChoices = null;
  if (definition.subgroupSourceType === 'question') {
    subgroupSourceChoices = getMergedChoices(
      subgroupSourceElement,
      subgroupSourceElement?.config?.choices ?? {},
      questionnaire.list
    );
  }
  if (definition.subgroupSourceType === 'calcvar' && isSubgroupSourceMultiCalc) {
    subgroupSourceChoices = subgroupSourceElement.values;
  }
  // const isSubgroupSourceMultiMatrix = subgroupSourceElement?.config?.qtype === 'multiple_matrix';

  return (
    <>
      <Card className='data-weight-definition mt-1'>
        <Row className='mb-1'>
          <Col className='data-weight-definition__headline'>
            <div>
              <b>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-definition' })}</b>
            </div>
            <div className='text-right position-relative'>
              <Button size='sm' color='secondary' outline onClick={toggleFilterModal} disabled={readOnly}>
                <i className='fal fa-filter' />
              </Button>
              {definition.staticTableFilters && <ActiveIndicator />}
            </div>
            <span>
              {definitionCount > 1 && (
                <>
                  <Button
                    color='danger'
                    outline
                    onClick={() => weightHandler.deleteWeightDefinition(weightId, definition.id)}
                    id={`del_def_${definition.id}`}
                    className='ml-2'
                    disabled={readOnly}
                  >
                    <i className='fal fa-trash-alt'></i>
                  </Button>
                  <UncontrolledTooltip
                    className='tooltip-test'
                    placement='top'
                    target={`del_def_${definition.id}`}
                    fade={false}
                  >
                    {intl.formatMessage({ id: 'smoove.page.data.weight.delete-definition' })}
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </Col>
        </Row>
        <Row className='mt-1 align-items-center'>
          <Col md={5}>{intl.formatMessage({ id: 'smoove.page.data.weight.source' })}</Col>
          <Col md={7}>
            <div className='d-flex align-items-center'>
              <Input
                className={`${isSourceMatrixQuestion || isSourceMultiVector || isSourceMultiCalc ? 'mr-2' : ''}`}
                type='select'
                label={intl.formatMessage({ id: 'smoove.page.data.weight.source' })}
                id={`Source`}
                name='Source'
                value={definition.sourceId ?? ''}
                onChange={({ target: { value } }) => setSource(value)}
                disabled={readOnly}
              >
                <option value='' disabled>
                  {intl.formatMessage({ id: 'smoove.page.data.weight.choose-source' })}
                </option>
                {Object.keys(sources).map(sourceId => {
                  const source = sources[sourceId];
                  if (source.type === 'sysvar' || !source.type) {
                    return (
                      <option key={sourceId} value={source.id}>
                        {source.label ?? 'unlabeled variable'}
                      </option>
                    );
                  }
                  return (
                    <option key={sourceId} value={source.id}>
                      {source.config?.varname ?? ''}
                      {source.config?.varname ? ' - ' : ''}
                      {source.title}
                    </option>
                  );
                })}
              </Input>
              {(isSourceMatrixQuestion || isSourceMultiVector || isSourceMultiCalc) && (
                <Input
                  type='select'
                  className={`${isSourceMultiMatrix ? 'mr-2' : ''}`}
                  label={intl.formatMessage({ id: 'smoove.page.data.weight.definition.choice' })}
                  id={`choice`}
                  name='choice'
                  // disabled={!definition.choiceId}
                  value={definition.choiceId ?? ''}
                  onChange={({ target: { value } }) => setDefinitionProperty('choiceId', value)}
                  disabled={readOnly}
                >
                  <option value='' disabled>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.choose-source' })}
                  </option>
                  {definition.sourceType === 'question' &&
                    sourceChoices.order.map(choiceId => {
                      const choice = sourceChoices.list[choiceId];
                      return (
                        <option key={choiceId} value={choiceId}>
                          {stripHtml(translations?.[choice.config.translations.label]?.[selectedLocale] ?? '')}
                        </option>
                      );
                    })}
                  {definition.sourceType === 'calcvar' &&
                    sourceChoices.map(el => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.label}
                        </option>
                      );
                    })}
                </Input>
              )}
              {isSourceMultiMatrix && (
                <Input
                  type='select'
                  label={'scale'}
                  id={`scale`}
                  name='scale'
                  // disabled={!definition.sourceId}
                  value={definition.scaleId ?? ''}
                  onChange={({ target: { value } }) => setDefinitionProperty('scaleId', value)}
                  disabled={readOnly}
                >
                  <option value='' disabled>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.choose-source' })}
                  </option>
                  {sourceElement.config.scales.order.map(scaleId => {
                    const scale = sourceElement.config.scales.list[scaleId];
                    return (
                      <option key={scaleId} value={scaleId}>
                        {stripHtml(translations?.[scale.config.translations.label]?.[selectedLocale] ?? '')}
                      </option>
                    );
                  })}
                </Input>
              )}
            </div>
          </Col>
        </Row>

        <Row className='mt-1 align-items-center'>
          <Col md={5} className='d-flex align-items-center'>
            <span className='mr-2'>{intl.formatMessage({ id: 'smoove.page.data.weight.subgroups' })}</span>
            <FormGroup switch style={{ display: ' flex', alignItems: 'center', pddingBottom: '4px' }}>
              <Input
                type='switch'
                role='switch'
                id={`${definition.id}_insubgroups`}
                name={'weight_def_insubgroups'}
                checked={definition.inSubgroups}
                onChange={({ target }) => toggleInSubgroups(target.checked)}
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
          <Col md={7}>
            <div className='d-flex align-items-center'>
              <Input
                className={`${
                  isSubgroupSourceMatrixQuestion || isSubgroupSourceMultiVector || isSubgroupSourceMultiCalc
                    ? 'mr-2'
                    : ''
                }`}
                type='select'
                label={intl.formatMessage({ id: 'smoove.page.data.weight.definition.subgroup' })}
                id={`subgroup`}
                disabled={!definition.inSubgroups || readOnly}
                name='subgroup'
                value={definition.subgroupSourceId || ''}
                onChange={({ target: { value } }) => setSubgroupSource(value)}
              >
                <option value='' disabled>
                  {intl.formatMessage({ id: 'smoove.page.data.weight.choose-subgroup' })}
                </option>
                {Object.keys(sources).map(sourceId => {
                  const source = sources[sourceId];
                  if (
                    source.type === 'question' &&
                    ['multiple_matrix', 'single_matrix'].includes(source?.config?.qtype)
                  ) {
                    return null;
                  }
                  if (source.type === 'sysvar' || !source.type) {
                    return (
                      <option key={sourceId} value={source.id}>
                        {source.label ?? 'unlabeled variable'}
                      </option>
                    );
                  }
                  return (
                    <option key={sourceId} value={source.id}>
                      {source.config?.varname ?? ''}
                      {source.config?.varname ? ' - ' : ''}
                      {source.title}
                    </option>
                  );
                })}
              </Input>
              {(isSubgroupSourceMatrixQuestion || isSubgroupSourceMultiVector || isSubgroupSourceMultiCalc) && (
                <Input
                  // className={`${isSubgroupSourceMultiMatrix ? 'mr-2' : ''}`}
                  type='select'
                  label={'subgroupChoiceId'}
                  id={`subgroupChoiceId`}
                  disabled={!definition.inSubgroups || readOnly}
                  name='subgroupChoiceId'
                  value={definition.subgroupChoiceId || ''}
                  onChange={({ target: { value } }) => setDefinitionProperty('subgroupChoiceId', value)}
                >
                  <option value='' disabled>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.choose-subgroup' })}
                  </option>
                  {definition.subgroupSourceType === 'question' && (
                    <>
                      {subgroupSourceChoices.order.map(choiceId => {
                        const choice = subgroupSourceChoices.list[choiceId];
                        return (
                          <option key={choiceId} value={choiceId}>
                            {stripHtml(translations?.[choice.config.translations.label]?.[selectedLocale] ?? '')}
                          </option>
                        );
                      })}
                    </>
                  )}
                  {definition.subgroupSourceType === 'calcvar' && (
                    <>
                      {(subgroupSourceChoices ?? []).map(el => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.label}
                          </option>
                        );
                      })}
                    </>
                  )}
                </Input>
              )}
              {/* {isSubgroupSourceMultiMatrix && (
                <Input
                  type='select'
                  label={'subgroupScaleId'}
                  id={`subgroupScaleId`}
                  // disabled={!definition.inSubgroups}
                  name='subgroupScaleId'
                  value={definition.subgroupScaleId || ''}
                  onChange={({ target: { value } }) => setDefinitionProperty('subgroupScaleId', value)}
                >
                  <option value='' disabled>
                    {intl.formatMessage({ id: 'smoove.page.data.weight.choose-subgroup' })}
                  </option>
                  {subgroupSourceElement.config.scales.order.map(scaleId => {
                    const scale = subgroupSourceElement.config.scales.list[scaleId];
                    return (
                      <option key={scaleId} value={scaleId}>
                        {stripHtml(translations?.[scale.config.translations.label]?.[selectedLocale] ?? '')}
                      </option>
                    );
                  })}
                </Input>
              )} */}
            </div>
          </Col>
        </Row>
        {definition.inSubgroups && (
          <Row className='mt-1 align-items-center'>
            <Col md={5} className='d-flex align-items-center'>
              <span className='mr-2'>
                {intl.formatMessage({ id: 'smoove.page.data.weight.definition.weight-method' })}
              </span>
            </Col>
            <Col md={7}>
              <Input
                type='select'
                label={intl.formatMessage({ id: 'smoove.page.data.weight.mode' })}
                id={`mode`}
                disabled={readOnly}
                name='mode'
                value={definition.mode || ''}
                onChange={({ target }) => setDefinitionProperty('mode', target.value)}
              >
                {['manual_align', 'total_align'].map(mode => {
                  return (
                    <option key={mode} value={mode}>
                      {/* todo: translation */}
                      {mode}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
        )}
        <Row className='mt-2 align-items-center'>
          <Col md={12}>
            <div className='d-flex align-items-center'>
              <i
                onClick={() => setIsDefinitionTableOpen(b => !b)}
                className={`pointer fas fa-fw fa-chevron-right rotate ${isDefinitionTableOpen ? 'rotate-90' : ''}`}
              ></i>
              <span className='pointer' onClick={() => setIsDefinitionTableOpen(b => !b)}>
                {isDefinitionTableOpen
                  ? intl.formatMessage({ id: 'smoove.page.data.weight.hide-weighting-table' })
                  : intl.formatMessage({ id: 'smoove.page.data.weight.show-weighting-table' })}
              </span>
            </div>
          </Col>
        </Row>

        <Collapse isOpen={isDefinitionTableOpen} className={'mt-1'}>
          <SyntheticDataWarning className='mb-0' />
          <Row className='align-items-center'>
            <Col md={12}>
              {isDefinitionTableOpen && (
                <WeightDefinitionTable
                  weightId={weightId}
                  definition={definition}
                  weightHandler={weightHandler}
                  sources={sources}
                  readOnly={readOnly}
                />
              )}
            </Col>
          </Row>
        </Collapse>
      </Card>
      <WeightDefinitionFilterModal
        isOpen={isFilterModalOpen}
        toggle={toggleFilterModal}
        weightId={weightId}
        definition={definition}
      />
    </>
  );
};
