export const chartLayersActionTypes = {
  // CHART LAYERS / VIEWS
  LOAD_CHARTLAYERS_REQUEST: 'LOAD_CHARTLAYERS_REQUEST',
  LOAD_CHARTLAYERS_SUCCESS: 'LOAD_CHARTLAYERS_SUCCESS',
  LOAD_CHARTLAYERS_FAILURE: 'LOAD_CHARTLAYERS_FAILURE',

  CREATE_CHARTLAYER_REQUEST: 'CREATE_CHARTLAYER_REQUEST',
  CREATE_CHARTLAYER_SUCCESS: 'CREATE_CHARTLAYER_SUCCESS',
  CREATE_CHARTLAYER_FAILURE: 'CREATE_CHARTLAYER_FAILURE',

  UPDATE_CHARTLAYERVIEWSORDER_REQUEST: 'UPDATE_CHARTLAYERVIEWSORDER_REQUEST',
  UPDATE_CHARTLAYERVIEWSORDER_SUCCESS: 'UPDATE_CHARTLAYERVIEWSORDER_SUCCESS',
  UPDATE_CHARTLAYERVIEWSORDER_FAILURE: 'UPDATE_CHARTLAYERVIEWSORDER_FAILURE',

  DELETE_CHARTLAYER_REQUEST: 'DELETE_CHARTLAYER_REQUEST',
  DELETE_CHARTLAYER_SUCCESS: 'DELETE_CHARTLAYER_SUCCESS',
  DELETE_CHARTLAYER_FAILURE: 'DELETE_CHARTLAYER_FAILURE',

  CREATE_CHARTLAYERVIEW_REQUEST: 'CREATE_CHARTLAYERVIEW_REQUEST',
  CREATE_CHARTLAYERVIEW_SUCCESS: 'CREATE_CHARTLAYERVIEW_SUCCESS',
  CREATE_CHARTLAYERVIEW_FAILURE: 'CREATE_CHARTLAYERVIEW_FAILURE',

  DELETE_CHARTLAYERVIEW_REQUEST: 'DELETE_CHARTLAYERVIEW_REQUEST',
  DELETE_CHARTLAYERVIEW_SUCCESS: 'DELETE_CHARTLAYERVIEW_SUCCESS',
  DELETE_CHARTLAYERVIEW_FAILURE: 'DELETE_CHARTLAYERVIEW_FAILURE',

  UPDATE_CHARTLAYERVIEW_REQUEST: 'UPDATE_CHARTLAYERVIEW_REQUEST',
  UPDATE_CHARTLAYERVIEW_SUCCESS: 'UPDATE_CHARTLAYERVIEW_SUCCESS',
  UPDATE_CHARTLAYERVIEW_FAILURE: 'UPDATE_CHARTLAYERVIEW_FAILURE'
};
