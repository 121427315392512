import React, { useEffect, useState, useCallback } from 'react';
import { Dropdown, Input, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import './multipleselect.scss';

export const MultipleSelectDropdownQB = ({
  options,
  values = [],
  onChange,
  placeholder = 'select',
  readOnly = false
}) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [internalValues, setInternalValues] = useState(values ?? []);
  const [maxSelectedElements, setMaxSelectedElements] = useState(0);

  const selectedElements = options.filter(item => internalValues.includes(item.id));

  useEffect(() => {
    if (onChange && !isEqual(values, internalValues)) {
      onChange(internalValues);
    }
  }, [internalValues, onChange, values]);

  const toggleOpened = () => setIsOpen(prevState => !prevState);

  const handleChange = useCallback((item, isSelected) => {
    if (isSelected) setInternalValues(prevState => prevState.filter(id => id !== item.id));
    else setInternalValues(prevState => [...prevState, item.id]);
  }, []);

  //calculate width of container to show the correct amount of selectedElements
  //one element shoud be about 80px wide
  const multiselectContainer = useCallback(node => {
    if (node !== null) {
      setMaxSelectedElements(Math.floor(((node.current?.offsetWidth ?? 200) - 60) / 80));
    }
  }, []);

  const renderSelectedElements = useCallback(
    elements => {
      return elements.map(item => (
        <div
          key={item.id}
          className={`multipleselect__selected-item ${readOnly ? 'multipleselect__selected-item--disabled' : ''}`}
        >
          {!readOnly && (
            <span
              className={`multipleselect__selected-item-delete`}
              onClick={e => {
                e.stopPropagation();
                setInternalValues(internalValues.filter(value => value !== item.id));
              }}
            >
              &times;
            </span>
          )}
          <div className='multipleselect__selected-item-label'>{item.label}</div>
        </div>
      ));
    },
    [internalValues, readOnly]
  );

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        toggle={toggleOpened}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownToggle tag={'div'} caret={false}>
          <div
            ref={multiselectContainer}
            className={`multipleselect__selected-container ${
              selectedElements.length <= 0 ? 'multipleselect__selected-container--empty' : ''
            }`}
          >
            {selectedElements.length === 0 && placeholder}
            {selectedElements.length <= maxSelectedElements && renderSelectedElements(selectedElements)}
            {selectedElements.length > maxSelectedElements && (
              <>
                {renderSelectedElements(selectedElements.slice(0, maxSelectedElements))}
                <div className='multipleselect__additional-items'>+{selectedElements.length - maxSelectedElements}</div>
              </>
            )}
          </div>
        </DropdownToggle>

        <DropdownMenu className='multipleselect__dropdown-menu'>
          {options.map(item => (
            <DropdownItem
              disabled={readOnly}
              key={item.id}
              tag={'div'}
              toggle={false}
              style={{ padding: 0 }}
              onClick={!readOnly ? () => handleChange(item, internalValues.includes(item.id)) : undefined}
            >
              <div style={{ width: '100%', height: '100%', padding: '6px 12px' }}>
                <Input
                  type='checkbox'
                  id={item.id.toString()}
                  label={item.label}
                  checked={internalValues.includes(item.id)}
                  onChange={() => {}}
                />
                <label className='ml-2'>{item.label}</label>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {selectedElements.length <= 0 && (
        <small className='text-warning'>
          {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-at-least-one-element' })}
        </small>
      )}
    </>
  );
};

MultipleSelectDropdownQB.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
