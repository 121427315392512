import React from 'react';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const FormulaBuilderTableElement = ({ element, value, handleAddReference, renderAsHeadline = false }) => {
  const label = useSubelementPropertyTranslation(
    value,
    {},
    { useShortcodes: true, useStripped: true, useFallback: true }
  );

  if (renderAsHeadline) {
    return (
      <div className={`table-list__element table-list__element--headline`}>
        <span>{label}</span>
      </div>
    );
  }

  return (
    <div className={`table-list__element`} onClick={() => handleAddReference(element)}>
      <span>{label}</span>
    </div>
  );
};
