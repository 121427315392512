// if these are changed, pls also change the according css values in Reports.scss - .reports-dashboard
export const gridBreakpoints = {
  lg: 1200,
  md: 768,
  sm: 480,
  xs: 320,
  xxs: 0
};

// those are the min-widths;
// eg: sm is minimum 480 and max 767 px
