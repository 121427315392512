import React from 'react';
import { QuestionText } from '../components';

export const SingleInputNumber = ({ question, writeable }) => {
  const { config } = question;
  // eslint-disable-next-line
  const { min = null, max = null, decimals = 0 } = config;

  return (
    <>
      <QuestionText question={question} writeable={writeable} />
      <input
        className={'form-control que-input-field'}
        type={'number'}
        readOnly={true}
        disabled={true}
        placeholder={'numeric input'}
        min={min ? min : undefined}
        max={max}
      />
    </>
  );
};
