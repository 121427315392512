import Axios from 'axios';

import 'smv-redux/axiosConfig';
import { store } from 'smv-redux';
import { projectActionTypes } from '../constants/projectConstants';
import { uploadMediaItem } from './helper';
import { surveyActions } from './surveyActions';

/**
 * Fetches all projects of a specific client
 *
 * @param {string} clientId ID of the client that the projects belong to
 *
 */
const getAllProjects = async clientId => {
  store.dispatch({
    type: projectActionTypes.PROJECTS_LIST_REQUEST
  });

  return new Promise((resolve, reject) => {
    Axios.get('/clients/' + clientId + '/projects')
      .then(res => {
        const projects = res.data;

        store.dispatch({
          type: projectActionTypes.PROJECTS_LIST_SUCCESS,
          payload: { projects }
        });
        resolve(projects);
        return projects;
      })
      .catch(error => {
        store.dispatch({
          type: projectActionTypes.PROJECTS_LIST_FAILURE
        });
        reject(error);
      });
  });
};

/**
 * Saves/ updates wizard config and uploads media elements
 *
 * @param {object} wizardState that should be saved to the backend
 * @param {object} media optional files from indexeddb as id => value
 * @param {object|null} idb idb hook
 */
const saveWizard = (wizardState, media = {}, idb = null) => {
  const project = {
    id: wizardState.projectid ?? null,
    title: wizardState?.name ?? '',
    type: wizardState.type,
    status: 0
  };

  const survey = {
    id: wizardState?.surveyid ?? null,
    projectid: wizardState.projectid ?? null,
    title: wizardState.name,
    type: wizardState.type,
    status: 'wizard'
  };

  store.dispatch({
    type: projectActionTypes.SAVE_WIZARD_REQUEST,
    payload: survey
  });

  /**
   * If wizard has media configured, the media should be uploaded to the backend
   * and deleted locally. For that, a project id provided by the backend is
   * required if not already set (through a saved wizard state).
   *
   * Check for media files without a url property, assuming that they have not
   * already been uploaded and quere uploads in `uploadMediaQueue`:
   */
  return _saveProject(project)
    .then(_project => _saveSurvey(_project.id, survey.id, survey))
    .then(_survey => {
      const mediaUploadPromises =
        Object.keys(wizardState?.media ?? {}).length > 0 &&
        Object.keys(media).length > 0 &&
        Object.values(wizardState.media)
          .map(mediaItem => {
            if (!mediaItem?.url && !!media?.[mediaItem.id]) {
              const payload = {
                projectid: _survey.projectid,
                surveyid: _survey.id,
                mediaid: mediaItem.id,
                config: mediaItem
              };
              const file = media[mediaItem.id].content;

              return uploadMediaItem(payload, file).then(response => {
                const { media } = response.data.result;
                idb.deleteEntry(mediaItem.id);
                return Promise.resolve({ id: mediaItem.id, media: media });
              });
            } else {
              return false;
            }
          })
          .filter(Boolean);

      if (!mediaUploadPromises) return Promise.all([Promise.resolve(_survey), Promise.resolve([])]);
      else return Promise.all([Promise.resolve(_survey), Promise.all(mediaUploadPromises)]);
    })
    .then(([_survey, mediaUploadResults]) => {
      // const wizardStateWithUpdatedMedia = produce(wizardState, draft => {
      //   mediaUploadResults.forEach(mediaUpload => {
      //     draft.media[mediaUpload.id] = { ...draft.media[mediaUpload.id], ...mediaUpload.media };
      //   });
      // });
      return _saveSurveyWizard(_survey.projectid, _survey.id, wizardState);
    })
    .then(result => {
      if (result !== false) {
        store.dispatch({
          type: projectActionTypes.SAVE_WIZARD_SUCCESS,
          payload: {}
        });
      }
      return result;
    })
    .catch(err => {
      store.dispatch({
        type: projectActionTypes.SAVE_WIZARD_FAILURE,
        payload: { error: err }
      });
    });
};

/**
 * Saves the survey to the backend an uploads new media files
 *
 * Save steps:
 * - if project already has a project id (e.g. it was saved as a wizard), the media can be uploaded directly.
 * - otherwise just save a minimum project configuration to get a project id from the backend and then upload the
 *   media
 * - when the media upload is done, add the image paths to the media elements in the project configuration
 * - then save the complete project with media items
 */
const saveWizardProject = (project, survey, surveyParts, media = {}, idb = null) => {
  store.dispatch({
    type: projectActionTypes.CREATE_PROJECT_REQUEST,
    payload: { project, survey }
  });

  return _saveProject(project, true)
    .then(_project => _saveSurvey(_project.id, survey.id, survey, true))
    .then(_survey => {
      const mediaUploadPromises =
        Object.keys(surveyParts.wizard?.media ?? {}).length > 0 &&
        Object.keys(media).length > 0 &&
        Object.values(surveyParts.wizard.media)
          .map(mediaItem => {
            if (!mediaItem?.url && !!media?.[mediaItem.id]) {
              const payload = {
                projectid: _survey.projectid,
                surveyid: _survey.id,
                mediaid: mediaItem.id,
                config: mediaItem
              };
              const file = media[mediaItem.id].content;

              return uploadMediaItem(payload, file).then(response => {
                const { media } = response.data.result;
                idb.deleteEntry(mediaItem.id);
                return Promise.resolve({ id: mediaItem.id, media: media });
              });
            } else {
              return false;
            }
          })
          .filter(Boolean);

      if (!mediaUploadPromises) return Promise.all([Promise.resolve(_survey), Promise.resolve([])]);
      else return Promise.all([Promise.resolve(_survey), Promise.all(mediaUploadPromises)]);
    })
    .then(([_survey, mediaUploadResults]) => _saveSurveyParts(_survey.projectid, _survey.id, surveyParts))
    .then(result => {
      if (result !== false) {
        store.dispatch({
          type: projectActionTypes.CREATE_PROJECT_SUCCESS,
          payload: {}
        });
      }
      return result;
    })
    .catch(err => {
      store.dispatch({
        type: projectActionTypes.CREATE_PROJECT_FAILURE,
        payload: err
      });
    });
};

// const save = survey => dispatch => {
//   dispatch({
//     type: projectActionTypes.SAVE_PROJECT_REQUEST,
//     payload: { survey }
//   });
//   try {
//     _saveSurvey(survey)
//       .then(result => {
//         dispatch({
//           type: projectActionTypes.SAVE_PROJECT_SUCCESS,
//           payload: { ...result }
//         });
//       })
//       .catch(err => {
//         dispatch({
//           type: projectActionTypes.SAVE_PROJECT_FAILURE,
//           payload: {}
//         });
//       });
//   } catch (error) {
//     dispatch({
//       type: projectActionTypes.SAVE_PROJECT_FAILURE,
//       payload: {}
//     });
//   }
// };

/**
 * Deletes a project.
 *
 * @param {string} clientid
 * @param {string} projectid
 * @callback callbackFn //function that is called after request is done, passed parameters: true on success, false + error-object when failed
 *
 */
const deleteProject = (clientid, projectid, callbackFn = () => {}) => {
  store.dispatch({
    type: projectActionTypes.DELETE_PROJECT_REQUEST,
    payload: { projectid }
  });

  Axios.delete(`/projects/${projectid}`)
    .then(res => {
      store.dispatch({
        type: projectActionTypes.DELETE_PROJECT_SUCCESS,
        payload: { projectid }
      });
      callbackFn(true);
    })
    .catch(err => {
      callbackFn(false, err);
      store.dispatch({
        type: projectActionTypes.DELETE_PROJECT_FAILURE,
        payload: { error: err }
      });
    });
};

const resetProjectsList = () => {
  store.dispatch({
    type: projectActionTypes.INIT_RESET_PROJECTS_LIST
  });
};

/**
 * Checks for a project id and returns the project or saves it as a new project
 * to the backend and returns the updated project with an assigned id.
 *
 * @param {object} project
 *
 * @returns {promise} A promise resolving in the original or updated project
 *                    contents (with an id)
 * @private
 */
function _saveProject(project, forceSave = false) {
  return new Promise(resolve => {
    if (!!project?.id && !forceSave) {
      /**  project has an id, resolve with original content */
      resolve(project);
    } else {
      /**  project has no id, save contents to backend */
      const data = new FormData();
      data.append(
        'payload',
        JSON.stringify({
          projectid: project?.id ?? null,
          content: project
        })
      );

      Axios.post('/jsonrpc.php', data, {
        params: {
          program: 'Smoove',
          controller: 'XProject',
          action: 'save'
        }
      }).then(result => {
        project.id = result.data.result.projectid;
        resolve(project);
      });
    }
  });
}

/**
 * Checks for a survey id and returns the survey or saves it as a new survey
 * to the backend, binds it to the parent project and returns the updated
 * survey with an assigned id.
 *
 * @param {object} project
 * @param {object} survey
 *
 * @returns {promise} A promise resolving in the original or updated survey (with an id)
 * @private
 */
function _saveSurvey(projectid, surveyid = null, config, forceSave = false) {
  return new Promise((resolve, reject) => {
    if (!projectid) reject('Invalid project, missing a valid id property.');
    /**  survey has no project id, create a new one */
    if (surveyid && !forceSave) resolve(config);
    else {
      surveyActions.saveConfig(projectid, surveyid, config).then(_survey => resolve(_survey));
    }
  });
}

/**
 * Saves/ updates a survey wizard configuration
 *
 * @param {string} projectid Project id
 * @param {string} surveyid Survey id
 * @param {object} wizard WizardState contents {type: '', countries: [], ... }
 *
 * @returns {Promise} Axis Post request
 */
function _saveSurveyWizard(projectid, surveyid, wizard) {
  return surveyActions.saveWizard(projectid, surveyid, wizard);
}

function _saveSurveyParts(projectid, surveyid, surveyParts) {
  return surveyActions.saveSurveyPartsAll(projectid, surveyid, surveyParts);
}

export const projectsActions = {
  // projects
  getAllProjects,

  // project
  // save,
  deleteProject,
  resetProjectsList,

  // wizard
  saveWizard,
  saveWizardProject
};
