import React from 'react';
import { useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

import { useSubelementPropertyTranslation } from 'smv-helpers';
import { EditableTextHover } from '../..';

import './SharedChoices.scss';

export const SharedChoice = ({ question, choice, writeable }) => {
  const choiceLabel = useSubelementPropertyTranslation(choice, null, {
    property: 'label',
    useShortcodes: false,
    useStripped: !writeable,
    useFallback: !writeable
  });

  const intl = useIntl();

  switch (question.config.qtype) {
    case 'single_vector':
      return (
        <div className='d-flex align-items-center shared-choice'>
          <i className={`fal fa-fw fa-circle`} />
          <UncontrolledTooltip target={`tt_${choice.id}`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.shared-choice' })}
          </UncontrolledTooltip>
          <i className='fal fa-fw fa-arrow-turn-down-right mr-1' id={`tt_${choice.id}`}></i>
          <EditableTextHover value={choiceLabel} writeable={false} className='no-padding' />
        </div>
      );

    case 'multiple_vector':
      return (
        <div className='d-flex align-items-center shared-choice'>
          <i className={`fal fa-fw fa-square`} />
          <UncontrolledTooltip target={`tt_${choice.id}`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.shared-choice' })}
          </UncontrolledTooltip>
          <i className='fal fa-fw fa-arrow-turn-down-right mr-1' id={`tt_${choice.id}`}></i>
          <EditableTextHover value={choiceLabel} writeable={false} className='no-padding' />
        </div>
      );

    case 'multiple_matrix':
    case 'single_matrix':
      return (
        <div className='d-flex align-items-baseline question-choice'>
          <UncontrolledTooltip target={`tt_${choice.id}`} delay={{ hide: 200 }} autohide={false}>
            {intl.formatMessage({ id: 'smoove.questionnaire.shared-choice' })}
          </UncontrolledTooltip>
          <i className='fal fa-fw fa-arrow-turn-down-right mr-1' id={`tt_${choice.id}`}></i>
          <EditableTextHover value={choiceLabel} writeable={false} className='no-padding' />
        </div>
      );

    default:
      return <div>question type unknown</div>;
  }
};

SharedChoice.propTypes = {
  question: PropTypes.object.isRequired,
  choice: PropTypes.object.isRequired
};
