export const settingsActionTypes = {
  /**
   * SMOOVE_SETTINGS_INIT_X
   */
  SMOOVE_SETTINGS_INIT_REQUEST: 'SMOOVE_SETTINGS_INIT_REQUEST',
  SMOOVE_SETTINGS_INIT_SUCCESS: 'SMOOVE_SETTINGS_INIT_SUCCESS',
  SMOOVE_SETTINGS_INIT_FAILURE: 'SMOOVE_SETTINGS_INIT_FAILURE',

  /**
   * SMOOVE_SETTINGS_USER_X_X_X
   */
  SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS: 'SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS',

  SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS: 'SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS',

  APP_SETTINGS_SET_LOCALE_SUCCESS: 'APP_SETTINGS_SET_LOCALE_SUCCESS',

  TOGGLE_SHARED_EXPORTS_WINDOW_REQUEST: 'TOGGLE_SHARED_EXPORTS_WINDOW_REQUEST',

  SHARED_EXPORTS_REQUEST: 'SHARED_EXPORTS_REQUEST',
  SHARED_EXPORTS_SUCECSS: 'SHARED_EXPORTS_SUCECSS',
  SHARED_EXPORTS_FAILURE: 'SHARED_EXPORTS_FAILURE',

  TOGGLE_USERS_EXPORTS_WINDOW_REQUEST: 'TOGGLE_USERS_EXPORTS_WINDOW_REQUEST',

  USERS_EXPORTS_REQUEST: 'USERS_EXPORTS_REQUEST',
  USERS_EXPORTS_SUCECSS: 'USERS_EXPORTS_SUCECSS',
  USERS_EXPORTS_FAILURE: 'USERS_EXPORTS_FAILURE'
};
