import React from 'react';

import {
  ResultTableOpenEndedListingHead,
  ResultTableOpenEndedListingBody
  // , ResultTableOpenEndedListingFooter
} from '.';

import './ResultTableOpenEndedListing.scss';

export const ResultTableOpenEndedListing = ({ table, tableResult, handler, isLoading, limit, offset }) => {
  return (
    <>
      <div className={'result-table-container'}>
        {isLoading && (
          <div className='loading-overlay'>
            <span className={'fas fa-spinner fa-spin fa-3x'}></span>
          </div>
        )}
        <div className={'result-table-listing'}>
          <ResultTableOpenEndedListingHead table={table} />
          <ResultTableOpenEndedListingBody table={table} tableResult={tableResult} />
        </div>
      </div>
      {/* <ResultTableOpenEndedListingFooter
        table={table}
        tableResult={tableResult}
        handler={handler}
        limit={limit}
        offset={offset}
      /> */}
    </>
  );
};
