import { useCallback } from 'react';
import { Col, Input, FormGroup, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import produce from 'immer';

export const WeightSettings = ({ tableConfig, handler }) => {
  const weights = useSelector(state => state.survey.data?.weights ?? {});
  const intl = useIntl();

  const toggleWeightResult = useCallback(
    () =>
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.weightResult = !draftState.config.weightResult;
          // set first weight, if none is selected
          if (draftState.config.weightResult && !draftState.config?.weightVariable) {
            draftState.config.weightVariable = Object.values(weights)[0].id;
          }
        })
      ),
    [handler, weights]
  );

  const setWeightVariable = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.config.weightVariable = value;
        })
      );
    },
    [handler]
  );

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.table-config.weighting'} defaultMessage={'Weighting'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>{intl.formatMessage({ id: `smoove.page.tables.table-config.enable-weighting` })}</Col>
        <Col md={7}>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              id={`weightResult`}
              name='weightResult'
              checked={tableConfig.config.weightResult}
              onChange={toggleWeightResult}
              disabled={Object.values(weights).length <= 0}
            />
          </FormGroup>
        </Col>
      </Row>
      {tableConfig.config.weightResult && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.table-config.weighting-variable'}
              defaultMessage={'Weighting variable'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              label={intl.formatMessage({ id: `smoove.page.tables.table-config.weighting-variable` })}
              id={`weightVariable`}
              name='weightVariable'
              value={tableConfig.config.weightVariable ?? ''}
              onChange={setWeightVariable}
            >
              {Object.values(weights).map(weight => {
                return (
                  <option key={weight.id} value={weight.id}>
                    {weight.label}
                  </option>
                );
              })}
            </Input>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};
