import { useCallback } from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { FormulaBuilderTableList } from '.';

const mathOperators = ['+', '-', '*', '/', '(', ')'];

export const FormulaBuilderToolbar = ({
  calculationMode,
  calculation,
  tableConfig,
  handleAddValue,
  handleAddOperator,
  handleAddReference,
  dropdownContainerRef
}) => {
  const [isReferenceDropdownOpen, setIsReferenceDropdownOpen] = useState(false);
  const toggleReferenceDropdown = useCallback(() => setIsReferenceDropdownOpen(b => !b), []);

  const _handleAddReference = useCallback(
    (...args) => {
      handleAddReference(...args);
      toggleReferenceDropdown();
    },
    [handleAddReference, toggleReferenceDropdown]
  );

  return (
    <div className={`formula-builder-toolbar`}>
      <div className='formula-builder-toolbar__operators'>
        {mathOperators.map((el, idx) => {
          return (
            <div key={`${el}_${idx}`} className='formula-builder__element' onClick={() => handleAddOperator(el)}>
              <span>{el}</span>
            </div>
          );
        })}

        <div className='formula-builder__element formula-builder__element--new' onClick={handleAddValue}>
          fix value
        </div>
        <div>
          <Dropdown
            className='formula-builder__element formula-builder__element--new'
            tag={'div'}
            isOpen={isReferenceDropdownOpen}
            toggle={toggleReferenceDropdown}
          >
            <DropdownToggle tag={'span'} caret>
              table reference
            </DropdownToggle>
            <DropdownMenu container={dropdownContainerRef}>
              <FormulaBuilderTableList
                calculationMode={calculationMode}
                tableConfig={tableConfig}
                calculation={calculation}
                handleAddReference={_handleAddReference}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
